import { Grid2, SimpleLoader, Typography } from "components";
import dayjs from "dayjs";
import { motion } from "framer-motion";
import { ThemeContainer } from "utils/Theme";

interface LayoutProps {
  children?: React.ReactNode;
  logo?: string;
  loading?: boolean;
}

const CommonThirdPartyLayout = (props: LayoutProps) => {
  const { theme } = ThemeContainer();

  const { children, logo, loading } = props;

  return (
    <Grid2 container bgcolor="#F8FAFC" height="100vh" direction="column">
      <Grid2
        size="auto"
        py={2}
        px={4}
        display="flex"
        alignItems="center"
        borderBottom={`1px solid ${theme.palette.divider}`}
      >
        <img
          src={logo || "/Logos/iconFullLogo.png"}
          alt=""
          height={`-webkit-fill-available`}
          width={"auto"}
          style={{ maxHeight: "32px" }}
          onError={({ currentTarget }) => {
            currentTarget.src = "https://gridkey.in/Logos/iconFullLogo.png";
          }}
        />
      </Grid2>
      <Grid2
        container
        size={12}
        display="flex"
        justifyContent="center"
        alignItems="center"
        flex={1}
        sx={{ p: { xs: "2px", sm: 2 } }}
      >
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
          style={{
            flexGrow: 1,
            minWidth: 0,
            padding: "8px 16px",
            overflow: "auto !important",
          }}
          className="overFlowAuto"
        >
          {loading ? <SimpleLoader /> : children}
        </motion.div>
      </Grid2>
      <Grid2
        size="auto"
        p={2}
        display="flex"
        justifyContent="center"
        borderTop="1px solid #E3E8EF"
      >
        <Typography variant="caption" color="text.secondary">
          © {dayjs().get("year")} gridkey. All rights reserved.
        </Typography>
      </Grid2>
    </Grid2>
  );
};

export default CommonThirdPartyLayout;
