export const portfolioListColumns = {
  clientName: true,
  investedValue: true,
  currentValue: true,
  unrealisedGain: true,
  absoluteReturn: true,
  todaysGain: true,
  todaysGainPercentage: true,
  xirr: true,
  lastTransactionDate: true,
  lastUploadDate: true,
  lastOrderModelPortfolio: true,
  lastOrderDate: true,
  lastOrderVersion: true,
  lastOrderTemplate: true,
  lastOrderType: true,
  lastExecutionSheet: true,
  action: true,
  cash: true,
  equityPercentage: true,
  equityBasket: true,
  twrr: true,
  netContribution: true,
};

export const portfolioGroupListColumns = {
  name: true,
  investedAmount: true,
  currentValue: true,
  unrealisedGain: true,
  absoluteReturn: true,
  todaysGain: true,
  todaysGainPercentage: true,
  xirr: true,
  count: true,
  action: true,
};

export const importManagerListColumns = {
  uploadedOn: true,
  fileName: true,
  brokerFormat: true,
  source: true,
  remarks: true,
  status: true,
  userUploaded: true,
  action: true,
};

export const reconManagerListColumns = {
  clientName: true,
  uploadedOn: true,
  fileName: true,
  format: true,
  remarks: true,
  status: true,
  userUploaded: true,
};

export const reportListColumns = {
  name: true,
  reportType: true,
  format: true,
  timePeriod: true,
  isSchedule: true,
  createdOn: true,
  portfolioCount: true,
  action: true,
  reportName: true,
  scheduleType: true,
  scheduledDate: true,
  portfolios: true,
};

export const tradeBookListColumns = {
  date: true,
  clientName: true,
  companyName: true,
  nseCode: true,
  transactionType: true,
  quantity: true,
  price: true,
  amountWithBrokerage: true,
  contractNoteNum: true,
  brokerName: true,
  billAmount: true,
  isinCode: true,
  brokerage: true,
  stt: true,
  gst: true,
  stampCharges: true,
  transactionCharges: true,
  sebiTurnoverFees: true,
  otherCharges: true,
  fdAccountNo: true,
  interestType: true,
  tds: true,
};

export const mutualFundListColumns = {
  date: true,
  clientName: true,
  companyName: true,
  nseCode: true,
  transactionType: true,
  quantity: true,
  price: true,
  brokerName: true,
  billAmount: true,
  isinCode: true,
  brokerage: true,
  stampCharges: true,
  transactionRemarks: true,
  folioNo: true,
};

export const portfolioMutualFundsColumns = {
  date: true,
  clientName: true,
  companyName: true,
  folioNo: true,
  nseCode: true,
  transactionType: true,
  transactionRemarks: true,
  quantity: true,
  price: true,
  brokerName: true,
  billAmount: true,
  isinCode: true,
  brokerage: true,
  stampCharges: true,
};

export const bankStatementListColumns = {
  date: true,
  clientName: true,
  expenseIncome: true,
  billAmount: true,
  remarks: true,
};

export const clientListColumns = {
  name: true,
  currentValue: true,
  investedValue: true,
  unrealisedGain: true,
  absoluteReturn: true,
  todaysGain: true,
  todaysGainPercentage: true,
  portfolioCount: true,
  netContribution: true,
};

export const familyListColumns = {
  name: true,
  currentValue: true,
  investedValue: true,
  unrealisedGain: true,
  absoluteReturn: true,
  todaysGain: true,
  todaysGainPercentage: true,
  portfolioCount: true,
  netContribution: true,
};

export const complianceListColumns = {
  clientName: true,
  riskScore: true,
  kycStatus: true,
  agreementStatus: true,
  action: true,
};

export const leadListColumns = {
  name: true,
  companyName: true,
  email: true,
  leadStatusStr: true,
  phoneNumber: true,
  tags: true,
  leadAssignedToStr: true,
  action: true,
};

export const branchListColumns = {
  name: true,
  reportingBranchName: true,
  code: true,
  address: true,
  action: true,
};

export const employeeListColumns = {
  name: true,
  code: true,
  email: true,
  phone: true,
  reportingBranch: true,
  headBranch: true,
};

export const holdingSummaryListColumns = {
  name: true,
  rawQuantity: true,
  avgBuyPrice: true,
  investedAmount: true,
  cmp: true,
  currentAmount: true,
  unrealisedGain: true,
  absoluteReturn: true,
  todaysGain: true,
  todaysGainPercentage: true,
  countPortfolios: true,
  weightage: false,
};
export const insightsColumns = {
  name: true,
  rawQuantity: true,
  avgBuyPrice: true,
  investedAmount: true,
  cmp: true,
  currentAmount: true,
  unrealisedGain: true,
  absoluteReturn: true,
  todaysGain: true,
  todaysGainPercentage: true,
  countPortfolios: true,
  weightage: false,
};

export const portfolioHoldingListColumns = {
  assetName: true,
  rawQuantity: true,
  avgPrice: true,
  investedAmount: true,
  cmp: true,
  currentAmount: true,
  unrealisedGain: true,
  absoluteReturn: true,
  todaysGain: true,
  todaysGainPercentage: true,
  weightage: true,
};

export const portfolioHoldingsColumns = {
  companyName: true,
  rawQuantity: true,
  avgPrice: true,
  investedAmount: true,
  cmp: true,
  currentAmount: true,
  unrealisedGain: true,
  absoluteReturn: true,
  todaysGain: true,
  todaysGainPercentage: true,
  weightage: true,
};

export const portfolioGroupDetailsListColumns = {
  name: true,
  rawQuantity: true,
  price: true,
  investedAmount: true,
  cmp: true,
  currentAmount: true,
  unrealisedGain: true,
  absoluteReturn: true,
  todaysGain: true,
  todaysGainPercentage: true,
  weightage: true,
};

export const portfolioGroupTradeBookListColumns = {
  date: true,
  type: true,
  quantity: true,
  price: true,
  brokerage: true,
  amount: true,
  totalQuantity: true,
};

export const clientHoldingListColumns = {
  name: true,
  rawQuantity: true,
  avgPrice: true,
  investedAmount: true,
  cmp: true,
  currentAmount: true,
  unrealisedGain: true,
  absoluteReturn: true,
  todaysGain: true,
  todaysGainPercentage: true,
  weightage: true,
  countPortfolios: false,
};

export const clientFamilyListColumns = {
  name: true,
  currentValue: true,
  investedValue: true,
  portfolioCount: true,
  unrealisedGain: true,
  todaysGain: true,
  todaysGainPercentage: true,
  absoluteReturn: true,
  netContribution: true,
};

export const clientAccessListColumns = {
  branch: true,
  name: true,
  email: true,
  phone: true,
  role: true,
  salary: true,
  action: true,
};

export const familyClientListColumns = {
  name: true,
  currentValue: true,
  investedValue: true,
  unrealisedGain: true,
  absoluteReturn: true,
  todaysGain: true,
  todaysGainPercentage: true,
  portfolioCount: true,
  netContribution: true,
};

export const familyHoldingColumns = {
  name: true,
  rawQuantity: true,
  avgBuyPrice: true,
  avgPrice: true,
  investedAmount: true,
  cmp: true,
  currentAmount: true,
  unrealisedGain: true,
  absoluteReturn: true,
  todaysGain: true,
  todaysGainPercentage: true,
  weightage: true,
  countPortfolios: false,
};

export const familyPortfolioColumns = {
  clientName: true,
  investedValue: true,
  currentValue: true,
  unrealisedGain: true,
  absoluteReturn: true,
  todaysGain: true,
  todaysGainPercentage: true,
  xirr: true,
  lastTransactionDate: true,
  lastUploadDate: true,
  netContribution: true,
};

export const branchHoldingColumns = {
  name: true,
  currentValue: true,
  unrealisedGain: true,
  absoluteReturn: true,
  todaysGain: true,
  todaysGainPercentage: true,
  portfolioCount: true,
};

export const branchClientColumns = {
  name: true,
  currentValue: true,
  unrealisedGain: true,
  absoluteReturn: true,
  todaysGain: true,
  todaysGainPercentage: true,
  portfolioCount: true,
};

export const branchPortfolioColumns = {
  name: true,
  investedValue: true,
  unrealisedGain: true,
  absoluteReturn: true,
  todaysGain: true,
  todaysGainPercentage: true,
  xirr: true,
  lastTransactionDate: true,
  lastUploadDate: true,
  action: true,
};

export const employeeHoldingColumns = {
  name: true,
  rawQuantity: true,
  avgBuyPrice: true,
  investedAmount: true,
  cmp: true,
  currentAmount: true,
  unrealisedGain: true,
  absoluteReturn: true,
  todaysGain: true,
  todaysGainPercentage: true,
  countPortfolios: true,
};

export const feePlanSetupColumns = {
  name: true,
  revenueProducts: true,
  totalAumMapped: true,
  totalPortfolio: true,
  totalFeeGenerated: true,
  totalFeeCollected: true,
  action: true,
};

export const portfolioManagerColumns = {
  clientName: true,
  feePlan: true,
  startDate: true,
  totalFeeCollected: true,
  feeGenerated: true,
  partner: true,
};

export const feeCalculationColumns = {
  clientName: true,
  calculationDate: true,
  feeAmount: true,
  feePlanName: true,
  feeDateRange: true,
  proformaCreated: true,
  generatedType: true,
  action: true,
};

export const proformaInvoiceColumns = {
  proformaNumber: true,
  date: true,
  clientName: true,
  portfolio: true,
  amount: true,
  feePlanName: true,
  Name: true,
  isInvoice: true,
  action: true,
};

export const invoiceColumns = {
  invoiceNumber: true,
  invoiceDate: true,
  clientName: true,
  portfolioName: true,
  totalAmount: true,
  proformaNumber: true,
  isPayment: true,
  action: true,
};

export const feeCollectionColumns = {
  date: true,
  client: true,
  amount: true,
  paymentType: true,
  status: true,
  action: true,
};

export const feePlanPortfolioMappingColumns = {
  clientName: true,
  feePlan: true,
  startDate: true,
  totalFeeCollected: true,
  feeGenerated: true,
  partner: true,
};
// ======================================================
export const autoImportColumns = {
  email: true,
  setUpEmail: true,
  broker: true,
  autoApprove: true,
  password: true,
};

export const brokerIntegrationColumns = {
  orderDate: true,
  modelPortfolio: true,
  version: true,
  smallcaseStatus: true,
  action: true,
};

export const modelPortfolioColumns = {
  name: true,
  mappingStatus: true,
};

export const leadNoteColumns = {
  note: true,
  noteType: true,
  noteDate: true,
  updatedAt: true,
  action: true,
};

export const leadMeetingListColumns = {
  agenda: true,
  meetingType: true,
  meetingPerson: true,
  meetingDate: true,
  startTime: true,
  status: true,
  createdAt: true,
  action: true,
};

export const ClientRiskProfilingColumns = {
  riskStatus: true,
  points: true,
  createdAt: true,
  action: true,
};

export const mappedPortfolioColumns = {
  name: true,
  status: true,
  actions: true,
};

export const modelPortfolioOrderManagerColumns = {
  employeeName: true,
  orderTemplate: true,
  orderType: true,
  version: true,
  orderDate: true,
  orderDateTime: true,
  numPortfolios: true,
  action: true,
};

export const modelPortfolioVersionFormColumns = {
  version: true,
  date: true,
  portfolioValue: true,
  investedValue: true,
  currentValue: true,
  xirr: true,
  holdingCount: true,
  action: true,
};

export const stockUniverseColumns = {
  companyName: true,
  status: true,
  cmp: true,
  targetPrice: true,
  stopLoss: true,
  portfolioCount: true,
  holdingValue: true,
  action: true,
};

export const modelTradebookColumns = {
  date: true,
  companyName: true,
  isinCode: true,
  nseCode: true,
  transactionType: true,
  quantity: true,
  price: true,
};

export const modelBankLedgerColumns = {
  date: true,
  expenseIncome: true,
  bal: true,
  debit: true,
  credit: true,
  remarks: true,
};

export const portfolioHoldingTransactionColumns = {
  date: true,
  type: true,
  quantity: true,
  price: true,
  brokerage: true,
  amount: true,
  totalQuantity: true,
};

export const longTermHoldingColumns = {
  quantity: true,
  purchasePrice: true,
  purchaseDate: true,
  sellPrice: true,
  sellDate: true,
  realisedGain: true,
  avgUnitCost: true,
  age: true,
  unrealisedGain: true,
};

export const portfolioGroupHoldingColumns = {
  name: true,
  rawQuantity: true,
  price: true,
  investedAmount: true,
  cmp: true,
  currentAmount: true,
  unrealisedGain: true,
  absoluteReturn: true,
  todaysGain: true,
  todaysGainPercentage: true,
  weightage: false,
  countPortfolios: true,
};

export const importManagerColumns = {
  date: true,
  portfolioName: true,
  companyName: true,
  bseCode: true,
  transactionType: true,
  quantity: true,
  price: true,
  brokerage: true,
  amountWithBrokerage: true,
  stt: true,
  gst: true,
  stampCharges: true,
  transactionCharges: true,
  sebiTurnoverFees: true,
  otherCharges: true,
  billAmount: true,
  contractNoteNum: true,
  brokerName: true,
  error: true,
};

export const archiveManagerPageColumns = {
  uploadedOn: true,
  fileName: true,
  format: true,
  source: true,
  remarks: true,
  status: true,
  action: true,
};

export const familyAccessColumns = {
  branch: true,
  name: true,
  email: true,
  phone: true,
  role: true,
  salary: true,
  action: true,
  portfolioCount: true,
  accessCount: true,
};

export const addModelPortfoliolColumns = {
  stock: true,
  weightage: true,
  targetPrice: false,
  stopLoss: false,
  currentAllocation: true,
  rationale: false,
  action: true,
};

export const selectPortfolioModalColumns = {
  clientName: true,
  portfolioValue: true,
  currentAmount: true,
  availableCash: true,
  availableCashPercentage: true,
  availableCashEquivalent: true,
  totalCash: true,
};

export const totalOrdersColumns = {
  employeeName: true,
  orderTemplate: true,
  orderType: true,
  version: true,
  orderDate: true,
  orderDateTime: true,
  numPortfolios: true,
  action: true,
};

export const avlCashBalance = {
  clientName: true,
  lastCashDate: true,
  availableLedgerBalance: true,
  date: true,
  transactionType: true,
  billAmount: true,
  remarks: true,
  brokerLedgerBalance: true,
  action: true,
  tax: true,
};

export const equityBasketOrderPortfolio = {
  portfolio: true,
  netTradeAmount: true,
  buyAmount: true,
  sellAmount: true,
  sendingMode: true,
  broker: true,
  actions: true,
  totalBuyAmount: true,
  totalSellAmount: true,
  action: true,
};

export const incomeExpense = {
  date: true,
  portfolioName: true,
  transactionType: true,
  billAmount: true,
  remarks: true,
};

export const bankLedgerColumns = {
  date: true,
  clientName: true,
  transactionType: true,
  debit: true,
  credit: true,
  companyName: true,
  status: true,
};

export const employeeClientAccessColumns = {
  branch: true,
  name: true,
  email: true,
  phone: true,
  pan: true,
  portfolioCount: true,
  action: true,
};

export const uploadManagerColumns = {
  date: true,
  portfolioName: true,
  companyName: true,
  bseCode: true,
  transactionType: true,
  quantity: true,
  price: true,
  brokerage: true,
  amountWithBrokerage: true,
  stt: true,
  gst: true,
  stampCharges: true,
  transactionCharges: true,
  sebiTurnoverFees: true,
  otherCharges: true,
  billAmount: true,
  contractNoteNum: true,
  brokerName: true,
  error: true,
};

export const portfolioStatusMColumns = {
  companyName: true,
  status: true,
};

export const executionSheetColumns = {
  name: true,
  createdOn: true,
  orderBy: true,
  mapPortfolio: true,
  action: true,
};

export const walletHistoryColumns = {
  date: true,
  particulars: true,
  client: true,
  amount: true,
  bal: true,
};

export const walletFullHistoryColumns = {
  chargesType: true,
  totalAmount: true,
};

export const rightIssueColumns = {
  portfolioName: true,
  convertedQuantity: true,
  quantity: true,
};

export const editRightIssueColumns = {
  portfolioName: true,
  convertedQuantity: true,
  quantity: true,
};

export const bankInfoColumns = {
  name: true,
  bank: true,
  accountNum: true,
  type: true,
  ifsc: true,
  Assign: true,
};

export const demateInfoColumns = {
  brokerName: true,
  dematId: true,
  depositary: true,
  uniqClientCode: true,
  assign: true,
};

export const portfolioImportManagerColumns = {
  uploadedOn: true,
  fileName: true,
  brokerFormat: true,
  source: true,
  remarks: true,
  status: true,
  userUploaded: true,
  action: true,
};

export const portfolioBankLedgerColumns = {
  date: true,
  clientName: true,
  transactionType: true,
  debit: true,
  credit: true,
  ledgerBalance: true,
  remarks: true,
};

export const portfolioTradeBookColumns = {
  date: true,
  companyName: true,
  nseCode: true,
  transactionType: true,
  quantity: true,
  price: true,
  brokerage: true,
  amountWithBrokerage: true,
  stt: true,
  gst: true,
  stampCharges: true,
  transactionCharges: true,
  sebiTurnoverFees: true,
  otherCharges: true,
  billAmount: true,
  isinCode: true,
  contractNoteNum: true,
  brokerName: true,
};

export const portfolioAccessColumns = {
  name: true,
  email: true,
  phone: true,
  role: true,
};

export const portfolioInterestColumns = {
  date: true,
  companyName: true,
  nseCode: true,
  isinCode: true,
  quantity: true,
};

export const holdingDetailsColumns = {
  clientName: true,
  rawQuantity: true,
  avgBuyPrice: true,
  investedAmount: true,
  currentAmount: true,
  unrealisedGain: true,
  absoluteReturn: true,
  todaysGain: true,
  weightage: true,
};

export const portfolioCashEntryColumns = {
  date: true,
  clientName: true,
  expenseIncome: true,
  billAmount: true,
  remarks: true,
};

export const editFeePlanPortfolioColumns = {
  portfolio: true,
  feePlan: true,
};

export const employeeClientAccess = {
  name: true,
  familyName: true,
  email: true,
  phone: true,
  pan: true,
  portfolioCount: true,
  action: true,
};

export const customAgreementColumns = {
  title: true,
  createdAt: true,
  action: true,
};

export const historicalImportColumns = {
  portfolioName: true,
  feePlan: true,
  startDate: true,
  endDate: true,
  invoiceDate: true,
  invoiceNumber: true,
  invoiceAmount: true,
  managementFee: true,
  performanceFee: true,
  sgst: true,
  cgst: true,
  IGST: true,
  feeCollectionDate: true,
  feeCollectionAmount: true,
  source: true,
  ref: true,
  error: true,
};

export const reconManagerViewUploadColumns = {
  companyName: true,
  gridQuantity: true,
  brokerQuantity: true,
  quantityDeviation: true,
  gridAvgBuyPrice: true,
  brokerAvgBuyPrice: true,
  avgBuyPriceDeviation: true,
  status: true,
};

export const portfolioFeePlansColumns = {
  feePlan: true,
  startDate: true,
  endDate: true,
  status: true,
  action: true,
};

export const portfolioFeeCollectionColumns = {
  date: true,
  invoiceDate: true,
  invoiceAmount: true,
  paidAmount: true,
  invoiceStatus: true,
  source: true,
};
export const sheetPortfolioMappingsColumns = {
  name: true,
  status: true,
  action: true,
};

export const marketingMaterialColumns = {
  fileName: true,
  marketingText: true,
  actions: true,
};
export const portfolioStatusColumns = {
  portfolio: true,
  quantity: true,
  investedValue: true,
  holdingValue: true,
  avgBuyPrice: true,
  weightage: true,
  action: true,
};

export const tradesOfPortfolioColumns = {
  company: true,
  uniqueCode: true,
  transactionType: true,
  quantity: true,
  price: true,
  isConverted: true,
  executedQuantity: true,
  smallcaseStatus: true,
  smallcaseError: true,
  filledQuantity: true,
};

export const revenueMandateColumns = {
  clientName: true,
  feePlanName: true,
  firstCollectionDate: true,
  amount: true,
  state: true,
  frequency: true,
};

export const mandatePresentationColumns = {
  settlementDate: true,
  frequency: true,
  withoutGstAmount: true,
  amount: true,
  status: true,
  paymentCount: true,
};

export const mandatePresentationTransactionColumns = {
  settlementDate: true,
  amount: true,
  status: true,
  invoice: true,
};

export const portfolioFamilyAccessColumns = {
  name: true,
  familyName: true,
  action: true,
};

export const familyAdminAccessColumns = {
  name: true,
  email: true,
  phone: true,
  role: true,
  salary: true,
  action: true,
};

export const portfolioHoldingsTableColumns = {
  name: true,
  folioNo: true,
  rawQuantity: true,
  avgBuyPrice: true,
  investedAmount: true,
  cmp: true,
  currentAmount: true,
  unrealisedGain: true,
  absoluteReturn: true,
  todaysGain: true,
  todaysGainPercentage: true,
  weightage: true,
  fdAccountNo: true,
};

export const privateEquityTableColumns = {
  startingDate: true,
  name: true,
  shortName: true,
  cap: true,
  cmp: true,
  instType: true,
  instSubType: true,
  status: true,
};

const partnerTableColumns = {
  name: true,
  code: true,
  email: true,
  phone: true,
  headBranch: true,
};

const partnerClientListColumns = {
  name: true,
  totalAum: true,
  xirr: true,
  billedRevenue: true,
  commissionAmount: true,
  billingDate: true,
  portfolioCount: true,
};
const subscribedPortfolioListColumns = {
  clientName: true,
  basketName: true,
  withGstAmount: true,
  lastExecutionSheet: true,
  action: true,
};
const couponColumns = {
  name: true,
  feePlanName: true,
  discountValue: true,
  isActive: true,
  couponType: true,
};

export const columnsPersist = {
  portfolio: portfolioListColumns,
  portfolioGroup: portfolioGroupListColumns,
  importManager: { ...importManagerListColumns, ...importManagerColumns },
  uploadManager: {
    ...importManagerListColumns,
    ...importManagerColumns,
    ...uploadManagerColumns,
  },
  brokerReviewMapping: {
    ...importManagerListColumns,
    ...importManagerColumns,
    ...uploadManagerColumns,
  },
  report: reportListColumns,
  tradeBook: tradeBookListColumns,
  privateEquityList: tradeBookListColumns,
  aif: tradeBookListColumns,
  FDList: tradeBookListColumns,
  FDPayoutList: tradeBookListColumns,
  portfolioFDPayoutList: tradeBookListColumns,
  anchorTransaction: portfolioHoldingsTableColumns,
  bankStatement: bankStatementListColumns,
  client: clientListColumns,
  family: familyListColumns,
  compliance: complianceListColumns,
  lead: leadListColumns,
  branch: branchListColumns,
  employee: employeeListColumns,
  holdingSummary: holdingSummaryListColumns,
  insights: insightsColumns,
  portfolioHolding: portfolioHoldingListColumns,
  portfolioGroupDetails: portfolioGroupDetailsListColumns,
  portfolioGroupTradeBook: portfolioGroupTradeBookListColumns,
  clientHolding: clientHoldingListColumns,
  clientFamily: clientFamilyListColumns,
  clientAccess: clientAccessListColumns,
  familyClient: familyClientListColumns,
  familyHolding: familyHoldingColumns,
  familyPortfolio: familyPortfolioColumns,
  branchHolding: branchHoldingColumns,
  branchClient: branchClientColumns,
  branchPortfolio: branchPortfolioColumns,
  employeeHolding: employeeHoldingColumns,
  reconManager: reconManagerListColumns,
  feePlans: feePlanSetupColumns,
  portfolioMapping: portfolioManagerColumns,
  feeCalculation: feeCalculationColumns,
  proformaInvoice: proformaInvoiceColumns,
  invoice: invoiceColumns,
  portfolioInvoice: invoiceColumns,
  feeCollection: feeCollectionColumns,
  feePlanPortfolioMapping: feePlanPortfolioMappingColumns,
  autoImport: autoImportColumns,
  mutualFund: mutualFundListColumns,
  brokerIntegration: brokerIntegrationColumns,
  modelPortfolio: modelPortfolioColumns,
  leadNote: leadNoteColumns,
  leadMeetingList: leadMeetingListColumns,
  ClientRiskProfiling: ClientRiskProfilingColumns,
  mappedPortfolio: mappedPortfolioColumns,
  mapUnmapPortfolios: mappedPortfolioColumns,
  modelPortfolioOrderManager: modelPortfolioOrderManagerColumns,
  modelPortfolioVersionForm: modelPortfolioVersionFormColumns,
  stockUniverse: stockUniverseColumns,
  modelTradebook: modelTradebookColumns,
  modelBankLedger: modelBankLedgerColumns,
  portfolioHoldingTransaction: portfolioHoldingTransactionColumns,
  longTermHolding: longTermHoldingColumns,
  portfolioGroupHolding: portfolioGroupHoldingColumns,
  archiveManagerPage: archiveManagerPageColumns,
  familyAccess: familyAccessColumns,
  addModelPortfoliol: addModelPortfoliolColumns,
  selectPortfolioModal: selectPortfolioModalColumns,
  totalOrders: totalOrdersColumns,
  avlCashBalance: avlCashBalance,
  incomeExpense: avlCashBalance,
  portfolioIncomeExpense: avlCashBalance,
  uploadManagerViewUpload: {
    ...importManagerListColumns,
    ...importManagerColumns,
    ...uploadManagerColumns,
  },
  bankLedger: bankLedgerColumns,
  portfolioStatusModal: bankLedgerColumns,
  executionSheet: executionSheetColumns,
  walletHistory: walletHistoryColumns,
  walletFullHistory: walletFullHistoryColumns,
  rightIssue: rightIssueColumns,
  editRightIssue: editRightIssueColumns,
  bankInfo: bankInfoColumns,
  demateInfo: demateInfoColumns,
  portfolioImportManager: portfolioImportManagerColumns,
  portfolioBankLedger: portfolioBankLedgerColumns,
  portfolioTradeBook: portfolioTradeBookColumns,
  portfolioAccess: portfolioAccessColumns,
  portfolioInterest: portfolioInterestColumns,
  holdingDetails: holdingDetailsColumns,
  portfolioCashEntry: portfolioCashEntryColumns,
  editFeePlanPortfolio: editFeePlanPortfolioColumns,
  employeeClientAccess: employeeClientAccess,
  portfolioHoldingList: portfolioHoldingsColumns,
  customAgreement: customAgreementColumns,
  customBroker: { ...importManagerListColumns, ...importManagerColumns },
  historicalSheetTable: historicalImportColumns,
  reportScheduler: reportListColumns,
  reconMangerViewUpload: reconManagerViewUploadColumns,
  portfolioFeePlans: portfolioFeePlansColumns,
  portfolioFeeCollection: portfolioFeeCollectionColumns,
  portfolioFeeCalculation: portfolioFeeCollectionColumns,
  equityBasketOrderManagerPortfolio: equityBasketOrderPortfolio,
  sheetPortfolioMappings: sheetPortfolioMappingsColumns,
  marketingMaterial: marketingMaterialColumns,
  portfolioMutualFunds: portfolioMutualFundsColumns,
  portfolioStatus: portfolioStatusColumns,
  tradesOfPortfolio: tradesOfPortfolioColumns,
  revenueMandate: revenueMandateColumns,
  mandatePresentation: mandatePresentationColumns,
  mandatePresentationTransaction: mandatePresentationTransactionColumns,
  portfolioCalculation: feeCalculationColumns,
  portfolioProforma: proformaInvoiceColumns,
  portfolioFamilyAccess: portfolioFamilyAccessColumns,
  familyAdminAccess: familyAdminAccessColumns,
  portfolioHoldingsTable: portfolioHoldingsTableColumns,
  privateEquity: privateEquityTableColumns,
  partner: partnerTableColumns,
  portfolioPrivateEquityList: tradeBookListColumns,
  portfolioFDList: tradeBookListColumns,
  portfolioAIFList: tradeBookListColumns,
  partnerClient: partnerClientListColumns,
  subscribedPortfolio: subscribedPortfolioListColumns,
  coupon: couponColumns,
};
