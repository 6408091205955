import useDocumentTitle from "utils/useDocumentTitle";
import ConfigurePaymentForm from "./ConfigurePaymentForm";

const PaymentGateway = () => {
  useDocumentTitle("Payment Gateway");

  return <ConfigurePaymentForm />;
};

export default PaymentGateway;
