import { EditIcon, GKButton, Grid2, Show, Typography } from "components";
import { PartnerByIdResponseInterface } from "interfaces/Partner";
import AssignRoleModal from "Pages/Organisations/AssignRoleModal";
import { useState } from "react";
import { PARTNER_ASSIGN_ROLE } from "utils/apiRoutes";
import { matchDate } from "utils/helpers";
import { QueryKeys } from "utils/queryKey";
import PartnerModal from "./PartnerModal";

interface Props {
  data: PartnerByIdResponseInterface;
  id: string;
}

const DetailsHeaderSection = (props: Props) => {
  const { data, id } = props;

  const [openEditModal, setOpenEditModal] = useState(false);
  const [assignRoleModal, setAssignRoleModal] = useState(false);

  return (
    <Grid2 container alignItems={"center"}>
      <Grid2 container size={8}>
        <Grid2 size={12}>
          <Typography
            fontWeight={600}
            fontSize={20}
            color={"#0D121C"}
            gap={1}
            display={"flex"}
            alignItems={"center"}
          >
            {data?.personalDetails?.name || "-"}
            <Typography fontWeight={500} fontSize={12}>
              {data?.code}
            </Typography>
          </Typography>
        </Grid2>
        <Grid2 size={12} display={"flex"} alignItems={"center"} gap={2}>
          <Typography
            color={"#202939"}
            fontWeight={500}
            display={"flex"}
            alignItems={"center"}
            gap={0.5}
            fontSize={12}
          >
            <Typography color="#697586" fontWeight={400}>
              Phone:
            </Typography>
            {data?.user?.phone || "-"}
          </Typography>
          <Typography
            color={"#202939"}
            fontWeight={500}
            display={"flex"}
            alignItems={"center"}
            gap={0.5}
            fontSize={12}
          >
            <Typography color="#697586" fontWeight={400}>
              Email:
            </Typography>
            {data?.personalDetails?.email || "-"}
          </Typography>
          <Typography
            color={"#202939"}
            fontWeight={500}
            display={"flex"}
            alignItems={"center"}
            gap={0.5}
            fontSize={12}
          >
            <Typography color="#697586" fontWeight={400}>
              Joining Date:
            </Typography>
            {matchDate(data?.joiningDate)}
          </Typography>
        </Grid2>
      </Grid2>
      <Grid2
        container
        size={4}
        display={"flex"}
        justifyContent={"flex-end"}
        alignItems={"center"}
        gap={1}
      >
        {/* <FormControlLabel
          sx={{
            "&.MuiFormControlLabel-labelPlacementEnd": {
              marginRight: "0px !important",
            },
          }}
          control={<Switch checked={false} />}
          label="Active"
        /> */}
        <GKButton
          variant="outlined"
          color="dark"
          startIcon={<EditIcon size={14} />}
          onClick={() => setOpenEditModal(true)}
        >
          Profile details
        </GKButton>
        <GKButton
          variant="outlined"
          color="dark"
          onClick={() => setAssignRoleModal(true)}
        >
          Assign Role
        </GKButton>
      </Grid2>

      <Show.When isTrue={openEditModal}>
        <PartnerModal
          open={openEditModal}
          setOpen={setOpenEditModal}
          isEdit
          data={data}
        />
      </Show.When>

      <Show.When isTrue={assignRoleModal}>
        <AssignRoleModal
          open={assignRoleModal}
          setOpen={setAssignRoleModal}
          getAssignRoleApi={{
            key: ["ASSIGN_ROLE_FOR_PARTNER"],
            route: PARTNER_ASSIGN_ROLE(id),
          }}
          roleType="partner"
          refetchQueryName={QueryKeys.PARTNER_PERMISSION}
        />
      </Show.When>
    </Grid2>
  );
};

export default DetailsHeaderSection;
