/* eslint-disable import/no-cycle */
import { useAppContext } from "AppContext";
import AdvisorOnboarding from "Pages/AdvisorOnboarding";
import ChangePasswordModal from "Pages/Authentication/ChangePasswordModal";
import axios from "axios";
import {
  Avatar,
  Box,
  CgNotes,
  CustomeBreadCrumbs,
  Fade,
  Grid2,
  IconButton,
  IoPersonAddOutline,
  Popper,
  ProfileMenu,
  Show,
  SideMenu,
  SimpleLoader,
  Tooltip,
  Typography,
} from "components";
import { motion } from "framer-motion";
import { useClickOutside } from "hooks/useClickOutside";
import { useDashboardLayout } from "hooks/useDashboardLayout";
import { useMutation } from "hooks/useQueries";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { SIDE_BAR_OPEN } from "store/slices/appSlice";
import { useAppDispatch, useAppSelector } from "store/store";
import { ThemeContainer } from "utils/Theme";
import { profileBorderColorRoleWise } from "utils/colorHelper";
import { UserRoleTypes } from "utils/common";
import AvatarImg from "../assets/avatar.png";

interface DashboardLayoutProps {
  title: string;
  loading?: boolean;
  onlyChildren?: boolean;
  children?: React.ReactNode;
  toolbar?: React.ReactNode;
}

const DashboardLayout = (props: DashboardLayoutProps) => {
  const { theme } = ThemeContainer();

  const { toolbar, children, loading, onlyChildren = false } = props;

  const sidebarOpen = useAppSelector((state) => state?.app?.sidebarOpen);
  const documentTitle = useAppSelector((state) => state?.app?.documentTitle);
  const dispatch = useAppDispatch();

  const { outSideModalOpen, setOutSideModalOpen, popperRef } =
    useClickOutside();

  const navigate = useNavigate();

  const { items } = useDashboardLayout();

  const {
    state: { userData: userInfo },
  } = useAppContext();

  const [openAdvisorAddModal, setOpenAdvisorAddModal] = useState(false);

  const [openPasswordModal, setOpenPasswordModal] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const { mutate: handleGetOPT, isLoading: otpLoading } = useMutation({
    mutationFn: () =>
      axios.get(
        `${process.env.REACT_APP_BASE_URL}/otp/${userInfo?.countryCode}-${userInfo?.phone}/send/`,
      ),
    onSuccess: () => {
      setOpenPasswordModal(true);
    },
  });

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setOutSideModalOpen(true);
  };

  const canBeOpen = outSideModalOpen && Boolean(anchorEl);

  const id = canBeOpen ? "transition-popper" : undefined;

  return (
    <>
      <Show.When isTrue={onlyChildren}>{children}</Show.When>
      <Show.When isTrue={!onlyChildren}>
        <Grid2 container position={"relative"}>
          <Grid2
            size={12}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
            px={1}
            position={"sticky"}
            top={0}
            maxWidth={"100vw"}
            zIndex={1}
            height={"66px"}
            sx={{
              background: theme.palette.bgColor.main,
              borderBottom: "solid 1px",
              borderColor: theme.palette.grey[300],
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: 15,
                height: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  minWidth: "232px",
                  height: "100%",
                  borderRight: `1px solid ${theme.palette.grey[300]}`,
                }}
              >
                <IconButton
                  className="outline"
                  onClick={() => dispatch(SIDE_BAR_OPEN(!sidebarOpen))}
                >
                  <img
                    src={
                      sidebarOpen
                        ? "/LandingPage/expandLess.svg"
                        : "/LandingPage/expandMore.svg"
                    }
                    alt=""
                  />
                </IconButton>
                <img
                  src={userInfo?.companyLogo || "/Logos/iconFullLogo.png"}
                  alt=""
                  style={{
                    transition: "all .4s linear",
                    padding: 16,
                    maxWidth: 174,
                    maxHeight: "-webkit-fill-available",
                  }}
                  draggable="false"
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: 1,
                }}
              >
                <Typography fontWeight={600}>
                  {documentTitle || "Dashboard"}
                </Typography>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <CustomeBreadCrumbs />

              <span>{toolbar}</span>
              {userInfo?.grantAccess && (
                <Tooltip title="Notes" placement="bottom" arrow>
                  <IconButton
                    className="outline"
                    style={{
                      borderRadius: 10,
                    }}
                    onClick={() => {
                      navigate("/app/advice-register-notes");
                    }}
                  >
                    <CgNotes />
                  </IconButton>
                </Tooltip>
              )}
              <Show.When
                isTrue={
                  userInfo?.userRole !== UserRoleTypes.client &&
                  userInfo?.grantAccess
                }
              >
                <Tooltip title="Client Onboarding" placement="bottom" arrow>
                  <IconButton
                    className="outline"
                    style={{
                      borderRadius: 10,
                    }}
                    onClick={() => {
                      setOpenAdvisorAddModal(true);
                    }}
                  >
                    <IoPersonAddOutline />{" "}
                  </IconButton>
                </Tooltip>
              </Show.When>

              <IconButton className="outline" onClick={handleClick}>
                <Avatar
                  alt=""
                  src={
                    userInfo?.profilePic?.includes("s3")
                      ? userInfo?.profilePic
                      : AvatarImg
                  }
                  sx={{
                    width: 50,
                    height: 50,
                    ".MuiAvatar-img": { borderRadius: "50%" },
                    p: "2px",
                    border: `2px solid ${profileBorderColorRoleWise(userInfo?.userRole)}`,
                  }}
                />
              </IconButton>
            </div>
          </Grid2>
          <Grid2 container size={12} maxWidth={"100vw"}>
            <div
              style={{
                background: theme.palette.light.main,
                display: "flex",
                maxWidth: "100%",
                minWidth: "100%",
              }}
            >
              <SideMenu menuList={items} />
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5 }}
                style={{
                  flexGrow: 1,
                  minWidth: 0,
                  padding: "8px 16px",
                  maxHeight: "calc(100vh - 82px)",
                  minHeight: "calc(100vh - 82px)",
                  overflow: "auto !important",
                }}
                className="overFlowAuto"
              >
                {loading ? <SimpleLoader /> : children}
              </motion.div>
            </div>
          </Grid2>
        </Grid2>
      </Show.When>
      <Popper
        id={id}
        open={outSideModalOpen}
        anchorEl={anchorEl}
        transition
        placement="bottom-start"
        style={{
          zIndex: 4,
          width: `260px`,
          marginTop: "2px",
        }}
        ref={popperRef}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Box
              sx={{
                borderRadius: "8px",
                bgcolor: theme.palette.light.main,
                boxShadow: `4px 2px 8px 0px rgba(0, 0, 0, 0.13)`,
                border: `1px solid`,
                borderColor: theme.palette.border.main,
              }}
            >
              <ProfileMenu
                handleGetOPT={handleGetOPT}
                otpLoading={otpLoading}
              />
            </Box>
          </Fade>
        )}
      </Popper>

      {openPasswordModal && (
        <ChangePasswordModal
          open={openPasswordModal}
          setOpen={setOpenPasswordModal}
        />
      )}

      <Show.When isTrue={openAdvisorAddModal}>
        <AdvisorOnboarding
          open={openAdvisorAddModal}
          setOpen={setOpenAdvisorAddModal}
        />
      </Show.When>
    </>
  );
};

export default DashboardLayout;
