import { useAppContext } from "AppContext";
import {
  GKButton,
  GKClientDataGrid,
  Grid2,
  StatusField,
  Typography,
} from "components";
import { useFetchQuery, useMutation } from "hooks/useQueries";
import { useParams } from "react-router-dom";
import axiosInstance from "utils/axiosInstance";
import { extraColumnsField, matchDate, refetchQuery } from "utils/helpers";

const CompliancePortfolios = () => {
  const { id } = useParams();

  const {
    state: { onBoardflowData },
  } = useAppContext();

  const { data } = useFetchQuery({
    route: `/client/${id}/onboard-details/`,
    key: ["GET_ALL_COMPLIANCE_BY_CLIENT_ID"],
  });

  const { mutate: handleMarkAsComplete } = useMutation({
    mutationKey: ["MARK_OVERALL_STATUS_COMPLETE"],
    mutationFn: (id: any) =>
      axiosInstance.post(`/portfolios/${id}/onboard-details/`),

    onSuccess: () => {
      refetchQuery("GET_ALL_COMPLIANCE_BY_CLIENT_ID");
    },
  });

  return (
    <GKClientDataGrid
      rows={data?.data?.data || []}
      name="compliancePortfolio"
      columns={[
        {
          ...extraColumnsField("agreement Date", "data", 1, 1),
          renderCell: (params) => {
            const { row } = params;
            return (
              <Typography>
                {matchDate(
                  row[onBoardflowData?.onboardingDate || "agreementDate"] || "",
                )}
              </Typography>
            );
          },
        },
        {
          ...extraColumnsField("Portfolio Name / Code", "ame", 150),
          renderCell: (params) => {
            return (
              <Grid2
                container
                display={"flex"}
                flexDirection={"column"}
                alignItems={"flex-start"}
              >
                <Typography fontWeight={700}>{params?.row?.name}</Typography>
                <Typography>{params?.row?.portfolioCode}</Typography>
              </Grid2>
            );
          },
        },
        {
          ...extraColumnsField("Partner", "partnerName", 1, 1),
          renderCell: (params) => {
            const { row } = params;
            return <Typography>{row?.partnerName || "-"}</Typography>;
          },
        },
        {
          ...extraColumnsField("risk Status", "riskStatus", 1, 1),
          renderCell: (params) => {
            const { row } = params;
            return <StatusField statusString={row?.riskStatus || "Pending"} />;
          },
        },
        {
          ...extraColumnsField("agreement Status", "agreementStatus", 1, 1),
          renderCell: (params) => {
            const { row } = params;
            return (
              <StatusField statusString={row?.agreementStatus || "Pending"} />
            );
          },
        },
        {
          ...extraColumnsField("proof Status", "proofStatus", 1, 1),
          renderCell: (params) => {
            const { row } = params;
            return <StatusField statusString={row?.proofStatus || "Pending"} />;
          },
        },
        {
          ...extraColumnsField(
            "subscription Status",
            "subscriptionStatus",
            1,
            1,
          ),
          renderCell: (params) => {
            const { row } = params;
            return (
              <StatusField
                statusString={row?.subscriptionStatus || "Pending"}
              />
            );
          },
        },

        {
          ...extraColumnsField("overallStatus", "overallStatus", 1, 1),
          renderCell: (params) => {
            const { row } = params;
            return (
              <StatusField statusString={row?.overallStatus || "Pending"} />
            );
          },
        },
        {
          ...extraColumnsField("Actions", "action", 100, 1),
          align: "right",
          headerAlign: "right",
          renderCell: (params) => {
            return (
              <GKButton
                variant="link"
                onClick={() => {
                  handleMarkAsComplete(params.row.id);
                }}
              >
                Mark as completed
              </GKButton>
            );
          },
        },
      ]}
    />
  );
};

export default CompliancePortfolios;
