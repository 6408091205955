import { LoadingButton } from "@mui/lab";
import {
  GKDatePicker,
  GKModal,
  GKTextField,
  Grid2,
  PhoneInput,
} from "components";
import dayjs from "dayjs";
import useAddEmployee from "hooks/useAddEmployee";
import { useState } from "react";
import { Controller } from "react-hook-form";
import { GKModalProps } from "utils/commonInterface";

const AddEmployee = ({ open, setOpen }: GKModalProps) => {
  const { isSubmitting, form, onSubmit } = useAddEmployee({
    setOpen,
  });

  const [hasError, setHasError] = useState(false);

  form.watch();

  return (
    <GKModal
      modalTitle="Add New Admin"
      open={open}
      setOpen={setOpen}
      footer={
        <Grid2 size={12} textAlign={"right"}>
          <LoadingButton
            type="submit"
            variant="contained"
            size="medium"
            form="employee-form"
            disabled={hasError}
            loading={isSubmitting}
          >
            {"Submit"}
          </LoadingButton>
        </Grid2>
      }
    >
      <form id="employee-form" onSubmit={onSubmit}>
        <Grid2 container spacing={1}>
          <Grid2 size={4}>
            <Controller
              name="user.personalDetails.name"
              control={form.control}
              render={({ field, fieldState: { error } }) => (
                <GKTextField
                  {...field}
                  inputLabel="Name"
                  requiredField
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
          </Grid2>
          <Grid2 size={4}>
            <Controller
              name="user.personalDetails.email"
              control={form.control}
              render={({ field, fieldState: { error } }) => (
                <GKTextField
                  {...field}
                  requiredField
                  inputLabel="Email"
                  placeholder="Email"
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
          </Grid2>
          <Grid2 size={4}>
            <Controller
              name="user.phone"
              control={form.control}
              render={({ field, fieldState: { error } }) => (
                <PhoneInput
                  {...field}
                  requiredField
                  onSelectChange={(event: any, val: any) => {
                    form.setValue("user.countryCode", val?.callingCodes);
                  }}
                  onTextChange={(event: any) => {
                    field.onChange(event.target.value);
                  }}
                  onTextValue={field.value}
                  onSelectValue={form.getValues("user.countryCode")}
                  error={!!error || hasError}
                  helperText={error?.message}
                  hasError={hasError}
                  setHasError={setHasError}
                />
              )}
            />
          </Grid2>
          <Grid2 size={4}>
            <Controller
              name="position"
              control={form.control}
              render={({ field }) => (
                <GKTextField {...field} inputLabel="Position" />
              )}
            />
          </Grid2>
          <Grid2 size={4}>
            <Controller
              name="salary"
              control={form.control}
              render={({ field }) => (
                <GKTextField {...field} type="number" inputLabel="Salary" />
              )}
            />
          </Grid2>
          <Grid2 size={4}>
            <Controller
              name="user.personalDetails.aadhar"
              control={form.control}
              render={({ field }) => (
                <GKTextField
                  {...field}
                  type="number"
                  inputLabel="Aadhar Card"
                />
              )}
            />
          </Grid2>
          <Grid2 size={4}>
            <Controller
              name="user.personalDetails.dateOfBirth"
              control={form.control}
              render={({ field }) => (
                <GKDatePicker
                  {...field}
                  inputLabel="Date of Birth"
                  value={dayjs(field.value)}
                  onChange={(value) => {
                    field.onChange(dayjs(value).format("YYYY-MM-DD"));
                  }}
                  minDate={dayjs("01-01-1901")}
                  slotProps={{
                    textField: {
                      error: false,
                      helperText: false,
                    },
                  }}
                />
              )}
            />
          </Grid2>
          <Grid2 size={4}>
            <Controller
              name="user.personalDetails.emergencyContact"
              control={form.control}
              render={({ field }) => (
                <GKTextField {...field} inputLabel="Emergency Contact Name" />
              )}
            />
          </Grid2>
          <Grid2 size={4}>
            <Controller
              name="user.personalDetails.emergencyContactNum"
              control={form.control}
              rules={{
                minLength: {
                  value: 10,
                  message: "Minimum length is 10 characters",
                },
                maxLength: {
                  value: 10,
                  message: "Maximum length is 10 characters",
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <GKTextField
                  {...field}
                  type="number"
                  inputLabel="Emergency Contact Number"
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
          </Grid2>
        </Grid2>
      </form>
    </GKModal>
  );
};

export default AddEmployee;
