/* eslint-disable react/destructuring-assignment */
import { GridColDef, GridRowParams } from "@mui/x-data-grid-pro";
import { GKClientDataGrid, GKDataGrid, Grid2 } from "components";
import useGenerateQueryParams from "hooks/useGenerateQueryParams";
import { useFetchQuery } from "hooks/useQueries";
import { moneyCommaSeparator } from "utils";
import { extraColumnsField, matchDate } from "utils/helpers";
import useDocumentTitle from "utils/useDocumentTitle";

interface Props {
  id: string;
}

const ClientTab = (props: Props) => {
  useDocumentTitle("Partner Clients");

  const { id } = props;

  const requestBody = useGenerateQueryParams("partnerClient");

  const { data, isFetching } = useFetchQuery({
    key: ["PARTNER_BY_CLIENTS", requestBody],
    route: `/partner/${id}/clients/`,
    requestBody,
  });

  const PortfolioDetailTable = (params: GridRowParams) => {
    const { row } = params;

    const columns: GridColDef[] = [
      {
        ...extraColumnsField("Portfolio Name / Code", "clientName", 250, 1),
        valueGetter: (params) => `${params?.row?.name} / ${params?.row?.code}`,
      },
      {
        ...extraColumnsField("Fee Plan", "feePlan", 150, 1),
        valueGetter: (row) => row?.row?.feePlan || "-",
      },
      {
        ...extraColumnsField("Sharing Structure", "sharingStructure", 150, 1),
        valueGetter: (row) => row?.row?.sharingStructure || "-",
      },
      {
        ...extraColumnsField("Billing Date", "billingDate", 150, 1),
        valueGetter: (row) => matchDate(row?.row?.billingDate),
      },
    ];

    return (
      <Grid2 container p={2}>
        <Grid2 size={12}>
          <GKClientDataGrid
            name="partnerClient"
            showHeaderGrid={false}
            columnsButton={false}
            columns={columns}
            rows={row?.portfolios || []}
          />
        </Grid2>
      </Grid2>
    );
  };

  const columns: GridColDef[] = [
    {
      ...extraColumnsField("Client Name / Code", "name", 200, 1),
      editable: false,
      valueGetter: (params) => `${params?.row?.name} / ${params?.row?.code}`,
    },
    {
      ...extraColumnsField("Total AUM", "totalAum", 150, 1),
      type: "number",
      valueGetter: (row) => row?.row?.totalAum,
      valueFormatter: (params) => moneyCommaSeparator(params.value) || 0,
    },
    {
      ...extraColumnsField("XIRR", "xirr", 100, 1),
      type: "number",
      valueGetter: (row) => row?.row?.xirr,
      valueFormatter: (params) => moneyCommaSeparator(params.value) || 0,
    },
    {
      ...extraColumnsField("Billed Revenue", "billedRevenue", 150, 1),
      type: "number",
      valueGetter: (row) => row?.row?.billedRevenue,
      valueFormatter: (params) => moneyCommaSeparator(params.value) || 0,
    },
    {
      ...extraColumnsField("Commission Amount", "commissionAmount", 150, 1),
      type: "number",
      valueGetter: (row) => row?.row?.commissionAmount,
      valueFormatter: (params) => moneyCommaSeparator(params.value) || 0,
    },
    {
      ...extraColumnsField("Billing Date", "billingDate", 150, 1),
      valueGetter: (row) => matchDate(row?.row?.billingDate),
    },
    {
      ...extraColumnsField("Portfolio Count", "portfolioCount", 150, 1),
      type: "number",
    },
  ];

  return (
    <GKDataGrid
      loading={isFetching}
      rows={data?.data?.data || []}
      columns={columns}
      maxHeight={"calc(100vh - 321px)"}
      getDetailPanelContent={PortfolioDetailTable}
      totalCount={data?.data?.totalCount || 0}
      zeroHolding
      name="partnerClient"
      tableName="partner_client_list"
    />
  );
};

export default ClientTab;
