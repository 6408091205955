import { GridColDef } from "@mui/x-data-grid-pro";
import {
  FaChartBar,
  GKClientDataGrid,
  GKSideModal,
  GKTypography,
  Grid2,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TbUserSquareRounded,
  Typography,
} from "components";
import { useFetchQuery } from "hooks/useQueries";
import DashboardLayout from "layouts/DashboardLayout";
import {
  handleReturnPositiveNegative,
  moneyCommaSeparator,
  numberToCurrency,
} from "utils";
import { LONG_TEAM_HOLDING_BY_PORTFOLIO_ID } from "utils/apiRoutes";
import { paletteLightTheme } from "utils/ColorTheme";
import { GKModalProps } from "utils/commonInterface";
import { extraColumnsField, matchDate } from "utils/helpers";
import { ThemeContainer } from "utils/Theme";

interface Props {
  rowData: any;
}

const HoldingDetails = (props: GKModalProps & Props) => {
  const { open, setOpen, rowData } = props;
  const { theme } = ThemeContainer();

  const { portfolioId, gcode } = rowData;

  const { data: holdingData, isFetching } = useFetchQuery({
    key: ["GET_COMPANY_DETAILS_DATA"],
    route: `/holdings/${gcode}/portfolios/?portfolios=[${portfolioId}]`,
    enabled: !!portfolioId,
  });

  const { data, isFetching: longTermHoldingLoading } = useFetchQuery({
    key: ["LONG_TERM_HOLDING"],
    route: LONG_TEAM_HOLDING_BY_PORTFOLIO_ID(portfolioId),
    requestBody: {
      gcode: gcode,
    },
    enabled: !!gcode && !!portfolioId,
  });

  const columns: GridColDef[] = [
    {
      ...extraColumnsField("DATE", "purchaseDate", 150, 1),
      valueGetter: (row) => row?.row?.purchaseDate || "N/A",
      valueFormatter: (value) => matchDate(value.value),
    },
    {
      ...extraColumnsField("LT QTY", "rawQuantity", 150, 1),
      type: "number",
      valueGetter: (value) => moneyCommaSeparator(value.value),
    },
    {
      ...extraColumnsField("PRICE", "avgUnitCost", 150, 1),
      type: "number",
      valueGetter: (value) => moneyCommaSeparator(value.value),
    },
    {
      ...extraColumnsField("LT GAIN", "longTermGain", 120, 1),
      type: "number",
      valueGetter: (value) => moneyCommaSeparator(value.value),
    },
    {
      ...extraColumnsField("LT LOSS", "longTermLoss", 120, 1),
      type: "number",
      valueGetter: (value) => moneyCommaSeparator(value.value),
    },
    {
      ...extraColumnsField("ST GAIN", "shortTermGain", 120, 1),
      type: "number",
      valueGetter: (value) => moneyCommaSeparator(value.value),
    },
    {
      ...extraColumnsField("ST LOSS", "shortTermLoss", 120, 1),
      type: "number",
      valueGetter: (value) => moneyCommaSeparator(value.value),
    },
    {
      ...extraColumnsField("TOTAL", "unrealisedGain", 120, 1),
      type: "number",
      valueGetter: (params) => params.row.unrealisedGain,
      valueFormatter: (params) => moneyCommaSeparator(params.value),
      renderCell: (value) => {
        const { unrealisedGain } = value?.row;
        return (
          <Typography color={handleReturnPositiveNegative(unrealisedGain)}>
            {moneyCommaSeparator(unrealisedGain)}
          </Typography>
        );
      },
    },
  ];

  return (
    <DashboardLayout title="Holding Inventory">
      <GKSideModal
        width="60%"
        open={open}
        setOpen={setOpen}
        onClose={() => {
          setOpen(false);
        }}
        modalTitle={"Holding Inventory"}
      >
        <Grid2 container spacing={1}>
          <Grid2 size={12}>
            <Typography fontWeight={800} fontSize={20}>
              {holdingData?.data?.fullName}
            </Typography>
          </Grid2>
          <Grid2 size={12}>
            <Typography
              color="#697586"
              display={"flex"}
              alignItems={"center"}
              gap={1}
              fontSize={12}
              fontWeight={500}
            >
              ₹ CMP :{" "}
              <Typography color="#222" fontSize={12} fontWeight={500}>
                {holdingData?.data?.cmp}
              </Typography>
              <span
                style={{
                  color: handleReturnPositiveNegative(
                    holdingData?.data?.todaysGainPercentage,
                  ),
                  fontSize: 12,
                  fontWeight: 400,
                }}
              >
                ({holdingData?.data?.todaysGainPercentage}%)
              </span>
            </Typography>
          </Grid2>
          <Grid2 size={12} display={"flex"} alignItems={"center"} gap={1}>
            <Typography
              sx={{
                display: "flex",
                alignItems: "center",
              }}
              color={theme.palette.secondaryText.main}
              fontSize={12}
            >
              <TbUserSquareRounded size={14} style={{ marginRight: 4 }} />
              Client Name:
              <span
                style={{
                  color: "#202939",
                  marginLeft: 4,
                }}
              >
                {holdingData?.data?.clientName}
              </span>
            </Typography>
            <Typography
              sx={{
                display: "flex",
                alignItems: "center",
              }}
              color={theme.palette.secondaryText.main}
              fontSize={12}
            >
              <FaChartBar size={14} style={{ marginRight: 4 }} />
              Portfolio Name/Code:
              <span
                style={{
                  color: "#202939",
                  marginLeft: 4,
                }}
              >
                {holdingData?.data?.portfolioName}/
                {holdingData?.data?.portfolioCode}
              </span>
            </Typography>
          </Grid2>
          <Grid2 size={12} mt={1}>
            <TableContainer>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell
                      style={{
                        borderRight: `solid 1px ${paletteLightTheme.palette.grey[300]}`,
                        borderBottom: "none",
                        padding: 16,
                        width: "22.5%",
                      }}
                    >
                      <GKTypography
                        fontSize={12}
                        color={theme.palette.secondaryText.main}
                      >
                        Holding Quantity
                      </GKTypography>
                      <GKTypography
                        fontWeight={600}
                        fontSize={18}
                        loading={isFetching}
                      >
                        {holdingData?.data?.rawQuantity || 0}
                      </GKTypography>
                    </TableCell>
                    <TableCell
                      style={{
                        borderRight: `solid 1px ${paletteLightTheme.palette.grey[300]}`,
                        borderBottom: "none",
                        padding: 16,
                        width: "22.5%",
                      }}
                    >
                      <Typography
                        fontSize={12}
                        color={theme.palette.secondaryText.main}
                      >
                        Avg Buy Price
                      </Typography>
                      <GKTypography
                        fontWeight={600}
                        fontSize={18}
                        loading={isFetching}
                      >
                        {numberToCurrency(holdingData?.data?.avgBuyPrice || 0)}
                      </GKTypography>
                    </TableCell>
                    <TableCell
                      style={{
                        borderRight: `solid 1px ${paletteLightTheme.palette.grey[300]}`,
                        borderBottom: "none",
                        padding: 16,
                        width: "22.5%",
                      }}
                    >
                      <Typography
                        fontSize={12}
                        color={theme.palette.secondaryText.main}
                      >
                        Invested Value
                      </Typography>
                      <GKTypography
                        fontWeight={600}
                        fontSize={18}
                        loading={isFetching}
                      >
                        {numberToCurrency(
                          holdingData?.data?.investedAmount || 0,
                        )}
                      </GKTypography>
                    </TableCell>
                    <TableCell
                      style={{
                        borderRight: `solid 1px ${paletteLightTheme.palette.grey[300]}`,
                        borderBottom: "none",
                        padding: 16,
                        width: "22.5%",
                      }}
                    >
                      <Typography
                        fontSize={12}
                        color={theme.palette.secondaryText.main}
                      >
                        Total Holding Value
                      </Typography>
                      <GKTypography
                        fontWeight={600}
                        fontSize={18}
                        loading={isFetching}
                      >
                        {numberToCurrency(
                          holdingData?.data?.currentAmount || 0,
                        )}
                      </GKTypography>
                    </TableCell>
                    <TableCell
                      style={{
                        borderBottom: "none",
                        padding: 16,
                        width: "10%",
                      }}
                    >
                      <Typography
                        fontSize={12}
                        color={theme.palette.secondaryText.main}
                      >
                        XIRR
                      </Typography>
                      <GKTypography
                        fontWeight={600}
                        fontSize={18}
                        loading={isFetching}
                      >
                        {holdingData?.data?.xirr || 0}%
                      </GKTypography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid2>

          <Grid2 size={12} mt={1}>
            <GKClientDataGrid
              loading={longTermHoldingLoading}
              columns={columns}
              columnsButton={false}
              rows={
                data?.data?.data.map((dataItem: any, index: number) => {
                  return {
                    id: index,
                    ...dataItem,
                  };
                }) || []
              }
              name="netUnrealisedGain"
              headerComponent={
                <Grid2 container display={"inline-grid"}>
                  <Typography fontSize={12}> * LT : Long Term</Typography>
                  <Typography fontSize={12}> * ST : Short Term</Typography>
                </Grid2>
              }
              onRowClick={(params) => console.log(params)}
            />
          </Grid2>
        </Grid2>
      </GKSideModal>
    </DashboardLayout>
  );
};

export default HoldingDetails;
