import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HighchartsMore from "highcharts/highcharts-more";
import exporting from "highcharts/modules/exporting";
import HighchartsNoData from "highcharts/modules/no-data-to-display";
import SolidGauge from "highcharts/modules/solid-gauge";
import StockModule from "highcharts/modules/stock";
import HighchartsSunburst from "highcharts/modules/sunburst";
import { useEffect, useRef } from "react";
import { ThemeContainer } from "utils/Theme";

interface Props {
  chartOptions: Highcharts.Options | any;
  rangeSelector?: boolean;
  navigator?: boolean;
  loading?: boolean;
  constructorType?: string;
  containerProps?: any;
}

HighchartsSunburst(Highcharts);
HighchartsNoData(Highcharts);
HighchartsMore(Highcharts);
StockModule(Highcharts);
SolidGauge(Highcharts);
exporting(Highcharts);

const GKCharts = ({
  chartOptions,
  rangeSelector = false,
  navigator = false,
  loading = false,
  constructorType,
  containerProps,
}: Props) => {
  const { theme } = ThemeContainer();

  const chartRef = useRef<HighchartsReact.RefObject>(null);

  const options: Highcharts.Options = {
    lang: { noData: "No data available" },
    noData: { style: { fontWeight: "bold", fontSize: "15px" } },
    accessibility: {
      enabled: false,
    },
    exporting: {
      enabled: false,
    },
    credits: {
      text: "",
      enabled: false,
    },
    rangeSelector: {
      enabled: rangeSelector,
      inputStyle: {
        color: theme.palette.primary.main,
        fontWeight: "normal",
      },
      labelStyle: {
        color: theme.palette.primary.main,
        fontWeight: "normal",
      },
      buttonTheme: {
        width: 50,
        fill: `#ffffff`,
        style: {
          background: theme.palette.light.main,
          color: theme.palette.grey[600],
          fontWeight: "normal",
          textTransform: "uppercase",
        },
        states: {
          select: {
            fill: `#F8FAFC`,
            style: {
              fontWeight: "normal",
              color: theme.palette.grey[900],
            },
          },
        },
      },
    },
    navigator: {
      enabled: navigator,
      height: 30,
      xAxis: {
        type: "datetime",
        dateTimeLabelFormats: {
          day: "%e of %b, %Y",
        },
      },
    },
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 500,
          },
          chartOptions: {
            legend: {
              layout: "horizontal",
              align: "center",
              verticalAlign: "bottom",
            },
          },
        },
      ],
    },
  };

  useEffect(() => {
    const chart = chartRef.current?.chart;
    if (chart) {
      if (loading) {
        chart.showLoading(`Please wait we are fetching your data...`);
      } else {
        chart.hideLoading();
      }
    }
  }, [loading]);

  return (
    <HighchartsReact
      constructorType={constructorType}
      highcharts={Highcharts}
      ref={chartRef}
      containerProps={containerProps}
      options={{
        ...chartOptions,
        ...options,
        series: loading ? [] : chartOptions.series,
      }}
    />
  );
};

export default GKCharts;
