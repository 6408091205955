import { LoadingButton } from "@mui/lab";
import { GKButton, GKEditor, GKModal, GKTextField, Grid2 } from "components";
import { useMutation } from "hooks/useQueries";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import axiosInstance from "utils/axiosInstance";
import { GKModalProps } from "utils/commonInterface";
import { refetchQuery } from "utils/helpers";

interface Props {
  getFeePlanById: any;
}

const EditManagementFeeModal = (props: Props & GKModalProps) => {
  const { id } = useParams();
  const { open, setOpen, getFeePlanById } = props;

  const form = useForm({
    defaultValues: {
      name: getFeePlanById?.data?.data?.name || "",
      description: getFeePlanById?.data?.data?.description || "",
    },
  });

  const { mutate: updateFeePlan, isLoading: updateFeePlanLoading } =
    useMutation({
      mutationKey: ["UPDATE_FEE_PLAN"],
      mutationFn: (data) => axiosInstance.patch(`/fee-plans/${id}/`, data),
      onSuccess: () => {
        refetchQuery("GET_FEE_PLAN_BY_ID");
        setOpen(false);
      },
    });

  return (
    <GKModal
      open={open}
      setOpen={setOpen}
      modalTitle={"Edit Fee Plan"}
      footer={
        <Grid2 container display={"flex"} justifyContent={"flex-end"} gap={1}>
          <GKButton variant="outlined">Cancel</GKButton>
          <LoadingButton
            type="submit"
            variant="contained"
            form="fee-plan-update-form"
            size="medium"
            loading={updateFeePlanLoading}
          >
            Save
          </LoadingButton>
        </Grid2>
      }
    >
      <form
        id="fee-plan-update-form"
        onSubmit={form.handleSubmit((values: any) => updateFeePlan(values))}
      >
        <Grid2 container spacing={1}>
          <Grid2 size={12}>
            <Controller
              name="name"
              control={form.control}
              render={({ field }) => (
                <GKTextField
                  {...field}
                  inputLabel="Fee Plan Name"
                  name="name"
                />
              )}
            />
          </Grid2>
          <Grid2 size={12}>
            <Controller
              name="description"
              control={form.control}
              render={({ field }) => (
                <GKEditor
                  value={field.value}
                  onChange={field.onChange}
                  inputLabel="Description"
                />
              )}
            />
          </Grid2>
        </Grid2>
      </form>
    </GKModal>
  );
};

export default EditManagementFeeModal;
