import { LoadingButton } from "@mui/lab";
import { grey } from "@mui/material/colors";
import { useIsMutating } from "@tanstack/react-query";
import { useAppContext } from "AppContext";
import {
  Box,
  Button,
  Divider,
  GKTypography,
  Grid2,
  IconButton,
  MdClose,
  Modal,
  RiListRadio,
  Show,
  Tab,
  Tabs,
  Typography,
} from "components";
import { useFetchQuery, useMutation } from "hooks/useQueries";
import { AdvisorOnboardFormInterface } from "interfaces/OnboardingInterface";
import { RiskProfileListInterface } from "interfaces/RiskProfileInterface";
import { split, sum } from "lodash";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { CgCreditCard, CgFileDocument } from "react-icons/cg";
import { HiOutlineCheckBadge } from "react-icons/hi2";
import { PiCardsThree, PiUserCheck, PiUserRectangle } from "react-icons/pi";
import { RiCheckDoubleLine } from "react-icons/ri";
import { TbPigMoney } from "react-icons/tb";
import { numberToCurrency } from "utils";
import { GET_RISK_PROFILE } from "utils/apiRoutes";
import axiosInstance from "utils/axiosInstance";
import { UserRoleTypes } from "utils/common";
import { GKModalProps } from "utils/commonInterface";
import { refetchQuery } from "utils/helpers";
import { QueryKeys } from "utils/queryKey";
import { ThemeContainer } from "utils/Theme";
import AdvisorOnboardingAgreement from "./AdvisorOnboardingAgreement";
import AdvisorOnboardingClientDetails from "./AdvisorOnboardingClientDetails";
import AdvisorOnboardingCompleted from "./AdvisorOnboardingCompleted";
import AdvisorOnboardingEquityBasket from "./AdvisorOnboardingEquityBasket";
import AdvisorOnboardingInvestments from "./AdvisorOnboardingInvestments";
import AdvisorOnboardingKYC from "./AdvisorOnboardingKYC";
import AdvisorOnboardingPayment from "./AdvisorOnboardingPayment";
import AdvisorOnboardingRiskProfile from "./AdvisorOnboardingRiskProfile";
import AdvisorOnboardingTypeSelect from "./AdvisorOnboardingTypeSelect";

const AdvisorOnboarding = ({ open, setOpen }: GKModalProps) => {
  const { theme } = ThemeContainer();

  const {
    state: { userData, onBoardflowData },
  } = useAppContext();

  const [hasError, setHasError] = useState(false);

  // XXX===XXX===XXX===XXX===XXX===XXX===XXX===XXX===XXX===XXX===XXX===
  const form = useForm<AdvisorOnboardFormInterface>({
    defaultValues: {
      activeStep: 0,
      skipAdvanceFee: false,
      type: "",
      personalDetails: {
        email: "",
        name: "",
        gender: "",
        maritalStatus: "",
        fatherName: "",
        motherName: "",
        dateOfBirth: "",
        address: "",
        city: "",
        state: "",
        country: "",
        pincode: "",
        income: "",
        occupation: "",
        pan: "",
        aadhar: "",
        emergencyContact: "",
        emergencyContactNum: "",
      },
      branch: "",
      family: [],
      signatureType: onBoardflowData?.defaultAdvisorSignType || "",
      isExistingClient: false,
      phone: "",
      countryCode: "+91",
      bankAccounts: [],
      dematAccounts: [],
      nominees: [],
      kyc: null,
      document: [],
      id: "",
      sendOptions: "",
      manual: "",
      identification_type: onBoardflowData?.kycIdentificationType || "",
      riskProfile: null,
      agreement: "",
      basketObject: null,
      partner: null,
      portfolioObject: null,
      riskProfileObject: null,
      selectedFeePlan: null,
      planDataObject: {
        aumCash: "",
        showHoldingsModal: false,
        aumBasedPlan: "",
        subscriptionPlan: null,
        holdingsValue: null,
        subscriptionPlanAccountType: null,
        holdingsProofs: [],
        cashProofs: [],
        plansHoldings: [
          {
            gridCode: "",
            quantity: 0,
            cmp: "",
            id: 0,
          },
        ],
      },
    },
  });
  // XXX===XXX===XXX===XXX===XXX===XXX===XXX===XXX===XXX===XXX===XXX===
  const { data = { data: [] } } = useFetchQuery<any>({
    key: [QueryKeys.MODEL_PORTFOLIO_LIST],
    route: "/model_portfolio/summary/",
  });
  // XXX===XXX===XXX===XXX===XXX===XXX===XXX===XXX===XXX===XXX===XXX===
  useFetchQuery({
    key: ["RISK_PROFILE_LIST"],
    route: GET_RISK_PROFILE,
    enabled: userData?.userRole === UserRoleTypes.advisor,
    success: (data: RiskProfileListInterface) => {
      form.setValue("riskProfileObject", data?.data?.data?.data);
    },
  });
  // XXX===XXX===XXX===XXX===XXX===XXX===XXX===XXX===XXX===XXX===XXX===
  useFetchQuery({
    key: [QueryKeys.GET_CLIENT_BY_ID],
    route: `/client/${form.getValues("id")}/`,
    enabled: Boolean(form.getValues("id")),
    success: (data: any) => {
      form.reset({
        ...form.getValues(),
        ...data?.data,
        activeStep: form.getValues("activeStep"),
        selectedFeePlan: data?.data?.basketIds[0]?.feePlan || null,
        basketObject: data?.data?.basketIds[0]?.basket || null,
        portfolioObject: data?.data?.basketIds[0]?.portfolio || null,
      });
    },
  });
  // XXX===XXX===XXX===XXX===XXX===XXX===XXX===XXX===XXX===XXX===XXX===
  useFetchQuery({
    key: [QueryKeys.GET_COMPLIANCE_BY_CLIENT_ID],
    route: `/compliance/?client_id=${form.getValues("id")}`,
    enabled: Boolean(form.getValues("id")),
    success: (response: any) => {
      form.setValue("kyc", response?.data?.data?.kyc);
      form.setValue("riskProfile", response?.data?.data?.riskProfile);
      form.setValue("agreement", response?.data?.data?.agreement);
    },
  });
  // XXX===XXX===XXX===XXX===XXX===XXX===XXX===XXX===XXX===XXX===XXX===

  const riskProfileMatch =
    +form.getValues("riskProfile.points") >
    +split(form.getValues("basketObject.riskProfilePoints") || "0 - 0", " ")[0];

  // XXX===XXX===XXX===XXX===XXX===XXX===XXX===XXX===XXX===XXX===XXX===
  const { mutate: sendRiskProfile, isLoading: riskLoading } = useMutation({
    mutationFn: (data: any) =>
      axiosInstance.post(`/riskprofile-request/`, { client: data }),
    mutationKey: ["RISK_PROFILE_CLIENT_REQUEST"],

    onSuccess: () => {
      form.setValue("activeStep", form.getValues("activeStep") + 1);
    },
  });

  // XXX===XXX===XXX===XXX===XXX===XXX===XXX===XXX===XXX===XXX===XXX===
  const TabsData = [
    {
      icon: PiUserCheck,
      label: "Choose",
      show: true,
      isCompleted: form.getValues("activeStep") !== 0,
      disabled: form.getValues("activeStep") !== 0,
      component: <AdvisorOnboardingTypeSelect />,
    },
    {
      icon: PiUserRectangle,
      label: "Details",
      show: true,
      isCompleted: form.getValues("id"),
      disabled: form.getValues("id"),
      component: (
        <AdvisorOnboardingClientDetails
          setHasError={setHasError}
          hasError={hasError}
        />
      ),
    },
    {
      icon: RiListRadio,
      label: "Basket",
      show: true,
      isCompleted: form.getValues("basketObject.id"),
      disabled: false,
      component: (
        <AdvisorOnboardingEquityBasket basketList={data} setOpen={setOpen} />
      ),
    },
    {
      icon: TbPigMoney,
      label: "Investments",
      show: form.getValues("type") !== "manual",
      isCompleted: false,
      disabled: false,
      component: <AdvisorOnboardingInvestments />,
    },
    {
      icon: PiCardsThree,
      label: "KYC",
      show: form.getValues("type") !== "manual",
      isCompleted: form.getValues("kyc.kycId"),
      disabled: false,
      component: <AdvisorOnboardingKYC />,
    },
    {
      icon: CgFileDocument,
      label: "Risk Assessment",
      show: form.getValues("type") !== "manual",
      isCompleted: form.getValues("riskProfile.points"),
      disabled: false,
      component: <AdvisorOnboardingRiskProfile />,
    },
    {
      icon: CgFileDocument,
      label: "Agreement",
      show: form.getValues("type") !== "manual",
      isCompleted: form.getValues("agreement.agreementStatus.id"),
      disabled: false,
      component: <AdvisorOnboardingAgreement />,
    },
    {
      icon: CgCreditCard,
      label: "Payment",
      show: form.getValues("type") !== "manual",
      isCompleted: form.getValues("activeStep") > 7,
      disabled: false,
      component: <AdvisorOnboardingPayment />,
    },
    {
      icon: HiOutlineCheckBadge,
      label: "Completed",
      show: form.getValues("type") !== "manual",
      isCompleted: false,
      disabled: false,
      component: <AdvisorOnboardingCompleted setOpen={setOpen} />,
    },
  ];
  // ================================================================================================================= clientData={addedClientData}====
  const { mutate: handleSendPaymentLink, isLoading: sendLinkLoading } =
    useMutation({
      mutationKey: ["HANDLE_SEND_PAYMENT_LINK"],
      mutationFn: (values: any) =>
        axiosInstance.post(`/client-onboarding-payment-history/`, values),
      onSuccess: () => {
        refetchQuery(QueryKeys.GET_COMPLIANCE_BY_CLIENT_ID);
        refetchQuery(QueryKeys.GET_CLIENT_BY_ID);
        form.setValue("activeStep", form.getValues("activeStep") + 1);
      },
    });
  // XXX===XXX===XXX===XXX===XXX===XXX===XXX===XXX===XXX===XXX===XXX===
  const handleCurrentForm = (currentStep: number) => {
    switch (currentStep) {
      case 1:
        return {
          buttonText: "Next",
          form: "advisor-onboarding-client-details-form",
        };
      case 2:
        return {
          buttonText:
            form.getValues("type") !== "manual" ? "Next" : "Send & Finish",
          form: "advisor-onboarding-equity-basket-form",
        };
      case 3:
        return {
          buttonText: "Next",
          form: "aum-fee-plan-form",
        };
      case 4:
        return {
          buttonText: "Next",
          form: "advisor-onboarding-kyc-form",
        };
      case 5:
        return {
          buttonText: "Next",
          form: "advisor-onboarding-risk-profile-form",
        };
      case 6:
        return {
          buttonText: "Next",
          form: "advisor-onboarding-agreement-form",
        };
      case 7:
        return {
          buttonText: "Skip & Finish",
          form: "advisor-onboarding-payment-form",
        };

      default:
        return {
          buttonText: "Next",
          form: "",
        };
    }
  };

  // XXX===XXX===XXX===XXX===XXX===XXX===XXX===XXX===XXX===XXX===XXX===
  const agreementLoading = !!useIsMutating({
    mutationKey: ["HANDLE_ADD_AGREMENT_MUTATUION"],
  });
  const addClientLoading = !!useIsMutating({
    mutationKey: ["HANDLE_ADD_CLIENT_MUTATUION"],
  });
  const updateClientLoading = !!useIsMutating({
    mutationKey: ["HANDLE_UPDATE_CLIENT_MUTATUION"],
  });
  const riskProfileLoading = !!useIsMutating({
    mutationKey: ["HANDLE_RISK_PROFILE_MUTATUION"],
  });
  const equityBasketLoading = !!useIsMutating({
    mutationKey: ["HANDLE_CLIENT_EB_MUTATUION"],
  });
  const getClientLoading = !!useIsMutating({
    mutationKey: ["GET_CLIENT_BY_ID"],
  });
  const complianceLoading = !!useIsMutating({
    mutationKey: ["GET_KYC_CLIENT_BY_ID"],
  });
  const clientIdLoading = !!useIsMutating({
    mutationKey: ["GET_CLIENT_BY_ID"],
  });
  const clientSendIdLoading = !!useIsMutating({
    mutationKey: ["HANDLE_CLIENT_SEND_LINK_MUTATUION"],
  });
  const paymentLinkLoading = !!useIsMutating({
    mutationKey: ["HANDLE_SEND_PAYMENT_LINK"],
  });
  const aumBasedLoading = !!useIsMutating({
    mutationKey: ["ADD_AUM_BASED_SUBSCRIPTION"],
  });
  const getKraLoading = !!useIsMutating({
    mutationKey: ["GET_KRA_DATA"],
  });
  const handleKYCLoading = !!useIsMutating({
    mutationKey: ["HANDLE_KYC_ADVISOR"],
  });
  const handleSendAgreementLoading = !!useIsMutating({
    mutationKey: ["CLIENT_ONBOARDING_SEND_CUSTOM_AGREEMENT"],
  });
  const handleAgreementLoading = !!useIsMutating({
    mutationKey: ["CLIENT_ONBOARDING_GENERATE_AGREEMENT"],
  });
  const handleHoldingParseLoading = !!useIsMutating({
    mutationKey: ["HANDLE_HOLDINGS_PARSING"],
  });

  const loading =
    riskLoading ||
    getKraLoading ||
    aumBasedLoading ||
    clientIdLoading ||
    agreementLoading ||
    handleKYCLoading ||
    addClientLoading ||
    getClientLoading ||
    paymentLinkLoading ||
    riskProfileLoading ||
    clientSendIdLoading ||
    updateClientLoading ||
    equityBasketLoading ||
    handleAgreementLoading ||
    handleHoldingParseLoading ||
    handleSendAgreementLoading ||
    complianceLoading;
  // XXX===XXX===XXX===XXX===XXX===XXX===XXX===XXX===XXX===XXX===XXX===
  const activestepWatch = form.watch("activeStep");

  form.watch();

  const handleReturnStepColor = ({
    index,
    dataItem,
  }: {
    index: number;
    dataItem: any;
  }) => {
    if (index === activestepWatch) {
      return theme.palette?.primary.main;
    }
    if (dataItem?.isCompleted) {
      return theme.palette?.success.main;
    }
    return grey[600];
  };

  return (
    <FormProvider {...form}>
      <Modal open={open} onClose={() => setOpen(false)} disableEscapeKeyDown>
        <Box
          sx={{
            display: "flex",
            height: "100vh",
            width: "100vw",
            justifyContent: "center",

            alignItems: {
              md: "center",
              xs: "flex-end",
            },
            borderRadius: 0,
          }}
        >
          <Grid2
            container
            sx={{
              width: {
                xs: "100%",
                md: "70%",
              },
            }}
            bgcolor={"#F2F2F2"}
            borderRadius={2}
            p={2}
          >
            <Grid2 size={12} display={"flex"} justifyContent={"space-between"}>
              <GKTypography fontSize={24} fontWeight={600}>
                Client Onboarding
              </GKTypography>
              <IconButton onClick={() => setOpen(false)}>
                <MdClose />
              </IconButton>{" "}
            </Grid2>
            <Grid2
              size={12}
              bgcolor={theme.palette.light.main}
              my={2}
              container
              borderRadius={2}
            >
              <Show.When isTrue={activestepWatch !== 8}>
                <Grid2 size={12}>
                  <Tabs
                    onChange={(e, value) => {
                      if (form.getValues("id")) {
                        form.setValue("activeStep", value);
                      }
                    }}
                    variant="scrollable"
                    value={form.getValues("activeStep")}
                    TabIndicatorProps={{
                      style: {
                        backgroundColor: theme.palette.primary.main,
                      },
                    }}
                  >
                    {TabsData?.map(
                      (dataItem, index) =>
                        dataItem.show && (
                          <Tab
                            sx={{
                              p: "16px !important",
                              color: handleReturnStepColor({
                                dataItem: dataItem,
                                index: index,
                              }),
                              "&.Mui-disabled": {
                                color: handleReturnStepColor({
                                  dataItem: dataItem,
                                  index: index,
                                }),
                              },
                            }}
                            disabled={dataItem?.disabled}
                            key={dataItem.label}
                            label={dataItem.label}
                            icon={
                              dataItem?.isCompleted &&
                              index !== form.getValues("activeStep") ? (
                                <RiCheckDoubleLine
                                  color={theme.palette.success.main}
                                  size={20}
                                />
                              ) : (
                                <dataItem.icon size={20} />
                              )
                            }
                            iconPosition="start"
                          />
                        ),
                    )}
                  </Tabs>
                  <Divider />
                </Grid2>
              </Show.When>
              <Grid2
                size={12}
                p={2}
                minHeight={400}
                maxHeight={400}
                overflow={"auto"}
              >
                {TabsData.map((d, i) => {
                  return (
                    <Show.When isTrue={activestepWatch === i}>
                      {d.component}
                    </Show.When>
                  );
                })}
              </Grid2>
            </Grid2>
            <Show.When isTrue={!form.getValues("uploadHoldingsModal")}>
              <Grid2
                size={12}
                display={
                  [0, 8]?.includes(form.getValues("activeStep"))
                    ? "none"
                    : "flex"
                }
                justifyContent={"space-between"}
                gap={1}
              >
                <Button
                  sx={{
                    visibility: [2, 3, 4, 5, 6, 7].includes(
                      form.getValues("activeStep"),
                    )
                      ? "visible"
                      : "hidden",
                  }}
                  onClick={() => {
                    form.setValue(
                      "activeStep",
                      form.getValues("activeStep") - 1,
                    );
                  }}
                  variant="outlined"
                  color="dark"
                >
                  Previous
                </Button>
                <Show.When
                  isTrue={
                    form.getValues("activeStep") === 5 && riskProfileMatch
                  }
                >
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                      form.setValue("riskProfile", null);
                    }}
                    sx={{ ml: "auto" }}
                  >
                    Retake Assessment
                  </Button>
                </Show.When>
                <Show.When
                  isTrue={
                    form.getValues("activeStep") === 5 &&
                    !form.getValues("riskProfile.points")
                  }
                >
                  <LoadingButton
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                      sendRiskProfile(form.getValues("id"));
                    }}
                    sx={{ ml: "auto" }}
                    loading={loading}
                  >
                    Send to Client
                  </LoadingButton>
                </Show.When>
                <Show.When
                  isTrue={
                    form.getValues("activeStep") === 7 &&
                    !form.getValues("skipAdvanceFee") &&
                    onBoardflowData?.paymentMethods !== "manual_payment"
                  }
                >
                  <LoadingButton
                    loading={sendLinkLoading || loading}
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                      handleSendPaymentLink({
                        isSendEmail: true,
                        client: form.getValues("id"),
                        basket: form.getValues("basketObject.id"),
                        feePlan: form.getValues("selectedFeePlan.id"),
                        portfolio: form.getValues("portfolioObject.id"),
                        frequency: form.getValues("advanceFeePlan.frequency"),
                        advanceFeePlan: form.getValues(
                          "advanceFeePlan.feePlan.id",
                        ),
                        flowType: "advisor_initiated_advisory_flow",
                      });
                    }}
                    sx={{ ml: "auto" }}
                  >
                    Send payment link
                  </LoadingButton>
                </Show.When>
                <LoadingButton
                  variant="contained"
                  color="primary"
                  type="submit"
                  loading={loading}
                  disabled={
                    (activestepWatch === 5 && !riskProfileMatch) || hasError
                  }
                  form={handleCurrentForm(activestepWatch).form}
                >
                  {handleCurrentForm(activestepWatch).buttonText}
                </LoadingButton>
              </Grid2>
            </Show.When>
            <Show.When isTrue={form.getValues("uploadHoldingsModal")}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Button
                  onClick={() => {
                    form.setValue("uploadHoldingsModal", false);
                    form.setValue("planDataObject.plansHoldings", []);
                  }}
                  variant="outlined"
                  color="dark"
                >
                  Cancel
                </Button>
                <Typography>
                  Total Investment value:{" "}
                  <span
                    style={{
                      fontWeight: 600,
                    }}
                  >
                    {numberToCurrency(
                      +sum(
                        form
                          .getValues("planDataObject.plansHoldings")
                          .map((d) => +d.cmp * +d.quantity),
                      ) || 0,
                    )}
                  </span>
                </Typography>
                <Button
                  onClick={() => {
                    form.setValue(
                      "planDataObject.holdingsValue",
                      +sum(
                        form
                          .getValues("planDataObject.plansHoldings")
                          .map((d) => +d.cmp * +d.quantity),
                      ),
                    );
                    form.setValue("uploadHoldingsModal", false);
                  }}
                  variant="contained"
                >
                  Save Holdings
                </Button>
              </Box>
            </Show.When>
          </Grid2>
        </Box>
      </Modal>
    </FormProvider>
  );
};

export default AdvisorOnboarding;
