export const permissions = [
  {
    mainHeading: "Company Management",
    permission: "companyManagement",
    isExist: ["BRANCH", "EMPLOYEE", "DEFAULT_BRANCH", "DEFAULT_EMPLOYEE"],
    subPermission: [
      {
        heading: "Manage Head Branch",
        subHeading:
          "Edit company profile details ( all details, logo ) & set default permissions",
        permission: "manageHeadBranch",
        isExist: ["BRANCH", "DEFAULT_BRANCH"],
      },
      {
        heading: "Manage Branch",
        subHeading: "Create and edit branch details",
        permission: "manageBranch",
        isExist: ["EMPLOYEE", "BRANCH", "DEFAULT_BRANCH", "DEFAULT_EMPLOYEE"],
      },
      {
        heading: "Manage Admin",
        subHeading: "Create and edit employee details",
        permission: "manageEmployee",
        isExist: ["EMPLOYEE", "BRANCH", "DEFAULT_BRANCH", "DEFAULT_EMPLOYEE"],
      },
      {
        heading: "Manage Partner",
        subHeading: "Create and edit partner details",
        permission: "managePartner",
        isExist: ["EMPLOYEE", "BRANCH", "DEFAULT_BRANCH", "DEFAULT_EMPLOYEE"],
      },
      {
        heading: "Permissions",
        subHeading: "Edit branch, employee, partner",
        permission: "updatePermissions",
        isExist: ["EMPLOYEE", "BRANCH", "DEFAULT_BRANCH", "DEFAULT_EMPLOYEE"],
      },
      {
        heading: "Company Settings",
        subHeading: "Create custom upload sheet template",
        permission: "updateCompanySettings",
        isExist: ["EMPLOYEE", "BRANCH", "DEFAULT_BRANCH", "DEFAULT_EMPLOYEE"],
      },
      {
        heading: "Access admin dashboard",
        subHeading: "Access admin dashboard",
        permission: "adminDashboard",
        isExist: ["EMPLOYEE"],
      },
    ],
  },
  {
    mainHeading: "Imports",
    permission: "imports",
    isExist: [
      "BRANCH",
      "EMPLOYEE",
      "CLIENT",
      "PARTNER",
      "DEFAULT_CLIENT",
      "DEFAULT_BRANCH",
      "DEFAULT_PARTNER",
      "DEFAULT_EMPLOYEE",
    ],
    subPermission: [
      {
        heading: "Import Data",
        subHeading: "Import stock and cash transactions",
        permission: "importData",
        isExist: [
          "EMPLOYEE",
          "BRANCH",
          "CLIENT",
          "PARTNER",
          "DEFAULT_CLIENT",
          "DEFAULT_BRANCH",
          "DEFAULT_PARTNER",
          "DEFAULT_EMPLOYEE",
        ],
      },
      {
        heading: "Manual Data",
        subHeading: "Add manual stocks transactions and bank entry",
        permission: "addTransaction",
        isExist: [
          "EMPLOYEE",
          "BRANCH",
          "CLIENT",
          "PARTNER",
          "DEFAULT_CLIENT",
          "DEFAULT_BRANCH",
          "DEFAULT_PARTNER",
          "DEFAULT_EMPLOYEE",
        ],
      },
      {
        heading: "Import Manager",
        subHeading:
          "View, download, check errors for imports in import manager",
        permission: "importManager",
        isExist: [
          "EMPLOYEE",
          "BRANCH",
          "CLIENT",
          "PARTNER",
          "DEFAULT_CLIENT",
          "DEFAULT_BRANCH",
          "DEFAULT_PARTNER",
          "DEFAULT_EMPLOYEE",
        ],
      },
      {
        heading: "Migrate Client Data",
        subHeading: "Migrate clients & portfolios in bulk",
        permission: "importClient",
        isExist: ["EMPLOYEE", "BRANCH", "DEFAULT_BRANCH", "DEFAULT_EMPLOYEE"],
      },
      {
        heading: "Auto Import",
        subHeading: "Auto import approval for transaction updation",
        permission: "autoForward",
        isExist: [
          "EMPLOYEE",
          "BRANCH",
          "CLIENT",
          "PARTNER",
          "DEFAULT_CLIENT",
          "DEFAULT_BRANCH",
          "DEFAULT_PARTNER",
          "DEFAULT_EMPLOYEE",
        ],
      },
      {
        heading: "Undo File Import",
        subHeading: "Undo import of a file in import manager",
        permission: "undoFileImport",
        isExist: ["EMPLOYEE", "BRANCH", "DEFAULT_BRANCH", "DEFAULT_EMPLOYEE"],
      },
    ],
  },
  {
    mainHeading: "CRM",
    permission: "crm",
    isExist: [
      "BRANCH",
      "EMPLOYEE",
      "PARTNER",
      "DEFAULT_BRANCH",
      "DEFAULT_PARTNER",
      "DEFAULT_EMPLOYEE",
    ],
    subPermission: [
      {
        heading: "Client Onboarding",
        subHeading: "Add and edit client",
        permission: "createClient",
        isExist: [
          "EMPLOYEE",
          "BRANCH",
          "PARTNER",
          "DEFAULT_BRANCH",
          "DEFAULT_PARTNER",
          "DEFAULT_EMPLOYEE",
        ],
      },
      {
        heading: "Client Permissions",
        subHeading: "Edit client permissions",
        permission: "updateClientPermissions",
        isExist: [
          "EMPLOYEE",
          "BRANCH",
          "PARTNER",
          "DEFAULT_BRANCH",
          "DEFAULT_PARTNER",
          "DEFAULT_EMPLOYEE",
        ],
      },
      {
        heading: "Family Creation",
        subHeading: "Create family, add or remove clients, edit any details.",
        permission: "createFamily",
        isExist: [
          "EMPLOYEE",
          "BRANCH",
          "PARTNER",
          "DEFAULT_BRANCH",
          "DEFAULT_PARTNER",
          "DEFAULT_EMPLOYEE",
        ],
      },
      {
        heading: "Access for ORG",
        subHeading:
          "Client and family access permissions from org side for org side",
        permission: "updateClientAndFamilyAccess",
        isExist: [
          "EMPLOYEE",
          "BRANCH",
          "PARTNER",
          "DEFAULT_BRANCH",
          "DEFAULT_PARTNER",
          "DEFAULT_EMPLOYEE",
        ],
      },
      {
        heading: "Compliance",
        subHeading: "Risk profile",
        permission: "riskProfile",
        isExist: [
          "EMPLOYEE",
          "BRANCH",
          "PARTNER",
          "DEFAULT_BRANCH",
          "DEFAULT_PARTNER",
          "DEFAULT_EMPLOYEE",
        ],
      },
      {
        heading: "Compliance 1",
        subHeading: "Complete KYC & Agreement",
        permission: "kycAndAgreement",
        isExist: [
          "EMPLOYEE",
          "BRANCH",
          "PARTNER",
          "DEFAULT_BRANCH",
          "DEFAULT_PARTNER",
          "DEFAULT_EMPLOYEE",
        ],
      },
      {
        heading: "Lead Creation",
        subHeading: "Lead creation",
        permission: "createLead",
        isExist: [
          "EMPLOYEE",
          "BRANCH",
          "PARTNER",
          "DEFAULT_BRANCH",
          "DEFAULT_PARTNER",
          "DEFAULT_EMPLOYEE",
        ],
      },
      {
        heading: "Lead Management",
        subHeading: "Create meetings, notes, send decks, convert to client",
        permission: "mangeLead",
        isExist: [
          "EMPLOYEE",
          "BRANCH",
          "PARTNER",
          "DEFAULT_BRANCH",
          "DEFAULT_PARTNER",
          "DEFAULT_EMPLOYEE",
        ],
      },
    ],
  },
  {
    mainHeading: "Portfolio Management",
    permission: "portfolioManagement",
    isExist: [
      "BRANCH",
      "EMPLOYEE",
      "CLIENT",
      "PARTNER",
      "DEFAULT_CLIENT",
      "DEFAULT_BRANCH",
      "DEFAULT_PARTNER",
      "DEFAULT_EMPLOYEE",
    ],
    subPermission: [
      {
        heading: "Portfolio Creation",
        subHeading: "Create and edit portfolio details",
        permission: "createPortfolio",
        isExist: [
          "EMPLOYEE",
          "BRANCH",
          "PARTNER",
          "DEFAULT_BRANCH",
          "DEFAULT_PARTNER",
          "DEFAULT_EMPLOYEE",
        ],
      },
      {
        heading: "Edit & Delete Transaction",
        subHeading: "Edit or delete stock transactions & bank entry",
        permission: "updateTransactions",
        isExist: ["EMPLOYEE", "BRANCH", "DEFAULT_BRANCH", "DEFAULT_EMPLOYEE"],
      },
      {
        heading: "View Portfolio",
        subHeading: "View portfolio list",
        permission: "viewPortfolios",
        isExist: [
          "EMPLOYEE",
          "BRANCH",
          "CLIENT",
          "PARTNER",
          "DEFAULT_CLIENT",
          "DEFAULT_BRANCH",
          "DEFAULT_PARTNER",
          "DEFAULT_EMPLOYEE",
        ],
      },
      {
        heading: "Holding & Transactions",
        subHeading: "View holding & transactions",
        permission: "viewHoldings",
        isExist: [
          "EMPLOYEE",
          "BRANCH",
          "CLIENT",
          "PARTNER",
          "DEFAULT_CLIENT",
          "DEFAULT_BRANCH",
          "DEFAULT_PARTNER",
          "DEFAULT_EMPLOYEE",
        ],
      },
      {
        heading: "Group Portfolios",
        subHeading: "Grouping portfolios",
        permission: "groupPortfolio",
        isExist: [
          "EMPLOYEE",
          "BRANCH",
          "CLIENT",
          "PARTNER",
          "DEFAULT_CLIENT",
          "DEFAULT_BRANCH",
          "DEFAULT_PARTNER",
          "DEFAULT_EMPLOYEE",
        ],
      },
      {
        heading: "Share Portfolio",
        subHeading: "Sharing portfolios",
        permission: "sharePortfolio",
        isExist: [
          "EMPLOYEE",
          "BRANCH",
          "CLIENT",
          "DEFAULT_CLIENT",
          "DEFAULT_BRANCH",
          "DEFAULT_EMPLOYEE",
        ],
      },
      {
        heading: "Client Access",
        subHeading: "Enable client access to view the portfolio",
        permission: "updatePortfolioClientAccess",
        isExist: [
          "EMPLOYEE",
          "BRANCH",
          "PARTNER",
          "DEFAULT_BRANCH",
          "DEFAULT_PARTNER",
          "DEFAULT_EMPLOYEE",
        ],
      },
      {
        heading: "Active Status",
        subHeading: "Enable or disable portfolio active status",
        permission: "updateActiveStatus",
        isExist: [
          "EMPLOYEE",
          "BRANCH",
          "PARTNER",
          "DEFAULT_BRANCH",
          "DEFAULT_PARTNER",
          "DEFAULT_EMPLOYEE",
        ],
      },
      {
        heading: "Access for ORG",
        subHeading: "Giving access of a portfolio to anyone from organisation",
        permission: "updatePortfolioAccess",
        isExist: ["EMPLOYEE", "BRANCH", "DEFAULT_BRANCH"],
      },
      {
        heading: "Access client dashboard",
        subHeading: "Access client dashboard",
        permission: "clientDashboard",
        isExist: ["CLIENT"],
      },
    ],
  },
  {
    mainHeading: "Equity Basket",
    permission: "equityBasket",
    isExist: [
      "BRANCH",
      "EMPLOYEE",
      "PARTNER",
      "DEFAULT_BRANCH",
      "DEFAULT_PARTNER",
      "DEFAULT_EMPLOYEE",
    ],
    subPermission: [
      {
        heading: "Creation of Equity Basket",
        subHeading: "Create equity basket",
        permission: "createModelPortfolio",
        isExist: ["EMPLOYEE", "BRANCH", "DEFAULT_BRANCH", "DEFAULT_EMPLOYEE"],
      },
      {
        heading: "Basket Versions & Rebalance",
        subHeading: "Manage equity basket",
        permission: "manageModelPortfolio",
        isExist: ["EMPLOYEE", "BRANCH", "DEFAULT_BRANCH", "DEFAULT_EMPLOYEE"],
      },
      {
        heading: "Delete Equity Basket",
        subHeading: "Delete equity basket",
        permission: "deleteModelPortfolio",
        isExist: [
          "EMPLOYEE",
          "BRANCH",
          "DEFAULT_BRANCH",
          "DEFAULT_PARTNER",
          "DEFAULT_EMPLOYEE",
        ],
      },
      {
        heading: "Portfolio Mapping",
        subHeading: "Map/Unmap portfolios with equity basket",
        permission: "mapPortfolios",
        isExist: [
          "EMPLOYEE",
          "BRANCH",
          "PARTNER",
          "DEFAULT_BRANCH",
          "DEFAULT_PARTNER",
          "DEFAULT_EMPLOYEE",
        ],
      },
      {
        heading: "Manage Portfolio",
        subHeading: "Allocation, exit & rebalance of portfolios",
        permission: "orderManager",
        isExist: ["EMPLOYEE", "BRANCH", "DEFAULT_BRANCH", "DEFAULT_EMPLOYEE"],
      },
      // {
      //   heading: "Order Manager",
      //   subHeading: "Download or Send order Sheet",
      //   permission: "orderManager",
      //   isExist: [
      //     "EMPLOYEE",
      //     "BRANCH",
      //     "PARTNER",
      //     "DEFAULT_BRANCH",
      //     "DEFAULT_PARTNER",
      //     "DEFAULT_EMPLOYEE",
      //   ],
      // },
      {
        heading: "Manage versions",
        subHeading: "Create, manage and rebalance basket versions",
        permission: "mpVersionAndRebalance",
        isExist: [
          "EMPLOYEE",
          "BRANCH",
          "PARTNER",
          "DEFAULT_BRANCH",
          "DEFAULT_PARTNER",
          "DEFAULT_EMPLOYEE",
        ],
      },
    ],
  },
  {
    mainHeading: "Reports",
    permission: "reports",
    isExist: [
      "BRANCH",
      "EMPLOYEE",
      "CLIENT",
      "PARTNER",
      "DEFAULT_CLIENT",
      "DEFAULT_BRANCH",
      "DEFAULT_PARTNER",
      "DEFAULT_EMPLOYEE",
    ],
    subPermission: [
      {
        heading: "Generate Reports",
        subHeading:
          "Generate any report from the report list (Only for client and portfolio allowed)",
        permission: "generateReport",
        isExist: [
          "EMPLOYEE",
          "BRANCH",
          "CLIENT",
          "PARTNER",
          "DEFAULT_CLIENT",
          "DEFAULT_BRANCH",
          "DEFAULT_PARTNER",
          "DEFAULT_EMPLOYEE",
        ],
      },
      {
        heading: "Tradebook",
        subHeading: "Generate tradebook report",
        permission: "generateTradebookReports",
        isExist: [
          "EMPLOYEE",
          "BRANCH",
          "CLIENT",
          "PARTNER",
          "DEFAULT_CLIENT",
          "DEFAULT_BRANCH",
          "DEFAULT_PARTNER",
          "DEFAULT_EMPLOYEE",
        ],
      },
      {
        heading: "Ledger",
        subHeading: "Generate ledger report",
        permission: "generateBankStatements",
        isExist: [
          "EMPLOYEE",
          "BRANCH",
          "CLIENT",
          "PARTNER",
          "DEFAULT_CLIENT",
          "DEFAULT_BRANCH",
          "DEFAULT_PARTNER",
          "DEFAULT_EMPLOYEE",
        ],
      },
      {
        heading: "Send Reports",
        subHeading: "Share/ Send report to Partner/ Client, etc",
        permission: "shareReports",
        isExist: [
          "EMPLOYEE",
          "BRANCH",
          "PARTNER",
          "DEFAULT_BRANCH",
          "DEFAULT_PARTNER",
          "DEFAULT_EMPLOYEE",
        ],
      },
    ],
  },
  {
    mainHeading: "Delete",
    permission: "delete",
    isExist: [
      "BRANCH",
      "EMPLOYEE",
      "PARTNER",
      "DEFAULT_BRANCH",
      "DEFAULT_PARTNER",
      "DEFAULT_EMPLOYEE",
    ],
    subPermission: [
      {
        heading: "Delete Branch",
        subHeading: "Branch",
        permission: "deleteBranch",
        isExist: ["BRANCH", "DEFAULT_BRANCH"],
      },
      {
        heading: "Delete Admin",
        subHeading: "Admin",
        permission: "deleteEmployee",
        isExist: ["EMPLOYEE", "BRANCH", "DEFAULT_BRANCH", "DEFAULT_EMPLOYEE"],
      },
      {
        heading: "Delete Partner",
        subHeading: "Partner",
        permission: "deletePartner",
        isExist: ["EMPLOYEE", "BRANCH", "DEFAULT_BRANCH", "DEFAULT_EMPLOYEE"],
      },
      {
        heading: "Delete Client",
        subHeading: "Client",
        permission: "deleteClient",
        isExist: [
          "EMPLOYEE",
          "BRANCH",
          "PARTNER",
          "DEFAULT_BRANCH",
          "DEFAULT_PARTNER",
          "DEFAULT_EMPLOYEE",
        ],
      },
      {
        heading: "Delete Portfolio",
        subHeading: "Portfolios & Transactions",
        permission: "deletePortfolioTransaction",
        isExist: [
          "EMPLOYEE",
          "BRANCH",
          "PARTNER",
          "DEFAULT_BRANCH",
          "DEFAULT_PARTNER",
          "DEFAULT_EMPLOYEE",
        ],
      },
      {
        heading: "Delete Equity Basket",
        subHeading: "Equity basket",
        permission: "deleteModelPortfolio",
        isExist: ["EMPLOYEE", "BRANCH", "DEFAULT_BRANCH", "DEFAULT_EMPLOYEE"],
      },
    ],
  },
  {
    mainHeading: "Revenue Management",
    permission: "revenueManagement",
    isExist: ["EMPLOYEE"],
    subPermission: [
      {
        subHeading: "Create Fee Plans",
        heading: "Create Fee Plans",
        permission: "createFeePlans",
        isExist: ["EMPLOYEE"],
      },
      {
        subHeading: "Map/Unmap portfolios with Fee Plan",
        heading: "Map/Unmap portfolios with Fee Plan",
        permission: "feePlanMapping",
        isExist: ["EMPLOYEE"],
      },
      {
        subHeading: "Calculate fees",
        heading: "Calculate fees",
        permission: "runFeeCalculations",
        isExist: ["EMPLOYEE"],
      },
      {
        subHeading: "Create Proforma Invoice and Tax Invoice",
        heading: "Create Proforma Invoice and Tax Invoice",
        permission: "profromaAndInvoice",
        isExist: ["EMPLOYEE"],
      },
      {
        subHeading: "Manage fee collection",
        heading: "Manage fee collection",
        permission: "feeCollection",
        isExist: ["EMPLOYEE"],
      },
      {
        heading: "Delete Invoice",
        subHeading: "Delete Invoice",
        permission: "deleteInvoice",
        isExist: ["EMPLOYEE"],
      },
    ],
  },
];
