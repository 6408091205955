import {
  FormControlLabel,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import {
  AiOutlinePlus,
  FiTrash2,
  GKButton,
  GKSearchSelect,
  GKTextField,
  Grid2,
} from "components";
import GKCheckbox from "components/GKCheckbox";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { GoInfo } from "react-icons/go";
import { handleReturnAutocompletePlaceholder } from "utils/StringFunctions";
import { ThemeContainer } from "utils/Theme";
import {
  FeeMethod,
  FeeType,
  PERFORMANCE_PLAN_FEE_PARAMETER,
} from "utils/constants";

const PerformanceFee = () => {
  const { theme } = ThemeContainer();

  const form: any = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: "performanceFee.performanceFeeSlab",
  });

  form.watch(["performanceFee.feeType"]);

  const isFeeTypeFix = form.getValues("performanceFee.feeType") !== "fixed";

  return (
    <Grid2 container rowSpacing={2} spacing={1}>
      <Grid2 size={12}>
        <Typography fontSize={18} fontWeight={500}>
          Performance Fee
        </Typography>
      </Grid2>
      <Grid2 size={12}>
        <Controller
          name="performanceFee.minesManagementFee"
          control={form.control}
          render={({ field }) => (
            <FormControlLabel
              control={<GKCheckbox {...field} checked={field.value} />}
              label={"Management Fee Deduction"}
            />
          )}
        />
        <Controller
          name="performanceFee.highwatermark"
          control={form.control}
          defaultValue={false}
          render={({ field }) => (
            <FormControlLabel
              control={<GKCheckbox {...field} checked={field.value} />}
              label={"High Water Mark"}
            />
          )}
        />
        <Controller
          name="performanceFee.hardHurdle"
          control={form.control}
          defaultValue={false}
          render={({ field }) => (
            <FormControlLabel
              control={<GKCheckbox {...field} checked={field.value} />}
              label={"Hard Hurdle"}
            />
          )}
        />
      </Grid2>

      <Grid2 size={6}>
        <Controller
          name="performanceFee.productName"
          control={form.control}
          render={({ field }) => (
            <GKTextField
              {...field}
              placeholder="Product Name"
              inputLabel={
                <Typography fontSize={18}>
                  Product Name
                  <Tooltip
                    arrow
                    title="This name will appear on the invoices generated for your clients."
                    placement="right"
                  >
                    <IconButton color="primary">
                      <GoInfo size={17} />
                    </IconButton>
                  </Tooltip>
                </Typography>
              }
            />
          )}
        />
      </Grid2>

      <Grid2 size={6}>
        <Controller
          name="performanceFee.hsnCode"
          control={form.control}
          rules={{
            required: {
              value: true,
              message: "Please enter HSN code",
            },
          }}
          render={({ field, fieldState: { error } }) => (
            <GKTextField
              {...field}
              placeholder="HSN Code"
              requiredField
              inputLabel={"HSN Code"}
              tooltipTitle="This code will appear on invoices to classify your product for tax compliance."
              error={!!error}
              helperText={error?.message}
            />
          )}
        />
      </Grid2>
      <Grid2 size={4}>
        <Controller
          name="performanceFee.feeType"
          control={form.control}
          rules={{
            required: {
              value: true,
              message: "Please select a Fee Type",
            },
          }}
          render={({ field }) => (
            <GKSearchSelect
              {...field}
              requiredField
              inputLabel="Fee Type"
              disableClearable={false}
              options={
                FeeType.filter((dataItem) => dataItem.value !== "fixed") || []
              }
              onChange={(e, val) => {
                field.onChange(val?.value);
              }}
              value={
                FeeType.find((dataItem) => dataItem.value === field.value) || ""
              }
              getOptionLabel={(option) => option.name || option}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name="performanceFee.feeType"
                  placeholder={handleReturnAutocompletePlaceholder({
                    placeholder: "Select  Fee Type",
                    value: field.value,
                  })}
                  error={Boolean(
                    form?.formState?.errors?.performanceFee?.feeType,
                  )}
                  helperText={
                    form?.formState?.errors?.performanceFee?.feeType?.message
                  }
                />
              )}
            />
          )}
        />
      </Grid2>
      {isFeeTypeFix && (
        <>
          <Grid2 size={4}>
            <Controller
              name="performanceFee.feeMethod"
              control={form.control}
              render={({ field }) => (
                <GKSearchSelect
                  {...field}
                  inputLabel="Fee Method"
                  disableClearable={false}
                  options={FeeMethod || []}
                  onChange={(e, val) => field.onChange(val?.value)}
                  value={
                    FeeMethod.find(
                      (dataItem) => dataItem.value === field.value,
                    ) || ""
                  }
                  getOptionLabel={(option) => option.name || option}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="performanceFee.feeMethod"
                      placeholder={handleReturnAutocompletePlaceholder({
                        placeholder: "Select  Fee Method",
                        value: field.value,
                      })}
                    />
                  )}
                />
              )}
            />
          </Grid2>
          <Grid2 size={4}>
            <Controller
              name="performanceFee.calculationBenchmark"
              control={form.control}
              render={({ field }) => (
                <GKSearchSelect
                  {...field}
                  inputLabel="Fee Parameter"
                  disableClearable={false}
                  options={PERFORMANCE_PLAN_FEE_PARAMETER}
                  onChange={(e, val) => field.onChange(val?.value)}
                  value={
                    PERFORMANCE_PLAN_FEE_PARAMETER.find(
                      (dataItem) => dataItem.value === field.value,
                    ) || ""
                  }
                  getOptionLabel={(option) => option.name || option}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="performanceFee.calculationBenchmark"
                      placeholder={handleReturnAutocompletePlaceholder({
                        placeholder: "Select  Fee Parameter",
                        value: field.value,
                      })}
                    />
                  )}
                />
              )}
            />
          </Grid2>
        </>
      )}

      {/* <Grid2 size={4}>
        <Controller
          name="performanceFee.calculationFrequency"
          control={form.control}
          render={({ field }) => (
            <GKSearchSelect
              {...field}
              inputLabel="Calculation Frequency"
              disableClearable={false}
              options={calculationFrequency || []}
              onChange={(e, val) => field.onChange(val ? val.value : null)}
              value={
                calculationFrequency.find(
                  (dataItem) => dataItem.value === field.value
                ) || ""
              }
              getOptionLabel={(option) => option.name || option}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name="performanceFee.calculationFrequency"
                  placeholder={handleReturnAutocompletePlaceholder({
                      placeholder: "Sele  Calculation Frequencyct",
                      value: field.value,
                    })}
                />
              )}
            />
          )}
        />
      </Grid2> */}
      <Grid2 size={4}>
        <Controller
          name="performanceFee.fee"
          control={form.control}
          rules={{
            required: {
              value: true,
              message: "Please enter a Fee",
            },
          }}
          render={({ field }) => (
            <GKTextField
              {...field}
              requiredField
              inputLabel={!isFeeTypeFix ? "Fee Amount" : "Fee %"}
              type="number"
              error={Boolean(form?.formState?.errors?.performanceFee?.fee)}
              helperText={form?.formState?.errors?.performanceFee?.fee?.message}
            />
          )}
        />
      </Grid2>
      {/* <Grid2 size={4}>
        <Controller
          name="performanceFee.billFrequency"
          control={form.control}
          render={({ field }) => (
            <GKSearchSelect
            {...field}
              inputLabel="Billing Frequency"
              disableClearable={false}
              options={billingFrequency || []}
              onChange={(e, val) => field.onChange(val ? val.value : null)}
              value={
                billingFrequency.find(
                  (dataItem) => dataItem.value === field.value
                ) || ""
              }
              getOptionLabel={(option) => option.name || option}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder={handleReturnAutocompletePlaceholder({
                      placeholder: "Sele  Billing Frequencyct",
                      value: field.value,
                    })}
                />
              )}
            />
          )}
        />
      </Grid2> */}
      <Grid2 size={4}>
        <Controller
          name="performanceFee.outOfPocketExpenses"
          control={form.control}
          render={({ field }) => (
            <GKTextField
              {...field}
              inputLabel="Out Of Pocket Expense"
              type="number"
            />
          )}
        />
      </Grid2>
      {/* <Grid2 size={4}>

          <Controller
            name="performanceFee.minimumFee"
            control={form.control}
            render={({ field }) => (
              <GKTextField
                {...field}
                inputLabel="Minimum Fee"
                type="number"
              />
            )}
          />

      </Grid2>
      <Grid2 size={4}>

          <Controller
            name="performanceFee.maximumFee"
            control={form.control}
            render={({ field }) => (
              <GKTextField
                {...field}
                inputLabel="Maximum Fee"
                type="number"
              />
            )}
          />

      </Grid2> */}
      {/* <Grid2 size={4}>

          <Controller
            name="performanceFee.softHurdle"
            control={form.control}
            render={({ field }) => (
              <GKTextField
                {...field}
                inputLabel="Soft Hurdle"
                type="number"
              />
            )}
          />

      </Grid2> */}
      {form.watch("performanceFee.hardHurdle") && (
        <Grid2 size={4}>
          <Controller
            name="performanceFee.hurdle"
            control={form.control}
            render={({ field }) => (
              <GKTextField {...field} inputLabel="Hurdle %" type="number" />
            )}
          />
        </Grid2>
      )}

      {/* {isFeeTypeFix && (
        <Grid2 size={4}>
          <Controller
            name="performanceFee.calculationMethod"
            control={form.control}
            render={({ field }) => (
              <GKSearchSelect
                {...field}
                inputLabel="Calculation Method"
                disableClearable={false}
                options={
                  managementFeeCalculationMethod.filter(
                    (dataItem) => dataItem.value !== "advance"
                  ) || []
                }
                onChange={(e, val) => field.onChange(val ? val.value : null)}
                value={
                  managementFeeCalculationMethod.find(
                    (dataItem) => dataItem.value === field.value
                  ) || ""
                }
                getOptionLabel={(option) => option.name || option}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="performanceFee.calculationMethod"
                    placeholder={handleReturnAutocompletePlaceholder({
                      placeholder: "Select  Calculation Method",
                      value: field.value,
                    })}
                  />
                )}
              />
            )}
          />
        </Grid2>
      )} */}

      {form.getValues("performanceFee.feeMethod") === "slabs" && (
        <Grid2 size={12}>
          <TableContainer
            component={Paper}
            sx={{ marginTop: 2, minHeight: 100 }}
          >
            <Table
              sx={{ minWidth: 650 }}
              size="small"
              aria-label="a dense table"
            >
              <TableHead>
                <TableRow>
                  <TableCell
                    colSpan={2}
                    style={{
                      padding: 12,
                    }}
                  >
                    <Typography
                      fontSize={16}
                      fontWeight={700}
                      color={theme.palette.primary.main}
                    >
                      Slabs
                    </Typography>
                  </TableCell>
                  <TableCell
                    colSpan={2}
                    align="right"
                    style={{
                      padding: 12,
                    }}
                  >
                    <Typography
                      fontSize={16}
                      fontWeight={700}
                      color={theme.palette.primary.main}
                    >
                      <GKButton
                        variant="outlined"
                        onClick={() => {
                          append({
                            lowerLimit: "",
                            upperLimit: "",
                            slabFee: "",
                          });
                        }}
                        startIcon={<AiOutlinePlus />}
                      >
                        Add Slab
                      </GKButton>
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableHead>
                <TableRow>
                  <TableCell>From</TableCell>
                  <TableCell>To</TableCell>
                  <TableCell>Management Fee&nbsp;(%)</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {fields.map((row: any, index: number) => {
                  return (
                    <TableRow
                      key={row.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        <Controller
                          name={`performanceFee.performanceFeeSlab[${index}].lowerLimit`}
                          control={form.control}
                          render={({ field }) => (
                            <GKTextField {...field} type="number" />
                          )}
                        />
                      </TableCell>
                      <TableCell>
                        <Controller
                          name={`performanceFee.performanceFeeSlab[${index}].upperLimit`}
                          control={form.control}
                          render={({ field }) => (
                            <GKTextField {...field} type="number" />
                          )}
                        />
                      </TableCell>
                      <TableCell>
                        <Controller
                          name={`performanceFee.performanceFeeSlab[${index}].slabFee`}
                          control={form.control}
                          render={({ field }) => (
                            <GKTextField {...field} type="number" />
                          )}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <IconButton
                          color="error"
                          className="outline"
                          disabled={fields.length === 1}
                          onClick={() => remove(index)}
                        >
                          <FiTrash2 size={16} />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid2>
      )}
    </Grid2>
  );
};

export default PerformanceFee;
