import { LoadingButton } from "@mui/lab";
import { useAppContext } from "AppContext";
import { Grid2 } from "components";
import GKDatePicker from "components/GKDatePicker";
import GKModal from "components/GKModal";
import GKTextArea from "components/GKTextBar";
import GKTextField from "components/GKTextField";
import dayjs from "dayjs";
import { useMutation } from "hooks/useQueries";
import { Controller, useForm } from "react-hook-form";
import axiosInstance from "utils/axiosInstance";
import { GKModalProps } from "utils/commonInterface";
import { validateNotWhitespaceOnly } from "utils/validationSchema";

interface Props {
  invoiceId: number;
  refetch: any;
  name?: string;
}

const ConvertToInvoiceModal = (props: Props & GKModalProps) => {
  const { open, setOpen, invoiceId, refetch, name } = props;

  const {
    state: { tab },
    dispatch,
  } = useAppContext();

  const { mutate: handleConvertToInvoice, isLoading } = useMutation({
    mutationKey: ["CONVERT_TO_INVOICE"],
    mutationFn: (data) =>
      axiosInstance.post(
        `/proforma-history/${invoiceId}/invoice_convert/`,
        data,
      ),
    onSuccess: () => {
      setOpen(false);
      refetch();
      if (name) {
        dispatch({ type: "TAB", data: { ...tab, tabOfPortfolioRevenue: 3 } });
      } else {
        dispatch({ type: "TAB", data: { ...tab, tabValueOfRevenue: 4 } });
      }
    },
  });

  const form = useForm({
    defaultValues: {
      invoice_date: dayjs(),
      invoice_number: "",
      isUpdateHighwaterMark: true,
      remarks: "",
    },
  });

  return (
    <GKModal
      open={open}
      setOpen={setOpen}
      modalTitle={"Convert To Invoice"}
      footer={
        <LoadingButton
          type="submit"
          variant="contained"
          form="convert-invoice-form"
          size="medium"
          loading={isLoading}
        >
          Save
        </LoadingButton>
      }
    >
      <form
        id="convert-invoice-form"
        onSubmit={form.handleSubmit((data: any) => {
          handleConvertToInvoice({
            ...data,
            invoice_date: dayjs(data.invoice_date).format("YYYY-MM-DD"),
          });
        })}
      >
        <Grid2 container spacing={1}>
          <Grid2 size={6}>
            <Controller
              name="invoice_date"
              control={form.control}
              render={({ field }) => (
                <GKDatePicker
                  {...field}
                  inputLabel="Invoice Date"
                  value={dayjs(field.value)}
                  onChange={(value) => {
                    field.onChange(dayjs(value).format("YYYY-MM-DD"));
                  }}
                  disableFuture
                  slotProps={{
                    textField: {
                      error: false,
                      helperText: false,
                    },
                  }}
                />
              )}
            />
          </Grid2>
          <Grid2 size={6}>
            <Controller
              name="invoice_number"
              control={form.control}
              rules={{
                required: "Invoice number is required",
                minLength: {
                  value: 1,
                  message: "Minimum length is 1 characters",
                },
                maxLength: {
                  value: 49,
                  message: "Maximum length is 49 characters",
                },
                validate: validateNotWhitespaceOnly,
              }}
              render={({ field }) => (
                <GKTextField
                  {...field}
                  inputLabel="Invoice Number"
                  error={Boolean(form?.formState?.errors.invoice_number)}
                  helperText={form?.formState?.errors.invoice_number?.message}
                />
              )}
            />
          </Grid2>
          <Grid2 size={12}>
            <Controller
              name="remarks"
              control={form.control}
              render={({ field }) => (
                <GKTextArea {...field} inputLabel="Remarks" />
              )}
            />
          </Grid2>
          {/* <Grid2 item xs={6}>
            <Controller
              name="isUpdateHighwaterMark"
              control={form.control}
              render={({ field }) => {
                return (
                  <FormControlLabel
                    {...field}
                    label="Update High waterMark"
                    control={
                      <GKCheckbox
                        value={field.value}
                        onChange={(event) => {
                          field.onChange(event.target.checked);
                        }}
                      />
                    }
                  />
                );
              }}
            />
          </Grid2> */}
        </Grid2>
      </form>
    </GKModal>
  );
};

export default ConvertToInvoiceModal;
