import { LoadingButton } from "@mui/lab";
import { useAppContext } from "AppContext";
import {
  Divider,
  GKSearchSelect,
  GKTextField,
  Grid2,
  SimpleLoader,
  TextField,
  Typography,
} from "components";
import GKImageUploader from "components/GKImageUploader";
import { useFetchQuery, useMutation } from "hooks/useQueries";
import { isEmpty } from "lodash";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { handleFreshReturnFormData } from "utils";
import axiosInstance from "utils/axiosInstance";
import { BANK_ACCOUNT_TYPES } from "utils/constants";
import { handleReturnAutocompletePlaceholder } from "utils/StringFunctions";
import { ThemeContainer } from "utils/Theme";

const Billing = () => {
  const { theme } = ThemeContainer();

  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [croppedImage, setCroppedImage] = useState<string | null>(null);

  const {
    state: { userData, countryList, stateList, cityList },
    dispatch,
  } = useAppContext();

  const { data, isFetching, refetch } = useFetchQuery({
    key: ["GET_BILLING_DETAILS"],
    route: `/billing-details/`,
    success: (response: any) => {
      setCroppedImage(response?.data?.qrCode);
    },
  });

  const { mutate } = useMutation({
    mutationKey: ["BILLING"],
    mutationFn: (data: any) =>
      axiosInstance.patch(`/billing-details/${userData?.id}/`, data),
    onSuccess: () => {
      refetch();
    },
  });

  const form = useForm({
    defaultValues: {
      pan: "",
      gst: "",
      cinCode: "",
      sacCode: "",
      address: "",
      pincode: "",
      country: "",
      state: "",
      city: "",
      accountName: "",
      accountNumber: "",
      accountType: "",
      bankName: "",
      ifscCode: "",
      qrCode: null,
    },
    values: data?.data?.data,
  });

  if (isFetching) return <SimpleLoader />;

  const handlePhotoDelete = (field: string) => {
    const formData = new FormData();
    formData.append(field, "");
    mutate(formData);
  };

  const onSubmit = (values: any) => {
    let newObject = { ...values };

    if (isEmpty(newObject?.qrCode) || typeof newObject?.qrCode === "string") {
      delete newObject.qrCode;
    } else {
      newObject = { ...newObject, qrCode: values.qrCode[0] };
    }

    mutate(
      handleFreshReturnFormData({
        isEditForm: false,
        obj: {
          ...newObject,
        },
      }),
    );
  };

  return (
    <form onSubmit={form.handleSubmit(onSubmit)} id="billing-form">
      <Grid2 container spacing={2}>
        <Grid2 size={3}>
          <Typography
            fontSize={16}
            fontWeight={500}
            color={theme.palette.secondaryText.main}
          >
            Invoicing Information
          </Typography>
        </Grid2>

        <Grid2 container size={9} spacing={2}>
          <Grid2 size={6}>
            <Controller
              name="pan"
              control={form.control}
              rules={{
                required: {
                  value: true,
                  message: "Please Enter PAN",
                },
              }}
              render={({ field, fieldState: { error } }) => {
                return (
                  <GKTextField
                    {...field}
                    inputLabel="PAN"
                    onChange={(e) =>
                      field.onChange(e.target.value.toUpperCase())
                    }
                    placeholder="Eg: ABSDF1230J"
                    requiredField
                    error={!!error}
                    helperText={error?.message}
                  />
                );
              }}
            />
          </Grid2>
          <Grid2 size={6}>
            <Controller
              name="gst"
              control={form.control}
              render={({ field, fieldState: { error } }) => (
                <GKTextField
                  {...field}
                  inputLabel="GSTN"
                  placeholder="GSTN"
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
          </Grid2>
          <Grid2 size={6}>
            <Controller
              name="cinCode"
              control={form.control}
              render={({ field, fieldState: { error } }) => (
                <GKTextField
                  {...field}
                  inputLabel="CIN Code"
                  placeholder="CIN Code"
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
          </Grid2>
          <Grid2 size={6}>
            <Controller
              name="sacCode"
              control={form.control}
              render={({ field, fieldState: { error } }) => (
                <GKTextField
                  {...field}
                  inputLabel="SAC Code"
                  placeholder="SAC Code"
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
          </Grid2>
        </Grid2>

        <Grid2 size={12} my={1}>
          <Divider />
        </Grid2>

        <Grid2 size={3}>
          <Typography
            fontSize={16}
            fontWeight={500}
            color={theme.palette.secondaryText.main}
          >
            Billing Address
          </Typography>
        </Grid2>

        <Grid2 container size={9} spacing={2}>
          <Grid2 size={12}>
            <Controller
              name="address"
              control={form.control}
              rules={{
                required: {
                  value: true,
                  message: "Please Enter Address",
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <GKTextField
                  {...field}
                  inputLabel="Address"
                  requiredField
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
          </Grid2>
          <Grid2 size={3}>
            <Controller
              name="country"
              control={form.control}
              rules={{
                required: {
                  value: true,
                  message: "Please Select Country",
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <GKSearchSelect
                  {...field}
                  inputLabel="Country"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder={handleReturnAutocompletePlaceholder({
                        placeholder: "Select Country",
                        value: field.value,
                      })}
                      error={!!error}
                      helperText={error?.message}
                    />
                  )}
                  requiredField
                  options={countryList || []}
                  getOptionLabel={(e) => e.name || e}
                  onChange={(e, val) => {
                    field.onChange(val.name);
                    form.setValue("city", "");
                    form.setValue("state", "");
                    dispatch({
                      type: "SELECTED_COUNTRY",
                      data: val.id,
                    });
                  }}
                />
              )}
            />
          </Grid2>
          <Grid2 size={3}>
            <Controller
              name="state"
              control={form.control}
              rules={{
                required: {
                  value: true,
                  message: "Please Select State",
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <GKSearchSelect
                  {...field}
                  inputLabel="State"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder={handleReturnAutocompletePlaceholder({
                        placeholder: "Select State",
                        value: field.value,
                      })}
                      error={!!error}
                      helperText={error?.message}
                    />
                  )}
                  requiredField
                  options={stateList || []}
                  getOptionLabel={(e) => e.name || e}
                  onChange={(e, val) => {
                    field.onChange(val.name);
                    form.setValue("city", "");
                    dispatch({
                      type: "SELECTED_STATE",
                      data: val.id,
                    });
                  }}
                />
              )}
            />
          </Grid2>
          <Grid2 size={3}>
            <Controller
              name="city"
              control={form.control}
              rules={{
                required: {
                  value: true,
                  message: "Please Select City",
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <GKSearchSelect
                  {...field}
                  inputLabel="City"
                  renderInput={(er) => (
                    <TextField
                      {...er}
                      placeholder={handleReturnAutocompletePlaceholder({
                        placeholder: "Select City",
                        value: field.value,
                      })}
                      error={!!error}
                      helperText={error?.message}
                    />
                  )}
                  requiredField
                  options={cityList || []}
                  getOptionLabel={(e) => e.name || e}
                  onChange={(e, val) => {
                    field.onChange(val.name);
                    dispatch({
                      type: "SELECTED_CITY",
                      data: val.id,
                    });
                  }}
                />
              )}
            />
          </Grid2>
          <Grid2 size={3}>
            <Controller
              name="pincode"
              control={form.control}
              rules={{
                required: {
                  value: true,
                  message: "Please Enter Pincode",
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <GKTextField
                  {...field}
                  type="number"
                  inputLabel="Pincode"
                  requiredField
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
          </Grid2>
        </Grid2>

        <Grid2 size={12} my={1}>
          <Divider />
        </Grid2>

        <Grid2 size={3}>
          <Typography
            fontSize={16}
            fontWeight={500}
            color={theme.palette.secondaryText.main}
          >
            Bank Details
          </Typography>
        </Grid2>

        <Grid2 container size={9} spacing={2}>
          <Grid2 size={4}>
            <Controller
              name="accountName"
              control={form.control}
              rules={{
                required: {
                  value: true,
                  message: "Please Enter Account Name",
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <GKTextField
                  {...field}
                  inputLabel="Account Name"
                  requiredField
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
          </Grid2>

          <Grid2 size={4}>
            <Controller
              name="accountNumber"
              control={form.control}
              rules={{
                required: {
                  value: true,
                  message: "Please Enter Account Number",
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <GKTextField
                  {...field}
                  type="number"
                  inputLabel="Account Number"
                  placeholder="Enter your name as per account"
                  requiredField
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
          </Grid2>
          <Grid2 size={4}>
            <Controller
              name="accountType"
              control={form.control}
              rules={{
                required: {
                  value: true,
                  message: "Please Select Account Type",
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <GKSearchSelect
                  {...field}
                  disableClearable={false}
                  inputLabel="Account Type"
                  options={BANK_ACCOUNT_TYPES || []}
                  onChange={(e, val) => {
                    field.onChange(val?.value);
                  }}
                  value={
                    BANK_ACCOUNT_TYPES?.find(
                      (option: any) => field.value === option.value,
                    )?.name || []
                  }
                  getOptionLabel={(option) => option.name || option}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder={handleReturnAutocompletePlaceholder({
                        placeholder: "Select Account Type",
                        value: field.value,
                      })}
                      error={!!error}
                      helperText={error?.message}
                    />
                  )}
                  requiredField
                />
              )}
            />
          </Grid2>
          <Grid2 size={6}>
            <Controller
              name="bankName"
              control={form.control}
              rules={{
                required: {
                  value: true,
                  message: "Please Enter Bank Name",
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <GKTextField
                  {...field}
                  inputLabel="Bank Name"
                  requiredField
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
          </Grid2>
          <Grid2 size={6}>
            <Controller
              name="ifscCode"
              control={form.control}
              rules={{
                required: {
                  value: true,
                  message: "Please Enter IFSC Code",
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <GKTextField
                  {...field}
                  inputLabel="IFSC Code"
                  requiredField
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
          </Grid2>
        </Grid2>

        <Grid2 size={12} my={1}>
          <Divider />
        </Grid2>

        <Grid2 size={3}>
          <Typography
            fontSize={16}
            fontWeight={500}
            color={theme.palette.secondaryText.main}
          >
            Upload QR Code
          </Typography>
        </Grid2>

        <Grid2 container size={9} spacing={2}>
          <Grid2 container size={12} gap={1}>
            <div>
              <div
                style={{ display: "flex", alignItems: "center", gap: "8px" }}
              >
                <Grid2 size={12}>
                  <Controller
                    name="qrCode"
                    control={form.control}
                    render={({ field }) => (
                      <GKImageUploader
                        croppedImage={croppedImage || field.value}
                        setCroppedImage={setCroppedImage}
                        handleProfilePicDelete={() =>
                          handlePhotoDelete("qrCode")
                        }
                        selectedImage={selectedImage}
                        setSelectedImage={setSelectedImage}
                        form={form}
                        logoName="qrCode"
                        buttonName="Upload QR Code"
                      />
                    )}
                  />
                </Grid2>
              </div>

              <Grid2 mt={2} ml={1.5}>
                <Typography color="#9AA4B2">
                  Format should be JPG or PNG, 3:4 aspect ratio, up to 10MB
                </Typography>
              </Grid2>
            </div>
          </Grid2>
        </Grid2>

        <Grid2 size={12} my={1}>
          <Divider />
        </Grid2>

        <Grid2 size={12} textAlign={"right"} my={1}>
          <LoadingButton form="billing-form" variant="contained" type="submit">
            Save
          </LoadingButton>
        </Grid2>
      </Grid2>
    </form>
  );
};

export default Billing;
