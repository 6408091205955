import { LoadingButton } from "@mui/lab";
import { GKModal, GKOtpInput, Grid2 } from "components";
import { useMutation } from "hooks/useQueries";
import { PaymentGatewayFormValues } from "interfaces/PaymentGateway";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import axiosInstance from "utils/axiosInstance";
import { GKModalProps } from "utils/commonInterface";

interface Props {
  companyId: string | number;
}

const VerifyAccountModal = (props: GKModalProps & Props) => {
  const { open, setOpen, companyId } = props;

  const form = useFormContext();

  const [otpCode, setOtpCode] = useState("");
  const [isDoneTyping, setIsDoneTyping] = useState(false);

  const { mutate: razorPayAccountLink } = useMutation({
    mutationKey: ["ACCOUNT_LINK"],
    mutationFn: (data: PaymentGatewayFormValues) =>
      axiosInstance.patch(
        `/company/${companyId}/payment-gateway-configurations/`,
        data,
      ),
    onSuccess: () => {
      setOpen(false);
    },
  });

  const { mutate: handleVerifyOPT } = useMutation({
    mutationFn: (otpCode: any) =>
      axiosInstance.post(`/company-otp/verify/`, {
        otp: otpCode,
      }),
    onSuccess: (response) => {
      form.setValue("token", response?.data?.data?.token);
      razorPayAccountLink(form.getValues() as PaymentGatewayFormValues);
    },
  });

  const { mutate: handleGetOPT, isLoading } = useMutation({
    mutationFn: (data: any) => axiosInstance.post(`/company-otp/${data}/`),
  });

  useEffect(() => {
    if (isDoneTyping) handleVerifyOPT(otpCode);
  }, [isDoneTyping]);

  return (
    <GKModal
      open={open}
      setOpen={setOpen}
      modalTitle="Verify OTP"
      titleComponent={
        <LoadingButton
          variant="outlined"
          size="medium"
          loading={isLoading}
          onClick={() => {
            handleGetOPT("resend");
          }}
        >
          Resend OTP
        </LoadingButton>
      }
    >
      <Grid2 container spacing={1}>
        <Grid2 size={12}>
          <GKOtpInput
            value={otpCode}
            onChange={setOtpCode}
            setIsTyping={setIsDoneTyping}
          />
        </Grid2>
      </Grid2>
    </GKModal>
  );
};

export default VerifyAccountModal;
