import { GridColDef } from "@mui/x-data-grid-pro";
import {
  CgMathPlus,
  ConfirmDeleteModal,
  FiTrash2,
  GKButton,
  GKDataGrid,
  Grid2,
  IconButton,
  MdEdit,
  PiWarningOctagonLight,
  Show,
  StatusField,
  Tooltip,
  Typography,
} from "components";
import dayjs from "dayjs";
import useGenerateQueryParams from "hooks/useGenerateQueryParams";
import { useFetchQuery, useMutation } from "hooks/useQueries";
import { useState } from "react";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { paletteLightTheme } from "utils/ColorTheme";
import { moneyCommaSeparator } from "utils/MathFunction";
import { FEE_CALCULATION_HISTORY } from "utils/apiRoutes";
import axiosInstance from "utils/axiosInstance";
import { extraColumnsField, matchDate } from "utils/helpers";
import useDocumentTitle from "utils/useDocumentTitle";
import CalculationProformaModal from "./CalculationProformaModal";

interface Props {
  name?: string;
  route?: string;
  tableName?: string;
}

const RevenueFeeCalculations = (props: Props) => {
  useDocumentTitle("Fee Calculation");

  const { name, route, tableName } = props;

  const { id } = useParams();
  const navigate = useNavigate();

  const [stateModal, setStateModal] = useState(false);
  const [calculationData, setCalculationData] = useState();
  const [deleteCalculationId, setDeleteCalculationId] = useState(null);
  const [confirmationModal, setConfirmationModal] = useState(false);

  const requestBody = useGenerateQueryParams(name || "feeCalculation", {
    portfolio_id: id,
  });

  const { data, isFetching, refetch } = useFetchQuery({
    key: ["CALCULATION _LIST", requestBody],
    route: FEE_CALCULATION_HISTORY,
    requestBody,
  });

  const { mutate: handleDeleteMutation } = useMutation({
    mutationKey: ["DELETE_CALCULATION"],
    mutationFn: ({ id }: { id: any }) =>
      axiosInstance.delete(`/fee-calculation-history/${id}/`),
    onSuccess: () => {
      refetch();
    },
  });

  const columns: GridColDef[] = [
    {
      ...extraColumnsField("Portfolio Name", "clientName", 250, 1),
      renderCell: (params) => {
        return (
          <Grid2
            container
            display={"flex"}
            flexDirection={"column"}
            alignItems={"flex-start"}
          >
            <Typography fontWeight={700}>{params?.row?.clientName}</Typography>
            <Typography fontSize={12}>
              {params?.row?.portfolioName} - {params?.row?.portfolioId}
            </Typography>
          </Grid2>
        );
      },
    },
    {
      ...extraColumnsField("Calculation Date", "calculationDate", 120, 1),
      type: "date",
      valueFormatter: (value) => matchDate(value.value),
      renderCell: (props) => {
        return (
          <Typography>
            {dayjs(props?.row?.calculationDate).format("D MMM YYYY")}
          </Typography>
        );
      },
    },
    {
      ...extraColumnsField("Fee Amount", "feeAmount", 100, 1),
      valueGetter: (row) => row?.row?.feeAmount,
      valueFormatter: (params) => moneyCommaSeparator(params.value) || 0,
      type: "number",
    },
    {
      ...extraColumnsField("Fee Plan", "feePlanName", 150, 1),
    },
    {
      ...extraColumnsField("Fee Date Range", "feeDateRange", 200, 1),
    },
    {
      ...extraColumnsField("Proforma Created", "proformaCreated", 150, 1),
      renderCell: (row) => (
        <StatusField statusString={row?.row?.isProforma.toString()} />
      ),
    },
    {
      ...extraColumnsField("Auto/Manual", "generatedType", 150, 1),
      renderCell: (row) => (
        <StatusField statusString={row?.row?.generatedType} />
      ),
    },
    {
      ...extraColumnsField("Actions", "action", 100, 1),
      headerAlign: "right",
      align: "right",
      type: "action",
      renderCell: (params) => {
        return (
          <Grid2 container justifyContent={"flex-end"} gap={1}>
            <Tooltip title="Create Proforma" arrow>
              <IconButton
                disabled={params.row.isProforma}
                onClick={(event) => {
                  event.stopPropagation();
                  if (params.row.isHistoricallyMigrated) {
                    toast.success(
                      "This fee calculation cannot be viewed because it is historically migrated",
                    );
                  } else {
                    setCalculationData(params.row.id);
                    setStateModal(true);
                  }
                }}
              >
                <MdEdit size={20} />
              </IconButton>
            </Tooltip>

            <IconButton
              color="error"
              className="outline"
              onClick={(e) => {
                e.stopPropagation();
                setDeleteCalculationId(params.row.id);
                setConfirmationModal(true);
              }}
            >
              <FiTrash2 size={20} />
            </IconButton>
          </Grid2>
        );
      },
    },
  ];

  return (
    <Grid2 container>
      <GKDataGrid
        loading={isFetching}
        columns={columns}
        rows={data?.data?.data || []}
        maxHeight={"calc(100vh - 320px)"}
        totalCount={data?.data?.totalCount || 0}
        name={name || "feeCalculation"}
        tableName={tableName || "fee_calculation"}
        headerComponent={
          <GKButton
            variant="contained"
            onClick={() => {
              navigate(route || "/app/revenue-manager/create-calculation");
            }}
            startIcon={<CgMathPlus size={18} />}
          >
            Fee Calculation
          </GKButton>
        }
        onRowClick={(params) => {
          if (params.row.isHistoricallyMigrated) {
            toast(() => (
              <span
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  gap: 12,
                }}
              >
                <PiWarningOctagonLight
                  size={24}
                  color={paletteLightTheme.palette.warning.main}
                />
                <Typography>
                  This fee calculation cannot be viewed because it is
                  historically migrated
                </Typography>
              </span>
            ));
          } else {
            navigate(`/app/revenue-manager/${params.row.id}/edit-calculation`);
          }
        }}
      />

      <Show.When isTrue={stateModal}>
        <CalculationProformaModal
          open={stateModal}
          setOpen={setStateModal}
          calculationData={calculationData}
          refetch={refetch}
          portfolioId={id}
        />
      </Show.When>

      <Show.When isTrue={confirmationModal}>
        <ConfirmDeleteModal
          open={confirmationModal}
          setOpen={setConfirmationModal}
          onDelete={() => handleDeleteMutation({ id: deleteCalculationId })}
          title={"Calculation"}
          isDelete
        />
      </Show.When>
    </Grid2>
  );
};

export default RevenueFeeCalculations;
