/* eslint-disable import/no-named-as-default-member */
import { blueGrey, grey } from "@mui/material/colors";
import {
  Box,
  Button,
  GKTypography,
  Grid2,
  Show,
  Slide,
  Typography,
} from "components";
import { useMutation } from "hooks/useQueries";
import { AdvisorOnboardFormInterface } from "interfaces/OnboardingInterface";
import { split } from "lodash";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
import axiosInstance from "utils/axiosInstance";
import { handleReturnRiskColors } from "utils/colorHelper";
import { refetchQuery } from "utils/helpers";
import { QueryKeys } from "utils/queryKey";
import { ThemeContainer } from "utils/Theme";

const AdvisorOnboardingRiskProfile = () => {
  const form = useFormContext<AdvisorOnboardFormInterface>();

  const [currentQuestion, setCurrentQuestion] = useState(0);

  const [checked, setChecked] = useState(true);

  const [answers, setAnswers] = useState<any[]>([]);

  const { theme } = ThemeContainer();

  // XXX===XXX===XXX===XXX===XXX===XXX===XXX===XXX===XXX===XXX===XXX===
  const { mutate: handleRiskProfile } = useMutation({
    mutationKey: ["HANDLE_RISK_PROFILE_MUTATUION"],
    mutationFn: ({
      clientId,
      portfolioId,
      riskAnswers,
    }: {
      clientId: any;
      portfolioId: any;
      riskAnswers: any;
    }) =>
      axiosInstance.post(`/riskprofileresponse/${clientId}/`, {
        client: clientId,
        portfolio: portfolioId,
        response: riskAnswers,
      }),
    onSuccess: () => {
      setChecked(true);
      setCurrentQuestion(0);
      refetchQuery(QueryKeys.GET_CLIENT_BY_ID);
      refetchQuery(QueryKeys.GET_COMPLIANCE_BY_CLIENT_ID);
    },
    onError: () => {
      setChecked(true);
      setCurrentQuestion(0);
    },
  });

  // XXX===XXX===XXX===XXX===XXX===XXX===XXX===XXX===XXX===XXX===XXX===
  const current =
    form.getValues("riskProfileObject.riskQuestions") &&
    form.getValues("riskProfileObject.riskQuestions")[currentQuestion];

  // XXX===XXX===XXX===XXX===XXX===XXX===XXX===XXX===XXX===XXX===XXX===

  const riskProfileMatch =
    +form.getValues("riskProfile.points") >
    +split(form.getValues("basketObject.riskProfilePoints") || "0 - 0", " ")[0];

  // XXX===XXX===XXX===XXX===XXX===XXX===XXX===XXX===XXX===XXX===XXX===

  const handleNextQuestion = (option: any) => {
    setChecked(false);
    setAnswers((prevAnswers: any) => [
      ...prevAnswers,
      {
        question: current?.id,
        option: option.id,
        optionPoints: option.optionPoints,
      },
    ]);
    if (
      currentQuestion + 1 ===
      form.getValues("riskProfileObject.riskQuestions").length
    ) {
      handleRiskProfile({
        clientId: form.getValues("id"),
        portfolioId: form.getValues("portfolioObject.id"),
        riskAnswers: answers,
      });
    } else {
      setTimeout(() => {
        setChecked(true);
        setCurrentQuestion((prev) => prev + 1);
      }, 300);
    }
  };

  const clientPoint =
    ((form.getValues("riskProfile.points") -
      form.getValues("riskProfileObject.lowPoints")) /
      (+form.getValues("riskProfileObject.highPoints") -
        form.getValues("riskProfileObject.lowPoints"))) *
    100;

  return (
    <form
      id="advisor-onboarding-risk-profile-form"
      onSubmit={form.handleSubmit(() => {
        form.setValue("activeStep", form.getValues("activeStep") + 1);
      })}
    >
      <Grid2 container spacing={2} overflow={"hidden auto"}>
        <Show.When isTrue={Boolean(form.getValues("riskProfile.points"))}>
          <Grid2
            size={12}
            bgcolor={"#F8FAFC"}
            p={2}
            borderRadius={2}
            textAlign={"center"}
          >
            <Typography color={blueGrey[600]}>
              Your risk score is{" "}
              <span
                style={{
                  fontWeight: 600,
                  color: theme.palette.dark.main,
                }}
              >
                {form.getValues("riskProfile.points")}
              </span>{" "}
              and risk profile status is{" "}
              <span
                style={{
                  fontWeight: 600,
                  color: handleReturnRiskColors(
                    form.getValues("riskProfile.riskStatus") || "-",
                  ),
                }}
              >
                {form.getValues("riskProfile.riskStatus")}
              </span>
            </Typography>
          </Grid2>
          <Grid2 size={12} bgcolor={"#F8FAFC"} p={4} borderRadius={2}>
            <Box
              sx={{
                width: "100%",
                position: "relative",
                background: `linear-gradient(90deg, rgba(255,0,0,1) 0%, rgba(235,233,58,1) 51%, rgba(141,224,43,1) 100%)`,
                height: 10,
                borderRadius: 2,
                "::before": {
                  content: `" "`,
                  position: "absolute",
                  height: "150%",
                  width: "2px",
                  background: "#000000",
                  top: -2,
                  left: `${clientPoint}%`,
                },
                "::after": {
                  content: `"${form.getValues("riskProfile.points")}"`,
                  position: "absolute",
                  height: "100%",
                  left: `${clientPoint}%`,
                  top: 16,
                  fontSize: 12,
                  fontWeight: 600,
                },
              }}
            >
              <div
                style={{
                  position: "absolute",
                  bottom: -20,
                  left: 0,
                  fontSize: 12,
                  color: "#9AA4B2",
                }}
              >
                {form.getValues("riskProfileObject.lowPoints")}
              </div>
              <div
                style={{
                  position: "absolute",
                  top: -20,
                  left: 0,
                  color: "#9AA4B2",
                  fontSize: 12,
                }}
              >
                {form.getValues("riskProfileObject.pointsDetails") &&
                  form.getValues("riskProfileObject.pointsDetails")[0]}
              </div>
              <Show.When
                isTrue={
                  form.getValues("riskProfileObject.pointsDetails")?.length ===
                  3
                }
              >
                <div
                  style={{
                    position: "absolute",
                    bottom: -20,
                    right: 0,
                    color: "#9AA4B2",
                    fontSize: 12,
                  }}
                >
                  {form.getValues("riskProfileObject.highPoints")}
                </div>
                <div
                  style={{
                    position: "absolute",
                    top: -20,
                    left: 0,
                    fontSize: 12,
                    color: "#9AA4B2",
                    right: 0,
                    marginInline: "auto",
                    width: "fit-content",
                  }}
                >
                  {form.getValues("riskProfileObject.pointsDetails") &&
                    form.getValues("riskProfileObject.pointsDetails")[1]}
                </div>
                <div
                  style={{
                    position: "absolute",
                    color: "#9AA4B2",
                    top: -20,
                    right: 0,
                    fontSize: 12,
                  }}
                >
                  {form.getValues("riskProfileObject.pointsDetails") &&
                    form.getValues("riskProfileObject.pointsDetails")[2]}
                </div>
              </Show.When>
              <Show.When
                isTrue={
                  form.getValues("riskProfileObject.pointsDetails")?.length ===
                  5
                }
              >
                <div
                  style={{
                    position: "absolute",
                    bottom: -20,
                    right: 0,
                    color: "#9AA4B2",
                    fontSize: 12,
                  }}
                >
                  {form.getValues("riskProfileObject.highPoints")}
                </div>
                <div
                  style={{
                    position: "absolute",
                    top: -20,
                    left: "25%",
                    fontSize: 12,
                    color: "#9AA4B2",
                  }}
                >
                  {form.getValues("riskProfileObject.pointsDetails") &&
                    form.getValues("riskProfileObject.pointsDetails")[1]}
                </div>
                <div
                  style={{
                    position: "absolute",
                    top: -20,
                    left: "50%",
                    fontSize: 12,
                    color: "#9AA4B2",
                  }}
                >
                  {form.getValues("riskProfileObject.pointsDetails") &&
                    form.getValues("riskProfileObject.pointsDetails")[2]}
                </div>
                <div
                  style={{
                    position: "absolute",
                    top: -20,
                    left: "74%",
                    fontSize: 12,
                    color: "#9AA4B2",
                  }}
                >
                  {form.getValues("riskProfileObject.pointsDetails") &&
                    form.getValues("riskProfileObject.pointsDetails")[3]}
                </div>
                <div
                  style={{
                    position: "absolute",
                    color: "#9AA4B2",
                    top: -20,
                    right: 0,
                    fontSize: 12,
                  }}
                >
                  {form.getValues("riskProfileObject.pointsDetails") &&
                    form.getValues("riskProfileObject.pointsDetails")[4]}
                </div>
              </Show.When>
            </Box>
          </Grid2>
          <Grid2 size={12} textAlign={"center"}>
            {riskProfileMatch ? (
              <img src="/Images/risk-success.svg" alt="" />
            ) : (
              <img src="/Images/risk-error.svg" alt="" />
            )}
          </Grid2>
          <Grid2 size={12} textAlign={"center"}>
            <Typography fontWeight={600}>
              {riskProfileMatch
                ? "Congratulations! Your risk profile matches with the basket’s risk profile, we can move ahead with the subscription."
                : "Your risk profile does not align with this investment. Do you still wish to proceed with the selected basket?"}
            </Typography>
          </Grid2>
          <Show.When isTrue={!riskProfileMatch}>
            <Grid2
              size={12}
              display={"flex"}
              gap={1}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Button
                variant="contained"
                onClick={() => {
                  form.setValue("activeStep", form.getValues("activeStep") + 1);
                }}
              >
                Yes
              </Button>
              <Typography color={grey[500]} fontWeight={600}>
                OR
              </Typography>
              <Button
                variant="outlined"
                onClick={() => {
                  form.setValue("riskProfile", null);
                  setChecked(true);
                  setCurrentQuestion(0);
                }}
              >
                Retake Assessment
              </Button>
            </Grid2>
          </Show.When>
        </Show.When>
      </Grid2>
      <Show.When isTrue={!form.getValues("riskProfile.points")}>
        <Grid2 container minHeight={"250px"}>
          <div
            style={{
              margin: "auto",
              overflow: "none",
              width: "50%",
            }}
          >
            {form.getValues("riskProfileObject.riskQuestions")?.length && (
              <Slide direction="left" in={checked} mountOnEnter unmountOnExit>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: "column",
                    overflow: "none",
                    gap: 16,
                    width: "100%",
                  }}
                >
                  <GKTypography fontSize={14} textAlign={"center"}>
                    {current.questionText}
                  </GKTypography>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: "column",
                      gap: 8,
                      overflow: "none",
                    }}
                  >
                    {current.options.map((opt: any, index: number) => (
                      <Button
                        fullWidth
                        key={index}
                        color={"dark"}
                        variant={"outlined"}
                        onClick={() => handleNextQuestion(opt)}
                      >
                        {opt.option}
                      </Button>
                    ))}
                  </div>
                </div>
              </Slide>
            )}
          </div>
        </Grid2>
      </Show.When>
    </form>
  );
};

export default AdvisorOnboardingRiskProfile;
