import { GridColDef } from "@mui/x-data-grid-pro";
import { StatusField } from "components";
import GKButton from "components/GKButton";
import GKDataGrid from "components/GKDataGrid";
import { Show } from "components/Show";
import useGenerateQueryParams from "hooks/useGenerateQueryParams";
import { useFetchQuery } from "hooks/useQueries";
import { useState } from "react";
import { CgMathPlus } from "react-icons/cg";
import { useNavigate } from "react-router-dom";
import { moneyCommaSeparator } from "utils";
import { ADVISOR_COMPANY_MASTER_LIST } from "utils/apiRoutes";
import { extraColumnsField, matchDate } from "utils/helpers";
import CompanyModal from "./CompanyModal";

interface Props {
  name: string;
  tableName?: string;
  type: string;
  routeKey: string;
  addBtnName?: string;
}

const PrivateEquity = (props: Props) => {
  const { name, type, routeKey, addBtnName } = props;

  const navigate = useNavigate();

  const [openCompanyModal, setOpenCompanyModal] = useState(false);

  const requestBody = useGenerateQueryParams(name, { inst_type: type });

  const { data, isFetching } = useFetchQuery({
    key: [routeKey, requestBody],
    route: ADVISOR_COMPANY_MASTER_LIST,
    requestBody,
  });

  const columns: (GridColDef & { visibleType?: string[] })[] = [
    {
      ...extraColumnsField("Start Date", "startingDate", 100, 1),
      valueFormatter: (params) => matchDate(params?.value),
      visibleType: ["PEQ", "FD", "AIF"],
    },
    {
      ...extraColumnsField("Company Name", "name", 250, 1),
      visibleType: ["PEQ", "FD", "AIF"],
    },
    {
      ...extraColumnsField("Short Name", "shortName", 150, 1),
      visibleType: ["PEQ", "FD", "AIF"],
    },
    { ...extraColumnsField("Cap", "cap", 100, 1) },
    {
      ...extraColumnsField("CMP", "cmp", 100, 1),
      valueGetter: (row) => moneyCommaSeparator(row?.row?.cmp) || 0,
    },
    {
      ...extraColumnsField("Tenure", "tenure", 100, 1),
      valueGetter: (row) => row?.row?.otherAssetMeta?.tenure || 0,
      visibleType: ["FD"],
    },
    {
      ...extraColumnsField("Frequency", "frequency", 100, 1),
      valueGetter: (row) => row?.row?.otherAssetMeta?.frequency || 0,
      visibleType: ["FD"],
    },
    {
      ...extraColumnsField("Interest Rate", "interestRate", 120, 1),
      valueGetter: (row) => `${row?.row?.otherAssetMeta?.interestRate}%` || 0,
      visibleType: ["FD"],
    },
    {
      ...extraColumnsField("Status", "status", 100, 1),
      valueFormatter: (params) => params.value || "-",
      renderCell: (params) => {
        return <StatusField statusString={params?.row?.status || "N/A"} />;
      },
      visibleType: ["PEQ", "FD", "AIF"],
    },
  ];

  return (
    <>
      <GKDataGrid
        rows={data?.data?.data || []}
        columns={columns.filter((column) => {
          return column.visibleType?.includes(type);
        })}
        loading={isFetching}
        headerComponent={
          <GKButton
            variant="contained"
            size="medium"
            onClick={() => setOpenCompanyModal(true)}
            startIcon={<CgMathPlus size={18} />}
          >
            {`Add ${addBtnName}`}
          </GKButton>
        }
        name={name}
        // tableName={tableName}
        columnsButton={false}
        onRowClick={(params) =>
          navigate(`/company-master/equity/${params?.row?.gcode}`)
        }
        totalCount={data?.data?.totalCount || 0}
      />

      <Show.When isTrue={openCompanyModal}>
        <CompanyModal
          open={openCompanyModal}
          setOpen={setOpenCompanyModal}
          type={type}
          refetchKey={routeKey}
        />
      </Show.When>
    </>
  );
};

export default PrivateEquity;
