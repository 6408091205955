import { Grid2 } from "components";
import { useLocation } from "react-router-dom";
import { ThemeContainer } from "utils/Theme";
import WelcomePage from "./WelcomePage";

const InvestorOnboarding = () => {
  const { theme } = ThemeContainer();

  const location = useLocation();

  const logo = location.search.split("logo=")[1];

  return (
    <Grid2
      container
      bgcolor="#F8FAFC"
      height="100vh"
      width={"100vw"}
      overflow={"auto"}
    >
      <Grid2
        size={12}
        py={2}
        px={4}
        display="flex"
        alignItems="center"
        borderBottom={`1px solid ${theme.palette.divider}`}
      >
        <img
          src={logo || "/Logos/iconFullLogo.png"}
          alt=""
          height={`-webkit-fill-available`}
          width={"auto"}
          style={{ maxHeight: "32px" }}
          onError={({ currentTarget }) => {
            currentTarget.src = "https://gridkey.in/Logos/iconFullLogo.png";
          }}
        />
      </Grid2>
      <Grid2
        size={12}
        sx={{ p: { xs: 2, md: 10 } }}
        maxHeight={"calc(100vh - 65px)"}
        minHeight={"calc(100vh - 65px)"}
        overflow={"auto"}
      >
        <WelcomePage />
      </Grid2>
    </Grid2>
  );
};

export default InvestorOnboarding;
