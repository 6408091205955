import { blueGrey } from "@mui/material/colors";
import { useAppContext } from "AppContext";
import {
  Box,
  FaFileSignature,
  FiShoppingBag,
  Grid2,
  HiOutlineOfficeBuilding,
  List,
  ListItem,
  ListItemText,
  LuDownload,
  LuFileCheck2,
  LuFileSpreadsheet,
  LuGlobe,
  LuLogIn,
  LuMail,
  LuSettings,
  LuSettings2,
  LuUsers,
  PiIdentificationCard,
  PiInvoice,
  PiUserCircleLight,
  RiListRadio,
  TbInvoice,
  TbSitemap,
  Typography,
  styled,
} from "components";
import { UserInfoInterface } from "interfaces/User";
import DashboardLayout from "layouts/DashboardLayout";
import CompanyInformationPage from "Pages/CompanyInformation";
import Mandate from "Pages/Mandate";
import MarketingMaterial from "Pages/MarketingMaterial";
import MutualFundAutoImportSettings from "Pages/MutualFundAutoImport/MutualFundAutoImportSettings";
import PaymentGateway from "Pages/PaymentGateway";
import RightIssue from "Pages/RightIssue";
import CompanyMappingPreferences from "Pages/Settings/CompanyMappingPreferences";
import CustomAgreement from "Pages/Settings/CustomAgreement";
import EquityBasketSetting from "Pages/Settings/EquityBasketSetting";
import ExecutionSheet from "Pages/Settings/ExecutionSheet";
import GeneralPreferences from "Pages/Settings/GeneralPreferences";
import KYCRegistrationAgency from "Pages/Settings/KYCRegistrationAgency";
import RevenueManagerSettings from "Pages/Settings/RevenueManagerSettings";
import RiskProfiling from "Pages/Settings/RiskProfiling";
import SmtpSetup from "Pages/Settings/SmtpSetup";
import UserProfile from "Pages/Settings/UserProfile";
import { CgWebsite } from "react-icons/cg";
import { GiStamper } from "react-icons/gi";
import { LuCreditCard, LuMegaphone } from "react-icons/lu";
import { SETTING_TAB } from "store/slices/appSlice";
import { useAppDispatch, useAppSelector } from "store/store";
import { paletteLightTheme } from "utils/ColorTheme";
import { UserRoleTypes } from "utils/common";
import { ThemeContainer } from "utils/Theme";
import useDocumentTitle from "utils/useDocumentTitle";
import Billing from "./Billing";
import BrokerConfiguration from "./BrokerConfiguration";
import Digio from "./Digio";
import ComplianceSettings from "./InvesterWebsite/ComplianceSettings";
import WebsiteConfigurations from "./InvesterWebsite/WebsiteConfigurations";
import WebsiteSettings from "./InvesterWebsite/WebsiteSettings";
import LedgerSettings from "./LedgerSettings";
import OnBoardingFlowTab from "./OnBoardingFlow";
import PortfolioMetrics from "./PortfolioMetrics";
import RoleWisePermission from "./RoleWisePermission";

const Sidebar = styled(Box)(({ theme }) => ({
  borderRight: `1px solid ${theme.palette.divider}`,
}));

const returnSettingTab = (userInfo: UserInfoInterface) => {
  const menuItems = [
    {
      text: "Account",
      children: [
        {
          text: "User Profile",
          component: "USER_PROFILE",
          icon: PiUserCircleLight,
          show: true,
        },
        {
          text: "Company Profile",
          component: "COMPANY_PROFILE",
          icon: HiOutlineOfficeBuilding,
          show: true,
        },
        {
          text: "Billing",
          component: "BILLING",
          icon: PiInvoice,
          show: true,
        },
      ],
    },
    {
      text: "Onboarding",
      children: [
        {
          text: "Risk Profiling",
          component: "RISK_PROFILING",
          icon: RiListRadio,
          show: true,
        },
        {
          text: "Custom Agreement",
          component: "CUSTOM_AGREEMENT",
          icon: FaFileSignature,
          show: true,
        },
        {
          text: "KRA/KYC",
          component: "KYC_REGISTRATION_AGENCY",
          icon: PiIdentificationCard,
          show: true,
        },
        {
          text: "Onboarding Flow",
          component: "ONBOARDING_FLOW",
          icon: LuLogIn,
          show: true,
        },
      ],
    },
    {
      text: "Subscription",
      children: [
        {
          text: "Website",
          component: "WEBSITE",
          icon: LuGlobe,
          show: userInfo?.broker?.isRaAccess,
        },
        {
          text: "Configuration",
          component: "WEBSITE_CONFIGURATIONS",
          icon: CgWebsite,
          show: true,
        },
        {
          text: "Compliance",
          component: "COMPLIANCE",
          icon: LuFileSpreadsheet,
          show: true,
        },
      ],
    },
    {
      text: "Preferences",
      children: [
        {
          text: "General Preferences",
          component: "GENERAL_PREFERENCES",
          icon: LuSettings,
          show: true,
        },
        {
          text: "Portfolio Metrics",
          component: "PORTFOLIO_METRICS",
          icon: LuSettings2,
          show: true,
        },
        {
          text: "Invoice Preferences",
          component: "ANNEXURE_FOR_INVOICE",
          icon: TbInvoice,
          show: true,
        },
        {
          text: "Company Mapping",
          component: "COMPANY_MAPPING",
          icon: TbSitemap,
          show: true,
        },
        {
          text: "Right Issue",
          component: "RIGHT_ISSUE",
          icon: LuFileCheck2,
          show: true,
        },
        {
          text: "Basket",
          component: "BASKET",
          icon: FiShoppingBag,
          show: true,
        },
        {
          text: "Order & Execution",
          component: "EXECUTION_SHEET",
          icon: LuFileSpreadsheet,
          show: true,
        },
        {
          text: "Marketing Material",
          component: "MARKETING_MATERIAL",
          icon: LuMegaphone,
          show: true,
        },
      ],
    },
    {
      text: "Roles & Permissions",
      children: [
        {
          text: "Employee",
          component: "EMPLOYEE",
          icon: LuUsers,
          show: true,
        },
        {
          text: "Client",
          component: "CLIENT",
          icon: LuFileSpreadsheet,
          show: true,
        },
        {
          text: "Partner",
          component: "PARTNER",
          icon: LuFileSpreadsheet,
          show: true,
        },
      ],
    },
    {
      text: "Integration & Setup",
      children: [
        {
          text: "Email Configuration",
          component: "EMAIL_CONFIGURATION",
          icon: LuMail,
          show: true,
        },

        {
          text: "Payment Gateway",
          component: "PAYMENT_GATEWAY",
          icon: LuCreditCard,
          show: true,
        },
        {
          text: "Digio",
          component: "DIGIO",
          icon: GiStamper,
          show: true,
        },
        // {
        //   text: "Mandate",
        //   component: "MANDATE",
        //   icon: LuFileCog,
        // },
      ],
    },
    {
      text: "Broker Configuration",
      children: [
        {
          text: "IIFL",
          component: "BROKER_CONFIGURATION",
          icon: LuFileSpreadsheet,
          show: userInfo?.brokerSupport?.iiflSupport,
        },
      ],
    },
    {
      text: "Mutual Fund",
      children: [
        {
          text: "Auto Import",
          component: "AUTO_IMPORT",
          icon: LuDownload,
          show: true,
        },
      ],
    },
  ];

  return menuItems;
};

interface SidebarPropsInterface {
  dispatch: any;
  settingTab: {
    type: string;
    name: string;
  };
  userInfo: UserInfoInterface;
}

const SidebarMenu = (props: SidebarPropsInterface) => {
  const { dispatch, settingTab, userInfo } = props;

  const { theme } = ThemeContainer();

  return (
    <Sidebar>
      <List sx={{ pr: 2 }}>
        {returnSettingTab(userInfo).map((item, index) => {
          return (
            item.children.some((dataItem) => dataItem.show) && (
              <div key={index}>
                <Typography
                  mt={1}
                  fontSize={14}
                  fontWeight={500}
                  color={"#364152"}
                  px={2}
                  py={1}
                >
                  {item?.text}
                </Typography>
                {item?.children &&
                  item?.children?.map((item) => {
                    return (
                      item.show && (
                        <ListItem
                          key={item.text}
                          onClick={() => {
                            dispatch(
                              SETTING_TAB({
                                name: item.text,
                                type: item.component,
                              }),
                            );
                          }}
                          sx={{
                            backgroundColor:
                              settingTab.type === item.component
                                ? `${paletteLightTheme.palette.primary.main}11`
                                : "",
                            color:
                              settingTab.type === item.component
                                ? `${paletteLightTheme.palette.primary.main}`
                                : "",
                            borderRadius: "8px",
                            ":hover": {
                              cursor: "pointer",
                            },
                          }}
                        >
                          <item.icon
                            size={16}
                            color={
                              settingTab.type === item.component
                                ? `${paletteLightTheme.palette.primary.main}`
                                : blueGrey[500]
                            }
                          />
                          &nbsp;&nbsp;
                          <ListItemText
                            sx={{
                              fontSize: 14,
                              fontWeight: 500,
                              color:
                                settingTab.type === item.component
                                  ? `${paletteLightTheme.palette.primary.main}`
                                  : theme.palette.secondaryText.main,
                              "& .MuiTypography-root": {
                                lineHeight: "16px !important",
                              },
                            }}
                            primary={item.text}
                          />
                        </ListItem>
                      )
                    );
                  })}
              </div>
            )
          );
        })}
      </List>
    </Sidebar>
  );
};

const AccountSetting = () => {
  useDocumentTitle("Settings");

  const sidebarOpen = useAppSelector((state) => state?.app?.sidebarOpen);
  const settingTab = useAppSelector((state) => state?.app?.settingTab);

  const dispatch = useAppDispatch();

  const {
    state: { userData },
  } = useAppContext();

  const renderComponent = () => {
    switch (settingTab.type) {
      case "USER_PROFILE":
        return <UserProfile />;
      case "COMPANY_PROFILE":
        return <CompanyInformationPage />;
      case "RISK_PROFILING":
        return <RiskProfiling />;
      case "CUSTOM_AGREEMENT":
        return <CustomAgreement />;
      case "PORTFOLIO_METRICS":
        return <PortfolioMetrics />;
      case "ANNEXURE_FOR_INVOICE":
        return <RevenueManagerSettings />;
      case "COMPANY_MAPPING":
        return <CompanyMappingPreferences />;
      case "RIGHT_ISSUE":
        return <RightIssue />;
      case "BASKET":
        return <EquityBasketSetting />;
      case "GENERAL_PREFERENCES":
        return <GeneralPreferences />;
      case "EXECUTION_SHEET":
        return <ExecutionSheet />;
      case "EMAIL_CONFIGURATION":
        return <SmtpSetup />;
      case "MARKETING_MATERIAL":
        return <MarketingMaterial />;
      case "EMPLOYEE":
        return <RoleWisePermission type="Employee" />;
      case "CLIENT":
        return <RoleWisePermission type="Client" />;
      case "PARTNER":
        return <RoleWisePermission type="Partner" />;
      case "PAYMENT_GATEWAY":
        return <PaymentGateway />;
      case "AUTO_IMPORT":
        return <MutualFundAutoImportSettings />;
      case "MANDATE":
        return <Mandate />;
      case "LEDGER":
        return <LedgerSettings />;
      case "BROKER_CONFIGURATION":
        return <BrokerConfiguration broker={"IIFL"} />;
      case "KYC_REGISTRATION_AGENCY":
        return <KYCRegistrationAgency />;
      case "BILLING":
        return <Billing />;
      case "ONBOARDING_FLOW":
        return <OnBoardingFlowTab />;
      case "WEBSITE":
        return <WebsiteSettings />;
      case "COMPLIANCE":
        return <ComplianceSettings />;
      case "WEBSITE_CONFIGURATIONS":
        return <WebsiteConfigurations />;
      case "DIGIO":
        return <Digio />;
      default:
        return <Typography variant="h4">Page not found!</Typography>;
    }
  };

  return (
    <DashboardLayout title="Account Setting">
      <Grid2 container display={"flex"}>
        {userData?.userRole === UserRoleTypes.advisor && (
          <Grid2
            width={sidebarOpen ? "21%" : "20%"}
            height={"calc(100vh - 100px)"}
            overflow={"auto"}
          >
            <SidebarMenu
              dispatch={dispatch}
              settingTab={settingTab}
              userInfo={userData}
            />
          </Grid2>
        )}
        <Grid2
          size={
            (userData?.userRole === UserRoleTypes.client ||
              userData?.userRole === UserRoleTypes.partner) &&
            12
          }
          width={sidebarOpen ? "79%" : "80%"}
          p={"8px 16px"}
          height={"calc(100vh - 100px)"}
          overflow={"auto"}
        >
          <Typography fontSize={24} fontWeight={500} mb={3}>
            {settingTab.name}
          </Typography>
          {renderComponent()}
        </Grid2>
      </Grid2>
    </DashboardLayout>
  );
};

export default AccountSetting;
