import {
  GKButton,
  GKTextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "components";
import { Controller, useFormContext } from "react-hook-form";

const PartnerPlanHistory = () => {
  const form = useFormContext();

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow sx={{ "&:last-child td, &:last-child th": { p: 2 } }}>
            <TableCell>Partner</TableCell>
            <TableCell>Management Fee&nbsp;(%)</TableCell>
            <TableCell>Performance Fee&nbsp;(%)</TableCell>
            <TableCell>Brokerage&nbsp;(%)</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow
            sx={{ "&:last-child td, &:last-child th": { border: "0", p: 2 } }}
          >
            <TableCell component="th" scope="row">
              Sofia Rodriguez
            </TableCell>
            <TableCell>
              <Controller
                name={``}
                control={form.control}
                render={({ field }) => <GKTextField {...field} type="number" />}
              />
            </TableCell>
            <TableCell>
              <Controller
                name={``}
                control={form.control}
                render={({ field }) => <GKTextField {...field} type="number" />}
              />
            </TableCell>
            <TableCell>
              <Controller
                name={``}
                control={form.control}
                render={({ field }) => <GKTextField {...field} type="number" />}
              />
            </TableCell>
            <TableCell align="right">
              <GKButton variant="outlined" color="dark">
                History
              </GKButton>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PartnerPlanHistory;
