import { GKButton, GKModal, GKTextField, Grid2 } from "components";
import { Controller, useFormContext } from "react-hook-form";
import { GKModalProps } from "utils/commonInterface";

interface Props {
  index: any;
}

const ExtraChargesModal = (props: Props & GKModalProps) => {
  const form = useFormContext();

  const { open, setOpen, index } = props;

  return (
    <GKModal
      open={open}
      setOpen={setOpen}
      modalTitle="Extra Charges"
      footer={
        <Grid2 container justifyContent={"flex-end"}>
          <GKButton
            variant="contained"
            onClick={() => {
              // const getChargesValue =
              //   form.getValues(
              //     index.id
              //       ? "totalCharges.stt"
              //       : `transactions.${index}.totalCharges.stt`
              //   ) +
              //   form.getValues(
              //     index.id
              //       ? "totalCharges.gst"
              //       : `transactions.${index}.totalCharges.gst`
              //   ) +
              //   form.getValues(
              //     index.id
              //       ? "totalCharges.otherCharges"
              //       : `transactions.${index}.totalCharges.other_charges`
              //   ) +
              //   form.getValues(
              //     index.id
              //       ? "totalCharges.sebiTurnoverFees"
              //       : `transactions.${index}.totalCharges.sebi_turnover_fees`
              //   ) +
              //   form.getValues(
              //     index.id
              //       ? "totalCharges.stampCharges"
              //       : `transactions.${index}.totalCharges.stamp_charges`
              //   ) +
              //   form.getValues(
              //     index.id
              //       ? "totalCharges.transactionCharges"
              //       : `transactions.${index}.totalCharges.transaction_charges`
              //   );

              // form.setValue(
              //   index.id ? "billAmount" : `transactions.${index}.billAmount`,
              //   getChargesValue
              // );

              const totalChargesPath = index.id
                ? "totalCharges"
                : `transactions.${index}.totalCharges`;

              const billAmountPath = index.id
                ? "billAmount"
                : `transactions.${index}.billAmount`;

              const getChargeValue = (chargeKey: string) =>
                form.getValues(`${totalChargesPath}.${chargeKey}`) || 0;

              const getChargesValue = [
                "gst",
                "other_charges",
                "sebi_turnover_fees",
                "stamp_charges",
                "stt",
                "transaction_charges",
              ].reduce((sum, chargeKey) => sum + getChargeValue(chargeKey), 0);

              form.setValue(billAmountPath, getChargesValue);

              setOpen(false);
            }}
          >
            Save
          </GKButton>
        </Grid2>
      }
    >
      <Grid2 container spacing={1}>
        <Grid2 size={4}>
          <Controller
            name={
              index?.id
                ? "totalCharges.stt"
                : `transactions.${index}.totalCharges.stt`
            }
            control={form.control}
            render={({ field }) => (
              <GKTextField
                {...field}
                placeholder="Enter STT"
                inputLabel="STT"
                type="number"
                onChange={(e: any) => field.onChange(e.target.valueAsNumber)}
              />
            )}
          />
        </Grid2>
        <Grid2 size={4}>
          <Controller
            name={
              index.id
                ? "totalCharges.gst"
                : `transactions.${index}.totalCharges.gst`
            }
            control={form.control}
            render={({ field }) => (
              <GKTextField
                {...field}
                placeholder="Enter gst"
                inputLabel="gst"
                type="number"
                onChange={(e: any) => field.onChange(e.target.valueAsNumber)}
              />
            )}
          />
        </Grid2>
        <Grid2 size={4}>
          <Controller
            name={
              index.id
                ? "totalCharges.otherCharges"
                : `transactions.${index}.totalCharges.other_charges`
            }
            control={form.control}
            render={({ field }) => (
              <GKTextField
                {...field}
                placeholder="Enter other Charges"
                inputLabel="other Charges"
                type="number"
                onChange={(e: any) => field.onChange(e.target.valueAsNumber)}
              />
            )}
          />
        </Grid2>
        <Grid2 size={4}>
          <Controller
            name={
              index.id
                ? "totalCharges.sebiTurnoverFees"
                : `transactions.${index}.totalCharges.sebi_turnover_fees`
            }
            control={form.control}
            render={({ field }) => (
              <GKTextField
                {...field}
                placeholder="Enter sebi Turnover Fees"
                inputLabel="sebi Turnover Fees"
                type="number"
                onChange={(e: any) => field.onChange(e.target.valueAsNumber)}
              />
            )}
          />
        </Grid2>
        <Grid2 size={4}>
          <Controller
            name={
              index.id
                ? "totalCharges.stampCharges"
                : `transactions.${index}.totalCharges.stamp_charges`
            }
            control={form.control}
            render={({ field }) => (
              <GKTextField
                {...field}
                placeholder="Enter stampCharges"
                inputLabel="stamp Charges"
                type="number"
                onChange={(e: any) => field.onChange(e.target.valueAsNumber)}
              />
            )}
          />
        </Grid2>
        <Grid2 size={4}>
          <Controller
            name={
              index.id
                ? "totalCharges.transactionCharges"
                : `transactions.${index}.totalCharges.transaction_charges`
            }
            control={form.control}
            render={({ field }) => (
              <GKTextField
                {...field}
                placeholder="Enter transactionCharges"
                inputLabel="transaction Charges"
                type="number"
                onChange={(e: any) => field.onChange(e.target.valueAsNumber)}
              />
            )}
          />
        </Grid2>
      </Grid2>
    </GKModal>
  );
};

export default ExtraChargesModal;
