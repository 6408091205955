import { useAppContext } from "AppContext";
import { AnimationComponent, Box, Grid2, Show, Tab, Tabs } from "components";
import DashboardLayout from "layouts/DashboardLayout";
import { FeeCollection, FeePlans } from "Pages";
import SubscribedPortfolio from "Pages/Portfolio/SubscribedPortfolio";
import Coupon from "Pages/RevenueManagement/Coupon";
import InvoiceTab from "Pages/RevenueManagement/Invoice/InvoiceTab";
import PerformaInvoiceTab from "Pages/RevenueManagement/Invoice/PerformaInvoiceTab";
import RevenueFeeCalculations from "Pages/RevenueManagement/RevenueManager/RevenueFeeCalculation";
import RevenuePortfolioTab from "Pages/RevenueManagement/RevenueManager/RevenuePortfolioTab";
import RevenueMandate from "Pages/RevenueManagement/RevenueMandate";
import SubscriptionHistory from "Pages/RevenueManagement/SubscriptionHistory";
import { UserRoleTypes } from "utils/common";
import { ThemeContainer } from "utils/Theme";

const RevenueMenu = () => {
  const {
    state: { tab: tabValue, userData },
    dispatch,
  } = useAppContext();

  const { theme } = ThemeContainer();

  const tabs = [
    {
      label: "Fee Plan Setup",
      component: FeePlans,
      show: [UserRoleTypes.advisor],
    },
    { label: "Coupon", component: Coupon, show: [UserRoleTypes.advisor] },
    {
      label: "Revenue Manager",
      component: RevenuePortfolioTab,
      show: [UserRoleTypes.advisor, UserRoleTypes.partner],
    },
    {
      label: "Fee Calculation",
      component: RevenueFeeCalculations,
      show: [UserRoleTypes.advisor],
    },
    {
      label: "Proforma",
      component: PerformaInvoiceTab,
      show: [
        UserRoleTypes.advisor,
        UserRoleTypes.client,
        UserRoleTypes.partner,
      ],
    },
    {
      label: "Invoice",
      component: InvoiceTab,
      show: [
        UserRoleTypes.advisor,
        UserRoleTypes.client,
        UserRoleTypes.partner,
      ],
    },
    {
      label: "Fee Collection",
      component: FeeCollection,
      show: [
        UserRoleTypes.advisor,
        UserRoleTypes.client,
        UserRoleTypes.partner,
      ],
    },
    {
      label: "Mandate",
      component: RevenueMandate,
      show: [UserRoleTypes.advisor],
    },
    {
      label: "Pending Approvals",
      component: SubscribedPortfolio,
      show: [UserRoleTypes.advisor],
    },
    {
      label: "Subscriptions",
      component: SubscriptionHistory,
      show: [UserRoleTypes.advisor],
    },
  ];

  const filteredTabs = tabs.filter((tab) =>
    tab.show.includes(userData?.userRole),
  );

  return (
    <DashboardLayout title="Dashboard">
      <Grid2 container spacing={2}>
        <Grid2 size={12}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              sx={{
                "& .MuiTabs-flexContainer": {
                  backgroundColor: theme.palette.light.main,
                },
              }}
              onChange={(e, val) => {
                dispatch({
                  type: "TAB",
                  data: { ...tabValue, tabValueOfRevenue: val },
                });
              }}
              value={tabValue?.tabValueOfRevenue}
              variant="scrollable"
              scrollButtons={false}
            >
              {filteredTabs?.map((tab, index) => (
                <Tab key={index} label={tab.label} disableTouchRipple />
              ))}
            </Tabs>
          </Box>
        </Grid2>
        <Grid2 size={12}>
          {filteredTabs?.map((tab, index) => (
            <Show.When
              key={index}
              isTrue={tabValue?.tabValueOfRevenue === index}
            >
              <AnimationComponent>
                <tab.component />
              </AnimationComponent>
            </Show.When>
          ))}
        </Grid2>
      </Grid2>
    </DashboardLayout>
  );
};

export default RevenueMenu;
