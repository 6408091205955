import {
  FormControl,
  FormControlLabel,
  FormLabel,
  GKDatePicker,
  Grid2,
  Radio,
  RadioGroup,
} from "components";
import dayjs from "dayjs";
import { Controller, useFormContext } from "react-hook-form";
import { useParams } from "react-router-dom";
import { ThemeContainer } from "utils/Theme";

interface FormErrors {
  step1: {
    fromDate?: {
      value: boolean;
      message: string;
    };
    toDate?: {
      value: boolean;
      message: string;
    };
  };
}

const ImportBrokerStepOne = () => {
  const { id } = useParams();
  const form = useFormContext();

  const { theme } = ThemeContainer();

  const {
    formState: { errors },
  } = useFormContext<FormErrors>();

  const handleDateChange = (field: any, value: any) => {
    const formattedDate = dayjs(value).format("YYYY-MM-DD");
    field.onChange(formattedDate);
    if (!id) {
      form.setValue("step1.toDate", formattedDate);
    }
  };

  return (
    <Grid2 container spacing={1}>
      {!id && (
        <Grid2 size={12}>
          <Controller
            name="step1.subType"
            control={form.control}
            render={({ field }) => (
              <FormControl>
                <FormLabel
                  style={{
                    color: theme.palette.dark.main,
                    fontSize: "14px",
                  }}
                >
                  Type
                </FormLabel>
                <RadioGroup
                  {...field}
                  row
                  onChange={(e) => {
                    field.onChange(e.target.value);
                    form.setValue("step1.type", "ADITYA_BIRLA_MONEY");
                  }}
                >
                  <FormControlLabel
                    value="EQUITY"
                    control={<Radio />}
                    label="Trades"
                  />
                  <FormControlLabel
                    value="LEDGER"
                    control={<Radio />}
                    label="Ledger Entries"
                  />
                </RadioGroup>
              </FormControl>
            )}
          />
        </Grid2>
      )}

      <Grid2 size={!id ? 12 : 6}>
        <Controller
          name="step1.fromDate"
          control={form.control}
          rules={{
            required: { value: true, message: "Please select start date" },
          }}
          render={({ field }) => (
            <GKDatePicker
              {...field}
              requiredField
              inputLabel={!id ? "Date" : "Start Date"}
              value={dayjs(field.value)}
              onChange={(value) => handleDateChange(field, value)}
              disableFuture
              slotProps={{
                textField: {
                  error: !!errors.step1?.fromDate,
                  helperText: errors.step1?.fromDate?.message as string,
                },
              }}
            />
          )}
        />
      </Grid2>

      {id && (
        <Grid2 size={6}>
          <Controller
            name="step1.toDate"
            control={form.control}
            rules={{
              required: { value: !!id, message: "Please select end date" },
            }}
            render={({ field }) => (
              <GKDatePicker
                {...field}
                requiredField
                inputLabel="End Date"
                value={dayjs(field.value)}
                minDate={
                  dayjs(form.getValues("step1.fromDate")).isValid()
                    ? dayjs(form.getValues("step1.fromDate"))
                    : null
                }
                onChange={(value) =>
                  field.onChange(dayjs(value).format("YYYY-MM-DD"))
                }
                disableFuture
                slotProps={{
                  textField: {
                    error: !!errors.step1?.toDate,
                    helperText: errors.step1?.toDate?.message as string,
                  },
                }}
              />
            )}
          />
        </Grid2>
      )}
    </Grid2>
  );
};

export default ImportBrokerStepOne;
