import {
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid2,
  Switch,
  TextField,
} from "@mui/material";
import { useAppContext } from "AppContext";
import dayjs from "dayjs";
import { CommonFilterState } from "interfaces/CommonFilter";
import { Controller, useForm } from "react-hook-form";
import { GKModalProps } from "utils/commonInterface";
import { handleReturnAutocompletePlaceholder } from "utils/StringFunctions";
import {
  LEDGER_TRANSACTION_TYPE,
  clientOption,
  companiesOption,
  employeeOption,
  feePlanOption,
  importManagerFormats,
  importManagerSource,
  includeClient,
  includeCompany,
  includeDate,
  includeDateRange,
  includeEmployee,
  includeFeePlan,
  includeFormat,
  includePartner,
  includePortfolio,
  includePortfolioGroup,
  includeTransactionType,
  includeType,
  mutualFundsType,
  partnerOption,
  portfolioGroupOption,
  portfolioOption,
  tradeBookTransactionType,
  transactionTypeCashEntry,
  transactionTypeIncomeExpense,
} from "utils/TableFilters";
import GKButton from "./GKButton";
import GKCheckbox from "./GKCheckbox";
import GKDatePicker from "./GKDatePicker";
import GKDateRangePicker from "./GKDateRangePicker";
import GKModal from "./GKModal";
import GKSearchSelect from "./GKSearchSelect";
import GKTypography from "./GKTypography";
import { Show } from "./Show";

interface Props {
  zeroHolding: boolean;
  tableName?: string;
  name?: string;
}

const GKCommonTableFilterModal = (props: Props & GKModalProps) => {
  const { open, setOpen, tableName, name, zeroHolding } = props;

  const {
    state: {
      portfolios,
      companies,
      portfolioGroupList,
      clientList,
      employeeList,
      commonFilter,
      partnerList,
      feePlanList,
    },
    dispatch,
  } = useAppContext();

  const commonFilterWithTable = commonFilter[name as keyof CommonFilterState];

  const form = useForm({
    defaultValues: {
      name: commonFilterWithTable?.customFilter?.name || [],
      type: commonFilterWithTable?.customFilter?.type || "",
      date: commonFilterWithTable?.customFilter?.date || "",
      format: commonFilterWithTable?.customFilter?.format || [],
      source: commonFilterWithTable?.customFilter?.source || [],
      start_date: commonFilterWithTable?.customFilter?.start_date || "",
      end_date: commonFilterWithTable?.customFilter?.end_date || "",
      portfolio: commonFilterWithTable?.customFilter?.portfolio || [],
      portfolioFilterName:
        commonFilterWithTable?.customFilter?.portfolioFilterName || [],
      gcode: commonFilterWithTable?.customFilter?.gcode || [],
      company: commonFilterWithTable?.customFilter?.company || [],
      client: commonFilterWithTable?.customFilter?.client || [],
      clientName: commonFilterWithTable?.customFilter?.clientName || [],
      employee: commonFilterWithTable?.customFilter?.employee || [],
      employeeName: commonFilterWithTable?.customFilter?.employeeName || [],
      feePlan: commonFilterWithTable?.customFilter?.feePlan || [],
      feePlanName: commonFilterWithTable?.customFilter?.feePlanName || [],
      group: commonFilterWithTable?.customFilter?.group || [],
      groupName: commonFilterWithTable?.customFilter?.groupName || [],
      financialYear: commonFilterWithTable?.customFilter?.financialYear || [],
      partner: commonFilterWithTable?.customFilter?.partner || [],
      partnerName: commonFilterWithTable?.customFilter?.partnerName || [],
    },
  });

  return (
    <GKModal
      open={open}
      setOpen={setOpen}
      modalTitle="Filters"
      width={"50%"}
      footer={
        <Grid2
          container
          size={12}
          justifyContent={zeroHolding ? "space-between" : "end"}
        >
          {zeroHolding && (
            <FormControlLabel
              style={{ marginLeft: 2 }}
              control={
                <Switch
                  checked={commonFilterWithTable?.showNonZero}
                  onChange={(event) => {
                    dispatch({
                      type: "FILTER",
                      data: {
                        ...commonFilter,
                        [name]: {
                          ...commonFilterWithTable,
                          showNonZero: event.target.checked,
                          skip: 0,
                          page: 0,
                        },
                      },
                    });
                  }}
                />
              }
              labelPlacement="end"
              label={<b style={{ fontSize: 12 }}> Show zero holding</b>}
            />
          )}
          <GKButton
            type="submit"
            form="table-filter"
            variant="contained"
            size="medium"
          >
            Submit
          </GKButton>
        </Grid2>
      }
      titleComponent={
        <GKButton
          onClick={() => {
            dispatch({
              type: "FILTER",
              data: {
                ...commonFilter,
                [name]: {
                  ...commonFilterWithTable,
                  customFilter: {},
                },
              },
            });
            form.reset({
              portfolioFilterName: [],
              portfolio: [],
              name: [],
              type: "",
              date: "",
              format: [],
              source: [],
              start_date: "",
              end_date: "",
              gcode: [],
              company: [],
              client: [],
              clientName: [],
              employee: [],
              employeeName: [],
              feePlan: [],
              feePlanName: [],
              group: [],
              groupName: [],
              financialYear: [],
            });
            // setIsModalOpen(false);
          }}
          size="medium"
          variant="outlined"
          color="error"
        >
          Reset
        </GKButton>
      }
    >
      <form
        id="table-filter"
        onSubmit={form.handleSubmit((values) => {
          dispatch({
            type: "FILTER",
            data: {
              ...commonFilter,
              [name]: {
                ...commonFilterWithTable,
                customFilter: values,
                skip: 0,
                page: 0,
              },
            },
          });
          setOpen(false);
        })}
      >
        <Grid2 container spacing={1}>
          <Show.When isTrue={includePortfolio.includes(tableName)}>
            <Grid2 size={12}>
              <Controller
                name="portfolio"
                control={form.control}
                render={({ field }) => (
                  <GKSearchSelect
                    {...field}
                    multiple
                    disableClearable={false}
                    disableCloseOnSelect
                    limitTags={5}
                    inputLabel="Portfolio"
                    renderOption={(props, option) => {
                      return (
                        <li
                          {...props}
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                          }}
                        >
                          <GKTypography fontWeight={600}>
                            {option.clientName}
                          </GKTypography>
                          <GKTypography>{option.name}</GKTypography>
                        </li>
                      );
                    }}
                    options={
                      portfolioOption(
                        portfolios.map((item) => {
                          return {
                            ...item,
                            name: item.name,
                            value: item.value,
                          };
                        }),
                        form,
                      ) || []
                    }
                    onChange={(e, val) => {
                      const selectedIds = val.map(
                        (option: { value: string | number }) => option.value,
                      );
                      field.onChange(selectedIds);
                      form.setValue("portfolioFilterName", val);
                    }}
                    value={
                      portfolios?.filter((option: { value: string | number }) =>
                        field.value?.includes(option.value),
                      ) || []
                    }
                    getOptionLabel={(option) => option.name || option}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder={handleReturnAutocompletePlaceholder({
                          placeholder: "Select Portfolio",
                          value: field.value,
                        })}
                      />
                    )}
                  />
                )}
              />
            </Grid2>
          </Show.When>

          <Show.When isTrue={includePortfolioGroup.includes(tableName)}>
            <Grid2 size={12}>
              <Controller
                name="group"
                control={form.control}
                render={({ field }) => (
                  <GKSearchSelect
                    {...field}
                    multiple
                    disableClearable={false}
                    disableCloseOnSelect
                    limitTags={5}
                    inputLabel="Portfolio Group"
                    options={
                      portfolioGroupOption(portfolioGroupList, form) || []
                    }
                    onChange={(e, val) => {
                      const selectedIds = val.map(
                        (option: { value: string | number }) => option.value,
                      );
                      field.onChange(selectedIds);
                      form.setValue("groupName", val);
                    }}
                    value={
                      portfolioGroupList?.filter(
                        (option: { value: string | number }) =>
                          field.value?.includes(option.value),
                      ) || []
                    }
                    getOptionLabel={(option) => option.name || option}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="Portfolio Group"
                        placeholder={handleReturnAutocompletePlaceholder({
                          placeholder: "Select Portfolio Group",
                          value: field.value,
                        })}
                      />
                    )}
                  />
                )}
              />
            </Grid2>
          </Show.When>

          <Show.When isTrue={includeCompany.includes(tableName)}>
            <Grid2 size={12}>
              <Controller
                name="gcode"
                control={form.control}
                render={({ field }) => (
                  <GKSearchSelect
                    {...field}
                    multiple
                    disableClearable={false}
                    disableCloseOnSelect
                    limitTags={5}
                    inputLabel="Company Name"
                    options={
                      companiesOption(
                        companies.map((dataItem) => {
                          return {
                            name: dataItem.name,
                            value: dataItem.value,
                          };
                        }),
                        form,
                      ) || []
                    }
                    onChange={(e, val) => {
                      const selectedIds = val.map(
                        (option: { value: string | number }) => option.value,
                      );
                      field.onChange(selectedIds);
                      form.setValue("company", val);
                    }}
                    value={
                      companies?.filter((option: { value: string | number }) =>
                        field.value?.includes(option.value),
                      ) || []
                    }
                    getOptionLabel={(option) => option.name || option}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder={handleReturnAutocompletePlaceholder({
                          placeholder: "Select Company Name",
                          value: field.value,
                        })}
                      />
                    )}
                  />
                )}
              />
            </Grid2>
          </Show.When>

          <Show.When isTrue={includeDateRange.includes(tableName)}>
            <Grid2 size={12}>
              <Controller
                name={"start_date"}
                control={form.control}
                render={({ field }) => (
                  <GKDateRangePicker
                    {...field}
                    inputLabel="Date Range"
                    name="date"
                    value={[
                      dayjs(form.getValues("start_date")),
                      dayjs(form.getValues("end_date")),
                    ]}
                    onChange={(
                      value: [start_date: string, end_date: string],
                    ) => {
                      form.setValue(
                        "start_date",
                        dayjs(value[0]).format("YYYY-MM-DD"),
                      );
                      form.setValue(
                        "end_date",
                        dayjs(value[1]).format("YYYY-MM-DD"),
                      );
                    }}
                    disableFuture
                    slotProps={{
                      textField: {
                        error: false,
                        helperText: false,
                      },
                    }}
                  />
                )}
              />
            </Grid2>
          </Show.When>

          <Show.When isTrue={includeDate.includes(tableName)}>
            <Grid2 size={12}>
              <Controller
                name="date"
                control={form.control}
                render={({ field }) => (
                  <GKDatePicker
                    {...field}
                    inputLabel="Transaction Date"
                    value={dayjs(field.value)}
                    onChange={(value) => {
                      field.onChange(dayjs(value).format("YYYY-MM-DD"));
                    }}
                    disableFuture
                    slotProps={{
                      textField: {
                        error: false,
                        helperText: false,
                      },
                    }}
                  />
                )}
              />
            </Grid2>
          </Show.When>

          <Show.When isTrue={includeType.includes(tableName)}>
            <Grid2 size={6}>
              <GKTypography fontSize={14}>Transaction Type</GKTypography>
              <FormControl component="fieldset">
                <Controller
                  name="type"
                  control={form.control}
                  render={({ field }) => (
                    <FormGroup>
                      {(tableName === "income_expense_list" ||
                      tableName === "portfolio_income_expense"
                        ? transactionTypeIncomeExpense
                        : transactionTypeCashEntry
                      ).map((option) => (
                        <FormControlLabel
                          key={option.value}
                          control={
                            <GKCheckbox
                              {...field}
                              checked={field.value.includes(option.value)}
                              onChange={() => {
                                const updatedOptions = field?.value?.includes(
                                  option.value,
                                )
                                  ? field?.value?.filter(
                                      (item: string | number) =>
                                        item !== option.value,
                                    )
                                  : [...field?.value, option.value];

                                field.onChange(updatedOptions);
                              }}
                            />
                          }
                          label={option.name}
                        />
                      ))}
                    </FormGroup>
                  )}
                />
              </FormControl>
            </Grid2>
          </Show.When>

          <Show.When isTrue={includeTransactionType.includes(tableName)}>
            <Grid2 size={6}>
              <GKTypography fontSize={14}>Transaction Type</GKTypography>
              <FormControl component="fieldset">
                <Controller
                  name="type"
                  control={form.control}
                  render={({ field }) => (
                    <FormGroup>
                      {tableName !== "bank_ledger_list" &&
                      tableName !== "portfolio_bank_ledger_list"
                        ? (tableName === "mutual_funds_list" ||
                          tableName === "portfolio_mutual_funds"
                            ? mutualFundsType
                            : tradeBookTransactionType
                          ).map((option) => (
                            <FormControlLabel
                              key={option.value}
                              control={
                                <GKCheckbox
                                  {...field}
                                  checked={field.value.includes(option.value)}
                                  onChange={() => {
                                    const updatedOptions = field.value.includes(
                                      option.value,
                                    )
                                      ? field.value.filter(
                                          (item: string | number) =>
                                            item !== option.value,
                                        )
                                      : [...field.value, option.value];

                                    field.onChange(updatedOptions);
                                  }}
                                />
                              }
                              label={option.name}
                            />
                          ))
                        : LEDGER_TRANSACTION_TYPE.map((option) => (
                            <FormControlLabel
                              key={option.value}
                              control={
                                <GKCheckbox
                                  {...field}
                                  checked={field.value.includes(option.value)}
                                  onChange={() => {
                                    const updatedOptions = field.value.includes(
                                      option.value,
                                    )
                                      ? field.value.filter(
                                          (item: string | number) =>
                                            item !== option.value,
                                        )
                                      : [...field.value, option.value];

                                    field.onChange(updatedOptions);
                                  }}
                                />
                              }
                              label={option.name}
                            />
                          ))}
                    </FormGroup>
                  )}
                />
              </FormControl>
            </Grid2>
          </Show.When>

          <Show.When isTrue={includeClient.includes(tableName)}>
            <Grid2 size={12}>
              <Controller
                name="client"
                control={form.control}
                render={({ field }) => (
                  <GKSearchSelect
                    {...field}
                    multiple
                    disableClearable={false}
                    disableCloseOnSelect
                    limitTags={5}
                    inputLabel="Client"
                    options={
                      clientOption(
                        clientList.map((dataItem) => {
                          return {
                            name: dataItem.name,
                            value: dataItem.value,
                          };
                        }),
                        form,
                      ) || []
                    }
                    onChange={(e, val) => {
                      const selectedIds = val.map(
                        (option: { value: string | number }) => option.value,
                      );
                      field.onChange(selectedIds);
                      form.setValue("clientName", val);
                    }}
                    value={
                      clientList?.filter((option: { value: string | number }) =>
                        field.value?.includes(option.value),
                      ) || []
                    }
                    getOptionLabel={(option) => option.name || option}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder={handleReturnAutocompletePlaceholder({
                          placeholder: "Select Client",
                          value: field.value,
                        })}
                      />
                    )}
                  />
                )}
              />
            </Grid2>
          </Show.When>

          <Show.When isTrue={includeEmployee.includes(tableName)}>
            <Grid2 size={12}>
              <Controller
                // name={
                //   tableName === "import_manager_list" ||
                //   tableName === "recon_manager_list" ||
                //   tableName === "portfolio_import_manager_list"
                //     ? "uploaded_by"
                //     : "name"
                // }
                name="employee"
                control={form.control}
                render={({ field }) => (
                  <GKSearchSelect
                    {...field}
                    multiple
                    disableClearable={false}
                    disableCloseOnSelect
                    limitTags={5}
                    inputLabel={
                      tableName === "import_manager_list" ||
                      tableName === "recon_manager_list" ||
                      tableName === "portfolio_import_manager_list"
                        ? "Uploaded By"
                        : "Admin"
                    }
                    options={employeeOption(employeeList, form) || []}
                    onChange={(e, val) => {
                      const selectedIds = val.map(
                        (option: { value: string | number }) => option.value,
                      );

                      field.onChange(selectedIds);
                      form.setValue("employeeName", val);
                    }}
                    value={
                      employeeList?.filter(
                        (option: { value: string | number }) =>
                          field.value?.includes(option.value),
                      ) || []
                    }
                    getOptionLabel={(option) => option.name || option}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder={handleReturnAutocompletePlaceholder({
                          placeholder: "Select Admin",
                          value: field.value,
                        })}
                      />
                    )}
                  />
                )}
              />
            </Grid2>
          </Show.When>

          {/* <Show.When
            isTrue={
              tableName === "holding_summary_list" ||
              tableName === "insights_list" ||
              tableName === "portfolio_holding_list" ||
              tableName === "portfolio_group_holding_list" ||
              tableName === "client_holding_list" ||
              tableName === "family_holding_list" ||
              tableName === "branch_holding_list" ||
              tableName === "employee_holding_list"
            }
          >
            <Grid2 size={12}>
              <Controller
                name={"gcode"}
                control={form.control}
                render={({ field }) => (
                  <GKSearchSelect
                    {...field}
                    multiple
                    disableClearable={false}
                    disableCloseOnSelect
                    limitTags={5}
                    inputLabel="Company Name"
                    options={
                      companiesOption(
                        companies.map((dataItem) => {
                          return {
                            name: dataItem.name,
                            value: dataItem.value,
                          };
                        }),
                        form
                      ) || []
                    }
                    onChange={(e, val) => {
                      const selectedIds = val.map(
                        (option:  { value: string | number }) => option.value
                      );
                      field.onChange(selectedIds);
                      form.setValue("company", val);
                    }}
                    value={
                      companies?.filter((option:  { value: string | number }) =>
                        field.value?.includes(option.value)
                      ) || []
                    }
                    getOptionLabel={(option) => option.name || option}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder={
                          handleReturnAutocompletePlaceholder({
                          placeholder: "Select Company Name",
                          value:field.value})
                        }
                      />
                    )}
                  />
                )}
              />
            </Grid2>
          </Show.When> */}

          <Show.When isTrue={includeFormat.includes(tableName)}>
            <Grid2 size={6}>
              <GKTypography fontSize={14}>File Formats</GKTypography>
              <FormControl component="fieldset">
                <Controller
                  name={"format"}
                  control={form.control}
                  render={({ field }) => (
                    <FormGroup>
                      {importManagerFormats.map((option) => (
                        <FormControlLabel
                          key={option.value}
                          control={
                            <GKCheckbox
                              {...field}
                              checked={field.value.includes(option.value)}
                              onChange={() => {
                                const updatedOptions = field.value.includes(
                                  option.value,
                                )
                                  ? field.value.filter(
                                      (item: string | number) =>
                                        item !== option.value,
                                    )
                                  : [...field.value, option.value];

                                field.onChange(updatedOptions);
                              }}
                            />
                          }
                          label={option.name}
                        />
                      ))}
                    </FormGroup>
                  )}
                />
              </FormControl>
            </Grid2>
            <Grid2 size={6}>
              <GKTypography fontSize={14}>Upload Type</GKTypography>
              <FormControl component="fieldset">
                <Controller
                  name={`source`}
                  control={form.control}
                  render={({ field }) => (
                    <FormGroup>
                      {importManagerSource.map((option) => (
                        <FormControlLabel
                          key={option.value}
                          control={
                            <GKCheckbox
                              {...field}
                              checked={field.value.includes(option.value)}
                              onChange={() => {
                                const updatedOptions = field.value.includes(
                                  option.value,
                                )
                                  ? field.value.filter(
                                      (item: string | number) =>
                                        item !== option.value,
                                    )
                                  : [...field.value, option.value];

                                field.onChange(updatedOptions);
                              }}
                            />
                          }
                          label={option.name}
                        />
                      ))}
                    </FormGroup>
                  )}
                />
              </FormControl>
            </Grid2>
          </Show.When>

          <Show.When isTrue={includeFeePlan.includes(tableName)}>
            <Grid2 size={12}>
              <Controller
                name={"feePlan"}
                control={form.control}
                render={({ field }) => (
                  <GKSearchSelect
                    {...field}
                    multiple
                    disableClearable={false}
                    disableCloseOnSelect
                    limitTags={5}
                    inputLabel="Fee Plan"
                    options={feePlanOption(feePlanList, form) || []}
                    onChange={(e, val) => {
                      const selectedIds = val.map(
                        (option: { value: string | number }) => option.value,
                      );
                      field.onChange(selectedIds);
                      form.setValue("feePlanName", val);
                    }}
                    value={
                      feePlanList?.filter(
                        (option: { value: string | number }) =>
                          field.value?.includes(option.value),
                      ) || []
                    }
                    getOptionLabel={(option) => option.name || option}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="feePlan"
                        placeholder={handleReturnAutocompletePlaceholder({
                          placeholder: "Select Fee Plan",
                          value: field.value,
                        })}
                      />
                    )}
                  />
                )}
              />
            </Grid2>
          </Show.When>

          <Show.When isTrue={includePartner.includes(tableName)}>
            <Grid2 size={12}>
              <Controller
                name="partner"
                control={form.control}
                render={({ field }) => (
                  <GKSearchSelect
                    {...field}
                    multiple
                    disableClearable={false}
                    disableCloseOnSelect
                    limitTags={5}
                    inputLabel={"Partner"}
                    options={partnerOption(partnerList, form) || []}
                    onChange={(e, val) => {
                      const selectedIds = val.map(
                        (option: { value: string | number }) => option.value,
                      );

                      field.onChange(selectedIds);
                      form.setValue("partnerName", val);
                    }}
                    value={
                      partnerList?.filter(
                        (option: { value: string | number }) =>
                          field.value?.includes(option.value),
                      ) || []
                    }
                    getOptionLabel={(option) => option.name || option}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder={handleReturnAutocompletePlaceholder({
                          placeholder: "Select Partner",
                          value: field.value,
                        })}
                      />
                    )}
                  />
                )}
              />
            </Grid2>
          </Show.When>
        </Grid2>
      </form>
    </GKModal>
  );
};

export default GKCommonTableFilterModal;
