/* eslint-disable @typescript-eslint/no-unused-expressions */
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  GKRadio,
  Grid2,
  RadioGroup,
  Show,
  Switch,
  Typography,
} from "components";
import GKCheckbox from "components/GKCheckbox";
import { useFetchQuery } from "hooks/useQueries";
import { EquitybasketFormInterface } from "interfaces/EquityBasket";
import { useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { LuPlus } from "react-icons/lu";
import { handleCreateFormData } from "utils/formHelpers";
import { ThemeContainer } from "utils/Theme";
import { publishPlatformData } from "../equityBasketHelper";
import AddBasketPartner from "./AddBasketPartner";

interface PartnerListInterface {
  data: {
    data: {
      id: number;
      name: string;
      code: string;
      email: string;
      phone: string;
      role: string;
      headBranch: string;
      reportingBranch: any;
      countryCode: string;
    }[];
  };
}

const SettingsTab = ({
  handleUpdateEquityBasket,
}: {
  handleUpdateEquityBasket: any;
}) => {
  const form = useFormContext<EquitybasketFormInterface>();

  const { theme } = ThemeContainer();

  const [showPartnerModal, setShowPartnerModal] = useState(false);

  const { data } = useFetchQuery<PartnerListInterface>({
    key: ["GET_ALL_PARTNERS"],
    route: "/partner/",
  });

  const partnerWatch = form.watch("publishPlatform");

  return (
    <>
      <Grid2 container spacing={1}>
        <Grid2 container spacing={1}>
          <Grid2 size={3} display={"flex"}>
            <Controller
              name="published"
              control={form.control}
              render={({ field }) => {
                return (
                  <Switch
                    checked={field.value}
                    onChange={(e) => {
                      handleUpdateEquityBasket(
                        handleCreateFormData({
                          values: { published: e.target.checked },
                          dirtyFields: {
                            published: true,
                          },
                        }),
                      );
                    }}
                  />
                );
              }}
            />
            <Typography fontSize={18} fontWeight={600} lineHeight={1}>
              Publish Basket
            </Typography>
          </Grid2>
          <Grid2 size={9}>
            <Grid2 container spacing={2}>
              <Grid2 size={12} display={"flex"} flexDirection={"column"}>
                <Typography color={theme.palette.secondaryText.main}>
                  Publish basket on
                </Typography>
                <Controller
                  name="publishPlatform"
                  control={form.control}
                  render={({ field }) => {
                    return (
                      <FormGroup>
                        {publishPlatformData?.map((dataItem) => {
                          return (
                            <FormControlLabel
                              key={dataItem.value}
                              label={
                                <Box
                                  display={"flex"}
                                  alignItems={"center"}
                                  gap={2}
                                >
                                  <Typography>{dataItem.name}</Typography>
                                  {dataItem?.value === "channelPartners" && (
                                    <Button
                                      startIcon={<LuPlus size={12} />}
                                      variant="contained"
                                      size="small"
                                      disabled={
                                        !partnerWatch?.includes(
                                          "channelPartners",
                                        )
                                      }
                                      onClick={() => setShowPartnerModal(true)}
                                    >
                                      Add Partner
                                    </Button>
                                  )}
                                </Box>
                              }
                              control={
                                <GKCheckbox
                                  checked={field.value?.includes(
                                    dataItem.value,
                                  )}
                                  disabled={dataItem.disabled}
                                  onChange={(event) => {
                                    const newValue = event.target.checked
                                      ? [...(field.value || []), dataItem.value]
                                      : field.value?.filter(
                                          (v: string) => v !== dataItem.value,
                                        );

                                    field.onChange(newValue);
                                  }}
                                  disableLeftPadding
                                />
                              }
                            />
                          );
                        })}
                      </FormGroup>
                    );
                  }}
                />
              </Grid2>
              <Grid2 size={12} textAlign={"end"}>
                <Divider />
              </Grid2>
              <Grid2 size={12}>
                <Grid2 container>
                  <Grid2 size={12}>
                    <Controller
                      name="allowAllocation"
                      control={form.control}
                      render={({ field }) => {
                        return (
                          <FormControlLabel
                            {...field}
                            sx={{
                              "&.MuiFormControlLabel-root .MuiTypography-root":
                                {
                                  fontSize: 20,
                                },
                            }}
                            label="Allocation"
                            control={
                              <GKCheckbox
                                disableLeftPadding
                                checked={field.value}
                                onChange={(event) => {
                                  field.onChange(event.target.checked);
                                }}
                              />
                            }
                          />
                        );
                      }}
                    />
                  </Grid2>
                  <Grid2 size={6}>
                    <Controller
                      name="allocationReferenceWeightage"
                      control={form.control}
                      render={({ field }) => {
                        return (
                          <FormControl
                            sx={{
                              "& .MuiFormGroup-root": {
                                display: "flex",
                                flexDirection: "row",
                              },
                            }}
                          >
                            <FormLabel>
                              Allocation Reference Weightage
                            </FormLabel>
                            <RadioGroup {...field}>
                              <FormControlLabel
                                value="true"
                                control={<GKRadio disableLeftPadding />}
                                label="Allocated"
                              />
                              <FormControlLabel
                                value="false"
                                control={<GKRadio disableLeftPadding />}
                                label="Current"
                              />
                            </RadioGroup>
                          </FormControl>
                        );
                      }}
                    />
                  </Grid2>
                </Grid2>
              </Grid2>
              <Grid2 size={12} textAlign={"end"}>
                <Divider />
              </Grid2>
              <Grid2 size={12}>
                <Grid2 container>
                  <Grid2 size={12}>
                    <Controller
                      name="allowRebalance"
                      control={form.control}
                      render={({ field }) => {
                        return (
                          <FormControlLabel
                            {...field}
                            sx={{
                              "&.MuiFormControlLabel-root .MuiTypography-root":
                                {
                                  fontSize: 20,
                                },
                            }}
                            label="Rebalance"
                            control={
                              <GKCheckbox
                                disableLeftPadding
                                checked={field.value}
                                onChange={(event) => {
                                  field.onChange(event.target.checked);
                                }}
                              />
                            }
                          />
                        );
                      }}
                    />
                  </Grid2>
                  <Grid2 size={4}>
                    <Controller
                      name="rebalanceReferenceWeightage"
                      control={form.control}
                      render={({ field }) => {
                        return (
                          <FormControl
                            sx={{
                              "& .MuiFormGroup-root": {
                                display: "flex",
                                flexDirection: "row",
                              },
                            }}
                          >
                            <FormLabel id="demo-radio-buttons-group-label">
                              Rebalance Reference Weightage
                            </FormLabel>
                            <RadioGroup {...field}>
                              <RadioGroup {...field}>
                                <FormControlLabel
                                  value="true"
                                  control={<GKRadio disableLeftPadding />}
                                  label="Allocated"
                                />
                                <FormControlLabel
                                  value="false"
                                  control={<GKRadio disableLeftPadding />}
                                  label="Current"
                                />
                              </RadioGroup>
                            </RadioGroup>
                          </FormControl>
                        );
                      }}
                    />
                  </Grid2>
                </Grid2>
              </Grid2>
              <Grid2 size={12}>
                <Divider />
              </Grid2>
              <Grid2 size={12}>
                <Controller
                  name="allowLowRiskProfile"
                  control={form.control}
                  render={({ field }) => {
                    return (
                      <FormControl
                        sx={{
                          "& .MuiFormGroup-root": {
                            display: "flex",
                            flexDirection: "row",
                          },
                        }}
                      >
                        <FormLabel>Allow Low Risk Score</FormLabel>
                        <RadioGroup {...field}>
                          <FormControlLabel
                            value="true"
                            control={<GKRadio />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value="false"
                            control={<GKRadio />}
                            label="No"
                          />
                        </RadioGroup>
                      </FormControl>
                    );
                  }}
                />{" "}
              </Grid2>
            </Grid2>
          </Grid2>
        </Grid2>
      </Grid2>
      <Show.When isTrue={showPartnerModal}>
        <AddBasketPartner
          setOpen={setShowPartnerModal}
          open={showPartnerModal}
          partnerList={data}
        />
      </Show.When>
    </>
  );
};

export default SettingsTab;
