import { LoadingButton } from "@mui/lab";
import { GKClientDataGrid, GKTextField } from "components";
import { useFetchQuery, useMutation } from "hooks/useQueries";
import DashboardLayout from "layouts/DashboardLayout";
import { Controller, useForm } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { CONVERT_RIGHT_ISSUE_PORTFOLIO_DONE } from "utils/apiRoutes";
import axiosInstance from "utils/axiosInstance";
import { extraColumnsField } from "utils/helpers";
import { QueryKeys } from "utils/queryKey";
import useDocumentTitle from "utils/useDocumentTitle";

const EditRightIssue = () => {
  useDocumentTitle("Right Issue Details");

  const { id } = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();

  const { data, isFetching, refetch } = useFetchQuery({
    key: [QueryKeys.EDIT_RIGHT_ISSUE],
    route: CONVERT_RIGHT_ISSUE_PORTFOLIO_DONE(id),
  });

  const { mutate: updateRightIssue, isLoading } = useMutation({
    mutationKey: ["UPDATE_RIGHT_ISSUE"],
    mutationFn: (data: any) =>
      axiosInstance.post("/convert-rightissue/update/", data),
    onSuccess: () => {
      navigate(-1);
      refetch();
    },
  });

  const form = useForm({
    defaultValues: {
      EditRightIssue: data?.data?.data || [],
    },
    values: {
      EditRightIssue: data?.data?.data || [],
    },
  });

  form.watch();

  const onSubmit = (values: any) => {
    const data = {
      ca_id: state?.id,
      mainGcode: state?.gridCode,
      portfolios: values.EditRightIssue.filter(
        (data: any) => +data.quantity > 0,
      ),
      convertedGcode: state?.convertGridCode,
    };

    updateRightIssue(data);
  };

  return (
    <DashboardLayout title={"Edit Right Issue"}>
      <form id="apply-right-issue-form" onSubmit={form.handleSubmit(onSubmit)}>
        <GKClientDataGrid
          name="editRightIssue"
          loading={isFetching}
          columns={[
            {
              ...extraColumnsField("Portfolio Name", "portfolioName", 150, 1),
              valueGetter: (row) => row?.row?.portfolioName || "-",
            },
            {
              ...extraColumnsField(
                "Available Quantity",
                "convertedQuantity",
                150,
                1,
              ),
              type: "number",
              valueGetter: (row) => row?.row?.convertedQuantity || 0,
            },
            {
              ...extraColumnsField("Quantity To Convert", "quantity", 150, 1),
              type: "number",
              renderCell: (params) => {
                return (
                  <Controller
                    name={`EditRightIssue.${params.row.index}.quantity`}
                    control={form.control}
                    rules={{
                      validate: (value) =>
                        value <=
                          form.getValues(
                            `EditRightIssue.${params.row.index}.convertedQuantity`,
                          ) || "Please enter a valid quantity",
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <GKTextField
                        sx={{
                          "& .MuiInputBase-input": {
                            padding: "8px !important",
                          },
                        }}
                        style={{ width: 150 }}
                        placeholder="Quantity"
                        type="number"
                        {...field}
                        onChange={(e) => {
                          field.onChange(Number(e.target.value));
                        }}
                        error={!!error}
                        helperText={error ? error.message : null}
                      />
                    )}
                  />
                );
              },
            },
          ]}
          rows={
            data?.data?.data.map((dataItem: any, index: number) => {
              return {
                ...dataItem,
                id: dataItem?.portfolioId,
                index: index,
              };
            }) || []
          }
          maxHeight={"calc(100vh - 70px)"}
          headerComponent={
            <LoadingButton
              disabled={
                form
                  .getValues("EditRightIssue")
                  ?.filter(
                    (dataItem: any) =>
                      dataItem?.convertedQuantity < dataItem?.quantity,
                  )?.length !== 0
              }
              variant="contained"
              size="medium"
              form="apply-right-issue-form"
              type="submit"
              loading={isLoading}
            >
              Save
            </LoadingButton>
          }
        />
      </form>
    </DashboardLayout>
  );
};

export default EditRightIssue;
