import { GridColDef } from "@mui/x-data-grid-pro";
import { useAppContext } from "AppContext";
import {
  CgMathPlus,
  ConfirmDeleteModal,
  GKButton,
  GKDataGrid,
  Grid2,
  Show,
  Typography,
} from "components";
import useGenerateQueryParams from "hooks/useGenerateQueryParams";
import { useFetchQuery, useMutation } from "hooks/useQueries";
import { useState } from "react";
import { moneyCommaSeparator } from "utils/MathFunction";
import {
  GET_ALL_MUTUAL_FUND_TRANSACTION,
  GET_ALL_MUTUAL_FUND_TRANSACTION_BY_PORTFOLIO_ID,
} from "utils/apiRoutes";
import axiosInstance from "utils/axiosInstance";
import { handleCapitalize, UserRoleTypes } from "utils/common";
import { extraColumnsField, matchDate } from "utils/helpers";
import { QueryKeys } from "utils/queryKey";
import useDocumentTitle from "utils/useDocumentTitle";
import MutualFundModal from "./MutualFundModal";

interface Props {
  id?: number | string;
  name?: string;
  tableName?: string;
}

const columns: GridColDef[] = [
  {
    ...extraColumnsField("Date", "date", 150, 1),
    valueFormatter: (value) => matchDate(value.value),
  },
  {
    ...extraColumnsField("Portfolio", "clientName", 200, 1),
    renderCell: (params) => {
      return (
        <Grid2
          container
          display={"flex"}
          flexDirection={"column"}
          alignItems={"flex-start"}
        >
          <Typography
            fontWeight={700}
            maxWidth={"100%"}
            textOverflow={"ellipsis"}
            overflow={"hidden"}
          >
            {params?.row?.clientName}
          </Typography>
          <Typography fontSize={12}>
            {params?.row?.portfolioName} - {params?.row?.portfolioCode}
          </Typography>
        </Grid2>
      );
    },
  },
  {
    ...extraColumnsField("Folio No.", "folioNo", 150, 1),
  },
  {
    ...extraColumnsField("Company Name", "companyName", 200, 1),
  },
  {
    ...extraColumnsField("ISIN Code", "isinCode", 150, 1),
    align: "right",
    headerAlign: "right",
  },
  {
    ...extraColumnsField("Trade Type", "transactionType", 120, 1),
    valueFormatter: (params) =>
      handleCapitalize(params?.value === "BUY" ? "Purchase" : "Redemption"),
  },
  {
    ...extraColumnsField("Transaction Remarks", "transactionRemarks", 150, 1),
  },
  {
    ...extraColumnsField("Quantity", "quantity", 120, 1),
    align: "right",
    headerAlign: "right",
    valueGetter: (params) => params?.row?.quantity || "0",
    valueFormatter: (params: any) => params?.value,
  },
  {
    ...extraColumnsField("Price", "price", 150, 1),
    align: "right",
    headerAlign: "right",
    valueGetter: (params) => params?.row?.price || "0",
    valueFormatter: (params) => moneyCommaSeparator(params.value),
  },
  {
    ...extraColumnsField("Brokerage", "brokerage", 150, 1),
    align: "right",
    headerAlign: "right",
    valueGetter: (params) => params.row.brokerage || "0",
    valueFormatter: (params) => moneyCommaSeparator(params.value),
  },
  {
    ...extraColumnsField("Stamp Charges", "stampCharges", 170, 1),
    align: "right",
    headerAlign: "right",
    valueGetter: (params) => params.row.stampCharges || "0",
    valueFormatter: (params) => moneyCommaSeparator(params.value),
  },
  {
    ...extraColumnsField("Bill Amount", "billAmount", 150, 1),
    align: "right",
    headerAlign: "right",
    valueGetter: (params) => params.row.billAmount || "0",
    valueFormatter: (params) => moneyCommaSeparator(params.value),
  },
];

const MutualFunds = (props: Props) => {
  useDocumentTitle(!props.id ? "Mutual Funds" : "Portfolio Reports");

  const { id, name, tableName } = props;

  const {
    state: { userData },
  } = useAppContext();

  const [openMutualFundModal, setOpenMutualFundModal] = useState(false);
  const [mutualFundData, setMutualFundData] = useState({});

  const [selectedIds, setSelectedIds] = useState([]);

  const [deleteConfirmation, setDeleteConfirmation] = useState<boolean>(false);

  const requestBody = useGenerateQueryParams(
    name ? "portfolioMutualFunds" : "mutualFund",
  );

  const { data, isFetching, refetch } = useFetchQuery({
    route: id
      ? GET_ALL_MUTUAL_FUND_TRANSACTION_BY_PORTFOLIO_ID(id)
      : GET_ALL_MUTUAL_FUND_TRANSACTION,
    key: [QueryKeys.MUTUAL_FUND_LIST, requestBody],
    requestBody,
  });

  const { mutate: handleMultiDelete } = useMutation({
    mutationFn: (selectedIds: any) =>
      axiosInstance?.delete(`/mutual_funds_transaction/bulk_delete/`, {
        data: { ids: selectedIds },
      }),
    onSuccess: () => {
      refetch();
      setDeleteConfirmation(false);
      setSelectedIds([]);
    },
  });

  return (
    <Grid2 container>
      <GKDataGrid
        checkboxSelection={userData?.userRole !== UserRoleTypes.client}
        onRowSelectionModelChange={(params) => {
          setSelectedIds(params);
        }}
        rowSelectionModel={selectedIds}
        loading={isFetching}
        maxHeight={"calc(100vh - 309px)"}
        columns={columns || []}
        rows={data?.data?.data || []}
        totalCount={data?.data?.totalCount || 0}
        name={name ? "portfolioMutualFunds" : "mutualFund"}
        onRowClick={(params) => {
          setMutualFundData(params.row);
          setOpenMutualFundModal(true);
        }}
        headerComponent={
          userData?.userRole !== UserRoleTypes.client && (
            <Grid2 container gap={1} justifyContent={"end"}>
              {selectedIds?.length !== 0 && (
                <GKButton
                  variant="outlined"
                  size="medium"
                  color="error"
                  onClick={() => {
                    setDeleteConfirmation(true);
                  }}
                >
                  Delete
                </GKButton>
              )}
              {selectedIds?.length !== 0 && (
                <GKButton
                  variant="outlined"
                  size="medium"
                  color="primary"
                  onClick={() => {
                    setSelectedIds([]);
                  }}
                >
                  Cancel
                </GKButton>
              )}
              {selectedIds?.length === 0 && (
                <GKButton
                  variant="contained"
                  size="medium"
                  onClick={() => {
                    setMutualFundData({});
                    setOpenMutualFundModal(true);
                  }}
                  startIcon={<CgMathPlus size={14} />}
                >
                  Add Trade
                </GKButton>
              )}
            </Grid2>
          )
        }
        tableName={tableName || "mutual_funds_list"}
        exportFile={{
          path: id
            ? GET_ALL_MUTUAL_FUND_TRANSACTION_BY_PORTFOLIO_ID(id)
            : GET_ALL_MUTUAL_FUND_TRANSACTION,
          exportName: "ExportMutualFund",
          paramsValue: {
            key: "inst_type",
            value: "MF",
          },
        }}
      />

      <Show.When isTrue={openMutualFundModal}>
        <MutualFundModal
          open={openMutualFundModal}
          setOpen={setOpenMutualFundModal}
          mutualFundData={mutualFundData}
          portfolioId={id}
        />
      </Show.When>

      {deleteConfirmation && (
        <ConfirmDeleteModal
          open={deleteConfirmation}
          setOpen={setDeleteConfirmation}
          onDelete={() => handleMultiDelete(selectedIds)}
          title={"Mutual Fund"}
          isDelete
          deleteMessage={`Are you sure that you want to delete the selected entries & once deleted it cannot be reverted?`}
        />
      )}
    </Grid2>
  );
};

export default MutualFunds;
