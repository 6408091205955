import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import axiosInstance from "utils/axiosInstance";
import { refetchQuery } from "utils/helpers";
import { QueryKeys } from "utils/queryKey";

interface PortfolioState {
  loading: boolean;
  error: string | null;
}

const initialState: PortfolioState = {
  loading: false,
  error: null,
};

export const createPortfolio = createAsyncThunk(
  "portfolio/create",
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post("/portfolios/", data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(
        error.response?.data?.message || "Something went wrong",
      );
    }
  },
);

const portfolioSlice = createSlice({
  name: "portfolio",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createPortfolio.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createPortfolio.fulfilled, (state, action) => {
        state.loading = false;
        toast.success(
          action.payload?.message || "Portfolio created successfully",
        );
        refetchQuery("GET_ALL_PORTFOLIOS");
        refetchQuery("GET_UPLOAD_PORTFOLIO_DROPDOWN");
        refetchQuery(QueryKeys.PORTFOLIOS_SHORT_DATA);
      })
      .addCase(createPortfolio.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
        toast.error((action.payload as string) || "Failed to create portfolio");
      });
  },
});

export default portfolioSlice.reducer;
