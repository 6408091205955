import { ErrorPage, PrivateRoute } from "components";
import GKNoAccess from "components/GKNoAccess";
import ClientOnBoardingLayout from "layouts/ClientOnBoardingLayout";
import RALayout from "layouts/RALayout";
import SmallCaseLayout from "layouts/SmallCaseLayout";
import AccountSetting from "Pages/AccountSetting";
import AdvisorOnboardingPaymentPage from "Pages/AdvisorOnboarding/AdvisorOnboardingPaymentPage";
import ForgotPassword from "Pages/Authentication/ForgotPassword";
import Register from "Pages/Authentication/Register";
import ClientDetailsPage from "Pages/Client/ClientDetailsPage";
import CompanyOnboarding from "Pages/CompanyInformation/CompanyOnboarding";
import DetailsPage from "Pages/CompanyOrganisation/Partner/DetailsPage";
import ExternalRiskProfile from "Pages/Compliance/ExternalRiskProfile";
import EquityBasket from "Pages/EquityBasket";
import AddPortfolioDetailsModal from "Pages/EquityBasket/AddPortfolioDetailsModal";
import BasketViewTab from "Pages/EquityBasket/BasketViewTab";
import EquitybasketDetails from "Pages/EquityBasket/EquityBasketDetails";
import EquityBasketManage from "Pages/EquityBasket/EquityBasketManage/Manage";
import EquityBasketOrderManager from "Pages/EquityBasket/EquityBasketOrderManager";
import OrderManagerPortfolios from "Pages/EquityBasket/EquityBasketOrderManager/OrderManagerPortfolios";
import EquityBasketPortfolioMapping from "Pages/EquityBasket/EquityBasketPortfolioMapping";
import EquityBasketSettings from "Pages/EquityBasket/EquityBasketSettings";
import ModelPortfolioVersion from "Pages/EquityBasket/ModelPortfolioVersion";
import AddModelportfolioVersionModal from "Pages/EquityBasket/ModelPortfolioVersion/AddModelportfolioVersionModal";
import ViewModelPortfolioVersion from "Pages/EquityBasket/ModelPortfolioVersion/ViewModelPortfolioVersion";
import TotalOrders from "Pages/EquityBasket/TotalOrders";
import EquityEtfForm from "Pages/EquityEtf/EquityEtfForm";
import FamilyDetails from "Pages/Family/FamilyDetails";
import FixedDepositForm from "Pages/FixedDepositForm";
import FixedDepositInterestPayout from "Pages/FixedDepositForm/FixedDepositInterestPayout";
import IncomeExpenseForm from "Pages/IncomeExpense/IncomeExpenseForm";
import InvestorOnboarding from "Pages/InvestorOnboarding";
import { InvestorContextProvider } from "Pages/InvestorOnboarding/InvestorContext";
import InvestorPrivateRoute from "Pages/InvestorOnboarding/InvestorPrivateRoute";
import OnBoarding from "Pages/InvestorOnboarding/OnBoarding";
import ExternalLeadRisk from "Pages/Lead/ExternalLeadRisk";
import AdminDetails from "Pages/Organisations/AdminDetails";
import CompanyMasterDashboard from "Pages/OtherAssets/CompanyMasterDashboard";
import CompanyMasterSetting from "Pages/OtherAssets/CompanyMasterSetting";
import EditPrivateEquity from "Pages/OtherAssets/EditPrivateEquity";
import ConfigurePaymentForm from "Pages/PaymentGateway/ConfigurePaymentForm";
import PortfolioDetailsPage from "Pages/Portfolio/PortfolioDetailsPage";
import PortfolioETFTransaction from "Pages/Portfolio/PortfolioETFTransaction";
import NAV from "Pages/Portfolio/SummaryPages/NAV";
import GenerateReport from "Pages/ReportManager/GenerateReports";
import CommissionEntries from "Pages/RevenueManagement/CommissionManager/CommissionEntries";
import PayCommission from "Pages/RevenueManagement/CommissionManager/PayCommission";
import CouponCreation from "Pages/RevenueManagement/Coupon/CouponCreation";
import PaymentLinkList from "Pages/RevenueManagement/Invoice/PaymentLinkList";
import MandatePresentation from "Pages/RevenueManagement/RevenueMandate/MandatePresentation";
import MandatePresentationTransaction from "Pages/RevenueManagement/RevenueMandate/MandatePresentationTransaction";
import SubscriptionPaymentHistory from "Pages/RevenueManagement/SubscriptionHistory/SubscriptionPaymentHistory";
import CreateRoleWisePermission from "Pages/Settings/CreateRoleWisePermission";
import SmallCase from "Pages/SmallCase";
import OrderGeneration from "Pages/SmallCase/LMOrderDetails.tsx/OrderGeneration";
import RASmallcase from "Pages/SmallCase/RASmallcase";
import VerifyDocument from "Pages/VerifyDocument";
import Wallet from "Pages/Wallet";
import AboutUs from "Pages/Website/Aboutus/AboutUs";
import ContactUsPage from "Pages/Website/ContactUsPage";
import Disclaimer from "Pages/Website/Disclaimer";
import FAQPage from "Pages/Website/FAQPage";
import HomePage from "Pages/Website/Home";
import PrivacyPolicy from "Pages/Website/PrivacyPolicy";
import RefundPolicy from "Pages/Website/RefundPolicy";
import TermsCondition from "Pages/Website/TermsCondition";
import WealthBasket from "Pages/Website/WealthBasket/WealthBasket";
import WealthPartner from "Pages/Website/WealthPartner/WealthPartner";
import WealthWise from "Pages/Website/WealthWise/WealthWise";
import CompanyOrganisationMenu from "SubMenuPages/CompanyOrganisationMenu";
import CRMMenu from "SubMenuPages/CRMMenu";
import DashboardMenu from "SubMenuPages/DashboardMenu";
import ImportsMenu from "SubMenuPages/ImportsMenu";
import ReportsMenu from "SubMenuPages/ReportsMenu";
import RevenueMenu from "SubMenuPages/RevenueMenu";
import {
  AdviceRegisterNotes,
  AppLayout,
  ApplyRightIssue,
  ArchiveManager,
  AuthenticationLayout,
  ChangePassword,
  ClientAgreement,
  ClientKYC,
  ClientRiskProfilingPage,
  CompliancePage,
  CreateCalculation,
  CreateCollection,
  CreateFeePlans,
  Dashboard,
  EditFeePlan,
  EditRevenuePortfolio,
  EditRightIssue,
  FeeCollection,
  FeePlans,
  InsightsHoldings,
  InsightsHoldingsDetails,
  Lead,
  LeadDetail,
  LoginPage,
  OTP,
  PortfolioGroupDetails,
  PortfolioHoldingTransactions,
  Questionnaire,
  RegisterDetails,
  RightIssue,
  SheetPortfolioMapping,
  UploadManager,
  ViewQuestionnaire,
  Website,
} from "./Pages";

export const productRoutes = [
  {
    path: "/",
    element: <Website />,
    child: [
      { element: <HomePage />, path: "/home" },
      { element: <WealthBasket />, path: "product/wealth-basket" },
      { element: <WealthWise />, path: "product/wealth-wise" },
      { element: <WealthPartner />, path: "product/wealth-partner" },
      { element: <AboutUs />, path: "about-us" },
      { element: <TermsCondition />, path: "terms-condition" },
      { element: <PrivacyPolicy />, path: "privacy-policy" },
      { element: <Disclaimer />, path: "disclaimer" },
      { element: <RefundPolicy />, path: "refund-policy" },
      { element: <ContactUsPage />, path: "contact-us" },
      { element: <FAQPage />, path: "faq" },
    ],
  },
  {
    path: "/broker-integration",
    elements: <SmallCaseLayout />,
    child: [
      { path: "broker", element: <SmallCase /> },
      { path: "order", element: <OrderGeneration /> },
    ],
  },
  {
    element: <AuthenticationLayout />,
    path: "/login",
    child: [
      { element: <OTP />, path: "opt-login" },
      { element: <Register />, path: "authentication" },
      { element: <ChangePassword />, path: "password" },
      { element: <ForgotPassword />, path: "forgot-password" },
      { element: <RegisterDetails />, path: "register-details" },
      { element: <LoginPage />, path: "" },
    ],
  },
  { element: <RASmallcase />, path: "/connect-client-payment-gateway" },
  { element: <ExternalRiskProfile />, path: "/client-risk-profile" },
  { element: <ExternalLeadRisk />, path: "/lead-risk-profile" },
  { element: <ErrorPage />, path: "*" },
  { element: <RALayout />, path: "/user-details-ra" },
  { element: <VerifyDocument />, path: "upload-proofs" },
  { element: <AdvisorOnboardingPaymentPage />, path: "payment-verification" },
  {
    path: "/company-master",
    element: (
      <PrivateRoute>
        <AppLayout />
      </PrivateRoute>
    ),
    child: [
      { element: <CompanyMasterDashboard />, path: "dashboard" },
      { element: <CompanyMasterSetting />, path: "setting" },
      { element: <EditPrivateEquity />, path: "equity/:id" },
    ],
  },
  {
    path: "/app",
    element: (
      <PrivateRoute>
        <AppLayout />
      </PrivateRoute>
    ),
    child: [
      { element: <Wallet />, path: "wallet" },
      { element: <CompanyOnboarding />, path: "company-onboarding" },
      { element: <EquityEtfForm />, path: "create-transaction" },
      { element: <EquityEtfForm />, path: "edit-transaction/:id" },
      { element: <InsightsHoldings />, path: "insights-holdings" },
      {
        element: <InsightsHoldingsDetails />,
        path: "insights-holdings/:id/details",
      },
      {
        element: <PortfolioHoldingTransactions />,
        path: "holdings/:id/transactions",
      },
      { element: <UploadManager />, path: "upload" },
      { element: <ClientRiskProfilingPage />, path: "risk-profile/:id" },
      { element: <Questionnaire />, path: "risk-profile/:id/questionnaire" },
      {
        element: <ViewQuestionnaire />,
        path: "risk-profile/:id/questionnaire/:questionnaireId",
      },
      { element: <ArchiveManager />, path: "upload/archive-manager" },
      { element: <FamilyDetails />, path: "family/:id" },
      { element: <PortfolioGroupDetails />, path: "portfolio/group/:id" },
      { element: <ClientDetailsPage />, path: "clients/:id" },
      { element: <ClientKYC />, path: "clients/:id/kyc" },
      { element: <ClientAgreement />, path: "clients/:id/agreement" },
      { element: <EquityBasket />, path: "equity-basket" },
      { element: <TotalOrders />, path: "equity-basket/orders" },
      { element: <BasketViewTab />, path: "equity-basket/:id" },
      { element: <EquitybasketDetails />, path: "equity-basket/:id/edit" },
      {
        element: <EquityBasketSettings />,
        path: "equity-basket/:equityBasketId/settings",
      },
      { element: <EquityBasketManage />, path: "equity-basket/:id/manage" },
      {
        element: <AddPortfolioDetailsModal />,
        path: "equity-basket/:id/assets/:assetId/details",
      },
      {
        element: <ModelPortfolioVersion />,
        path: "equity-basket/:id/model-portfolio",
      },
      {
        element: <ViewModelPortfolioVersion />,
        path: "equity-basket/:id/model-portfolio/:modelPortfolioId",
      },
      {
        element: <AddModelportfolioVersionModal />,
        path: "equity-basket/:id/model-portfolio/:modelPortfolioId/edit",
      },
      {
        element: <AddModelportfolioVersionModal />,
        path: "equity-basket/:id/model-portfolio/add",
      },
      {
        element: <EquityBasketOrderManager />,
        path: "equity-basket/:id/order-manager",
      },
      {
        element: <OrderManagerPortfolios />,
        path: "equity-basket/:id/order-manager/:orderId/portfolios",
      },
      {
        element: <EquityBasketPortfolioMapping />,
        path: "equity-basket/:id/portfolio",
      },
      {
        element: <SheetPortfolioMapping />,
        path: "user-profile/:id/portfolio-mapping",
      },
      // {
      //   element: <CompanyInformation />,
      //   path: "user-profile/company-information",
      // },
      { element: <AccountSetting />, path: "user-profile/settings" },
      { element: <GenerateReport />, path: "generate-report" },
      { element: <CompliancePage />, path: "compliance" },
      { element: <Lead />, path: "lead" },
      { element: <LeadDetail />, path: "lead/detail/:id/profile" },
      { element: <LeadDetail />, path: "lead/detail/:id/summary" },
      { element: <LeadDetail />, path: "lead/detail/:id/meeting" },
      { element: <LeadDetail />, path: "lead/detail/:id/notes" },
      { element: <Questionnaire />, path: ":type/:id/questionnaire" },
      { element: <RightIssue />, path: "right-issue" },
      {
        element: <ApplyRightIssue />,
        path: "right-issue/:id/apply-right-issue",
      },
      {
        element: <EditRightIssue />,
        path: "right-issue/:id/update-right-issue",
      },
      { element: <AdviceRegisterNotes />, path: "advice-register-notes" },
      { element: <FeePlans />, path: "fee-plans" },
      { element: <CreateFeePlans />, path: "fee-plans/create-fee-plan" },
      { element: <EditFeePlan />, path: "fee-plans/:id/edit-fee-plan" },
      {
        element: <EditRevenuePortfolio />,
        path: "revenue-manager/:id/edit-portfolio",
      },
      {
        element: <CreateCalculation />,
        path: "revenue-manager/create-calculation",
      },
      {
        element: <CreateCalculation />,
        path: "revenue-manager/create-calculation/:portfolioId",
      },
      {
        element: <CreateCalculation />,
        path: "revenue-manager/:id/edit-calculation",
      },
      { element: <FeeCollection />, path: "fee-collection" },
      { element: <CreateCollection />, path: "collection/create" },
      { element: <CreateCollection />, path: "collection/:id/edit" },
      { element: <DashboardMenu />, path: "holdings" },
      { element: <Dashboard />, path: "dashboard" },
      { element: <CompanyOrganisationMenu />, path: "organisation" },
      { element: <AdminDetails />, path: "admin/:id" },
      { element: <CRMMenu />, path: "crm" },
      { element: <RevenueMenu />, path: "revenue" },
      { element: <ReportsMenu />, path: "reports" },
      { element: <ImportsMenu />, path: "imports" },
      { element: <PortfolioDetailsPage />, path: "holdings/portfolio/:id" },
      {
        element: <PortfolioETFTransaction />,
        path: "holdings/portfolio/:id/etf-transaction",
      },
      { element: <CreateRoleWisePermission />, path: ":role/permission" },
      { element: <CreateRoleWisePermission />, path: ":role/permission/:id" },
      { element: <NAV />, path: "nav/:id" },
      { element: <PaymentLinkList />, path: "invoice/payment-link" },
      { element: <ConfigurePaymentForm />, path: "connect-payment-gateway" },
      {
        element: <ConfigurePaymentForm />,
        path: "connect-payment-gateway/:id",
      },
      { element: <IncomeExpenseForm />, path: "income-expense" },
      { element: <IncomeExpenseForm />, path: "income-expense/:id" },
      {
        element: <IncomeExpenseForm />,
        path: "income-expense/portfolio/:portfolioId",
      },
      {
        element: <MandatePresentation />,
        path: "revenue/mandate/:id/mandate-presentation",
      },
      {
        element: <MandatePresentationTransaction />,
        path: "revenue/mandate/:id/mandate-presentation/:presentationId/transaction",
      },
      { element: <DetailsPage />, path: "partner/:id" },
      { element: <PayCommission />, path: "commission-manager/pay-commission" },
      {
        element: <CommissionEntries />,
        path: "commission-manager/pay-commission/:id/entries",
      },
      {
        element: <FixedDepositForm />,
        path: "create-fixed-deposit-transaction",
      },
      {
        element: <FixedDepositForm />,
        path: "edit-fixed-deposit-transaction/:id",
      },
      {
        element: <FixedDepositInterestPayout />,
        path: "reports/fixed-deposit-interest",
      },
      {
        element: <FixedDepositInterestPayout />,
        path: "holdings/portfolio/:id/fixed-deposit-interest",
      },
      {
        path: "subscription-history/:id/payment-history/",
        element: <SubscriptionPaymentHistory />,
      },
      {
        path: "create-coupon",
        element: <CouponCreation />,
      },
      {
        path: "coupon/:id/edit-coupon",
        element: <CouponCreation />,
      },
      { element: <GKNoAccess />, path: "access-denied" },
    ],
  },
  {
    path: "/investor",
    element: (
      <InvestorContextProvider>
        <InvestorPrivateRoute>
          <ClientOnBoardingLayout />
        </InvestorPrivateRoute>
      </InvestorContextProvider>
    ),
    child: [
      { path: "get-start", element: <InvestorOnboarding /> },
      { path: "onboarding", element: <OnBoarding /> },
    ],
  },
];
