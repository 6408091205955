import { useMutation } from "hooks/useQueries";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import axiosInstance from "utils/axiosInstance";

export const useTransactionAction = () => {
  const navigate = useNavigate();

  const { id } = useParams();

  const { state } = useLocation();

  const getTransactionType = state?.transactionType === "OTHER-ASSETS";
  const getAssetType = state?.assetType;

  const { mutate: handleCreate, isLoading: createLoading } = useMutation({
    mutationKey: ["ADD_TRANSACTION"],
    mutationFn: (data: any) =>
      axiosInstance.post(
        getTransactionType ? "/other_assets/" : `/transaction/`,
        data,
      ),
    onSuccess: () => {
      navigate(-1);
    },
  });

  const { mutate: handleUpdateStock, isLoading: mutationUpdateLoading } =
    useMutation({
      mutationKey: ["UPDATE_TRANSACTION"],
      mutationFn: (data: any) =>
        axiosInstance.patch(
          getTransactionType
            ? `/other_assets/${id}/?asset_type=${getAssetType}`
            : `/transaction/${id}/`,
          data,
        ),
      onSuccess: () => {
        navigate(-1);
      },
    });

  const { mutate: handleDelete, isLoading: deleteLoading } = useMutation({
    mutationKey: ["DELETE_TRANSACTION"],
    mutationFn: (id: any) =>
      axiosInstance.delete(
        getTransactionType
          ? `/other_assets/${id}/?asset_type=${getAssetType}`
          : `/transaction/${id}/`,
      ),
    onSuccess: () => {
      navigate(-1);
    },
  });

  return {
    handleCreate,
    createLoading,
    handleUpdateStock,
    mutationUpdateLoading,
    handleDelete,
    deleteLoading,
  };
};
