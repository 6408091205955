import { useAppContext } from "AppContext";
import { useFetchQuery, useMutation } from "hooks/useQueries";
import { COMPANY_SETTING_BY_ID } from "utils/apiRoutes";
import axiosInstance from "utils/axiosInstance";
import { refetchQuery } from "utils/helpers";
import { QueryKeys } from "utils/queryKey";

const useAccountSetting = () => {
  const {
    state: { commonFilter, userData },
    dispatch,
  } = useAppContext();

  const { data: getCompanySetting } = useFetchQuery({
    key: [QueryKeys.PREFERENCES],
    route: COMPANY_SETTING_BY_ID(userData?.companyId),
    success: (response: any) => {
      const d = Object.entries(commonFilter)?.map(([key, val]) => {
        return {
          [key]: {
            ...val,
            showNonZero: response?.data?.showZeroHoldingTables,
          },
        };
      });

      const combinedObject = d.reduce((acc, obj) => {
        const item = Object.keys(obj)[0] as any;
        acc[item] = obj[item];
        return acc;
      }, {});

      dispatch({ type: "FILTER", data: combinedObject });
    },
  });

  const {
    mutate: handleUpdateCompanySetting,
    isLoading: updateCompanyLoading,
  } = useMutation({
    mutationKey: ["preferences"],
    mutationFn: (data: any) =>
      axiosInstance.patch(
        `/company/${userData?.companyId}/company_settings/`,
        data,
      ),
    onSuccess: () => {
      refetchQuery(QueryKeys.PREFERENCES);
      refetchQuery(QueryKeys.GET_USER_DETAIL);
    },
  });

  return {
    getCompanySetting,
    handleUpdateCompanySetting,
    updateCompanyLoading,
  };
};

export default useAccountSetting;
