import { LoadingButton } from "@mui/lab";
import {
  Button,
  CgMathPlus,
  ConfirmDeleteModal,
  Divider,
  GKButton,
  GKEditor,
  GKModal,
  GKTextField,
  Grid2,
  Typography,
} from "components";
import { useFetchQuery, useMutation } from "hooks/useQueries";
import { useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import axiosInstance from "utils/axiosInstance";
import { refetchQuery } from "utils/helpers";

interface Props {
  setOpen: any;
  open: boolean;
  sheetDetails?: any;
}

const EstampModal = (props: Props) => {
  const { setOpen, open, sheetDetails } = props;

  const editorRef = useRef(null);

  const [confirmDelete, setConfirmDelete] = useState(false);

  const { mutate: submitForm, isLoading } = useMutation({
    mutationKey: ["ESTAMP"],
    mutationFn: (formData) => axiosInstance.post(`/estamp-tags/`, formData),
    onSuccess: () => {
      setOpen(false);
      refetchQuery("ESTAMP_TAGS_QUERY_KEY");
    },
  });

  const { mutate: updateEstamp } = useMutation({
    mutationKey: ["UPDATE_ESTAMP"],
    mutationFn: (data: any) =>
      axiosInstance.patch(`/estamp-tags/${sheetDetails.id}/`, data),
    onSuccess: () => {
      setOpen(false);
      refetchQuery("ESTAMP_TAGS_QUERY_KEY");
    },
  });

  const { mutate: deleteTag } = useMutation({
    mutationKey: ["DELETE_ESTAMP"],
    mutationFn: async ({ id }: { id: any }) => {
      return axiosInstance.delete(`/estamp-tags/${id}/`);
    },
    onSuccess: () => {
      setOpen(false);
      refetchQuery("ESTAMP_TAGS_QUERY_KEY");
    },
  });

  const form = useForm({
    defaultValues: {
      tags: sheetDetails?.tags || "",
      description: sheetDetails?.description || "",
    },
  });

  const handleDeleteExecution = () => {
    deleteTag({ id: sheetDetails.id });
    setConfirmDelete(false);
  };

  const onSubmit = (data: any) => {
    if (sheetDetails?.id) {
      updateEstamp(data);
    } else {
      submitForm(data);
    }
  };

  // ============================================================================
  const appendVariable = (variableValue: string) => {
    if (!editorRef.current) {
      console.error("Editor reference is not available");
      return;
    }

    const editor = editorRef && (editorRef?.current as any);

    editor.model.change((writer: any) => {
      const { selection } = editor.model.document;
      const position = selection.getFirstPosition();
      writer.insertText(`{{${variableValue}}}`, position);
    });
  };

  const { data } = useFetchQuery({
    key: ["GET_ESTAMP_VARIABLE"],
    route: `/estamp-tags/variables/`,
  });
  console.log("🚀 ~ EstampModal ~ data:", data);

  return (
    <>
      <GKModal
        modalTitle={sheetDetails?.tags ? "Edit E-Stamp" : "Add E-Stamp"}
        open={open}
        setOpen={setOpen}
        titleComponent={
          sheetDetails?.id && (
            <Button
              variant="outlined"
              size="medium"
              color="error"
              onClick={() => setConfirmDelete(true)}
            >
              Delete
            </Button>
          )
        }
        footer={
          <Grid2 container gap={1} justifyContent={"flex-end"}>
            <Button
              onClick={() => setOpen(false)}
              variant="outlined"
              color="secondary"
            >
              Cancel
            </Button>
            <LoadingButton
              variant="contained"
              color="primary"
              onClick={form.handleSubmit(onSubmit)}
              loading={isLoading}
            >
              {sheetDetails?.tags ? "Update" : "Submit"}
            </LoadingButton>
          </Grid2>
        }
      >
        <Grid2 container size={12} spacing={2}>
          <Grid2 size={12}>
            <Controller
              name="tags"
              control={form.control}
              rules={{
                required: {
                  value: true,
                  message: "Please enter tags",
                },
              }}
              render={({ field }) => (
                <GKTextField
                  {...field}
                  requiredField
                  inputLabel="Tag"
                  onChange={(e) => field.onChange(e.target.value)}
                  placeholder="Enter Tag"
                />
              )}
            />
          </Grid2>
          <Grid2 size={12}>
            <Typography fontWeight={600} fontSize={16}>
              Dynamic Values
            </Typography>
            <Divider sx={{ my: 1 }} />
            {data?.data?.data.map((variable: any) => (
              <GKButton
                sx={{
                  margin: "4px",
                  "& .MuiButton-icon": {
                    marginRight: "2px",
                  },
                }}
                variant="outlined"
                color="dark"
                size="extraSmall"
                key={variable?.value}
                onClick={() => appendVariable(variable?.value)}
                startIcon={<CgMathPlus size={14} />}
              >
                {variable?.name || "-"}
              </GKButton>
            ))}
          </Grid2>{" "}
          <Grid2 size={12}>
            <Controller
              name="description"
              control={form.control}
              rules={{
                required: {
                  value: true,
                  message: "Please enter description",
                },
              }}
              render={({ field }) => (
                <GKEditor
                  {...field}
                  inputLabel="Description"
                  onReady={(editor: any) => {
                    editorRef.current = editor;
                  }}
                  toolbar={[
                    "undo",
                    "redo",
                    "|",
                    "fontColor",
                    "fontBackgroundColor",
                  ]}
                />
              )}
            />
          </Grid2>
        </Grid2>
      </GKModal>

      {confirmDelete && (
        <ConfirmDeleteModal
          open={confirmDelete}
          setOpen={setConfirmDelete}
          title="E-Stamp Tag"
          onDelete={handleDeleteExecution}
          isDelete
        />
      )}
    </>
  );
};

export default EstampModal;
