import { LoadingButton } from "@mui/lab";
import { useAppContext } from "AppContext";
import {
  Box,
  ConfirmDeleteModal,
  Divider,
  GKButton,
  Grid2,
  Show,
  Tab,
  Tabs,
  Typography,
} from "components";
import { useFetchQuery, useMutation } from "hooks/useQueries";
import DashboardLayout from "layouts/DashboardLayout";
import NoteModal from "Pages/AdviceRegisterNotes/NoteModal";
import React, { useState } from "react";
import { BiLockAlt } from "react-icons/bi";
import { useNavigate, useParams } from "react-router-dom";
import { CLIENT_BY_ID } from "utils/apiRoutes";
import axiosInstance from "utils/axiosInstance";
import { QueryKeys } from "utils/queryKey";
import useDocumentTitle from "utils/useDocumentTitle";
import ClientCard from "./ClientCard";
import ClientNotes from "./ClientNotes";
import ComplianceTab from "./ComplianceTabs";
import EditClientDetails from "./ProfilePages/EditClientDetails";
import AccessPage from "./SettingsPages/Access";
import ClientPermissions from "./SettingsPages/ClientPermissions";
import FamilyPage from "./SumaryPages/Family";
import HoldingsMainPage from "./SumaryPages/Holdings";
import PortfolioPage from "./SumaryPages/Portfolios";

const ClientDetailsPage = () => {
  useDocumentTitle("Client Details");

  const { id } = useParams();

  const navigate = useNavigate();

  const {
    state: { tab: tabValue, userData },
    dispatch,
  } = useAppContext();

  const tabs = [
    { canAccess: true, label: "Profile", component: EditClientDetails },
    { canAccess: true, label: "Holdings", component: HoldingsMainPage },
    { canAccess: true, label: "Portfolios", component: PortfolioPage },
    { canAccess: true, label: "Family", component: FamilyPage },
    {
      canAccess: userData?.grantAccess,
      label: "Onboarding",
      component: ComplianceTab,
    },
    {
      canAccess: userData?.grantAccess,
      label: "Notes",
      component: ClientNotes,
    },
    {
      canAccess: userData?.grantAccess,
      label: "Permissions",
      component: ClientPermissions,
    },
    {
      canAccess: userData?.grantAccess,
      label: "Access",
      component: AccessPage,
    },
  ];

  const [confirmationModal, setConfirmationModal] = useState(false);
  const [openNoteModal, setOpenNoteModal] = useState(false);

  const { mutate, isLoading } = useMutation({
    mutationFn: (data: any) => axiosInstance.delete(`/client/${data}/`),
    mutationKey: ["DELETE_CLIENT"],
    onSuccess: () => {
      navigate(-1);
    },
  });

  const { mutate: sendRegistrationLink, isLoading: linkLoading } = useMutation({
    mutationFn: (data: any) =>
      axiosInstance.post("/send-client-reg/", { client: data }),
    mutationKey: ["SEND_REGISTRATION_LINK"],
  });

  const { data: clientData, isFetching } = useFetchQuery({
    key: [QueryKeys.CLIENTS_LIST, id],
    route: CLIENT_BY_ID(id),
  });

  return (
    <DashboardLayout title="Client Details" loading={isFetching}>
      <Grid2 container spacing={2}>
        <Grid2 size={12}>
          <ClientCard clientData={clientData?.data} />
        </Grid2>
        <Grid2 container size={12} display={"inline"}>
          <Grid2
            size={12}
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Tabs
              variant="scrollable"
              scrollButtons={false}
              value={tabValue?.clientERMPageTabs}
              onChange={(e, val) => {
                dispatch({
                  type: "TAB",
                  data: { ...tabValue, clientERMPageTabs: val },
                });
              }}
            >
              {tabs.map((tabItem, index) => (
                <Tab
                  key={index}
                  disabled={!tabItem.canAccess}
                  label={
                    <Box display={"flex"} gap={1}>
                      <Typography>{tabItem.label}</Typography>
                      {!tabItem.canAccess && <BiLockAlt size={16} />}
                    </Box>
                  }
                  disableRipple
                />
              ))}
            </Tabs>

            {tabValue?.clientERMPageTabs === 7 && (
              <div style={{ gap: "8px", display: "flex" }}>
                <LoadingButton
                  loading={linkLoading}
                  variant="outlined"
                  onClick={() => sendRegistrationLink(id)}
                >
                  Send Registration Link
                </LoadingButton>
                <LoadingButton
                  loading={isLoading}
                  variant="outlined"
                  color="error"
                  onClick={() => setConfirmationModal(true)}
                >
                  Delete Client
                </LoadingButton>
              </div>
            )}

            {tabValue?.clientERMPageTabs === 5 && (
              <GKButton
                variant="contained"
                onClick={() => setOpenNoteModal(true)}
              >
                Add Note
              </GKButton>
            )}
          </Grid2>
          <Grid2 size={12}>
            <Divider />
          </Grid2>
        </Grid2>
        <Grid2 size={12}>
          {tabs.map((tab, index) => (
            <Show.When
              key={index}
              isTrue={tabValue?.clientERMPageTabs === index}
            >
              {React.createElement(tab.component)}
            </Show.When>
          ))}
        </Grid2>
      </Grid2>
      <ConfirmDeleteModal
        onDelete={() => {
          mutate(id);
        }}
        title="Client"
        isDelete
        setOpen={setConfirmationModal}
        open={confirmationModal}
      />

      <Show.When isTrue={openNoteModal}>
        <NoteModal
          open={openNoteModal}
          setOpen={setOpenNoteModal}
          noteDetails={{}}
          clientId={id}
        />
      </Show.When>
    </DashboardLayout>
  );
};

export default ClientDetailsPage;
