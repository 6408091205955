import { reduce } from "lodash";

// ===###===###===###===###===###===###===###===###===###===###===###===###===###===###===###===###===###===###===###===###===###
export const handleFreshReturnFormData = ({
  obj,
  multipleFilesField,
  isEditForm,
}: {
  obj: any;
  multipleFilesField?: string;
  isEditForm: boolean;
}) => {
  const formData = new FormData();

  if (multipleFilesField) {
    obj[multipleFilesField]?.forEach((file: any, index: any) => {
      formData.append(`${multipleFilesField}[${index}]`, file);
    });
  }

  const handleReturnVal = (val: any) => {
    if (Array.isArray(val) && val?.length !== 0) {
      return JSON.stringify(val);
    }
    if (typeof val === "object" && val?.name && val?.size) {
      return val;
    }
    if (typeof val === "object") {
      return JSON.stringify(val);
    }
    if (val?.size) {
      return JSON.stringify(val[0]);
    }
    if (!isEditForm && !val) {
      return null;
    }
    if (!isEditForm && val?.length === 0) {
      return JSON.stringify([]);
    }
    return val;
  };

  const objectArr = Object.entries(obj).map(([key, val]: any) => {
    return {
      name: key,
      value: handleReturnVal(val),
    };
  });

  objectArr.forEach((item) => formData.append(item.name, item?.value ?? ""));

  return formData;
};

// ===###===###===###===###===###===###===###===###===###===###===###===###===###===###===###===###===###===###===###===###===###
export const handleReturnNaturalRows = ({
  options,
  values,
  selectedValueKey,
  optionsValueKey,
}: {
  options: any[];
  values: any[];
  selectedValueKey: string;
  optionsValueKey: string;
}) => {
  const selectedValues = values?.map((data) => data[selectedValueKey]);
  const newOptions = options?.filter(
    (da) => !selectedValues?.includes(da[optionsValueKey]),
  );

  return newOptions;
};
// ===###===###===###===###===###===###===###===###===###===###===###===###===###===###===###===###===###===###===###===###===###
export const handleReturnNaturalRowsForString = ({
  options,
  values,
  optionsValueKey,
}: {
  options: any[];
  values: any[];
  optionsValueKey: string;
}) => {
  const newOptions = options?.filter((da) => values !== da[optionsValueKey]);

  return newOptions;
};
// ===###===###===###===###===###===###===###===###===###===###===###===###===###===###===###===###===###===###===###===###===###
export const handleReturnChangedObject = ({
  dirtyFields,
  values,
}: {
  dirtyFields: any;
  values: any;
}) => {
  const filterDirtyFields = Object.keys(dirtyFields).map((d: any) => {
    return { [d]: values[d] };
  });

  const convertToObject = reduce(
    filterDirtyFields,
    (result: any, item: any) => {
      const key = Object.keys(item)[0];
      result[key] = item[key];
      return result;
    },
    {},
  );

  return convertToObject;
};
// ===###===###===###===###===###===###===###===###===###===###===###===###===###===###===###===###===###===###===###===###===###
export const handleCreateFormData = ({
  values,
  dirtyFields,
}: {
  values: any;
  dirtyFields: any;
}) => {
  const formData = new FormData();

  if (!dirtyFields || Object.keys(dirtyFields).length === 0) {
    return formData;
  }
  const appendToFormData = (key: string, value: any) => {
    if (value instanceof File) {
      formData.append(key, value, value.name);
    } else if (Array.isArray(value)) {
      if (value.length === 1 && value[0] instanceof File) {
        formData.append(key, value[0], value[0].name);
      } else if (
        value.length > 0 &&
        (typeof value[0] === "number" ||
          typeof value[0] === "string" ||
          typeof value[0] === "boolean")
      ) {
        formData.append(key, JSON.stringify(value));
      } else {
        value.forEach((item, index) => {
          if (item instanceof File) {
            formData.append(`${key}[${index}]`, item, item.name);
          } else if (typeof item === "object" && item !== null) {
            Object.keys(item).forEach((subKey) => {
              if (item[subKey] instanceof File) {
                formData.append(
                  `${key}[${index}][${subKey}]`,
                  item[subKey],
                  item[subKey].name,
                );
              } else {
                formData.append(
                  `${key}[${index}][${subKey}]`,
                  String(item[subKey]),
                );
              }
            });
          } else {
            formData.append(`${key}[${index}]`, String(item));
          }
        });
      }
    } else if (typeof value === "object" && value !== null) {
      Object.keys(value).forEach((subKey) => {
        appendToFormData(`${key}[${subKey}]`, value[subKey]);
      });
    } else {
      formData.append(key, String(value));
    }
  };

  const processFields = (obj: any, dirtyObj: any, parentKey = "") => {
    Object.keys(dirtyObj).forEach((key) => {
      const newKey = parentKey ? `${parentKey}[${key}]` : key;

      if (typeof dirtyObj[key] === "object" && dirtyObj[key] !== null) {
        processFields(obj[key], dirtyObj[key], newKey);
      } else if (dirtyObj[key] && obj[key] !== undefined) {
        appendToFormData(newKey, obj[key]);
      }
    });
  };

  processFields(values, dirtyFields);
  return formData;
};
