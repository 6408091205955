import { GKButton, Grid2, SimpleLoader } from "components";
import { useCompanyInformation } from "hooks/useCompanyInformation";
import { FormProvider } from "react-hook-form";
import useDocumentTitle from "utils/useDocumentTitle";
import EssentialPageForm from "./EssentialPageForm";

const CompanyInformationPage = () => {
  useDocumentTitle("Company Information");

  const {
    companyFetching,
    form,
    onSubmit,
    handleUpdate,
    croppedImage,
    setCroppedImage,
  } = useCompanyInformation();

  if (companyFetching) return <SimpleLoader />;

  return (
    <>
      <FormProvider {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          id="company-information-form"
        >
          <Grid2 container spacing={2}>
            <Grid2 size={12}>
              <EssentialPageForm
                handleUpdate={handleUpdate}
                croppedImage={croppedImage}
                setCroppedImage={setCroppedImage}
              />
            </Grid2>
            <Grid2
              size={12}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"flex-end"}
              my={1}
            >
              <GKButton
                type="submit"
                variant="contained"
                form={"company-information-form"}
              >
                Save
              </GKButton>
            </Grid2>
          </Grid2>
        </form>
      </FormProvider>
    </>
  );
};

export default CompanyInformationPage;
