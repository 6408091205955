import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import {
  GKSearchSelect,
  GKTextField,
  Grid2,
  Show,
  TextField,
} from "components";
import SimpleLoader from "components/SimpleLoader";
import { useFetchQuery, useMutation } from "hooks/useQueries";
import { MANDATE_MANAGEMENT_CATEGORY } from "Pages/PaymentGateway/helper";
import { useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { MANDATE_COMPANY_CONFIGURE_LIST } from "utils/apiRoutes";
import axiosInstance from "utils/axiosInstance";
import { refetchQuery } from "utils/helpers";
import { handleReturnAutocompletePlaceholder } from "utils/StringFunctions";
import useDocumentTitle from "utils/useDocumentTitle";
import { object, string } from "yup";
import VerifyModal from "./VerifyModal";

type FormValues = {
  id?: string;
  clientId?: string;
  clientSecret?: string;
  corporateConfigId?: string;
  upiCorporateConfigId?: string;
  corporateAccountNumber?: string;
  managementCategory?: string;
  company?: number;
  token?: string;
};

interface MandateInterface {
  data: {
    data: FormValues[];
  };
}

const validationSchema = object({
  clientId: string()
    .required("Please enter client ID")
    .test("no-empty-spaces", "Field cannot contain only spaces", (value) => {
      return value && value.trim().length > 0;
    }),
  clientSecret: string()
    .required("Please enter client secret")
    .test("no-empty-spaces", "Field cannot contain only spaces", (value) => {
      return value && value.trim().length > 0;
    }),
  corporateConfigId: string()
    .required("Please enter corporate config ID")
    .test("no-empty-spaces", "Field cannot contain only spaces", (value) => {
      return value && value.trim().length > 0;
    }),
  upiCorporateConfigId: string()
    .required("Please enter UPI corporate config ID")
    .test("no-empty-spaces", "Field cannot contain only spaces", (value) => {
      return value && value.trim().length > 0;
    }),
  corporateAccountNumber: string()
    .required("Please enter corporate account number")
    .test("no-empty-spaces", "Field cannot contain only spaces", (value) => {
      return value && value.trim().length > 0;
    }),
  managementCategory: string().required("Please select management category"),
});

const Mandate = () => {
  useDocumentTitle("Mandate");

  const [openVerifyModal, setOpenVerifyModal] = useState(false);

  const form = useForm<FormValues>({
    resolver: yupResolver(validationSchema),
  });

  const { isLoading } = useFetchQuery({
    key: ["MANDATE_LIST"],
    route: MANDATE_COMPANY_CONFIGURE_LIST,
    success: (response: MandateInterface) =>
      form.reset(response?.data?.data[0]),
  });

  const { mutate: handleMandate } = useMutation({
    mutationFn: (data: {
      values: FormValues;
      request: string;
      route: string;
    }) =>
      axiosInstance[data?.request === "post" ? "post" : "patch"](
        data.route,
        data.values,
      ),
    onSuccess: () => {
      refetchQuery("MANDATE_LIST");
      setOpenVerifyModal(false);
    },
  });

  const { mutate: sendOtp, isLoading: otpLoading } = useMutation({
    mutationKey: ["MANDATE_SEND_OTP"],
    mutationFn: (data) => axiosInstance.post(`/company-otp/${data}/`),
    onSuccess: () => {
      setOpenVerifyModal(true);
    },
  });

  if (isLoading) return <SimpleLoader />;

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(() => sendOtp("send"))}>
        <Grid2 container spacing={2}>
          <Grid2 size={4}>
            <Controller
              name="clientId"
              control={form.control}
              render={({ field, fieldState: { error } }) => (
                <GKTextField
                  {...field}
                  requiredField
                  inputLabel="Client ID"
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
          </Grid2>
          <Grid2 size={4}>
            <Controller
              name="clientSecret"
              control={form.control}
              render={({ field, fieldState: { error } }) => (
                <GKTextField
                  {...field}
                  requiredField
                  inputLabel="Client Secret Id"
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
          </Grid2>
          <Grid2 size={4}>
            <Controller
              name="corporateConfigId"
              control={form.control}
              render={({ field, fieldState: { error } }) => (
                <GKTextField
                  {...field}
                  requiredField
                  inputLabel="Corporate Config ID"
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
          </Grid2>
          <Grid2 size={4}>
            <Controller
              name="upiCorporateConfigId"
              control={form.control}
              render={({ field, fieldState: { error } }) => (
                <GKTextField
                  {...field}
                  requiredField
                  inputLabel="UPI Corporate Config ID"
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
          </Grid2>
          <Grid2 size={4}>
            <Controller
              name="corporateAccountNumber"
              control={form.control}
              render={({ field, fieldState: { error } }) => (
                <GKTextField
                  {...field}
                  requiredField
                  inputLabel="Corporate Account Number"
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
          </Grid2>
          <Grid2 size={4}>
            <Controller
              name="managementCategory"
              control={form.control}
              render={({ field, fieldState: { error } }) => (
                <GKSearchSelect
                  {...field}
                  requiredField
                  disableClearable={false}
                  inputLabel="Management Category"
                  options={MANDATE_MANAGEMENT_CATEGORY || []}
                  value={
                    MANDATE_MANAGEMENT_CATEGORY.find(
                      (dataItem) => dataItem?.value === field.value,
                    )?.name
                  }
                  onChange={(event, val) => field.onChange(val?.value)}
                  getOptionLabel={(option) => option.name || option}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder={handleReturnAutocompletePlaceholder({
                        placeholder: "Select category",
                        value: field.value,
                      })}
                      error={!!error}
                      helperText={error?.message}
                    />
                  )}
                />
              )}
            />
          </Grid2>
          <Grid2 size={12} display={"flex"} justifyContent={"flex-end"}>
            <LoadingButton
              size="medium"
              variant="contained"
              type="submit"
              loading={otpLoading}
            >
              Save
            </LoadingButton>
          </Grid2>
        </Grid2>
      </form>

      <Show.When isTrue={openVerifyModal}>
        <VerifyModal
          open={openVerifyModal}
          setOpen={setOpenVerifyModal}
          optApi={`company-otp`}
          onSuccess={form.handleSubmit((values) => {
            values.id
              ? handleMandate({
                  values: values,
                  request: "patch",
                  route: `/digio/company-configuration/${values?.id}/`,
                })
              : handleMandate({
                  values: values,
                  request: "post",
                  route: `/digio/company-configuration/`,
                });
          })}
        />
      </Show.When>
    </FormProvider>
  );
};

export default Mandate;
