import {
  Alert,
  Divider,
  FaChartBar,
  GoPlus,
  Grid2,
  Show,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tabs,
  TbUserSquareRounded,
  Typography,
} from "components";
import useGenerateQueryParams from "hooks/useGenerateQueryParams";
import { useFetchQuery } from "hooks/useQueries";
import DashboardLayout from "layouts/DashboardLayout";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { handleReturnPositiveNegative } from "utils/colorHelper";
import { formatPercentage } from "utils/helpers";
import { numberToCurrency } from "utils/MathFunction";
import { ThemeContainer } from "utils/Theme";
import PortfolioHoldingTradeBook from "./PortfolioHoldingsDetails/PortfolioHoldingTradeBook";
import PortfolioLongTermHolding from "./PortfolioHoldingsDetails/PortfolioLongTermHolding";
import PortfolioRealisedInventory from "./PortfolioRealisedInventory";

const PortfolioHoldingTransactions = () => {
  const location = useLocation();

  const { state } = useLocation();

  const { theme } = ThemeContainer();

  const type = new URLSearchParams(location.search).get("type");

  const [transactionTabs, setTransactionTabs] = useState(0);

  const requestBody = useGenerateQueryParams("portfolioHoldingTransaction", {
    folioNo: state?.folioNo,
    fdAccountNo: state?.fdAccountNo,
    gcode: state?.gcode || "",
  });

  const { data, isFetching } = useFetchQuery({
    key: ["HOLDINGS_TRANSACTIONS", requestBody],
    route: `/${state?.type}/${state?.holdingId}/company_transactions/`,
    requestBody,
  });

  const insertRequestBody = (type: string) => {
    let req = {};

    if (type === "FD") {
      req = {
        fdAccountNo: state?.fdAccountNo,
      };
    }

    if (type === "MF") {
      req = {
        folioNo: state?.folioNo,
      };
    }

    return req;
  };

  const { data: companyTransactionSummary } = useFetchQuery({
    key: ["GET_COMPANY_DETAILS_DATA"],
    route: `/holdings/${state?.gcode}/${state.type}/?portfolios=[${state?.holdingId}]`,
    requestBody: insertRequestBody(type),
  });

  const hasNegativeHoldings =
    data?.data?.data?.length === 0 ||
    data?.data?.data?.filter((dataItem: any) => !dataItem?.valid)?.length !== 0;

  return (
    <DashboardLayout title="Portfolio Trade Book">
      <Grid2 container spacing={1}>
        {!isFetching && hasNegativeHoldings && (
          <Grid2 size={12}>
            <Alert severity="error">
              We found some discrepancies in the transactions of this asset.
            </Alert>
          </Grid2>
        )}
        <Grid2
          size={companyTransactionSummary?.data?.instType === "MF" ? 10 : 7}
        >
          <Typography
            fontWeight={600}
            fontSize={20}
            display={"flex"}
            gap={2}
            alignItems="center"
          >
            {companyTransactionSummary?.data?.fullName}
            <span
              style={{
                fontSize: 14,
                color: theme.palette.secondaryText.main,
              }}
            >
              {type === "MF" &&
                ` Folio Number: ${companyTransactionSummary?.data?.folioNo}`}
              {type === "FD" &&
                ` FD Account Number: ${companyTransactionSummary?.data?.fdAccountNo}`}
            </span>
          </Typography>
          <Typography fontWeight={600} fontSize={16}>
            <span
              style={{
                fontWeight: 400,
                fontSize: 12,
                color: theme.palette.secondaryText.main,
                marginRight: 4,
              }}
            >
              {type === "FD" ? "Value" : "CMP"}
            </span>{" "}
            {companyTransactionSummary?.data?.cmp}{" "}
            {type !== "FD" && (
              <span
                style={{
                  color: handleReturnPositiveNegative(
                    companyTransactionSummary?.data?.todaysGainPercentage,
                  ),
                  fontSize: 12,
                  fontWeight: 400,
                }}
              >
                ( {companyTransactionSummary?.data?.todaysGainPercentage}% )
              </span>
            )}
          </Typography>
        </Grid2>
        {type !== "FD" && (
          <Grid2
            size={type === "MF" ? 2 : 5}
            display={"flex"}
            justifyContent={"flex-end"}
            alignItems={"center"}
          >
            <TableContainer>
              <Table>
                <TableBody>
                  <TableRow>
                    {type !== "MF" && (
                      <>
                        <TableCell
                          style={{
                            borderRight: `solid 1px ${theme.palette.grey[300]}`,
                            borderBottom: "none",
                            padding: "8px 16px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              gap: 8,
                            }}
                          >
                            <Typography
                              fontSize={12}
                              color={theme.palette.secondaryText.main}
                            >
                              NSE
                            </Typography>
                            <Typography fontWeight={600} fontSize={14}>
                              {companyTransactionSummary?.data?.nse || "-"}{" "}
                            </Typography>
                          </div>
                        </TableCell>
                        <TableCell
                          style={{
                            borderRight: `solid 1px ${theme.palette.grey[300]}`,
                            borderBottom: "none",
                            padding: "8px 16px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              gap: 8,
                            }}
                          >
                            <Typography
                              fontSize={12}
                              color={theme.palette.secondaryText.main}
                            >
                              BSE
                            </Typography>
                            <Typography fontWeight={600} fontSize={14}>
                              {companyTransactionSummary?.data?.bse || "-"}
                            </Typography>
                          </div>
                        </TableCell>
                      </>
                    )}
                    <TableCell
                      style={{
                        borderRight: `solid 1px ${theme.palette.grey[300]}`,
                        borderBottom: "none",
                        padding: "8px 16px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          gap: 8,
                        }}
                      >
                        <Typography
                          fontSize={12}
                          color={theme.palette.secondaryText.main}
                        >
                          ISIN
                        </Typography>
                        <Typography fontWeight={600} fontSize={14}>
                          {companyTransactionSummary?.data?.isin || "-"}{" "}
                        </Typography>
                      </div>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid2>
        )}
        <Grid2 size={12} display={"flex"} alignItems={"center"} gap={1}>
          <Typography
            sx={{
              display: "flex",
              alignItems: "center",
            }}
            color={theme.palette.secondaryText.main}
            fontSize={12}
          >
            <TbUserSquareRounded size={14} style={{ marginRight: 4 }} />
            Client Name:
            <span
              style={{
                color: "#202939",
                marginLeft: 4,
              }}
            >
              {companyTransactionSummary?.data?.clientName}
            </span>
          </Typography>
          <Typography
            sx={{
              display: "flex",
              alignItems: "center",
            }}
            color={theme.palette.secondaryText.main}
            fontSize={12}
          >
            <FaChartBar size={14} style={{ marginRight: 4 }} />
            Portfolio Name/Code:
            <span
              style={{
                color: "#202939",
                marginLeft: 4,
              }}
            >
              {companyTransactionSummary?.data?.portfolioName}/
              {companyTransactionSummary?.data?.portfolioCode}
            </span>
          </Typography>
        </Grid2>
        <Show.When isTrue={type !== "FD"}>
          <Grid2 size={6}>
            <TableContainer>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell
                      style={{
                        borderRight: `solid 1px ${theme.palette.grey[300]}`,
                        borderBottom: "none",
                        padding: 16,
                      }}
                    >
                      <Typography
                        fontSize={12}
                        color={theme.palette.secondaryText.main}
                      >
                        Holding Qty.
                      </Typography>
                      <Typography
                        fontWeight={600}
                        fontSize={14}
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        {companyTransactionSummary?.data?.rawQuantity}
                        <span
                          style={{
                            display: "flex",
                            fontSize: 12,
                            color: theme.palette.primary.main,
                            fontWeight: 400,
                          }}
                        >
                          &nbsp;
                          <img
                            loading="lazy"
                            src="/Logos/longTerm.svg"
                            alt=""
                          />
                          &nbsp;
                          {companyTransactionSummary?.data?.ltHoldingsCount}
                        </span>
                      </Typography>
                    </TableCell>

                    <TableCell
                      style={{
                        borderRight: `solid 1px ${theme.palette.grey[300]}`,
                        borderBottom: "none",
                        padding: 16,
                      }}
                    >
                      <Typography
                        fontSize={12}
                        color={theme.palette.secondaryText.main}
                      >
                        Avg. Buy Price
                      </Typography>
                      <Typography fontWeight={600} fontSize={14}>
                        {numberToCurrency(
                          companyTransactionSummary?.data?.avgBuyPrice,
                        )}
                      </Typography>
                    </TableCell>
                    <TableCell
                      style={{
                        borderBottom: "none",
                        padding: 16,
                      }}
                    >
                      <Typography
                        fontSize={12}
                        color={theme.palette.secondaryText.main}
                      >
                        Invested Value
                      </Typography>
                      <Typography fontWeight={600} fontSize={14}>
                        {numberToCurrency(
                          companyTransactionSummary?.data?.investedAmount,
                        )}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      style={{
                        borderRight: `solid 1px ${theme.palette.grey[300]}`,
                        borderTop: `solid 1px ${theme.palette.grey[300]}`,
                        borderBottom: "none",
                        padding: 16,
                      }}
                    >
                      <Typography
                        fontSize={12}
                        color={theme.palette.secondaryText.main}
                      >
                        Total Holding Value{" "}
                      </Typography>
                      <Typography fontWeight={600} fontSize={14}>
                        {numberToCurrency(
                          companyTransactionSummary?.data?.currentAmount,
                        )}
                      </Typography>
                    </TableCell>
                    <TableCell
                      style={{
                        borderRight: `solid 1px ${theme.palette.grey[300]}`,
                        borderTop: `solid 1px ${theme.palette.grey[300]}`,
                        borderBottom: "none",
                        padding: 16,
                      }}
                    >
                      <Typography
                        fontSize={12}
                        color={theme.palette.secondaryText.main}
                      >
                        Total Buy Amount
                      </Typography>
                      <Typography fontWeight={600} fontSize={14}>
                        {numberToCurrency(
                          companyTransactionSummary?.data?.totalBuyAmount,
                        )}
                      </Typography>
                    </TableCell>
                    <TableCell
                      style={{
                        borderTop: `solid 1px ${theme.palette.grey[300]}`,
                        borderBottom: "none",
                        padding: 16,
                      }}
                    >
                      <Typography
                        fontSize={12}
                        color={theme.palette.secondaryText.main}
                      >
                        Total Sell Amount
                      </Typography>
                      <Typography fontWeight={600} fontSize={14}>
                        {numberToCurrency(
                          companyTransactionSummary?.data?.totalSellAmount,
                        )}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid2>
          <Grid2 size={6}>
            <TableContainer>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell
                      style={{
                        borderRight: `solid 1px ${theme.palette.grey[300]}`,
                        borderBottom: "none",
                        padding: 16,
                        width: "50%",
                      }}
                    >
                      <Typography
                        fontSize={12}
                        color={theme.palette.secondaryText.main}
                      >
                        Total Gain{" "}
                      </Typography>
                      <Typography fontWeight={600} fontSize={14}>
                        {numberToCurrency(
                          companyTransactionSummary?.data?.totalGain,
                        )}
                      </Typography>
                    </TableCell>
                    <TableCell
                      style={{
                        borderBottom: "none",
                        padding: 16,
                        width: "50%",
                      }}
                    >
                      <Typography
                        fontSize={12}
                        color={theme.palette.secondaryText.main}
                      >
                        XIRR{" "}
                      </Typography>
                      <Typography fontWeight={600} fontSize={14}>
                        {formatPercentage(
                          companyTransactionSummary?.data?.xirr,
                        )}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      colSpan={3}
                      style={{
                        borderRight: `solid 1px ${theme.palette.grey[300]}`,
                        borderTop: `solid 1px ${theme.palette.grey[300]}`,
                        borderBottom: "none",
                        padding: 16,
                      }}
                    >
                      <Grid2 container spacing={1}>
                        <Grid2 size={2.4}>
                          <Typography
                            fontSize={12}
                            color={theme.palette.secondaryText.main}
                            display={"flex"}
                            alignItems={"center"}
                            gap={1}
                          >
                            Unrealised Gain
                          </Typography>
                          <Typography fontWeight={600} fontSize={14}>
                            {numberToCurrency(
                              companyTransactionSummary?.data?.unrealisedGain,
                            )}
                          </Typography>
                        </Grid2>
                        <Grid2
                          size={2.4}
                          display={"flex"}
                          justifyContent={"center"}
                          alignItems={"center"}
                        >
                          <span
                            style={{
                              background: "#F8FAFC",
                              borderRadius: 4,
                              height: 24,
                              width: 24,
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <GoPlus />
                          </span>
                        </Grid2>
                        <Grid2 size={2.4}>
                          <Typography
                            fontSize={12}
                            color={theme.palette.secondaryText.main}
                            display={"flex"}
                            alignItems={"center"}
                            gap={1}
                          >
                            Realised Gain{" "}
                          </Typography>
                          <Typography fontWeight={600} fontSize={14}>
                            {numberToCurrency(
                              companyTransactionSummary?.data?.realisedGain,
                            )}
                          </Typography>
                        </Grid2>
                        <Grid2
                          size={2.4}
                          display={"flex"}
                          justifyContent={"center"}
                          alignItems={"center"}
                        >
                          <span
                            style={{
                              background: "#F8FAFC",
                              borderRadius: 4,
                              height: 24,
                              width: 24,
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <GoPlus />
                          </span>
                        </Grid2>
                        <Grid2 size={2.4}>
                          <Typography
                            fontSize={12}
                            color={theme.palette.secondaryText.main}
                            display={"flex"}
                            alignItems={"center"}
                            gap={1}
                          >
                            {type === "FD" ? "Gains" : "Dividend"}
                          </Typography>
                          <Typography fontWeight={600} fontSize={14}>
                            {numberToCurrency(
                              type === "FD"
                                ? companyTransactionSummary?.data?.fdGains
                                : companyTransactionSummary?.data?.dividend,
                            )}
                          </Typography>
                        </Grid2>
                      </Grid2>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid2>
        </Show.When>
        <Show.When isTrue={type === "FD"}>
          <Grid2 size={12}>
            <TableContainer>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell
                      style={{
                        borderBottom: "none",
                        padding: 16,
                        borderRight: `solid 1px ${theme.palette.grey[300]}`,
                        width: "16%",
                      }}
                    >
                      <Typography
                        fontSize={12}
                        color={theme.palette.secondaryText.main}
                      >
                        Invested Value
                      </Typography>
                      <Typography fontWeight={600} fontSize={14}>
                        {numberToCurrency(
                          companyTransactionSummary?.data?.investedAmount,
                        )}
                      </Typography>
                    </TableCell>

                    <TableCell
                      style={{
                        borderRight: `solid 1px ${theme.palette.grey[300]}`,
                        borderBottom: "none",
                        padding: 16,
                        width: "16%",
                      }}
                    >
                      <Typography
                        fontSize={12}
                        color={theme.palette.secondaryText.main}
                      >
                        Current Value
                      </Typography>
                      <Typography fontWeight={600} fontSize={14}>
                        {numberToCurrency(
                          companyTransactionSummary?.data?.currentAmount,
                        )}
                      </Typography>
                    </TableCell>

                    <TableCell
                      style={{
                        borderBottom: "none",
                        padding: 16,
                        borderRight: `solid 1px ${theme.palette.grey[300]}`,
                        width: "16%",
                      }}
                    >
                      <Typography
                        fontSize={12}
                        color={theme.palette.secondaryText.main}
                      >
                        Unrealised Gain
                      </Typography>
                      <Typography fontWeight={600} fontSize={14}>
                        {numberToCurrency(
                          companyTransactionSummary?.data?.unrealisedGain,
                        )}
                      </Typography>
                    </TableCell>

                    <TableCell
                      style={{
                        borderRight: `solid 1px ${theme.palette.grey[300]}`,
                        borderBottom: "none",
                        padding: 16,
                        width: "16%",
                      }}
                    >
                      <Typography
                        fontSize={12}
                        color={theme.palette.secondaryText.main}
                      >
                        Total Payout
                      </Typography>
                      <Typography fontWeight={600} fontSize={14}>
                        {numberToCurrency(
                          companyTransactionSummary?.data?.fdGains,
                        )}
                      </Typography>
                    </TableCell>

                    <TableCell
                      style={{
                        borderRight: `solid 1px ${theme.palette.grey[300]}`,
                        borderBottom: "none",
                        padding: 16,
                        width: "16%",
                      }}
                    >
                      <Typography
                        fontSize={12}
                        color={theme.palette.secondaryText.main}
                      >
                        Total Gains
                      </Typography>
                      <Typography fontWeight={600} fontSize={14}>
                        {numberToCurrency(
                          companyTransactionSummary?.data?.totalGain,
                        )}
                      </Typography>
                    </TableCell>

                    <TableCell
                      style={{
                        borderBottom: "none",
                        padding: 16,
                        width: "16%",
                      }}
                    >
                      <Typography
                        fontSize={12}
                        color={theme.palette.secondaryText.main}
                      >
                        XIRR
                      </Typography>
                      <Typography fontWeight={600} fontSize={14}>
                        {numberToCurrency(
                          companyTransactionSummary?.data?.xirr,
                        )}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid2>
        </Show.When>

        <Grid2 size={12}>
          <Divider />
          <Tabs
            onChange={(e, val) => {
              setTransactionTabs(val);
            }}
            value={transactionTabs}
          >
            <Tab label="Trade Book" key="tradeBook" value={0} />
            {type !== "FD" && <Tab label="Inventory" key="inventory" />}
            {type !== "FD" && (
              <Tab
                label="Realised Inventory"
                key="realisedInventory"
                value={2}
              />
            )}
          </Tabs>
          <Divider />
        </Grid2>
        <Show.When isTrue={transactionTabs === 0}>
          <Grid2 size={12}>
            <PortfolioHoldingTradeBook
              data={data}
              isFetching={isFetching}
              portfolioId={state?.holdingId}
              createTransactionData={{
                gridCode: companyTransactionSummary?.data?.gcode,
                fdAccountNo: companyTransactionSummary?.data?.fdAccountNo,
                interestType: companyTransactionSummary?.data?.interestType,
              }}
            />
          </Grid2>
        </Show.When>
        <Show.When isTrue={transactionTabs === 1 && type !== "FD"}>
          <Grid2 size={12}>
            <PortfolioLongTermHolding portfolioId={state?.holdingId} />
          </Grid2>
        </Show.When>
        <Show.When isTrue={transactionTabs === 2 && type !== "FD"}>
          <Grid2 size={12}>
            <PortfolioRealisedInventory portfolioId={state?.holdingId} />
          </Grid2>
        </Show.When>
      </Grid2>
    </DashboardLayout>
  );
};

export default PortfolioHoldingTransactions;
