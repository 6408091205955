import { LoadingButton } from "@mui/lab";
import {
  Divider,
  GKDatePicker,
  GKSearchSelect,
  GKTextField,
  Grid2,
  PhoneInput,
  TextField,
  Typography,
} from "components";
import dayjs from "dayjs";
import { useMutation } from "hooks/useQueries";
import { useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import axiosInstance from "utils/axiosInstance";
import { MARITAL_STATUS, PERSONAL_GENDER } from "utils/constants";
import { handleReturnAutocompletePlaceholder } from "utils/StringFunctions";
import { emailRegex, panRegex } from "utils/validationSchema";
import BankDetails from "./BankDetails";
import DematDetails from "./DematDetails";
import { useInvestorContext } from "./InvestorContext";
import NomineeDetails from "./NomineeDetails";

interface Props {
  companyId: string;
}

const DetailsTab = (props: Props) => {
  const form = useFormContext();

  const {
    state: { countryList, stateList, cityList },
    dispatch,
  } = useInvestorContext();

  const [hasError, setHasError] = useState(false);

  const { mutate: handleGetKRAData, isLoading: KRALoading } = useMutation({
    mutationKey: ["GET_KRA_DATA"],
    mutationFn: (dataItem: any) => {
      return axiosInstance.post("/pan-details/", dataItem, {
        headers: { Authorization: null },
      });
    },
    onSuccess: (response) => {
      form.setValue(
        "clientCreation.personalDetails.name",
        response.data.data.name,
      );
      form.setValue("fetchedFromKra", true);
      form.setValue(
        "clientCreation.personalDetails.address",
        response.data.data.address,
      );
      form.setValue(
        "clientCreation.personalDetails.gender",
        response.data.data.gender,
      );
      form.setValue(
        "clientCreation.personalDetails.pincode",
        response.data.data.pincode,
      );
      form.setValue(
        "clientCreation.personalDetails.city",
        response.data.data.city,
      );
      form.setValue(
        "clientCreation.personalDetails.country",
        response.data.data.country,
      );
      form.setValue(
        "clientCreation.personalDetails.state",
        response.data.data.state,
      );
    },
  });

  return (
    <Grid2 container spacing={2}>
      <Grid2 size={12}>
        <Typography
          fontWeight={600}
          pt={2}
          sx={{ fontSize: "clamp(1.125rem, 0.6563rem + 1.5vw, 1.5rem)" }}
        >
          Complete Your Profile
        </Typography>
      </Grid2>
      <Grid2 size={{ md: 4, xs: 12 }}>
        <Controller
          name="clientCreation.personalDetails.pan"
          rules={{
            required: {
              value: true,
              message: "Please enter PAN",
            },
            pattern: {
              value: panRegex,
              message: "PAN should have this format ABCDE1234A",
            },
            maxLength: {
              value: 10,
              message: "PAN should have 10 character",
            },
            minLength: {
              value: 10,
              message: "PAN should have 10 character",
            },
          }}
          control={form.control}
          render={({ field, fieldState: { error } }) => (
            <GKTextField
              {...field}
              inputLabel="PAN"
              onChange={(e) => field.onChange(e.target.value.toUpperCase())}
              requiredField
              error={!!error}
              helperText={error?.message}
            />
          )}
        />
      </Grid2>
      <Grid2 size={{ md: 4, xs: 12 }}>
        <Controller
          name="clientCreation.personalDetails.dateOfBirth"
          control={form.control}
          rules={{
            required: {
              value: true,
              message: "Please select Date Of Birth",
            },
          }}
          render={({ field, fieldState: { error } }) => (
            <GKDatePicker
              {...field}
              inputLabel="Date Of Birth"
              value={dayjs(field.value)}
              onChange={(value) => {
                field.onChange(dayjs(value).format("YYYY-MM-DD"));
              }}
              minDate={dayjs("01-01-1901")}
              slotProps={{
                textField: {
                  error: !!error,
                  helperText: error?.message,
                },
              }}
            />
          )}
        />
      </Grid2>
      <Grid2 size={{ md: 4, xs: 12 }} mt={{ md: "20px", xs: 0 }}>
        <LoadingButton
          variant="outlined"
          color="dark"
          loading={KRALoading}
          onClick={() => {
            handleGetKRAData({
              phone: form.getValues("clientCreation.phone"),
              pan: form.getValues("clientCreation.personalDetails.pan"),
              dob: dayjs(
                form.getValues("clientCreation.personalDetails.dateOfBirth"),
              ).format("YYYY-MM-DD"),
              company: +props?.companyId,
            });
          }}
        >
          Fetch Details&nbsp;
        </LoadingButton>
      </Grid2>
      <Grid2 size={{ md: 4, xs: 12 }}>
        <Controller
          name="clientCreation.personalDetails.name"
          control={form.control}
          render={({ field, fieldState: { error } }) => (
            <GKTextField
              {...field}
              inputLabel="Name"
              requiredField
              error={!!error}
              helperText={error?.message}
            />
          )}
        />
      </Grid2>
      <Grid2 size={{ md: 4, xs: 12 }}>
        <Controller
          name="clientCreation.phone"
          rules={{
            required: {
              value: true,
              message: "Please enter phone number",
            },
          }}
          control={form.control}
          render={({ field, fieldState: { error } }) => (
            <PhoneInput
              {...field}
              requiredField
              onSelectChange={(event: any, val: any) => {
                form.setValue("clientCreation.countryCode", val?.callingCodes);
              }}
              onTextChange={(event: any) => {
                field.onChange(event.target.value);
              }}
              onTextValue={field.value}
              onSelectValue={form.getValues("clientCreation.countryCode")}
              error={!!error || hasError}
              helperText={error?.message}
              hasError={hasError}
              setHasError={setHasError}
            />
          )}
        />
      </Grid2>
      <Grid2 size={{ md: 4, xs: 12 }}>
        <Controller
          name="clientCreation.personalDetails.email"
          control={form.control}
          rules={{
            required: {
              value: true,
              message: "Please enter email",
            },
            pattern: {
              value: emailRegex,
              message: "Please enter a valid mail",
            },
          }}
          render={({ field, fieldState: { error } }) => (
            <GKTextField
              {...field}
              inputLabel="Email"
              requiredField
              error={!!error}
              helperText={error?.message}
            />
          )}
        />
      </Grid2>
      {/* <Grid2 size={{ md: 4, xs: 12 }}>
        <Controller
          name="family"
          control={form.control}
          render={({ field }) => (
            <GKSearchSelect
              disableClearable
              inputLabel="Family"
              options={familyList || []}
              onChange={(e, val) => {
                field.onChange(val?.value);
              }}
              getOptionLabel={(option) => option.name || option}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder={handleReturnAutocompletePlaceholder({
                    placeholder: "Select Family",
                    value: field.value,
                  })}
                />
              )}
            />
          )}
        />
      </Grid2>
      <Grid2 size={{ md: 4, xs: 12 }}>
        <Controller
          name="partner"
          control={form.control}
          render={({ field }) => (
            <GKSearchSelect
              disableClearable
              inputLabel="Partner"
              options={partnerList || []}
              onChange={(e, val) => {
                field.onChange(val?.value);
              }}
              getOptionLabel={(option) => option.name || option}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder={handleReturnAutocompletePlaceholder({
                    placeholder: "Select Partner",
                    value: field.value,
                  })}
                />
              )}
            />
          )}
        />
      </Grid2> */}
      <Grid2 size={12}>
        <Typography
          fontWeight={600}
          py={2}
          sx={{ fontSize: "clamp(0.875rem, 0.5625rem + 1vw, 1.125rem)" }}
        >
          Personal Details
        </Typography>
      </Grid2>
      <Grid2 size={{ md: 4, xs: 12 }}>
        <Controller
          name="clientCreation.personalDetails.gender"
          control={form.control}
          render={({ field }) => {
            return (
              <GKSearchSelect
                {...field}
                disableClearable={false}
                inputLabel="Gender"
                options={PERSONAL_GENDER || []}
                onChange={(event, val) => {
                  field.onChange(val?.value);
                }}
                getOptionLabel={(option) => option.name || option}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder={handleReturnAutocompletePlaceholder({
                      placeholder: "Select Gender",
                      value: field.value,
                    })}
                  />
                )}
              />
            );
          }}
        />
      </Grid2>
      <Grid2 size={{ md: 4, xs: 12 }}>
        <Controller
          name="clientCreation.personalDetails.maritalStatus"
          control={form.control}
          render={({ field }) => (
            <GKSearchSelect
              {...field}
              disableClearable={false}
              inputLabel="Marital Status"
              options={MARITAL_STATUS || []}
              onChange={(event, val) => {
                field.onChange(val?.value);
              }}
              getOptionLabel={(option) => option.name || option}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder={handleReturnAutocompletePlaceholder({
                    placeholder: "Select Marital Status",
                    value: field.value,
                  })}
                />
              )}
            />
          )}
        />
      </Grid2>
      <Grid2 size={{ md: 4, xs: 12 }}>
        <Controller
          name="clientCreation.personalDetails.income"
          control={form.control}
          render={({ field }) => (
            <GKTextField {...field} type="number" inputLabel="Income" />
          )}
        />
      </Grid2>
      <Grid2 size={{ md: 4, xs: 12 }}>
        <Controller
          name="clientCreation.personalDetails.fatherName"
          control={form.control}
          render={({ field }) => (
            <GKTextField {...field} inputLabel="Father Name" />
          )}
        />
      </Grid2>
      <Grid2 size={{ md: 4, xs: 12 }}>
        <Controller
          name="clientCreation.personalDetails.motherName"
          control={form.control}
          render={({ field }) => (
            <GKTextField {...field} inputLabel="Mother Name" />
          )}
        />
      </Grid2>
      <Grid2 size={12}>
        <Controller
          name="clientCreation.personalDetails.address"
          control={form.control}
          // rules={{
          //   required: {
          //     value: true,
          //     message: "Address is required",
          //   },
          //   validate: validateNotWhitespaceOnly,
          // }}
          render={({ field }) => (
            <GKTextField
              {...field}
              inputLabel="Address"
              // requiredField
              // error={!!error}
              // helperText={error?.message}
            />
          )}
        />
      </Grid2>
      <Grid2 size={{ md: 3, xs: 12 }}>
        <Controller
          name="clientCreation.personalDetails.country"
          control={form.control}
          render={({ field, fieldState: { error } }) => (
            <GKSearchSelect
              {...field}
              inputLabel="Country"
              value={
                countryList.find(
                  (dataItem: { name: string }) =>
                    dataItem.name === field?.value,
                ) || ""
              }
              options={countryList || []}
              getOptionLabel={(e) => e.name || e}
              onChange={(e, val) => {
                field.onChange(val.name);
                form.setValue("city", "");
                form.setValue("state", "");
                dispatch({
                  type: "SELECTED_COUNTRY",
                  data: val.id,
                });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder={handleReturnAutocompletePlaceholder({
                    placeholder: "Select Country",
                    value: field.value,
                  })}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
          )}
        />
      </Grid2>
      <Grid2 size={{ md: 3, xs: 12 }}>
        <Controller
          name="clientCreation.personalDetails.state"
          control={form.control}
          render={({ field, fieldState: { error } }) => (
            <GKSearchSelect
              {...field}
              inputLabel="State"
              value={
                stateList.find(
                  (dataItem: { name: string }) =>
                    dataItem.name === field?.value,
                ) || ""
              }
              options={stateList || []}
              getOptionLabel={(e) => e.name || e}
              onChange={(e, val) => {
                field.onChange(val.name);
                form.setValue("city", "");
                dispatch({
                  type: "SELECTED_STATE",
                  data: val.id,
                });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder={handleReturnAutocompletePlaceholder({
                    placeholder: "Select State",
                    value: field.value,
                  })}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
          )}
        />
      </Grid2>
      <Grid2 size={{ md: 3, xs: 12 }}>
        <Controller
          name="clientCreation.personalDetails.city"
          control={form.control}
          render={({ field, fieldState: { error } }) => (
            <GKSearchSelect
              {...field}
              inputLabel="City"
              renderInput={(er) => (
                <TextField
                  {...er}
                  placeholder={handleReturnAutocompletePlaceholder({
                    placeholder: "Select City",
                    value: field.value,
                  })}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
              value={
                cityList.find(
                  (dataItem: { name: string }) =>
                    dataItem.name === field?.value,
                ) || ""
              }
              options={cityList || []}
              getOptionLabel={(e) => e.name || e}
              onChange={(e, val) => {
                field.onChange(val.name);
                dispatch({
                  type: "SELECTED_CITY",
                  data: val.id,
                });
              }}
            />
          )}
        />
      </Grid2>
      <Grid2 size={{ md: 3, xs: 12 }}>
        <Controller
          name="clientCreation.personalDetails.pincode"
          control={form.control}
          render={({ field, fieldState: { error } }) => (
            <GKTextField
              {...field}
              type="number"
              inputLabel="Pincode"
              error={!!error}
              helperText={error?.message}
            />
          )}
        />
      </Grid2>
      <Grid2 size={12} py={2}>
        <Divider />
      </Grid2>
      <Grid2 size={12}>
        <Typography
          fontWeight={600}
          sx={{ fontSize: "clamp(0.875rem, 0.5625rem + 1vw, 1.125rem)" }}
        >
          Bank Details
        </Typography>
      </Grid2>
      <Grid2 size={12}>
        <BankDetails />
      </Grid2>
      <Grid2 size={12} py={2}>
        <Divider />
      </Grid2>
      <Grid2 size={12}>
        <Typography
          fontWeight={600}
          sx={{ fontSize: "clamp(0.875rem, 0.5625rem + 1vw, 1.125rem)" }}
        >
          Demat Details
        </Typography>
      </Grid2>
      <Grid2 size={12}>
        <DematDetails />
      </Grid2>
      <Grid2 size={12} py={2}>
        <Divider />
      </Grid2>
      <Grid2 size={12}>
        <Typography
          fontWeight={600}
          sx={{ fontSize: "clamp(0.875rem, 0.5625rem + 1vw, 1.125rem)" }}
        >
          Nominee Details
        </Typography>
      </Grid2>
      <Grid2 size={12}>
        <NomineeDetails />
      </Grid2>
      <Grid2 size={12} py={2}>
        <Divider />
      </Grid2>
    </Grid2>
  );
};

export default DetailsTab;
