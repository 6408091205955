import { grey } from "@mui/material/colors";
import { Box, Grid2, Typography } from "components";
import { useMutation } from "hooks/useQueries";
import { AdvisorOnboardFormInterface } from "interfaces/OnboardingInterface";
import { useFormContext } from "react-hook-form";
import { numberToCurrency } from "utils";
import axiosInstance from "utils/axiosInstance";
import { handleCalculateFee, refetchQuery } from "utils/helpers";
import { QueryKeys } from "utils/queryKey";
import { ThemeContainer } from "utils/Theme";

const AdvisorOnboardingPayment = () => {
  const form = useFormContext<AdvisorOnboardFormInterface>();

  const { theme } = ThemeContainer();

  // ================================================================================================================= clientData={addedClientData}====
  const { mutate: handleSendPaymentLink } = useMutation({
    mutationKey: ["HANDLE_SEND_PAYMENT_LINK"],
    mutationFn: (values: any) =>
      axiosInstance.post(`/client-onboarding-payment-history/`, values),
    onSuccess: () => {
      refetchQuery(QueryKeys.GET_COMPLIANCE_BY_CLIENT_ID);
      refetchQuery(QueryKeys.GET_CLIENT_BY_ID);
      form.setValue("activeStep", form.getValues("activeStep") + 1);
    },
  });

  return (
    <form
      onSubmit={form.handleSubmit(() => {
        handleSendPaymentLink({
          isSendEmail: true,
          client: form.getValues("id"),
          basket: form.getValues("basketObject.id"),
          feePlan: form.getValues("selectedFeePlan.id"),
          portfolio: form.getValues("portfolioObject.id"),
          frequency: form.getValues("advanceFeePlan.frequency"),
          advanceFeePlan: null,
          flowType: "advisor_initiated_advisory_flow",
        });
      })}
      id="advisor-onboarding-payment-form"
    >
      <Grid2
        spacing={2}
        container
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        sx={{
          width: {
            xs: "55%",
          },
        }}
        m={"auto"}
      >
        <Grid2 size={12}>
          <Typography fontWeight={600} fontSize={16}>
            Payment
          </Typography>
        </Grid2>
        <Grid2 size={12}>
          <Box
            sx={{
              p: 2,
              background: "#F8FAFC",
              borderRadius: 2,
            }}
          >
            <Grid2 container>
              <Grid2 size={12} mb={2}>
                <Typography fontWeight={500} color={grey[400]} fontSize={12}>
                  Payment Amount
                </Typography>
                <Box display={"flex"} alignItems={"center"} gap={0.5}>
                  <Typography fontWeight={600} fontSize={20}>
                    {numberToCurrency(
                      form.getValues("skipAdvanceFee")
                        ? 0
                        : handleCalculateFee({
                            feePercent: Number(
                              form.getValues(
                                "advanceFeePlan.feePlan.managementFee.fee",
                              ),
                            ),
                            frequency: form.getValues(
                              "advanceFeePlan.frequency",
                            ),
                            total:
                              Number(
                                form.getValues("planDataObject.holdingsValue"),
                              ) +
                              Number(form.getValues("planDataObject.aumCash")),
                          }) * 1.18 || 0,
                    )}
                  </Typography>
                  <Typography fontSize={12} color="#94A3B8">
                    (inclusive of GST)
                  </Typography>
                </Box>
              </Grid2>
              <Grid2
                size={12}
                bgcolor={theme.palette.light.main}
                display={"grid"}
                gridTemplateColumns={"auto auto"}
                p={2}
              >
                <Box>
                  <Typography
                    color={theme.palette.secondaryText.main}
                    fontSize={12}
                  >
                    Basket
                  </Typography>
                  <Typography fontWeight={600}>
                    {form.getValues("basketObject.name") || "-"}
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    color={theme.palette.secondaryText.main}
                    fontSize={12}
                  >
                    Renews every
                  </Typography>
                  <Typography fontWeight={600}>
                    {form.getValues("advanceFeePlan.frequency")}
                  </Typography>
                </Box>
              </Grid2>
            </Grid2>
          </Box>
        </Grid2>
      </Grid2>
    </form>
  );
};

export default AdvisorOnboardingPayment;
