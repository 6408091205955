import { Box, Divider, DragDrop, Grid2, Show, Typography } from "components";
import { useState } from "react";
import { Controller, useFormContext } from "react-hook-form";

const UploadPlanProofs = () => {
  const form = useFormContext();

  const [uploadError, setUploadError] = useState(null);

  const aumBasedPlanWatch = form.watch("planDataObject.aumBasedPlan");

  return (
    <Grid2 container spacing={2}>
      <Grid2 size={12}>
        <Typography
          fontWeight={600}
          pt={2}
          sx={{ fontSize: "clamp(1.125rem, 0.6563rem + 1.5vw, 1.5rem)" }}
        >
          Verify your investments for compliance purposes
        </Typography>
      </Grid2>
      <Show.When
        isTrue={
          aumBasedPlanWatch === "withHoldings" || aumBasedPlanWatch === "both"
        }
      >
        <Grid2
          size={{
            md: aumBasedPlanWatch === "withHoldings" ? 12 : 5.8,
            xs: 12,
          }}
          display={"flex"}
          flexDirection={"column"}
          gap={1}
        >
          <Typography fontSize={14}>
            Upload proof of your holding value (Demat holding Statement)
          </Typography>
          <Controller
            control={form.control}
            name="planDataObject.cashProofs"
            render={({ field }) => {
              return (
                <Box sx={{ height: "250px", overflow: "auto" }}>
                  <DragDrop
                    {...field}
                    supportName=".xlsx, .pdf"
                    acceptType={".xlsx,.pdf"}
                    multiple
                    onChange={(e) => field.onChange(e)}
                    setUploadError={setUploadError}
                    uploadError={uploadError}
                  />
                </Box>
              );
            }}
          />
        </Grid2>
      </Show.When>

      <Grid2 size={0.4} display={"flex"} justifyContent={"center"}>
        <Divider orientation="vertical" />
      </Grid2>

      <Show.When
        isTrue={
          aumBasedPlanWatch === "withCash" || aumBasedPlanWatch === "both"
        }
      >
        <Grid2
          size={{
            md: aumBasedPlanWatch === "withCash" ? 12 : 5.8,
            xs: 12,
          }}
          gap={1}
          display={"flex"}
          flexDirection={"column"}
        >
          <Typography fontSize={14}>
            Upload proof of your bank balance (Latest bank statement)
          </Typography>
          <Controller
            control={form.control}
            name="planDataObject.holdingsProofs"
            render={({ field }) => {
              return (
                <Box sx={{ height: "250px", overflow: "auto" }}>
                  <DragDrop
                    {...field}
                    supportName=".xlsx, .pdf"
                    acceptType={".xlsx,.pdf"}
                    multiple
                    onChange={(e) => field.onChange(e)}
                    setUploadError={setUploadError}
                    uploadError={uploadError}
                  />
                </Box>
              );
            }}
          />
        </Grid2>
      </Show.When>
    </Grid2>
  );
};

export default UploadPlanProofs;
