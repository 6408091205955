import { LoadingButton } from "@mui/lab";
import { GridColDef } from "@mui/x-data-grid-pro";
import {
  Checkbox,
  GKClientDataGrid,
  GKSideModal,
  Grid2,
  Typography,
} from "components";
import { useFetchQuery, useMutation } from "hooks/useQueries";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { GET_ALL_LEAD } from "utils/apiRoutes";
import axiosInstance from "utils/axiosInstance";
import { extraColumnsField } from "utils/helpers";

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  addMaterialData: any;
}

type FormValues = {
  lead_id: any[];
};

const MarketingMaterialSendNotificationModal = (props: Props) => {
  const { open, setOpen, addMaterialData } = props;

  const { data, isFetching } = useFetchQuery({
    key: ["FETCH_ALL_LEAD"],
    route: GET_ALL_LEAD,
  });

  const form = useForm<FormValues>({
    defaultValues: {
      lead_id: [],
    },
  });

  const { mutate: handleWhatsAppLeadSend, isLoading } = useMutation({
    mutationKey: ["WHATSAPP_LEAD_SEND"],
    mutationFn: (data: any) =>
      axiosInstance.post(`/send_marketing_material/?role=advisor`, data),
    onSuccess: (response) => {
      setOpen(false);
      toast.success(response?.data?.message || "Lead sent successfully.");
    },
  });

  const onSubmit = (data: FormValues) => {
    handleWhatsAppLeadSend({
      ...data,
      marketing_material_id: addMaterialData.id,
    });
  };

  const columns: GridColDef[] = [
    {
      ...extraColumnsField("Lead Name", "portfolio", 200, 1),
      renderCell: (params) => {
        return (
          <Grid2
            container
            display={"flex"}
            flexDirection={"column"}
            alignItems={"flex-start"}
          >
            <Typography fontWeight={700}>{params?.row?.name}</Typography>
          </Grid2>
        );
      },
    },
    {
      ...extraColumnsField("Phone", "phoneNumber", 100, 1),
      valueGetter: (params) => params.row.phoneNumber || "-",
    },
    {
      ...extraColumnsField("Whatsapp", "whatsapp", 100, 1),
      renderCell: (params) => {
        return (
          <Controller
            name="lead_id"
            control={form.control}
            render={({ field: { onChange, value } }) => (
              <Checkbox
                disabled={!params.row.phoneNumber}
                indeterminate={!params.row.phoneNumber}
                checked={value.includes(params.row.id)}
                onChange={(e) =>
                  onChange(
                    e.target.checked
                      ? [...value, params.row.id]
                      : value.filter((id: string) => id !== params.row.id),
                  )
                }
              />
            )}
          />
        );
      },
    },
  ];

  return (
    <GKSideModal
      width="40%"
      open={open}
      setOpen={setOpen}
      onClose={() => {
        setOpen(false);
      }}
      modalTitle="Send Notification"
      footerButton={
        <Grid2 container justifyContent={"flex-end"}>
          <LoadingButton
            variant="contained"
            type="submit"
            disabled={isLoading && !form.formState.isDirty}
            form="whatsapp-form"
          >
            Send
          </LoadingButton>
        </Grid2>
      }
    >
      <form id="whatsapp-form" onSubmit={form.handleSubmit(onSubmit)}>
        <GKClientDataGrid
          name="sendMarketingMaterial"
          loading={isFetching}
          showHeaderGrid={false}
          columns={columns}
          rows={data?.data?.data}
          maxHeight={`calc(100vh - 305px)`}
        />
      </form>
    </GKSideModal>
  );
};

export default MarketingMaterialSendNotificationModal;
