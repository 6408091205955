import { LoadingButton } from "@mui/lab";
import GKModal from "components/GKModal";
import { useMutation } from "hooks/useQueries";
import axiosInstance from "utils/axiosInstance";
import { GKModalProps } from "utils/commonInterface";
import { refetchQuery } from "utils/helpers";
import CompanyDetailsForm from "./CompanyDetailsForm";

interface Props {
  type: string;
  refetchKey: string;
}

interface FormValues {
  name: string;
  shortName: string;
  instType: string;
  cap: string;
  sector: string;
  industry: string;
  house: string;
  status: string;
  isin: string;
  instSubType: string;
  startingDate: string;
  cmp: string;
}

const CompanyModal = (props: Props & GKModalProps) => {
  const { open, setOpen, type, refetchKey } = props;

  const { mutate: handleCreateAssets, isLoading } = useMutation({
    mutationKey: ["CREATE_PRIVATE_EQUITY"],
    mutationFn: (data: FormValues) => axiosInstance.post("/advisor-cm/", data),
    onSuccess: () => {
      refetchQuery(refetchKey);
      setOpen(false);
    },
  });

  return (
    <GKModal
      open={open}
      setOpen={setOpen}
      modalTitle="Add New Company"
      footer={
        <LoadingButton
          type="submit"
          variant="contained"
          size="medium"
          form="company-form"
          loading={isLoading}
        >
          Save
        </LoadingButton>
      }
    >
      <CompanyDetailsForm
        type={type}
        onSave={handleCreateAssets}
        isEdit={false}
      />
    </GKModal>
  );
};

export default CompanyModal;
