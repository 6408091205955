/* eslint-disable array-callback-return */
import { LoadingButton } from "@mui/lab";
import { useAppContext } from "AppContext";
import {
  Button,
  Divider,
  DragDrop,
  GKClientDataGrid,
  GKSearchSelect,
  GKTextArea,
  GKTextField,
  Grid2,
  MenuItem,
  StatusField,
  Switch,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "components";
import GKNoDataFound from "components/GKNoDataFound";
import GKSelect from "components/GKSelect";
import dayjs from "dayjs";
import { useFetchQuery, useMutation } from "hooks/useQueries";
import DashboardLayout from "layouts/DashboardLayout";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { handleRemoveUnderscore } from "utils";
import axiosInstance from "utils/axiosInstance";
import { handleCapitalize } from "utils/common";
import { handleCreateFormData } from "utils/formHelpers";
import { extraColumnsField, refetchQuery } from "utils/helpers";
import { handleReturnAutocompletePlaceholder } from "utils/StringFunctions";
import { ThemeContainer } from "utils/Theme";
import useDocumentTitle from "utils/useDocumentTitle";
import AssetHistoryDetailsModal from "./AssetHistoryDetailsModal";
import AssetsStatusModal from "./AssetsStatusModal";
import {
  riskDropdown,
  timePerdioDropdown,
  volatilityDropdown,
} from "./equityBasketHelper";

const AddPortfolioDetailsModal = () => {
  useDocumentTitle("Basket Asset Details");

  const {
    dispatch,
    state: { tab, userData },
  } = useAppContext();

  const { theme } = ThemeContainer();

  const [uploadError, setUploadError] = useState<string | null>(null);

  const [isStatusModalOpen, setIsStatusModalOpen] = useState(false);

  const [showAssetModal, setShowAssetModal] = useState(false);

  const [showAssetDetails, setShowAssetDetails] = useState(null);

  const { id, assetId } = useParams();

  const form = useForm({
    defaultValues: {
      stockName: "",
      recommendedBuyPrice: "",
      rationale: "",
      status: "",
      riskProfile: "",
      published: false,
      timePeriod: "",
      volatility: "",
      targetPrice: "",
      limitPrice: "",
      deviation: "",
      stopLoss: "",
      research_files: [],
    },
  });

  /** ========================================================================================================= */
  const { data: basketDetailsData } = useFetchQuery({
    key: ["GET_MODEL_PORTFOLIO_DETAILS_DATA"],
    route: `/model_portfolio/${id}/details/`,
    enabled: Boolean(id),
  });

  const {
    data: addDetailsData,
    isFetching,
    isRefetching,
  } = useFetchQuery({
    key: ["GET_STOCK_DETAILS", id, assetId],
    route: `/model_portfolio/${id}/stock_item/${assetId}/`,
    success: (res: any) => {
      form.reset({
        ...res?.data,
        research_files: [],
      });
    },
  });

  const { mutate: handleSubmit, isLoading: submitLoading } = useMutation({
    mutationKey: ["ADD_PORTFOLIO_DETAILS"],
    mutationFn: (data: any) =>
      axiosInstance.patch(
        `/model_portfolio/${id}/stock_item/${addDetailsData?.data?.id}/`,
        data,
      ),
    onSuccess: () => {
      refetchQuery("GET_STOCK_DETAILS");
    },
  });

  const { mutate: handleDeleteResearchFiles, isLoading: researchFilesLoading } =
    useMutation({
      mutationKey: ["DELETE_RESEARCH_FILES"],
      mutationFn: (data: any) =>
        axiosInstance.delete(
          `/model_portfolio/${id}/stock_item/${addDetailsData?.data?.id}/delete_research_file/?role=advisor&file_id=${data}`,
        ),
      onSuccess: () => {
        refetchQuery("GET_STOCK_DETAILS");
      },
    });

  const {
    mutate: handlePublishResearchFiles,
    isLoading: researchUpdateFilesLoading,
  } = useMutation({
    mutationKey: ["PUBLISH_RESEARCH_FILES"],
    mutationFn: (data: any) =>
      axiosInstance.patch(
        `/model_portfolio/${id}/stock_item/${addDetailsData?.data?.id}/update_research_status/?role=advisor&file_id=${data}`,
      ),
    onSuccess: () => {
      refetchQuery("GET_STOCK_DETAILS");
    },
  });

  const handleFormSubmit = (values: any) => {
    handleSubmit(
      handleCreateFormData({
        values: values,
        dirtyFields: form.formState.dirtyFields,
      }),
    );
  };

  return (
    <>
      <DashboardLayout
        title="Assets Details"
        loading={isFetching && !isRefetching}
      >
        <Grid2 container spacing={1}>
          <Grid2 size={12}>
            {addDetailsData?.data?.id !== "" && (
              <form
                onSubmit={form.handleSubmit(handleFormSubmit)}
                id="stock-details-form"
              >
                <Grid2 container spacing={1}>
                  <Grid2
                    size={12}
                    display={"flex"}
                    alignItems={"center"}
                    gap={1}
                  >
                    <Typography fontWeight={600} fontSize={24}>
                      {addDetailsData?.data?.companyName}
                    </Typography>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: 8,
                        marginLeft: "auto",
                      }}
                    >
                      <Button
                        variant="outlined"
                        size="small"
                        onClick={() => {
                          setIsStatusModalOpen(true);
                        }}
                      >
                        Set Portfolio Status
                      </Button>
                      {userData?.broker?.isRaAccess &&
                        basketDetailsData?.data?.basketType !== "Model" && (
                          <Button
                            variant="outlined"
                            size="small"
                            color={
                              form.getValues("published") ? "error" : "success"
                            }
                            onClick={() => {
                              form.setValue(
                                "published",
                                !form.getValues("published"),
                              );
                              setTimeout(() => {
                                handleSubmit(
                                  handleCreateFormData({
                                    values: form.getValues(),
                                    dirtyFields: form.formState.dirtyFields,
                                  }),
                                );
                              }, 10);
                            }}
                          >
                            {form.getValues("published")
                              ? "Unpublish Asset"
                              : "Publish Asset"}
                          </Button>
                        )}
                    </div>
                  </Grid2>
                  <Grid2 size={12}>
                    <Tabs
                      onChange={(e, val) => {
                        dispatch({
                          type: "TAB",
                          data: { ...tab, assetDetailsTabs: val },
                        });
                      }}
                      value={tab?.assetDetailsTabs}
                    >
                      <Tab label="Details" />
                      <Tab label="Assets History" />
                      <Tab label="Research Files" />
                      <Tab label="Linked Model Portfolio" />
                    </Tabs>
                    <Divider />
                  </Grid2>
                  {tab?.assetDetailsTabs === 0 && (
                    <Grid2 size={2.4}>
                      <Controller
                        name="status"
                        control={form.control}
                        render={({ field }) => {
                          return (
                            <GKSelect {...field} inputLabel="Status">
                              <MenuItem style={{ display: "none" }} value="">
                                Select Status
                              </MenuItem>
                              <MenuItem value={"BUY"}>Buy</MenuItem>
                              <MenuItem value={"HOLD"}>Hold</MenuItem>
                              <MenuItem value={"EXIT"}>Exit</MenuItem>
                            </GKSelect>
                          );
                        }}
                      />
                    </Grid2>
                  )}
                  {tab?.assetDetailsTabs === 0 && (
                    <Grid2 size={2.4}>
                      <Controller
                        name="targetPrice"
                        control={form.control}
                        render={({ field }) => {
                          return (
                            <GKTextField
                              {...field}
                              type="number"
                              inputLabel="Latest Target"
                              placeholder="Latest Target"
                            />
                          );
                        }}
                      />
                    </Grid2>
                  )}
                  {tab?.assetDetailsTabs === 0 && (
                    <Grid2 size={2.4}>
                      <Controller
                        name="stopLoss"
                        control={form.control}
                        render={({ field }) => {
                          return (
                            <GKTextField
                              {...field}
                              type="number"
                              inputLabel="Stop Loss"
                              placeholder="Stop Loss"
                            />
                          );
                        }}
                      />
                    </Grid2>
                  )}
                  {tab?.assetDetailsTabs === 0 && (
                    <Grid2 size={2.4}>
                      <Controller
                        name="limitPrice"
                        control={form.control}
                        render={({ field }) => {
                          return (
                            <GKTextField
                              {...field}
                              type="number"
                              inputLabel="Limit Price"
                              placeholder="Limit Price"
                            />
                          );
                        }}
                      />
                    </Grid2>
                  )}
                  {tab?.assetDetailsTabs === 0 && (
                    <Grid2 size={2.4}>
                      <Controller
                        name="recommendedBuyPrice"
                        control={form.control}
                        render={({ field }) => {
                          return (
                            <GKTextField
                              {...field}
                              type="number"
                              inputLabel="Recommended Buy Price"
                              placeholder="Recommended Buy Price"
                            />
                          );
                        }}
                      />
                    </Grid2>
                  )}
                  {tab?.assetDetailsTabs === 0 && (
                    <Grid2 size={3}>
                      <Controller
                        name="deviation"
                        control={form.control}
                        render={({ field }) => {
                          return (
                            <GKTextField
                              {...field}
                              type="number"
                              inputLabel="Deviation %"
                              placeholder="Deviation %"
                            />
                          );
                        }}
                      />
                    </Grid2>
                  )}
                  {tab?.assetDetailsTabs === 0 && (
                    <Grid2 size={3}>
                      <Controller
                        name="riskProfile"
                        control={form.control}
                        render={({ field }) => {
                          return (
                            <GKSearchSelect
                              multiple
                              {...field}
                              disableClearable={false}
                              onChange={(e, val) => {
                                field.onChange(val);
                              }}
                              value={field.value}
                              options={riskDropdown}
                              inputLabel="Risk Profile"
                              renderInput={(params) => {
                                return (
                                  <TextField
                                    {...params}
                                    placeholder={handleReturnAutocompletePlaceholder(
                                      {
                                        placeholder: "Select Risk Profile",
                                        value: field.value,
                                      },
                                    )}
                                  />
                                );
                              }}
                            />
                          );
                        }}
                      />
                    </Grid2>
                  )}
                  {tab?.assetDetailsTabs === 0 && (
                    <Grid2 size={3}>
                      <Controller
                        name="volatility"
                        control={form.control}
                        render={({ field }) => {
                          return (
                            <GKSearchSelect
                              {...field}
                              onChange={(e, val) => {
                                field.onChange(val?.value);
                              }}
                              disableClearable={false}
                              value={
                                volatilityDropdown?.find(
                                  (da) => da?.value === field.value,
                                )?.name
                              }
                              options={volatilityDropdown}
                              getOptionLabel={(opt) => opt?.name || opt}
                              inputLabel="Volatility"
                              renderInput={(params) => {
                                return (
                                  <TextField
                                    {...params}
                                    placeholder={handleReturnAutocompletePlaceholder(
                                      {
                                        placeholder: "Select Volatility",
                                        value: field.value,
                                      },
                                    )}
                                  />
                                );
                              }}
                            />
                          );
                        }}
                      />
                    </Grid2>
                  )}
                  {tab?.assetDetailsTabs === 0 && (
                    <Grid2 size={3}>
                      <Controller
                        name="timePeriod"
                        control={form.control}
                        render={({ field }) => {
                          return (
                            <GKSearchSelect
                              {...field}
                              onChange={(e, val) => {
                                field.onChange(val?.value);
                              }}
                              disableClearable={false}
                              value={
                                timePerdioDropdown?.find(
                                  (da) => da?.value === field.value,
                                )?.name
                              }
                              options={timePerdioDropdown}
                              getOptionLabel={(opt) => opt?.name || opt}
                              inputLabel="Time Horizon"
                              renderInput={(params) => {
                                return (
                                  <TextField
                                    {...params}
                                    placeholder={handleReturnAutocompletePlaceholder(
                                      {
                                        placeholder: "Select Time Horizon",
                                        value: field.value,
                                      },
                                    )}
                                  />
                                );
                              }}
                            />
                          );
                        }}
                      />
                    </Grid2>
                  )}
                  {tab?.assetDetailsTabs === 0 && (
                    <Grid2 size={12}>
                      <Controller
                        name="rationale"
                        control={form.control}
                        render={({ field }) => {
                          return (
                            <GKTextArea
                              {...field}
                              minRows={3}
                              inputLabel="Rationale"
                              placeholder="Rationale"
                            />
                          );
                        }}
                      />
                    </Grid2>
                  )}
                  {tab?.assetDetailsTabs === 2 && (
                    <Grid2 size={12}>
                      <Typography>Research Files</Typography>
                      <Controller
                        name="research_files"
                        control={form.control}
                        render={({ field }) => {
                          return (
                            <DragDrop
                              multiple
                              uploadError={uploadError}
                              setUploadError={setUploadError}
                              acceptType=".pdf, .csv, .docx"
                              supportName="PDF, CSV & DOCX"
                              value={field.value}
                              onChange={(e) => {
                                field.onChange(e);
                                e.map((file) => {
                                  setTimeout(() => {
                                    const data = new FormData();
                                    data.append("research_files", file);
                                    handleSubmit(data);
                                  }, 200);
                                });
                              }}
                            />
                          );
                        }}
                      />
                    </Grid2>
                  )}
                </Grid2>
              </form>
            )}
          </Grid2>
          {tab?.assetDetailsTabs === 1 &&
            addDetailsData?.data?.stockUniverseItemHistory?.length !== 0 && (
              <Grid2 size={12}>
                <GKClientDataGrid
                  name="assetHistory"
                  density="compact"
                  columnsButton={false}
                  pagination={false}
                  rows={addDetailsData?.data?.stockUniverseItemHistory || []}
                  onRowClick={(params) => {
                    setShowAssetDetails({
                      ...params.row,
                      name: addDetailsData?.data?.companyName,
                    });
                    setShowAssetModal(true);
                  }}
                  columns={[
                    {
                      ...extraColumnsField("Date", "date", 1, 1),
                      valueFormatter: (params) => params.value || "-",
                    },
                    {
                      ...extraColumnsField("Status", "status", 1, 1),
                      valueFormatter: (params) => params.value || "-",
                      renderCell: (params) => {
                        return (
                          <StatusField
                            statusString={params.row.status || "N/A"}
                          />
                        );
                      },
                    },
                    {
                      ...extraColumnsField("Target Price", "targetPrice", 1, 1),
                      valueFormatter: (params) => params.value || "-",
                      type: "number",
                    },
                    {
                      ...extraColumnsField("Stop Loss", "stopLoss", 1, 1),
                      valueFormatter: (params) => params.value || "-",
                      type: "number",
                    },
                    {
                      ...extraColumnsField("Deviation", "deviation", 1, 1),
                      valueFormatter: (params) => params.value || "-",
                      type: "number",
                    },

                    {
                      ...extraColumnsField("Volatility", "volatility", 1, 1),
                      valueFormatter: (params) =>
                        handleCapitalize(handleRemoveUnderscore(params?.value)),
                    },
                    {
                      ...extraColumnsField("Time Horizon", "timePeriod", 1, 1),
                      valueFormatter: (params) =>
                        handleCapitalize(handleRemoveUnderscore(params?.value)),
                    },

                    {
                      ...extraColumnsField("Rationale", "rationale", 1, 1),
                      valueFormatter: (params) => params.value || "-",
                    },
                  ]}
                />
              </Grid2>
            )}
          {tab?.assetDetailsTabs === 1 &&
            addDetailsData?.data?.stockUniverseItemHistory?.length === 0 && (
              <Grid2 size={12}>
                <GKNoDataFound />
              </Grid2>
            )}
          {tab?.assetDetailsTabs === 2 &&
            addDetailsData?.data?.researchFiles?.length !== 0 && (
              <Grid2 size={12}>
                <GKClientDataGrid
                  name="assetResearchFiles"
                  density="compact"
                  columnsButton={false}
                  pagination={false}
                  rows={addDetailsData?.data?.researchFiles || []}
                  columns={[
                    {
                      ...extraColumnsField("Name", "name", 1, 1),
                      valueFormatter: (params) => params.value || "-",
                    },
                    {
                      ...extraColumnsField("Uploaded By", "uploadedBy", 1, 1),
                    },
                    {
                      ...extraColumnsField("Date", "createdOn", 1, 1),
                      valueFormatter: (params) =>
                        dayjs(params.value).format("DD MMM YYYY hh:ss") || "-",
                    },
                    {
                      ...extraColumnsField("Publish File", "visible ", 1, 1),
                      headerAlign: "right",
                      align: "right",
                      renderCell: (params) => {
                        return (
                          <Switch
                            onChange={(e) => {
                              e.stopPropagation();
                              e.preventDefault();
                              handlePublishResearchFiles(params?.row.id);
                            }}
                            checked={params?.row?.visible}
                            disabled={researchUpdateFilesLoading}
                          />
                        );
                      },
                    },
                    {
                      ...extraColumnsField("Acion", "action", 1, 1),
                      headerAlign: "right",
                      align: "right",
                      renderCell: (params) => {
                        return (
                          <LoadingButton
                            color="error"
                            size="extraSmall"
                            loading={researchFilesLoading}
                            variant="text"
                            onClick={(e: any) => {
                              e.stopPropagation();
                              e.preventDefault();
                              handleDeleteResearchFiles(params?.row.id);
                            }}
                          >
                            Delete
                          </LoadingButton>
                        );
                      },
                    },
                  ]}
                />
              </Grid2>
            )}
          {tab?.assetDetailsTabs === 3 &&
            addDetailsData?.data?.linkedVersions?.length !== 0 && (
              <Grid2 size={12}>
                <GKClientDataGrid
                  name="linkedVersion"
                  columnsButton={false}
                  density="compact"
                  pagination={false}
                  getRowId={(pa) => pa?.itemId}
                  rows={addDetailsData?.data?.linkedVersions || []}
                  columns={[
                    {
                      ...extraColumnsField("Version", "version", 120, 0),
                    },
                    {
                      type: "number",
                      ...extraColumnsField("Weightage", "weightage", 1, 0),
                    },
                  ]}
                />
              </Grid2>
            )}
          {tab?.assetDetailsTabs === 0 && (
            <Grid2
              p={1}
              size={12}
              bottom={"-8px"}
              position={"sticky"}
              textAlign={"right"}
              bgcolor={theme.palette.light.main}
            >
              <LoadingButton
                variant="contained"
                type="submit"
                form="stock-details-form"
                size="medium"
                loading={submitLoading}
              >
                {"Submit"}
              </LoadingButton>
            </Grid2>
          )}
        </Grid2>
      </DashboardLayout>
      {isStatusModalOpen && (
        <AssetsStatusModal
          open={isStatusModalOpen}
          setOpen={setIsStatusModalOpen}
        />
      )}
      {showAssetModal && (
        <AssetHistoryDetailsModal
          open={showAssetModal}
          setOpen={setShowAssetModal}
          data={showAssetDetails}
        />
      )}
    </>
  );
};

export default AddPortfolioDetailsModal;
