import { LoadingButton } from "@mui/lab";
import {
  DragDrop,
  GKButton,
  GKModal,
  Grid2,
  MdOutlineFileDownload,
} from "components";
import { useMutation } from "hooks/useQueries";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
import axiosInstance from "utils/axiosInstance";
import { GKModalProps } from "utils/commonInterface";
import { ThemeContainer } from "utils/Theme";
import { useInvestorContext } from "./InvestorContext";

const UploadHoldingsModal = ({ setOpen, open }: GKModalProps) => {
  const {
    state: { companies },
  } = useInvestorContext();

  const { theme } = ThemeContainer();

  const [allFiles, setAllFiles] = useState([]);

  const [uploadError, setUploadError] = useState<string | null>(null);

  const form = useFormContext();

  const { mutate, isLoading } = useMutation({
    mutationFn: (data: any) =>
      axiosInstance.post(`/plans/parse-holdings/`, data),
    onSuccess: (response) => {
      const plansHoldings = response?.data?.data?.map((da: any, i: number) => {
        return {
          name: companies?.find((d) => da?.gridCode === d.value)?.name,
          quantity: da?.quantity || 0,
          gridCode: da?.gridCode,
          cmp: da?.cmp || 0,
          id: i,
        };
      });
      form.setValue("planDataObject.plansHoldings", plansHoldings);

      setOpen(false);
    },
  });

  return (
    <GKModal
      modalTitle="Holdings Upload"
      open={open}
      setOpen={setOpen}
      footer={
        <Grid2
          size={12}
          display={"flex"}
          justifyContent={"space-between"}
          gap={1}
        >
          <GKButton
            variant="outlined"
            color="dark"
            onClick={() => {
              setOpen(false);
            }}
          >
            Close
          </GKButton>
          <LoadingButton
            loading={isLoading}
            variant="contained"
            onClick={() => {
              const formData = new FormData();
              formData.append("file", allFiles[0]);
              mutate(formData);
            }}
          >
            Upload
          </LoadingButton>
        </Grid2>
      }
    >
      <Grid2 container>
        <Grid2
          size={12}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          flexDirection={"column"}
        >
          <DragDrop
            supportName="CSV & XLSX"
            acceptType={".csv,.xlsx"}
            value={allFiles}
            multiple
            onChange={(e: any) => setAllFiles(e)}
            uploadError={uploadError}
            setUploadError={setUploadError}
          />
          <GKButton
            sx={{
              p: 0,
              color: theme.palette.grey[500],
              display: "flex",
              alignItems: "center",
              "&:hover": {
                background: "transparent",
              },
            }}
            href="https://frontend-logo.s3.us-east-1.amazonaws.com/GRIDKEY_HOLDINGS_CSV.xlsx"
            startIcon={<MdOutlineFileDownload size={14} />}
          >
            Download Sample File
          </GKButton>
        </Grid2>
      </Grid2>
    </GKModal>
  );
};

export default UploadHoldingsModal;
