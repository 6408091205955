/* eslint-disable react/no-danger */
import {
  AiOutlineLineChart,
  BiTachometer,
  CgMathPlus,
  Chip,
  Divider,
  GKTypography,
  Grid2,
  LuSparkle,
  Typography,
} from "components";
import { useFormContext } from "react-hook-form";
import { handleRemoveUnderscore } from "utils";
import { paletteLightTheme } from "utils/ColorTheme";
import { handleCapitalize } from "utils/common";
import { getHexaColor } from "utils/constants";
import { ThemeContainer } from "utils/Theme";
import { SkeletonLoading } from "./utils";

interface Props {
  loading: boolean;
}

const BasketTab = (props: Props) => {
  const { loading } = props;

  const form = useFormContext();

  const { theme } = ThemeContainer();

  const basketDetails = form.getValues("clientObject");
  const feePlanDetails = form.getValues("selectedFeePlan");

  if (loading) return <SkeletonLoading />;

  return (
    <Grid2 container spacing={2}>
      <Grid2 size={12} pt={2}>
        {/* <Typography
          fontWeight={600}
          pt={2}
          sx={{ fontSize: "clamp(1.125rem, 0.6563rem + 1.5vw, 1.5rem)" }}
        >
          Basket and Fee plan
        </Typography> */}
      </Grid2>

      <Grid2
        size={{ xs: 12, md: 6 }}
        sx={{ m: { xs: "", md: "auto" } }}
        display={"flex"}
        justifyContent={"center"}
        flexDirection={"column"}
        border={"1px solid #E3E8EF"}
        borderRadius={2}
      >
        <Grid2 size={12} container spacing={3} sx={{ p: { xs: 2, md: 5 } }}>
          <Grid2 size={12} container spacing={1}>
            <Grid2 size={12} display={"flex"} justifyContent={"center"} gap={1}>
              {basketDetails?.basket?.riskProfile?.map(
                (dataItem: string, index: number) => {
                  return (
                    <Chip
                      key={index}
                      icon={
                        <BiTachometer
                          color={`${getHexaColor(dataItem)}`}
                          size={16}
                        />
                      }
                      label={dataItem || "-"}
                      sx={{
                        borderRadius: 2,
                        p: "4px 8px 4px 3px",
                        background: `${getHexaColor(dataItem)}22`,
                        height: "unset",
                        color: `${getHexaColor(dataItem)}`,
                        "& .MuiChip-label": {
                          pr: 0,
                          pl: "10px",
                        },
                      }}
                    />
                  );
                },
              )}
              {basketDetails?.basket?.volatility && (
                <Chip
                  icon={<LuSparkle color="#8C1823" size={16} />}
                  label={
                    `${handleCapitalize(
                      basketDetails?.basket?.volatility,
                    )} volatility` || "-"
                  }
                  sx={{
                    borderRadius: 2,
                    p: "4px 8px 4px 3px",
                    background: `#8C182322`,
                    height: "unset",
                    color: "#8C1823",
                    textTransform: "capitalize",
                    "& .MuiChip-label": {
                      pr: 0,
                      pl: "10px",
                    },
                  }}
                />
              )}
              {basketDetails?.basket?.timePeriod && (
                <Chip
                  icon={<AiOutlineLineChart color="#0F172A" size={16} />}
                  label={
                    handleCapitalize(
                      handleRemoveUnderscore(basketDetails?.basket?.timePeriod),
                    ) || "-"
                  }
                  sx={{
                    borderRadius: 2,
                    p: "4px 8px 4px 3px",
                    background: `#0F172A22`,
                    height: "unset",
                    textTransform: "capitalize",
                    color: "#0F172A",
                    "& .MuiChip-label": {
                      pr: 0,
                      pl: "10px",
                    },
                  }}
                />
              )}
            </Grid2>

            <Grid2 size={12}>
              <Typography
                fontWeight={600}
                textAlign={"center"}
                sx={{ fontSize: "clamp(1.125rem, 0.6563rem + 1.5vw, 1.5rem)" }}
              >
                {basketDetails?.basket?.name || "-"}
              </Typography>
            </Grid2>
            <Grid2 size={12}>
              <Typography
                fontWeight={500}
                textAlign={"center"}
                color="#9AA4B2"
                sx={{ fontSize: "clamp(0.75rem, 0.4375rem + 1vw, 1rem)" }}
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: basketDetails?.basket?.rationale,
                  }}
                />
              </Typography>
            </Grid2>
          </Grid2>
          <Grid2 size={12}>
            <Divider />
          </Grid2>
          <Grid2 size={12} container spacing={1}>
            <Grid2 size={12}>
              <Typography
                fontWeight={600}
                textAlign={"center"}
                sx={{ fontSize: "clamp(1rem, 0.6563rem + 1.5vw, 1.2rem)" }}
              >
                {feePlanDetails.name || "-"}
              </Typography>
            </Grid2>
            <Grid2
              container
              size={12}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              spacing={4}
            >
              {feePlanDetails?.managementFee?.fee && (
                <Grid2>
                  <Typography
                    textTransform={"capitalize"}
                    color={theme.palette.secondaryText.main}
                    fontSize={12}
                  >
                    {feePlanDetails?.managementFee?.name || "-"}
                  </Typography>
                  <GKTypography
                    // fontWeight={600}
                    display={"flex"}
                    justifyContent={"center"}
                  >
                    {feePlanDetails?.managementFee?.fee || "-"}%
                  </GKTypography>
                </Grid2>
              )}
              {feePlanDetails?.performanceFee?.fee &&
                feePlanDetails?.managementFee?.fee && (
                  <Grid2>
                    <div
                      style={{
                        border: `1px solid ${paletteLightTheme.palette.border.main}`,
                        borderRadius: 4,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: 4,
                      }}
                    >
                      <CgMathPlus color={theme.palette.secondaryText.main} />
                    </div>
                  </Grid2>
                )}
              {feePlanDetails?.performanceFee?.fee && (
                <Grid2>
                  <Typography
                    textTransform={"capitalize"}
                    color={theme.palette.secondaryText.main}
                    fontSize={12}
                  >
                    {feePlanDetails?.performanceFee?.name || "-"}
                  </Typography>
                  <GKTypography
                    // fontWeight={600}
                    display={"flex"}
                    justifyContent={"center"}
                  >
                    {feePlanDetails?.performanceFee?.fee || "-"}%
                  </GKTypography>
                </Grid2>
              )}
            </Grid2>
            <Grid2 size={12}>
              <Typography
                fontWeight={500}
                textAlign={"center"}
                color="#9AA4B2 !important"
                sx={{ fontSize: "clamp(0.75rem, 0.4375rem + 1vw, 1rem)" }}
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: feePlanDetails?.description,
                  }}
                />
              </Typography>
            </Grid2>
          </Grid2>
        </Grid2>
      </Grid2>
    </Grid2>
  );
};

export default BasketTab;
