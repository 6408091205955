import { Grid2, IconButton } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-pro";
import { EditIcon } from "components";
import GKButton from "components/GKButton";
import GKClientDataGrid from "components/GKClientDataGrid";
import { Show } from "components/Show";
import { useFetchQuery, useMutation } from "hooks/useQueries";
import { useState } from "react";
import { CgMathPlus, CgTrash } from "react-icons/cg";
import { useParams } from "react-router-dom";
import axiosInstance from "utils/axiosInstance";
import { extraColumnsField, matchDate, refetchQuery } from "utils/helpers";
import { moneyCommaSeparator } from "utils/MathFunction";
import HighWaterMarkFormModal from "./HighWaterMarkFormModal";

const HighWaterMarkHistory = () => {
  const { id } = useParams();

  const [highWatermarkData, setHighWatermarkData] = useState({});
  const [openSubModal, setOpenSubModal] = useState(false);

  const { data, isFetching } = useFetchQuery({
    key: ["GET_HIGH_WATERMARK_HISTORY_BY_PORTFOLIO_ID"],
    route: "/portfolio-high-watermark/",
    requestBody: {
      portfolio: id,
    },
  });

  const { mutate: removeHighwatermark } = useMutation({
    mutationKey: ["ADD_HIGH_WATERMARK"],
    mutationFn: (value: number) =>
      axiosInstance.delete(
        `/portfolio-high-watermark/${value}/?portfolio=${id}`,
      ),
    onSuccess: () => {
      refetchQuery("GET_HIGH_WATERMARK_HISTORY_BY_PORTFOLIO_ID");
    },
  });

  const columns: GridColDef[] = [
    {
      ...extraColumnsField("Date", "date", 120, 1),
      valueFormatter: (value) => matchDate(value?.value),
    },
    {
      ...extraColumnsField("Amount", "highWatermark", 100, 1),
      valueGetter: (row) => row?.row?.highWatermark,
      valueFormatter: (params) => moneyCommaSeparator(params.value || 0),
      type: "number",
    },
    {
      ...extraColumnsField("Mode of Updation", "generateType", 140, 1),
    },
    {
      ...extraColumnsField("Update with Invoice", "invoice", 140, 1),
    },
    {
      ...extraColumnsField("Actions", "action", 100, 1),
      align: "right",
      headerAlign: "right",
      renderCell: (params) => {
        return (
          <Grid2 container justifyContent={"flex-end"} gap={1}>
            <IconButton
              disabled={params.row.generateType !== "Manual"}
              onClick={() => {
                setHighWatermarkData(params.row);
                setOpenSubModal(true);
              }}
            >
              <EditIcon size={20} />
            </IconButton>
            <IconButton
              disabled={params.row.generateType !== "Manual"}
              onClick={() => removeHighwatermark(params.row.id)}
            >
              <CgTrash size={20} />
            </IconButton>
          </Grid2>
        );
      },
    },
  ];

  return (
    <>
      <GKClientDataGrid
        loading={isFetching}
        columns={columns}
        rows={data?.data?.data || []}
        columnsButton={false}
        name="portfolioHighWaterMark"
        headerComponent={
          <GKButton
            variant="outlined"
            color="dark"
            startIcon={<CgMathPlus size={14} />}
            onClick={() => {
              setHighWatermarkData({});
              setOpenSubModal(true);
            }}
          >
            Add High Watermark
          </GKButton>
        }
        maxHeight={"calc(100vh - 403px)"}
      />

      <Show.When isTrue={openSubModal}>
        <HighWaterMarkFormModal
          open={openSubModal}
          setOpen={setOpenSubModal}
          portfolioId={id}
          highWatermarkData={highWatermarkData}
        />
      </Show.When>
    </>
  );
};

export default HighWaterMarkHistory;
