import {
  AiOutlineEdit,
  CgMathPlus,
  Divider,
  FiTrash2,
  FormControl,
  FormControlLabel,
  FormGroup,
  GKEditor,
  GKRadio,
  GKSelect,
  Grid2,
  IconButton,
  LuRefreshCw,
  MenuItem,
  RadioGroup,
  Show,
  TextField,
  Tooltip,
  Typography,
} from "components";
import GKButton from "components/GKButton";
import GKCheckbox from "components/GKCheckbox";
import GKSearchSelect from "components/GKSearchSelect";
import { isArray } from "lodash";
import { Controller, useFormContext } from "react-hook-form";
import { MdOutlineInfo } from "react-icons/md";
import {
  CUSTOM_REPORT_RETURN_METRICS,
  PMS_REPORT_EXTRA_OPTIONS_FIELD,
  PMS_REPORT_OPTION_TO_INCLUDE,
  PMS_REPORT_OPTIONAL_FIELD,
} from "utils/constants";
import { handleReturnAutocompletePlaceholder } from "utils/StringFunctions";

interface Props {
  benchmarkList: any;
  benchmarkLoading: boolean;
  quoteList: any;
  handleDeleteQuote: any;
  generateQuote: any;
  setEditQuoteValue: any;
  setOpenQuoteModal: any;
}

const timePeriods = [
  { label: "MTD", value: "month_to_date" },
  { label: "QTD", value: "quarter_to_date" },
  { label: "YTD", value: "year_to_date" },
  { label: "Since Inception", value: "since_inception" },
  { label: "Custom", value: "custom_date_range" },
];

const advisorCategoryList = [
  { name: "Research Analyst", value: "researchAnalyst" },
  { name: "Investment Advisor", value: "investmentAdvisor" },
  { name: "Portfolio Manager", value: "portfolioManager" },
];

const CustomReportForm = (props: Props) => {
  const {
    benchmarkList,
    benchmarkLoading,
    quoteList,
    handleDeleteQuote,
    generateQuote,
    setEditQuoteValue,
    setOpenQuoteModal,
  } = props;

  const form = useFormContext();

  const selectedReportName = form.getValues("selectedReported.reportName");

  const handleClickTimePeriods = (key: string, option: string) => {
    const updatedOptions = form.getValues(key).includes(option)
      ? form.getValues(key).filter((item: any) => item !== option)
      : [...form.getValues(key), option];

    form.setValue(key, updatedOptions);
  };

  return (
    <Grid2 container spacing={2}>
      <Show.When isTrue={selectedReportName === "benchmark_performance_report"}>
        <Grid2 size={12}>
          <Controller
            name={"disclaimer"}
            control={form.control}
            render={({ field }) => (
              <GKEditor {...field} inputLabel="Report Body" />
            )}
          />
        </Grid2>
        <Grid2 size={12}>
          <Controller
            name={"benchmark"}
            control={form.control}
            render={({ field, fieldState: { error } }) => (
              <GKSearchSelect
                {...field}
                disableClearable
                multiple
                inputLabel="Benchmark"
                loading={benchmarkLoading}
                onChange={(event, val) => {
                  const selectedIds = val.map(
                    (option: { value: string }) => option.value,
                  );
                  field.onChange(selectedIds);
                }}
                value={
                  benchmarkList?.filter((option: { value: string }) =>
                    field.value.includes(option.value),
                  ) || []
                }
                options={benchmarkList || []}
                getOptionLabel={(option) =>
                  isArray(option) ? "" : option.name || option
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder={handleReturnAutocompletePlaceholder({
                      placeholder: "Select Benchmark",
                      value: field.value,
                    })}
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              />
            )}
          />
        </Grid2>
        <Grid2 size={12}>
          <Controller
            name="returnMetrics"
            control={form.control}
            render={({ field }) => {
              return (
                <FormControl
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "4px",
                  }}
                >
                  <Typography
                    color={"#4B5565"}
                    display={"flex"}
                    alignItems={"center"}
                    gap={"4px"}
                  >
                    Return Metrics
                    <Tooltip
                      arrow
                      title={
                        <Typography fontSize={12}>
                          <li>
                            XIRR: Even if the investment is less than one year,
                            XIRR is displayed.
                          </li>
                          <li>
                            TWRR: For investments under one year, absolute
                            return is shown; for more than a year, TWRR is
                            applied.
                          </li>
                        </Typography>
                      }
                      placement="right"
                    >
                      <IconButton>
                        <MdOutlineInfo size={16} />
                      </IconButton>
                    </Tooltip>
                  </Typography>
                  <RadioGroup row {...field} defaultValue={field.value}>
                    {CUSTOM_REPORT_RETURN_METRICS.map((dataItem) => (
                      <FormControlLabel
                        value={dataItem.value}
                        control={<GKRadio />}
                        label={dataItem.name}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              );
            }}
          />
        </Grid2>
      </Show.When>
      <Show.When isTrue={selectedReportName === "custom_report"}>
        <Grid2 size={12}>
          <Controller
            name={"advisorCategory"}
            control={form.control}
            render={({ field }) => (
              <GKSearchSelect
                {...field}
                disableClearable
                inputLabel="Advisor Category"
                onChange={(event, val) => field.onChange(val?.value)}
                value={
                  advisorCategoryList.find(
                    (data: any) => data?.value === field.value,
                  )?.name || []
                }
                options={advisorCategoryList || []}
                getOptionLabel={(option) =>
                  isArray(option) ? "" : option.name || option
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder={handleReturnAutocompletePlaceholder({
                      placeholder: "Select Advisor Category",
                      value: field.value,
                    })}
                  />
                )}
              />
            )}
          />
        </Grid2>
        <Grid2 size={12}>
          <Typography
            color={"#4B5565"}
            display={"flex"}
            alignItems={"center"}
            gap={"4px"}
          >
            Section to includes
            <Tooltip
              arrow
              title="Tick the checkbox to include these sections in your reports."
              placement="right"
            >
              <IconButton>
                <MdOutlineInfo size={16} />
              </IconButton>
            </Tooltip>
          </Typography>
          <FormControl component="fieldset">
            <Controller
              name={"optionsToInclude"}
              control={form.control}
              render={({ field }) => (
                <FormGroup>
                  {PMS_REPORT_OPTION_TO_INCLUDE.map((option) => (
                    <FormControlLabel
                      {...field}
                      key={option.value}
                      control={
                        <GKCheckbox
                          disableLeftPadding
                          checked={field.value.includes(option.value)}
                          onChange={() => {
                            const updatedOptions = field.value.includes(
                              option.value,
                            )
                              ? field.value.filter(
                                  (item: any) => item !== option.value,
                                )
                              : [...field.value, option.value];

                            field.onChange(updatedOptions);
                          }}
                        />
                      }
                      label={option.name}
                    />
                  ))}
                </FormGroup>
              )}
            />
          </FormControl>
        </Grid2>
        <Grid2 size={12}>
          <Divider />
        </Grid2>
        <Grid2 size={12}>
          <Controller
            name="returnMetrics"
            control={form.control}
            render={({ field }) => {
              return (
                <FormControl
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "4px",
                  }}
                >
                  <Typography
                    color={"#4B5565"}
                    display={"flex"}
                    alignItems={"center"}
                    gap={"4px"}
                  >
                    Return Metrics{" "}
                    <Tooltip
                      arrow
                      title={
                        <Typography fontSize={12}>
                          <li>
                            XIRR (Extended Internal Rate of Return): For
                            investments under a year, absolute return is shown;
                            for more than a year, XIRR is applied.
                          </li>
                          <li>
                            TWRR (Time-Weighted Rate of Return): For investments
                            under a year, absolute return is shown; for more
                            than a year, TWRR is applied.
                          </li>
                        </Typography>
                      }
                      placement="right"
                    >
                      <IconButton>
                        <MdOutlineInfo size={16} />
                      </IconButton>
                    </Tooltip>
                  </Typography>
                  <RadioGroup row {...field} defaultValue={field.value}>
                    {CUSTOM_REPORT_RETURN_METRICS.map((dataItem) => (
                      <FormControlLabel
                        value={dataItem.value}
                        control={<GKRadio />}
                        label={dataItem.name}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              );
            }}
          />
        </Grid2>
        <Grid2 size={12} display={"flex"} alignItems={"center"} gap={2}>
          <Typography
            color={"#4B5565"}
            display={"flex"}
            alignItems={"center"}
            gap={"4px"}
          >
            Portfolio Calculation Preference
            <Tooltip
              arrow
              title="Select whether to calculate returns based on trades only or trades + cash."
              placement="right"
            >
              <IconButton>
                <MdOutlineInfo size={16} />
              </IconButton>
            </Tooltip>
          </Typography>
          <FormControl component="fieldset">
            <Controller
              name="tradesPlusCash"
              control={form.control}
              render={({ field }) => (
                <FormGroup row>
                  {PMS_REPORT_EXTRA_OPTIONS_FIELD.map((option) => {
                    return (
                      <FormControlLabel
                        control={
                          <GKRadio
                            {...field}
                            sx={{ paddingLeft: "0px" }}
                            checked={field.value === option.value}
                            onChange={() => {
                              const updatedOption = option.value;
                              field.onChange(updatedOption);
                            }}
                          />
                        }
                        label={option.name}
                      />
                    );
                  })}
                </FormGroup>
              )}
            />
          </FormControl>
        </Grid2>
        <Grid2 size={12}>
          <Divider />
        </Grid2>
        <Grid2 size={12}>
          <Typography
            color={"#4B5565"}
            display={"flex"}
            alignItems={"center"}
            gap={"4px"}
          >
            Optional Field
            <Tooltip
              arrow
              title="Choose additional fields to include in the portfolio summary."
              placement="right"
            >
              <IconButton>
                <MdOutlineInfo size={16} />
              </IconButton>
            </Tooltip>
          </Typography>
          <FormControl component="fieldset">
            <Controller
              name="optionalField"
              control={form.control}
              render={({ field }) => (
                <FormGroup row>
                  {PMS_REPORT_OPTIONAL_FIELD.map((option) => {
                    return (
                      <FormControlLabel
                        key={option.value}
                        control={
                          <GKCheckbox
                            {...field}
                            disableLeftPadding
                            checked={field.value?.includes(option.value)}
                            onChange={() => {
                              const updatedOptions = field.value.includes(
                                option.value,
                              )
                                ? field.value.filter(
                                    (item: any) => item !== option.value,
                                  )
                                : [...field.value, option.value];

                              field.onChange(updatedOptions);
                            }}
                          />
                        }
                        label={option.name}
                      />
                    );
                  })}
                </FormGroup>
              )}
            />
          </FormControl>
        </Grid2>
        <Show.When
          isTrue={form
            .getValues("optionsToInclude")
            ?.includes("performance_comparison")}
        >
          <Grid2 size={12}>
            <Divider />
          </Grid2>
          <Grid2 size={12}>
            <Controller
              name={"benchmark"}
              control={form.control}
              render={({ field }) => {
                return (
                  <GKSearchSelect
                    {...field}
                    disableClearable
                    inputLabel="Benchmark"
                    loading={benchmarkLoading}
                    onChange={(event, val) => field.onChange([val?.value])}
                    value={
                      benchmarkList?.filter((option: { value: string }) =>
                        field.value.includes(option.value),
                      )[0]?.name || []
                    }
                    options={benchmarkList || []}
                    getOptionLabel={(option) =>
                      isArray(option) ? "" : option.name || option
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder={handleReturnAutocompletePlaceholder({
                          placeholder: "Select Benchmark",
                          value: field.value,
                        })}
                      />
                    )}
                  />
                );
              }}
            />
          </Grid2>
          <Grid2 size={12}>
            <Controller
              name="benchmarkComparisionTimePeriods"
              control={form.control}
              render={({ field }) => (
                <Grid2 container size={12} display={"flex"} spacing={1}>
                  <Grid2 size={12}>
                    <Typography color={"#4B5565"}>
                      Select time period for benchmark comparison
                    </Typography>
                  </Grid2>
                  {timePeriods.map((button) => {
                    return (
                      <Grid2 size={2.4}>
                        <GKButton
                          fullWidth
                          color={
                            field.value.includes(button.value)
                              ? "primary"
                              : "dark"
                          }
                          variant={
                            field.value.includes(button.value)
                              ? "contained"
                              : "outlined"
                          }
                          onClick={() =>
                            handleClickTimePeriods(
                              "benchmarkComparisionTimePeriods",
                              button.value,
                            )
                          }
                        >
                          {button.label}
                        </GKButton>
                      </Grid2>
                    );
                  })}
                </Grid2>
              )}
            />
          </Grid2>
        </Show.When>
        <Show.When
          isTrue={form
            .getValues("optionsToInclude")
            ?.includes("portfolio_performance")}
        >
          <Grid2 size={12}>
            <Divider />
          </Grid2>
          <Grid2 size={12}>
            <Controller
              name="availableTimePeriods"
              control={form.control}
              render={({ field }) => (
                <Grid2 container size={12} display={"flex"} spacing={1}>
                  <Grid2 size={12}>
                    <Typography color={"#4B5565"}>
                      Select time period for Portfolio Performance
                    </Typography>
                  </Grid2>
                  {timePeriods.map((button) => {
                    return (
                      <Grid2 size={2.4}>
                        <GKButton
                          fullWidth
                          color={
                            field.value.includes(button.value)
                              ? "primary"
                              : "dark"
                          }
                          variant={
                            field.value.includes(button.value)
                              ? "contained"
                              : "outlined"
                          }
                          onClick={() =>
                            handleClickTimePeriods(
                              "availableTimePeriods",
                              button.value,
                            )
                          }
                        >
                          {button.label}
                        </GKButton>
                      </Grid2>
                    );
                  })}
                </Grid2>
              )}
            />
          </Grid2>
        </Show.When>
        <Grid2 size={12}>
          <Divider />
        </Grid2>
        <Grid2 size={12}>
          <Controller
            name={"quote"}
            control={form.control}
            render={({ field }) => (
              <GKSelect
                {...field}
                inputLabel={
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography fontSize={18} color={"#000000"}>
                      Quote
                    </Typography>
                    <IconButton onClick={() => generateQuote(quoteList, form)}>
                      <LuRefreshCw size={18} />
                    </IconButton>
                  </div>
                }
                placeholder="Select Quote"
                renderValue={(selected) => {
                  const value =
                    selected &&
                    quoteList?.find(
                      (data: { name: string; value: number }) =>
                        data?.value === selected,
                    )?.name;

                  return value || "Select an option";
                }}
              >
                {quoteList.map((data: { name: string; value: number }) => {
                  return (
                    <MenuItem
                      value={data?.value}
                      key={data?.value}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        gap: "10px",
                      }}
                    >
                      <Typography>{data?.name}</Typography>
                      <span style={{ display: "flex", gap: "2px" }}>
                        <IconButton
                          style={{ padding: 0 }}
                          onClick={(e) => {
                            e.stopPropagation();
                            setEditQuoteValue(data);
                            setOpenQuoteModal(true);
                          }}
                        >
                          <AiOutlineEdit />
                        </IconButton>
                        <IconButton
                          style={{ padding: 0 }}
                          className="outline"
                          color="error"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDeleteQuote(data?.value);
                          }}
                        >
                          <FiTrash2 size={18} />
                        </IconButton>
                      </span>
                    </MenuItem>
                  );
                })}
                <MenuItem
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    gap: "10px",
                  }}
                >
                  <GKButton
                    variant="link"
                    startIcon={<CgMathPlus />}
                    onClick={() => {
                      setEditQuoteValue({});
                      setOpenQuoteModal(true);
                    }}
                  >
                    Add New
                  </GKButton>
                </MenuItem>
              </GKSelect>
            )}
          />
        </Grid2>
        <Grid2 size={12}>
          <Controller
            name={"disclaimer"}
            control={form.control}
            render={({ field }) => (
              <GKEditor {...field} inputLabel="Disclaimer" />
            )}
          />
        </Grid2>
      </Show.When>
    </Grid2>
  );
};

export default CustomReportForm;

/* {form.getValues("availableTimePeriods").includes("custom_date_range") && (
        <>
          <Grid2 container size={6}>

              <Controller
                name="custom_date.start_date"
                control={form.control}
                render={({ field }) => (
                  <GKDatePicker
                    {...field}
                    inputLabel="Start Date"
                    value={dayjs(field.value)}
                    onChange={(value) =>
                      field.onChange(
                        value ? dayjs(value).format("YYYY-MM-DD") : ""
                      )
                    }
                    disableFuture
                    slotProps={{
                      textField: {
                        error: false,
                        helperText: false,
                      },
                    }}
                  />
                )}
              />

          </Grid2>
          <Grid2 container size={6}>

              <Controller
                name="custom_date.end_date"
                control={form.control}
                render={({ field }) => (
                  <GKDatePicker
                    {...field}
                    inputLabel="End Date"
                    value={dayjs(field.value)}
                    onChange={(value) =>
                      field.onChange(
                        value ? dayjs(value).format("YYYY-MM-DD") : ""
                      )
                    }
                    maxDate={dayjs()}
                    disableFuture
                    slotProps={{
                      textField: {
                        error: false,
                        helperText: false,
                      },
                    }}
                  />
                )}
              />

          </Grid2>
        </>
      )} */
