import { GridColDef } from "@mui/x-data-grid-pro";
import {
  CgMathPlus,
  GKButton,
  GKDataGrid,
  Grid2,
  StatusField,
} from "components";
import useGenerateQueryParams from "hooks/useGenerateQueryParams";
import { useFetchQuery } from "hooks/useQueries";
import { useNavigate } from "react-router-dom";
import { numberToCurrency } from "utils";
import { COUPON_LIST } from "utils/apiRoutes";
import { handleCapitalize } from "utils/common";
import { extraColumnsField } from "utils/helpers";
import useDocumentTitle from "utils/useDocumentTitle";

const columns: GridColDef[] = [
  {
    ...extraColumnsField("Name", "name", 150, 1),
  },
  {
    ...extraColumnsField("Fee Plan", "feePlanName", 150, 1),
  },
  {
    ...extraColumnsField("Discount", "discountValue", 150, 1),
    valueGetter: (params) =>
      params?.row?.couponType === "fixed"
        ? numberToCurrency(params?.row?.discountValue)
        : `${params?.row?.discountValue}%`,
  },
  {
    ...extraColumnsField("Status", "isActive", 150, 1),
    renderCell: (row) => (
      <StatusField statusString={row?.row?.isActive?.toString()} />
    ),
  },
  {
    ...extraColumnsField("Type", "applyOn", 150, 1),
    valueFormatter: (params) => handleCapitalize(params?.value),
  },
];

const Coupon = () => {
  useDocumentTitle("Coupon");

  const navigate = useNavigate();

  const requestBody = useGenerateQueryParams("coupon");

  const { data, isFetching } = useFetchQuery({
    key: ["GET_FEE_PLAN_LIST", requestBody],
    route: COUPON_LIST,
    requestBody,
  });

  return (
    <Grid2 container>
      <Grid2 size={12}>
        <GKDataGrid
          loading={isFetching}
          columns={columns}
          rows={data?.data?.data || []}
          maxHeight={"calc(100vh - 312px)"}
          name="coupon"
          tableName={"coupon"}
          totalCount={data?.data?.totalCount || 0}
          headerComponent={
            <GKButton
              variant="contained"
              onClick={() => navigate("/app/create-coupon")}
              startIcon={<CgMathPlus size={16} />}
            >
              Create Coupon
            </GKButton>
          }
          onRowClick={(row) => navigate(`/app/coupon/${row.id}/edit-coupon`)}
        />
      </Grid2>
    </Grid2>
  );
};

export default Coupon;
