export const BRANCH_HAS_MANAGER = "/branch/has_manager/";
export const GET_ALL_FAMILY = "/family/all/";
export const GET_AGREEMENT_BY_ID = (id: number | string) =>
  `/agreements/${id}/`;
export const TRANSACTION_CASH = "/transaction_cash/";
export const CLIENT_ID_BY_FAMILY = (id: number | string) =>
  `/client/${id}/families/`;
export const GET_COMPANY_BY_ID = (id: number | string) => `/company/${id}/`;
export const AUMS_AGGREGATE = "/aums/aggregate/";
export const AUMS_TIMELINE = "/aums/timeline/";
export const AUMS_TIMELINE_TRANSACTION = "/aums/timeline/transactions/";
export const AUMS_TIMELINE_BALANCE = "/aums/timeline/balance/";
export const CUSTOM_AGREEMENT_MODAL_PORTFOLIO =
  "/custom-agreement/model-portfolios/";
export const CLIENT_BY_ID = (id: number | string) => `/client/${id}/`;
export const GET_FAMILY = `/family/`;
export const GET_LEAD_NOTE_BY_ID = (id: number | string) =>
  `/lead/${id}/notes/`;
export const GET_LEAD_MEETING_BY_ID = (id: number | string) =>
  `/lead/${id}/meetings/`;
export const PORTFOLIO_CARD_BY_ID = (id: number | string) =>
  `/portfolios/${id}/cards/`;
export const PORTFOLIO_REFRESH_XIRR_BY_ID = (id: number | string) =>
  `/portfolios/${id}/refresh_xirr/`;
export const PORTFOLIO_HOLDING_BY_ID = (id: number | string) =>
  `/portfolios/${id}/holdings/`;
export const GET_HOLDING = "/holdings/table/";
export const REPORT_QUOTE = "/report_quotes/";
export const GET_ALL_PORTFOLIO = "/portfolios/dropdown/";
export const GET_ALL_BENCHMARK = "/indices/list/";
export const GET_FEE_PLAN_BY_ID = (id: number | string) => `/fee-plans/${id}/`;
export const GET_LEAD_BY_ID = (id: number | string) => `/lead/${id}/`;
export const GET_ALL_EMPLOYEE = "/employees/all/";
export const GET_ALL_EMPLOYEE_LIST = "/employees/";
export const GET_LEAD_STATUS = "/lead-status/";
export const GET_LEAD_SOURCE = "/lead-source/";
export const TRANSACTION_UPLOAD_CONTRACT_NOTE = `/transaction_upload/contract_note/`;
export const GET_ALL_FAVORITE_UPLOAD = `/transaction_upload/fav_upload/`;
export const GET_COMPANY_LIST = (type?: string[]) => {
  const queryString = type
    ? `?inst_type=[${type.map((t) => `"${t}"`).join(",")}]`
    : "";

  return `/intruments/list/${queryString}`;
};
export const ROLE_WISE_PERMISSION = "/role_permissions/";
export const COMPANY_SETTING_BY_ID = (id: number | string) =>
  `/company/${id}/company_settings/`;
export const GET_ALL_LEDGER_ENTRIES = "/ledger/";
export const PORTFOLIO_AVAILABLE_BALANCE = "/portfolios/avl_balance/";
export const CLIENT_SUMMARY_BY_ID = (id: number | string) =>
  `/client/${id}/summary/`;
export const KYC_BY_ID = (id: number | string) => `/kyc/${id}/`;
export const RISK_PROFILE_BY_ID = (id: number | string) =>
  `/riskprofileresponse/${id}/`;
export const CLIENT_BANK_BY_ID = (id: number | string) =>
  `/client/${id}/banks/`;
export const CLIENT_DEMAT_BY_ID = (id: number | string) =>
  `/client/${id}/demats/`;
export const CLIENT_NOMINEE_BY_ID = (id: number | string) =>
  `/client/${id}/nominees/`;
export const CLIENT_ACCESS_BY_ID = (id: number | string) =>
  `/client/access/${id}/`;
export const CLIENT_HOLDING_BY_ID = (id: number | string) =>
  `/client/${id}/holdings/`;
export const GET_ALL_MODAL_PORTFOLIO = "/model_portfolio/all/";
export const GET_ALL_AGREEMENT = "/custom-agreement/";
export const GET_ALL_FEE_PLAN = "/fee-plans-dropdown/";
export const GET_RISK_PROFILE = `/riskprofile/`;
export const COMPLIANCE_BY_CLIENT_ID = (id: number | string) =>
  `/compliance/?client_id=${id}`;
export const GET_ALL_CLIENT = `/client/all/`;
export const GET_CLIENT_COMPLIANCE = `/client/compliance/`;
export const GET_RISK_PROFILE_RESPONSE_BY_ID = (id: number | string) =>
  `/single-riskprofile-response/${id}/`;
export const CUSTOM_BROKER_UPLOAD_LIST = "/broker-api-data/";
export const DEFAULT_BRANCH_PERMISSION = `/default_permissions/branch/`;
export const DEFAULT_CLIENT_PERMISSION = `/default_permissions/client/`;
export const DEFAULT_EMPLOYEE_PERMISSION = `/default_permissions/employee/`;
export const DEFAULT_PARTNER_PERMISSION = `/default_permissions/partner/`;
export const EQUITY_ETF_TRANSACTION_BY_ID = (id: number | string) =>
  `/transaction/${id}/`;
export const GET_ALL_EQUITY_ETF_TRANSACTION = "/transaction/";
export const FAMILY_ID_BY_CLIENT = (id: number | string) =>
  `/family/${id}/clients/`;
export const FAMILY_ID_BY_PORTFOLIO_ACCESS = (
  id: number | string,
  assetsId: number | string,
) => `/family/${id}/portfolios_access/${assetsId}/`;
export const FAMILY_ACCESS_BY_ID = (id: number | string) =>
  `/family/access/${id}/`;
export const FAMILY_ID_BY_SUMMARY = (id: number | string) =>
  `/family/${id}/summary/`;
export const FAMILY_ID_BY_CLIENT_ACCESS = (id: number | string) =>
  `/family/${id}/clients_access/`;
export const FAMILY_BY_ID = (id: number | string) => `/family/${id}/`;
export const FAMILY_ID_BY_HOLDING = (id: number | string) =>
  `/family/${id}/holdings/`;
export const FAMILY_ID_BY_PORTFOLIO = (id: number | string) =>
  `/family/${id}/portfolios/`;
export const GET_ALL_INCOME_EXPENSE_LIST = "/income_expense/";
export const GET_ALL_INCOME_EXPENSE_BY_PORTFOLIO_ID = (id: number | string) =>
  `/income_expense/portfolio/${id}/`;
export const GET_HOLDING_BY_PORTFOLIO_ID = (id: number | string) =>
  `/holdings/${id}/portfolios_table/`;
export const GET_LEAD_DATA_BY_ID = (
  id: number | string,
  assetsId: number | string,
) => `/lead/${id}/notes/${assetsId}/`;
export const GET_ALL_NOTE_TYPE = "/notes-type/";
export const GET_ALL_LEAD_TAG = "/lead-tags/";
export const GET_LEAD_STATUS_SOURCE_LOGS = `/lead-update-status-source/`;
export const GET_ALL_MARKETING_MATERIAL_LIST = `/marketing-materials/`;
export const GET_ALL_LEAD_LOGS_BY_LEAD_ID = `/lead-logs/`;
export const MANDATE_COMPANY_CONFIGURE_LIST = "/digio/company-configuration/";
export const GET_ALL_LEAD = "/lead/";
export const GET_ALL_MUTUAL_FUND_TRANSACTION = "/mutual_funds_transaction/";
export const GET_ALL_MUTUAL_FUND_TRANSACTION_BY_PORTFOLIO_ID = (
  id: number | string,
) => `/portfolios/${id}/mutual-fund/transactions/`;
export const EMPLOYEE_ACCESS_ID_BY_CLIENT = (id: number | string) =>
  `/employees/access/${id}/clients/`;
export const EMPLOYEE_ASSIGN_ROLE = (id: number | string) =>
  `/employees/${id}/assign_role/`;
export const PARTNER_ASSIGN_ROLE = (id: number | string) =>
  `/partner/${id}/assign_role/`;
export const GET_EMPLOYEE_BY_ID = (id: number | string) => `/employees/${id}/`;
export const GET_PORTFOLIO_BY_ID = (id: number | string) =>
  `/portfolios/${id}/`;
export const PORTFOLIO_HOLDING_SUMMARY_BY_ID = (id: number | string) =>
  `/portfolios/${id}/holdings/summary/`;
export const GET_ALL_REALISED_INVENTORY = (id: number | string) =>
  `/portfolios/${id}/sold_inventory/`;
export const GET_ALL_PORTFOLIO_LIST = "/portfolios/";
export const ETF_TRANSACTION_BY_ID = (id: number | string) =>
  `/etf_transaction/${id}/`;
export const ETF_TRANSACTION_BY_PORTFOLIO_ID = (id: number | string) =>
  `/etf_transaction/portfolio/${id}/`;
export const LONG_TEAM_HOLDING_BY_PORTFOLIO_ID = (id: number | string) =>
  `/portfolios/${id}/inventory/`;
export const CASH_TRANSACTION_BY_PORTFOLIO_ID = (id: number | string) =>
  `/transaction_cash/portfolio/${id}/`;
export const ADVANCE_FEE_COLLECTION_BY = (id: number | string) =>
  `/advance-fee-collection/${id}/`;
export const FEE_COLLECTION_BY_PORTFOLIO_ID = (id: number | string) =>
  `/client-fee-collection/get-portfolio-fee-collection/${id}/`;
export const FEE_PLAN_BY_PORTFOLIO_ID = (id: number | string) =>
  `/portfolio-feeplan-mapping/get-portfolio-fee-plan/${id}/`;
export const PORTFOLIO_ACCESS_BY_ID = (id: number | string) =>
  `/portfolios/access/${id}/`;
export const PORTFOLIO_CLIENT_ACCESS_BY_ID = (id: number | string) =>
  `/portfolios/${id}/clients_access/`;
export const PORTFOLIO_COMPARISION = (
  id: number | string,
  assetId: number | string,
) => `/portfolios/${id}/performance_comparision/?benchmarks=[${assetId}]`;
export const NAV_LIST_BY_PORTFOLIO_ID = (id: number | string) =>
  `/portfolios/${id}/nav/`;
export const LEDGER_BY_PORTFOLIO_ID = (id: number | string) =>
  `/ledger/portfolio/${id}/`;
export const AUTO_FORWARD_DATA = "/auto-forward-data/";
export const CONTRACT_NOTE_PORTFOLIO = "/contract-notes/";
export const PORTFOLIO_HOLDING_PREVIEW_ID = (id: number | string) =>
  `/portfolio-review/${id}/holdings/`;
export const PORTFOLIO_TREAD_BOOK_LIST = (id: number | string) =>
  `/portfolios/${id}/transactions/`;
export const PORTFOLIO_GROUP_HOLDING = (id: number | string) =>
  `/portfolio-groups/${id}/holdings/`;
export const PORTFOLIO_GROUP_BY_ID = (id: number | string) =>
  `/portfolio-groups/${id}/`;
export const GET_PORTFOLIOS = "/portfolios/all/";
export const GET_PORTFOLIO_GROUP_LIST = "/portfolio-groups/";
export const INDICES = `/particular-indices/`;
export const REPORTS = `/reports_manager/`;
export const GET_REPORTS = `/report/`;
export const REPORT_SCHEDULE_LIST = `/report_scheduler/`;
export const GET_REPORT_SCHEDULE_BY_ID = (id: number | string) =>
  `/report_scheduler/${id}/`;
export const PORTFOLIO_FEE_PLAN_MAPPING = "/portfolio-feeplan-mapping/";
export const CLIENT_FEE_COLLECTION_BY_ID = (id: number | string) =>
  `/client-fee-collection/${id}/`;
export const PORTFOLIO_FEE_PLAN_MAPPING_BY_ID = (id: number | string) =>
  `/portfolio-feeplan-mapping/get-fee-plan/${id}/`;
export const CLIENT_FEE_COLLECTION = "/client-fee-collection/";
export const FEE_PLAN_LIST = "/fee-plans/";
export const INVOICE_HISTORY = "/invoice-history/";
export const PROFORMA_HISTORY = `/proforma-history/`;
export const GET_BRANCH_BY_ID = (id: number | string) => `/branch/${id}}/`;
export const GET_FAQ = "/faq/";
export const GET_FAQ_MODULES = `/faq-modules/`;
export const WALLET_HISTORY = "/wallet-history/";
export const FULL_WALLET_HISTORY = "/full-wallet-history/";
export const WALLET_RATE_CARD = "/rate-card/";
export const GET_WALLET_TOP_UP_LIST = "/wallet-top-up/";
export const USER_PROFILE_DETAILS_BY_ID = (id: number | string) =>
  `/personal-details-update/${id}/`;
export const EMAIL_CONFIGURATION = "/email-configuration/";
export const REPLY_EMAIL_PORTFOLIO = "/reply-email-portfolio/";
export const ALL_MAPPED_PORTFOLIO_BY_ORDER_FORMAT_BY = `/order-manager-mappings/all_map_portfolio/`;
export const ORDER_MANAGER_MAPPING = "/order-manager-mappings/";
export const CUSTOM_AGREEMENT_VARIABLES = `/custom-agreement/variables/`;
export const ROLE_PERMISSION_BY_ID = (id: number | string) =>
  `/role_permissions/${id}/`;
export const ADVISOR_COMPANY_MAPPING = "/advisor-company-mappings/";
export const CONVERT_RIGHT_ISSUE_LIST = "/convert-rightissue-instrument/list/";
export const CONVERT_RIGHT_ISSUE_PORTFOLIO_DONE = (id: number | string) =>
  `/convert-rightissue/${id}/portfolio/done/`;
export const CONVERT_RIGHT_ISSUE_PORTFOLIO = (id: number | string) =>
  `/convert-rightissue/${id}/portfolio/`;
export const FEE_CALCULATION_HISTORY = "/fee-calculation-history/";
export const ADVISOR_COMPANY_MASTER_LIST = "/advisor-cm/";
export const ADVISOR_COMPANY_CORPORATE_ACTION_LIST = (id: number | string) =>
  `/advisor-cm/${id}/ca/`;
export const GET_ALL_ANCHOR_TRANSACTION_BY_PORTFOLIO_ID = (
  id: number | string,
) => `/portfolios/${id}/anchor-holdings/`;
export const GET_ALL_OTHER_ASSET_BY_ASSET_TYPE_LIST_BY_PORTFOLIO_ID = (
  id: number | string,
  assetId: number | string,
) => `/other_assets/${id}/?asset_type=${assetId}`;
export const INCOME_EXPENSE_LIST_BY_TRANSACTION_ID = (id: number | string) =>
  `/income_expense/${id}/`;
export const GET_ADVISOR_HISTORICAL_PRICE_LIST = (id: number | string) =>
  `/advisor-cm/${id}/price/`;
export const MANDATE_PRESENTATION_TRANSACTION_LIST = (id: number | string) =>
  `/digio/mandate-presentation/${id}/transactions/`;
export const CLIENT_MANDATE_PRESENTATION_LIST = (id: number | string) =>
  `/digio/client-mandate/${id}/presentation/`;
export const CLIENT_MANDATE_LIST = "/digio/client-mandate/";
export const INVOICE_CHARGES_DETAILS = (id: number | string) =>
  `/invoice-history/${id}/invoice-payment-details/`;
export const PAYMENT_LINK_LIST = "/razorpay-payment-link/";
export const COUPON_LIST = "/fee-plan-coupon/";
