import { LoadingButton } from "@mui/lab";
import { useAppContext } from "AppContext";
import {
  CgMathPlus,
  GKButton,
  GKModal,
  GKSearchSelect,
  GKTextField,
  Grid2,
  TextField,
} from "components";
import useAddPortfolioModal from "hooks/useAddPortfolioModal";
import { Controller } from "react-hook-form";
import { GKModalProps } from "utils/commonInterface";
import { getActiveBrokers } from "utils/helpers";
import { handleReturnAutocompletePlaceholder } from "utils/StringFunctions";
import { validateNotWhitespaceOnly } from "utils/validationSchema";
import ClientModal from "./ClientModal";

interface Props {
  showClientButton?: boolean;
}

const AddPortfolioModal = ({
  setOpen,
  open,
  showClientButton = true,
}: Props & GKModalProps) => {
  const {
    state: {
      clientList,
      partnerList,
      userData: { brokerSupport },
    },
  } = useAppContext();

  const {
    isSubmitting,
    isClientCreateModalOpen,
    setIsClientCreateModalOpen,
    id,
    form,
    onSubmit,
  } = useAddPortfolioModal({ setOpen });

  return (
    <GKModal
      modalTitle="Create Portfolio"
      open={open}
      setOpen={setOpen}
      titleComponent={
        showClientButton && (
          <GKButton
            variant="contained"
            size="medium"
            onClick={() => setIsClientCreateModalOpen(true)}
            startIcon={<CgMathPlus size={18} />}
          >
            Add Client
          </GKButton>
        )
      }
      footer={
        <LoadingButton
          variant="contained"
          type="submit"
          size="medium"
          form="add-portfolio-form"
          loading={isSubmitting}
        >
          Create Portfolio
        </LoadingButton>
      }
    >
      <>
        <form id="add-portfolio-form" onSubmit={form.handleSubmit(onSubmit)}>
          <Grid2 container spacing={1}>
            {getActiveBrokers(brokerSupport).length > 1 && (
              <Grid2 size={6}>
                <Controller
                  name="broker"
                  control={form.control}
                  rules={{
                    required: {
                      value: true,
                      message: "Please select a broker",
                    },
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <GKSearchSelect
                      disableClearable
                      inputLabel="Select Broker"
                      requiredField
                      options={getActiveBrokers(brokerSupport) || []}
                      value={
                        getActiveBrokers(brokerSupport).find(
                          (dataItem) => dataItem.value === field.value,
                        )?.name || ""
                      }
                      onChange={(e, val) => field.onChange(val?.value)}
                      getOptionLabel={(option) => option.name || option}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder={handleReturnAutocompletePlaceholder({
                            placeholder: "Select Broker",
                            value: field.value,
                          })}
                          error={!!error}
                          helperText={error?.message}
                        />
                      )}
                    />
                  )}
                />
              </Grid2>
            )}

            <Grid2 size={6}>
              <Controller
                name="name"
                control={form.control}
                rules={{
                  required: {
                    value: true,
                    message: "Please enter name",
                  },
                  validate: validateNotWhitespaceOnly,
                }}
                render={({ field, fieldState: { error } }) => {
                  return (
                    <GKTextField
                      {...field}
                      inputLabel="Portfolio Name"
                      placeholder="Portfolio Name"
                      requiredField
                      error={!!error}
                      helperText={error?.message}
                    />
                  );
                }}
              />
            </Grid2>
            <Grid2 size={6}>
              <Controller
                name="portfolioId"
                control={form.control}
                rules={{
                  required: {
                    value: true,
                    message: "Please enter portfolio code",
                  },
                  validate: validateNotWhitespaceOnly,
                }}
                render={({ field, fieldState: { error } }) => (
                  <GKTextField
                    {...field}
                    requiredField
                    onChange={(event) => {
                      field.onChange(event.target.value.trim());
                    }}
                    inputLabel="Portfolio Code"
                    placeholder="like UCC of Demat A/c"
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              />
            </Grid2>
            <Grid2 size={6}>
              {id ? (
                <Controller
                  name="name"
                  control={form.control}
                  render={({ field }) => (
                    <GKTextField
                      {...field}
                      name={"name"}
                      disabled
                      inputLabel="Client"
                      placeholder="Client"
                      requiredField
                      value={
                        clientList?.find((data) => data?.value === Number(id))
                          ?.name
                      }
                    />
                  )}
                />
              ) : (
                <Controller
                  name="client"
                  control={form.control}
                  rules={{
                    required: {
                      value: true,
                      message: "Please select a client",
                    },
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <GKSearchSelect
                      disableClearable
                      inputLabel="Client"
                      requiredField
                      options={clientList || []}
                      onChange={(e, val) => {
                        field.onChange(val?.value);
                      }}
                      getOptionLabel={(option) => option.name || option}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="client"
                          placeholder={handleReturnAutocompletePlaceholder({
                            placeholder: "Select Client",
                            value: field.value,
                          })}
                          error={!!error}
                          helperText={error?.message}
                        />
                      )}
                    />
                  )}
                />
              )}
            </Grid2>
            <Grid2 size={6}>
              <Controller
                name="partners"
                control={form.control}
                render={({ field }) => (
                  <GKSearchSelect
                    disableClearable={false}
                    disableCloseOnSelect
                    inputLabel="Partner"
                    options={partnerList || []}
                    onChange={(e, val) => {
                      // const selectedIds = val.map(
                      //   (option: { value: string | number }) => option.value,
                      // );
                      field.onChange([val?.value]);
                    }}
                    getOptionLabel={(option) => option.name || option}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder={handleReturnAutocompletePlaceholder({
                          placeholder: "Select Partner",
                          value: field.value,
                        })}
                      />
                    )}
                  />
                )}
              />
            </Grid2>
          </Grid2>
        </form>

        {isClientCreateModalOpen && (
          <ClientModal
            open={isClientCreateModalOpen}
            setOpen={setIsClientCreateModalOpen}
          />
        )}
      </>
    </GKModal>
  );
};

export default AddPortfolioModal;
