import { LoadingButton } from "@mui/lab";
import { GridColDef } from "@mui/x-data-grid-pro";
import { ConfirmDeleteModal, GKDataGrid, StatusField } from "components";
import useGenerateQueryParams from "hooks/useGenerateQueryParams";
import { useFetchQuery, useMutation } from "hooks/useQueries";
import DashboardLayout from "layouts/DashboardLayout";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { moneyCommaSeparator } from "utils";
import axiosInstance from "utils/axiosInstance";
import { extraColumnsField, matchDate } from "utils/helpers";
import useDocumentTitle from "utils/useDocumentTitle";

const SubscriptionPaymentHistory = () => {
  useDocumentTitle("Payment History");

  const { id } = useParams();
  const navigate = useNavigate();

  const [confirmationModal, setConfirmationModal] = useState(false);

  const requestBody = useGenerateQueryParams("subscribedPaymentHistory");

  const { data, isFetching } = useFetchQuery({
    key: ["GET_ALL_SUBSCRIBED_PAYMENT_HISTORY", requestBody],
    route: `/subscription-history/${id}/payment-history/`,
    requestBody,
  });

  const { mutate, isLoading } = useMutation({
    mutationKey: ["CANCEL_SUBSCRIPTION"],
    mutationFn: (id: string) =>
      axiosInstance.post(`/subscription-history/${id}/cancel/`),
    onSuccess: () => {
      navigate(-1);
    },
  });

  const columns: GridColDef[] = [
    {
      ...extraColumnsField("Payment Date", "paymentDate", 100, 1),
      type: "date",
      valueFormatter: (params) => matchDate(params?.value),
    },
    {
      ...extraColumnsField("Amount", "paymentAmount", 150, 1),
      valueGetter: (row) => row?.row?.paymentAmount,
      valueFormatter: (params) => moneyCommaSeparator(params.value || 0),
      type: "number",
    },
    {
      ...extraColumnsField("Status", "paymentStatus", 100, 1),
      renderCell: (row) => (
        <StatusField statusString={row?.row?.paymentStatus} />
      ),
    },
    {
      ...extraColumnsField("Failure Details", "failureDetails", 150, 1),
      valueGetter: (row) => row?.row?.failureDetails || "-",
    },
    {
      ...extraColumnsField("Remark", "remark", 100, 1),
      valueGetter: (row) => row?.row?.remark || "-",
    },
  ];

  return (
    <>
      <DashboardLayout title="Payment History">
        <GKDataGrid
          loading={isFetching}
          columns={columns}
          rows={data?.data?.data || []}
          maxHeight={"calc(100vh - 321px)"}
          name="subscribedPaymentHistory"
          columnsButton={false}
          totalCount={data?.data?.totalCount}
          headerComponent={
            <LoadingButton
              variant="outlined"
              color="dark"
              loading={isLoading}
              onClick={() => setConfirmationModal(true)}
            >
              Cancel Subscription
            </LoadingButton>
          }
        />
      </DashboardLayout>

      {confirmationModal && (
        <ConfirmDeleteModal
          open={confirmationModal}
          setOpen={setConfirmationModal}
          onDelete={() => mutate(id)}
          title={"Cancel Subscription"}
          messageContent={"Are you sure you want to cancel this subscription?"}
          customButtonText="Cancel Subscription"
        />
      )}
    </>
  );
};

export default SubscriptionPaymentHistory;
