import React, {
  ReactNode,
  createContext,
  useContext,
  useMemo,
  useReducer,
} from "react";

interface State {
  token: string;
  familyList: any[];
  countryList: [];
  stateList: [];
  cityList: [];
  selectedCountry: number;
  selectedState: number;
  partnerList: any[];
  companies: any[];
  isExistToken: boolean;
}

interface Action {
  type: string;
  data?: any;
}

interface InvestorContextType {
  state: State;
  dispatch: React.Dispatch<Action>;
}

const initialState: State = {
  token: "",
  familyList: [],
  countryList: [],
  stateList: [],
  cityList: [],
  selectedCountry: null,
  selectedState: null,
  partnerList: [],
  companies: [],
  isExistToken: false,
};

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "TOKEN":
      return { ...state, token: action.data };
    case "FAMILY_LIST":
      return { ...state, familyList: action.data };
    case "COUNTRY_LIST":
      return { ...state, countryList: action.data };
    case "STATE_LIST":
      return { ...state, stateList: action.data };
    case "CITY_LIST":
      return { ...state, cityList: action.data };
    case "SELECTED_COUNTRY":
      return { ...state, selectedCountry: action.data };
    case "SELECTED_STATE":
      return { ...state, selectedState: action.data };
    case "PARTNER_LIST":
      return { ...state, partnerList: action.data };
    case "COMPANIES":
      return { ...state, companies: action.data };
    case "IS_EXIST_TOKEN":
      return { ...state, isExistToken: action.data };
    default:
      return { ...state };
  }
};

const InvestorContext = createContext<InvestorContextType | undefined>(
  undefined,
);

interface InvestorContextProviderProps {
  children: ReactNode;
}

const InvestorContextProvider: React.FC<InvestorContextProviderProps> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const value = useMemo(() => ({ state, dispatch }), [state, dispatch]);

  return (
    <InvestorContext.Provider value={value}>
      {children}
    </InvestorContext.Provider>
  );
};

const useInvestorContext = (): InvestorContextType => {
  const context = useContext(InvestorContext);
  if (!context) {
    throw new Error(
      "useInvestorContext must be used within an InvestorContextProvider",
    );
  }
  return context;
};

export { InvestorContextProvider, useInvestorContext };
