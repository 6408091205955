import { LoadingButton } from "@mui/lab";
import { Button, DragDrop, GKModal, Grid2 } from "components";
import { useMutation } from "hooks/useQueries";
import React from "react";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import axiosInstance from "utils/axiosInstance";
import { GKModalProps } from "utils/commonInterface";
import { generateUUID } from "utils/helpers";
import { v4 as uuid } from "uuid";

interface Props {
  form: any;
}

const VersionUploadModal = ({ open, setOpen, form }: Props & GKModalProps) => {
  const { id } = useParams();

  const [uploadError, setUploadError] = React.useState<string | null>(null);

  const [files, setFiles] = React.useState([]);

  const { mutate, isLoading } = useMutation({
    mutationFn: ({ modelId, file }: { modelId: any; file: any }) =>
      axiosInstance.post(`model_portfolio/${modelId}/upload/`, file),
    onSuccess: (response: any) => {
      const data = response?.data?.map((item: any, index: number) => {
        return {
          ...item,
          id: `${generateUUID(10)}${index}`,
        };
      });
      form?.setValue(
        "stocks",
        data?.map((item: any) => {
          return {
            ...item,
            uuid: uuid(),
          };
        }),
      );
      setOpen(false);
    },
  });

  const { mutate: handleDownloadSample } = useMutation({
    mutationFn: (data) => axiosInstance.get(`/model_portfolio/${data}/`),
    onSuccess: (response: any) => {
      try {
        const blobData = new Blob([response.data], {
          type: response.headers["content-type"],
        });
        const url = window.URL.createObjectURL(blobData);
        const a = document.createElement("a");
        a.href = url;
        a.download = "SampleVersionUpload.csv";
        a.click();
        window.URL.revokeObjectURL(url);
      } catch (error) {
        toast.error("An error occurred while processing the file.");
      }
    },
  });

  return (
    <GKModal
      open={open}
      setOpen={setOpen}
      modalTitle="Upload Model"
      titleComponent={
        <Button
          variant="outlined"
          size="medium"
          onClick={() => handleDownloadSample("export_sample_version_csv")}
        >
          Download Sample File
        </Button>
      }
      footer={
        <LoadingButton
          variant="contained"
          size="medium"
          disabled={isLoading}
          onClick={() => {
            const appendedFiles = new FormData();
            files.forEach((file) => {
              appendedFiles.append("file", file);
            });
            mutate({ file: appendedFiles, modelId: id });
          }}
          loading={isLoading}
        >
          {"Submit"}
        </LoadingButton>
      }
    >
      <Grid2 container>
        <Grid2 size={12}>
          <DragDrop
            value={files}
            onChange={setFiles}
            acceptType={".csv"}
            uploadError={uploadError}
            setUploadError={setUploadError}
          />
        </Grid2>
      </Grid2>
    </GKModal>
  );
};

export default VersionUploadModal;
