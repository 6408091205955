/* eslint-disable no-prototype-builtins */
import {
  Box,
  BsPatchCheck,
  Divider,
  GKButton,
  Grid2,
  StatusField,
  Typography,
} from "components";
import { useMutation } from "hooks/useQueries";
import { useFormContext } from "react-hook-form";
import axiosInstance from "utils/axiosInstance";
import { refetchQuery } from "utils/helpers";
import { ThemeContainer } from "utils/Theme";
import { useInvestorContext } from "./InvestorContext";

declare global {
  interface Window {
    Digio: any;
  }
}

interface Props {
  handleNext: () => void;
  logo: string;
}

const KYCTab = (props: Props) => {
  const { theme } = ThemeContainer();

  const form = useFormContext();

  const {
    state: { token },
  } = useInvestorContext();

  const options = {
    environment: "production",
    callback: (response: any) => {
      if (response.hasOwnProperty("error_code")) {
        console.log(response);
      } else {
        props.handleNext();
        refetchQuery("GET_CLIENT_FOR_CREATION");
      }
    },
    logo: props?.logo || "https://www.mylogourl.com/image.jpeg",
    theme: {
      primaryColor: theme.palette.primary.main,
      secondaryColor: theme.palette.secondary.main,
    },
  };

  const digio = window.Digio && new window.Digio(options);

  const { mutate: requestKYC } = useMutation({
    mutationKey: ["REQUEST_KYC"],
    mutationFn: ({
      clientId,
      pan,
      dob,
    }: {
      clientId: any;
      pan: any;
      dob: any;
    }) =>
      axiosInstance.post(
        `/client-basket/${clientId}/onboard-kyc/`,
        {
          client: clientId,
          pan: pan,
          dob: dob,
        },
        {
          headers: {
            Authorization: `Token ${token}`,
            "x-gridkey-user-role": "client",
          },
        },
      ),
    onSuccess: (response) => {
      digio.init();
      digio.submit(
        response?.data?.data?.kycId,
        response?.data?.data?.identifier,
      );
    },
  });

  const completeKyc = form.getValues("clientObject.kycDetails.kycId");

  return (
    <Grid2 container spacing={2}>
      <Grid2 size={12}>
        <Typography
          fontWeight={600}
          pt={2}
          sx={{ fontSize: "clamp(1.125rem, 0.6563rem + 1.5vw, 1.5rem)" }}
        >
          Complete your KYC
        </Typography>
      </Grid2>
      {completeKyc ? (
        <Grid2
          spacing={2}
          container
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          width={{ md: "60%", xs: "100%" }}
          m={"auto"}
        >
          <Grid2 size={12} textAlign={"center"} p={2}>
            <img
              src="/Investor/KYCCompleteLogo.svg"
              alt="KYC"
              width={"56px"}
              height={"auto"}
            />
            <Typography fontSize={26} fontWeight={600} textAlign={"center"}>
              KYC completed
            </Typography>
          </Grid2>
          <Grid2 size={12}>
            <Box
              sx={{
                p: 2,
                background: "#F8FAFC",
                borderRadius: 2,
              }}
            >
              <Grid2 container spacing={2}>
                <Grid2
                  size={12}
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  <Typography fontWeight={600} fontSize={20}>
                    {form.getValues("clientObject.kycDetails.name")}
                  </Typography>
                  <StatusField statusString={"completed"} />
                </Grid2>
                <Grid2
                  container
                  size={12}
                  bgcolor={theme.palette.light.main}
                  p={1}
                >
                  <Grid2 size={{ md: 6, xs: 12 }}>
                    <Typography color={theme.palette.secondaryText.main}>
                      PAN
                    </Typography>
                    <Typography fontWeight={600}>
                      {form.getValues("clientObject.kycDetails.pan")}
                    </Typography>
                  </Grid2>
                  <Grid2 size={{ md: 6, xs: 12 }}>
                    <Typography color={theme.palette.secondaryText.main}>
                      KYC ID
                    </Typography>
                    <Typography
                      fontWeight={600}
                      sx={{ wordBreak: "break-all" }}
                    >
                      {form.getValues("clientObject.kycDetails.kycId")}
                    </Typography>
                  </Grid2>
                </Grid2>
              </Grid2>
            </Box>
          </Grid2>
        </Grid2>
      ) : (
        <Grid2
          size={{ xs: 12, md: 4 }}
          sx={{
            m: {
              xs: "",
              md: "auto",
            },
          }}
          display={"flex"}
          justifyContent={"center"}
          flexDirection={"column"}
          border={"1px solid #E3E8EF"}
          borderRadius={2}
        >
          <Grid2
            size={12}
            container
            spacing={1}
            sx={{
              p: {
                xs: 2,
                md: 5,
              },
            }}
            bgcolor={"#F8FAFC"}
          >
            <Grid2 size={12} display={"flex"} justifyContent={"center"}>
              <div
                style={{
                  borderRadius: "16px",
                  border: "1px solid #E3E8EF",
                  background: theme.palette.light.main,
                  padding: "16px",
                }}
              >
                <BsPatchCheck size={30} color={theme.palette.success.main} />
              </div>
            </Grid2>
            <Grid2 size={12} mt={2}>
              <Typography
                fontWeight={600}
                textAlign={"center"}
                sx={{ fontSize: "clamp(1.125rem, 0.8125rem + 1vw, 1.375rem)" }}
              >
                Complete your KYC in minutes
              </Typography>
            </Grid2>
            <Grid2 size={12}>
              <Typography
                fontSize={16}
                fontWeight={500}
                textAlign={"center"}
                color="#9AA4B2"
              >
                On Clicking <span style={{ color: "#0D121C" }}>Start KYC</span>,
                and you&apos;ll be redirected to Digio to complete the process.
              </Typography>
            </Grid2>
            <Grid2 size={12} mt={1}>
              <GKButton
                variant="contained"
                fullWidth
                onClick={() =>
                  requestKYC({
                    clientId: form.getValues("clientObject.id"),
                    pan: form.getValues("clientObject.personalDetails.pan"),
                    dob: form.getValues(
                      "clientObject.personalDetails.dateOfBirth",
                    ),
                  })
                }
              >
                Start KYC
              </GKButton>
            </Grid2>
          </Grid2>
          <Grid2 size={12}>
            <Divider />
          </Grid2>
          <Grid2 size={12} container spacing={1} sx={{ p: { xs: 2, md: 5 } }}>
            <Grid2 size={12}>
              <Typography
                fontSize={16}
                fontWeight={500}
                color="#4B5565"
                display={"flex"}
              >
                What you’ll need to do
              </Typography>
            </Grid2>
            <Grid2 size={12}>
              <Typography
                fontSize={14}
                fontWeight={500}
                color="#9AA4B2"
                display={"flex"}
              >
                <li>Upload your PAN card</li>
              </Typography>
            </Grid2>
            <Grid2 size={12}>
              <Typography
                fontSize={14}
                fontWeight={500}
                color="#9AA4B2"
                display={"flex"}
              >
                <li>Provide address proof (Aadhaar/Voter ID)</li>
              </Typography>
            </Grid2>
            <Grid2 size={12}>
              <Typography
                fontSize={14}
                fontWeight={500}
                color="#9AA4B2"
                display={"flex"}
              >
                <li>Capture a clear photo of your face</li>
              </Typography>
            </Grid2>
            <Grid2 size={12}>
              <Typography
                fontSize={14}
                fontWeight={500}
                color="#9AA4B2"
                display={"flex"}
              >
                <li>Sign on white paper & upload</li>
              </Typography>
            </Grid2>
            <Grid2 size={12}>
              <Typography
                fontSize={14}
                fontWeight={500}
                color="#9AA4B2"
                display={"flex"}
              >
                <li>Agree to FATCA & CRS declaration</li>
              </Typography>
            </Grid2>
            <Grid2 size={12}>
              <Typography
                fontSize={14}
                fontWeight={500}
                color="#9AA4B2"
                display={"flex"}
              >
                <li>Complete a video verification</li>
              </Typography>
            </Grid2>
          </Grid2>
        </Grid2>
      )}
    </Grid2>
  );
};

export default KYCTab;
