import {
  GKButton,
  GKDatePicker,
  GKModal,
  GKTextField,
  Grid2,
  PhoneInput,
} from "components";
import dayjs from "dayjs";
import { useFetchQuery, useMutation } from "hooks/useQueries";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { GET_EMPLOYEE_BY_ID } from "utils/apiRoutes";
import axiosInstance from "utils/axiosInstance";
import { GKModalProps } from "utils/commonInterface";
import { refetchQuery } from "utils/helpers";
import { QueryKeys } from "utils/queryKey";
import { emailRegex, validateNotWhitespaceOnly } from "utils/validationSchema";

const EditAdminModal = ({ open, setOpen }: GKModalProps) => {
  const { id } = useParams<string>();

  const [hasError, setHasError] = useState(false);

  const { data } = useFetchQuery({
    key: ["GET_EMPLOYEE", id],
    enabled: Boolean(id),
    route: GET_EMPLOYEE_BY_ID(id),
  });

  const { mutate: submitHandler, isLoading: isSubmitting } = useMutation({
    mutationKey: ["UPDATE_EMPLOYEE"],
    mutationFn: (data: any) => axiosInstance.patch(`/employees/${id}/`, data),
    onSuccess: () => {
      setOpen(false);
      refetchQuery(QueryKeys.EMPLOYEE_LIST);
    },
  });

  const form = useForm({
    defaultValues: {
      position: "",
      salary: "",
      user: {
        phone: "",
        countryCode: "",
        personalDetails: {
          name: "",
          email: "",
          aadhar: "",
          dateOfBirth: "",
          emergencyContact: "",
          emergencyContactNum: "",
        },
      },
    },
    values: data?.data,
  });

  form.watch();

  return (
    <GKModal
      open={open}
      setOpen={setOpen}
      modalTitle="Admin Profile Details"
      footer={
        <GKButton
          color="primary"
          disabled={isSubmitting || hasError}
          variant="contained"
          size="medium"
          type="submit"
          form="admin-profile-details-form"
        >
          Save
        </GKButton>
      }
    >
      <form
        id="admin-profile-details-form"
        onSubmit={form.handleSubmit((values) => submitHandler(values))}
      >
        <Grid2 container spacing={2}>
          <Grid2 size={4}>
            <Controller
              name="user.personalDetails.name"
              control={form.control}
              rules={{
                required: {
                  value: true,
                  message: "Please enter name",
                },
                validate: validateNotWhitespaceOnly,
              }}
              render={({ field, fieldState: { error } }) => (
                <GKTextField
                  {...field}
                  inputLabel="Name"
                  requiredField
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
          </Grid2>
          <Grid2 size={4}>
            <Controller
              name="user.personalDetails.email"
              control={form.control}
              rules={{
                required: {
                  value: true,
                  message: "Please enter Email",
                },
                pattern: {
                  value: emailRegex,
                  message: "Please enter a valid email",
                },
              }}
              render={({ field }) => (
                <GKTextField
                  {...field}
                  inputLabel="Email"
                  type="email"
                  requiredField
                />
              )}
            />
          </Grid2>
          <Grid2 size={4}>
            <Controller
              name="user.phone"
              control={form.control}
              rules={{
                required: {
                  value: true,
                  message: "Please enter a valid Phone Number",
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <PhoneInput
                  {...field}
                  requiredField
                  onSelectChange={(event: any, val: any) => {
                    form.setValue("user.countryCode", val?.callingCodes);
                  }}
                  onTextChange={(event: any) => {
                    field.onChange(event?.target?.value);
                  }}
                  onTextValue={field.value}
                  onSelectValue={form.getValues("user.countryCode")}
                  error={!!error || hasError}
                  helperText={error?.message}
                  hasError={hasError}
                  setHasError={setHasError}
                />
              )}
            />
          </Grid2>
          <Grid2 size={4}>
            <Controller
              name="position"
              control={form.control}
              render={({ field }) => (
                <GKTextField {...field} inputLabel="Position" />
              )}
            />
          </Grid2>
          <Grid2 size={4}>
            <Controller
              name="salary"
              control={form.control}
              render={({ field }) => (
                <GKTextField {...field} type="number" inputLabel="Salary" />
              )}
            />
          </Grid2>
          <Grid2 size={4}>
            <Controller
              name="user.personalDetails.aadhar"
              control={form.control}
              render={({ field }) => (
                <GKTextField
                  {...field}
                  inputLabel="Aadhar Card"
                  type="number"
                />
              )}
            />
          </Grid2>
          <Grid2 size={4}>
            <Controller
              name="user.personalDetails.dateOfBirth"
              control={form.control}
              render={({ field }) => (
                <GKDatePicker
                  {...field}
                  inputLabel="Date of Birth"
                  value={dayjs(field.value)}
                  disableFuture
                  onChange={(value) => {
                    field.onChange(dayjs(value).format("YYYY-MM-DD"));
                  }}
                  minDate={dayjs("01-01-1901")}
                  slotProps={{
                    textField: {
                      error: false,
                      helperText: false,
                    },
                  }}
                />
              )}
            />
          </Grid2>
          <Grid2 size={4}>
            <Controller
              name="user.personalDetails.emergencyContact"
              control={form.control}
              render={({ field }) => (
                <GKTextField {...field} inputLabel="Emergency Contact Name" />
              )}
            />
          </Grid2>
          <Grid2 size={4}>
            <Controller
              name="user.personalDetails.emergencyContactNum"
              control={form.control}
              render={({ field }) => (
                <GKTextField
                  {...field}
                  type="tel"
                  inputLabel="Emergency Contact Number"
                />
              )}
            />
          </Grid2>
        </Grid2>
      </form>
    </GKModal>
  );
};

export default EditAdminModal;
