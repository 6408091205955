import { LoadingButton } from "@mui/lab";
import {
  Button,
  ConfirmDeleteModal,
  Divider,
  FiTrash2,
  FormControl,
  FormControlLabel,
  GKClientDataGrid,
  GKDatePicker,
  GKSearchSelect,
  GKTextField,
  Grid2,
  IconButton,
  Radio,
  RadioGroup,
  Show,
  TextField,
  Typography,
} from "components";
import dayjs from "dayjs";
import { motion } from "framer-motion";
import DashboardLayout from "layouts/DashboardLayout";
import { sum } from "lodash";
import React from "react";
import { Controller } from "react-hook-form";
import { FiRefreshCw } from "react-icons/fi";
import { handleReturnNaturalRows } from "utils/formHelpers";
import { validateNotWhitespaceOnly } from "utils/validationSchema";
import { v4 as uuid } from "uuid";
import VersionUploadModal from "./VersionUploadModal";
import useModelPortfolioVersion from "./useModelPortfolioVersion";

const AddModelportfolioVersionModal = () => {
  const {
    handleAddSubmit,
    insert,
    theme,
    openUploadModal,
    setOpenUploadModal,
    submitLoading,
    form,
    setTabs,
    handleUpdateVersion,
    isLoading,
    editModelPortfolioVersion,
    setModelPortfolioVersion,
    getDataByIdLoading,
    modalPortfolioDelete,
    modelPortdolioData,
    modelPortfolioId,
    replace,
    equityBasketDetailsData,
    companies,
  } = useModelPortfolioVersion();

  const [deleteConfirmation, setDeleteConfirmation] = React.useState(false);

  return (
    <DashboardLayout title="Add Model Portfolio" loading={getDataByIdLoading}>
      <Grid2 container spacing={1}>
        <Grid2 size={12}>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            <form
              id="equity-basket-version-form"
              onSubmit={
                modelPortfolioId
                  ? form?.handleSubmit(handleUpdateVersion)
                  : form?.handleSubmit(handleAddSubmit)
              }
            >
              <Grid2 container spacing={1} size={12}>
                <Grid2 size={2}>
                  <Controller
                    rules={{
                      required: {
                        value: true,
                        message: "Please enter version name",
                      },
                      validate: validateNotWhitespaceOnly,
                    }}
                    control={form.control}
                    name="versionName"
                    render={({ field }) => (
                      <GKTextField
                        {...field}
                        inputLabel="Model Portfolio Name"
                        placeholder="Model Portfolio Name"
                        name="versionName"
                        error={Boolean(form.formState.errors?.versionName)}
                        helperText={
                          Boolean(form.formState.errors?.versionName) &&
                          `${form.formState.errors?.versionName?.message}`
                        }
                      />
                    )}
                  />
                </Grid2>
                <Grid2 size={2}>
                  <Controller
                    control={form.control}
                    name="date"
                    render={({ field }) => (
                      <GKDatePicker
                        {...field}
                        onChange={(e) => {
                          field.onChange(dayjs(e).format("YYYY-MM-DD"));
                        }}
                        value={dayjs(field.value)}
                        inputLabel="Model Portfolio start date"
                      />
                    )}
                  />
                </Grid2>
                <Grid2 size={2}>
                  <Controller
                    control={form.control}
                    disabled={modelPortdolioData?.data?.data?.isARebalance}
                    name="startValue"
                    rules={{
                      min: 1,
                      required: {
                        value: true,
                        message: "Portfolio value should be greater than 0",
                      },
                    }}
                    render={({ field }) => (
                      <GKTextField
                        {...field}
                        disabled={modelPortdolioData?.data?.data?.isARebalance}
                        inputLabel="Model Portfolio Value"
                        placeholder="Model Portfolio Value"
                        error={Boolean(form.formState.errors?.startValue)}
                        helperText={
                          Boolean(form.formState.errors?.startValue) &&
                          `${form.formState.errors?.startValue?.message}`
                        }
                      />
                    )}
                  />
                </Grid2>
                <Grid2 size={2}>
                  <Controller
                    disabled={
                      equityBasketDetailsData?.data?.minimumInvestmentValueCheck
                    }
                    control={form.control}
                    name="minimumInvestement"
                    render={({ field }) => (
                      <GKTextField
                        {...field}
                        onChange={(e: any) => {
                          field.onChange(+e.target.value);
                        }}
                        inputLabel="Minimum Invested Value"
                        placeholder="Minimum Invested Value"
                      />
                    )}
                  />
                </Grid2>
                <Show.When isTrue={editModelPortfolioVersion}>
                  <Grid2 ml={"auto"} mt={"auto"} mb={"auto"}>
                    <Button
                      variant="contained"
                      onClick={() => {
                        setOpenUploadModal(true);
                        setTabs(0);
                      }}
                    >
                      Upload Model
                    </Button>
                  </Grid2>
                </Show.When>
                <Grid2 size={12}>
                  <Divider />
                </Grid2>
                <Grid2 size={12} display={"flex"} alignItems={"center"} gap={1}>
                  <Typography>Weightage Type</Typography>
                  <Controller
                    control={form.control}
                    name="equalWeightage"
                    render={({ field }) => {
                      return (
                        <FormControl
                          sx={{
                            "& .MuiFormGroup-root.MuiRadioGroup-root": {
                              display: "flex",
                              alignItems: "center",
                              flexDirection: "row",
                            },
                          }}
                        >
                          <RadioGroup
                            {...field}
                            onChange={(eve, val) => {
                              field.onChange(val);
                              const equalAllocation =
                                100 / form.getValues("stocks")?.length;
                              const newData = form
                                .getValues("stocks")
                                ?.map((da) => {
                                  return {
                                    ...da,
                                    allocation: JSON.parse(val)
                                      ? equalAllocation
                                      : 0,
                                    weightage: JSON.parse(val)
                                      ? equalAllocation
                                      : 0,
                                  };
                                });

                              form.setValue("stocks", newData);
                            }}
                          >
                            <FormControlLabel
                              value={false}
                              control={<Radio />}
                              label="Custom Weighted"
                            />
                            <FormControlLabel
                              value
                              control={<Radio />}
                              label="Equal Weighted"
                            />
                          </RadioGroup>
                        </FormControl>
                      );
                    }}
                  />
                </Grid2>
                <Grid2 size={12}>
                  <GKClientDataGrid
                    maxHeight={"4000vh"}
                    name="addModelPortfoliol"
                    getRowId={(row) => row.uuid}
                    disableRowSelectionOnClick
                    rows={
                      form.getValues("stocks")?.map((d, index) => {
                        return {
                          ...d,
                          uuidIndex: index,
                        };
                      }) || []
                    }
                    columns={[
                      {
                        minWidth: 500,
                        field: "stock",
                        disableColumnMenu: true,
                        valueGetter: (params) => {
                          return params?.row?.companyName || "";
                        },
                        renderCell: (params) => {
                          const index = params.row.uuidIndex;
                          return (
                            <Controller
                              rules={{
                                required: true,
                              }}
                              control={form.control}
                              name={`stocks.${index}.companyName`}
                              render={({ field, fieldState: { error } }) => {
                                return (
                                  <GKSearchSelect
                                    {...field}
                                    size="small"
                                    disableClearable={false}
                                    sx={{
                                      width: "100%",
                                    }}
                                    options={
                                      handleReturnNaturalRows({
                                        options: companies?.map(
                                          (item: any) => ({
                                            ...item,
                                            name: item?.companyName,
                                            value: item?.isin,
                                            gridCode: item?.gridCode,
                                          }),
                                        ),
                                        values: form.getValues("stocks"),
                                        selectedValueKey: "isinCode",
                                        optionsValueKey: "isin",
                                      }) || []
                                    }
                                    getOptionLabel={(option) =>
                                      option.name || option
                                    }
                                    renderOption={(props, option) => (
                                      <li
                                        {...props}
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          gap: 4,
                                        }}
                                      >
                                        <Typography
                                          color={theme.palette.light.main}
                                          fontSize={8}
                                          style={{
                                            background:
                                              option?.instType === "EQ"
                                                ? theme.palette.secondary.main
                                                : theme.palette.warning.main,
                                            padding: "2px 4px",
                                            borderRadius: 4,
                                          }}
                                        >
                                          {option?.instType}
                                        </Typography>
                                        <Typography whiteSpace={"nowrap"}>
                                          {option?.name}
                                        </Typography>
                                      </li>
                                    )}
                                    onChange={(e, val) => {
                                      form.setValue(
                                        `stocks.${index}.isinCode`,
                                        val?.value,
                                      );
                                      form.setValue(
                                        `stocks.${index}.cap`,
                                        val?.cap,
                                      );
                                      form.setValue(
                                        `stocks.${index}.gridCode`,
                                        val?.gridCode,
                                      );
                                      form.setValue(
                                        `stocks.${index}.companyName`,
                                        val?.name,
                                      );
                                      form.setValue(
                                        `stocks.${index}.sector`,
                                        val?.sector,
                                      );
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        onKeyDown={(e) => e.stopPropagation()}
                                        error={!!error}
                                      />
                                    )}
                                  />
                                );
                              }}
                            />
                          );
                        },
                      },
                      {
                        minWidth: 200,
                        disableColumnMenu: true,
                        field: "weightage",
                        headerName: `Weightage`,
                        type: "number",
                        valueGetter: (params) => {
                          return params?.row?.allocation
                            ? +params?.row?.allocation
                            : 0;
                        },
                        renderCell: (params) => {
                          const index = params.row.uuidIndex;

                          return (
                            <Controller
                              control={form.control}
                              name={`stocks.${index}.allocation`}
                              rules={{
                                required: true,
                              }}
                              render={({ field, fieldState: { error } }) => {
                                return form.getValues("equalWeightage") ===
                                  "true" ? (
                                  <Typography>
                                    {form
                                      ?.getValues(`stocks.${index}.allocation`)
                                      ?.toFixed(2)}
                                  </Typography>
                                ) : (
                                  <GKTextField
                                    {...field}
                                    size="small"
                                    name={`stocks.${index}.allocation`}
                                    type="number"
                                    value={form.getValues(
                                      `stocks.${index}.allocation`,
                                    )}
                                    placeholder="Weightage"
                                    onChange={(e) => {
                                      form.setValue(`stocks.${index}`, {
                                        ...form.getValues(`stocks.${index}`),
                                        allocation: +e.target.value,
                                        weightage:
                                          (+form.getValues("startValue") /
                                            +e.target.value) *
                                          100,
                                      });
                                    }}
                                    error={!!error}
                                  />
                                );
                              }}
                            />
                          );
                        },
                      },

                      {
                        field: "action",
                        headerName: "Actions",
                        disableColumnMenu: true,
                        align: "right",
                        headerAlign: "right",
                        sortable: false,
                        renderCell: (params) => {
                          const index = params.row.uuidIndex;
                          return (
                            <IconButton
                              disabled={form.getValues("stocks").length === 1}
                              onClick={() => {
                                const equalAllocation =
                                  100 /
                                  form
                                    .getValues("stocks")
                                    ?.filter((d, i) => i !== index)?.length;

                                const updatedData = JSON.parse(
                                  form.getValues("equalWeightage"),
                                )
                                  ? form
                                      .getValues("stocks")
                                      ?.filter((d, i) => i !== index)
                                      ?.map((da) => {
                                        return {
                                          ...da,
                                          allocation: equalAllocation,
                                          weightage: equalAllocation,
                                        };
                                      })
                                  : form
                                      .getValues("stocks")
                                      ?.filter((d, i) => i !== index);

                                form.setValue("stocks", updatedData);
                              }}
                              color="error"
                              className="outline"
                            >
                              <FiTrash2 size={18} />
                            </IconButton>
                          );
                        },
                      },
                    ]}
                    headerComponent={
                      <Grid2
                        container
                        justifyContent={"flex-end"}
                        alignItems={"center"}
                        gap={1}
                      >
                        <Typography
                          fontSize={12}
                          color={theme.palette.grey[600]}
                        >
                          Total Weightage:{"  "}
                          <span style={{ fontWeight: 600 }}>
                            {sum(
                              form
                                .getValues("stocks")
                                ?.map((d) => d?.allocation),
                            )}
                          </span>
                        </Typography>
                        <IconButton
                          onClick={() => {
                            form.trigger();
                          }}
                          sx={{
                            background: theme.palette.primary.main,
                            color: theme.palette.light.main,
                          }}
                        >
                          <FiRefreshCw size={14} />
                        </IconButton>
                        <Button
                          onClick={() => {
                            insert(0, {
                              isinCode: "",
                              allocation: null,
                              targetPrice: null,
                              stopLoss: null,
                              weightage: null,
                              rationale: "",
                              sector: "",
                              gridCode: "",
                              cap: "",
                              companyName: "",
                              uuid: uuid(),
                            });
                            if (JSON.parse(form.getValues("equalWeightage"))) {
                              const equalAllocation =
                                100 / form.getValues("stocks")?.length;
                              const newData = form
                                .getValues("stocks")
                                ?.map((da) => {
                                  return {
                                    ...da,
                                    allocation: equalAllocation,
                                    weightage: equalAllocation,
                                  };
                                });
                              replace(newData);
                            }
                          }}
                          variant="lightBg"
                          size="small"
                        >
                          Add Stock
                        </Button>
                      </Grid2>
                    }
                  />
                </Grid2>
              </Grid2>
              <Grid2 size={12} mt={2}>
                <Grid2 container justifyContent={"flex-end"} gap={1}>
                  <Show.When
                    isTrue={modelPortfolioId && editModelPortfolioVersion}
                  >
                    <Button
                      variant="outlined"
                      color="dark"
                      onClick={() => {
                        setModelPortfolioVersion(false);
                        setTabs(0);
                      }}
                    >
                      Cancel
                    </Button>
                  </Show.When>
                  <Show.When isTrue={editModelPortfolioVersion}>
                    <LoadingButton
                      variant="contained"
                      type="submit"
                      size="medium"
                      form="equity-basket-version-form"
                      loading={submitLoading || isLoading}
                    >
                      {"Submit"}
                    </LoadingButton>
                  </Show.When>
                </Grid2>
              </Grid2>
            </form>
          </motion.div>
        </Grid2>
      </Grid2>

      {openUploadModal && (
        <VersionUploadModal
          open={openUploadModal}
          form={form}
          setOpen={setOpenUploadModal}
        />
      )}

      {deleteConfirmation && (
        <ConfirmDeleteModal
          open={deleteConfirmation}
          setOpen={setDeleteConfirmation}
          onDelete={() => modalPortfolioDelete(modelPortfolioId)}
          title="Model Portfolio"
          isDelete
        />
      )}
    </DashboardLayout>
  );
};

export default AddModelportfolioVersionModal;
