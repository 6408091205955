import {
  Card,
  GKButton,
  GKDatePicker,
  GKModal,
  GKTextField,
  Grid2,
  StatusField,
  Typography,
} from "components";
import dayjs from "dayjs";
import { useMutation } from "hooks/useQueries";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import axiosInstance from "utils/axiosInstance";
import { GKModalProps } from "utils/commonInterface";
import { refetchQuery } from "utils/helpers";
import { QueryKeys } from "utils/queryKey";
import { ThemeContainer } from "utils/Theme";

interface Props {
  data: {
    id: number;
    kycId: string;
    type: string;
    document: string;
    status: string;
    createdAt: string;
    editedOcrDetails: {
      dob: string;
      name: string;
      idNo: string;
      idType: string;
      fathersName: string;
      encodedImage: string;
      encodedSignature: string;
      centralDbMatchResult: string;
      centralDbLookupDetails: string;
    };
  };
}

const PreviewKYCModal = (props: Props & GKModalProps) => {
  const { open, setOpen, data } = props;

  const { theme } = ThemeContainer();

  const [editDetails, setEditDetails] = useState(false);

  const form = useForm({
    defaultValues: {
      name: data?.editedOcrDetails?.name || "",
      fathersName: data?.editedOcrDetails?.fathersName || "",
      dob: data?.editedOcrDetails?.dob || "",
    },
  });

  const { mutate } = useMutation({
    mutationFn: (values: any) =>
      axiosInstance.patch(`/edit-ocr-details/${data?.id}/`, values),
    onSuccess: () => {
      refetchQuery(QueryKeys.KYC_CLIENT);
      refetchQuery(QueryKeys.CLIENT_DETAILS);
      setOpen(false);
    },
  });

  const handleSubmit = (values: any) => {
    mutate(values);
  };

  return (
    <GKModal
      width={"70%"}
      setOpen={setOpen}
      open={open}
      modalTitle={editDetails ? "Edit Details" : "Preview"}
      titleComponent={
        data?.editedOcrDetails?.idType &&
        !editDetails && (
          <GKButton
            variant="outlined"
            color="dark"
            size="small"
            onClick={() => {
              setEditDetails(true);
            }}
          >
            Edit Details
          </GKButton>
        )
      }
      footer={
        editDetails && (
          <Grid2
            container
            justifyContent={"flex-end"}
            alignItems={"center"}
            gap={1}
          >
            <GKButton
              variant="outlined"
              color="error"
              size="small"
              onClick={() => {
                setEditDetails(false);
              }}
            >
              Cancel
            </GKButton>
            <GKButton
              variant="contained"
              size="small"
              form="kyc-document-form"
              type="submit"
            >
              Save
            </GKButton>
          </Grid2>
        )
      }
    >
      {editDetails ? (
        <form onSubmit={form.handleSubmit(handleSubmit)} id="kyc-document-form">
          <Grid2 container spacing={1}>
            <Grid2 size={6}>
              <Controller
                control={form.control}
                name="name"
                render={({ field }) => {
                  return <GKTextField {...field} inputLabel={"Name"} />;
                }}
              />
            </Grid2>
            {data?.editedOcrDetails?.idType === "PAN" && (
              <Grid2 size={6}>
                <Controller
                  control={form.control}
                  name="fathersName"
                  render={({ field }) => {
                    return (
                      <GKTextField {...field} inputLabel={"Father Name"} />
                    );
                  }}
                />
              </Grid2>
            )}
            <Grid2 size={data?.editedOcrDetails?.idType === "PAN" ? 12 : 6}>
              <Controller
                name="dob"
                control={form.control}
                render={({ field }) => (
                  <GKDatePicker
                    {...field}
                    inputLabel="Date Of Birth"
                    value={dayjs(field.value)}
                    onChange={(value) => {
                      field.onChange(dayjs(value).format("YYYY-MM-DD"));
                    }}
                    disableFuture
                    slotProps={{
                      textField: {
                        error: false,
                        helperText: false,
                      },
                    }}
                  />
                )}
              />
            </Grid2>
          </Grid2>
        </form>
      ) : (
        <Grid2 container spacing={1}>
          <Grid2 size={5}>
            <Card sx={{ p: 2 }}>
              <Grid2 container spacing={1}>
                <Grid2
                  size={12}
                  display={"flex"}
                  justifyContent={"flex-start"}
                  alignItems={"center"}
                  gap={2}
                >
                  {data?.editedOcrDetails?.idType && (
                    <Typography fontWeight={600} fontSize={16}>
                      {data?.editedOcrDetails?.idType}
                    </Typography>
                  )}
                  <StatusField statusString={data?.status || "Pending"} />
                </Grid2>
                {data?.kycId && (
                  <Grid2 size={12}>
                    <Typography
                      fontWeight={600}
                      fontSize={14}
                      color={theme.palette.dark.main}
                    >
                      <span
                        style={{
                          fontSize: 14,
                          fontWeight: 400,
                          color: theme.palette.secondaryText.main,
                        }}
                      >
                        ID:{" "}
                      </span>
                      {data?.kycId}
                    </Typography>
                  </Grid2>
                )}
                {data?.editedOcrDetails?.name && (
                  <Grid2 size={12}>
                    <Typography
                      fontWeight={600}
                      fontSize={14}
                      color={theme.palette.dark.main}
                    >
                      <span
                        style={{
                          fontSize: 14,
                          fontWeight: 400,
                          color: theme.palette.secondaryText.main,
                        }}
                      >
                        Name:{" "}
                      </span>
                      {data?.editedOcrDetails?.name || "-"}
                    </Typography>
                  </Grid2>
                )}
                {data?.editedOcrDetails?.fathersName && (
                  <Grid2 size={12}>
                    <Typography
                      fontWeight={600}
                      fontSize={14}
                      color={theme.palette.dark.main}
                    >
                      <span
                        style={{
                          fontSize: 14,
                          fontWeight: 400,
                          color: theme.palette.secondaryText.main,
                        }}
                      >
                        Fathers Name:{" "}
                      </span>
                      {data?.editedOcrDetails?.fathersName || "-"}
                    </Typography>
                  </Grid2>
                )}
                {data?.editedOcrDetails?.dob && (
                  <Grid2 size={12}>
                    <Typography
                      fontWeight={600}
                      fontSize={14}
                      color={theme.palette.dark.main}
                    >
                      <span
                        style={{
                          fontSize: 14,
                          fontWeight: 400,
                          color: theme.palette.secondaryText.main,
                        }}
                      >
                        DOB:{" "}
                      </span>
                      {data?.editedOcrDetails?.dob || "-"}
                    </Typography>
                  </Grid2>
                )}
              </Grid2>
            </Card>
          </Grid2>
          <Grid2 size={7}>
            <Card
              sx={{
                p: 1,
                display: "flex",
                justifyContent: "center",
                width: "auto",
                alignItems: "center",
              }}
            >
              {data?.type === "video" ? (
                <video autoPlay>
                  <source src={data?.document} type="video/mp4" />
                  <track
                    kind="captions"
                    src="captions-en.vtt"
                    srcLang="en"
                    label="English"
                    default
                  />
                </video>
              ) : (
                <img
                  onError={({ currentTarget }) => {
                    currentTarget.src = "/Logos/errorImg.svg";
                  }}
                  height={"80%"}
                  width={"auto"}
                  alt=""
                  src={data.document}
                />
              )}
            </Card>
          </Grid2>
        </Grid2>
      )}
    </GKModal>
  );
};

export default PreviewKYCModal;
