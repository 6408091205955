import { LoadingButton } from "@mui/lab";
import { useQueryClient } from "@tanstack/react-query";
import {
  Button,
  FiTrash2,
  GKModal,
  GKSearchSelect,
  Grid2,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "components";
import { useFetchQuery, useMutation } from "hooks/useQueries";
import { useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { handleReturnAutocompletePlaceholder } from "utils/StringFunctions";
import { ThemeContainer } from "utils/Theme";
import { GET_COMPANY_LIST } from "utils/apiRoutes";
import axiosInstance from "utils/axiosInstance";
import { GKModalProps } from "utils/commonInterface";
import { QueryKeys } from "utils/queryKey";

const AddNewStockModal = (props: GKModalProps) => {
  const { open, setOpen } = props;

  const { theme } = ThemeContainer();

  const { id } = useParams();

  const queryClient = useQueryClient();

  const [companyMasterData, setCompanyMasterData] = useState(null);

  /** ========================================================================================================= */
  useFetchQuery({
    key: [QueryKeys.COMPANY_NAMES],
    route: GET_COMPANY_LIST(),
    success: (companyNames: any) => {
      setCompanyMasterData(
        companyNames?.data?.data?.map((item: any) => ({
          name: item?.companyName,
          value: item?.isin,
          gridCode: item?.gridCode,
          ...item,
        })),
      );
    },
  });

  /** ========================================================================================================= */
  const { mutate: handleStockSubmit, isLoading } = useMutation({
    mutationFn: (values: any) =>
      axiosInstance.patch(`/model_portfolio/${id}/stock_universe/`, {
        id: Number(id),
        allocItem: values?.stocks,
      }),
    onSuccess: () => {
      queryClient?.refetchQueries(["STOKE_UNIVERSE"]);
      setOpen(false);
    },
  });

  /** ========================================================================================================= */
  const { control, watch, setValue, handleSubmit, getValues } = useForm({
    defaultValues: {
      stocks: [
        {
          isinCode: "",
          allocation: null,
          targetPrice: null,
          stopLoss: null,
          rationale: "",
          gridCode: "",
          companyName: "",
        },
      ],
    },
  });
  watch();

  const { fields, append, remove } = useFieldArray({
    control,
    name: "stocks",
  });

  const handleFormSubmit = (values: any) => {
    handleStockSubmit(values);
  };

  return (
    <GKModal
      modalTitle="Add Stocks"
      open={open}
      setOpen={setOpen}
      footer={
        <Grid2 container justifyContent={"flex-end"}>
          <LoadingButton
            type="submit"
            form="equity-basket-stock-form"
            variant="contained"
            size="medium"
            loading={isLoading}
          >
            {"Save"}
          </LoadingButton>
        </Grid2>
      }
      titleComponent={
        <Button
          onClick={() =>
            append({
              isinCode: "",
              allocation: null,
              targetPrice: null,
              stopLoss: null,
              rationale: "",
              gridCode: "",
              companyName: "",
            })
          }
          size="small"
          variant="outlined"
        >
          Add Stocks
        </Button>
      }
    >
      <Grid2 container>
        <Grid2 size={12}>
          <form
            id="equity-basket-stock-form"
            onSubmit={handleSubmit(handleFormSubmit)}
          >
            <Grid2
              container
              spacing={1}
              justifyContent={"space-between"}
              alignItems={"flex-end"}
            >
              <Grid2 size={12}>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Stocks</TableCell>
                        <TableCell> </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {fields.map((item: any, index: number) => (
                        <TableRow key={index}>
                          <TableCell
                            width={"80%"}
                            style={{
                              padding: 6,
                            }}
                          >
                            <Controller
                              control={control}
                              name={`stocks.${index}.isinCode`}
                              rules={{
                                required: {
                                  value: true,
                                  message: "Please select a company",
                                },
                              }}
                              render={({ field, fieldState: { error } }) => {
                                return (
                                  <GKSearchSelect
                                    {...field}
                                    disableClearable={false}
                                    renderOption={(props, option) => (
                                      <li
                                        {...props}
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          gap: 4,
                                        }}
                                      >
                                        <Typography
                                          color={theme.palette.light.main}
                                          fontSize={8}
                                          style={{
                                            background:
                                              option?.instType === "EQ"
                                                ? theme.palette.secondary.main
                                                : theme.palette.warning.main,
                                            padding: "2px 4px",
                                            borderRadius: 4,
                                          }}
                                        >
                                          {option?.instType}
                                        </Typography>
                                        <Typography whiteSpace={"nowrap"}>
                                          {option?.name}
                                        </Typography>
                                      </li>
                                    )}
                                    sx={{
                                      "& .MuiInputBase-root": {
                                        padding: "6px !important",
                                      },
                                    }}
                                    options={
                                      companyMasterData?.filter(
                                        (dataItem: any) => dataItem,
                                      ) || []
                                    }
                                    getOptionLabel={(option) =>
                                      option.name || option
                                    }
                                    value={getValues(
                                      `stocks.${index}.companyName`,
                                    )}
                                    onChange={(e, val) => {
                                      field.onChange(val?.value);
                                      setValue(
                                        `stocks.${index}.gridCode`,
                                        val.gridCode,
                                      );
                                      setValue(
                                        `stocks.${index}.companyName`,
                                        val.name,
                                      );
                                    }}
                                    renderInput={(params: any) => (
                                      <TextField
                                        {...params}
                                        error={!!error}
                                        helperText={
                                          !!error && "Please select a company"
                                        }
                                        placeholder={handleReturnAutocompletePlaceholder(
                                          {
                                            placeholder: `Select Company`,
                                            value: field.value,
                                          },
                                        )}
                                      />
                                    )}
                                  />
                                );
                              }}
                            />
                          </TableCell>
                          <TableCell
                            align="right"
                            style={{
                              padding: 6,
                            }}
                          >
                            <IconButton
                              color="error"
                              className="outline"
                              onClick={() => {
                                remove(index);
                              }}
                            >
                              <FiTrash2 />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid2>
            </Grid2>
          </form>
        </Grid2>
      </Grid2>
    </GKModal>
  );
};

export default AddNewStockModal;
