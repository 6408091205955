import { GridColDef } from "@mui/x-data-grid-pro";
import { useAppContext } from "AppContext";
import {
  Box,
  GKButton,
  GKClientDataGrid,
  GKDataGrid,
  Grid2,
  StatusField,
  Typography,
} from "components";
import useGenerateQueryParams from "hooks/useGenerateQueryParams";
import { useFetchQuery, useMutation } from "hooks/useQueries";
import { OnboardingDashboardInterface } from "interfaces/ClientOnboarding";
import { useNavigate } from "react-router-dom";
import { GET_CLIENT_COMPLIANCE } from "utils/apiRoutes";
import axiosInstance from "utils/axiosInstance";
import { extraColumnsField, matchDate, refetchQuery } from "utils/helpers";
import { QueryKeys } from "utils/queryKey";
import { ThemeContainer } from "utils/Theme";
import useDocumentTitle from "utils/useDocumentTitle";

const CompliancePage = () => {
  const {
    state: { tab, onBoardflowData },
    dispatch,
  } = useAppContext();

  const { theme } = ThemeContainer();

  const navigate = useNavigate();

  useDocumentTitle("Onboarding");

  const requestBody = useGenerateQueryParams("compliance");

  const { data: complianceData, isFetching } =
    useFetchQuery<OnboardingDashboardInterface>({
      key: [QueryKeys.COMPLIANCE_LIST, requestBody],
      route: `/client/onboarding/`,
      requestBody,
    });

  const { mutate: handleMarkAsComplete } = useMutation({
    mutationKey: ["MARK_OVERALL_STATUS_COMPLETE"],
    mutationFn: (id: any) =>
      axiosInstance.post(`/portfolios/${id}/onboarding/`),

    onSuccess: () => {
      refetchQuery(QueryKeys.COMPLIANCE_LIST);
    },
  });

  const columns: GridColDef[] = [
    {
      ...extraColumnsField("Client Name / Code", "clientName", 1, 1),
      renderCell: (params) => {
        return (
          <Grid2
            container
            display={"flex"}
            flexDirection={"column"}
            alignItems={"flex-start"}
          >
            <Typography fontWeight={700}>{params?.row?.clientName}</Typography>
            <Typography>{params?.row?.clientCode}</Typography>
          </Grid2>
        );
      },
    },
    {
      ...extraColumnsField("KYC", "kycStatus", 1, 1),
      renderCell: (props) => {
        return (
          <StatusField statusString={props?.row?.kycStatus || "Pending"} />
        );
      },
    },
    {
      ...extraColumnsField("Overall", "overallStatus", 1, 1),
      renderCell: (props) => {
        return (
          <StatusField statusString={props?.row?.overallStatus || "Pending"} />
        );
      },
    },
    {
      ...extraColumnsField("Action", "actions", 10),
      align: "right",
      headerAlign: "right",
      renderCell: (props) => {
        return (
          <GKButton
            variant="link"
            onClick={() => {
              dispatch({
                type: "TAB",
                data: { ...tab, complianceTabs: 2, clientERMPageTabs: 0 },
              });
              navigate(`/app/clients/${props.row.id}`, {
                state: { name: props.row?.clientName },
              });
            }}
          >
            View
          </GKButton>
        );
      },
    },
  ];

  return (
    <Box
      sx={{
        "& .MuiDataGrid-detailPanel": {
          bgcolor: `${theme.palette.primary.main}11`,
        },
      }}
    >
      <GKDataGrid
        loading={isFetching}
        rows={complianceData?.data?.data || []}
        columns={columns}
        totalCount={complianceData?.data.totalCount || 0}
        getDetailPanelContent={(params) => {
          return (
            <Box sx={{ m: 2 }}>
              <GKClientDataGrid
                name=""
                columnsButton={false}
                pagination={false}
                showHeaderGrid={false}
                rows={params?.row?.portfolioDetails || []}
                columns={[
                  {
                    ...extraColumnsField("Portfolio Name / Code", "ame", 150),
                    renderCell: (params) => {
                      return (
                        <Grid2
                          container
                          display={"flex"}
                          flexDirection={"column"}
                          alignItems={"flex-start"}
                        >
                          <Typography fontWeight={700}>
                            {params?.row?.name}
                          </Typography>
                          <Typography>{params?.row?.portfolioCode}</Typography>
                        </Grid2>
                      );
                    },
                  },
                  {
                    ...extraColumnsField("Partner", "partnerName", 1, 1),
                    renderCell: (params) => {
                      const { row } = params;
                      return <Typography>{row?.partnerName || "-"}</Typography>;
                    },
                  },
                  {
                    ...extraColumnsField("agreement Date", "data", 1, 1),
                    renderCell: (params) => {
                      const { row } = params;
                      return (
                        <Typography>
                          {matchDate(
                            row[
                              onBoardflowData?.onboardingDate || "agreementDate"
                            ] || "",
                          )}
                        </Typography>
                      );
                    },
                  },
                  {
                    ...extraColumnsField("risk Status", "riskStatus", 1, 1),
                    renderCell: (params) => {
                      const { row } = params;
                      return (
                        <StatusField
                          statusString={row?.riskStatus || "Pending"}
                        />
                      );
                    },
                  },
                  {
                    ...extraColumnsField(
                      "agreement Status",
                      "agreementStatus",
                      1,
                      1,
                    ),
                    renderCell: (params) => {
                      const { row } = params;
                      return (
                        <StatusField
                          statusString={row?.agreementStatus || "Pending"}
                        />
                      );
                    },
                  },
                  {
                    ...extraColumnsField("proof Status", "proofStatus", 1, 1),
                    renderCell: (params) => {
                      const { row } = params;
                      return (
                        <StatusField
                          statusString={row?.proofStatus || "Pending"}
                        />
                      );
                    },
                  },
                  {
                    ...extraColumnsField(
                      "subscription Status",
                      "subscriptionStatus",
                      1,
                      1,
                    ),
                    renderCell: (params) => {
                      const { row } = params;
                      return (
                        <StatusField
                          statusString={row?.subscriptionStatus || "Pending"}
                        />
                      );
                    },
                  },
                  {
                    ...extraColumnsField(
                      "overallStatus",
                      "overallStatus",
                      1,
                      1,
                    ),
                    renderCell: (params) => {
                      const { row } = params;
                      return (
                        <StatusField
                          statusString={row?.overallStatus || "Pending"}
                        />
                      );
                    },
                  },
                  {
                    ...extraColumnsField("Actions", "action", 100, 1),
                    align: "right",
                    headerAlign: "right",
                    renderCell: (v) => {
                      return (
                        <GKButton
                          variant="link"
                          onClick={() => {
                            handleMarkAsComplete(v.row.id);
                          }}
                        >
                          Mark as completed
                        </GKButton>
                      );
                    },
                  },
                ]}
              />
            </Box>
          );
        }}
        name="compliance"
        exportFile={{
          path: GET_CLIENT_COMPLIANCE,
          exportName: "ClientComplianceList",
        }}
      />
    </Box>
  );
};

export default CompliancePage;
