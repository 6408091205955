import { LoadingButton } from "@mui/lab";
import {
  Chip,
  Divider,
  GKSearchSelect,
  Grid2,
  MdClose,
  Switch,
  TextField,
  Typography,
} from "components";
import { useMutation } from "hooks/useQueries";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import axiosInstance from "utils/axiosInstance";
import { refetchQuery } from "utils/helpers";
import { ThemeContainer } from "utils/Theme";
import useDocumentTitle from "utils/useDocumentTitle";

const SettingTab = (props: {
  data: {
    clientAccess: boolean;
    ccEmail: string[];
    active: boolean;
    isSubscribed: boolean;
    tradesPlusCash: boolean;
    considerCash: boolean;
  };
}) => {
  useDocumentTitle("Portfolio Settings");

  const { data } = props;

  const { id } = useParams();
  const { theme } = ThemeContainer();

  const [toggle, setToggle] = useState({
    active: data?.active || false,
    considerCash: data?.considerCash || false,
    tradesPlusCash: data?.tradesPlusCash || false,
  });

  const form = useForm({
    defaultValues: {
      ccEmail: data?.ccEmail || [],
    },
    values: {
      ccEmail: data?.ccEmail,
    },
  });

  form.watch();

  const { mutate, isLoading } = useMutation({
    mutationFn: (data: any) =>
      axiosInstance.patch(`/portfolios/${id}/cc-email/`, data),
    onSuccess: () => {
      refetchQuery("GET_PORTFOLIO_DETAIL_BY_ID");
    },
  });

  const {
    mutate: handlePortfolioSettingUpdate,
    isLoading: portfolioSettingLoading,
  } = useMutation({
    mutationFn: (data: any) =>
      axiosInstance.patch(`/portfolios/${id}/settings/`, data),
    onSuccess: () => {
      refetchQuery("GET_PORTFOLIO_DETAIL_BY_ID");
      refetchQuery("GET_PORTFOLIO_DETAIL_CARDS");
    },
  });

  const permissionArray = [
    {
      title: "Manage portfolio with cash",
      description:
        "Track portfolio metrics and returns using cash entries and trades. Turning this toggle off would calculate portfolio metrics and returns considering only trades.",
      value: toggle?.considerCash,
      onChange: (e: any) =>
        setToggle({ ...toggle, considerCash: e.target.checked }),
      visible: true,
    },
    {
      title: "Portfolio metrics configuration",
      description: "Calculate NAV, XIRR and Net Contribution with cash flows.",
      value: toggle?.tradesPlusCash,
      onChange: (e: any) =>
        setToggle({ ...toggle, tradesPlusCash: e.target.checked }),
      visible: true,
      disabled: !toggle?.considerCash,
    },
    {
      title: "Portfolio Permissions",
      description:
        "Manage Portfolio: Easily Switch Between Active and Inactive Items",
      onChange: (e: any) => setToggle({ ...toggle, active: e.target.checked }),
      value: toggle?.active,
      visible: true,
    },
  ];

  const validateEmail = (email: string) => {
    const re = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
    return re.test(String(email).toLowerCase());
  };

  return (
    <Grid2 container spacing={2}>
      <Grid2 size={3}>
        <Typography
          fontSize={16}
          fontWeight={500}
          color={theme.palette.secondaryText.main}
        >
          Portfolio Configuration
        </Typography>
      </Grid2>
      <Grid2
        container
        size={9}
        spacing={2}
        display={"flex"}
        alignItems={"flex-start"}
      >
        {permissionArray.map((dataItem, index) => {
          const loading = portfolioSettingLoading;

          return (
            <Grid2 size={4} key={index}>
              <div style={{ display: "flex", alignItems: "center", gap: 1 }}>
                <Switch
                  checked={dataItem.value as boolean}
                  onChange={dataItem.onChange}
                  disabled={loading || dataItem.disabled}
                />
                <Typography
                  fontSize={16}
                  fontWeight={400}
                  textTransform={"none"}
                  color={theme.palette.dark?.main}
                >
                  {dataItem.title}
                </Typography>
              </div>
              <Typography
                ml={"42px"}
                fontSize={12}
                color={theme.palette.secondaryText.main}
              >
                {dataItem.description}
              </Typography>
            </Grid2>
          );
        })}
      </Grid2>
      <Grid2
        size={12}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"flex-end"}
      >
        <LoadingButton
          type="button"
          variant="contained"
          loading={portfolioSettingLoading}
          onClick={() => handlePortfolioSettingUpdate(toggle)}
        >
          Save
        </LoadingButton>
      </Grid2>

      <Grid2 size={12} my={1}>
        <Divider />
      </Grid2>

      <Grid2 size={3}>
        <Typography
          fontSize={16}
          fontWeight={500}
          color={theme.palette.secondaryText.main}
        >
          CC Email Configuration
        </Typography>
      </Grid2>
      <Grid2
        container
        size={9}
        spacing={2}
        display={"flex"}
        alignItems={"center"}
      >
        <Grid2 size={12}>
          <form
            id="cc-email-form"
            onSubmit={form.handleSubmit((values) => mutate(values))}
          >
            <Controller
              name="ccEmail"
              control={form.control}
              rules={{
                required: {
                  value: false,
                  message: "Please enter email",
                },
                validate: (value) => {
                  if (value.length > 5) {
                    return "You can add a maximum of 5 email addresses.";
                  }
                  if (value.some((email) => !validateEmail(email))) {
                    return "Please enter a valid email address.";
                  }
                  return true;
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <GKSearchSelect
                  {...field}
                  requiredField
                  sx={{ width: "100%" }}
                  inputLabel={""}
                  freeSolo
                  multiple
                  options={[]}
                  onChange={(e, val) => {
                    const selected = val.map((option: string) => option);
                    field.onChange(selected);
                  }}
                  renderTags={(value, props) => {
                    return value?.map((option, index) => (
                      <Chip
                        label={option}
                        {...props({ index })}
                        deleteIcon={<MdClose size={12} />}
                      />
                    ));
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={!!error}
                      helperText={error?.message}
                    />
                  )}
                />
              )}
            />
          </form>
          {data?.ccEmail.length === 0 && (
            <Typography fontSize={12} fontWeight={400} color={"#98A4b3"}>
              You can add maximum 5 email addresses in CC.
            </Typography>
          )}
        </Grid2>
        <Grid2
          size={12}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"flex-end"}
        >
          <LoadingButton
            type="submit"
            variant="contained"
            loading={isLoading}
            form="cc-email-form"
            onClick={(e) => e.stopPropagation()}
          >
            Save
          </LoadingButton>
        </Grid2>
      </Grid2>
      <Grid2 size={12} my={1}>
        <Divider />
      </Grid2>
    </Grid2>
  );
};

export default SettingTab;
