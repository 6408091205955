import { GridColDef } from "@mui/x-data-grid-pro";
import { GKClientDataGrid, Grid2, Show, Typography } from "components";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
import { handleReturnPositiveNegative, moneyCommaSeparator } from "utils";
import { extraColumnsField } from "utils/helpers";
import HoldingDetails from "./HoldingDetails";

interface Props {
  isLoading: boolean;
}

const NetUnrealisedGainSection = (props: Props) => {
  const form = useFormContext();

  const [openDetailsModal, setOpenDetailsModal] = useState(false);
  const [rowData, setRowData] = useState({});

  const columns: GridColDef[] = [
    {
      ...extraColumnsField("HOLDINGS", "companyName", 250, 1),
    },
    {
      ...extraColumnsField("PORTFOLIO", "portfolio", 200, 1),
      renderCell: (params) => {
        return (
          <Typography fontWeight={500}>
            {params.row.portfolioName}
            <Typography fontSize={12} fontWeight={600} color="#697586">
              {params?.row?.portfolioCode}
            </Typography>
          </Typography>
        );
      },
    },
    {
      ...extraColumnsField("CMP", "cmp", 150, 1),
      type: "number",
      valueGetter: (value) => moneyCommaSeparator(value.value),
    },
    {
      ...extraColumnsField("LT QTY", "longTermQuantity", 100, 1),
      type: "number",
      valueGetter: (value) => moneyCommaSeparator(value.value),
    },
    {
      ...extraColumnsField("LT GAIN", "longTermCapitalGain", 120, 1),
      type: "number",
      valueGetter: (value) => moneyCommaSeparator(value.value),
    },
    {
      ...extraColumnsField("LT LOSS", "longTermCapitalLoss", 120, 1),
      type: "number",
      valueGetter: (value) => moneyCommaSeparator(value.value),
    },
    {
      ...extraColumnsField("ST QTY", "shortTermQuantity", 100, 1),
      type: "number",
      valueGetter: (value) => moneyCommaSeparator(value.value),
    },
    {
      ...extraColumnsField("ST GAIN", "shortTermCapitalGain", 120, 1),
      type: "number",
      valueGetter: (value) => moneyCommaSeparator(value.value),
    },
    {
      ...extraColumnsField("ST LOSS", "shortTermCapitalLoss", 120, 1),
      type: "number",
      valueGetter: (value) => moneyCommaSeparator(value.value),
    },
    {
      ...extraColumnsField("TOTAL", "saleAmount", 120, 1),
      type: "number",
      renderCell: (params) => {
        const longTermCapitalGain = params?.row?.longTermCapitalGain || 0;
        const longTermCapitalLoss = params?.row?.longTermCapitalLoss || 0;
        const shortTermCapitalGain = params?.row?.shortTermCapitalGain || 0;
        const shortTermCapitalLoss = params?.row?.shortTermCapitalLoss || 0;

        const total =
          longTermCapitalGain +
          longTermCapitalLoss +
          shortTermCapitalGain +
          shortTermCapitalLoss;

        return (
          <Typography color={handleReturnPositiveNegative(total)}>
            {moneyCommaSeparator(total)}
          </Typography>
        );
      },
    },
  ];

  return (
    <>
      <GKClientDataGrid
        loading={props?.isLoading}
        columns={columns}
        columnsButton={false}
        rows={form
          .getValues("unrealisedGainReportData")
          ?.map((dataItem: any, index: number) => {
            return {
              ...dataItem,
              id: index,
            };
          })}
        name="netUnrealisedGain"
        headerComponent={
          <Grid2 container display={"inline-grid"}>
            <Typography fontSize={12}> * LT : Long Term</Typography>
            <Typography fontSize={12}> * ST : Short Term</Typography>
          </Grid2>
        }
        onRowClick={(params) => {
          setRowData(params.row);
          setOpenDetailsModal(true);
        }}
      />
      <Show.When isTrue={openDetailsModal}>
        <HoldingDetails
          open={openDetailsModal}
          setOpen={setOpenDetailsModal}
          rowData={rowData}
        />
      </Show.When>
    </>
  );
};

export default NetUnrealisedGainSection;
