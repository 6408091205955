import { LoadingButton } from "@mui/lab";
import { useAppContext } from "AppContext";
import {
  CgMathPlus,
  GKButton,
  GKModal,
  GKSearchSelect,
  GKTextField,
  Grid2,
  PhoneInput,
  TextField,
} from "components";
import useAddClient from "hooks/useAddClient";
import FamilyCreateModal from "Pages/Client/FamilyCreateModal";
import AddBranchModal from "Pages/Organisations/AddBranchModal";
import { useState } from "react";
import { Controller } from "react-hook-form";
import { GKModalProps } from "utils/commonInterface";
import { handleReturnAutocompletePlaceholder } from "utils/StringFunctions";

const ClientModal = ({ open, setOpen }: GKModalProps) => {
  const {
    isSubmitting,
    setIsBranchCreateModalOpen,
    setIsFamilyCreateModalOpen,
    // branchList,
    // familyList,
    isBranchCreateModalOpen,
    isFamilyCreateModalOpen,
    loading,
    form,
    onSubmit,
  } = useAddClient({ setOpen });

  const [hasError, setHasError] = useState(false);

  const {
    state: { familyList },
  } = useAppContext();

  // const watchClientType = form.watch("type");

  // const basketOptionList = () => {
  //   return basketList?.data?.data
  //     ?.map((dataItem: { name: string; id: number }) => {
  //       return {
  //         name: dataItem.name,
  //         value: dataItem.id,
  //       };
  //     })
  //     .filter(
  //       (data: any) =>
  //         !form
  //           .getValues("basket")
  //           ?.map((dataItem: any) => dataItem)
  //           .includes(data.value),
  //     );
  // };

  return (
    <GKModal
      open={open}
      setOpen={setOpen}
      modalTitle="Add Client"
      footer={
        <Grid2 container justifyContent={"flex-end"} gap={1}>
          <LoadingButton
            disabled={hasError}
            loading={isSubmitting}
            size="medium"
            type="submit"
            variant="contained"
            form="client-form"
          >
            Add Client
          </LoadingButton>
        </Grid2>
      }
      titleComponent={
        <Grid2
          container
          justifyContent={"flex-end"}
          alignItems={"center"}
          gap={1}
        >
          {/* <Button
            size="medium"
            variant="outlined"
            onClick={() => setIsBranchCreateModalOpen(true)}
          >
            Add Branch
          </Button> */}
          <GKButton
            size="medium"
            variant="outlined"
            onClick={() => setIsFamilyCreateModalOpen(true)}
            startIcon={<CgMathPlus size={14} />}
          >
            Add Family
          </GKButton>
        </Grid2>
      }
    >
      <>
        <form onSubmit={form.handleSubmit(onSubmit)} id="client-form">
          <Grid2 container spacing={1}>
            <Grid2 size={4}>
              <Controller
                name="personalDetails.name"
                control={form.control}
                render={({ field, fieldState: { error } }) => (
                  <GKTextField
                    {...field}
                    inputLabel="Name"
                    requiredField
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              />
            </Grid2>
            <Grid2 size={4}>
              <Controller
                name="phone"
                control={form.control}
                render={({ field, fieldState: { error } }) => (
                  <PhoneInput
                    {...field}
                    requiredField
                    onSelectChange={(event: any, val: any) => {
                      form.setValue("countryCode", val?.callingCodes);
                    }}
                    onTextChange={(event: any) => {
                      field.onChange(event.target.value);
                    }}
                    onTextValue={field.value}
                    onSelectValue={form.getValues("countryCode")}
                    error={!!error || hasError}
                    helperText={!!error && error?.message}
                    hasError={hasError}
                    setHasError={setHasError}
                  />
                )}
              />
            </Grid2>
            <Grid2 size={4}>
              <Controller
                name="personalDetails.email"
                control={form.control}
                render={({ field, fieldState: { error } }) => (
                  <GKTextField
                    {...field}
                    inputLabel="Email"
                    placeholder="Email"
                    requiredField
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              />
            </Grid2>
            <Grid2 size={4}>
              <Controller
                name="personalDetails.pan"
                control={form.control}
                render={({ field, fieldState: { error } }) => (
                  <GKTextField
                    {...field}
                    inputLabel="PAN"
                    placeholder="PAN"
                    requiredField
                    onChange={(event: any) =>
                      field.onChange(event.target.value.toUpperCase())
                    }
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              />
            </Grid2>
            {/* <Grid2 size={4}>
              <Controller
                name="branch"
                control={form.control}
                rules={{
                  required: {
                    value: true,
                    message: "Please select branch",
                  },
                }}
                render={({ field }) => (
                  <GKSearchSelect
                    disableClearable
                    inputLabel="Branch"
                    requiredField
                    options={branchList || []}
                    onChange={(e, val) => {
                      field.onChange(val.value);
                    }}
                    getOptionLabel={(option) => option.name || option}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="Branch"
                        placeholder="Select branch"
                        error={Boolean(form?.formState?.errors?.branch)}
                        helperText={form?.formState?.errors?.branch?.message}
                      />
                    )}
                    disabled={loading}
                  />
                )}
              />
            </Grid2> */}
            <Grid2 size={4}>
              <Controller
                name="family"
                control={form.control}
                render={({ field }) => (
                  <GKSearchSelect
                    disableClearable
                    inputLabel="Family"
                    options={familyList || []}
                    onChange={(e, val) => {
                      field.onChange(val?.value);
                    }}
                    getOptionLabel={(option) => option.name || option}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="family"
                        placeholder={handleReturnAutocompletePlaceholder({
                          placeholder: "Select Family",
                          value: field.value,
                        })}
                      />
                    )}
                    disabled={loading}
                  />
                )}
              />
            </Grid2>
            {/* {userData?.broker?.pmsSupport && (
              <Grid2 size={4}>
                <Controller
                  name="type"
                  control={form.control}
                  render={({ field }) => (
                    <GKSearchSelect
                      disableClearable
                      inputLabel="Client Type"
                      requiredField
                      options={[
                        { name: "PMS", value: "PMS" },
                        { name: "Advisory", value: "ADVISORY" },
                      ]}
                      value={[
                        { name: "PMS", value: "PMS" },
                        { name: "Advisory", value: "ADVISORY" },
                      ].find((option) => option.value === field.value)}
                      onChange={(e, val) => field.onChange(val?.value)}
                      getOptionLabel={(option) => option.name || option}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="type"
                          placeholder={handleReturnAutocompletePlaceholder({
                            placeholder: "Select Client Type",
                            value: field.value,
                          })}
                        />
                      )}
                      disabled={loading}
                    />
                  )}
                />
              </Grid2>
            )}
            {watchClientType === "PMS" && (
              <>
                <Grid2 size={4}>
                  <Controller
                    name="code"
                    control={form.control}
                    render={({ field, fieldState: { error } }) => (
                      <GKTextField
                        {...field}
                        inputLabel="Client Code"
                        requiredField
                        error={!!error}
                        helperText={error?.message}
                      />
                    )}
                  />
                </Grid2>
                <Grid2 size={8}>
                  <Controller
                    name="basket"
                    control={form.control}
                    render={({ field, fieldState: { error } }) => (
                      <GKSearchSelect
                        {...field}
                        loading={loading}
                        multiple
                        disableClearable={false}
                        disableCloseOnSelect
                        limitTags={5}
                        inputLabel="Basket"
                        requiredField
                        options={basketOptionList()}
                        value={
                          basketList?.data?.data
                            .map((dataItem: { name: string; id: number }) => {
                              return {
                                name: dataItem.name,
                                value: dataItem.id,
                              };
                            })
                            ?.filter((option: any) =>
                              field.value?.includes(option.value),
                            ) || []
                        }
                        onChange={(e, val) => {
                          const selectedIds = val.map(
                            (option: any) => option.value,
                          );
                          field.onChange(selectedIds);
                        }}
                        getOptionLabel={(option) => option.name || option}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            name="basket"
                            placeholder={handleReturnAutocompletePlaceholder({
                              placeholder: "Select Basket",
                              value: field.value,
                            })}
                            error={!!error}
                            helperText={error?.message}
                          />
                        )}
                        disabled={loading}
                      />
                    )}
                  />
                </Grid2>
              </>
            )} */}
          </Grid2>
        </form>
        {isBranchCreateModalOpen && (
          <AddBranchModal
            open={isBranchCreateModalOpen}
            updateData={null}
            isEditModal={false}
            setOpen={setIsBranchCreateModalOpen}
          />
        )}
        {isFamilyCreateModalOpen && (
          <FamilyCreateModal
            open={isFamilyCreateModalOpen}
            setOpen={setIsFamilyCreateModalOpen}
          />
        )}
      </>
    </GKModal>
  );
};

export default ClientModal;
