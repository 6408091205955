import { LoadingButton } from "@mui/lab";
import {
  Divider,
  GKButton,
  GKTextField,
  Grid2,
  IconButton,
  IoEyeOffOutline,
  IoMdEye,
  PhoneInput,
  Typography,
} from "components";
import { useLogin } from "hooks/useLogin";
import { Controller } from "react-hook-form";

const LoginPage = () => {
  const {
    isSubmitting,
    navigate,
    form,
    onSubmit,
    hasError,
    setHasError,
    showPassword,
    setShowPassword,
    theme,
  } = useLogin();

  return (
    <form onSubmit={form.handleSubmit(onSubmit)}>
      <Grid2 container gap={2} justifyContent={"center"}>
        <Grid2
          size={12}
          position={"relative"}
          display={"flex"}
          alignItems={"center"}
          flexDirection={"column"}
          justifyContent={"center"}
          gap="20px"
        >
          <Typography
            fontWeight={700}
            fontSize={24}
            className="textUnderlineLogin"
            color={theme.palette.dark.main}
            sx={{
              "&.textUnderlineLogin::before": {
                width: "140px !important",
              },
            }}
          >
            Hello again !
          </Typography>
          <Typography color={theme.palette.dark.main}>
            If you are already a member, please login to your account.
          </Typography>
        </Grid2>
        <Grid2 container size={{ xs: 12, md: 7, sm: 8 }} spacing={1}>
          <Grid2 size={12}>
            <Controller
              name="phone"
              control={form.control}
              rules={{
                required: {
                  value: true,
                  message: "Please enter phone number",
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <PhoneInput
                  {...field}
                  onSelectChange={(event: any, val: any) => {
                    form.setValue("countryCode", val?.callingCodes);
                  }}
                  onTextChange={(event: any) => {
                    field.onChange(event.target.value);
                  }}
                  onTextValue={field.value}
                  onSelectValue={form.getValues("countryCode")}
                  error={!!error || hasError}
                  helperText={error?.message}
                  hasError={hasError}
                  setHasError={setHasError}
                />
              )}
            />
          </Grid2>
          <Grid2 size={12}>
            <Controller
              name="password"
              control={form.control}
              render={({ field, fieldState: { error } }) => (
                <GKTextField
                  {...field}
                  inputLabel="Password"
                  type={showPassword ? "text" : "password"}
                  error={!!error}
                  helperText={error?.message}
                  slotProps={{
                    input: {
                      style: {
                        paddingRight: 12,
                      },
                      endAdornment: (
                        <IconButton
                          onClick={() => {
                            setShowPassword(!showPassword);
                          }}
                          style={{
                            padding: 0,
                          }}
                        >
                          {!showPassword ? <IoMdEye /> : <IoEyeOffOutline />}
                        </IconButton>
                      ),
                    },
                  }}
                />
              )}
            />

            <Grid2
              size={12}
              justifyContent={"space-between"}
              alignItems={"center"}
              display={"flex"}
            >
              <GKButton
                variant="text"
                style={{ fontWeight: 600 }}
                onClick={() =>
                  navigate("/login/authentication", {
                    state: { loginWithOTP: true },
                  })
                }
              >
                Login Using OTP
              </GKButton>
              <GKButton
                variant="text"
                style={{ fontWeight: 600 }}
                onClick={() => navigate("/login/forgot-password")}
              >
                {" "}
                Forgot your password?
              </GKButton>
            </Grid2>
          </Grid2>
          <Grid2 size={12} textAlign={"end"}>
            <LoadingButton
              fullWidth
              variant="contained"
              size="medium"
              disabled={hasError}
              type="submit"
              loading={isSubmitting}
            >
              Login
            </LoadingButton>
          </Grid2>
        </Grid2>
        <Grid2
          size={12}
          justifyContent={"center"}
          alignItems={"center"}
          textAlign={"center"}
        >
          <Divider style={{ margin: "10px 0" }} />
          <Typography color={theme.palette.dark.main}>
            Don&apos;t have an account?
            <GKButton
              variant="text"
              onClick={() =>
                navigate("/login/authentication", { state: "forgot" })
              }
              style={{ padding: 0 }}
            >
              {" "}
              Register
            </GKButton>
          </Typography>
        </Grid2>
      </Grid2>
    </form>
  );
};

export default LoginPage;
