import { GridColDef } from "@mui/x-data-grid-pro";
import { Button, GKClientDataGrid, GKModal, Grid2 } from "components";
import { EquitybasketFormInterface } from "interfaces/EquityBasket";
import { Controller, useFormContext } from "react-hook-form";
import { GKModalProps } from "utils/commonInterface";
import { extraColumnsField } from "utils/helpers";

interface PartnerListInterface {
  data: {
    data: {
      id: number;
      name: string;
      code: string;
      email: string;
      phone: string;
      role: string;
      headBranch: string;
      reportingBranch: any;
      countryCode: string;
    }[];
  };
}

const AddBasketPartner = ({
  open,
  setOpen,
  partnerList,
}: GKModalProps & { partnerList: PartnerListInterface }) => {
  const form = useFormContext<EquitybasketFormInterface>();

  const columns: GridColDef[] = [
    {
      ...extraColumnsField("Name", "name", 150, 1),
    },
    {
      ...extraColumnsField("Code", "code", 150, 1),
    },
    {
      ...extraColumnsField("Email", "email", 150, 1),
    },
    {
      ...extraColumnsField("Phone", "phone", 150, 1),
    },
    {
      ...extraColumnsField("Branch", "headBranch", 150, 1),
    },
  ];

  return (
    <GKModal
      open={open}
      setOpen={setOpen}
      modalTitle="Edit Partner "
      width={"60%"}
      footer={
        <Button
          variant="contained"
          onClick={() => {
            setOpen(false);
          }}
        >
          Save
        </Button>
      }
    >
      <Grid2 container>
        <Grid2 size={12}>
          <Controller
            name="partners"
            control={form.control}
            render={({ field }) => {
              return (
                <GKClientDataGrid
                  checkboxSelection
                  rows={partnerList?.data?.data || []}
                  columns={columns}
                  maxHeight={"calc(100vh - 312px)"}
                  name="basketPartner"
                  columnsButton={false}
                  density="compact"
                  rowSelectionModel={field.value}
                  disableRowSelectionOnClick
                  onRowSelectionModelChange={(params) => field.onChange(params)}
                />
              );
            }}
          />
        </Grid2>
      </Grid2>
    </GKModal>
  );
};

export default AddBasketPartner;
