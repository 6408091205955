import { LoadingButton } from "@mui/lab";
import { GKModal, GKTextField, Grid2 } from "components";
import { useMutation } from "hooks/useQueries";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import axiosInstance from "utils/axiosInstance";
import { GKModalProps } from "utils/commonInterface";
import { refetchQuery } from "utils/helpers";
import { QueryKeys } from "utils/queryKey";
import { validateNotWhitespaceOnly } from "utils/validationSchema";

interface Props {
  data: any;
}

const EditFamilyModal = (props: Props & GKModalProps) => {
  const { open, setOpen, data } = props;

  const { id } = useParams();

  const { mutate, isLoading } = useMutation({
    mutationKey: ["UPDATE_FAMILY"],
    mutationFn: (data) => axiosInstance.patch(`/family/${id}/`, data),
    onSuccess: () => {
      refetchQuery(QueryKeys.FAMILY_LIST);
      setOpen(false);
    },
  });

  const form = useForm({
    defaultValues: {
      name: data?.name || "",
    },
  });

  return (
    <GKModal
      open={open}
      setOpen={setOpen}
      width={"40%"}
      modalTitle={"Edit Family"}
      footer={
        <LoadingButton
          type="submit"
          variant="contained"
          form="edit-family-form"
          loading={isLoading}
        >
          Save
        </LoadingButton>
      }
    >
      <form
        id="edit-family-form"
        onSubmit={form.handleSubmit((values: any) => mutate(values))}
      >
        <Grid2 container spacing={1}>
          <Grid2 size={12}>
            <Controller
              name="name"
              control={form.control}
              rules={{
                required: {
                  value: true,
                  message: "Name is required",
                },
                validate: validateNotWhitespaceOnly,
              }}
              render={({ field, fieldState: { error } }) => (
                <GKTextField
                  {...field}
                  inputLabel={"Family Name"}
                  requiredField
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
          </Grid2>
        </Grid2>
      </form>
    </GKModal>
  );
};

export default EditFamilyModal;
