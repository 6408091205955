import { LoadingButton } from "@mui/lab";
import { useAppContext } from "AppContext";
import {
  Divider,
  GKDatePicker,
  GKSearchSelect,
  GKSelect,
  GKTextField,
  Grid2,
  MenuItem,
  PhoneInput,
  Show,
  TextField,
  Typography,
} from "components";
import dayjs from "dayjs";
import { useFetchQuery, useMutation } from "hooks/useQueries";
import { AdvisorOnboardFormInterface } from "interfaces/OnboardingInterface";
import FamilyCreateModal from "Pages/Client/FamilyCreateModal";
import AddBranchModal from "Pages/Organisations/AddBranchModal";
import { useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { BRANCH_HAS_MANAGER, GET_ALL_CLIENT } from "utils/apiRoutes";
import axiosInstance from "utils/axiosInstance";
import { UserRoleTypes } from "utils/common";
import { MARITAL_STATUS, PERSONAL_GENDER } from "utils/constants";
import { refetchQuery } from "utils/helpers";
import { QueryKeys } from "utils/queryKey";
import { handleReturnAutocompletePlaceholder } from "utils/StringFunctions";
import {
  emailRegex,
  panRegex,
  validateNotWhitespaceOnly,
} from "utils/validationSchema";
import ClientBank from "./AdvisorOnboardingClientBank";
import ClientDemat from "./AdvisorOnboardingClientDemat";
import ClientNominee from "./AdvisorOnboardingClientNominee";

const AdvisorOnboardingClientDetails = ({
  hasError,
  setHasError,
}: {
  hasError: boolean;
  setHasError: any;
}) => {
  const form = useFormContext<AdvisorOnboardFormInterface>();

  const {
    dispatch,
    state: {
      cityList,
      stateList,
      countryList,
      familyList,
      partnerList,
      userData,
    },
  } = useAppContext();

  const [isBranchCreateModalOpen, setIsBranchCreateModalOpen] =
    useState<boolean>(false);

  const [isFamilyCreateModalOpen, setIsFamilyCreateModalOpen] =
    useState<boolean>(false);

  const { isFetching, data: clients } = useFetchQuery({
    key: [QueryKeys.CLIENTS_SHORT_DATA],
    route: GET_ALL_CLIENT,
  });
  // ===========================================================================================
  const { data: branchData } = useFetchQuery({
    key: ["GET_ALL_BRANCHES"],
    route: BRANCH_HAS_MANAGER,
  });

  // ===========================================================================================
  const { mutate: handleAddClient } = useMutation({
    mutationKey: ["HANDLE_ADD_CLIENT_MUTATUION"],
    mutationFn: (clientState: AdvisorOnboardFormInterface) =>
      axiosInstance.post("/client/", {
        branch: branchData.data[0]?.id,
        phone: clientState.phone,
        countryCode: clientState.countryCode,
        family: clientState.family,
        partner: clientState.partner || userData?.partnerId,
        personalDetails: {
          email: clientState.personalDetails.email,
          name: clientState.personalDetails.name,
          gender: clientState.personalDetails.gender,
          state: clientState.personalDetails.state,
          country: clientState.personalDetails.country,
          maritalStatus: clientState.personalDetails.maritalStatus,
          fatherName: clientState.personalDetails.fatherName,
          motherName: clientState.personalDetails.motherName,
          dateOfBirth: clientState.personalDetails.dateOfBirth,
          address: clientState.personalDetails.address,
          income: +clientState.personalDetails.income,
          occupation: clientState.personalDetails.occupation,
          pan: clientState.personalDetails.pan,
        },
      }),
    onSuccess: (response) => {
      form.reset({
        ...form.getValues(),
        ...response?.data,
        activeStep: form.getValues("activeStep") + 1,
      });
    },
  });

  // ===========================================================================================
  const { mutate: handleUpdateClient } = useMutation({
    mutationKey: ["HANDLE_UPDATE_CLIENT_MUTATUION"],
    mutationFn: (clientState: AdvisorOnboardFormInterface) =>
      axiosInstance.patch(`/client/${clientState.id}/`, {
        branch: branchData.data[0].id,
        family: clientState.family,
        countryCode: clientState.countryCode,
        phone: clientState.phone,
        partner: clientState.partner || userData?.partnerId,
        personalDetails: {
          email: clientState.personalDetails.email,
          name: clientState.personalDetails.name,
          gender: clientState.personalDetails.gender,
          maritalStatus: clientState.personalDetails.maritalStatus,
          fatherName: clientState.personalDetails.fatherName,
          motherName: clientState.personalDetails.motherName,
          dateOfBirth: clientState.personalDetails.dateOfBirth,
          address: clientState.personalDetails.address,
          income: +clientState.personalDetails.income,
          occupation: clientState.personalDetails.occupation,
          pan: clientState.personalDetails.pan,
          city: clientState?.personalDetails?.city,
          state: clientState?.personalDetails?.state,
          country: clientState?.personalDetails?.country,
          pincode: clientState?.personalDetails?.pincode,
          aadhar: clientState?.personalDetails?.aadhar,
          emergencyContact: clientState?.personalDetails?.emergencyContact,
          emergencyContactNum:
            clientState?.personalDetails?.emergencyContactNum,
        },
      }),
    onSuccess: () => {
      refetchQuery(QueryKeys.GET_COMPLIANCE_BY_CLIENT_ID);
      refetchQuery(QueryKeys.GET_CLIENT_BY_ID);
      form.setValue("activeStep", form.getValues("activeStep") + 1);
    },
  });
  // ===========================================================================================
  const { mutate: getClientById } = useMutation({
    mutationKey: ["GET_CLIENT_BY_ID"],
    mutationFn: (selectedClients: any) =>
      axiosInstance.get(`/client/${selectedClients}/`),
    onSuccess: (response: any) => {
      if (form.getValues("type") === "manual") {
        form.setValue(
          "personalDetails.email",
          response?.data?.personalDetails?.email,
        );
        form.setValue(
          "personalDetails.name",
          response?.data?.personalDetails?.name,
        );
        form.setValue("phone", response?.data?.phone);
      } else {
        form.reset({
          ...form.getValues(),
          ...response.data,
          isExistingClient: true,
        });
      }
    },
  });
  // ===========================================================================================
  const { mutate: handleGetKRAData, isLoading: KRALoading } = useMutation({
    mutationKey: ["GET_KRA_DATA"],
    mutationFn: (dataItem: any) => {
      return axiosInstance.post("/pan-details/", dataItem, {
        headers: { Authorization: null },
      });
    },
    onSuccess: (response) => {
      form.setValue("personalDetails.name", response.data.data.name);
      form.setValue("fetchedFromKra", true);
      form.setValue("personalDetails.address", response.data.data.address);
      form.setValue("personalDetails.gender", response.data.data.gender);
      form.setValue("personalDetails.pincode", response.data.data.pincode);
      form.setValue("personalDetails.city", response.data.data.city);
      form.setValue("personalDetails.country", response.data.data.country);
      form.setValue("personalDetails.state", response.data.data.state);
    },
  });

  return (
    <form
      id="advisor-onboarding-client-details-form"
      onSubmit={form.handleSubmit((values) => {
        if (form.getValues("id") && form.getValues("type") !== "manual") {
          handleUpdateClient(values);
        } else if (
          form.getValues("type") !== "manual" &&
          !form.getValues("id")
        ) {
          handleAddClient(values);
        } else {
          form.setValue("activeStep", form.getValues("activeStep") + 1);
        }
      })}
    >
      <Grid2 container spacing={2}>
        <Grid2 size={12}>
          <Typography fontSize={24} fontWeight={600}>
            Provide client information below
          </Typography>
        </Grid2>
        <Grid2 container spacing={2} size={12}>
          <Grid2 size={12}>
            <GKSearchSelect
              disableClearable
              inputLabel="Existing Client"
              options={clients?.data || []}
              disabled={isFetching}
              onChange={(e, val) => {
                getClientById(val.id);
              }}
              getOptionLabel={(option) => option.name || option}
              renderInput={(params: any) => (
                <TextField
                  {...params}
                  name="Client"
                  placeholder={"Select Client"}
                />
              )}
            />
          </Grid2>
          <Grid2 size={12}>
            <Divider />
          </Grid2>
          <Grid2 size={form.getValues("type") !== "manual" ? 3 : 6}>
            <Controller
              name="phone"
              rules={{
                required: {
                  value: form.getValues("activeStep") === 1,
                  message: "Please enter phone number",
                },
              }}
              control={form.control}
              render={({ field, fieldState: { error } }) => (
                <PhoneInput
                  {...field}
                  onSelectChange={(event: any, val: any) => {
                    form.setValue("countryCode", val?.callingCodes);
                  }}
                  onTextChange={(event: any) => {
                    field.onChange(event.target.value);
                  }}
                  onTextValue={field.value}
                  requiredField
                  onSelectValue={form.getValues("countryCode")}
                  error={!!error || hasError}
                  helperText={error?.message}
                  hasError={hasError}
                  setHasError={setHasError}
                />
              )}
            />
          </Grid2>
          <Show.When isTrue={form.getValues("type") !== "manual"}>
            <Grid2 size={3}>
              <Controller
                name="personalDetails.pan"
                rules={{
                  required: {
                    value: form.getValues("activeStep") === 1,
                    message: "Please enter PAN",
                  },
                  pattern: {
                    value: panRegex,
                    message: "PAN should have this format ABCDE1234A",
                  },
                  maxLength: {
                    value: 10,
                    message: "PAN should have 10 character",
                  },
                  minLength: {
                    value: 10,
                    message: "PAN should have 10 character",
                  },
                }}
                control={form.control}
                render={({ field, fieldState: { error } }) => (
                  <GKTextField
                    {...field}
                    inputLabel="PAN"
                    onChange={(e) =>
                      field.onChange(e.target.value.toUpperCase())
                    }
                    requiredField
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              />
            </Grid2>
            <Grid2 size={3}>
              <Controller
                name="personalDetails.dateOfBirth"
                control={form.control}
                render={({ field }) => (
                  <GKDatePicker
                    {...field}
                    disableFuture
                    inputLabel="Date Of Birth"
                    value={dayjs(field.value)}
                    onChange={(value) => {
                      field.onChange(dayjs(value).format("YYYY-MM-DD"));
                    }}
                    minDate={dayjs("01-01-1901")}
                    slotProps={{
                      textField: {
                        error: false,
                        helperText: false,
                      },
                    }}
                  />
                )}
              />
            </Grid2>
            <Grid2
              size={3}
              sx={{
                display: "flex",
                alignItems: "flex-end",
              }}
            >
              <LoadingButton
                fullWidth
                loading={KRALoading}
                variant="lightBg"
                size="large"
                onClick={() => {
                  handleGetKRAData({
                    phone: form.getValues("phone"),
                    pan: form.getValues("personalDetails.pan"),
                    dob: dayjs(
                      form.getValues("personalDetails.dateOfBirth"),
                    ).format("YYYY-MM-DD"),
                    company: +userData?.companyId,
                  });
                }}
              >
                Fetch Details
              </LoadingButton>
            </Grid2>
          </Show.When>
          <Grid2 size={form.getValues("type") !== "manual" ? 3 : 6}>
            <Controller
              name="personalDetails.name"
              control={form.control}
              rules={{
                required: {
                  value: form.getValues("activeStep") === 1,
                  message: "Please enter name",
                },
                validate: validateNotWhitespaceOnly,
              }}
              render={({ field, fieldState: { error } }) => (
                <GKTextField
                  {...field}
                  inputLabel="Name"
                  requiredField
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
          </Grid2>
          <Grid2 size={form.getValues("type") !== "manual" ? 3 : 6}>
            <Controller
              name="personalDetails.email"
              control={form.control}
              rules={{
                required: {
                  value: form.getValues("activeStep") === 1,
                  message: "Please enter email",
                },
                pattern: {
                  value: emailRegex,
                  message: "Please enter a valid mail",
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <GKTextField
                  {...field}
                  inputLabel="Email"
                  requiredField
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
          </Grid2>
          <Show.When isTrue={userData?.userRole === UserRoleTypes.advisor}>
            <Grid2 size={form.getValues("type") !== "manual" ? 3 : 6}>
              <Controller
                name="partner"
                control={form.control}
                render={({ field }) => (
                  <GKSearchSelect
                    disableClearable={false}
                    inputLabel="Partner"
                    options={partnerList || []}
                    onChange={(e, val) => {
                      field.onChange(val.value);
                    }}
                    getOptionLabel={(option) => option.name || option}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder={handleReturnAutocompletePlaceholder({
                          placeholder: "Select Partner",
                          value: field.value,
                        })}
                      />
                    )}
                  />
                )}
              />
            </Grid2>
          </Show.When>
          <Show.When isTrue={form.getValues("type") !== "manual"}>
            <Grid2 size={3}>
              <Controller
                name="family"
                control={form.control}
                render={({ field, formState: { errors } }) => (
                  <GKSearchSelect
                    {...field}
                    disableClearable
                    inputLabel="Family"
                    options={
                      familyList?.map((x: any) => ({
                        name: x.name,
                        value: x.id,
                      })) || []
                    }
                    onChange={(e, val) => {
                      field.onChange(val?.value);
                    }}
                    value={
                      familyList?.find(
                        (data: any) => +data?.id === +field.value,
                      )?.name
                    }
                    getOptionLabel={(option) => option.name || option}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="Family"
                        placeholder={handleReturnAutocompletePlaceholder({
                          placeholder: "Select Family",
                          value: field.value,
                        })}
                        error={Boolean(errors?.family)}
                        helperText={errors?.family?.message as string}
                      />
                    )}
                  />
                )}
              />
            </Grid2>
            <Grid2 size={12}>
              <Typography fontWeight={600} fontSize={20}>
                Personal Details
              </Typography>
            </Grid2>
            <Grid2 size={12} container spacing={1}>
              <Grid2 size={6}>
                <Controller
                  name="personalDetails.gender"
                  control={form.control}
                  render={({ field }) => (
                    <GKSearchSelect
                      {...field}
                      disableClearable={false}
                      inputLabel="Gender"
                      options={PERSONAL_GENDER || []}
                      onChange={(event, val) => {
                        field.onChange(val?.value);
                      }}
                      getOptionLabel={(option) => option.name || option}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder={handleReturnAutocompletePlaceholder({
                            placeholder: "Select Gender",
                            value: field.value,
                          })}
                        />
                      )}
                    />
                  )}
                />
              </Grid2>
              <Grid2 size={6}>
                <Controller
                  name="personalDetails.maritalStatus"
                  control={form.control}
                  render={({ field }) => (
                    <GKSelect {...field} inputLabel="Marital Status">
                      <MenuItem style={{ display: "none" }} value="">
                        Select Marital Status
                      </MenuItem>
                      {MARITAL_STATUS?.map((data) => (
                        <MenuItem value={data.value} key={data.value}>
                          {data.name}
                        </MenuItem>
                      ))}
                    </GKSelect>
                  )}
                />
              </Grid2>
              <Grid2 size={6}>
                <Controller
                  name="personalDetails.fatherName"
                  control={form.control}
                  render={({ field }) => (
                    <GKTextField {...field} inputLabel="Father's Name" />
                  )}
                />
              </Grid2>
              <Grid2 size={6}>
                <Controller
                  name="personalDetails.motherName"
                  control={form.control}
                  render={({ field }) => (
                    <GKTextField {...field} inputLabel="Mother's Name" />
                  )}
                />
              </Grid2>
              <Grid2 size={4}>
                <Controller
                  name="personalDetails.emergencyContact"
                  control={form.control}
                  render={({ field }) => (
                    <GKTextField
                      {...field}
                      type="number"
                      inputLabel="ُEmergency Contact Number"
                    />
                  )}
                />
              </Grid2>
              <Grid2 size={4}>
                <Controller
                  name="personalDetails.income"
                  control={form.control}
                  render={({ field }) => (
                    <GKTextField {...field} type="number" inputLabel="Income" />
                  )}
                />
              </Grid2>
              <Grid2 size={4}>
                <Controller
                  name="personalDetails.occupation"
                  control={form.control}
                  render={({ field }) => (
                    <GKTextField {...field} inputLabel="Occupation" />
                  )}
                />
              </Grid2>
              <Grid2 size={12}>
                <Controller
                  name="personalDetails.address"
                  control={form.control}
                  render={({ field }) => (
                    <GKTextField {...field} inputLabel="Address" />
                  )}
                />
              </Grid2>
              <Grid2 size={3}>
                <Controller
                  name="personalDetails.country"
                  control={form.control}
                  rules={{
                    required: {
                      value: true,
                      message: "Please select a country",
                    },
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <GKSearchSelect
                      {...field}
                      requiredField
                      inputLabel="Country"
                      renderInput={(er) => (
                        <TextField
                          {...er}
                          placeholder={handleReturnAutocompletePlaceholder({
                            placeholder: "Select Country",
                            value: field.value,
                          })}
                          error={!!error}
                          helperText={error?.message}
                        />
                      )}
                      options={countryList || []}
                      getOptionLabel={(e) => e.name || e}
                      onChange={(e, val) => {
                        field.onChange(val.name);
                        form.setValue("personalDetails.city", "");
                        form.setValue("personalDetails.state", "");
                        dispatch({
                          type: "SELECTED_COUNTRY",
                          data: val.id,
                        });
                      }}
                    />
                  )}
                />
              </Grid2>
              <Grid2 size={3}>
                <Controller
                  name="personalDetails.state"
                  control={form.control}
                  rules={{
                    required: {
                      value: true,
                      message: "Please select a state",
                    },
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <GKSearchSelect
                      {...field}
                      inputLabel="State"
                      requiredField
                      renderInput={(er) => (
                        <TextField
                          {...er}
                          placeholder={handleReturnAutocompletePlaceholder({
                            placeholder: "Select State",
                            value: field.value,
                          })}
                          error={!!error}
                          helperText={error?.message}
                        />
                      )}
                      options={stateList || []}
                      getOptionLabel={(e) => e.name || e}
                      onChange={(e, val) => {
                        field.onChange(val.name);
                        form.setValue("personalDetails.city", "");
                        dispatch({
                          type: "SELECTED_STATE",
                          data: val.id,
                        });
                      }}
                    />
                  )}
                />
              </Grid2>
              <Grid2 size={3}>
                <Controller
                  name="personalDetails.city"
                  control={form.control}
                  render={({ field, fieldState: { error } }) => (
                    <GKSearchSelect
                      {...field}
                      inputLabel="City"
                      renderInput={(er) => (
                        <TextField
                          {...er}
                          placeholder={handleReturnAutocompletePlaceholder({
                            placeholder: "Select City",
                            value: field.value,
                          })}
                          error={!!error}
                          helperText={error?.message}
                        />
                      )}
                      options={cityList || []}
                      getOptionLabel={(e) => e.name || e}
                      onChange={(e, val) => {
                        field.onChange(val.name);
                        dispatch({
                          type: "SELECTED_CITY",
                          data: val.id,
                        });
                      }}
                    />
                  )}
                />
              </Grid2>
              <Grid2 size={3}>
                <Controller
                  name="personalDetails.pincode"
                  control={form.control}
                  render={({ field }) => (
                    <GKTextField {...field} inputLabel="Pincode" />
                  )}
                />
              </Grid2>
            </Grid2>
            <Grid2 size={12}>
              <Typography fontWeight={600} fontSize={20}>
                Bank
              </Typography>
            </Grid2>
            <Grid2 size={12}>
              <ClientBank />
            </Grid2>
            <Grid2 size={12}>
              <Typography fontWeight={600} fontSize={20}>
                Demat
              </Typography>
            </Grid2>
            <Grid2 size={12}>
              <ClientDemat />
            </Grid2>
            <Grid2 size={12}>
              <Typography fontWeight={600} fontSize={20}>
                Nominee
              </Typography>
            </Grid2>
            <Grid2 size={12}>
              <ClientNominee />
            </Grid2>
          </Show.When>
        </Grid2>
      </Grid2>
      {isBranchCreateModalOpen && (
        <AddBranchModal
          open={isBranchCreateModalOpen}
          updateData={null}
          setOpen={setIsBranchCreateModalOpen}
          isEditModal={false}
        />
      )}
      {isFamilyCreateModalOpen && (
        <FamilyCreateModal
          open={isFamilyCreateModalOpen}
          setOpen={setIsFamilyCreateModalOpen}
        />
      )}
    </form>
  );
};

export default AdvisorOnboardingClientDetails;
