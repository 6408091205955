import { Grid2, Skeleton } from "components";
import dayjs from "dayjs";

export const feePlanTimePeriod = [
  {
    name: "Monthly",
    stringVaue: "MONTHLY",
    value: 1,
  },
  {
    name: "Quarterly",
    stringVaue: "QUARTERLY",
    value: 3,
  },
  {
    name: "Semiannually",
    stringVaue: "SEMIANNUALLY",
    value: 6,
  },
  {
    name: "Yearly",
    stringVaue: "YEARLY",
    value: 12,
  },
];

export const SkeletonLoading = () => (
  <Grid2 container spacing={2}>
    <Grid2 size={12} pt={2}>
      {/* <Skeleton variant="text" width="60%" height={40} /> */}
    </Grid2>
    <Grid2
      size={{ xs: 12, md: 6 }}
      sx={{ m: { xs: "", md: "auto" } }}
      display={"flex"}
      justifyContent={"center"}
      flexDirection={"column"}
    >
      <Skeleton variant="rectangular" width="100%" height={200} />
      <Skeleton variant="text" width="80%" height={30} />
      <Skeleton variant="text" width="60%" height={20} />
      <Skeleton variant="rectangular" width="100%" height={200} />
      <Skeleton variant="text" width="80%" height={30} />
      <Skeleton variant="text" width="60%" height={20} />
    </Grid2>
    {/* <Grid2 size={{ xs: 12, md: 6 }}>
      <Skeleton variant="rectangular" width="100%" height={200} />
      <Skeleton variant="text" width="80%" height={30} />
      <Skeleton variant="text" width="60%" height={20} />
    </Grid2> */}
  </Grid2>
);

export const isTestingEnvironment = ["stage.gridkey.in", "localhost"].includes(
  window.location.hostname,
);

export const daysCalculate = (frequency: string) => {
  const startData = dayjs();

  const frequencyCount = +feePlanTimePeriod.find(
    (d) => d.stringVaue === frequency,
  )?.value;

  const endDate = startData.add(frequencyCount, "month");

  return endDate.diff(startData, "days") || 0;
};
