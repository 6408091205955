import {
  Button,
  ConfirmDeleteModal,
  Divider,
  GKSearchSelect,
  Grid2,
  Show,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tabs,
  TextField,
  Typography,
} from "components";
import dayjs from "dayjs";
import { motion } from "framer-motion";
import DashboardLayout from "layouts/DashboardLayout";
import React from "react";
import { numberToCurrency } from "utils/MathFunction";
import { handleReturnAutocompletePlaceholder } from "utils/StringFunctions";
import { UserRoleTypes } from "utils/common";
import ModelBankLedger from "./ModelBankLedger";
import ModelHoldings from "./ModelHoldings";
import ModelOverview from "./ModelOverview";
import ModelTradebook from "./ModelTradebook";
import VersionUploadModal from "./VersionUploadModal";
import useModelPortfolioVersion from "./useModelPortfolioVersion";

const ViewModelPortfolioVersion = () => {
  const {
    theme,
    openUploadModal,
    setOpenUploadModal,
    form,
    tabs,
    setTabs,
    editModelPortfolioVersion,
    getDataByIdLoading,
    modalPortfolioDelete,
    modelPortdolioData,
    modelCardData,
    getAllOldModels,
    setOldIdSelected,
    oldIdSelected,
    modalPortfolioDefault,
    userData,
    navigate,
    modelPortfolioId,
    id,
  } = useModelPortfolioVersion();

  const [deleteConfirmation, setDeleteConfirmation] = React.useState(false);

  return (
    <DashboardLayout title="Add Model Portfolio" loading={getDataByIdLoading}>
      <Grid2 container spacing={1}>
        <Grid2 size={4}>
          <Typography fontWeight={600} fontSize={18}>
            {modelPortdolioData?.data?.version}
          </Typography>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: 4,
            }}
          >
            <Typography
              fontWeight={400}
              fontSize={12}
              color={theme.palette.grey[600]}
            >
              Model Creation Date
            </Typography>
            <Typography fontWeight={600} fontSize={14}>
              {dayjs(modelPortdolioData?.data?.date).format("DD MMM YYYY")}
            </Typography>
          </div>
        </Grid2>
        <Grid2
          size={8}
          display={"flex"}
          justifyContent={"flex-end"}
          alignItems={"center"}
          gap={1}
        >
          <Show.When isTrue={modelPortfolioId && !editModelPortfolioVersion}>
            <GKSearchSelect
              options={getAllOldModels?.data?.data || []}
              getOptionLabel={(opt) => opt?.snapshotName || opt}
              onChange={(e, val) => {
                setOldIdSelected(val);
              }}
              sx={{
                width: "300px",
              }}
              disableClearable={false}
              renderInput={(props) => {
                return (
                  <TextField
                    {...props}
                    placeholder={handleReturnAutocompletePlaceholder({
                      placeholder: "Select Version",
                      value: oldIdSelected,
                    })}
                  />
                );
              }}
            />
          </Show.When>
          <Show.When isTrue={userData?.userRole === UserRoleTypes.advisor}>
            <Show.When
              isTrue={
                modelPortfolioId &&
                !editModelPortfolioVersion &&
                !modelPortdolioData?.data?.rebalanced
              }
            >
              <Button
                variant="outlined"
                color="dark"
                onClick={() => {
                  navigate(
                    `/app/equity-basket/${id}/model-portfolio/${modelPortfolioId}/edit`,
                  );
                }}
              >
                Edit Model
              </Button>
            </Show.When>
            <Show.When
              isTrue={
                modelPortfolioId &&
                !editModelPortfolioVersion &&
                !modelPortdolioData?.data?.rebalanced &&
                !modelPortdolioData?.data?.primary
              }
            >
              <Button
                variant="outlined"
                color="error"
                onClick={() => {
                  setDeleteConfirmation(true);
                }}
              >
                Delete Model
              </Button>
            </Show.When>
            <Show.When
              isTrue={
                modelPortfolioId &&
                !editModelPortfolioVersion &&
                !modelPortdolioData?.data?.rebalanced &&
                !modelPortdolioData?.data?.primary
              }
            >
              <Button
                variant="outlined"
                color="success"
                onClick={() => {
                  modalPortfolioDefault(modelPortfolioId);
                }}
              >
                Make As Default
              </Button>
            </Show.When>
          </Show.When>
        </Grid2>
        <Grid2 size={12}>
          <TableContainer>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell
                    width={"16.6%"}
                    sx={{
                      borderRight: `1px solid ${theme.palette.grey[300]}`,
                    }}
                  >
                    <Typography
                      fontWeight={400}
                      fontSize={12}
                      color={theme.palette.grey[600]}
                    >
                      Net Contribution
                    </Typography>
                    <Typography fontWeight={600} fontSize={14}>
                      {numberToCurrency(
                        modelCardData?.data?.data?.netContribution,
                      )}
                    </Typography>
                  </TableCell>
                  <TableCell
                    width={"16.6%"}
                    sx={{
                      borderBottom: `1px solid ${theme.palette.grey[300]}`,
                      borderRight: `1px solid ${theme.palette.grey[300]}`,
                    }}
                  >
                    <Typography
                      fontWeight={400}
                      fontSize={12}
                      color={theme.palette.grey[600]}
                    >
                      {" "}
                      Portfolio Value
                    </Typography>
                    <Typography fontWeight={600} fontSize={14}>
                      {numberToCurrency(
                        modelPortdolioData?.data?.portfolioValue,
                      )}
                    </Typography>
                  </TableCell>
                  <TableCell
                    width={"16.6%"}
                    sx={{
                      borderRight: `1px solid ${theme.palette.grey[300]}`,
                      borderBottom: `1px solid ${theme.palette.grey[300]}`,
                    }}
                  >
                    <Typography
                      fontWeight={400}
                      fontSize={12}
                      color={theme.palette.grey[600]}
                    >
                      Ledger Balance
                    </Typography>
                    <Typography fontWeight={600} fontSize={14}>
                      {numberToCurrency(
                        modelPortdolioData?.data?.ledgerBalance,
                      )}
                    </Typography>
                  </TableCell>
                  <TableCell
                    width={"16.6%"}
                    sx={{
                      borderRight: `1px solid ${theme.palette.grey[300]}`,
                    }}
                  >
                    <Typography
                      fontWeight={400}
                      fontSize={12}
                      color={theme.palette.grey[600]}
                    >
                      Dividend
                    </Typography>
                    <Typography fontWeight={600} fontSize={14}>
                      {numberToCurrency(modelCardData?.data?.data?.dividend)}
                    </Typography>
                  </TableCell>
                  <TableCell
                    width={"16.6%"}
                    sx={{
                      borderBottom: `1px solid ${theme.palette.grey[300]}`,
                      borderRight: `1px solid ${theme.palette.grey[300]}`,
                    }}
                  >
                    <Typography
                      fontWeight={400}
                      fontSize={12}
                      color={theme.palette.grey[600]}
                    >
                      Minimum Investement Value
                    </Typography>
                    <Typography fontWeight={600} fontSize={14}>
                      {numberToCurrency(
                        modelPortdolioData?.data?.minimumInvestement,
                      )}
                    </Typography>
                  </TableCell>
                  <TableCell
                    width={"16.6%"}
                    sx={{
                      borderBottom: `1px solid ${theme.palette.grey[300]}`,
                      borderRight: `1px solid ${theme.palette.grey[300]}`,
                    }}
                  >
                    <Typography
                      fontWeight={400}
                      fontSize={12}
                      color={theme.palette.grey[600]}
                    >
                      XIRR
                    </Typography>
                    <Typography fontWeight={600} fontSize={14}>
                      {modelCardData?.data?.data?.absoluteReturn
                        ? `${modelCardData?.data?.data?.absoluteReturn}%`
                        : "-"}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid2>
        <Grid2 size={12}>
          <Tabs
            value={tabs}
            onChange={(e, val) => {
              setTabs(val);
            }}
          >
            <Tab label="Overview" />
            <Tab label="Holdings" />
            <Tab label="Trade Book" />
            <Tab label="Ledger" />
          </Tabs>
          <Divider />
        </Grid2>
        <Grid2 size={12}>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            <Show.When isTrue={tabs === 0}>
              <ModelOverview
                selectedId={modelPortfolioId}
                oldIdSelected={oldIdSelected}
              />
            </Show.When>
            <Show.When isTrue={tabs === 1}>
              <ModelHoldings
                selectedId={modelPortfolioId}
                oldIdSelected={oldIdSelected}
              />
            </Show.When>
            <Show.When isTrue={tabs === 2}>
              <ModelTradebook
                selectedId={modelPortfolioId}
                oldIdSelected={oldIdSelected}
              />
            </Show.When>
            <Show.When isTrue={tabs === 3}>
              <ModelBankLedger
                selectedId={modelPortfolioId}
                oldIdSelected={oldIdSelected}
              />
            </Show.When>
          </motion.div>
        </Grid2>
      </Grid2>

      {openUploadModal && (
        <VersionUploadModal
          open={openUploadModal}
          form={form}
          setOpen={setOpenUploadModal}
        />
      )}

      {deleteConfirmation && (
        <ConfirmDeleteModal
          open={deleteConfirmation}
          setOpen={setDeleteConfirmation}
          onDelete={() => modalPortfolioDelete(modelPortfolioId)}
          title="Model Portfolio"
          isDelete
        />
      )}
    </DashboardLayout>
  );
};

export default ViewModelPortfolioVersion;
