import {
  AiOutlineDelete,
  Button,
  Card,
  CgMathPlus,
  Divider,
  GKClientDataGrid,
  Grid2,
  IconButton,
  Typography,
} from "components";
import { EquitybasketFormInterface } from "interfaces/EquityBasket";
import { Controller, useFormContext } from "react-hook-form";
import { useParams } from "react-router-dom";
import { paletteLightTheme } from "utils/ColorTheme";
import { handleCreateFormData } from "utils/formHelpers";
import { ThemeContainer } from "utils/Theme";
import EquityBasketFeePlanSelection from "../EquityBasketFeePlanSelection";

const FeePlansTab = ({
  handleSetFeeDefault,
  handleUpdateEquityBasket,
  updateLoading,
  openFeePlanModal,
  setOpenFeePlanModal,
  feePlanHistoryData,
}: {
  handleSetFeeDefault: any;
  handleUpdateEquityBasket: any;
  updateLoading: any;
  openFeePlanModal: any;
  feePlanHistoryData: {
    id: number;
    name: string;
    startDate: string;
    endDate: string;
  }[];
  setOpenFeePlanModal: any;
}) => {
  const { theme } = ThemeContainer();

  const { id } = useParams();

  const form = useFormContext<EquitybasketFormInterface>();

  return (
    <>
      <Grid2 container spacing={2}>
        <Grid2
          size={12}
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography>Select Fee Plans</Typography>
          <Button
            variant="contained"
            startIcon={<CgMathPlus size={16} />}
            onClick={() => {
              setOpenFeePlanModal(true);
            }}
          >
            Add Fee Plans
          </Button>
        </Grid2>
        <Grid2
          size={12}
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: 8,
          }}
        >
          {form?.getValues("feePlansList")?.map((item, index) => {
            return (
              <Card
                sx={{ p: 1, minWidth: "250px", maxWidth: "250px" }}
                key={index}
              >
                <Grid2
                  container
                  justifyContent={"space-between"}
                  flexDirection={"row"}
                  spacing={1}
                >
                  <Grid2>
                    <Button
                      variant={item?.isDefault ? "contained" : "outlined"}
                      color={item?.isDefault ? "primary" : "dark"}
                      size="extraSmall"
                      onClick={() => {
                        handleSetFeeDefault(item?.id);
                        handleUpdateEquityBasket(
                          handleCreateFormData({
                            values: form.getValues(),
                            dirtyFields: form.formState.dirtyFields,
                          }),
                        );
                      }}
                      disabled={!id}
                    >
                      {item?.isDefault ? "Default" : "Set As Default"}
                    </Button>
                  </Grid2>
                  <Grid2>
                    {!item?.isDefault && (
                      <Controller
                        name="feePlans"
                        control={form.control}
                        render={({ field }) => {
                          return (
                            <IconButton
                              color="error"
                              onClick={() => {
                                const newFeePlans = form
                                  ?.getValues("feePlans")
                                  ?.filter(
                                    (filterData) => +item?.id !== +filterData,
                                  );
                                const newFeePlansList = form
                                  ?.getValues("feePlansList")
                                  ?.filter(
                                    (filterData) =>
                                      +item?.id !== +filterData?.id,
                                  );
                                field.onChange(newFeePlans);
                                form.setValue("feePlansList", newFeePlansList);
                              }}
                            >
                              <AiOutlineDelete />
                            </IconButton>
                          );
                        }}
                      />
                    )}
                  </Grid2>
                  <Grid2 size={12}>
                    <Typography fontWeight={600} fontSize={16}>
                      {item.name || "-"}
                    </Typography>
                  </Grid2>
                  {item.managementFee?.fee && (
                    <Grid2
                      size={5}
                      display={"flex"}
                      justifyContent={"center"}
                      flexDirection={"column"}
                    >
                      <Typography
                        textTransform={"capitalize"}
                        color={theme.palette.secondaryText.main}
                        fontSize={12}
                      >
                        {item.managementFee?.name || "-"}
                      </Typography>
                      <Typography fontWeight={600}>
                        {item.managementFee?.fee || "-"}
                      </Typography>
                    </Grid2>
                  )}
                  {item.performanceFee?.fee && item.managementFee?.fee && (
                    <Grid2
                      size={2}
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"center"}
                    >
                      <div
                        style={{
                          border: `1px solid ${paletteLightTheme.palette.border.main}`,
                          borderRadius: 4,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          padding: 4,
                        }}
                      >
                        <CgMathPlus color={theme.palette.secondaryText.main} />
                      </div>
                    </Grid2>
                  )}
                  {item.performanceFee?.fee && (
                    <Grid2
                      size={5}
                      display={"flex"}
                      justifyContent={"center"}
                      flexDirection={"column"}
                    >
                      <Typography
                        textTransform={"capitalize"}
                        color={theme.palette.secondaryText.main}
                        fontSize={12}
                      >
                        {item.performanceFee?.name || "-"}
                      </Typography>
                      <Typography fontWeight={600}>
                        {item.performanceFee?.fee || "-"}
                      </Typography>
                    </Grid2>
                  )}
                </Grid2>
              </Card>
            );
          })}
        </Grid2>
        <Grid2 size={12}>
          <Divider />
        </Grid2>
        <Grid2 size={12}>
          <GKClientDataGrid
            columns={[
              {
                field: "name",
                flex: 1,
                disableColumnMenu: true,
              },
              {
                field: "startDate",
                headerName: "Start Date",
                flex: 1,
                disableColumnMenu: true,
              },
              {
                field: "endDate",
                headerName: "End Date",
                flex: 1,
                disableColumnMenu: true,
              },
            ]}
            rows={feePlanHistoryData || []}
            name="equityBasketFeePlan"
          />
        </Grid2>
      </Grid2>
      {openFeePlanModal && (
        <EquityBasketFeePlanSelection
          open={openFeePlanModal}
          setOpen={setOpenFeePlanModal}
          updateLoading={updateLoading}
          handleUpdateEquityBasket={handleUpdateEquityBasket}
        />
      )}
    </>
  );
};

export default FeePlansTab;
