/* eslint-disable no-else-return */
/* eslint-disable no-unsafe-optional-chaining */
import { LoadingButton } from "@mui/lab";
import { GridColDef } from "@mui/x-data-grid-pro";
import CreatePortfolioModal from "Pages/EquityEtf/ClientModal";
import {
  Alert,
  Box,
  BsExclamationOctagon,
  Card,
  Fade,
  GKButton,
  GKClientDataGrid,
  GKDatePicker,
  GKModal,
  GKSearchSelect,
  GKTextField,
  GoDotFill,
  Grid2,
  PiWarningOctagonLight,
  Show,
  StatusField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "components";
import dayjs from "dayjs";
import { useUploadmanager } from "hooks/useUploadmanager";
import { handleRemoveUnderscore } from "utils";
import { moneyCommaSeparator } from "utils/MathFunction";
import { ThemeContainer } from "utils/Theme";
import { handleCapitalize } from "utils/common";
import { GKModalProps } from "utils/commonInterface";
import { extraColumnsField, matchDate } from "utils/helpers";
import AddFavoriteTemplate from "./AddFavoriteTemplate";
import UploadManagerStepperModal from "./UploadManagerStepperModal";
import UploadStepOne from "./UploadStepOne";

interface Props {
  fileData?: any;
  isApproveModal?: boolean;
}

const UploadManagerModal = ({
  open,
  setOpen,
  isApproveModal,
  fileData,
}: Props & GKModalProps) => {
  const { theme } = ThemeContainer();

  const {
    isLoading,
    setIsLoading,
    activeStep,
    formik,
    handleReset,
    handleModalTitle,
    reviewResponse,
    favoriteUploadSamples,
    setShowFavoriteModal,
    menuOptions,
    portfolioFormik,
    portfolios,
    isPortfolioCreateModalOpen,
    setIsPortfolioCreateModalOpen,
    companyFormik,
    companyNames,
    userInfo,
    showFavoriteModal,
    favoriteRefetch,
    handleRowsReturn,
    downloadSingleRowErrors,
    handleDeleteFav,
    handleAddToFav,
    setActiveStep,
    facLoading,
    handleNext,
    allFiles,
    setAllFiles,
    handleCheckPassword,
    selectedId,
    setSelectedId,
    handleCheckDate,
    handleReturnSize,
    handleDownloadSampleFiles,
    templatesTabs,
    setTemplatesTabs,
    setSelectedIndex,
    selectedIndex,
    handleReviewComplete,
    handleReviewPortfolio,
  } = useUploadmanager({ setOpen, isApproveModal, fileData });

  const dataGridColumn: (GridColDef & { visibleType?: string[] })[] = [
    {
      ...extraColumnsField("Date", "date", 170, 1),
      valueFormatter: (params) => matchDate(params?.value),
      editable: false,
      visibleType: ["peq", "cash", "mf", "stock"],
      renderCell: (params) => {
        return (
          <Box display={"flex"} alignItems={"center"} gap={1}>
            <Tooltip title="Duplicate Entry" arrow placement="right">
              <BsExclamationOctagon
                color={theme.palette.error.main}
                size={20}
                style={{
                  visibility: params?.row?.isDuplicate ? "visible" : "hidden",
                }}
              />
            </Tooltip>
            <Typography>{params?.row?.date}</Typography>
          </Box>
        );
      },
    },
    {
      ...extraColumnsField("Portfolio", "Portfolio", 170, 1),
      editable: false,
      valueGetter: (params: any) =>
        `${params.row.portfolioName} / ${params.row.portfolioCode}`,
      visibleType: ["peq", "cash", "mf", "stock", "portfolio", "margin"],
    },
    {
      ...extraColumnsField("Client name", "clientName", 150, 1),
      visibleType: ["portfolio"],
      valueGetter: (params) => {
        return params?.row?.client?.personalDetails?.name || "-";
      },
    },
    {
      ...extraColumnsField("Transaction Type", "transactionType", 170, 1),
      editable: false,
      visibleType: ["peq", "cash", "mf", "stock"],
      valueFormatter: (params) => handleCapitalize(params?.value),
    },
    {
      ...extraColumnsField(
        "Transaction Sub Type",
        "subTransactionType",
        150,
        1,
      ),
      valueFormatter: (params) => handleCapitalize(params?.value),
      visibleType: ["cash"],
      renderCell: (data: any) =>
        handleRemoveUnderscore(handleCapitalize(data?.value)) || " ",
    },
    {
      ...extraColumnsField("Amount", "billAmount", 150, 1),
      valueGetter: (data: any) => moneyCommaSeparator(data?.value),
      visibleType: ["peq", "cash", "mf", "stock"],
      align: "right",
      headerAlign: "right",
    },
    {
      visibleType: ["cash"],
      ...extraColumnsField("Remarks", "remarks", 150, 1),
      renderCell: (data: any) => data?.value || " ",
    },
    {
      visibleType: ["mf"],
      ...extraColumnsField("Folio No", "folioNo", 150, 1),
      editable: false,
    },
    {
      visibleType: ["peq", "mf", "stock"],
      ...extraColumnsField("Company Name", "companyName", 170, 1),
      editable: false,
    },
    {
      visibleType: ["mf"],
      ...extraColumnsField("Company Code", "isinCode", 170, 1),
      editable: false,
      valueGetter: (params: any) => `${params.row.isinCode || "-"}`,
    },
    {
      visibleType: ["mf", "stock"],
      ...extraColumnsField("Quantity", "quantity", 170, 1),
      editable: false,
    },
    {
      visibleType: ["peq", "mf", "stock"],
      ...extraColumnsField("Price", "price", 170, 1),
      editable: false,
    },
    {
      visibleType: ["peq", "mf", "stock"],
      ...extraColumnsField("STT", "stt", 170, 1),
      valueFormatter: (params: any) => moneyCommaSeparator(params?.value),
      editable: false,
    },
    {
      visibleType: ["peq", "mf", "stock"],
      ...extraColumnsField("Stamp Charges", "stampCharges", 170, 1),
      editable: false,
      valueFormatter: (params: any) => moneyCommaSeparator(params?.value),
    },
    {
      visibleType: ["peq", "mf", "stock"],
      ...extraColumnsField("Broker Name", "brokerName", 170, 1),
      editable: false,
    },
    {
      visibleType: ["stock"],
      ...extraColumnsField("BSE / NSE Code", "bseCode", 170, 1),
      editable: false,
      valueGetter: (params: any) =>
        `${params.row.bseCode || "-"} / ${params.row.nseCode || "-"}`,
    },
    {
      visibleType: ["peq", "stock"],
      ...extraColumnsField(
        "Buy Back Payout",
        "buyPayout",
        formik?.values?.fileObject?.version === "BUY_BACK_CSV" ? 0 : 120,
        1,
      ),
    },
    {
      visibleType: ["peq", "stock"],
      ...extraColumnsField("Brokerage", "brokerage", 170, 1),
    },
    {
      visibleType: ["peq", "stock"],
      ...extraColumnsField(
        "Amount With Brokerage",
        "amountWithBrokerage",
        200,
        1,
      ),
      editable: false,
      valueFormatter: (params: any) => moneyCommaSeparator(params?.value),
    },
    {
      visibleType: ["peq", "stock"],
      ...extraColumnsField("GST", "gst", 170, 1),
      valueFormatter: (params: any) => moneyCommaSeparator(params?.value),
      editable: false,
    },

    {
      visibleType: ["peq", "stock"],
      ...extraColumnsField("Transaction Charges", "transactionCharges", 170, 1),
      editable: false,
      valueFormatter: (params: any) => moneyCommaSeparator(params?.value),
    },
    {
      visibleType: ["peq", "stock"],
      ...extraColumnsField("Sebi Turnover Fees", "sebiTurnoverFees", 170, 1),
      editable: false,
      valueFormatter: (params: any) => moneyCommaSeparator(params?.value),
    },
    {
      visibleType: ["peq", "stock"],
      ...extraColumnsField("Other Charges", "otherCharges", 170, 1),
      align: "right",
      headerAlign: "right",
      valueGetter: (params) => params.row.otherCharges || "0",
      valueFormatter: (params) => moneyCommaSeparator(params.value),
    },

    {
      visibleType: ["stock"],
      ...extraColumnsField("Contract Note Number", "contractNoteNum", 170, 1),
      editable: false,
    },
    {
      visibleType: ["portfolio"],
      ...extraColumnsField("Phone Number", "phoneNumber", 150, 1),
      valueGetter: (params) => {
        return params?.row?.client?.phone || "-";
      },
    },
    {
      visibleType: ["portfolio"],
      ...extraColumnsField("PAN", "pan", 150, 1),
      valueGetter: (params) => {
        return params?.row?.client?.personalDetails?.pan || "-";
      },
    },
    {
      visibleType: ["portfolio"],
      ...extraColumnsField("Email", "email", 150, 1),
      valueGetter: (params) => {
        return params?.row?.client?.personalDetails?.email || "-";
      },
    },
    {
      visibleType: ["portfolio"],
      ...extraColumnsField("Joining Date", "joiningDate", 150, 1),
    },
    {
      visibleType: ["portfolio"],
      ...extraColumnsField("Billing Cycle", "billingCycle", 150, 1),
    },
    {
      visibleType: ["portfolio"],
      ...extraColumnsField("AUM Fee", "aumFee", 150, 1),
      align: "right",
      headerAlign: "right",
    },
    {
      visibleType: ["portfolio"],
      ...extraColumnsField("Performance Fee", "phoperformanceFeene", 150, 1),
      align: "right",
      headerAlign: "right",
    },
    {
      visibleType: ["portfolio"],
      ...extraColumnsField("Soft Hurdle", "softHurdle", 150, 1),
    },
    {
      visibleType: ["portfolio"],
      ...extraColumnsField("Hard Hurdle", "hardHurdle", 150, 1),
    },
    {
      visibleType: ["portfolio"],
      ...extraColumnsField("Date Of Birth", "dateOfBirth", 150, 1),
      valueGetter: (params) => {
        return params?.row?.client?.personalDetails?.dateOfBirth || "-";
      },
    },
    {
      visibleType: ["portfolio"],
      ...extraColumnsField("Gender", "gender", 150, 1),
      valueGetter: (params) => {
        return params?.row?.client?.personalDetails?.gender || "-";
      },
    },
    {
      visibleType: ["portfolio"],
      ...extraColumnsField("Marital Status", "maritalStatus", 150, 1),
      valueGetter: (params) => {
        return params?.row?.client?.personalDetails?.maritalStatus || "-";
      },
    },
    {
      visibleType: ["portfolio"],
      ...extraColumnsField("Father's Name", "fatherName", 150, 1),
      valueGetter: (params) => {
        return params?.row?.client?.personalDetails?.fatherName || "-";
      },
    },
    {
      visibleType: ["portfolio"],
      ...extraColumnsField("Mother's Name", "motherName", 150, 1),
      valueGetter: (params) => {
        return params?.row?.client?.personalDetails?.motherName || "-";
      },
    },
    {
      visibleType: ["portfolio"],
      ...extraColumnsField("Family Name", "familyName", 150, 1),
      valueGetter: (params) => {
        return params?.row?.client?.family[0]?.name || "-";
      },
    },
    {
      visibleType: ["portfolio"],
      ...extraColumnsField("Nominee Added", "nominees", 150, 1),
      valueGetter: (params) => {
        return params?.row?.client?.nominees?.length || "-";
      },
    },
    {
      visibleType: ["portfolio"],
      ...extraColumnsField("Address", "address", 150, 1),
      valueGetter: (params) => {
        return params?.row?.client?.personalDetails?.address || "-";
      },
    },
    {
      visibleType: ["portfolio"],
      ...extraColumnsField("Occupation", "occupation", 150, 1),
      valueGetter: (params) => {
        return params?.row?.client?.personalDetails?.occupation || "-";
      },
    },
    {
      visibleType: ["portfolio"],
      ...extraColumnsField("Income", "income", 150, 1),
      valueGetter: (params) => {
        return params?.row?.client?.personalDetails?.income || "-";
      },
    },
    {
      visibleType: ["margin"],
      ...extraColumnsField("Available Margin", "brokerLedgerBalance", 150, 1),
      valueFormatter: (params) => {
        return moneyCommaSeparator(params.value || 0) || "-";
      },
    },
    {
      visibleType: ["peq", "cash", "mf", "stock", "portfolio", "margin"],
      ...extraColumnsField("Error", "error", 150, 1),
      renderCell: (data) => {
        return (
          <Tooltip placement="left-start" arrow title={data?.row?.error || "-"}>
            <Typography>{data?.row?.error || "-"}</Typography>
          </Tooltip>
        );
      },
    },
  ];

  return (
    <GKModal
      modalTitle={"Import"}
      open={open}
      setOpen={setOpen}
      width={"70%"}
      footer={
        <>
          <Show.When
            isTrue={templatesTabs === 2 && formik.values.fileBrokerType !== ""}
          >
            <Grid2 container justifyContent={"space-between"} size={12}>
              <GKButton
                variant="outlined"
                color="dark"
                onClick={() => {
                  formik.resetForm();
                  setActiveStep(0);
                  setIsLoading(false);
                  handleReset();
                  setAllFiles([]);
                  setSelectedIndex([]);
                }}
              >
                Cancel
              </GKButton>
              <div
                style={{
                  display: "flex",
                  gap: 8,
                }}
              >
                <GKButton
                  variant="outlined"
                  color="dark"
                  disabled={
                    formik.values?.file?.length === 0 ||
                    !formik.values.fileVersion ||
                    !formik.values.fileBrokerType ||
                    !formik.values.fileType
                  }
                  onClick={() => {
                    if (formik.values?.fileObject?.isPasswordRequired) {
                      handleCheckPassword(allFiles);
                    }
                    if (formik.values?.fileObject?.isDateRequired) {
                      handleCheckDate(allFiles);
                    }
                    handleNext();
                    setTemplatesTabs(0);
                    setAllFiles(
                      allFiles?.map((data) => ({
                        ...data,
                        inProgress: false,
                      })),
                    );
                  }}
                >
                  Use this template only once{" "}
                </GKButton>

                <GKButton
                  variant="contained"
                  disabled={
                    !formik.values.fileVersion ||
                    !formik.values.fileBrokerType ||
                    !formik.values.fileType
                  }
                  onClick={() => {
                    setShowFavoriteModal(true);
                  }}
                >
                  Save Template
                </GKButton>
              </div>
            </Grid2>
          </Show.When>
          <Show.When isTrue={templatesTabs !== 2}>
            <Grid2
              container
              justifyContent={activeStep !== 0 ? "space-between" : "flex-end"}
              gap={1}
              size={12}
            >
              {activeStep !== 0 && (
                <GKButton
                  variant="outlined"
                  color="dark"
                  size={"medium"}
                  onClick={() => {
                    formik.resetForm();
                    setActiveStep(0);
                    setIsLoading(false);
                    handleReset();
                    setSelectedIndex([]);
                    setAllFiles([]);
                  }}
                >
                  Cancel
                </GKButton>
              )}
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: 8,
                }}
              >
                {[0].includes(activeStep) && (
                  <GKButton
                    variant="contained"
                    size={"medium"}
                    disabled={
                      formik.values?.file?.length === 0 ||
                      !formik.values.fileVersion ||
                      !formik.values.fileBrokerType ||
                      !formik.values.fileType
                    }
                    onClick={() => {
                      if (
                        formik.values?.fileType === "CONTRACT_NOTE" ||
                        (formik?.values?.fileUploadType === "MF" &&
                          formik?.values?.fileBrokerType ===
                            "Mutual Funds Template")
                      ) {
                        handleCheckPassword(allFiles);
                      }
                      if (
                        formik.values?.fileVersion === "MOTILAL__XLSX_BULK__V0"
                      ) {
                        handleCheckDate(allFiles);
                      }
                      setAllFiles(
                        allFiles?.map((data) => ({
                          ...data,
                          inProgress: false,
                        })),
                      );
                      handleNext();
                    }}
                  >
                    Next
                  </GKButton>
                )}
                {activeStep === 6 && (
                  <GKButton
                    variant="contained"
                    size={"medium"}
                    onClick={() => {
                      formik.resetForm();
                      setActiveStep(0);
                      setIsLoading(false);
                      setShowFavoriteModal(false);
                      setIsPortfolioCreateModalOpen(false);
                      handleReset();
                      setOpen(false);
                      setAllFiles([]);
                      setSelectedIndex([]);
                    }}
                    disabled={
                      allFiles?.filter((data) => !data?.finished)?.length !== 0
                    }
                  >
                    Finish
                  </GKButton>
                )}
                {formik.values.fileUploadType !== "Portfolio" &&
                  [2, 3, 4].includes(activeStep) && (
                    <GKButton
                      variant="contained"
                      size={"medium"}
                      type="submit"
                      form={handleModalTitle(activeStep)?.form}
                      disabled={
                        formik.values.file?.length === 0 ||
                        formik.values.fileUploadType === "" ||
                        (formik.values.fileUploadType === "Stock" &&
                          formik.values.fileVersion === "")
                      }
                    >
                      Next
                    </GKButton>
                  )}
                {activeStep === 4 &&
                  formik.values.fileUploadType === "Portfolio" && (
                    <LoadingButton
                      onClick={() => {
                        setActiveStep(5);
                        handleReviewPortfolio(reviewResponse?.transactions);
                      }}
                      variant="contained"
                      size={"medium"}
                      loading={isLoading}
                    >
                      <Typography>Finish</Typography>
                    </LoadingButton>
                  )}
              </div>
            </Grid2>
          </Show.When>
        </>
      }
    >
      <>
        {activeStep === 0 && (
          <UploadStepOne
            formik={formik}
            id="upload-manager-form"
            templatesTabs={templatesTabs}
            setTemplatesTabs={setTemplatesTabs}
            favoriteUploadSamples={favoriteUploadSamples}
            setShowFavoriteModal={setShowFavoriteModal}
            menuOptions={menuOptions}
            facLoading={facLoading}
            showFavoriteModal={showFavoriteModal}
            favoriteRefetch={favoriteRefetch}
            handleDeleteFav={handleDeleteFav}
            handleAddToFav={handleAddToFav}
            setAllFiles={setAllFiles}
            selectedId={selectedId}
            setSelectedId={setSelectedId}
            handleDownloadSampleFiles={handleDownloadSampleFiles}
            portfolioFormik={portfolioFormik}
            companyFormik={companyFormik}
          />
        )}
        {activeStep !== 0 &&
          allFiles?.map((dataItem: any, index: any) => {
            return (
              <Grid2 container>
                <Show.When isTrue={!isApproveModal}>
                  <Grid2 size={12}>
                    <Card
                      sx={{
                        borderColor: theme.palette.grey[300],
                        my: 1,
                        p: 1,
                        borderRadius: 2,
                      }}
                    >
                      <Grid2 container alignItems={"center"}>
                        <Grid2
                          container
                          alignContent={"center"}
                          gap={1}
                          size={8}
                        >
                          <Grid2>
                            <img
                              src={`/FileIcons/${dataItem?.file?.name
                                .split(".")
                                .pop()}.svg`}
                              height={40}
                              width={40}
                              alt=""
                              style={{
                                borderRadius: 4,
                                border: "1px solid",
                                borderColor: theme.palette.grey[300],
                                padding: 8,
                              }}
                            />
                          </Grid2>
                          <Grid2>
                            <Typography>{dataItem?.file?.name}</Typography>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: 8,
                              }}
                            >
                              <Typography
                                color={theme.palette.secondaryText.main}
                                fontSize={12}
                              >
                                {handleReturnSize(dataItem?.file)}
                              </Typography>
                              <Show.When isTrue={dataItem?.data}>
                                <GoDotFill
                                  color={theme.palette.secondaryText.main}
                                />
                              </Show.When>
                              <Show.When isTrue={dataItem?.data}>
                                <Typography
                                  color={theme.palette.secondaryText.main}
                                  fontSize={12}
                                >
                                  {dataItem?.data?.remarks}
                                </Typography>
                              </Show.When>
                            </div>
                          </Grid2>
                        </Grid2>
                        <Grid2
                          size={4}
                          display={"flex"}
                          justifyContent={"flex-end"}
                          alignItems={"center"}
                        >
                          <Show.When
                            isTrue={
                              dataItem?.isDateRequired &&
                              !dataItem?.inProgress &&
                              !dataItem?.finished
                            }
                          >
                            <Grid2 mr={1}>
                              <GKDatePicker
                                value={dayjs(dataItem?.date)}
                                onChange={(event) => {
                                  setAllFiles(
                                    allFiles?.map(
                                      (item: any, itemIndex: number) => {
                                        return {
                                          ...item,
                                          date:
                                            itemIndex === index
                                              ? dayjs(event)
                                              : "",
                                        };
                                      },
                                    ),
                                  );
                                }}
                              />
                            </Grid2>
                          </Show.When>
                          <Show.When
                            isTrue={
                              dataItem?.hasPassword &&
                              !dataItem?.inProgress &&
                              !dataItem?.finished
                            }
                          >
                            <Grid2 mr={1}>
                              <GKTextField
                                placeholder="Password"
                                sx={{
                                  "& .MuiInputBase-input": {
                                    p: 1,
                                  },
                                }}
                                onChange={(event) => {
                                  setAllFiles(
                                    allFiles?.map(
                                      (item: any, itemIndex: number) => {
                                        return {
                                          ...item,
                                          password:
                                            itemIndex === index
                                              ? event?.target?.value
                                              : "",
                                        };
                                      },
                                    ),
                                  );
                                }}
                              />
                            </Grid2>
                          </Show.When>
                          <Show.When isTrue={!dataItem?.data?.id}>
                            <LoadingButton
                              disabled={isLoading || dataItem?.inProgress}
                              variant="contained"
                              onClick={() => {
                                formik.setFieldValue("file", [dataItem?.file]);
                                formik.setFieldValue("fileIndex", index);
                                formik.setFieldValue(
                                  "filePassword",
                                  dataItem?.password,
                                );
                                formik.setFieldValue("date", dataItem?.date);
                                setTimeout(() => {
                                  formik.handleSubmit();
                                }, 200);

                                setAllFiles(
                                  allFiles?.map((data, i) => ({
                                    ...data,
                                    inProgress: index === i,
                                  })),
                                );
                              }}
                            >
                              Import
                            </LoadingButton>
                          </Show.When>
                          <Show.When isTrue={dataItem?.data?.id}>
                            <StatusField
                              statusString={
                                dataItem?.data?.status || "Importing"
                              }
                            />
                          </Show.When>
                        </Grid2>
                      </Grid2>
                    </Card>
                  </Grid2>
                </Show.When>
                <Show.When
                  isTrue={isLoading && formik.values.fileIndex === index}
                >
                  <Grid2 size={12}>
                    <Fade in={isLoading}>
                      <Card
                        sx={{
                          borderColor: theme.palette.grey[300],
                          my: 1,
                          p: 1,
                          borderRadius: 2,
                          borderStyle: "dashed",
                        }}
                      >
                        <Grid2
                          container
                          justifyContent={"center"}
                          alignItems={"center"}
                        >
                          <Grid2 size={12} textAlign={"center"}>
                            <Typography
                              fontSize={16}
                              color={theme.palette.secondaryText.main}
                            >
                              Please wait, your file is being processed...
                            </Typography>
                          </Grid2>
                          <Grid2 size={12} textAlign={"center"}>
                            <UploadManagerStepperModal
                              activeStep={activeStep}
                              loading={isLoading}
                              formik={formik}
                            />
                          </Grid2>
                        </Grid2>
                      </Card>
                    </Fade>
                  </Grid2>
                </Show.When>
                <Show.When
                  isTrue={
                    !isLoading &&
                    activeStep === 2 &&
                    formik.values.fileIndex === index
                  }
                >
                  <Grid2 size={12}>
                    <form
                      onSubmit={portfolioFormik?.handleSubmit}
                      id={"portfolio-mapping-form"}
                      style={{
                        width: "100%",
                      }}
                    >
                      <TableContainer>
                        <Table>
                          <TableBody>
                            <TableRow>
                              <TableCell
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                }}
                              >
                                <Typography fontSize={16} />
                                <GKButton
                                  variant="outlined"
                                  size="small"
                                  onClick={() =>
                                    setIsPortfolioCreateModalOpen(true)
                                  }
                                  title="Add New Portfolio"
                                >
                                  Create New Portfolio
                                </GKButton>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                <Alert
                                  sx={{ mb: 2 }}
                                  severity="warning"
                                  icon={<PiWarningOctagonLight size={14} />}
                                >
                                  The Following portfolio codes we have not
                                  found in your account, please map it to
                                  already existing portfolios.
                                </Alert>
                                {portfolioFormik.values.nullPortfolios &&
                                  portfolioFormik.values.nullPortfolios
                                    ?.length !== 0 &&
                                  portfolioFormik.values.nullPortfolios.map(
                                    (item: any, index: number) => (
                                      <Grid2 size={12} my={1} key={index}>
                                        <GKSearchSelect
                                          disableClearable
                                          renderOption={(props, option) => {
                                            return (
                                              <li
                                                {...props}
                                                style={{
                                                  display: "flex",
                                                  flexDirection: "column",
                                                  alignItems: "flex-start",
                                                }}
                                              >
                                                <Typography fontWeight={600}>
                                                  {option.clientName}
                                                </Typography>
                                                <Typography>
                                                  {option.name}
                                                </Typography>
                                              </li>
                                            );
                                          }}
                                          options={portfolios || []}
                                          getOptionLabel={(option) =>
                                            option?.name || option
                                          }
                                          inputLabel={item?.value}
                                          renderInput={(params) => (
                                            <TextField
                                              name={`nullPortfolios.${index}.portfolio`}
                                              {...params}
                                            />
                                          )}
                                          onChange={(e, val) => {
                                            portfolioFormik?.setFieldValue(
                                              `nullPortfolios.${index}.portfolio`,
                                              val?.value,
                                            );
                                          }}
                                          onBlur={portfolioFormik?.handleBlur}
                                          value={
                                            portfolios?.find(
                                              (data: any) =>
                                                data?.id ===
                                                portfolioFormik.values
                                                  .nullPortfolios[index].value,
                                            )?.name
                                          }
                                        />
                                      </Grid2>
                                    ),
                                  )}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </form>
                  </Grid2>
                </Show.When>
                <Show.When
                  isTrue={
                    !isLoading &&
                    activeStep === 3 &&
                    formik.values.fileIndex === index
                  }
                >
                  <Grid2 size={12}>
                    <form
                      onSubmit={companyFormik?.handleSubmit}
                      id={"company-mapping-form"}
                    >
                      <TableContainer>
                        <Table>
                          <TableBody>
                            <TableRow>
                              <TableCell>
                                <Typography fontSize={16}>
                                  Company Mapping
                                </Typography>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                <Alert sx={{ mb: 2 }} severity="info">
                                  We couldn&#39;t find the following codes,
                                  please map them to the right companies.
                                </Alert>
                                {companyFormik.values.nullCompanies &&
                                  companyFormik.values.nullCompanies?.length !==
                                    0 &&
                                  companyFormik.values.nullCompanies.map(
                                    (item: any, index: number) => (
                                      <Grid2 size={12} my={1} key={index}>
                                        <GKSearchSelect
                                          disableClearable
                                          renderOption={(props, option) => (
                                            <li
                                              {...props}
                                              style={{
                                                display: "flex",
                                                alignItems: "center",
                                                gap: 4,
                                              }}
                                            >
                                              <Typography
                                                color={theme.palette.light.main}
                                                fontSize={8}
                                                style={{
                                                  background:
                                                    option?.instType === "EQ"
                                                      ? theme.palette.secondary
                                                          .main
                                                      : theme.palette.warning
                                                          .main,
                                                  padding: "2px 4px",
                                                  borderRadius: 4,
                                                }}
                                              >
                                                {option?.instType}
                                              </Typography>
                                              <Typography whiteSpace={"nowrap"}>
                                                {option?.name}
                                              </Typography>
                                            </li>
                                          )}
                                          options={
                                            companyNames?.data?.data || []
                                          }
                                          getOptionLabel={(option) =>
                                            option?.companyName || option
                                          }
                                          inputLabel={item?.advisorCompany}
                                          renderInput={(params) => (
                                            <TextField
                                              name={`nullCompanies.${index}.value`}
                                              {...params}
                                            />
                                          )}
                                          onChange={(e, val) => {
                                            companyFormik?.setFieldValue(
                                              `nullCompanies.${index}.value`,
                                              val.gridCode,
                                            );
                                            companyFormik?.setFieldValue(
                                              `nullCompanies.${index}.nseBseCode`,
                                              val.bse || val.nse,
                                            );
                                            companyFormik?.setFieldValue(
                                              `nullCompanies.${index}.company`,
                                              userInfo.companyId,
                                            );
                                          }}
                                          onBlur={companyFormik?.handleBlur}
                                          value={
                                            companyNames?.data?.data?.find(
                                              (data: any) =>
                                                data?.gridCode ===
                                                companyFormik.values
                                                  .nullCompanies[index].value,
                                            )?.companyName
                                          }
                                        />
                                      </Grid2>
                                    ),
                                  )}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </form>
                  </Grid2>
                </Show.When>
                <Show.When
                  isTrue={
                    !isLoading &&
                    activeStep === 4 &&
                    formik.values.fileIndex === index
                  }
                >
                  <form
                    id="review-completed-form"
                    onSubmit={() => handleReviewComplete(formik?.values)}
                  >
                    <Grid2 size={12} container spacing={1}>
                      <Grid2
                        size={12}
                        sx={{
                          display:
                            reviewResponse?.transactions?.filter(
                              (data: any) => data.isDuplicate,
                            )?.length === 0
                              ? "none"
                              : "flex",
                        }}
                      >
                        <Alert
                          severity="warning"
                          icon={<BsExclamationOctagon size={14} />}
                          style={{
                            width: "100%",
                          }}
                        >
                          There are duplicate found in the file that you have
                          uploaded please review your sheet
                        </Alert>
                      </Grid2>
                      <Grid2
                        size={12}
                        sx={{
                          display:
                            reviewResponse?.transactions?.filter(
                              (data: any) => data.error !== "",
                            )?.length === 0
                              ? "none"
                              : "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Alert
                          sx={{ width: "100%" }}
                          severity="error"
                          icon={<BsExclamationOctagon size={14} />}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <Typography>
                              There are errors found in the sheet. Please review
                              them and re-upload the file.
                            </Typography>
                            {formik?.values?.fileUploadType !== "Portfolio" && (
                              <GKButton
                                style={{
                                  marginLeft: 10,
                                  padding: 0,
                                }}
                                color="error"
                                variant="link"
                                onClick={() => {
                                  downloadSingleRowErrors(
                                    reviewResponse?.id,
                                    formik?.values?.file[0]?.name,
                                  );
                                }}
                              >
                                Download Errors
                              </GKButton>
                            )}
                          </div>
                        </Alert>
                      </Grid2>
                      <Grid2 size={12}>
                        <GKClientDataGrid
                          name="uploadManager"
                          density="compact"
                          pagination={false}
                          disableRowSelectionOnClick
                          keepNonExistentRowsSelected
                          checkboxSelection
                          maxHeight={"calc(100vh - 500px)"}
                          rows={reviewResponse?.transactions || []}
                          rowSelectionModel={selectedIndex}
                          onRowSelectionModelChange={(params) => {
                            setSelectedIndex(params);
                          }}
                          headerTitle={
                            <div style={{ marginRight: 8 }}>
                              <Typography
                                fontSize={14}
                                fontWeight={600}
                                whiteSpace={"nowrap"}
                              >
                                Review Imported data
                              </Typography>
                              <Typography
                                whiteSpace={"nowrap"}
                                fontSize={12}
                                color={theme?.palette?.grey[700]}
                              >
                                Total Entries:{" "}
                                {handleRowsReturn()?.length || "0"}
                              </Typography>
                            </div>
                          }
                          columns={
                            reviewResponse?.transactions &&
                            dataGridColumn?.filter((d) =>
                              d.visibleType.includes(
                                formik.values.fileObject.uploadFile?.toLowerCase(),
                              ),
                            )
                          }
                          initialState={{
                            columns: {
                              columnVisibilityModel: {
                                stt: false,
                                gst: false,
                                stampCharges: false,
                                otherCharges: false,
                                transactionCharges: false,
                                sebiTurnoverFees: false,
                              },
                            },
                            pagination: {
                              paginationModel: {
                                pageSize: 25,
                              },
                            },
                          }}
                        />
                      </Grid2>
                    </Grid2>
                  </form>
                </Show.When>
              </Grid2>
            );
          })}

        {isPortfolioCreateModalOpen && (
          <CreatePortfolioModal
            isModalOpen={isPortfolioCreateModalOpen}
            setIsModalOpen={setIsPortfolioCreateModalOpen}
          />
        )}

        {showFavoriteModal && (
          <AddFavoriteTemplate
            open={showFavoriteModal}
            setOpen={setShowFavoriteModal}
            favoriteRefetch={favoriteRefetch}
            otherData={formik.values}
            setTemplatesTabs={setTemplatesTabs}
          />
        )}
      </>
    </GKModal>
  );
};

export default UploadManagerModal;
