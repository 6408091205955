/** ========================================================================================================= */
export const riskDropdown = [
  "Conservative",
  "Very Conservative",
  "Moderate",
  "Aggressive",
  "Very Aggressive",
];
/** ========================================================================================================= */
export const volatilityDropdown = [
  {
    name: "High Volatile",
    value: "HIGH",
  },
  {
    name: "Medium Volatile",
    value: "MEDIUM",
  },
  {
    name: "Low Volatile",
    value: "LOW",
  },
];
/** ========================================================================================================= */
export const basketTypeDropdown = [
  {
    name: "Model Portfolio Basket",
    value: "Model",
  },
  {
    name: "Stock Recommendations Basket",
    value: "Asset",
  },
];
/** ========================================================================================================= */
export const timePerdioDropdown = [
  {
    name: "Short Term",
    value: "SHORT_TERM",
  },
  {
    name: "Medium Term",
    value: "MEDIUM_TERM",
  },
  {
    name: "Long Term",
    value: "LONG_TERM",
  },
];
/** ========================================================================================================= */
export const subscriptionDropdown = ["PMS", "Advisor", "Subscription"];

export const backetCategoriesData = [
  {
    name: "Thematic",
    value: "Theme",
  },
  {
    name: "Sector Trackers",
    value: "Trackers",
  },
  {
    name: "Target Date",
    value: "Target Date",
  },
  {
    name: "Smart Beta",
    value: "Smart Beta",
  },
  {
    name: "Quantitative Model",
    value: "Quant",
  },
  {
    name: "Factor*",
    value: "Basket Model",
  },
  {
    name: "Fundamental Model",
    value: "Fundamental",
  },
  {
    name: "Mixed Model",
    value: "Model",
  },
  {
    name: "Asset Allocation",
    value: "Asset Allocation",
  },
];

export const frequencyData = {
  MONTHLY: {
    name: "1 Month",
    value: 1,
  },
  QUARTERLY: {
    name: "3 Months",
    value: 3,
  },
  SEMIANNUALLY: {
    name: "6 Months",
    value: 6,
  },
  YEARLY: {
    name: "12 Months",
    value: 12,
  },
};

export const assetClassType = [
  {
    name: "Equity Large Cap",
    value: "Equity Large Cap",
    description:
      "If the asset class is Equity Large Cap, then all underlying portfolio constituents are selected from the Large Cap segment representing top 100 companies by market capitalisation listed on the NSE (National Stock Exchange of India) ",
  },
  {
    name: "Equity Mid Cap",
    value: "Equity Mid Cap",
    description:
      "If the asset class is Equity Mid Cap, then all underlying portfolio constituents are selected from the Mid Cap segment representing the companies ranked 101 to 250 by market capitalisation listed on the NSE (National Stock Exchange of India)",
  },
  {
    name: "Equity Large & Mid Cap",
    value: "Equity Large & Mid Cap",
    description:
      "If the asset class is Equity Large & Mid Cap, then all underlying portfolio constituents are selected from the Large & Mid Cap segment representing the top 250 companies by market capitalisation listed on the NSE (National Stock Exchange of India)",
  },
  {
    name: "Equity Small Cap",
    value: "Equity Small Cap",
    description:
      "If the asset class is Equity Small Cap, then all underlying portfolio constituents are selected from the Small Cap segment representing the companies ranked greater than 251 by market capitalisation listed on the NSE (National Stock Exchange of India)",
  },
  {
    name: "Equity Mid & Small Cap",
    value: "Equity Mid & Small Cap",
    description:
      "If the asset class is Equity Mid & Small Cap, then all underlying portfolio constituents are selected from the Mid & Small Cap segment representing the companies ranked greater than 100 by market capitalisation listed on the NSE (National Stock Exchange of India)",
  },
  {
    name: "Equity All Cap",
    value: "Equity All Cap",
    description:
      "If the asset class is Equity All Cap, then all the underlying portfolio constituents are selected from Multi Cap segment which may include companies from more than two of the Large Cap, Mid Cap, Small Cap categories as described above",
  },
];

export const comparableIndexData = [
  {
    name: "Equity Multi-Cap",
    value: "Equity Multi-Cap",
    description:
      "If the sum of weights of gold, silver, commodity, debt or REIT/INVIT constituents is greater than 50%, then comparable index is determined as Equity Multi-Cap",
  },
  {
    name: "Equity Large Cap",
    value: "Equity Large Cap",
    description:
      "If the sum of weights of large cap constituents is greater than 50%, then comparable index is determined as Equity Large Cap",
  },
  {
    name: "Equity Midcap",
    value: "Equity Midcap",
    description:
      "If the sum of weights of mid cap constituents is greater than 50%, then comparable index is determined as Equity Midcap",
  },
  {
    name: "Equity Small Cap",
    value: "Equity Small Cap",
    description:
      "If the sum of weights of small cap constituents is greater than 50%, then comparable index is determined as Equity Smallcap",
  },
  {
    name: "Equity Large & Midcap",
    value: "Equity Large & Midcap",
    description:
      "If the sum of weights of large cap constituents is greater than 30%, sum of weights of mid cap constituents are greater than 30%, and sum of weights of large cap and mid cap constituents are greater than 80%, then comparable index is determined as Equity Large & Midcap",
  },
  {
    name: "Equity Mid and Smallcap",
    value: "Equity Mid and Smallcap",
    description:
      "If the sum of weights of small cap constituents is greater than 30%, sum of weights of mid cap constituents are greater than 30%, and sum of weights of small cap and mid cap constituents are greater than 80%, then comparable index is determined as Equity Mid and Smallcap",
  },
  {
    name: "Equity Multi-cap",
    value: "Equity Multi-cap",
    description:
      "If none of the above conditions are met, then comparable index is determined as Equity Multi-cap",
  },
];

export const publishPlatformData = [
  { name: "Micro-Website", value: "microWebsite", disabled: false },
  { name: "Channel Partners", value: "channelPartners", disabled: false },
  {
    name: "Reporting Manager- Admins",
    value: "reportingManagerAdmins",
    disabled: true,
  },
  { name: "Gridkey partners", value: "gridkeyPartners", disabled: true },
];

export const methodologyButtons = [
  "Defining the Universe",
  "Research",
  "Constituent Screening",
  "Weightage",
  "Rebalance",
  "Asset Allocation",
];
