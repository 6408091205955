import { useAppContext } from "AppContext";
import { useMutation } from "hooks/useQueries";
import { useNavigate } from "react-router-dom";
import axiosInstance from "utils/axiosInstance";
import { refetchQuery } from "utils/helpers";
import { QueryKeys } from "utils/queryKey";

export const ProfileAPI = () => {
  const {
    state: { userData },
  } = useAppContext();

  const navigate = useNavigate();

  const { mutate: handleUpdateProfile, isLoading: updateProfileUpdateLoading } =
    useMutation({
      mutationFn: (data: any) =>
        axiosInstance.patch(`/personal-details-update/${userData?.id}/`, data),
      onSuccess: () => {
        refetchQuery(QueryKeys.GET_USER_DETAIL);
      },
    });

  const {
    mutate: handleUpdateProfileRole,
    isLoading: updateRoleUpdateLoading,
  } = useMutation({
    mutationFn: (data: any) =>
      axiosInstance.post(`/user-role/`, { userRole: data }),
    onSuccess: () => {
      navigate(`/app/holdings`);
      window?.location?.reload();
    },
  });

  return {
    handleUpdateProfile,
    updateProfileUpdateLoading,
    handleUpdateProfileRole,
    updateRoleUpdateLoading,
  };
};
