import { LoadingButton } from "@mui/lab";
import { grey } from "@mui/material/colors";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Card,
  CgMathPlus,
  FiTrash2,
  GKButton,
  GKTextField,
  Grid2,
  IconButton,
  MdOutlineExpandMore,
  RiCloseLine,
  SimpleLoader,
  Typography,
} from "components";
import useRiskProfileAPI from "hooks/useRiskProfileAPI";
import { useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { RxDragHandleHorizontal } from "react-icons/rx";
import { paletteLightTheme } from "utils/ColorTheme";
import { ThemeContainer } from "utils/Theme";
import useDocumentTitle from "utils/useDocumentTitle";
import { validateNotWhitespaceOnly } from "utils/validationSchema";
import { returnPoints } from "./utils";

const OptionsList = ({
  optionFields,
  removeOption,
  question,
  questionIndex,
  control,
  handleDeleteQuestionOptions,
  handleAddOption,
  handleRemoveQuestion,
}: any) => {
  const { theme } = ThemeContainer();

  return (
    <Grid2 container size={12} spacing={1}>
      <Grid2 size={8}>
        <Typography color={theme.palette.secondaryText.main}>
          Options
          <span style={{ color: paletteLightTheme.palette.error.main }}>*</span>
        </Typography>
      </Grid2>
      <Grid2 size={4}>
        <Typography color={theme.palette.secondaryText.main}>
          Points
          <span style={{ color: paletteLightTheme.palette.error.main }}>*</span>
        </Typography>
      </Grid2>

      {optionFields.map((option: any, optionIndex: number) => (
        <Grid2 size={12} key={option.id} container spacing={1}>
          <Grid2 size={8}>
            <Controller
              name={`riskQuestions.${questionIndex}.options.${optionIndex}.option`}
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Option text is required",
                },
                validate: validateNotWhitespaceOnly,
              }}
              render={({ field, fieldState: { error } }) => (
                <GKTextField
                  {...field}
                  inputLabel={``}
                  requiredField
                  placeholder="Option"
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
          </Grid2>
          <Grid2 size={4} display={"flex"}>
            <Controller
              name={`riskQuestions.${questionIndex}.options.${optionIndex}.optionPoints`}
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Option Points is required",
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <GKTextField
                  {...field}
                  inputLabel={``}
                  requiredField
                  placeholder="Option Points"
                  type="number"
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />

            <IconButton
              sx={{ ml: "4px" }}
              onClick={() => {
                if (option.optionId) {
                  handleDeleteQuestionOptions({
                    id: question.questionId,
                    option: option.option,
                  });
                } else {
                  removeOption(optionIndex);
                }
              }}
              color="error"
              className="outline"
            >
              <RiCloseLine size={18} />
            </IconButton>
          </Grid2>
        </Grid2>
      ))}

      <Grid2 size={8} mt={1}>
        <GKButton
          variant="outlined"
          color="dark"
          startIcon={<CgMathPlus size={18} />}
          onClick={handleAddOption}
        >
          Add Option
        </GKButton>
      </Grid2>
      <Grid2 size={4} mt={1} display={"flex"} justifyContent={"flex-end"}>
        <GKButton
          variant="outlined"
          color="error"
          onClick={handleRemoveQuestion}
          startIcon={<FiTrash2 size={18} />}
        >
          Delete Question
        </GKButton>
      </Grid2>
    </Grid2>
  );
};

const QuestionAccordion = ({
  question,
  questionIndex,
  setExpanded,
  expanded,
  control,
  handleDeleteQuestion,
  handleDeleteQuestionOptions,
  getValues,
  setValue,
  removeQuestion,
  onDragStart,
  onDragOver,
  onDrop,
}: any) => {
  const { theme } = ThemeContainer();

  const { fields: optionFields, remove: removeOption } = useFieldArray({
    control,
    name: `riskQuestions.${questionIndex}.options`,
  });

  const handleChange = () => {
    setExpanded(questionIndex === expanded ? -1 : questionIndex);
  };

  const handleAddOption = () => {
    const newOptions = getValues(`riskQuestions.${questionIndex}.options`);
    newOptions.push({ option: "", optionPoints: "" });
    setValue(`riskQuestions.${questionIndex}.options`, newOptions);
  };

  const handleRemoveQuestion = () => {
    if (question?.questionId) {
      handleDeleteQuestion(question?.questionId);
    } else {
      removeQuestion(questionIndex);
    }
  };

  return (
    <Grid2
      size={12}
      draggable
      onDragStart={(e) => onDragStart(e, questionIndex)}
      onDragOver={(e) => onDragOver(e)}
      onDrop={(e) => onDrop(e, questionIndex)}
      sx={{
        cursor: "move",
        "&:hover": {
          opacity: 0.8,
        },
      }}
    >
      <Accordion
        onChange={handleChange}
        style={{
          border: `solid 1px ${theme.palette.border.main}`,
          borderRadius: 10,
          boxShadow: "none",
        }}
      >
        <AccordionSummary
          expandIcon={<MdOutlineExpandMore size={20} />}
          sx={{
            "& .MuiAccordionSummary-content": {
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            },
          }}
        >
          <Typography
            fontSize={16}
            fontWeight={500}
            color={"#121926"}
            display={"flex"}
            alignItems={"center"}
            gap={1}
          >
            <RxDragHandleHorizontal size={22} color={grey[500]} />
            {questionIndex + 1}. {question.questionText}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid2 container spacing={2} alignItems="center">
            <Grid2 size={12}>
              <Controller
                name={`riskQuestions.${questionIndex}.questionText`}
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "Question text is required",
                  },
                  validate: validateNotWhitespaceOnly,
                }}
                render={({ field, fieldState: { error } }) => (
                  <GKTextField
                    {...field}
                    inputLabel={
                      question?.questionId ? "Edit Question" : "Add Question"
                    }
                    requiredField
                    placeholder="Question Text"
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              />
            </Grid2>
            <OptionsList
              optionFields={optionFields}
              removeOption={removeOption}
              question={question}
              questionIndex={questionIndex}
              control={control}
              handleDeleteQuestionOptions={handleDeleteQuestionOptions}
              handleAddOption={handleAddOption}
              handleRemoveQuestion={handleRemoveQuestion}
            />
          </Grid2>
        </AccordionDetails>
      </Accordion>
    </Grid2>
  );
};

const RiskProfiling = () => {
  useDocumentTitle("Risk Profiling");

  const { theme } = ThemeContainer();

  const [expanded, setExpanded] = useState(0);
  const [draggedIndex, setDraggedIndex] = useState<number | null>(null);

  const {
    data: riskProfileList,
    isFetching,
    handleUpdate,
    loading,
    setLoading,
    handleCreate,
    handleDeleteQuestion,
    handleDeleteQuestionOptions,
  } = useRiskProfileAPI();

  const { control, handleSubmit, setValue, getValues, watch } = useForm({
    defaultValues: {
      field1: "Very Conservative",
      field2: "Conservative",
      field3: "Moderate",
      field4: "Aggressive",
      field5: "Very Aggressive",
      riskStatusOption: 5,
      riskQuestions: [
        {
          questionText: "Question 1",
          options: [
            {
              option: "",
              optionPoints: "",
            },
          ],
        },
      ],
    },
    values: {
      ...riskProfileList?.data?.data?.data,
      field1:
        riskProfileList?.data?.data?.data?.pointsDetails[0] ||
        "Very Conservative",
      field2:
        riskProfileList?.data?.data?.data?.pointsDetails[1] || "Conservative",
      field3: riskProfileList?.data?.data?.data?.pointsDetails[2] || "Moderate",
      field4:
        riskProfileList?.data?.data?.data?.pointsDetails[3] || "Aggressive",
      field5:
        riskProfileList?.data?.data?.data?.pointsDetails[4] ||
        "Very Aggressive",
      riskStatusOption:
        riskProfileList?.data?.data?.data?.riskStatusOption === "three" ? 3 : 5,
      riskQuestions: riskProfileList?.data?.data?.data.riskQuestions.map(
        (dataItem: any) => {
          return {
            ...dataItem,
            questionId: dataItem.id,
            options: dataItem.options.map((optionItem: any) => {
              return {
                ...optionItem,
                optionId: optionItem.id,
              };
            }),
          };
        },
      ),
    },
  });

  const {
    fields: questionFields,
    append: appendQuestion,
    remove: removeQuestion,
  } = useFieldArray({
    control,
    name: "riskQuestions",
  });

  watch();

  const onSubmit = (values: any) => {
    const requestData = {
      ...values,
      riskStatusOption: values?.riskStatusOption === 5 ? "five" : "three",
      pointsDetails:
        values?.riskStatusOption === 5
          ? [
              values?.field1,
              values?.field2,
              values?.field3,
              values?.field4,
              values?.field5,
            ]
          : [values?.field1, values?.field2, values?.field3],
      pointsRange: returnPoints({
        questionFields: getValues("riskQuestions"),
        step: getValues("riskStatusOption"),
      }),
    };
    setLoading(true);
    riskProfileList?.data?.data?.data?.id
      ? handleUpdate({
          id: riskProfileList?.data?.data?.data?.id,
          data: requestData,
        })
      : handleCreate(requestData);
  };

  const handleDragStart = (e: React.DragEvent<HTMLElement>, index: number) => {
    setDraggedIndex(index);
    e.currentTarget.style.opacity = "0.4";
  };

  const handleDragOver = (e: React.DragEvent<HTMLElement>) => {
    e.preventDefault();
    e.currentTarget.style.opacity = "0.6";
  };

  const handleDrop = (e: React.DragEvent<HTMLElement>, dropIndex: number) => {
    e.preventDefault();
    e.currentTarget.style.opacity = "1";

    if (draggedIndex === null || draggedIndex === dropIndex) return;

    const reorderedQuestions = Array.from(questionFields);
    const [movedQuestion] = reorderedQuestions.splice(draggedIndex, 1);
    reorderedQuestions.splice(dropIndex, 0, movedQuestion);

    setValue("riskQuestions", reorderedQuestions);
    setDraggedIndex(null);
  };

  if (isFetching || loading) return <SimpleLoader />;

  return (
    <Grid2 container spacing={2}>
      <Grid2 size={12}>
        <form onSubmit={handleSubmit(onSubmit)} id="risk-form">
          <Grid2 container spacing={2}>
            <Grid2 size={12} display={"flex"} alignItems={"center"} gap={2}>
              <Typography>Select number of risk categories</Typography>
              <div style={{ display: "flex" }}>
                <GKButton
                  variant={
                    getValues("riskStatusOption") === 3
                      ? "contained"
                      : "outlined"
                  }
                  onClick={() => {
                    setValue("riskStatusOption", 3);
                  }}
                >
                  3 Categories
                </GKButton>
                <Typography
                  mx={1}
                  display={"flex"}
                  alignItems={"center"}
                  fontSize={12}
                  color={theme.palette.secondaryText.main}
                >
                  OR
                </Typography>
                <GKButton
                  variant={
                    getValues("riskStatusOption") === 5
                      ? "contained"
                      : "outlined"
                  }
                  onClick={() => {
                    setValue("riskStatusOption", 5);
                  }}
                >
                  5 Categories
                </GKButton>
              </div>
            </Grid2>
            <Grid2 container size={12}>
              <Grid2
                size={12}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                }}
              >
                <Controller
                  name="field1"
                  control={control}
                  render={({ field }) => (
                    <Card sx={{ padding: "10px 14px" }}>
                      <GKTextField
                        {...field}
                        sx={{
                          "& .MuiInputBase-input": {
                            p: 0,
                          },
                          "& .MuiOutlinedInput-notchedOutline": {
                            border: "none",
                          },
                        }}
                        placeholder="Enter Category Name"
                      />
                      <Typography
                        fontSize={14}
                        width={"-webkit-fill-available"}
                        color={"#888C96"}
                        mt={"4px"}
                        fontWeight={500}
                      >
                        {
                          returnPoints({
                            questionFields: getValues("riskQuestions"),
                            step: getValues("riskStatusOption"),
                          })[0]
                        }
                      </Typography>
                    </Card>
                  )}
                />

                <Controller
                  name="field2"
                  control={control}
                  render={({ field }) => (
                    <Card sx={{ padding: "10px 14px" }}>
                      <GKTextField
                        {...field}
                        sx={{
                          "& .MuiInputBase-input": {
                            p: 0,
                          },
                          "& .MuiOutlinedInput-notchedOutline": {
                            border: "none",
                          },
                        }}
                        placeholder="Enter Category Name"
                      />
                      <Typography
                        fontSize={14}
                        width={"-webkit-fill-available"}
                        color={"#888C96"}
                        fontWeight={500}
                      >
                        {
                          returnPoints({
                            questionFields: getValues("riskQuestions"),
                            step: getValues("riskStatusOption"),
                          })[1]
                        }
                      </Typography>
                    </Card>
                  )}
                />

                <Controller
                  name="field3"
                  control={control}
                  render={({ field }) => (
                    <Card sx={{ padding: "10px 14px" }}>
                      <GKTextField
                        {...field}
                        sx={{
                          "& .MuiInputBase-input": {
                            p: 0,
                          },
                          "& .MuiOutlinedInput-notchedOutline": {
                            border: "none",
                          },
                        }}
                        placeholder="Enter Category Name"
                      />
                      <Typography
                        fontSize={14}
                        width={"-webkit-fill-available"}
                        color={"#888C96"}
                        fontWeight={500}
                      >
                        {
                          returnPoints({
                            questionFields: getValues("riskQuestions"),
                            step: getValues("riskStatusOption"),
                          })[2]
                        }
                      </Typography>
                    </Card>
                  )}
                />

                {getValues("riskStatusOption") === 5 && (
                  <Controller
                    name="field4"
                    control={control}
                    render={({ field }) => (
                      <Card sx={{ padding: "10px 14px" }}>
                        <GKTextField
                          {...field}
                          sx={{
                            "& .MuiInputBase-input": {
                              p: 0,
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              border: "none",
                            },
                          }}
                          placeholder="Enter Category Name"
                        />
                        <Typography
                          fontSize={14}
                          width={"-webkit-fill-available"}
                          color={"#888C96"}
                        >
                          {
                            returnPoints({
                              questionFields: getValues("riskQuestions"),
                              step: getValues("riskStatusOption"),
                            })[3]
                          }
                        </Typography>
                      </Card>
                    )}
                  />
                )}
                {getValues("riskStatusOption") === 5 && (
                  <Controller
                    name="field5"
                    control={control}
                    render={({ field }) => (
                      <Card sx={{ padding: "10px 14px" }}>
                        <GKTextField
                          {...field}
                          sx={{
                            "& .MuiInputBase-input": {
                              p: 0,
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              border: "none",
                            },
                          }}
                          placeholder="Enter Category Name"
                        />
                        <Typography
                          fontSize={14}
                          width={"-webkit-fill-available"}
                          color={"#888C96"}
                        >
                          {
                            returnPoints({
                              questionFields: getValues("riskQuestions"),
                              step: getValues("riskStatusOption"),
                            })[4]
                          }
                        </Typography>
                      </Card>
                    )}
                  />
                )}
              </Grid2>
              <Grid2 size={12} mt={1}>
                <Typography fontSize={12} fontWeight={400} color={"#999"}>
                  Tip: Just click on the risk category title to change it!
                </Typography>
              </Grid2>
            </Grid2>
            <Grid2 size={6} mt={1} display={"flex"} alignItems={"center"}>
              <Typography
                fontSize={18}
                fontWeight={500}
                color={theme.palette.secondaryText.main}
              >
                Questions
              </Typography>
            </Grid2>
            <Grid2
              size={6}
              mt={1}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"flex-end"}
              gap={1}
            >
              <Button
                onClick={() =>
                  appendQuestion({
                    questionText: `Question ${questionFields.length + 1}`,
                    options: [{ option: "", optionPoints: "" }],
                  })
                }
                variant="outlined"
                color="dark"
                disabled={isFetching}
                startIcon={<CgMathPlus size={18} />}
              >
                Add Question
              </Button>
              <LoadingButton
                type="submit"
                variant="contained"
                form="risk-form"
                size="medium"
                loading={isFetching}
              >
                Save
              </LoadingButton>
            </Grid2>
            <Grid2 size={12}>
              <div>
                {questionFields.map((question, questionIndex) => (
                  <QuestionAccordion
                    key={question.id}
                    question={question}
                    questionIndex={questionIndex}
                    setExpanded={setExpanded}
                    expanded={expanded}
                    control={control}
                    handleDeleteQuestion={handleDeleteQuestion}
                    handleDeleteQuestionOptions={handleDeleteQuestionOptions}
                    getValues={getValues}
                    setValue={setValue}
                    removeQuestion={removeQuestion}
                    onDragStart={handleDragStart}
                    onDragOver={handleDragOver}
                    onDrop={handleDrop}
                  />
                ))}
              </div>
            </Grid2>
          </Grid2>
        </form>
      </Grid2>
    </Grid2>
  );
};

export default RiskProfiling;
