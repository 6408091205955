import { GridColDef } from "@mui/x-data-grid-pro";
import {
  ConfirmDeleteModal,
  FiTrash2,
  GKButton,
  GKDataGrid,
  Grid2,
  IconButton,
  Show,
} from "components";
import useGenerateQueryParams from "hooks/useGenerateQueryParams";
import { useFetchQuery, useMutation } from "hooks/useQueries";
import { useState } from "react";
import { handleRemoveUnderscore } from "utils";
import { REPORT_SCHEDULE_LIST } from "utils/apiRoutes";
import axiosInstance from "utils/axiosInstance";
import { extraColumnsField, matchDate } from "utils/helpers";
import { moneyCommaSeparatorPoint } from "utils/MathFunction";
import SchedulerModal from "./SchedulerModal";

const ReportScheduler = () => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [reportId, setReportId] = useState(null);
  const [confirmationModal, setConfirmationModal] = useState(false);

  const requestBody = useGenerateQueryParams("reportScheduler");

  const { data, isFetching, refetch } = useFetchQuery({
    route: REPORT_SCHEDULE_LIST,
    key: ["REPORT_SCHEDULER_LIST", requestBody],
    requestBody,
  });

  const { mutate: handleScheduleReportDelete, isLoading } = useMutation({
    mutationKey: ["DELETE_SCHEDULE_REPORT"],
    mutationFn: ({ id }: { id: any }) =>
      axiosInstance.delete(`/report_scheduler/${id}/`),
    onSuccess: () => {
      refetch();
    },
  });

  const columns: GridColDef[] = [
    {
      ...extraColumnsField("Report Name", "reportName", 300, 1),
      renderCell: (params) => handleRemoveUnderscore(params?.row?.reportName),
    },
    {
      ...extraColumnsField("Schedule Type", "scheduleType", 150, 1),
    },
    {
      ...extraColumnsField("Format", "format", 50, 1),
    },
    {
      ...extraColumnsField("Time Period", "timePeriod", 250, 1),
      renderCell: (params) => params?.row?.timePeriod || "-",
    },
    {
      ...extraColumnsField("Next Scheduled Date", "scheduledDate", 150, 1),
      valueFormatter: (value) => matchDate(value.value),
    },
    {
      ...extraColumnsField("Portfolio Count", "portfolios", 120, 1),
      valueFormatter: (params) => moneyCommaSeparatorPoint(params.value),
      type: "number",
      renderCell: (params) => params?.row?.portfolios?.length || 0,
    },
    {
      ...extraColumnsField("Actions", "action", 100, 1),
      align: "right",
      headerAlign: "right",
      sortable: false,
      renderCell: (params) => (
        <IconButton
          color="error"
          className="outline"
          disabled={params.row.id === reportId && isLoading}
          onClick={(event) => {
            event.stopPropagation();
            setReportId(params.row.id);
            setConfirmationModal(true);
          }}
        >
          <FiTrash2 size={20} />
        </IconButton>
      ),
    },
  ];

  return (
    <Grid2 container>
      <GKDataGrid
        density="compact"
        rows={data?.data?.data || []}
        columns={columns}
        loading={isFetching}
        totalCount={data?.data?.totalCount}
        maxHeight={"calc(100vh - 357px)"}
        exportFile={{
          path: REPORT_SCHEDULE_LIST,
          exportName: "ReportSchedulerList",
        }}
        name="reportScheduler"
        headerComponent={
          <GKButton
            variant="contained"
            onClick={() => {
              setReportId(null);
              setIsOpenModal(true);
            }}
          >
            Schedule Report
          </GKButton>
        }
        onRowClick={(params) => {
          setReportId(params.row.id);
          setIsOpenModal(true);
        }}
      />

      <Show.When isTrue={isOpenModal}>
        <SchedulerModal
          open={isOpenModal}
          setOpen={setIsOpenModal}
          reportId={reportId}
        />
      </Show.When>

      <Show.When isTrue={confirmationModal}>
        <ConfirmDeleteModal
          open={confirmationModal}
          setOpen={setConfirmationModal}
          onDelete={() => handleScheduleReportDelete({ id: reportId })}
          title={"Schedule Report"}
          isDelete
        />
      </Show.When>
    </Grid2>
  );
};

export default ReportScheduler;
