import {
  BiSolidCheckCircle,
  BiTrash,
  Card,
  CgMathPlus,
  Divider,
  EditIcon,
  GKButton,
  Grid2,
  IconButton,
  NoData,
  SimpleLoader,
  Typography,
} from "components";
import { useFetchQuery, useMutation } from "hooks/useQueries";
import { PortfolioDetailsInterface } from "interfaces/Portfolio";
import { useState } from "react";
import { CLIENT_BANK_BY_ID } from "utils/apiRoutes";
import axiosInstance from "utils/axiosInstance";
import { refetchQuery } from "utils/helpers";
import { QueryKeys } from "utils/queryKey";
import { ThemeContainer } from "utils/Theme";
import BankAccountModal from "../ProfilePages/BankAccountModal";

interface Props {
  portfolioDetailData: PortfolioDetailsInterface;
}

interface BankInterface {
  bank: string;
  name: string;
  type: string;
  accountNum: number;
  ifsc: string;
  client: number;
  id: number;
}

const BankDetails = (props: Props) => {
  const { portfolioDetailData } = props;
  const { theme } = ThemeContainer();

  const [openModal, setOpenModal] = useState(false);
  const [updateData, setUpdateData] = useState(null);

  const { data, isFetching, refetch } = useFetchQuery({
    key: [QueryKeys.PORTFOLIO_BANK_ACCOUNT],
    route: CLIENT_BANK_BY_ID(portfolioDetailData?.client),
  });

  const { mutate: handleAssignMutation } = useMutation({
    mutationFn: (data: any) =>
      axiosInstance.patch(`/portfolios/${portfolioDetailData?.id}/`, {
        ...portfolioDetailData,
        bankAccount: data,
      }),
    onSuccess: () => {
      refetchQuery("GET_PORTFOLIO_DETAIL_BY_ID");
    },
  });

  const { mutate: handleDeleteBank } = useMutation({
    mutationFn: (id: number) =>
      axiosInstance.delete(`/client/${id}/delete_banks/`),
    onSuccess: () => {
      refetchQuery(QueryKeys.PORTFOLIO_BANK_ACCOUNT);
    },
  });

  if (isFetching) return <SimpleLoader />;

  return (
    <Grid2 container spacing={2}>
      {data?.data?.bankAccounts?.map((dataItem: BankInterface) => {
        return (
          <Grid2 size={6}>
            <Card sx={{ p: 2 }}>
              <Grid2 container spacing={2}>
                <Grid2
                  size={12}
                  display={"flex"}
                  justifyContent={"space-between"}
                >
                  <GKButton
                    size="extraSmall"
                    variant={
                      dataItem?.id === portfolioDetailData?.bankAccount
                        ? "contained"
                        : "outlined"
                    }
                    color={
                      dataItem?.id !== portfolioDetailData?.bankAccount
                        ? "dark"
                        : "success"
                    }
                    onClick={() =>
                      dataItem?.id !== portfolioDetailData?.bankAccount &&
                      handleAssignMutation(dataItem.id)
                    }
                    startIcon={
                      dataItem?.id === portfolioDetailData?.bankAccount && (
                        <BiSolidCheckCircle size={14} />
                      )
                    }
                  >
                    {dataItem?.id !== portfolioDetailData?.bankAccount
                      ? "Set as primary"
                      : "Primary"}
                  </GKButton>
                  <div style={{ gap: 8, display: "flex" }}>
                    <IconButton
                      onClick={() => {
                        setOpenModal(true);
                        setUpdateData(dataItem);
                      }}
                    >
                      <EditIcon size={16} />
                    </IconButton>
                    <IconButton onClick={() => handleDeleteBank(dataItem.id)}>
                      <BiTrash size={16} />
                    </IconButton>
                  </div>
                </Grid2>
                <Grid2 size={12}>
                  <Divider />
                </Grid2>
                <Grid2 size={12} display={"flex"} alignItems={"center"} gap={3}>
                  <Typography color={theme.palette.secondaryText.main}>
                    Bank Name
                  </Typography>
                  <Typography color={"#121926"}>
                    {dataItem?.bank || "-"}
                  </Typography>
                </Grid2>
                <Grid2 size={12} display={"flex"} alignItems={"center"} gap={3}>
                  <Typography color={theme.palette.secondaryText.main}>
                    Bank Account Name
                  </Typography>
                  <Typography color={"#121926"}>
                    {dataItem?.name || "-"}
                  </Typography>
                </Grid2>
                <Grid2 size={12} display={"flex"} alignItems={"center"} gap={3}>
                  <Typography color={theme.palette.secondaryText.main}>
                    Account Type
                  </Typography>
                  <Typography color={"#121926"}>
                    {dataItem?.type || "-"}
                  </Typography>
                </Grid2>
                <Grid2 size={12} display={"flex"} alignItems={"center"} gap={3}>
                  <Typography color={theme.palette.secondaryText.main}>
                    Account Number
                  </Typography>
                  <Typography color={"#121926"}>
                    {dataItem?.accountNum || "-"}
                  </Typography>
                </Grid2>
                <Grid2 size={12} display={"flex"} alignItems={"center"} gap={3}>
                  <Typography color={theme.palette.secondaryText.main}>
                    IFSC Code
                  </Typography>
                  <Typography color={"#121926"}>
                    {dataItem?.ifsc || "-"}
                  </Typography>
                </Grid2>
              </Grid2>
            </Card>
          </Grid2>
        );
      })}

      <Grid2 size={12}>
        {data?.data?.bankAccounts.length === 0 ? (
          <NoData
            text="No Linked Bank Accounts"
            additionalComponent={
              <GKButton
                variant="contained"
                size="medium"
                startIcon={<CgMathPlus size={16} />}
                onClick={() => {
                  setUpdateData(null);
                  setOpenModal(true);
                }}
              >
                Add Bank
              </GKButton>
            }
          />
        ) : (
          <GKButton
            variant="outlined"
            color="dark"
            startIcon={<CgMathPlus size={16} />}
            onClick={() => {
              setUpdateData(null);
              setOpenModal(true);
            }}
          >
            Add Bank
          </GKButton>
        )}
      </Grid2>

      {openModal && (
        <BankAccountModal
          open={openModal}
          setOpen={setOpenModal}
          allAccounts={data?.data}
          updateData={updateData}
          clientId={portfolioDetailData?.client}
          refetch={refetch}
        />
      )}
    </Grid2>
  );
};

export default BankDetails;
