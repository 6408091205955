import { LoadingButton } from "@mui/lab";
import { useAppContext } from "AppContext";
import { GKDatePicker, GKTextField, Grid2, SimpleLoader } from "components";
import dayjs from "dayjs";
import { useFetchQuery, useMutation } from "hooks/useQueries";
import { Controller, useForm } from "react-hook-form";
import axiosInstance from "utils/axiosInstance";
import { emailRegex } from "utils/validationSchema";

type FormValues = {
  appName: string;
  appSource: string;
  userId: string;
  password: string;
  userKey: string;
  encryptionKey: string;
  ocpKey: string;
  partnerCode: string;
  partnerPassword: string;
  partnerDob: string;
  partnerEmailId: string;
  partnerContactNumber: string;
  company?: number;
};

const IIFLBrokerConfigure = () => {
  const {
    state: { userData },
  } = useAppContext();

  const { data, isLoading } = useFetchQuery({
    key: ["GET_COMPANY_BROKER_CONFIGURE"],
    route: `/company/${userData.companyId}/broker_details/`,
  });

  const form = useForm<FormValues>({
    defaultValues: {
      appName: "",
      appSource: "",
      userId: "",
      password: "",
      userKey: "",
      encryptionKey: "",
      ocpKey: "",
      partnerCode: "",
      partnerPassword: "",
      partnerDob: "",
      partnerEmailId: "",
      partnerContactNumber: "",
    },
    values: {
      appName: data?.data?.data?.appName || "",
      appSource: data?.data?.data?.appSource || "",
      userId: data?.data?.data?.userId || "",
      password: data?.data?.data?.password || "",
      userKey: data?.data?.data?.userKey || "",
      encryptionKey: data?.data?.data?.encryptionKey || "",
      ocpKey: data?.data?.data?.ocpKey || "",
      partnerCode: data?.data?.data?.partnerCode || "",
      partnerPassword: data?.data?.data?.partnerPassword || "",
      partnerDob: data?.data?.data?.partnerDob || "",
      partnerEmailId: data?.data?.data?.partnerEmailId || "",
      partnerContactNumber: data?.data?.data?.partnerContactNumber || "",
    },
  });

  const { mutate, isLoading: updateConfigLoading } = useMutation({
    mutationKey: ["UPDATE_COMPANY_BROKER_CONFIGURE"],
    mutationFn: (data: FormValues) =>
      axiosInstance.patch(
        `/company/${userData.companyId}/broker_details/`,
        data,
      ),
  });

  if (isLoading) return <SimpleLoader />;

  return (
    <form
      onSubmit={form.handleSubmit((values) => {
        delete values.company;

        mutate(values);
      })}
    >
      <Grid2 container spacing={2}>
        <Grid2 size={4}>
          <Controller
            name="appName"
            control={form.control}
            render={({ field }) => (
              <GKTextField {...field} inputLabel="App Name" />
            )}
          />
        </Grid2>
        <Grid2 size={4}>
          <Controller
            name="appSource"
            control={form.control}
            render={({ field }) => (
              <GKTextField {...field} inputLabel="App Source" />
            )}
          />
        </Grid2>
        <Grid2 size={4}>
          <Controller
            name="userId"
            control={form.control}
            render={({ field }) => (
              <GKTextField {...field} inputLabel="User Id" />
            )}
          />
        </Grid2>
        <Grid2 size={4}>
          <Controller
            name="password"
            control={form.control}
            render={({ field }) => (
              <GKTextField {...field} inputLabel="Password" />
            )}
          />
        </Grid2>
        <Grid2 size={4}>
          <Controller
            name="userKey"
            control={form.control}
            render={({ field }) => (
              <GKTextField {...field} inputLabel="User Key" />
            )}
          />
        </Grid2>
        <Grid2 size={4}>
          <Controller
            name="encryptionKey"
            control={form.control}
            render={({ field }) => (
              <GKTextField {...field} inputLabel="Encryption Key" />
            )}
          />
        </Grid2>
        <Grid2 size={4}>
          <Controller
            name="ocpKey"
            control={form.control}
            render={({ field }) => (
              <GKTextField {...field} inputLabel="OCP Key" />
            )}
          />
        </Grid2>
        <Grid2 size={4}>
          <Controller
            name="partnerCode"
            control={form.control}
            render={({ field }) => (
              <GKTextField {...field} inputLabel="Partner Code" />
            )}
          />
        </Grid2>
        <Grid2 size={4}>
          <Controller
            name="partnerPassword"
            control={form.control}
            render={({ field }) => (
              <GKTextField {...field} inputLabel="Partner Password" />
            )}
          />
        </Grid2>
        <Grid2 size={4}>
          <Controller
            name="partnerDob"
            control={form.control}
            render={({ field }) => (
              <GKDatePicker
                {...field}
                inputLabel="Date Of Birth"
                value={dayjs(field.value)}
                onChange={(value) => {
                  field.onChange(dayjs(value).format("YYYY-MM-DD"));
                }}
                disableFuture
                slotProps={{
                  textField: {
                    error: false,
                    helperText: false,
                  },
                }}
              />
            )}
          />
        </Grid2>
        <Grid2 size={4}>
          <Controller
            name="partnerEmailId"
            control={form.control}
            rules={{
              pattern: {
                value: emailRegex,
                message: "Please enter valid email",
              },
            }}
            render={({ field, fieldState: { error } }) => (
              <GKTextField
                {...field}
                inputLabel="Partner Email"
                error={!!error}
                helperText={error?.message}
              />
            )}
          />
        </Grid2>
        <Grid2 size={4}>
          <Controller
            name="partnerContactNumber"
            control={form.control}
            render={({ field }) => (
              <GKTextField {...field} inputLabel="Partner Contact Number" />
            )}
          />
        </Grid2>
        <Grid2 size={12} mt={2} display={"flex"} justifyContent={"flex-end"}>
          <LoadingButton
            variant="contained"
            type="submit"
            loading={updateConfigLoading}
          >
            Save
          </LoadingButton>
        </Grid2>
      </Grid2>
    </form>
  );
};

export default IIFLBrokerConfigure;
