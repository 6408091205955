import { CSSProperties } from "react";
import { ThemeContainer } from "utils/Theme";

interface Props {
  statusString: string;
  styles?: CSSProperties;
}

const StatusField = ({ statusString, styles }: Props) => {
  const { theme } = ThemeContainer();
  const returnStatusColor = (text: string) => {
    switch (text && text?.toLowerCase()) {
      case "buy":
        return theme.palette.success.main;
      case "active":
        return theme.palette.success.main;
      case "match":
        return theme.palette.success.main;
      case "success":
        return theme.palette.success.main;
      case "finished":
        return theme.palette.success.main;
      case "completed":
        return theme.palette.success.main;
      case "complete":
        return theme.palette.success.main;
      case "mapped":
        return theme.palette.success.main;
      case "inactive":
        return theme.palette.error.main;
      case "sell":
        return theme.palette.error.main;
      case "not match":
        return theme.palette.error.main;
      case "unmapped":
        return theme.palette.error.main;
      case "low":
        return theme.palette.error.main;
      case "failed":
        return theme.palette.error.main;
      case "moderate":
        return theme.palette.info.main;
      case "high":
        return theme.palette.success.main;
      case "pending":
        return theme.palette.warning.main;
      case "approved":
        return theme.palette.success.main;
      case "rejected":
        return theme.palette.error.main;
      case "true":
        return theme.palette.success.main;
      case "false":
        return theme.palette.error.main;
      case "manual":
        return theme.palette.info.main;
      case "auto":
        return theme.palette.success.main;
      case "partly":
        return theme.palette.warning.main;
      case "emandates":
        return theme.palette.info.main;
      case "cash":
        return theme.palette.success.main;
      case "bank":
        return theme.palette.grey[600];
      default:
        return `#5A5A5A`;
    }
  };

  return (
    <div
      style={{
        ...styles,
        display: styles?.display || "flex",
        alignItems: styles?.alignItems || "center",
        justifyContent: styles?.justifyContent || "center",
        borderRadius: styles?.borderRadius || 4,
        padding: styles?.padding || "2px 6px",
        fontWeight: styles?.fontWeight || 400,
        fontSize: styles?.fontSize || 12,
        background:
          styles?.background || `${returnStatusColor(statusString)}11`,
        color: styles?.color || returnStatusColor(statusString),
        textTransform: styles?.textTransform || "capitalize",
        width: "max-content",
      }}
    >
      {statusString?.toLowerCase()}
    </div>
  );
};

export default StatusField;
