import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import {
  GKDatePicker,
  GKModal,
  GKSelect,
  GKTextArea,
  GKTextField,
  Grid2,
  MenuItem,
} from "components";
import dayjs from "dayjs";
import { useMutation } from "hooks/useQueries";
import { Controller, useForm } from "react-hook-form";
import axiosInstance from "utils/axiosInstance";
import { GKModalProps } from "utils/commonInterface";
import { MEETING_TYPE } from "utils/constants";
import { refetchQuery } from "utils/helpers";
import { QueryKeys } from "utils/queryKey";
import {
  newMeetingSchema,
  scheduleMeetingSchema,
} from "utils/validationSchema";

interface Props {
  leadId: number | string;
  meetingData: any;
  schedule?: any;
  refetch?: any;
}

type FormValues = {
  startDate?: string;
  startTime?: string;
  meetingTitle?: string;
  agenda?: string;
  meetingType?: string;
  meetingPerson?: string;
  location?: string;
  meetingLink?: string;
};

const AddNewMeeting = (props: Props & GKModalProps) => {
  const { open, setOpen, leadId, meetingData, refetch, schedule } = props;

  const { mutate: createMeeting, isLoading: addLoading } = useMutation({
    mutationFn: (data: any) =>
      axiosInstance.post("/meeting/", { ...data, lead: leadId }),
    onSuccess: () => {
      refetch && refetch();
      refetchQuery(QueryKeys.GET_LEAD);
      setOpen(false);
    },
  });

  const { mutate: updateMeeting, isLoading: updateLoading } = useMutation({
    mutationFn: (data: any) =>
      axiosInstance.put(`/meeting/${meetingData?.id}/`, {
        ...data,
        lead: meetingData?.lead,
      }),
    onSuccess: () => {
      refetch && refetch();
      setOpen(false);
    },
  });

  const form = useForm<FormValues>({
    defaultValues: {
      startDate: meetingData?.startDate || "",
      startTime: meetingData?.startTime || "",
      meetingTitle: meetingData?.meetingTitle || "",
      agenda: meetingData?.agenda || "",
      meetingType: meetingData?.meetingType || "",
      meetingLink: meetingData?.meetingLink || "",
      meetingPerson: meetingData?.meetingPerson || "",
      location: meetingData?.location || "",
    },
    resolver: schedule
      ? yupResolver(newMeetingSchema)
      : yupResolver(scheduleMeetingSchema),
  });

  form.watch();

  const returnModelTitle = () => {
    if (meetingData?.id && schedule) {
      return "Update Schedule Meeting";
    }
    if (meetingData?.id) {
      return "Update  Meeting";
    }
    if (schedule) {
      return "Schedule  Meeting";
    }
    return "Add Meeting";
  };

  return (
    <GKModal
      modalTitle={returnModelTitle()}
      open={open}
      setOpen={setOpen}
      footer={
        <Grid2 size={12} textAlign={"right"}>
          <LoadingButton
            variant="contained"
            type="submit"
            size="medium"
            form="add-meeting-form"
            loading={updateLoading || addLoading}
          >
            Save
          </LoadingButton>
        </Grid2>
      }
    >
      <form
        id="add-meeting-form"
        onSubmit={form.handleSubmit((values) => {
          if (meetingData?.id) {
            updateMeeting(values);
          } else {
            createMeeting(values);
          }
        })}
      >
        <Grid2 container spacing={1}>
          <Grid2 size={6}>
            <Controller
              name="meetingType"
              control={form.control}
              render={({ field, formState: { errors } }) => (
                <GKSelect
                  {...field}
                  inputLabel="Meeting Type"
                  requiredField
                  error={Boolean(errors?.meetingType)}
                  helperText={errors?.meetingType?.message as string}
                >
                  <MenuItem style={{ display: "none" }} value="">
                    Select Meeting Type
                  </MenuItem>
                  {MEETING_TYPE?.map((dataItem) => (
                    <MenuItem key={dataItem?.value} value={dataItem?.value}>
                      {dataItem?.name}
                    </MenuItem>
                  ))}
                </GKSelect>
              )}
            />
          </Grid2>
          <Grid2 size={6}>
            <Controller
              name="startDate"
              control={form.control}
              render={({ field, formState: { errors } }) => (
                <GKDatePicker
                  {...field}
                  disableFuture={false}
                  inputLabel="Meeting Start Date"
                  value={dayjs(field.value)}
                  requiredField
                  onChange={(value) =>
                    field.onChange(dayjs(value).format("YYYY-MM-DD"))
                  }
                  slotProps={{
                    textField: {
                      error: Boolean(errors.startDate),
                      helperText: errors.startDate?.message,
                    },
                  }}
                />
              )}
            />
          </Grid2>
          <Grid2 size={6}>
            <Controller
              name="startTime"
              control={form.control}
              rules={{
                required: {
                  value: true,
                  message: "Please enter a valid date",
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <GKTextField
                  {...field}
                  type="time"
                  requiredField
                  error={!!error}
                  inputLabel="Meeting Start Time"
                  helperText={!!error && error?.message}
                />
              )}
            />
          </Grid2>
          <Grid2 size={6}>
            <Controller
              name="meetingTitle"
              control={form.control}
              render={({ field, formState: { errors } }) => (
                <GKTextField
                  {...field}
                  inputLabel="Meeting Title"
                  error={Boolean(errors?.meetingTitle)}
                  helperText={errors?.meetingTitle?.message}
                />
              )}
            />
          </Grid2>
          <Grid2 size={12}>
            <Controller
              name="agenda"
              control={form.control}
              render={({ field, formState: { errors } }) => (
                <GKTextArea
                  {...field}
                  inputLabel="Description"
                  minRows={3}
                  error={Boolean(errors?.agenda)}
                  helperText={errors?.agenda?.message}
                />
              )}
            />
          </Grid2>
          <Grid2 size={12}>
            <Controller
              name="meetingPerson"
              control={form.control}
              render={({ field, formState: { errors } }) => (
                <GKTextField
                  {...field}
                  inputLabel="Attendees"
                  error={Boolean(errors?.meetingPerson)}
                  helperText={errors?.meetingPerson?.message}
                />
              )}
            />
          </Grid2>
          {(form.getValues("meetingType") === "Zoom" ||
            form.getValues("meetingType") === "Google Meet") && (
            <Grid2 size={12}>
              <Controller
                name="meetingLink"
                control={form.control}
                render={({ field, formState: { errors } }) => (
                  <GKTextField
                    {...field}
                    inputLabel="Meeting Link"
                    error={Boolean(errors?.meetingLink)}
                    helperText={errors?.meetingLink?.message}
                  />
                )}
              />
            </Grid2>
          )}
          {form.getValues("meetingType") === "Offline" && (
            <Grid2 size={12}>
              <Controller
                name="location"
                control={form.control}
                render={({ field, formState: { errors } }) => (
                  <GKTextField
                    {...field}
                    inputLabel="Meeting Location"
                    error={Boolean(errors?.location)}
                    helperText={errors?.location?.message}
                  />
                )}
              />
            </Grid2>
          )}
        </Grid2>
      </form>
    </GKModal>
  );
};

export default AddNewMeeting;
