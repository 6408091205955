import { LoadingButton } from "@mui/lab";
import { useAppContext } from "AppContext";
import { FiArrowRight, GKSearchSelect, Grid2, TextField } from "components";
import { Controller, useFormContext } from "react-hook-form";
import { handleReturnAutocompletePlaceholder } from "utils/StringFunctions";

const MFOption = [
  {
    name: "Equity",
    value: "EQ",
  },
  // {
  //   name: "Mutual Fund",
  //   value: "MF",
  // },
  // {
  //   name: "Private Equity",
  //   value: "PEQ",
  // },
  // {
  //   name: "Fixed Deposit",
  //   value: "FD",
  // },
  // {
  //   name: "Alternative Investment Funds",
  //   value: "AIF",
  // },
];

interface Props {
  isLoading: boolean;
}

const SelectionSection = (props: Props) => {
  const { isLoading } = props;

  const form = useFormContext();

  const {
    state: { clientList },
  } = useAppContext();

  return (
    <Grid2 container bgcolor={"#F8F8F8"} borderRadius={1} p={2} spacing={3}>
      <Grid2 size={3}>
        <Controller
          name="client"
          control={form.control}
          rules={{ required: true }}
          render={({ field, fieldState: { error } }) => (
            <GKSearchSelect
              {...field}
              options={clientList}
              getOptionLabel={(e) => e.name || e}
              value={clientList?.find((e) => e.value === field.value) || null}
              onChange={(e, val) => field.onChange(val.value)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder={handleReturnAutocompletePlaceholder({
                    placeholder: "Select Client",
                    value: field.value,
                  })}
                  error={!!error}
                />
              )}
            />
          )}
        />
      </Grid2>
      <Grid2 size={3}>
        <Controller
          name="instType"
          control={form.control}
          rules={{ required: true }}
          render={({ field, formState: { errors } }) => (
            <GKSearchSelect
              {...field}
              multiple
              options={MFOption}
              value={
                MFOption?.filter((option) =>
                  field.value?.includes(option.value),
                ) || []
              }
              onChange={(event, val) => {
                const selectedIds = val.map((option: any) => option.value);
                field.onChange(selectedIds);
              }}
              getOptionLabel={(option) => option.name || option}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder={handleReturnAutocompletePlaceholder({
                    placeholder: "Select Equity, Mutual Fund or Both",
                    value: field.value,
                  })}
                  error={!!errors?.portfolio}
                />
              )}
            />
          )}
        />
      </Grid2>
      <Grid2 size={3}>
        <LoadingButton
          loading={isLoading}
          variant="contained"
          size="large"
          type="submit"
          form="capital-gain-form"
          endIcon={<FiArrowRight size={16} />}
        >
          Submit
        </LoadingButton>
      </Grid2>
    </Grid2>
  );
};

export default SelectionSection;
