import { LoadingButton } from "@mui/lab";
import { useAppContext } from "AppContext";
import {
  FiTrash2,
  GKButton,
  GKModal,
  GKSearchSelect,
  Grid2,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "components";
import { useMutation } from "hooks/useQueries";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import axiosInstance from "utils/axiosInstance";
import { GKModalProps } from "utils/commonInterface";
import { refetchQuery } from "utils/helpers";
import { QueryKeys } from "utils/queryKey";
import { handleReturnAutocompletePlaceholder } from "utils/StringFunctions";

interface Props {
  data?: any;
  refetch: any;
}

const EditFamilyClientPortfolioModal = (props: Props & GKModalProps) => {
  const { id } = useParams<{ id: string }>();

  const { open, setOpen, data, refetch } = props;

  const {
    state: { clientList },
  } = useAppContext();

  const [dataToSubmit, setDataToSubmit] = useState<any[]>(
    data?.data?.clientList || [],
  );

  const { mutate: handleUpdateClient, isLoading } = useMutation({
    mutationFn: (data: any) => axiosInstance.patch(`/family/${id}/`, data),
    onSuccess: () => {
      setOpen(false);
      refetch();
      refetchQuery(QueryKeys.FAMILY_LIST);
    },
  });

  const form = useForm({
    defaultValues: {
      clients: [],
    },
  });

  const clientOptions = clientList?.filter(
    (client: any) => !dataToSubmit.some((item) => item.id === client.id),
  );

  const handleDeleteFromTable = (clientId: string) => {
    const newData = dataToSubmit.filter((item) => item.id !== clientId);
    setDataToSubmit(newData);
    form.setValue(
      "clients",
      form.getValues("clients").filter((id) => id !== clientId),
    );
  };

  return (
    <GKModal
      modalTitle={`Family: ${data?.data?.name}`}
      open={open}
      setOpen={setOpen}
      footer={
        <Grid2
          container
          gap={1}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"flex-end"}
        >
          <GKButton
            variant="outlined"
            size="medium"
            color="dark"
            onClick={() => setOpen(false)}
          >
            Cancel
          </GKButton>
          <LoadingButton
            variant="contained"
            size="medium"
            disabled={isLoading}
            type="submit"
            form="client-add-family-form"
          >
            Save
          </LoadingButton>
        </Grid2>
      }
    >
      <form
        id="client-add-family-form"
        onSubmit={form.handleSubmit(() =>
          handleUpdateClient({ clients: dataToSubmit.map((item) => item.id) }),
        )}
      >
        <Grid2 container spacing={1}>
          <Grid2 size={12}>
            <Controller
              name="clients"
              control={form.control}
              render={({ field }) => (
                <GKSearchSelect
                  {...field}
                  disableClearable={false}
                  multiple
                  getOptionLabel={(option) => option.name || ""}
                  inputLabel="Select Client"
                  options={clientOptions || []}
                  value={clientOptions?.filter((option: any) =>
                    field.value.includes(option.id),
                  )}
                  onChange={(e, val) => {
                    field.onChange(val.map((client: any) => client.id));
                    setDataToSubmit([...val, ...dataToSubmit]);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder={handleReturnAutocompletePlaceholder({
                        placeholder: "Select Client",
                        value: field.value,
                      })}
                    />
                  )}
                />
              )}
            />
          </Grid2>

          {dataToSubmit.length !== 0 && (
            <>
              <Grid2 size={12}>
                <Typography fontSize={12} fontWeight={400} color={"#98A4B3"}>
                  Existing Clients
                </Typography>
              </Grid2>
              <Grid2 size={12}>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell style={{ padding: 10 }}>Client</TableCell>
                        <TableCell align="right" style={{ padding: 10 }}>
                          Action
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {dataToSubmit.map((dataItem, index) => (
                        <TableRow key={index}>
                          <TableCell style={{ padding: 10 }}>
                            {dataItem.name}
                          </TableCell>
                          <TableCell align="right" style={{ padding: 10 }}>
                            <IconButton
                              className="outline"
                              onClick={() => handleDeleteFromTable(dataItem.id)}
                              color="error"
                            >
                              <FiTrash2 size={20} />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid2>
            </>
          )}
        </Grid2>
      </form>
    </GKModal>
  );
};

export default EditFamilyClientPortfolioModal;
