import { LoadingButton } from "@mui/lab";
import { useAppContext } from "AppContext";
import {
  CgMathPlus,
  FiTrash2,
  GKButton,
  GKModal,
  GKSearchSelect,
  GKTextField,
  Grid2,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "components";
import { useMutation } from "hooks/useQueries";
import React from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { handleReturnAutocompletePlaceholder } from "utils/StringFunctions";
import { ThemeContainer } from "utils/Theme";
import axiosInstance from "utils/axiosInstance";
import { GKModalProps } from "utils/commonInterface";
import { refetchQuery } from "utils/helpers";

interface Company {
  value: string;
  advisorCompany: string;
}

interface FormValues {
  mapping: Company[];
}

const padding = { padding: 16 };

const AddCompanyMappingModal: React.FC<GKModalProps> = ({ open, setOpen }) => {
  const { control, handleSubmit } = useForm<FormValues>({
    defaultValues: {
      mapping: [
        {
          value: "",
          advisorCompany: "",
        },
      ],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "mapping",
  });

  const { mutate: addCompanyMapping, isLoading } = useMutation({
    mutationKey: ["ADD_COMPANY_MAPPING"],
    mutationFn: (data: any) =>
      axiosInstance.post("/advisor-company-mappings/", data),
    onSuccess: () => {
      refetchQuery("COMPANY_MAPPING");
      setOpen(false);
    },
  });

  const {
    state: { companies },
  } = useAppContext();

  const { theme } = ThemeContainer();

  return (
    <GKModal
      modalTitle="Add New Company Mapping"
      setOpen={setOpen}
      open={open}
      footer={
        <Grid2 container display="flex" justifyContent="space-between" gap={1}>
          <GKButton
            variant="outlined"
            color="dark"
            startIcon={<CgMathPlus size={18} />}
            onClick={() => {
              append({ value: "", advisorCompany: "" });
            }}
          >
            Add Company
          </GKButton>
          <LoadingButton
            variant="contained"
            type="submit"
            form="add-company-mapping-form"
            loading={isLoading}
          >
            Save
          </LoadingButton>
        </Grid2>
      }
    >
      <form
        id="add-company-mapping-form"
        onSubmit={handleSubmit((values: FormValues) =>
          addCompanyMapping(values.mapping),
        )}
      >
        <Grid2 container spacing={1}>
          <Grid2 size={12}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell style={padding}>Company</TableCell>
                    <TableCell style={padding}>Mapping</TableCell>
                    <TableCell style={padding} />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {fields.map((row, index) => (
                    <TableRow key={row.id}>
                      <TableCell style={{ border: "none", minWidth: "200px" }}>
                        <Controller
                          name={`mapping.${index}.value`}
                          control={control}
                          rules={{ required: true }}
                          render={({ field, fieldState: { error } }) => (
                            <GKSearchSelect
                              {...field}
                              disableClearable
                              inputLabel=""
                              renderOption={(props, option) => (
                                <li
                                  {...props}
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 4,
                                  }}
                                >
                                  <Typography
                                    color={theme.palette.light.main}
                                    fontSize={8}
                                    style={{
                                      background:
                                        option?.instType === "EQ"
                                          ? theme.palette.secondary.main
                                          : theme.palette.warning.main,
                                      padding: "2px 4px",
                                      borderRadius: 4,
                                    }}
                                  >
                                    {option?.instType}
                                  </Typography>
                                  <Typography whiteSpace={"nowrap"}>
                                    {option?.name}
                                  </Typography>
                                </li>
                              )}
                              options={companies || []}
                              value={
                                companies?.find(
                                  (data: any) => data?.value === field?.value,
                                )?.name
                              }
                              onChange={(e, val) => field.onChange(val?.value)}
                              getOptionLabel={(option) => option.name || ""}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  name="Company"
                                  placeholder={handleReturnAutocompletePlaceholder(
                                    {
                                      placeholder: "Select Company",
                                      value: field.value,
                                    },
                                  )}
                                  error={!!error}
                                />
                              )}
                            />
                          )}
                        />
                      </TableCell>
                      <TableCell>
                        <Controller
                          name={`mapping.${index}.advisorCompany`}
                          control={control}
                          rules={{ required: true }}
                          render={({ field, fieldState: { error } }) => (
                            <GKTextField
                              {...field}
                              inputLabel=""
                              placeholder="Mapping"
                              error={!!error}
                            />
                          )}
                        />
                      </TableCell>
                      <TableCell>
                        <IconButton
                          color="error"
                          className="outline"
                          disabled={fields.length === 1}
                          onClick={() => {
                            remove(index);
                          }}
                          sx={{ ml: "16px" }}
                        >
                          <FiTrash2 />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid2>
        </Grid2>
      </form>
    </GKModal>
  );
};

export default AddCompanyMappingModal;
