import { yupResolver } from "@hookform/resolvers/yup";
import { useAppContext } from "AppContext";
import {
  GKButton,
  GKDatePicker,
  GKSearchSelect,
  GKSideModal,
  GKTextArea,
  GKTextField,
  Grid2,
  PhoneInput,
  TextField,
} from "components";
import dayjs from "dayjs";
import { useFetchQuery, useMutation } from "hooks/useQueries";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { GET_LEAD_BY_ID } from "utils/apiRoutes";
import axiosInstance from "utils/axiosInstance";
import { refetchQuery } from "utils/helpers";
import { QueryKeys } from "utils/queryKey";
import { handleReturnAutocompletePlaceholder } from "utils/StringFunctions";
import { LeadSchema, panRegex } from "utils/validationSchema";

interface Props {
  id: any;
  open: any;
  setOpen: any;
}

const UpdateLead = ({ id, open, setOpen }: Props) => {
  const {
    state: { cityList, countryList, stateList },
    dispatch,
  } = useAppContext();

  const [hasError, setHasError] = useState(false);

  const { mutate: updateLead } = useMutation({
    mutationFn: (data: any) => axiosInstance.put(`/lead/${id}/`, data),
    onSuccess: () => {
      refetchQuery("GET_ALL_LEADS");
      refetchQuery(QueryKeys.GET_LEAD);
      setOpen(false);
    },
  });

  const { data: leadData, isFetching } = useFetchQuery({
    key: ["GET_LEAD_BY_ID"],
    route: GET_LEAD_BY_ID(id),
    enabled: Boolean(id),
  });

  const form = useForm({
    defaultValues: {
      name: leadData?.data?.data ? leadData?.data?.data?.name : "",
      leadInformation: {
        companyName: leadData?.data?.data
          ? leadData?.data?.data?.leadInformation?.companyName
          : "",
        leadScore: leadData?.data?.data
          ? leadData?.data?.data?.leadInformation?.leadScore
          : 0,
        jobTitle: leadData?.data?.data
          ? leadData?.data?.data?.leadInformation?.jobTitle
          : "",
        lead_score: leadData?.data?.data
          ? leadData?.data?.data?.leadInformation?.leadScore
          : "",
        address1: leadData?.data?.data
          ? leadData?.data?.data?.leadInformation?.address1
          : "",
        address2: leadData?.data?.data
          ? leadData?.data?.data?.leadInformation?.address2
          : "",
        city: leadData?.data?.data
          ? leadData?.data?.data?.leadInformation?.city
          : "",
        state: leadData?.data?.data
          ? leadData?.data?.data?.leadInformation?.state
          : "",
        pincode: leadData?.data?.data
          ? leadData?.data?.data?.leadInformation?.pincode
          : "",
        country: leadData?.data?.data
          ? leadData?.data?.data?.leadInformation?.country
          : "",
        income: leadData?.data?.data
          ? leadData?.data?.data?.leadInformation?.income
          : 0,
        leadSummary: leadData?.data?.data
          ? leadData?.data?.data?.leadInformation?.leadSummary
          : "",
      },
      phoneNumber: leadData?.data?.data
        ? leadData?.data?.data?.phoneNumber
        : "",
      countryCode: leadData?.data?.data
        ? leadData?.data?.data?.countryCode
        : "",
      lastContacted: leadData?.data?.data
        ? leadData?.data?.data?.lastContacted
        : "",
      email: leadData?.data?.data ? leadData?.data?.data?.email : "",
      tags: leadData?.data?.data ? leadData?.data?.data?.tags : "",
      leadAssignedTo: leadData?.data?.data
        ? leadData?.data?.data?.leadAssignedTo
        : "",
      sourceOfLead: leadData?.data?.data
        ? leadData?.data?.data?.sourceOfLead
        : "",
      leadStatus: leadData?.data?.data ? leadData?.data?.data?.leadStatus : "",
      panNumber: leadData?.data?.data ? leadData?.data?.data?.panNumber : "",
    },
    resolver: yupResolver(LeadSchema),
    values: leadData?.data?.data,
  });

  form.watch();

  return (
    <GKSideModal
      width="80%"
      open={open}
      setOpen={setOpen}
      modalTitle="Update Lead Details"
      footerButton={
        <Grid2 container justifyContent={"flex-end"}>
          <GKButton
            form="lead-update-form"
            type="submit"
            variant="contained"
            disabled={hasError}
          >
            Update Lead
          </GKButton>
        </Grid2>
      }
    >
      <form
        onSubmit={form.handleSubmit((values) => updateLead(values))}
        id="lead-update-form"
      >
        <Grid2 container spacing={1}>
          <Grid2 size={3}>
            <Controller
              name="name"
              control={form.control}
              render={({ field }) => (
                <GKTextField
                  {...field}
                  loading={isFetching}
                  inputLabel="Full Name"
                />
              )}
            />
          </Grid2>
          <Grid2 size={3}>
            <Controller
              name="email"
              control={form.control}
              render={({ field, formState: { errors } }) => (
                <GKTextField
                  {...field}
                  loading={isFetching}
                  inputLabel="Email Address"
                  error={Boolean(errors?.email)}
                  helperText={errors?.email?.message as string}
                />
              )}
            />
          </Grid2>
          <Grid2 size={3}>
            <Controller
              name="phoneNumber"
              control={form.control}
              render={({ field }) => (
                <PhoneInput
                  {...field}
                  onSelectChange={(event: any, val: any) => {
                    form.setValue("countryCode", val?.callingCodes);
                  }}
                  onTextChange={(event: any) => {
                    field.onChange(event.target.value);
                  }}
                  onTextValue={field.value}
                  onSelectValue={form.getValues("countryCode")}
                  hasError={hasError}
                  setHasError={setHasError}
                />
              )}
            />
          </Grid2>
          <Grid2 size={3}>
            <Controller
              name="panNumber"
              control={form.control}
              rules={{
                pattern: {
                  value: panRegex,
                  message: "PAN should have this format ABCDE1234A",
                },
                maxLength: {
                  value: 10,
                  message: "PAN should have 10 character",
                },
                minLength: {
                  value: 10,
                  message: "PAN should have 10 character",
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <GKTextField
                  {...field}
                  loading={isFetching}
                  inputLabel="PAN"
                  onChange={(e) => field.onChange(e.target.value.toUpperCase())}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
          </Grid2>
          <Grid2 size={4}>
            <Controller
              name="leadInformation.jobTitle"
              control={form.control}
              render={({ field }) => (
                <GKTextField
                  {...field}
                  loading={isFetching}
                  inputLabel="Position"
                />
              )}
            />
          </Grid2>
          <Grid2 size={4}>
            <Controller
              name="leadInformation.companyName"
              control={form.control}
              render={({ field }) => (
                <GKTextField
                  {...field}
                  loading={isFetching}
                  inputLabel="Company Name"
                />
              )}
            />
          </Grid2>
          <Grid2 size={4}>
            <Controller
              name="leadInformation.leadScore"
              control={form.control}
              render={({ field }) => (
                <GKTextField
                  {...field}
                  loading={isFetching}
                  inputLabel="Lead Value"
                />
              )}
            />
          </Grid2>
          <Grid2 size={12}>
            <Controller
              name="leadInformation.address1"
              control={form.control}
              render={({ field }) => (
                <GKTextField
                  {...field}
                  loading={isFetching}
                  inputLabel="Address 1"
                />
              )}
            />
          </Grid2>
          <Grid2 size={12}>
            <Controller
              name="leadInformation.address2"
              control={form.control}
              render={({ field }) => (
                <GKTextField
                  {...field}
                  loading={isFetching}
                  inputLabel="Address 2"
                />
              )}
            />
          </Grid2>
          <Grid2 size={3}>
            <Controller
              name="leadInformation.pincode"
              control={form.control}
              render={({ field }) => (
                <GKTextField
                  {...field}
                  loading={isFetching}
                  inputLabel="Pin Code"
                />
              )}
            />
          </Grid2>
          <Grid2 size={3}>
            <Controller
              name="leadInformation.country"
              control={form.control}
              render={({ field }) => (
                <GKSearchSelect
                  {...field}
                  inputLabel="Country"
                  renderInput={(er) => (
                    <TextField
                      {...er}
                      placeholder={handleReturnAutocompletePlaceholder({
                        placeholder: "Select Country",
                        value: field.value,
                      })}
                    />
                  )}
                  options={countryList || []}
                  getOptionLabel={(e) => e.name || e}
                  onChange={(e, val) => {
                    field.onChange(val.name);
                    form.setValue("city", "");
                    form.setValue("state", "");
                    dispatch({
                      type: "SELECTED_COUNTRY",
                      data: val.id,
                    });
                  }}
                />
              )}
            />
          </Grid2>
          <Grid2 size={3}>
            <Controller
              name="leadInformation.state"
              control={form.control}
              render={({ field }) => (
                <GKSearchSelect
                  {...field}
                  inputLabel="State"
                  renderInput={(er) => (
                    <TextField
                      {...er}
                      placeholder={handleReturnAutocompletePlaceholder({
                        placeholder: "Select State",
                        value: field.value,
                      })}
                    />
                  )}
                  options={stateList || []}
                  getOptionLabel={(e) => e.name || e}
                  onChange={(e, val) => {
                    field.onChange(val.name);
                    form.setValue("city", "");
                    dispatch({
                      type: "SELECTED_STATE",
                      data: val.id,
                    });
                  }}
                />
              )}
            />
          </Grid2>
          <Grid2 size={3}>
            <Controller
              name="leadInformation.city"
              control={form.control}
              render={({ field }) => (
                <GKSearchSelect
                  {...field}
                  inputLabel="City"
                  renderInput={(er) => (
                    <TextField
                      {...er}
                      placeholder={handleReturnAutocompletePlaceholder({
                        placeholder: "Select City",
                        value: field.value,
                      })}
                    />
                  )}
                  options={cityList || []}
                  getOptionLabel={(e) => e.name || e}
                  onChange={(e, val) => {
                    field.onChange(val.name);
                    dispatch({
                      type: "SELECTED_CITY",
                      data: val.id,
                    });
                  }}
                />
              )}
            />
          </Grid2>

          <Grid2 size={12}>
            <Controller
              name="lastContacted"
              control={form.control}
              render={({ field }) => (
                <GKDatePicker
                  {...field}
                  loading={isFetching}
                  inputLabel="Last Contacted"
                  value={dayjs(field.value)}
                  onChange={(value) => {
                    field.onChange(dayjs(value).format("YYYY-MM-DD"));
                  }}
                  disableFuture
                  slotProps={{
                    textField: {
                      error: false,
                      helperText: false,
                    },
                  }}
                />
              )}
            />
          </Grid2>
          <Grid2 size={12}>
            <Controller
              name="leadInformation.leadSummary"
              control={form.control}
              render={({ field }) => (
                <GKTextArea
                  {...field}
                  minRows={5}
                  loading={isFetching}
                  inputLabel="Description"
                />
              )}
            />
          </Grid2>
        </Grid2>
      </form>
    </GKSideModal>
  );
};

export default UpdateLead;
