import { grey } from "@mui/material/colors";
import { useAppContext } from "AppContext";
import {
  Button,
  CgMathPlus,
  DeleteIcon,
  Divider,
  DragDrop,
  FormControlLabel,
  GKRadio,
  GKSearchSelect,
  GKTextArea,
  Grid2,
  IconButton,
  RadioGroup,
  Show,
  TextField,
  Typography,
} from "components";
import { useFetchQuery, useMutation } from "hooks/useQueries";
import { AdvisorCompliaceInterface } from "interfaces/SettingsInterface";
import { orderBy, split } from "lodash";
import { financialYearFilter } from "Pages/RevenueManagement/utils";
import { useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { RiAttachment2 } from "react-icons/ri";
import axiosInstance from "utils/axiosInstance";
import {
  handleCreateFormData,
  handleReturnNaturalRowsForString,
} from "utils/formHelpers";
import { handleReturnAutocompletePlaceholder } from "utils/StringFunctions";
import { ThemeContainer } from "utils/Theme";
import AuditFilesUpload from "./AuditFilesUpload";

const ComplianceSettings = () => {
  const { theme } = ThemeContainer();

  const {
    state: { userData },
  } = useAppContext();

  const [showFilesModal, setShowFilesModal] = useState(false);

  const [uploadError, setUploadError] = useState(null);

  const [attachmentsIndex, setAttachmentsIndex] = useState(0);

  const { mutate } = useMutation({
    mutationFn: (data: any) =>
      axiosInstance.patch(`/compliance-settings/${userData?.companyId}/`, data),
  });

  const form = useForm<AdvisorCompliaceInterface>({
    defaultValues: {
      complianceAudit: [
        {
          report1: [],
          report2: [],
          year: "",
        },
      ],
      flowType: "",
      charter: [],
      disclosure: "",
    },
  });

  useFetchQuery({
    key: ["GET_ALL_COMPLIANCE_DATA"],
    route: "/compliance-settings/",
    success: (response: any) => {
      form.reset({
        complianceAudit:
          response?.data?.data?.complianceAudit?.length !== 0
            ? response?.data?.data?.complianceAudit?.map((d: any) => {
                return {
                  year: d?.year,
                  report1: [
                    {
                      name:
                        split(split(d?.report2 || "", "?")[0], "/")[
                          split(split(d?.report2 || "", "?")[0], "/").length - 1
                        ] || "",
                      file: d?.report2,
                      size: 0,
                    },
                  ],
                  report2: [
                    {
                      name:
                        split(split(d?.report1 || "", "?")[0], "/")[
                          split(split(d?.report1 || "", "?")[0], "/").length - 1
                        ] || "",
                      file: d?.report1,
                      size: 0,
                    },
                  ],
                };
              })
            : [
                {
                  report1: [],
                  report2: [],
                  year: "",
                },
              ],
        flowType: response?.data?.data?.flowType || "",
        charter: response?.data?.data?.charter
          ? [
              {
                name:
                  split(
                    split(response?.data?.data?.charter || "", "?")[0],
                    "/",
                  )[
                    split(
                      split(response?.data?.data?.charter || "", "?")[0],
                      "/",
                    ).length - 1
                  ] || "",
                file: response?.data?.data?.charter,
                size: 0,
              },
            ]
          : [],
        disclosure: response?.data?.data?.disclosure || "",
      });
    },
  });

  form.watch();

  return (
    <FormProvider {...form}>
      <form
        onSubmit={form.handleSubmit((values) => {
          mutate(
            handleCreateFormData({
              values,
              dirtyFields: form.formState.dirtyFields,
            }),
          );
        })}
      >
        <Grid2 container spacing={3}>
          <Grid2 size={3}>
            <Typography
              fontSize={16}
              fontWeight={500}
              color={theme.palette.secondaryText.main}
            >
              Subscription Module
            </Typography>
          </Grid2>
          <Grid2
            container
            size={9}
            spacing={2}
            display={"flex"}
            alignItems={"center"}
          >
            <Controller
              control={form.control}
              name="flowType"
              render={({ field }) => (
                <RadioGroup
                  {...field}
                  row
                  onChange={(e) => field.onChange(e.target.value)}
                >
                  <FormControlLabel
                    label="RIA (Registered Investment Advisor)"
                    value="ria"
                    control={<GKRadio />}
                  />
                  <FormControlLabel
                    label="RA (Research Analyst)"
                    value="ra"
                    control={<GKRadio />}
                  />
                </RadioGroup>
              )}
            />
          </Grid2>
          <Grid2 size={12}>
            <Divider />
          </Grid2>
          <Grid2 size={3}>
            <Typography
              fontSize={16}
              fontWeight={500}
              color={theme.palette.secondaryText.main}
            >
              Subscription Disclosure
            </Typography>
          </Grid2>
          <Grid2
            container
            size={9}
            spacing={2}
            display={"flex"}
            alignItems={"center"}
          >
            <Controller
              control={form.control}
              name="disclosure"
              render={({ field }) => (
                <GKTextArea {...field} placeholder="Disclosure" />
              )}
            />
          </Grid2>
          <Grid2 size={12}>
            <Divider />
          </Grid2>
          <Grid2 size={3}>
            <Typography
              fontSize={16}
              fontWeight={500}
              color={theme.palette.secondaryText.main}
            >
              Charter
            </Typography>
          </Grid2>
          <Grid2
            container
            size={9}
            spacing={2}
            display={"flex"}
            alignItems={"center"}
          >
            <Controller
              control={form.control}
              name="charter"
              render={({ field }) => (
                <DragDrop
                  {...field}
                  uploadError={uploadError}
                  setUploadError={setUploadError}
                  value={field.value}
                  onChange={(e) => {
                    field.onChange(e);
                  }}
                  supportName=".png, .jpg, .pdf"
                  acceptType={".pdf,.png,.jpg"}
                />
              )}
            />
          </Grid2>
          <Grid2 size={12}>
            <Divider />
          </Grid2>
          <Grid2 size={3}>
            <Typography
              fontSize={16}
              fontWeight={500}
              color={theme.palette.secondaryText.main}
            >
              Compliance Audit Report{" "}
            </Typography>
          </Grid2>
          <Show.When isTrue={form.getValues("complianceAudit")?.length > 0}>
            <Grid2
              container
              size={9}
              spacing={2}
              display={"flex"}
              alignItems={"center"}
            >
              {form.getValues("complianceAudit").map((item, index) => {
                return (
                  <Grid2 size={12} container>
                    <Grid2 size={3}>
                      <Controller
                        control={form.control}
                        name={`complianceAudit.${index}.year`}
                        render={({ field }) => {
                          return (
                            <GKSearchSelect
                              inputLabel="Financial Year"
                              options={handleReturnNaturalRowsForString({
                                options: orderBy(
                                  financialYearFilter,
                                  "name",
                                  "desc",
                                ),
                                optionsValueKey: "name",
                                values: field.value || "",
                              })}
                              value={field.value}
                              onChange={(e, val) => {
                                field.onChange(val?.name);
                              }}
                              getOptionLabel={(option) => option.name || option}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  placeholder={handleReturnAutocompletePlaceholder(
                                    {
                                      placeholder: "Select Financial Year",
                                      value: field.value,
                                    },
                                  )}
                                />
                              )}
                            />
                          );
                        }}
                      />
                    </Grid2>
                    <Grid2 size="auto" mt={3.5}>
                      <Typography color={grey[600]}>
                        Audit Report:{" "}
                        <span style={{ fontWeight: 600, color: "#000" }}>
                          {
                            form.getValues(`complianceAudit.${index}.report1`)
                              ?.length
                          }
                        </span>
                      </Typography>
                    </Grid2>
                    <Grid2 size="auto" mt={3.5} mr={"auto"}>
                      <Typography color={grey[600]}>
                        Adverse Findings:{" "}
                        <span style={{ fontWeight: 600, color: "#000" }}>
                          {
                            form.getValues(`complianceAudit.${index}.report2`)
                              ?.length
                          }
                        </span>
                      </Typography>
                    </Grid2>
                    <Grid2 size="auto" mt={2.5}>
                      <Button
                        variant="contained"
                        startIcon={<RiAttachment2 />}
                        onClick={() => {
                          setShowFilesModal(true);
                          setAttachmentsIndex(index);
                        }}
                      >
                        Upload Files
                      </Button>
                    </Grid2>
                    <Show.When
                      isTrue={form.getValues("complianceAudit").length !== 1}
                    >
                      <Grid2 size="auto" mt={2.6}>
                        <IconButton
                          onClick={() => {
                            const newObj = form
                              .getValues("complianceAudit")
                              .filter((d, i) => i !== index);

                            form.setValue("complianceAudit", newObj);
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Grid2>
                    </Show.When>
                  </Grid2>
                );
              })}
            </Grid2>
            <Grid2 size={3} />
          </Show.When>
          <Grid2 size={9}>
            <Button
              variant="outlined"
              size="small"
              startIcon={<CgMathPlus />}
              onClick={() => {
                const newObj = [
                  ...form.getValues("complianceAudit"),
                  {
                    report1: [],
                    report2: [],
                    year: "",
                  },
                ];
                form.setValue("complianceAudit", newObj);
              }}
            >
              Add Financial Year
            </Button>
          </Grid2>
          <Grid2 size={12}>
            <Divider />
          </Grid2>
          <Grid2 size={12} textAlign={"right"}>
            <Button type="submit" variant="contained">
              Save
            </Button>
          </Grid2>
        </Grid2>
        <Show.When isTrue={showFilesModal}>
          <AuditFilesUpload
            setOpen={setShowFilesModal}
            open={showFilesModal}
            index={attachmentsIndex}
          />
        </Show.When>
      </form>
    </FormProvider>
  );
};

export default ComplianceSettings;
