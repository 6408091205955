import {
  CgMathPlus,
  ConfirmDeleteModal,
  Divider,
  GKButton,
  GKClientDataGrid,
  Grid2,
  Show,
  Tab,
  Tabs,
} from "components";
import { useFetchQuery, useMutation } from "hooks/useQueries";
import { useState } from "react";
import { ORDER_MANAGER_MAPPING, REPLY_EMAIL_PORTFOLIO } from "utils/apiRoutes";
import axiosInstance from "utils/axiosInstance";
import { ExecutionSheetColumns } from "utils/columns";
import useDocumentTitle from "utils/useDocumentTitle";
import EmailReply from "./EmailReply";
import ExecutionSheetModal from "./ExecutionSheetModal";

const ExecutionSheet = () => {
  useDocumentTitle("Execution Sheet");

  const [tabIndex, setTabIndex] = useState(0);

  const [showSheetModal, setShowSheetModal] = useState(false);

  const [sheetDetails, setSheetDetails] = useState({});

  const [deleteId, setDeleteId] = useState();

  const [confirmDelete, setConfirmDelete] = useState(false);

  const { data: handleReplyTo, isFetching: replyToFetching } = useFetchQuery({
    key: ["GET_REPLAY_EMAIL_LIST"],
    route: REPLY_EMAIL_PORTFOLIO,
  });

  const {
    data: allSheetData,
    isLoading,
    refetch,
  } = useFetchQuery({
    key: ["ORDER_MANAGER_MAPPING"],
    route: ORDER_MANAGER_MAPPING,
  });

  const { mutate: handleDeleteExecutionSheet } = useMutation({
    mutationFn: (data) =>
      axiosInstance.delete(`/order-manager-mappings/${data}/`),
    onSuccess: () => {
      refetch();
    },
  });

  return (
    <Grid2 container spacing={2}>
      <Grid2 size={12}>
        <Tabs
          onChange={(e, val) => {
            setTabIndex(val);
          }}
          value={tabIndex}
        >
          <Tab label="Order File Format" />
          <Tab label="Pre-Confirmation Trade Email" />
        </Tabs>
        <Divider />
      </Grid2>

      <Grid2 size={12}>
        <Show.When isTrue={tabIndex === 0}>
          <GKClientDataGrid
            name="executionSheet"
            maxHeight={"calc(100vh - 410px)"}
            headerComponent={
              <GKButton
                onClick={() => {
                  setSheetDetails({});
                  setShowSheetModal(true);
                }}
                variant="outlined"
                size="medium"
                startIcon={<CgMathPlus size={18} />}
              >
                Add Execution Sheet
              </GKButton>
            }
            columns={ExecutionSheetColumns(
              refetch,
              setConfirmDelete,
              setDeleteId,
            )}
            loading={isLoading}
            rows={allSheetData?.data || []}
            onRowClick={(params) => {
              setSheetDetails(params.row);
              setShowSheetModal(true);
            }}
          />
        </Show.When>
        <Show.When isTrue={tabIndex === 1}>
          <EmailReply
            data={handleReplyTo?.data?.data}
            isLoading={replyToFetching}
          />
        </Show.When>
      </Grid2>
      {showSheetModal && (
        <ExecutionSheetModal
          open={showSheetModal}
          setOpen={setShowSheetModal}
          sheetDetails={sheetDetails}
        />
      )}

      {confirmDelete && (
        <ConfirmDeleteModal
          open={confirmDelete}
          setOpen={setConfirmDelete}
          title="Portfolio"
          onDelete={() => handleDeleteExecutionSheet(deleteId)}
          isDelete
        />
      )}
    </Grid2>
  );
};

export default ExecutionSheet;
