import { useAppContext } from "AppContext";
import {
  Card,
  DoughnutChart,
  GKButton,
  GKDataGrid,
  GKSearchSelect,
  Grid2,
  Show,
  Skeleton,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "components";
import SimpleLoader from "components/SimpleLoader";
import { usePortfolioSummary } from "hooks/usePortfolioSummary";
import { useState } from "react";
import { numberToCurrency } from "utils/MathFunction";
import { ThemeContainer } from "utils/Theme";
import { assetsHoldingsColumns } from "utils/columns";
import useDocumentTitle from "utils/useDocumentTitle";
import CardOne from "./CardOne";
import HoldingReportModal from "./HoldingReportModal";
import MultiLineComponent from "./MultiLineComponent";
import PortfolioChart from "./PortfolioChart";
import MainPortfolioChart from "./PortfolioSummaryMultiLineChart";

const HoldingsSummary = () => {
  useDocumentTitle("Holdings Overview");

  const { theme } = ThemeContainer();

  const {
    state: { commonFilter },
    dispatch,
  } = useAppContext();

  const {
    setSelectedItems,
    selectedItems,
    selectedCriteria,
    setSelectedCriteria,
    lineChartData,
    handleRowClickApiCall,
    AUMData,
    loading,
    portfolioList,
    holdingData,
    linechartTabs,
    setLinechartTabs,
    id,
    pageScrollTabs,
    setpageScrollTabs,
  } = usePortfolioSummary();

  const [isOpenModal, setIsOpenModal] = useState(false);

  return (
    <div>
      <Grid2 container spacing={2} pl={1}>
        <Show.When isTrue={!id}>
          <Grid2 size={12} container spacing={2}>
            <Grid2 size={8}>
              <GKSearchSelect
                multiple
                limitTags={5}
                groupBy={(option: any) => option?.type?.toUpperCase()}
                getOptionLabel={(option) => option?.name || option}
                loading={loading}
                onChange={(event, val) => {
                  setSelectedItems(val);
                  setSelectedCriteria(`portfolioValue`);
                  dispatch({
                    type: "FILTER",
                    data: {
                      ...commonFilter,
                      holdingSummary: {
                        skip: 0,
                        page: 0,
                        take: 25,
                        sortModel: [],
                        search: "",
                        showNonZero: commonFilter.holdingSummary.showNonZero,
                        customFilter: {},
                      },
                    },
                  });
                }}
                value={selectedItems}
                options={portfolioList || []}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder={selectedItems?.length !== 0 ? "" : "Select..."}
                  />
                )}
                sx={{
                  "& .MuiInputBase-input.MuiOutlinedInput-input": {
                    padding: "1.5px !important",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    top: "-4px !important",
                  },
                }}
                renderOption={(props, option) => {
                  return (
                    <li
                      {...props}
                      style={{
                        display: "flex",
                        alignItems: "flex-start",
                        flexDirection: "column",
                        gap: 4,
                        background:
                          selectedItems?.filter(
                            (data) => data?.value === option?.value,
                          )?.length === 0
                            ? theme.palette.light.main
                            : `${theme.palette.primary.main}22`,
                        color:
                          selectedItems?.filter(
                            (data) => data?.value === option?.value,
                          )?.length === 0
                            ? theme.palette.dark.main
                            : theme.palette.primary.main,
                      }}
                    >
                      <Typography fontWeight={600}>
                        {option.clientName}
                      </Typography>
                      <Typography>{option.name}</Typography>
                    </li>
                  );
                }}
                disableCloseOnSelect
              />
            </Grid2>
            <Grid2
              size={4}
              container
              display={"flex"}
              gap={2}
              alignItems={"center"}
            >
              <Grid2
                width={"49%"}
                display={selectedItems?.length !== 1 ? "none" : "flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
                gap={2}
                border={`solid 1px ${theme.palette.grey[300]}`}
                px={2}
                py={1}
                borderRadius={"8px"}
              >
                <Typography
                  color={theme.palette.secondaryText.main}
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                  fontSize={12}
                  textAlign={"left"}
                >
                  XIRR
                </Typography>
                {loading ? (
                  <Skeleton animation="wave" width={"50%"} />
                ) : (
                  <Typography
                    display={"flex"}
                    alignItems={"center"}
                    fontWeight={700}
                    fontSize={16}
                    color={theme.palette.dark.main}
                  >
                    {AUMData?.data?.xirr?.toFixed(2)}%
                  </Typography>
                )}
              </Grid2>
              <Grid2 ml={"auto"}>
                <Tabs
                  variant="standard"
                  className="light"
                  TabIndicatorProps={{
                    style: {
                      display: "none",
                    },
                  }}
                  onChange={(e, val) => {
                    const anchor = (
                      (e.target as HTMLDivElement).ownerDocument || document
                    ).querySelector("#back-to-top-anchor");

                    if (anchor) {
                      anchor.scrollIntoView({
                        block: "center",
                        behavior: "smooth",
                      });
                      setpageScrollTabs(val);
                    }

                    setpageScrollTabs(0);
                  }}
                  value={pageScrollTabs}
                >
                  <Tab label="Overview" disableRipple value={0} />
                  <Tab label="Holdings" disableRipple value={1} />
                </Tabs>
              </Grid2>
            </Grid2>
          </Grid2>
        </Show.When>
        <Grid2 size={4} container spacing={2}>
          <Grid2 size={12}>
            <CardOne data={AUMData?.data} loading={loading} />
          </Grid2>
        </Grid2>
        <Grid2 size={4}>
          <Card sx={{ p: 0 }}>
            {loading ? (
              <SimpleLoader />
            ) : (
              <Grid2 container>
                <Grid2
                  size={12}
                  p={2}
                  borderBottom={`solid 1px ${theme.palette.grey[300]}`}
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                  gap={1}
                >
                  <Typography
                    fontSize={14}
                    fontWeight={600}
                    display={"flex"}
                    alignItems={"center"}
                    gap={0.5}
                  >
                    Portfolio Value
                  </Typography>
                  <Typography fontSize={18} fontWeight={600}>
                    {numberToCurrency(AUMData?.data?.portfolioValue)}
                  </Typography>
                </Grid2>
                <Grid2 size={12} px={1}>
                  <DoughnutChart data={AUMData} />
                </Grid2>

                <Grid2
                  size={12}
                  display={"flex"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  py={"0px"}
                  px={"16px"}
                  mb={2}
                >
                  <Typography
                    display={"flex"}
                    alignItems={"center"}
                    fontWeight={400}
                    fontSize={12}
                    color={theme.palette.secondaryText.main}
                  >
                    <span
                      style={{
                        height: 26,
                        width: 4,
                        borderRadius: "10px",
                        marginRight: 8,
                        background: "#FCAA67",
                      }}
                    />
                    All holdings
                  </Typography>
                  <Typography fontWeight={600} fontSize={12} color={"#495567"}>
                    {numberToCurrency(AUMData?.data?.currentValue)}
                  </Typography>
                </Grid2>
                <Grid2
                  size={12}
                  display={"flex"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  py={"0px"}
                  px={"16px"}
                  mb={2}
                >
                  <Typography
                    display={"flex"}
                    alignItems={"center"}
                    fontWeight={400}
                    fontSize={12}
                    color={theme.palette.secondaryText.main}
                  >
                    <span
                      style={{
                        height: 26,
                        width: 4,
                        borderRadius: "10px",
                        marginRight: 8,
                        background: "#39627A",
                      }}
                    />
                    Equity
                  </Typography>
                  <Typography fontWeight={600} fontSize={12} color={"#495567"}>
                    {numberToCurrency(AUMData?.data?.equity)}
                  </Typography>
                </Grid2>
                <Grid2
                  size={12}
                  display={"flex"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  py={"0px"}
                  px={"16px"}
                  mb={2}
                >
                  <Typography
                    display={"flex"}
                    alignItems={"center"}
                    fontWeight={400}
                    fontSize={12}
                    color={theme.palette.secondaryText.main}
                  >
                    <span
                      style={{
                        height: 26,
                        width: 4,
                        borderRadius: "10px",
                        marginRight: 8,
                        background: "#709D88",
                      }}
                    />
                    Ledger Balance
                  </Typography>
                  <Typography fontWeight={600} fontSize={12} color={"#495567"}>
                    {numberToCurrency(AUMData?.data?.ledgerBalance)}
                  </Typography>
                </Grid2>
              </Grid2>
            )}
          </Card>
        </Grid2>
        <Grid2 size={4}>
          <Card sx={{ p: 0 }}>
            {loading ? (
              <SimpleLoader />
            ) : (
              <Grid2 container>
                <Grid2
                  size={12}
                  p={2}
                  borderBottom={`solid 1px ${theme.palette.grey[300]}`}
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                  gap={1}
                >
                  <Typography
                    fontSize={14}
                    fontWeight={600}
                    display={"flex"}
                    alignItems={"center"}
                    gap={0.5}
                  >
                    Total Gain
                  </Typography>
                  <Typography fontSize={18} fontWeight={600}>
                    {numberToCurrency(AUMData?.data?.totalReturns)}
                  </Typography>
                </Grid2>
                <Grid2 size={12} px={1}>
                  <PortfolioChart data={AUMData?.data} />
                </Grid2>
                <Grid2
                  size={12}
                  display={"flex"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  py={"0px"}
                  mb={2}
                  px={"16px"}
                >
                  <Typography
                    display={"flex"}
                    alignItems={"center"}
                    fontWeight={400}
                    fontSize={12}
                    color={theme.palette.secondaryText.main}
                  >
                    <span
                      style={{
                        height: 26,
                        width: 4,
                        borderRadius: "10px",
                        marginRight: 8,
                        background: "#39627A",
                      }}
                    />
                    Unrealised Gain
                  </Typography>
                  <Typography fontWeight={600} fontSize={12} color={"#495567"}>
                    {" "}
                    {numberToCurrency(AUMData?.data?.unrealisedGain)}
                  </Typography>
                </Grid2>
                <Grid2
                  size={12}
                  display={"flex"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  py={"0px"}
                  mb={2}
                  px={"16px"}
                >
                  <Typography
                    display={"flex"}
                    alignItems={"center"}
                    fontWeight={400}
                    fontSize={12}
                    color={theme.palette.secondaryText.main}
                  >
                    <span
                      style={{
                        height: 26,
                        width: 4,
                        borderRadius: "10px",
                        marginRight: 8,
                        background: "#709D88",
                      }}
                    />
                    Realised Gain{" "}
                  </Typography>
                  <Typography fontWeight={600} fontSize={12} color={"#495567"}>
                    {" "}
                    {numberToCurrency(AUMData?.data?.realisedGain)}
                  </Typography>
                </Grid2>
                <Grid2
                  size={12}
                  display={"flex"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  py={"0px"}
                  px={"16px"}
                  mb={2}
                >
                  <Typography
                    display={"flex"}
                    alignItems={"center"}
                    fontWeight={400}
                    fontSize={12}
                    color={theme.palette.secondaryText.main}
                  >
                    <span
                      style={{
                        height: 26,
                        width: 4,
                        borderRadius: "10px",
                        marginRight: 8,
                        background: "#FCAA67",
                      }}
                    />
                    Dividend
                  </Typography>
                  <Typography fontWeight={600} fontSize={12} color={"#495567"}>
                    {" "}
                    {numberToCurrency(AUMData?.data?.dividend)}
                  </Typography>
                </Grid2>
              </Grid2>
            )}
          </Card>
        </Grid2>
        <Grid2 size={12}>
          <Card sx={{ p: 0 }}>
            <Grid2 container>
              <Grid2
                size={12}
                spacing={1}
                container
                alignItems={"center"}
                p={2}
                borderBottom={`solid 1px ${theme.palette.grey[300]}`}
              >
                <Grid2 size={8}>
                  <Typography fontWeight={700} fontSize={18}>
                    Portfolio Curve
                  </Typography>
                </Grid2>
                <Grid2 size={4} display={"flex"} justifyContent={"flex-end"}>
                  <Tabs
                    className="light"
                    variant="standard"
                    TabIndicatorProps={{
                      style: {
                        display: "none",
                      },
                    }}
                    value={linechartTabs}
                    onChange={(e, val) => {
                      setLinechartTabs(val);
                      setSelectedCriteria(val === 0 ? "portfolioValue" : "NAV");
                    }}
                  >
                    <Tab disableRipple label="Portfolio Value" value={0} />
                    <Tab
                      disableRipple
                      label="NAV"
                      value={1}
                      disabled={selectedItems?.length === 0}
                    />
                  </Tabs>
                </Grid2>
              </Grid2>
              <Grid2 size={12}>
                {selectedCriteria === "portfolioValue" && (
                  <MainPortfolioChart
                    data={lineChartData?.data?.data}
                    loading={loading}
                  />
                )}
                {selectedCriteria === "NAV" && (
                  <MultiLineComponent
                    multiLineData={lineChartData?.data?.navs}
                    isLoading={loading}
                  />
                )}
              </Grid2>
            </Grid2>
          </Card>
        </Grid2>
        <Show.When isTrue={!id}>
          <Grid2 size={12}>
            <GKDataGrid
              density="compact"
              loading={loading}
              minHeight={"calc(100vh - 256px)"}
              columns={assetsHoldingsColumns}
              rows={holdingData?.data?.data || []}
              maxHeight={"calc(100vh - 318px)"}
              zeroHolding
              totalCount={holdingData?.data?.totalCount || 0}
              name="holdingSummary"
              tableName="holding_summary_list"
              onRowClick={(row) => {
                handleRowClickApiCall(row?.row?.gcode);
              }}
              pinnedColumns={{ left: ["name"] }}
              headerComponent={
                <GKButton
                  variant="contained"
                  size="medium"
                  onClick={() => setIsOpenModal(true)}
                >
                  Holding Report
                </GKButton>
              }
              exportFile={{
                path: `/holdings/selected/`,
                exportName: "HoldingsList",
              }}
            />
          </Grid2>

          <Show.When isTrue={isOpenModal}>
            <HoldingReportModal open={isOpenModal} setOpen={setIsOpenModal} />
          </Show.When>
        </Show.When>
        {!id && <div id={"back-to-top-anchor"} />}
      </Grid2>
    </div>
  );
};

export default HoldingsSummary;
