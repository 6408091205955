import {
  Grid2,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import { matchDate } from "utils/helpers";
import { moneyCommaSeparator } from "utils/MathFunction";
import { ThemeContainer } from "utils/Theme";
import { CompanyGetByIdInterface } from "./helper";

interface Props {
  data: CompanyGetByIdInterface;
}

const PrivateEquityPreviewCard = (props: Props) => {
  const { data } = props;

  const { theme } = ThemeContainer();

  return (
    <Grid2 container>
      <Grid2 size={12}>
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell
                  style={{
                    borderRight: `solid 1px ${theme.palette.grey[300]}`,
                    borderBottom: "none",
                    padding: 16,
                    width: "60%",
                  }}
                >
                  <Typography fontSize={16} fontWeight={600}>
                    {data?.data?.instType === "FD"
                      ? "Bank Name"
                      : "Company Name"}
                  </Typography>
                  <Typography
                    fontWeight={600}
                    fontSize={14}
                    color={theme.palette.secondaryText.main}
                  >
                    {data?.data?.name} : {moneyCommaSeparator(data?.data?.cmp)}
                  </Typography>
                </TableCell>
                <TableCell
                  style={{
                    borderRight: `solid 1px ${theme.palette.grey[300]}`,
                    borderBottom: "none",
                    padding: 16,
                    width: "40%",
                  }}
                >
                  <Typography fontSize={14} fontWeight={600}>
                    Start Date
                  </Typography>
                  <Typography
                    fontWeight={600}
                    fontSize={12}
                    color={theme.palette.secondaryText.main}
                  >
                    {matchDate(data?.data?.startingDate)}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid2>
    </Grid2>
  );
};

export default PrivateEquityPreviewCard;
