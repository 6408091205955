/* eslint-disable no-unsafe-optional-chaining */
import { useQueries } from "@tanstack/react-query";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { GET_ALL_PORTFOLIO, GET_HOLDING, INDICES } from "utils/apiRoutes";
import axiosInstance from "utils/axiosInstance";
import { QueryKeys } from "utils/queryKey";
import { PortfolioDropdownInterface } from "utils/types";
import useGenerateQueryParams from "./useGenerateQueryParams";
import { useFetchQuery } from "./useQueries";

export const usePortfolioSummary = () => {
  const { id, modelPortfolioId } = useParams();

  const [selectedCriteria, setSelectedCriteria] =
    useState<string>("portfolioValue");

  const navigate = useNavigate();

  const [linechartTabs, setLinechartTabs] = useState(0);

  const [portfolioList, setPortfolioList] = useState([]);

  const [pageScrollTabs, setpageScrollTabs] = useState(0);

  const [selectedItems, setSelectedItems] = useState<any[]>(
    id || modelPortfolioId
      ? [
          {
            id: Number(modelPortfolioId || id),
            value: Number(modelPortfolioId || id),
            name: "",
            code: "",
            type: "portfolio",
            portfolios: {
              id: Number(modelPortfolioId || id),
              value: Number(modelPortfolioId || id),
              name: "",
              portfolioId: "",
            },
          },
        ]
      : [],
  );

  /** ============================================================================ */
  const handleRowClickApiCall = async (id: string) => {
    navigate(`/app/insights-holdings/${id}/details`, {
      state: {
        portfoliosIds: selectedItems
          ?.map((data: any) => data?.portfolios)
          ?.flat()
          ?.map((dataItem) => dataItem?.value),
      },
    });
  };

  /** ============================================================================ */
  const [
    { data: lineChartData, isFetching: isMainChartLoading },
    { data: AUMData, isFetching: aumLoading },
  ] = useQueries({
    queries: [
      {
        queryKey: [
          selectedItems
            ?.map((data: any) => data?.portfolios)
            ?.flat()
            ?.map((dataItem) => dataItem?.value),
          QueryKeys.HOLDINGS_SUMMARY_LINE_CHART_DATA,
        ],
        retry: 0,
        queryFn: () =>
          axiosInstance.post(`/aums/timeline/portfolios/portfolio_summary/`, {
            selected_portfolios: selectedItems
              ?.map((data: any) => data?.portfolios)
              ?.flat()
              ?.map((dataItem) => dataItem?.value),
            is_model_portfolio: !!modelPortfolioId,
          }),
        keepPreviousData: true,
      },
      {
        queryKey: [
          selectedItems
            ?.map((data: any) => data?.portfolios)
            ?.flat()
            ?.map((dataItem) => dataItem?.value),
          "CASH_EQUITY",
        ],
        retry: 0,
        queryFn: () =>
          axiosInstance.post(`/holdings/cash_equity/`, {
            portfolios: selectedItems
              ?.map((data: any) => data?.portfolios)
              ?.flat()
              ?.map((dataItem) => dataItem?.value),
            is_model_portfolio: !!modelPortfolioId,
          }),
      },
    ],
  });

  /** ============================================================================ */
  const { isFetching: portfolioLoading } = useFetchQuery({
    key: [QueryKeys.PORTFOLIO_OVERVIEW],
    retry: 0,
    route: GET_ALL_PORTFOLIO,
    success: (response: any) => {
      if (response?.data?.length === 0) {
        setSelectedItems(
          response?.data?.map((item: PortfolioDropdownInterface) => ({
            name: `${item?.name} - ${item?.code}`,
            value: item?.id,
            type: item?.type,
            portfolios: item?.portfolios?.map((portfolio) => ({
              label: portfolio?.name,
              value: portfolio?.id,
              portfolioId: portfolio?.portfolioId,
            })),
          })),
        );
      }

      setPortfolioList(
        response?.data?.map((item: PortfolioDropdownInterface) => ({
          name: `${item?.name} - ${item?.code}`,
          value: item?.id,
          type: item?.type,
          clientName: item?.clientName,
          portfolios: item?.portfolios?.map((portfolio) => ({
            label: portfolio?.name,
            value: portfolio?.id,
            portfolioId: portfolio?.portfolioId,
          })),
        })),
      );
    },
  });
  /** ============================================================================ */
  const { data: niftyData, isFetching: niftyLoading } = useFetchQuery({
    key: [QueryKeys.NIFTY_SENSEX],
    retry: 0,
    route: INDICES,
    keepPreviousData: true,
  });

  const requestBody = useGenerateQueryParams("holdingSummary", {
    is_model_portfolio: !!modelPortfolioId,
    portfolios: JSON.stringify(
      selectedItems
        ?.map((data: any) => data?.portfolios)
        ?.flat()
        ?.map((dataItem) => dataItem?.value),
    ),
  });

  const { data: holdingData, isFetching: holdingLoading } = useFetchQuery({
    key: [
      QueryKeys.HOLDING_SUMMARY,
      requestBody,
      selectedItems
        ?.map((data: any) => data?.portfolios)
        ?.flat()
        ?.map((dataItem) => dataItem?.value),
    ],
    retry: 0,
    route: GET_HOLDING,
    requestBody,
    enabled: !window.location.href.includes("equity-basket"),
  });

  /** ============================================================================ */
  const loading =
    portfolioLoading ||
    isMainChartLoading ||
    aumLoading ||
    niftyLoading ||
    holdingLoading;

  return {
    setSelectedItems,
    selectedItems,
    selectedCriteria,
    setSelectedCriteria,
    lineChartData,
    handleRowClickApiCall,
    AUMData,
    loading,
    niftyData,
    portfolioList,
    holdingData,
    linechartTabs,
    setLinechartTabs,
    id,
    pageScrollTabs,
    setpageScrollTabs,
  };
};
