/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { GKButton, GKModal, Grid2 } from "components";
import { useRef, useState } from "react";
import ReactCrop, { type Crop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { GKModalProps } from "utils/commonInterface";

interface Props {
  img?: string;
  onCropComplete: (croppedImageUrl: string) => void;
  aspect?: number;
}

const EditLogoModal = (props: Props & GKModalProps) => {
  const { open, setOpen, img, onCropComplete, aspect } = props;
  const [crop, setCrop] = useState<Crop>({
    unit: "%",
    width: 50,
    height: 50,
    x: 25,
    y: 25,
  });

  const imgRef = useRef<HTMLImageElement | null>(null);
  const [completedCrop, setCompletedCrop] = useState<Crop | null>(null);

  const handleCropComplete = (c: Crop) => {
    setCompletedCrop(c);
  };

  const generateCroppedImage = async () => {
    if (!completedCrop || !imgRef.current) return;

    const image = imgRef.current;

    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;

    const ctx = canvas.getContext("2d");
    if (!ctx) return;

    canvas.width = completedCrop.width;
    canvas.height = completedCrop.height;

    ctx.drawImage(
      image,
      completedCrop.x * scaleX,
      completedCrop.y * scaleY,
      completedCrop.width * scaleX,
      completedCrop.height * scaleY,
      0,
      0,
      completedCrop.width,
      completedCrop.height,
    );

    canvas.toBlob((blob) => {
      if (blob) {
        const croppedImageUrl = URL.createObjectURL(blob);
        onCropComplete(croppedImageUrl);
      }
    }, "image/png");
  };

  return (
    <GKModal
      open={open}
      setOpen={setOpen}
      modalTitle="Crop Logo"
      footer={
        <GKButton variant="contained" onClick={generateCroppedImage}>
          Crop & Save
        </GKButton>
      }
    >
      <Grid2 container>
        <Grid2 size={12} textAlign={"center"}>
          <ReactCrop
            crop={crop}
            onChange={(c) => setCrop(c)}
            onComplete={handleCropComplete}
            keepSelection
            aspect={aspect}
          >
            <img
              ref={imgRef}
              src={img}
              alt=""
              height={"100%"}
              style={{
                maxHeight: "calc(100vh - 400px)",
              }}
              width={"auto"}
            />
          </ReactCrop>
        </Grid2>
      </Grid2>
    </GKModal>
  );
};

export default EditLogoModal;
