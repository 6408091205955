import { useAppContext } from "AppContext";
import {
  AnimationComponent,
  Box,
  GKButton,
  Grid2,
  Show,
  Tab,
  Tabs,
} from "components";
import { useFetchQuery } from "hooks/useQueries";
import DashboardLayout from "layouts/DashboardLayout";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { UserRoleTypes } from "utils/common";
import { ThemeContainer } from "utils/Theme";
import MethodologiesTab from "./EquityBasketMethodologiesTab";
import EquityBasketView from "./EquityBasketView";

const BasketViewTab = () => {
  const { theme } = ThemeContainer();

  const { id } = useParams();
  const navigate = useNavigate();

  const {
    state: { userData },
  } = useAppContext();

  const [tabValue, setTabValue] = useState(0);

  const { data, isFetching } = useFetchQuery({
    key: ["GET_MODEL_PORTFOLIO_DETAILS_DATA"],
    route: `/model_portfolio/${id}/details/`,
    enabled: Boolean(id),
  });

  const tabs = [
    { label: "Meta", component: <EquityBasketView data={data?.data} /> },
    {
      label: "Methodologies",
      component: <MethodologiesTab data={data?.data} />,
    },
  ];

  return (
    <DashboardLayout title="Basket View" loading={isFetching}>
      <Grid2 container spacing={2}>
        <Grid2 size={12}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Grid2 container>
              <Grid2 size={9}>
                <Tabs
                  sx={{
                    "& .MuiTabs-flexContainer": {
                      backgroundColor: theme.palette.light.main,
                    },
                  }}
                  onChange={(e, val) => setTabValue(val)}
                  value={tabValue}
                >
                  {tabs.map((tab, index) => (
                    <Tab key={index} label={tab.label} disableRipple />
                  ))}
                </Tabs>
              </Grid2>
              <Grid2
                size={3}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"flex-end"}
              >
                {userData?.userRole === UserRoleTypes.advisor && (
                  <GKButton
                    variant="outlined"
                    color="dark"
                    size="small"
                    onClick={() => {
                      navigate(`/app/equity-basket/${id}/edit`);
                    }}
                  >
                    Edit Basket
                  </GKButton>
                )}
              </Grid2>
            </Grid2>
          </Box>
        </Grid2>
        <Grid2 size={12}>
          {tabs.map((tab, index) => (
            <Show.When key={index} isTrue={tabValue === index}>
              <AnimationComponent>{tab.component}</AnimationComponent>
            </Show.When>
          ))}
        </Grid2>
      </Grid2>
    </DashboardLayout>
  );
};

export default BasketViewTab;
