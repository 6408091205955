/* eslint-disable import/no-named-as-default-member */
import { LoadingButton } from "@mui/lab";
import { GKButton, GKClientDataGrid, Grid2 } from "components";
import { useFetchQuery, useMutation } from "hooks/useQueries";
import DashboardLayout from "layouts/DashboardLayout";
import { useNavigate, useParams } from "react-router-dom";
import { RISK_PROFILE_BY_ID } from "utils/apiRoutes";
import axiosInstance from "utils/axiosInstance";
import { ClientRiskProfilingColumns } from "utils/columns";
import useDocumentTitle from "utils/useDocumentTitle";
import RiskCard from "./RiskCard";

const ClientRiskProfilingPage = () => {
  useDocumentTitle("Client Risk Profile");

  const navigate = useNavigate();

  const { id } = useParams();

  const { data: answers, isFetching: RiskResponseDetailsFetching } =
    useFetchQuery({
      key: ["RISK_PROFILE_LIST_ANSWER"],
      route: RISK_PROFILE_BY_ID(id),
    });

  const { mutate, isLoading } = useMutation({
    mutationFn: (data: any) =>
      axiosInstance.post(`/riskprofile-request/`, { client: data }),
    mutationKey: ["RISK_PROFILE_CLIENT_REQUEST"],
  });
  return (
    <DashboardLayout title="Client Risk Profile">
      <Grid2 container spacing={1}>
        <Grid2 size={12}>
          <RiskCard answers={answers?.data?.data} />
        </Grid2>
        <Grid2 size={12}>
          <GKClientDataGrid
            name="ClientRiskProfiling"
            headerComponent={
              <Grid2 container justifyContent={"flex-end"} gap={1}>
                <LoadingButton
                  loading={isLoading}
                  variant="outlined"
                  color="dark"
                  size="medium"
                  onClick={() => {
                    mutate(id);
                  }}
                >
                  Request Risk Questionnaire
                </LoadingButton>
                <GKButton
                  variant="outlined"
                  color="dark"
                  size="medium"
                  onClick={() => {
                    navigate(`/app/risk-profile/${id}/questionnaire`);
                  }}
                >
                  {answers?.data?.data.length
                    ? "Retake Questionnaire"
                    : "Take Questionnaire"}
                </GKButton>
              </Grid2>
            }
            loading={RiskResponseDetailsFetching}
            rows={answers?.data?.data?.response || []}
            columns={ClientRiskProfilingColumns(id)}
          />
        </Grid2>
      </Grid2>
    </DashboardLayout>
  );
};

export default ClientRiskProfilingPage;
