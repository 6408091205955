import {
  Grid2,
  NoData,
  SimpleLoader,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "components";
import dayjs from "dayjs";
import { isEmpty } from "lodash";
import { useFormContext } from "react-hook-form";
import { moneyCommaSeparator } from "utils";

interface Props {
  isLoading: boolean;
}

export const RealisedGainSection = (props: Props) => {
  const { isLoading } = props;

  const form = useFormContext();

  const currentYear = dayjs().year();
  const lastYear = currentYear - 1;

  const currentFinancialYear = `${lastYear}-${currentYear}`;

  const portfolioData = form.getValues("responseData");

  if (isEmpty(portfolioData) && !isLoading) return <NoData />;

  return isLoading ? (
    <SimpleLoader />
  ) : (
    <Grid2 container spacing={3}>
      {Object.entries(portfolioData)?.map(([dataItem1, dataItem2]) => {
        return (
          <Grid2 size={12} container spacing={3} key={dataItem1}>
            <Grid2 size={12}>
              <Typography fontSize={16} fontWeight={600}>
                {dataItem1}
              </Typography>
            </Grid2>
            {Object.entries(dataItem2).map(([dataItem3, dataItem4]) => {
              return (
                <Grid2 size={12} key={`${dataItem1}-${dataItem3}`}>
                  <Typography color="44484F">{dataItem3}</Typography>
                  <TableContainer sx={{ mt: 1 }}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell
                            style={{
                              borderRight: `solid 1px #D5DEE5`,
                              borderBottom: "none",
                              padding: 8,
                              background: "#EEF5FA",
                              width: "20%",
                            }}
                          />
                          <TableCell
                            align="right"
                            style={{
                              borderRight: `solid 1px #D5DEE5`,
                              borderBottom: "none",
                              padding: 8,
                              background: "#EEF5FA",
                              width: "20%",
                            }}
                          >
                            LONG TERM
                          </TableCell>
                          <TableCell
                            align="right"
                            style={{
                              borderRight: `solid 1px #D5DEE5`,
                              borderBottom: "none",
                              padding: 8,
                              background: "#EEF5FA",
                              width: "20%",
                            }}
                          >
                            SHORT TERM
                          </TableCell>
                          <TableCell
                            align="right"
                            style={{
                              borderRight: `solid 1px #D5DEE5`,
                              borderBottom: "none",
                              padding: 8,
                              background: "#EEF5FA",
                              width: "20%",
                            }}
                          >
                            INTRADAY
                          </TableCell>
                          <TableCell
                            align="right"
                            style={{
                              borderBottom: "none",
                              padding: 8,
                              background: "#EEF5FA",
                              width: "20%",
                            }}
                          >
                            TOTAL
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell
                            style={{
                              borderRight: `solid 1px #D5DEE5`,
                              borderBottom: "solid 1px #EEF5FA",
                              padding: 8,
                              fontWeight: 600,
                              fontSize: 12,
                              color: "#697586",
                              width: "20%",
                            }}
                          >
                            AMOUNT
                          </TableCell>
                          <TableCell
                            align="right"
                            style={{
                              borderRight: `solid 1px #D5DEE5`,
                              borderBottom: "solid 1px #EEF5FA",
                              padding: 8,
                              fontSize: 16,
                              fontWeight: 500,
                              width: "20%",
                            }}
                          >
                            {moneyCommaSeparator(
                              dataItem4?.longTermCapitalGain,
                            )}
                          </TableCell>
                          <TableCell
                            align="right"
                            style={{
                              borderRight: `solid 1px #D5DEE5`,
                              borderBottom: "solid 1px #EEF5FA",
                              padding: 8,
                              fontSize: 16,
                              fontWeight: 500,
                              width: "20%",
                            }}
                          >
                            {moneyCommaSeparator(
                              dataItem4?.shortTermCapitalGain,
                            )}
                          </TableCell>
                          <TableCell
                            align="right"
                            style={{
                              borderRight: `solid 1px #D5DEE5`,
                              borderBottom: "solid 1px #EEF5FA",
                              padding: 8,
                              fontSize: 16,
                              fontWeight: 500,
                              width: "20%",
                            }}
                          >
                            {moneyCommaSeparator(
                              dataItem4?.intraDayCapitalGain,
                            )}
                          </TableCell>
                          <TableCell
                            align="right"
                            style={{
                              borderBottom: "solid 1px #EEF5FA",
                              padding: 8,
                              fontSize: 16,
                              fontWeight: 500,
                              width: "20%",
                            }}
                          >
                            {moneyCommaSeparator(dataItem4?.totalCapitalGain)}
                          </TableCell>
                        </TableRow>
                        {dataItem3 !== currentFinancialYear &&
                          dataItem1 !== currentFinancialYear && (
                            <TableRow>
                              <TableCell
                                style={{
                                  borderRight: `solid 1px #D5DEE5`,
                                  borderBottom: "solid 1px #EEF5FA",
                                  padding: 8,
                                  fontWeight: 600,
                                  fontSize: 12,
                                  color: "#697586",
                                  width: "20%",
                                }}
                              >
                                TAX AMOUNT
                              </TableCell>
                              <TableCell
                                align="right"
                                style={{
                                  borderRight: `solid 1px #D5DEE5`,
                                  borderBottom: "solid 1px #EEF5FA",
                                  padding: 8,
                                  fontSize: 16,
                                  fontWeight: 500,
                                  width: "20%",
                                }}
                              >
                                {moneyCommaSeparator(
                                  dataItem4?.longTermTaxAmount,
                                )}
                                <Typography
                                  fontSize={12}
                                  fontWeight={500}
                                  color="#697586"
                                >
                                  Tax: {dataItem4?.longTermTaxPercentage}%
                                </Typography>
                              </TableCell>
                              <TableCell
                                align="right"
                                style={{
                                  borderRight: `solid 1px #D5DEE5`,
                                  borderBottom: "solid 1px #EEF5FA",
                                  padding: 8,
                                  fontSize: 16,
                                  fontWeight: 500,
                                  width: "20%",
                                }}
                              >
                                {moneyCommaSeparator(
                                  dataItem4?.shortTermTaxAmount,
                                )}
                                <Typography
                                  fontSize={12}
                                  fontWeight={500}
                                  color="#697586"
                                >
                                  Tax: {dataItem4?.shortTermTaxPercentage}%
                                </Typography>
                              </TableCell>
                              <TableCell
                                align="right"
                                style={{
                                  borderRight: `solid 1px #D5DEE5`,
                                  borderBottom: "solid 1px #EEF5FA",
                                  padding: 8,
                                  fontSize: 16,
                                  fontWeight: 500,
                                  width: "20%",
                                }}
                              >
                                {moneyCommaSeparator(
                                  dataItem4?.intraDayTaxAmount,
                                )}
                                <Typography
                                  fontSize={12}
                                  fontWeight={500}
                                  color="#697586"
                                >
                                  Tax: {dataItem4?.intraDayTaxPercentage}%
                                </Typography>
                              </TableCell>
                              <TableCell
                                align="right"
                                style={{
                                  borderBottom: "solid 1px #EEF5FA",
                                  padding: 8,
                                  fontSize: 16,
                                  fontWeight: 500,
                                  width: "20%",
                                }}
                              >
                                {moneyCommaSeparator(dataItem4?.totalTaxAmount)}
                              </TableCell>
                            </TableRow>
                          )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid2>
              );
            })}
          </Grid2>
        );
      })}
    </Grid2>
  );
};
