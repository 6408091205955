import { GKOverlayLoader } from "components";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import axiosInstance from "utils/axiosInstance";
import { currentVersion } from "utils/constants";

const RALayout = () => {
  const { search } = useLocation();

  const token = new URLSearchParams(search).get("token");

  useEffect(() => {
    localStorage.setItem("token", JSON.stringify(token));
    localStorage.setItem("role", "client");
    localStorage.setItem("product-version", currentVersion);

    axiosInstance.interceptors.request.use((config) => {
      config.headers.Authorization = `Token ${token}`;
      config.headers[`x-gridkey-user-role`] = "client";
      return config;
    });
    setTimeout(() => {
      window.location.href = "/app/holdings";
    }, 3000);
  }, []);

  return <GKOverlayLoader />;
};

export default RALayout;
