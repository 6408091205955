import { useAppContext } from "AppContext";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  GKButton,
  GKModal,
  GKRadio,
  GKSearchSelect,
  GKTextField,
  Grid2,
  RadioGroup,
  TextField,
} from "components";
import { useMutation } from "hooks/useQueries";
import { useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import axiosInstance from "utils/axiosInstance";
import { GKModalProps } from "utils/commonInterface";
import { refetchQuery } from "utils/helpers";
import { handleReturnAutocompletePlaceholder } from "utils/StringFunctions";
import { ThemeContainer } from "utils/Theme";

interface FormValues {
  client: string;
  mode: string;
  accountType: string;
  portfolio: string;
  amount: string;
}

const accountTypeOption = [
  { name: "Saving", value: "savings" },
  { name: "Current", value: "current" },
];

const MandateModal = ({ open, setOpen }: GKModalProps) => {
  const {
    state: { clientList, portfolios },
  } = useAppContext();

  const { theme } = ThemeContainer();

  const form = useForm<FormValues>({
    defaultValues: {
      client: "",
      mode: "upi",
      accountType: "",
      portfolio: "",
      amount: "",
    },
  });

  const watchClient = form.watch("client");

  const portfolioList = useMemo(
    () => portfolios.filter((dataItem) => dataItem?.clientId === watchClient),
    [watchClient],
  );

  const { mutate: handleCreateMandate } = useMutation({
    mutationKey: ["CREATE_CLIENT_MANDATE"],
    mutationFn: (data: FormValues) =>
      axiosInstance.post("/digio/client-mandate/ria/", data),
    onSuccess: () => {
      refetchQuery("REVENUE_MANDATE_LIST");
      setOpen(false);
    },
  });

  return (
    <GKModal
      open={open}
      setOpen={setOpen}
      modalTitle="Create Mandate"
      footer={
        <GKButton variant="contained" type="submit" form="create-mandate-form">
          Save
        </GKButton>
      }
    >
      <form
        id="create-mandate-form"
        onSubmit={form.handleSubmit((values) => handleCreateMandate(values))}
      >
        <Grid2 container spacing={1}>
          <Grid2 size={6}>
            <Controller
              name="client"
              control={form.control}
              rules={{
                required: {
                  value: true,
                  message: "Please select client",
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <GKSearchSelect
                  {...field}
                  disableClearable
                  inputLabel="Client"
                  options={clientList}
                  value={
                    clientList.find((client) => client.value === field.value)
                      ?.name || ""
                  }
                  onChange={(e, val) => {
                    field.onChange(val?.value);
                    form.resetField("portfolio");
                  }}
                  getOptionLabel={(option) => option.name || option}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder={handleReturnAutocompletePlaceholder({
                        placeholder: "Select Client",
                        value: field.value,
                      })}
                      error={!!error}
                      helperText={error?.message}
                    />
                  )}
                />
              )}
            />
          </Grid2>
          <Grid2 size={6}>
            <Controller
              name="portfolio"
              control={form.control}
              rules={{
                required: {
                  value: true,
                  message: "Please select portfolio",
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <GKSearchSelect
                  {...field}
                  disableClearable
                  inputLabel="Portfolio"
                  options={portfolioList}
                  value={
                    portfolioList.find(
                      (portfolio) => portfolio.value === field.value,
                    )?.name || ""
                  }
                  onChange={(e, val) => field.onChange(val?.value)}
                  getOptionLabel={(option) => option.name || option}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder={handleReturnAutocompletePlaceholder({
                        placeholder: "Select Portfolio",
                        value: field.value,
                      })}
                      error={!!error}
                      helperText={error?.message}
                    />
                  )}
                />
              )}
            />
          </Grid2>
          <Grid2 size={6}>
            <Controller
              name="accountType"
              control={form.control}
              rules={{
                required: {
                  value: true,
                  message: "Please select account type",
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <GKSearchSelect
                  {...field}
                  disableClearable
                  inputLabel="Account Type"
                  options={accountTypeOption}
                  value={
                    accountTypeOption.find((type) => type.value === field.value)
                      ?.name || ""
                  }
                  onChange={(e, val) => field.onChange(val?.value)}
                  getOptionLabel={(option) => option.name || option}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder={handleReturnAutocompletePlaceholder({
                        placeholder: "Select Account Type",
                        value: field.value,
                      })}
                      error={!!error}
                      helperText={error?.message}
                    />
                  )}
                />
              )}
            />
          </Grid2>
          <Grid2 size={6}>
            <Controller
              name="amount"
              control={form.control}
              rules={{
                required: {
                  value: true,
                  message: "Please enter amount",
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <GKTextField
                  {...field}
                  type="number"
                  inputLabel="Amount"
                  onChange={(
                    event: React.ChangeEvent<
                      HTMLInputElement | HTMLTextAreaElement
                    >,
                  ) => {
                    if (event.target instanceof HTMLInputElement) {
                      field.onChange(event.target.valueAsNumber);
                    }
                  }}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
          </Grid2>
          <Grid2 size={6} display={"flex"} gap={2}>
            <Controller
              name="mode"
              control={form.control}
              render={({ field }) => (
                <FormControl>
                  <FormLabel
                    style={{
                      color: theme.palette.dark.main,
                      fontSize: "14px",
                      textTransform: "capitalize",
                    }}
                  >
                    Mode
                  </FormLabel>
                  <RadioGroup row {...field}>
                    <FormControlLabel
                      value="upi"
                      control={<GKRadio />}
                      label="UPI"
                    />
                    <FormControlLabel
                      value="api"
                      control={<GKRadio />}
                      label="API"
                    />
                  </RadioGroup>
                </FormControl>
              )}
            />
          </Grid2>
        </Grid2>
      </form>
    </GKModal>
  );
};

export default MandateModal;
