import { Grid2, Popover, Typography } from "@mui/material";
import GKButton from "./GKButton";

interface Props {
  open: boolean;
  setOpen: (open: any) => void;
  anchorEl: any;
  message?: string;
  onSuccess: () => void;
}

const PopConfirm = (props: Props) => {
  const { open, setOpen, anchorEl, message, onSuccess } = props;

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={() => setOpen(null)}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      slotProps={{
        paper: {
          style: { width: "20%", borderRadius: 8 },
        },
      }}
    >
      <Grid2 container spacing={3} p={2}>
        <Grid2 size={12}>
          <Typography fontWeight={500}>
            {message ||
              "Are you sure you want to delete this item? All of your data will be permanently removed. This action cannot be undone."}
          </Typography>
        </Grid2>

        <Grid2 size={12} gap={1} display={"flex"} justifyContent={"flex-end"}>
          <GKButton
            variant="outlined"
            size="extraSmall"
            color="dark"
            onClick={() => setOpen(null)}
          >
            No
          </GKButton>
          <GKButton
            variant="contained"
            size="extraSmall"
            onClick={() => {
              onSuccess();
              setOpen(null);
            }}
          >
            Yes
          </GKButton>
        </Grid2>
      </Grid2>
    </Popover>
  );
};

export default PopConfirm;
