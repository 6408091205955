import { useEffect } from "react";
import { DOCUMENT_TITLE } from "store/slices/appSlice";
import { useAppDispatch } from "store/store";

const useDocumentTitle = (title: string) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    document.title = `GridKey | ${title}`;
    dispatch(DOCUMENT_TITLE(title));
  }, [title]);
};

export default useDocumentTitle;
