import {
  FormControl,
  InputLabel,
  Skeleton,
  TextField,
  TextFieldProps,
  Tooltip,
  styled,
} from "@mui/material";
import { GoInfo } from "react-icons/go";
import { blockInvalidChar } from "utils/common";
import { ThemeContainer } from "utils/Theme";

interface GKTextFieldProps {
  inputLabel?: string | any;
  requiredField?: boolean;
  loading?: boolean;
  blockChar?: boolean;
  tooltipTitle?: string;
  placeholder?: string;
}

const BootstrapInput = styled(TextField)(({ theme, size }) => ({
  backgroundColor: theme.palette.light.main,
  borderRadius: 8,
  "label + &": {
    marginTop: "20px",
  },
  "& .MuiOutlinedInput-root": {
    borderRadius: 8,
  },
  "& .MuiInputBase-input": {
    borderRadius: 8,
    position: "relative",
    fontSize: 12,
    padding: size === "small" ? "8px" : "12px",
    "&::placeholder": {
      textTransform: "capitalize",
    },
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
  },
  "& .MuiFormHelperText-root ": {
    margin: 0,
    color: theme.palette.error.main,
    fontWeight: 400,
  },
}));

const GKTextField = (props: GKTextFieldProps & TextFieldProps) => {
  const { theme } = ThemeContainer();
  const {
    inputLabel,
    id,
    name,
    loading,
    requiredField,
    type,
    placeholder,
    fullWidth = true,
    tooltipTitle,
    blockChar = true,
    slotProps,
  } = props;

  return (
    <FormControl variant="standard" fullWidth={fullWidth}>
      {inputLabel && (
        <InputLabel
          style={{
            color: theme.palette.dark.main,
            fontSize: "18px",
            fontFamily: "Inter !important",
            textTransform: "capitalize",
            display: "flex",
            alignItems: "center",
            gap: 2,
          }}
          shrink
          htmlFor={name || id}
        >
          {inputLabel}
          {requiredField && (
            <span
              style={{
                color: theme.palette.error.main,
              }}
            >
              *
            </span>
          )}
          {tooltipTitle && (
            <Tooltip title={tooltipTitle} arrow>
              <GoInfo />
            </Tooltip>
          )}
        </InputLabel>
      )}
      {loading ? (
        <Skeleton
          animation="wave"
          variant="rectangular"
          sx={{
            marginTop: "20px",
            height: 46,
            borderRadius: 2,
          }}
        />
      ) : (
        <BootstrapInput
          {...props}
          placeholder={placeholder || inputLabel || "Enter"}
          onKeyDown={(e) => blockChar && blockInvalidChar(e)}
          onWheel={(e: any) => e.target.blur()}
          autoComplete="off"
          slotProps={{
            ...slotProps,
            input: {
              ...slotProps?.input,
              autoComplete: "off",
            },
          }}
          fullWidth
          type={type || ""}
        />
      )}
    </FormControl>
  );
};

export default GKTextField;
