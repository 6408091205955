import { Outlet } from "react-router-dom";

const ClientOnBoardingLayout = () => {
  return (
    <div
      style={{
        width: "100vw",
        display: "flex",
        height: "100vh",
        alignItems: "center",
        justifyContent: "center",
        background: "#030708",
      }}
    >
      <Outlet />
    </div>
  );
};

export default ClientOnBoardingLayout;
