import { GridColDef } from "@mui/x-data-grid-pro";
import { useAppContext } from "AppContext";
import ImportBrokerModal from "Pages/CustomBrokerImport/ImportBrokerModal";
import {
  CgMathPlus,
  ConfirmDeleteModal,
  GKButton,
  GKDataGrid,
  Grid2,
  PiArrowSquareDownRightDuotone,
  Show,
} from "components";
import useGenerateQueryParams from "hooks/useGenerateQueryParams";
import { useFetchQuery, useMutation } from "hooks/useQueries";
import { PortfolioDetailsInterface } from "interfaces/Portfolio";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  moneyCommaSeparator,
  moneyCommaSeparatorPoint,
} from "utils/MathFunction";
import axiosInstance from "utils/axiosInstance";
import { UserRoleTypes, handleCapitalize } from "utils/common";
import { extraColumnsField, matchDate, refetchQuery } from "utils/helpers";

interface Props {
  name: string;
  apiRoute: string;
  apiRouteKey: string;
  tableName: string;
  routeNavigate: {
    navigate: string;
    transactionType?: string;
    assetType?: string;
    editNavigate?: string;
  };
  bulkDelete: string;
  exportRoute: string;
  portfolioId: string;
  brokerBtn?: boolean;
  portfolioDetailData?: PortfolioDetailsInterface;
  requestParams?: {
    key?: string;
    value?: string;
  };
}

const PortfolioSummaryTradeBookTab = (props: Props) => {
  const {
    name,
    apiRoute,
    apiRouteKey,
    tableName,
    routeNavigate,
    bulkDelete,
    exportRoute,
    portfolioId,
    brokerBtn = false,
    portfolioDetailData,
    requestParams,
  } = props;

  const { id } = useParams();

  const navigate = useNavigate();

  const {
    state: { userData },
  } = useAppContext();

  const [selectedIds, setSelectedIds] = useState([]);
  const [deleteConfirmation, setDeleteConfirmation] = useState<boolean>(false);
  const [openImportBrokerModal, setOpenImportBrokerModal] = useState(false);

  const requestBody = useGenerateQueryParams(name, {
    [requestParams?.key]: requestParams?.value,
  });

  const { data, isFetching, refetch } = useFetchQuery({
    route: apiRoute,
    key: [apiRouteKey, requestBody],
    requestBody,
  });

  const { mutate: handleMultiDelete } = useMutation({
    mutationFn: ({ ids }: { ids: any }) =>
      axiosInstance?.delete(bulkDelete, {
        data: { ids: ids },
      }),
    onSuccess: () => {
      refetchQuery(apiRouteKey);
      setDeleteConfirmation(false);
      setSelectedIds([]);
    },
  });

  const transactionColumns: (GridColDef & { visibleType?: string[] })[] = [
    {
      ...extraColumnsField("Date", "date", 150, 1),
      valueFormatter: (value) => matchDate(value.value),
      visibleType: [
        "portfolioTradeBook",
        "portfolioPrivateEquityList",
        "portfolioFDList",
        "portfolioFDPayoutList",
        "portfolioAIFList",
      ],
    },
    {
      ...extraColumnsField("Company Name", "companyName", 150, 1),
      visibleType: [
        "portfolioTradeBook",
        "portfolioPrivateEquityList",
        "portfolioFDList",
        "portfolioFDPayoutList",
        "portfolioAIFList",
      ],
    },
    {
      ...extraColumnsField("FD Account No.", "fdAccountNo", 150, 1),
      visibleType: ["portfolioFDList", "portfolioFDPayoutList"],
    },
    {
      ...extraColumnsField("Interest Type", "interestType", 150, 1),
      visibleType: ["portfolioFDList", "portfolioFDPayoutList"],
    },
    {
      ...extraColumnsField("NSE/BSE Code", "nseCode", 150, 1),
      valueGetter: (params) => params?.row?.nseCode || params?.row?.bseCode,
      visibleType: ["portfolioTradeBook"],
    },
    {
      ...extraColumnsField("Transaction Type", "transactionType", 150, 1),
      valueFormatter: (params) => handleCapitalize(params?.value),
      visibleType: [
        "portfolioTradeBook",
        "portfolioPrivateEquityList",
        "portfolioFDList",
        "portfolioFDPayoutList",
        "portfolioAIFList",
      ],
    },
    {
      ...extraColumnsField("Quantity", "quantity", 150, 1),
      align: "right",
      headerAlign: "right",
      valueGetter: (params) => params?.row?.quantity || "0",
      valueFormatter: (params: any) => moneyCommaSeparatorPoint(params?.value),
      visibleType: [
        "portfolioTradeBook",
        "portfolioPrivateEquityList",
        "portfolioFDList",
        "portfolioFDPayoutList",
        "portfolioAIFList",
      ],
    },
    {
      ...extraColumnsField("Price", "price", 150, 1),
      align: "right",
      headerAlign: "right",
      valueGetter: (params) => params?.row?.price || "0",
      valueFormatter: (params) => moneyCommaSeparator(params.value),
      visibleType: [
        "portfolioTradeBook",
        "portfolioPrivateEquityList",
        "portfolioFDList",
        "portfolioFDPayoutList",
        "portfolioAIFList",
      ],
    },
    {
      ...extraColumnsField("Brokerage", "brokerage", 150, 1),
      align: "right",
      headerAlign: "right",
      valueGetter: (params) => params.row.brokerage || "0",
      valueFormatter: (params) => moneyCommaSeparator(params.value),
      visibleType: [
        "portfolioTradeBook",
        "portfolioPrivateEquityList",
        "portfolioFDList",
        "portfolioAIFList",
      ],
    },
    {
      ...extraColumnsField(
        "Amount With Brokerage",
        "amountWithBrokerage",
        190,
        1,
      ),
      align: "right",
      headerAlign: "right",
      valueGetter: (params) => params.row.amountWithBrokerage || "-",
      valueFormatter: (params) => moneyCommaSeparator(params.value),
      visibleType: [
        "portfolioTradeBook",
        "portfolioPrivateEquityList",
        "portfolioFDList",
        "portfolioAIFList",
      ],
    },
    {
      ...extraColumnsField("TDS", "tds", 100, 1),
      visibleType: ["portfolioFDList"],
      type: "number",
    },
    {
      ...extraColumnsField("STT", "stt", 150, 1),
      align: "right",
      headerAlign: "right",
      valueGetter: (params) => params.row.stt || "0",
      valueFormatter: (params) => moneyCommaSeparator(params.value),
      visibleType: [
        "portfolioTradeBook",
        "portfolioPrivateEquityList",
        "portfolioAIFList",
      ],
    },
    {
      ...extraColumnsField("GST", "gst", 150, 1),
      align: "right",
      headerAlign: "right",
      valueGetter: (params) => params.row.gst || "0",
      valueFormatter: (params) => moneyCommaSeparator(params.value),
      visibleType: [
        "portfolioTradeBook",
        "portfolioPrivateEquityList",
        "portfolioAIFList",
      ],
    },
    {
      ...extraColumnsField("Stamp Charges", "stampCharges", 170, 1),
      align: "right",
      headerAlign: "right",
      valueGetter: (params) => params.row.stampCharges || "0",
      valueFormatter: (params) => moneyCommaSeparator(params.value),
      visibleType: [
        "portfolioTradeBook",
        "portfolioPrivateEquityList",
        "portfolioAIFList",
      ],
    },
    {
      ...extraColumnsField("Transaction Charges", "transactionCharges", 170, 1),
      align: "right",
      headerAlign: "right",
      valueGetter: (params) => params.row.transactionCharges || "0",
      valueFormatter: (params) => moneyCommaSeparator(params.value),
      visibleType: [
        "portfolioTradeBook",
        "portfolioPrivateEquityList",
        "portfolioAIFList",
      ],
    },
    {
      ...extraColumnsField("SEBI Turnover Fees", "sebiTurnoverFees", 170, 1),
      align: "right",
      headerAlign: "right",
      valueGetter: (params) => params.row.sebiTurnoverFees || "0",
      valueFormatter: (params) => moneyCommaSeparator(params.value),
      visibleType: ["portfolioTradeBook"],
    },
    {
      ...extraColumnsField("Other Charges", "otherCharges", 170, 1),
      align: "right",
      headerAlign: "right",
      valueGetter: (params) => params.row.otherCharges || "0",
      valueFormatter: (params) => moneyCommaSeparator(params.value),
      visibleType: [
        "portfolioTradeBook",
        "portfolioPrivateEquityList",
        "portfolioAIFList",
      ],
    },
    {
      ...extraColumnsField("Bill Amount", "billAmount", 150, 1),
      align: "right",
      headerAlign: "right",
      valueGetter: (params) => params.row.billAmount || "0",
      valueFormatter: (params) => moneyCommaSeparator(params.value),
      visibleType: [
        "portfolioTradeBook",
        "portfolioPrivateEquityList",
        "portfolioFDList",
        "portfolioAIFList",
      ],
    },
    {
      ...extraColumnsField("ISIN Code", "isinCode", 150, 1),
      align: "right",
      headerAlign: "right",
      visibleType: [
        "portfolioTradeBook",
        "portfolioPrivateEquityList",
        "portfolioAIFList",
      ],
    },
    {
      ...extraColumnsField("Contract Note Number", "contractNoteNum", 190, 1),
      valueGetter: (params) => params.row.contractNoteNum || "-",
      valueFormatter: (params) => params.value,
      visibleType: ["portfolioTradeBook"],
    },
    {
      ...extraColumnsField("Broker Name", "brokerName", 150, 1),
      visibleType: ["portfolioTradeBook"],
    },
  ];

  return (
    <>
      <GKDataGrid
        density="compact"
        checkboxSelection
        onRowSelectionModelChange={(params) => {
          setSelectedIds(params);
        }}
        rowSelectionModel={selectedIds}
        loading={isFetching}
        columns={transactionColumns.filter((column) => {
          return column.visibleType?.includes(name);
        })}
        rows={data?.data?.data || []}
        onRowClick={(row) => {
          if (row?.row?.transactionType !== "BUY_BACK") {
            navigate(`${routeNavigate?.editNavigate}/${row?.id}`, {
              state: {
                transactionType: routeNavigate?.transactionType || "",
                assetType: routeNavigate?.assetType || "",
                id: row?.row?.portfolio,
              },
            });
          }
        }}
        initialState={{
          columns: {
            columnVisibilityModel: {
              stt: false,
              gst: false,
              stampCharges: false,
              otherCharges: false,
              transactionCharges: false,
              sebiTurnoverFees: false,
            },
          },
        }}
        totalCount={data?.data?.totalCount || 0}
        exportFile={{
          path: exportRoute,
          exportName: "portfolioTradeBook",
          paramsValue: {
            key: requestParams?.key,
            value: requestParams?.value,
          },
        }}
        name={name}
        headerComponent={
          userData?.userRole !== UserRoleTypes.client && (
            <Grid2 container gap={1} justifyContent={"end"}>
              {selectedIds?.length !== 0 && (
                <GKButton
                  variant="outlined"
                  size="medium"
                  color="error"
                  onClick={() => {
                    setDeleteConfirmation(true);
                  }}
                >
                  Bulk Delete
                </GKButton>
              )}
              {selectedIds?.length !== 0 && (
                <GKButton
                  variant="outlined"
                  size="medium"
                  color="primary"
                  onClick={() => {
                    setSelectedIds([]);
                  }}
                >
                  Cancel
                </GKButton>
              )}
              {selectedIds?.length === 0 &&
                brokerBtn &&
                props?.portfolioDetailData?.broker !== "OTHER" && (
                  <GKButton
                    variant="contained"
                    size="medium"
                    onClick={() => {
                      setOpenImportBrokerModal(true);
                    }}
                    startIcon={<PiArrowSquareDownRightDuotone size={16} />}
                  >
                    Import from broker
                  </GKButton>
                )}
              {name === "portfolioFDList" && selectedIds?.length === 0 && (
                <GKButton
                  variant="outlined"
                  size="medium"
                  onClick={() => {
                    navigate(
                      `/app/holdings/portfolio/${id}/fixed-deposit-interest`,
                    );
                  }}
                  startIcon={<CgMathPlus size={14} />}
                >
                  Interest Payout
                </GKButton>
              )}
              {selectedIds?.length === 0 && (
                <GKButton
                  onClick={() => {
                    navigate(routeNavigate.navigate, {
                      state: {
                        transactionType: routeNavigate?.transactionType || "",
                        assetType: routeNavigate?.assetType || "",
                        id: portfolioId,
                      },
                    });
                  }}
                  variant="contained"
                  startIcon={<CgMathPlus size={14} />}
                >
                  Add Trade
                </GKButton>
              )}
            </Grid2>
          )
        }
        tableName={tableName}
      />

      <Show.When isTrue={openImportBrokerModal}>
        <ImportBrokerModal
          open={openImportBrokerModal}
          setOpen={setOpenImportBrokerModal}
          refetch={refetch}
          type={portfolioDetailData?.broker}
          subType={"EQUITY"}
        />
      </Show.When>

      {deleteConfirmation && (
        <ConfirmDeleteModal
          open={deleteConfirmation}
          setOpen={setDeleteConfirmation}
          onDelete={() => handleMultiDelete({ ids: selectedIds })}
          title={"Bulk Statement"}
          isDelete
          deleteMessage={`Are you sure that you want to delete the selected stocks & once deleted it cannot be reverted?`}
        />
      )}
    </>
  );
};

export default PortfolioSummaryTradeBookTab;
