import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import { useAppContext } from "AppContext";
import {
  GKButton,
  GKDatePicker,
  GKModal,
  GKSearchSelect,
  GKTextField,
  Grid2,
  TextField,
  Typography,
} from "components";
import dayjs from "dayjs";
import { useMutation } from "hooks/useQueries";
import { Controller, useForm } from "react-hook-form";
import axiosInstance from "utils/axiosInstance";
import { GKModalProps } from "utils/commonInterface";
import { CASH_TRANSACTION_TYPES } from "utils/constants";
import { refetchQuery } from "utils/helpers";
import { QueryKeys } from "utils/queryKey";
import { handleReturnAutocompletePlaceholder } from "utils/StringFunctions";
import { number, object, string } from "yup";

interface Props {
  cashEntryData: any;
  portfolioId?: string;
}

type FormValues = {
  portfolio?: number;
  date?: string;
  transactionType?: string;
  billAmount?: number;
  remarks?: string;
};

const CashTransactionModal = ({
  open,
  setOpen,
  cashEntryData,
  portfolioId,
}: Props & GKModalProps) => {
  const {
    state: { portfolios },
  } = useAppContext();

  const cashEntry = cashEntryData?.id;

  const { mutate: handleAddMutation, isLoading } = useMutation({
    mutationFn: (data: any) => axiosInstance.post(`/transaction_cash/`, data),
    onSuccess: () => {
      refetchQuery(
        portfolioId
          ? QueryKeys.PORTFOLIO_CASH_TRANSACTIONS_LIST
          : QueryKeys.CASH_TRANSACTIONS_LIST,
      );
      setOpen(false);
    },
  });

  const { mutate: handleDeleteMutation, isLoading: deleteLoading } =
    useMutation({
      mutationFn: () => axiosInstance.delete(`/transaction_cash/${cashEntry}/`),
      onSuccess: () => {
        refetchQuery(
          portfolioId
            ? QueryKeys.PORTFOLIO_CASH_TRANSACTIONS_LIST
            : QueryKeys.CASH_TRANSACTIONS_LIST,
        );

        setOpen(false);
      },
    });

  const { mutate: handleUpdateMutation, isLoading: updateCashEntryLoading } =
    useMutation({
      mutationFn: (data: any) =>
        axiosInstance.put(`/transaction_cash/${cashEntry}/`, data),
      onSuccess: () => {
        refetchQuery(
          portfolioId
            ? QueryKeys.PORTFOLIO_CASH_TRANSACTIONS_LIST
            : QueryKeys.CASH_TRANSACTIONS_LIST,
        );

        setOpen(false);
      },
    });

  const validationSchema = object().shape({
    portfolio: number().required("Please select portfolio"),
    transactionType: string().required("Please select transaction type"),
    date: string()
      .required("Please select transaction date")
      .test("is-valid-date", "Invalid date", (value) => {
        return dayjs(value).isValid();
      })
      .test(
        "is-valid-date",
        "You are not allowed to add a transaction for this date.",
        (value) => dayjs(value).year() >= 1980,
      ),
    billAmount: number().required("Please enter amount"),
  });

  const form = useForm<FormValues>({
    defaultValues: {
      portfolio: +portfolioId || cashEntryData?.portfolio || undefined,
      date: cashEntryData?.date || "",
      transactionType: cashEntryData?.transactionType || "DEPOSIT",
      billAmount: cashEntryData?.billAmount || null,
      remarks: cashEntryData?.remarks || "",
    },
    resolver: yupResolver(validationSchema),
  });

  return (
    <GKModal
      open={open}
      setOpen={setOpen}
      modalTitle={cashEntry ? "Update Cash Entry" : "Add Cash Entry"}
      footer={
        <Grid2
          display={"flex"}
          justifyContent={"flex-end"}
          alignItems={"center"}
          gap={1}
        >
          <GKButton
            color="dark"
            variant="outlined"
            size="medium"
            form="cash-form"
            onClick={() => {
              setOpen(false);
            }}
          >
            Close
          </GKButton>
          <LoadingButton
            variant="contained"
            size="medium"
            type="submit"
            form="cash-form"
            loading={isLoading || updateCashEntryLoading}
          >
            {cashEntry ? "Update" : "Save"}
          </LoadingButton>
        </Grid2>
      }
      titleComponent={
        cashEntry && (
          <LoadingButton
            loading={deleteLoading}
            color="error"
            variant="outlined"
            onClick={handleDeleteMutation}
          >
            Delete
          </LoadingButton>
        )
      }
    >
      <form
        id="cash-form"
        onSubmit={form.handleSubmit((values) => {
          cashEntry ? handleUpdateMutation(values) : handleAddMutation(values);
        })}
      >
        <Grid2 container spacing={1}>
          <Grid2 size={12}>
            <Typography mb={1}>Select Transaction Type</Typography>

            <Controller
              name="transactionType"
              control={form.control}
              render={({ field }) => {
                return (
                  <>
                    {CASH_TRANSACTION_TYPES.map((dataItem) => (
                      <GKButton
                        key={dataItem?.name}
                        sx={{
                          marginRight: 1,
                        }}
                        variant={
                          field.value === dataItem?.value
                            ? "contained"
                            : "outlined"
                        }
                        color={
                          field.value === dataItem?.value ? "primary" : "dark"
                        }
                        onClick={() => {
                          form.setValue("transactionType", dataItem?.value);
                        }}
                      >
                        {dataItem?.name}
                      </GKButton>
                    ))}
                  </>
                );
              }}
            />
          </Grid2>
          <Grid2 size={6}>
            <Controller
              name="portfolio"
              control={form.control}
              render={({ field, fieldState: { error } }) => (
                <GKSearchSelect
                  {...field}
                  disableClearable
                  inputLabel="Select Portfolio"
                  requiredField
                  disabled={!!portfolioId}
                  renderOption={(props, option) => {
                    return (
                      <li
                        {...props}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }}
                      >
                        <Typography fontWeight={600}>
                          {option.clientName}
                        </Typography>
                        <Typography>{option.name}</Typography>
                      </li>
                    );
                  }}
                  options={portfolios || []}
                  onChange={(e, val) => {
                    field.onChange(val?.value);
                  }}
                  value={
                    portfolios?.find((data: any) => data?.id === field.value)
                      ?.name
                  }
                  getOptionLabel={(option) => option.name || option}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="portfolio"
                      placeholder={handleReturnAutocompletePlaceholder({
                        placeholder: "Select Portfolio",
                        value: field.value,
                      })}
                      error={!!error}
                      helperText={error?.message}
                    />
                  )}
                />
              )}
            />
          </Grid2>
          <Grid2 size={6}>
            <Controller
              name="date"
              control={form.control}
              render={({ field, fieldState: { error } }) => (
                <GKDatePicker
                  {...field}
                  inputLabel="Date"
                  name="date"
                  value={dayjs(field.value)}
                  onChange={(value) => {
                    field.onChange(dayjs(value).format("YYYY-MM-DD"));
                  }}
                  disableFuture
                  maxDate={dayjs()}
                  requiredField
                  slotProps={{
                    textField: {
                      error: !!error,
                      helperText: error?.message,
                    },
                  }}
                />
              )}
            />
          </Grid2>
          <Grid2 size={6}>
            <Controller
              name="billAmount"
              control={form.control}
              render={({ field, fieldState: { error } }) => (
                <GKTextField
                  {...field}
                  type="number"
                  name="billAmount"
                  requiredField
                  inputLabel="Amount"
                  onChange={(e: any) =>
                    field.onChange(e.target.valueAsNumber || null)
                  }
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
          </Grid2>
          <Grid2 size={6}>
            <Controller
              name="remarks"
              control={form.control}
              render={({ field }) => (
                <GKTextField {...field} inputLabel="Remarks" />
              )}
            />
          </Grid2>
        </Grid2>
      </form>
    </GKModal>
  );
};

export default CashTransactionModal;
