import { Grid2, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import DashboardLayout from "layouts/DashboardLayout";
import { FiLock } from "react-icons/fi";
import { ThemeContainer } from "utils/Theme";

const GKNoAccess = () => {
  const { theme } = ThemeContainer();

  return (
    <DashboardLayout title="Access Denied">
      <Grid2
        container
        height={"100%"}
        width={"100%"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        flexDirection={"column"}
      >
        <FiLock size={40} color={theme.palette.primary.main} />
        <Typography fontSize={20} fontWeight={600}>
          Access Denied
        </Typography>
        <Typography fontSize={14} color={grey[500]} fontWeight={600}>
          Sorry you are not allowed to perform this action.
        </Typography>
      </Grid2>
    </DashboardLayout>
  );
};

export default GKNoAccess;
