import { LoadingButton } from "@mui/lab";
import {
  AnimationComponent,
  ConfirmDeleteModal,
  Divider,
  Grid2,
  Permission,
  Show,
  Tab,
  Tabs,
} from "components";
import { useMutation } from "hooks/useQueries";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import axiosInstance from "utils/axiosInstance";
import { QueryKeys } from "utils/queryKey";
import BasketTab from "./BasketTab";
import ClientTab from "./ClientTab";

interface Props {
  tab: { partnerDetailsPageTab: number };
  dispatch: any;
  id: string;
}

const DetailsTabView = (props: Props) => {
  const { tab: tabValue, dispatch, id } = props;

  const navigate = useNavigate();

  const [confirmationModal, setConfirmationModal] = useState(false);

  const { mutate, isLoading } = useMutation({
    mutationFn: ({ partnerId }: { partnerId: any }) =>
      axiosInstance.delete(`/partner/${partnerId}/`),
    onSuccess: () => {
      navigate(-1);
    },
  });

  const tabsConfig = [
    {
      label: "Client",
      component: <ClientTab id={id} />,
    },
    {
      label: "Baskets",
      component: <BasketTab id={id} />,
    },
    // {
    //   label: "Fee plan",
    //   component: <FeePlanTab />,
    // },
    // {
    //   label: "Invoices",
    //   component: (
    //     <InvoiceTab name="partnerInvoice" tableName="partner_invoice" />
    //   ),
    // },
    // {
    //   label: "Commissions",
    //   component: <></>,
    // },
    {
      label: "Permissions",
      component: (
        <Permission
          headerTitle="Partner Permission"
          route={`/partner/${id}/permissions/`}
          queryKey={QueryKeys.PARTNER_PERMISSION}
          permissionType="PARTNER"
        />
      ),
    },
  ];

  const handleTabChange = (event: React.SyntheticEvent, value: number) => {
    dispatch({
      type: "TAB",
      data: { ...tabValue, partnerDetailsPageTab: value },
    });
  };

  return (
    <Grid2 container spacing={2}>
      <Grid2 size={10}>
        <Tabs
          value={tabValue?.partnerDetailsPageTab}
          onChange={handleTabChange}
          indicatorColor="primary"
        >
          {tabsConfig.map((tab, index) => (
            <Tab key={index} label={tab.label} />
          ))}
        </Tabs>
      </Grid2>
      <Show.When isTrue={tabValue?.partnerDetailsPageTab === 2}>
        <Grid2
          size={2}
          display={"flex"}
          justifyContent={"flex-end"}
          alignItems={"center"}
        >
          <LoadingButton
            variant="outlined"
            color="error"
            size="medium"
            loading={isLoading}
            onClick={() => setConfirmationModal(true)}
          >
            Delete Partner
          </LoadingButton>
          <Divider />
        </Grid2>
      </Show.When>
      <Grid2 size={12}>
        {tabsConfig.map((tab, index) => (
          <Show.When
            key={index}
            isTrue={tabValue?.partnerDetailsPageTab === index}
          >
            <AnimationComponent>{tab.component}</AnimationComponent>
          </Show.When>
        ))}
      </Grid2>

      <Show.When isTrue={confirmationModal}>
        <ConfirmDeleteModal
          onDelete={() => mutate({ partnerId: id })}
          title="Partner"
          isDelete
          open={confirmationModal}
          setOpen={setConfirmationModal}
        />
      </Show.When>
    </Grid2>
  );
};

export default DetailsTabView;
