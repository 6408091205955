import DashboardLayout from "layouts/DashboardLayout";
import useDocumentTitle from "utils/useDocumentTitle";

const CompanyMasterSetting = () => {
  useDocumentTitle("Company Master Setting");

  return (
    <DashboardLayout title="Company Master Setting">
      <div>Company Master Setting</div>
    </DashboardLayout>
  );
};

export default CompanyMasterSetting;
