import {
  CgMathPlus,
  CircularProgress,
  GKButton,
  GKClientDataGrid,
  Grid2,
  Show,
} from "components";
import { useFetchQuery, useMutation } from "hooks/useQueries";
import {
  HoldingDataInterface,
  PortfolioDetailsInterface,
} from "interfaces/Portfolio";
import { useState } from "react";
import { useParams } from "react-router-dom";
import axiosInstance from "utils/axiosInstance";
import { extraColumnsField } from "utils/helpers";
import { QueryKeys } from "utils/queryKey";
import useDocumentTitle from "utils/useDocumentTitle";
import PortfolioHoldingPreviewSideModal from "../UtilityPages/PortfolioHoldingPreviewSideModal";

interface Props {
  holdingSummaryDetails?: HoldingDataInterface;
  portfolioDetailData?: PortfolioDetailsInterface;
}

const ModelPortfolioTab = (props: Props) => {
  useDocumentTitle("Portfolio Baskets");

  const { id } = useParams<{ id: string }>();

  const [modelPortfolios, setModelPortfolios] = useState<any>([]);

  const [selectedId, setSelectedId] = useState(null);

  const [holdingPreviewModal, setHoldingPreviewModal] = useState(false);

  const { isFetching: modelPortfolioListLoading, refetch } = useFetchQuery({
    key: [QueryKeys.MODEL_PORTFOLIO_LIST, id],
    route: `/model_portfolio/simple_list/?portfolio=${id}`,
    success: (data: any) => {
      setModelPortfolios(data?.data);
    },
  });

  const { isLoading: mapLoading, mutate: mapMutation } = useMutation({
    mutationFn: (portfolioId: any) =>
      axiosInstance.post(`/model_portfolio/${portfolioId}/map/`, {
        portfolio: [Number(id)],
      }),
    onSuccess: () => {
      refetch();
    },
  });

  const { isLoading: unmapLoading, mutate: unmapMutation } = useMutation({
    mutationFn: (portfolioId: any) =>
      axiosInstance.post(`/model_portfolio/${portfolioId}/unmap/`, {
        portfolio: [Number(id)],
      }),
    onSuccess: () => {
      refetch();
    },
  });

  return (
    <Grid2 container spacing={2}>
      <Grid2 size={12}>
        <GKClientDataGrid
          name="modelPortfolio"
          loading={modelPortfolioListLoading}
          headerComponent={
            <GKButton
              variant="contained"
              onClick={() => setHoldingPreviewModal(true)}
              startIcon={<CgMathPlus size={18} />}
            >
              Portfolio Preview
            </GKButton>
          }
          columns={[
            {
              ...extraColumnsField("Equity Basket", "name", 0, 1),
            },
            {
              ...extraColumnsField("Status", "mappingStatus", 0, 1),
              headerAlign: "right",
              align: "right",
              renderCell: (params) => {
                return (
                  <GKButton
                    size="small"
                    variant={
                      params.row.mappingStatus === "MAPPED"
                        ? "contained"
                        : "outlined"
                    }
                    onClick={() => {
                      setSelectedId(params?.row?.id);
                      params?.row?.mappingStatus?.toLowerCase() === "unmapped"
                        ? mapMutation([params?.row?.id])
                        : unmapMutation([params?.row?.id]);
                    }}
                  >
                    {selectedId === params?.row?.id &&
                    (mapLoading || unmapLoading) ? (
                      <CircularProgress size={20} />
                    ) : (
                      params.row.mappingStatus
                    )}
                  </GKButton>
                );
              },
            },
            // {
            //   field: "action",
            //   disableColumnMenu: true,
            //   sortable: true,
            //   renderCell: (params) => {
            //     return selectedId === params?.row?.id &&
            //       (mapLoading || unmapLoading) ? (
            //       <CircularProgress size={20} />
            //     ) : (
            //       <Switch
            //         checked={
            //           params?.row?.mappingStatus?.toLowerCase() === "mapped"
            //         }
            //         onChange={() => {
            //           setSelectedId(params?.row?.id);
            //           // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            //           params?.row?.mappingStatus?.toLowerCase() === "unmapped"
            //             ? mapMutation([params?.row?.id])
            //             : unmapMutation([params?.row?.id]);
            //         }}
            //       />
            //     );
            //   },
            // },
          ]}
          rows={modelPortfolios || []}
        />
      </Grid2>

      <Show.When isTrue={holdingPreviewModal}>
        <PortfolioHoldingPreviewSideModal
          open={holdingPreviewModal}
          setOpen={setHoldingPreviewModal}
          holdingSummaryDetails={props?.holdingSummaryDetails}
          portfolioDetailData={props?.portfolioDetailData}
        />
      </Show.When>
    </Grid2>
  );
};

export default ModelPortfolioTab;
