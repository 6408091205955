import { Typography } from "@mui/material";
import { ThemeContainer } from "utils/Theme";
import CKEditor from "./CKEditor";

interface Props {
  onChange?: any;
  value?: string;
  inputLabel?: string;
  helperText?: string;
  requiredField?: boolean;
  disabled?: boolean;
  error?: boolean;
  onReady?: any;
  toolbar?: string[];
  placeholder?: string;
  charLimit?: number;
}

const GKEditor = (props: Props) => {
  const {
    onChange,
    value,
    inputLabel,
    helperText,
    requiredField,
    error,
    onReady,
    toolbar,
    placeholder,
    disabled,
    charLimit,
  } = props;

  const { theme } = ThemeContainer();

  return (
    <>
      {inputLabel && (
        <Typography
          style={{
            color: theme.palette.dark.main,
            fontSize: "14px",
            fontFamily: "Inter !important",
          }}
        >
          {inputLabel}
          {requiredField && (
            <span
              style={{
                color: theme.palette.error.main,
              }}
            >
              *
            </span>
          )}
        </Typography>
      )}
      <div
        style={{
          border: `1px solid ${theme.palette.border.main}`,
          borderColor: error
            ? theme.palette.error.main
            : theme.palette.border.main,
          borderRadius: "8px",
          marginTop: inputLabel ? "4px" : "0px",
        }}
      >
        <CKEditor
          value={value}
          onChange={onChange}
          onReady={onReady}
          toolbar={toolbar}
          placeholder={placeholder}
          disabled={disabled}
          charLimit={charLimit}
        />
      </div>
      {!!helperText && (
        <Typography
          color={theme.palette.error.main}
          fontSize={10.5}
          sx={{ marginTop: "0 !important" }}
        >
          {helperText}
        </Typography>
      )}
    </>
  );
};

export default GKEditor;
