import { createSlice } from "@reduxjs/toolkit";
import {
  CommonFilterState,
  initialCommonFilterState,
} from "interfaces/CommonFilter";
import { PartnerListInterface } from "interfaces/PrivateRouteInterface";
import { OnboardingInterface } from "interfaces/SettingsInterface";
import { columnsPersist } from "utils/columnsHide";

interface TabInterface {
  familyERMPageTabs: number;
  companyInfoTabs: number;
  defaultPermissionTabs: number;
  modelPortfolioTabs: number;
  employeeERMTabs: number;
  portfolioSummaryTabs: number;
  portfolioUtilityTabs: number;
  portfolioReportTabs: number;
  settingsPageTabs: number;
  investorBasketTabs: number;
  tabValueOfCRM: number;
  tabValueOfRevenue: number;
  tabOfPortfolioRevenue: number;
  tabValueDashboard: number;
  tabValueOfImport: number;
  tabValueOfReports: number;
  editFeePlanTab: number;
  complianceTabs: number;
  clientERMPageTabs: number;
  leadDetailsTabs: number;
  portfolioExtendedViewTabs: number;
  portfolioTabs: number;
  portfolioAdminViewTabs: number;
  assetDetailsTabs: number;
  portfolioProfileTab: number;
  organisationTab: number;
  partnerDetailsPageTab: number;
}

interface AppState {
  userData: any;
  userLoading: boolean;
  isFirstLogin: boolean;
  sidebarOpen: boolean;
  documentTitle: string;
  tab: TabInterface;
  noteFilter: number[];
  adviseRegisterFilter: number[];
  columnsPersist: any;
  portfolios: any[];
  companies: any[];
  portfolioGroupList: any[];
  clientList: any[];
  employeeList: any[];
  familyList: any[];
  commonFilter: CommonFilterState;
  countryList: any[];
  stateList: any[];
  cityList: any[];
  selectedCountry: number;
  selectedState: number;
  settingTab: {
    type: string;
    name: string;
  };
  partnerList: PartnerListInterface[];
  onBoardflowData: OnboardingInterface;
}

const initialState: AppState = {
  userData: {},
  userLoading: true,
  isFirstLogin: false,
  sidebarOpen: JSON.parse(localStorage.getItem("sidebar")),
  documentTitle: "Dashboard",
  tab: {
    familyERMPageTabs: 0,
    companyInfoTabs: 0,
    leadDetailsTabs: 0,
    defaultPermissionTabs: 0,
    modelPortfolioTabs: 0,
    employeeERMTabs: 0,
    portfolioSummaryTabs: 0,
    portfolioUtilityTabs: 0,
    portfolioReportTabs: 0,
    settingsPageTabs: 0,
    tabValueOfCRM: 0,
    tabValueOfRevenue: 0,
    tabOfPortfolioRevenue: 0,
    tabValueDashboard: 0,
    tabValueOfImport: 0,
    tabValueOfReports: 0,
    editFeePlanTab: 0,
    complianceTabs: 0,
    clientERMPageTabs: 0,
    investorBasketTabs: 0,
    portfolioTabs: 0,
    portfolioExtendedViewTabs: 1,
    portfolioAdminViewTabs: 0,
    assetDetailsTabs: 0,
    portfolioProfileTab: 0,
    organisationTab: 0,
    partnerDetailsPageTab: 0,
  },
  noteFilter: [],
  adviseRegisterFilter: [],
  columnsPersist: JSON.parse(localStorage.getItem("columns")) || columnsPersist,
  portfolios: [],
  companies: [],
  portfolioGroupList: [],
  clientList: [],
  employeeList: [],
  countryList: [],
  stateList: [],
  cityList: [],
  familyList: [],
  selectedCountry: null,
  selectedState: null,
  commonFilter: initialCommonFilterState,
  settingTab: {
    type: "USER_PROFILE",
    name: "User Profile",
  },
  partnerList: [],
  onBoardflowData: null,
};

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    SIDE_BAR_OPEN: (state, action) => {
      state.sidebarOpen = action.payload;
      localStorage.setItem("sidebar", JSON.stringify(action.payload));
    },
    SETTING_TAB: (state, action) => {
      state.settingTab = action.payload;
    },
    DOCUMENT_TITLE: (state, action) => {
      state.documentTitle = action.payload;
    },
    COLUMNS_PERSIST: (state, action) => {
      state.columnsPersist = action.payload;
    },
    USER_LOADING: (state, action) => {
      state.userLoading = action.payload;
    },
    IS_FIRST_LOGIN: (state, action) => {
      state.isFirstLogin = action.payload;
    },
    NOTE_FILTER: (state, action) => {
      state.noteFilter = action.payload;
    },
    ADVICE_REGISTER_FILTER: (state, action) => {
      state.adviseRegisterFilter = action.payload;
    },
  },
});

export const {
  SIDE_BAR_OPEN,
  SETTING_TAB,
  DOCUMENT_TITLE,
  COLUMNS_PERSIST,
  USER_LOADING,
  IS_FIRST_LOGIN,
  NOTE_FILTER,
  ADVICE_REGISTER_FILTER,
} = appSlice.actions;

export default appSlice.reducer;
