import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation } from "hooks/useQueries";
import { useForm } from "react-hook-form";
import axiosInstance from "utils/axiosInstance";
import { refetchQuery } from "utils/helpers";
import { QueryKeys } from "utils/queryKey";
import { addEmployeeSchema } from "utils/validationSchema";

type FormValue = {
  user?: {
    personalDetails?: {
      email?: string;
      name?: string;
      dateOfBirth?: string;
      aadhar?: string;
      emergencyContact?: string;
      emergencyContactNum?: string;
    };
    phone?: string;
    countryCode?: string;
  };
  salary?: 0;
  position?: string;
};

const useAddEmployee = ({ setOpen }: any) => {
  /** ===================================================================== */
  const { mutate: handleCreateEmployee, isLoading: isSubmitting } = useMutation(
    {
      mutationFn: (data: any) => axiosInstance.post(`/employees/`, data),
      onSuccess: () => {
        setOpen(false);
        refetchQuery(QueryKeys.EMPLOYEE_LIST);
      },
    },
  );

  /** ===================================================================== */

  const form = useForm<FormValue>({
    defaultValues: {
      user: {
        personalDetails: {
          email: "",
          name: "",
          dateOfBirth: "",
          aadhar: "",
          emergencyContact: "",
          emergencyContactNum: "",
        },
        phone: "",
        countryCode: "+91",
      },
      salary: 0,
      position: "",
    },
    resolver: yupResolver(addEmployeeSchema),
  });

  const onSubmit = form.handleSubmit((values) => handleCreateEmployee(values));

  return { isSubmitting, form, onSubmit };
};

export default useAddEmployee;
