/* eslint-disable no-prototype-builtins */
import { LoadingButton } from "@mui/lab";
import { grey } from "@mui/material/colors";
import {
  AiOutlineSignature,
  Card,
  Divider,
  GKButton,
  Grid2,
  Show,
  StatusField,
  Typography,
} from "components";
import { useMutation } from "hooks/useQueries";
import { useFormContext } from "react-hook-form";
import axiosInstance from "utils/axiosInstance";
import { refetchQuery } from "utils/helpers";
import { ThemeContainer } from "utils/Theme";
import { useInvestorContext } from "./InvestorContext";

declare global {
  interface Window {
    Digio: any;
  }
}

interface Props {
  handleNext: () => void;
  basketId: string;
}

const Agreementtab = (props: Props) => {
  const { theme } = ThemeContainer();

  const form = useFormContext();

  const {
    state: { token },
  } = useInvestorContext();

  const isExistAgreement = form.getValues(
    "clientObject.agreementDetails.agreementStatus.id",
  );

  const options = {
    environment: "production",
    callback: (response: any) => {
      if (response.hasOwnProperty("error_code")) {
        console.log(response);
      } else {
        props.handleNext();
        refetchQuery("GET_CLIENT_FOR_CREATION");
      }
    },
    logo: "https://www.mylogourl.com/image.jpeg",
    theme: {
      primaryColor: theme.palette.primary.main,
      secondaryColor: theme.palette.secondary.main,
    },
  };

  const digio = window.Digio && new window.Digio(options);

  const { mutate: handleAgreement } = useMutation({
    mutationKey: ["CREATE_AGREEMENT"],
    mutationFn: ({ basketId, clientId }: { basketId: any; clientId: any }) =>
      axiosInstance.post(
        `/client-basket/${clientId}/onboard-agreement/?manual=false&basket_id=${basketId}`,
        {},
        {
          headers: {
            Authorization: `Token ${token}`,
            "x-gridkey-user-role": "client",
          },
        },
      ),
    onSuccess: (response) => {
      digio.init();
      digio.submit(
        response?.data?.data?.documentId,
        response?.data?.data?.identifier,
      );
    },
  });

  return (
    <Grid2 container spacing={2}>
      <Grid2 size={12}>
        <Typography
          fontWeight={600}
          pt={2}
          sx={{ fontSize: "clamp(1.125rem, 0.6563rem + 1.5vw, 1.5rem)" }}
        >
          Agreement
        </Typography>
      </Grid2>
      <Show.When isTrue={!isExistAgreement}>
        <Grid2
          size={{ xs: 12, md: 4 }}
          sx={{
            m: {
              xs: "",
              md: "auto",
            },
          }}
          display={"flex"}
          justifyContent={"center"}
          flexDirection={"column"}
          border={"1px solid #E3E8EF"}
          borderRadius={2}
        >
          <Grid2
            size={12}
            container
            spacing={1}
            sx={{
              p: {
                xs: 2,
                md: 5,
              },
            }}
            bgcolor={"#F8FAFC"}
          >
            <Grid2 size={12} display={"flex"} justifyContent={"center"}>
              <div
                style={{
                  borderRadius: "16px",
                  border: "1px solid #E3E8EF",
                  background: theme.palette.light.main,
                  padding: "16px",
                }}
              >
                <AiOutlineSignature
                  size={30}
                  color={theme.palette.success.main}
                />
              </div>
            </Grid2>
            <Grid2 size={12} mt={2}>
              <Typography
                fontWeight={600}
                textAlign={"center"}
                sx={{ fontSize: "clamp(1.125rem, 0.8125rem + 1vw, 1.375rem)" }}
              >
                Sign your Agreement
              </Typography>
            </Grid2>
            <Grid2 size={12}>
              <Typography
                fontSize={16}
                fontWeight={500}
                textAlign={"center"}
                color="#9AA4B2"
              >
                On Clicking <span style={{ color: "#0D121C" }}>I Agree</span>,
                and you&apos;ll be redirected to Digio to complete the process.
              </Typography>
            </Grid2>
            <Grid2 size={12} mt={1}>
              <GKButton
                variant="contained"
                fullWidth
                onClick={() =>
                  handleAgreement({
                    basketId: props?.basketId,
                    clientId: form.getValues("clientObject.id"),
                  })
                }
              >
                i Agree
              </GKButton>
            </Grid2>
          </Grid2>
          <Grid2 size={12}>
            <Divider />
          </Grid2>
          <Grid2
            size={12}
            container
            spacing={1}
            sx={{
              p: {
                xs: 2,
                md: 5,
              },
            }}
          >
            <Grid2 size={12}>
              <Typography
                fontSize={16}
                fontWeight={500}
                color="#4B5565"
                display={"flex"}
              >
                Follow these Instructions:
              </Typography>
            </Grid2>
            <Grid2 size={12}>
              <Typography
                fontSize={14}
                fontWeight={500}
                color="#9AA4B2"
                display={"flex"}
              >
                1. Click on the &apos;Read Agreement&apos; button to go through
                the document carefully.
              </Typography>
            </Grid2>
            <Grid2 size={12}>
              <Typography
                fontSize={14}
                fontWeight={500}
                color="#9AA4B2"
                display={"flex"}
              >
                2. Once you have reviewed the agreement, click on &quot;I Agree
                &quot; to proceed.
              </Typography>
            </Grid2>
            <Grid2 size={12}>
              <Typography
                fontSize={14}
                fontWeight={500}
                color="#9AA4B2"
                display={"flex"}
              >
                3. You will need your Aadhaar number to complete the signing
                process.
              </Typography>
            </Grid2>
            <Grid2 size={12}>
              <Typography
                fontSize={14}
                fontWeight={500}
                color="#9AA4B2"
                display={"flex"}
              >
                4. Follow the on-screen instructions to verify and digitally
                sign the agreement.
              </Typography>
            </Grid2>
            <Grid2 size={12}>
              <Typography
                fontSize={14}
                fontWeight={500}
                color="#9AA4B2"
                display={"flex"}
              >
                Note: Once completed, your agreement will be successfully
                signed, recorded and intimated on your registered email.
              </Typography>
            </Grid2>
          </Grid2>
        </Grid2>
      </Show.When>

      <Show.When isTrue={isExistAgreement}>
        <Grid2 container spacing={1} m={"auto"}>
          <Grid2 size={6} m={"auto"}>
            <Card
              sx={{
                bgcolor: "#F8FAFC",
                border: "none",
                p: 1,
              }}
            >
              <Grid2 container spacing={1}>
                <Grid2
                  container
                  size={12}
                  bgcolor={theme.palette.light.main}
                  p={1}
                  borderRadius={1}
                >
                  <Grid2
                    size={12}
                    display={"flex"}
                    alignItems={"center"}
                    gap={2}
                  >
                    <Typography color={grey[600]} textTransform={"capitalize"}>
                      Agreement Status:
                    </Typography>
                    <StatusField
                      statusString={
                        form.getValues(
                          "clientObject.agreementDetails.agreementStatus.status",
                        ) || "Pending"
                      }
                    />
                  </Grid2>

                  <Grid2
                    size={12}
                    display={"flex"}
                    alignItems={"center"}
                    gap={2}
                  >
                    <Typography color={grey[600]} textTransform={"capitalize"}>
                      Signature Type:
                    </Typography>
                    <Typography
                      textTransform={"capitalize"}
                      textAlign={"left"}
                      fontWeight={600}
                    >
                      {form.getValues(
                        "clientObject.agreementDetails.signatureType",
                      ) || "-"}
                    </Typography>
                  </Grid2>

                  <Grid2
                    size={12}
                    display={"flex"}
                    alignItems={"center"}
                    gap={2}
                  >
                    <Typography color={grey[600]} textTransform={"capitalize"}>
                      Send Via :
                    </Typography>
                    <Typography
                      textTransform={"capitalize"}
                      textAlign={"left"}
                      fontWeight={600}
                    >
                      {form.getValues(
                        "clientObject.agreementDetails.sendOptions",
                      ) || "-"}
                    </Typography>
                  </Grid2>
                </Grid2>
                <Grid2 size={12} display={"flex"} justifyContent={"center"}>
                  <LoadingButton
                    variant="outlined"
                    href={
                      form.getValues(
                        "clientObject.agreementDetails.signedDocument",
                      ) || form.getValues("clientObject.agreementTemplate")
                    }
                    target="_blank"
                  >
                    View Document
                  </LoadingButton>
                </Grid2>
              </Grid2>
            </Card>
          </Grid2>
        </Grid2>
      </Show.When>
    </Grid2>
  );
};

export default Agreementtab;
