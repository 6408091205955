import { GKButton, GKModal, GKTextField, Grid2 } from "components";
import { useMutation } from "hooks/useQueries";
import { Controller, useForm } from "react-hook-form";
import { ThemeContainer } from "utils/Theme";
import axiosInstance from "utils/axiosInstance";
import { GKModalProps } from "utils/commonInterface";
import { refetchQuery } from "utils/helpers";

interface Props {
  editQuoteValue: any;
}

const QuoteModal = (props: Props & GKModalProps) => {
  const { open, setOpen, editQuoteValue } = props;

  const { theme } = ThemeContainer();

  const CHARACTER_LIMIT = 100;

  const { mutate: createQuote } = useMutation({
    mutationFn: (data: any) => axiosInstance.post("/report_quotes/", data),
    onSuccess: () => {
      refetchQuery("QUOTE_PMS_REPORT");
      setOpen(false);
    },
  });

  const { mutate: updateQuote } = useMutation({
    mutationFn: (data: any) =>
      axiosInstance.patch(
        `/report_quotes/${editQuoteValue?.id}/?role=advisor`,
        data,
      ),
    onSuccess: () => {
      refetchQuery("QUOTE_PMS_REPORT");
      setOpen(false);
    },
  });

  const form = useForm({
    defaultValues: { quote: editQuoteValue?.id ? editQuoteValue?.value : "" },
  });

  form.watch();

  return (
    <GKModal
      open={open}
      setOpen={setOpen}
      modalTitle={editQuoteValue?.id ? "Update Quote" : "Add New Quote"}
      footer={
        <Grid2 container justifyContent={"flex-end"} gap={1}>
          <GKButton
            variant="outlined"
            size="medium"
            onClick={() => setOpen(false)}
          >
            Cancel
          </GKButton>
          <GKButton
            variant="contained"
            type="submit"
            form="create-lead-form"
            size="medium"
          >
            {editQuoteValue?.id ? "Update" : "Save"}
          </GKButton>
        </Grid2>
      }
    >
      <form
        id="create-lead-form"
        onSubmit={form.handleSubmit((values) =>
          editQuoteValue?.id ? updateQuote(values) : createQuote(values),
        )}
      >
        <Grid2 container spacing={2}>
          <Grid2 size={12}>
            <Controller
              name="quote"
              control={form.control}
              render={({ field }) => (
                <GKTextField
                  {...field}
                  inputLabel="Quote"
                  slotProps={{ htmlInput: { maxLength: CHARACTER_LIMIT } }}
                  helperText={
                    <span
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        color: theme.palette.dark.main,
                        marginTop: 2,
                      }}
                    >
                      {form.getValues("quote").length}/{CHARACTER_LIMIT}
                    </span>
                  }
                />
              )}
            />
          </Grid2>
        </Grid2>
      </form>
    </GKModal>
  );
};

export default QuoteModal;
