import { LoadingButton } from "@mui/lab";
import { useAppContext } from "AppContext";
import {
  ConfirmDeleteModal,
  Divider,
  GKDatePicker,
  GKSearchSelect,
  GKTextField,
  Grid2,
  TextField,
  Typography,
} from "components";
import dayjs from "dayjs";
import { useFetchQuery } from "hooks/useQueries";
import { useTransactionAction } from "hooks/useTransactionAction";
import DashboardLayout from "layouts/DashboardLayout";
import { useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { useLocation, useParams } from "react-router-dom";
import { numberToCurrency } from "utils";
import { GET_ALL_OTHER_ASSET_BY_ASSET_TYPE_LIST_BY_PORTFOLIO_ID } from "utils/apiRoutes";
import { paletteLightTheme } from "utils/ColorTheme";
import { blockInvalidChar } from "utils/common";
import {
  FD_INTEREST_TYPES,
  FD_PAYOUT_CUMULATIVE_TYPE,
  FD_PAYOUT_TRANSACTION_TYPE,
} from "utils/constants";
import { QueryKeys } from "utils/queryKey";
import { handleReturnAutocompletePlaceholder } from "utils/StringFunctions";
import useDocumentTitle from "utils/useDocumentTitle";
import { schema } from "utils/validationSchema";

const FixedDepositForm = () => {
  useDocumentTitle("Transaction");

  const { id } = useParams();
  const { state } = useLocation();

  const {
    state: { portfolios, companies },
  } = useAppContext();

  const getAssetType = state?.assetType;

  const { data, isFetching } = useFetchQuery({
    key: [QueryKeys.TRANSACTIONS_LIST, id],
    route: GET_ALL_OTHER_ASSET_BY_ASSET_TYPE_LIST_BY_PORTFOLIO_ID(
      id,
      getAssetType,
    ),
    enabled: !!id,
  });

  const {
    handleCreate,
    createLoading,
    handleUpdateStock,
    mutationUpdateLoading,
    deleteLoading,
    handleDelete,
  } = useTransactionAction();

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const form = useForm({
    defaultValues: {
      portfolio: (data?.data ? data.data.portfolio : +state?.id) || null,
      date: "",
      brokerName: "",
      contractNoteNum: "",
      anchor: false,
      transactions: [
        {
          brokerage: 0,
          gridCode: state?.gridCode || "",
          transactionType: "",
          amountWithBrokerage: 0,
          billAmount: 0,
          price: 0,
          quantity: 1,
          totalTransactionCharges: 0,
          cashEntry: false,
          fdAccountNo: state?.fdAccountNo || "",
          interestType: state?.interestType || "",
          tds: 0,
          totalCharges: {
            gst: 0,
            otherCharges: 0,
            stampCharges: 0,
            stt: 0,
            transactionCharges: 0,
            sebiTurnoverFees: 0,
          },
        },
      ],
    },
    values: data?.data,
  });

  const { fields } = useFieldArray({
    name: "transactions",
    control: form.control,
  });

  form.watch("transactions");

  const getTotalAmount = (index: number) => {
    const getTotalAmount =
      (+form.getValues(`transactions.${index}.price`) || 0) -
      (+form.getValues(`transactions.${index}.tds`) || 0);

    return getTotalAmount;
  };

  return (
    <DashboardLayout title={"Create Trade"} loading={isFetching}>
      <Grid2
        container
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        gap={1}
      >
        <Typography fontWeight={600} fontSize={20}>
          {id ? "Update Trade" : "Add Trade"}
        </Typography>
      </Grid2>

      <form
        onSubmit={form.handleSubmit((values) => {
          const transactionData = {
            ...values,
            transactions: values.transactions.map((dataItem: any) => {
              return {
                ...dataItem,
                cashEntry: !id,
              };
            }),
          };

          id
            ? handleUpdateStock(transactionData)
            : handleCreate(transactionData);
        })}
      >
        <Grid2 container spacing={2} mt={1}>
          <Grid2 size={4}>
            <Controller
              name="portfolio"
              control={form.control}
              rules={{
                required: {
                  value: !Number(state?.id),
                  message: "Please select portfolio",
                },
              }}
              render={({ field, fieldState: { error } }) => {
                return (
                  <>
                    {!!id || !!state?.id ? (
                      <GKTextField
                        inputLabel="Portfolio"
                        disabled
                        value={
                          portfolios?.find(
                            (data: any) => +data?.value === +field.value,
                          )?.name
                        }
                      />
                    ) : (
                      <GKSearchSelect
                        {...field}
                        requiredField
                        disableClearable
                        inputLabel="Select Portfolio"
                        renderOption={(props, option) => {
                          return (
                            <li
                              {...props}
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "flex-start",
                              }}
                            >
                              <Typography fontWeight={600}>
                                {option.clientName}
                              </Typography>
                              <Typography>{option.name}</Typography>
                            </li>
                          );
                        }}
                        options={portfolios || []}
                        getOptionLabel={(option) => option?.name || option}
                        onChange={(event, val) => field.onChange(val?.value)}
                        value={
                          portfolios?.find(
                            (data: any) => +data?.value === +field.value,
                          )?.name
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder={handleReturnAutocompletePlaceholder({
                              placeholder: "Select Portfolio",
                              value: field.value,
                            })}
                            error={!!error}
                            helperText={error?.message}
                          />
                        )}
                      />
                    )}
                  </>
                );
              }}
            />
          </Grid2>
          <Grid2 size={4}>
            <Controller
              name="date"
              control={form.control}
              rules={{
                required: {
                  value: true,
                  message: "Please select date",
                },
                validate: async (value) => {
                  try {
                    await schema("date").validate({
                      date: dayjs(value).format("YYYY-MM-DD"),
                    });
                  } catch (error: any) {
                    return error.message;
                  }

                  return {};
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <GKDatePicker
                  requiredField
                  inputLabel="Trade Date"
                  name="date"
                  value={dayjs(field.value)}
                  onChange={(value) => {
                    field.onChange(dayjs(value).format("YYYY-MM-DD"));
                  }}
                  disableFuture
                  maxDate={dayjs()}
                  slotProps={{
                    textField: {
                      error: !!error,
                      helperText: error?.message,
                    },
                  }}
                />
              )}
            />
          </Grid2>

          <Grid2 size={12}>
            <Divider />
          </Grid2>
          <Grid2 size={12}>
            {fields?.map((row, index) => {
              return (
                <Grid2 container size={12} key={row.id} spacing={2}>
                  <Grid2 size={4}>
                    <Controller
                      control={form.control}
                      name={`transactions.${index}.gridCode`}
                      rules={{
                        required: {
                          value: true,
                          message: "Please select company",
                        },
                      }}
                      render={({ field, fieldState: { error } }) => {
                        return (
                          <GKSearchSelect
                            {...field}
                            inputLabel="Select Company"
                            requiredField
                            disableClearable
                            disabled={state?.disableField}
                            renderOption={(props, option) => (
                              <li
                                {...props}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: 4,
                                }}
                              >
                                <Typography
                                  color={paletteLightTheme.palette.light.main}
                                  fontSize={8}
                                  style={{
                                    background:
                                      option?.instType === "EQ"
                                        ? paletteLightTheme.palette.secondary
                                            .main
                                        : paletteLightTheme.palette.warning
                                            .main,
                                    padding: "2px 4px",
                                    borderRadius: 4,
                                  }}
                                >
                                  {option?.instType}
                                </Typography>
                                <Typography whiteSpace={"nowrap"}>
                                  {option?.name}
                                </Typography>
                              </li>
                            )}
                            options={
                              companies
                                .filter((dataItem) =>
                                  state.transactionType === "OTHER-ASSETS"
                                    ? dataItem.instType === "FD"
                                    : dataItem,
                                )
                                ?.filter((data) => data?.instType !== "MF") ||
                              []
                            }
                            getOptionLabel={(option) => option?.name || option}
                            onChange={(ev, val) => field.onChange(val?.value)}
                            value={
                              companies.find(
                                (data: any) => data?.value === field.value,
                              )?.name
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder={handleReturnAutocompletePlaceholder(
                                  {
                                    placeholder: "Select Company",
                                    value: field.value,
                                  },
                                )}
                                error={!!error}
                                helperText={error?.message}
                              />
                            )}
                          />
                        );
                      }}
                    />
                  </Grid2>
                  <Grid2 size={4}>
                    <Controller
                      control={form.control}
                      name={`transactions.${index}.fdAccountNo`}
                      rules={{
                        required: {
                          value: true,
                          message: "Please enter FD Account No",
                        },
                      }}
                      render={({ field, fieldState: { error } }) => (
                        <GKTextField
                          {...field}
                          disabled={state?.disableField}
                          requiredField
                          inputLabel="FD Account No"
                          error={!!error}
                          helperText={error?.message}
                        />
                      )}
                    />
                  </Grid2>
                  <Grid2 size={4}>
                    <Controller
                      control={form.control}
                      name={`transactions.${index}.interestType`}
                      rules={{
                        required: {
                          value: true,
                          message: "Please select interest type",
                        },
                      }}
                      render={({ field, fieldState: { error } }) => (
                        <GKSearchSelect
                          {...field}
                          onChange={(e, val) => {
                            field.onChange(val?.value);
                          }}
                          disabled={state?.disableField}
                          requiredField
                          disableClearable={false}
                          value={
                            FD_INTEREST_TYPES?.find(
                              (item) => item?.value === field.value,
                            )?.name || []
                          }
                          options={FD_INTEREST_TYPES || []}
                          getOptionLabel={(opt) => opt?.name || opt}
                          inputLabel={"Interest Type"}
                          renderInput={(params) => {
                            return (
                              <TextField
                                {...params}
                                placeholder={handleReturnAutocompletePlaceholder(
                                  {
                                    placeholder: "Select Interest Type",
                                    value: field.value,
                                  },
                                )}
                                error={!!error}
                                helperText={error?.message}
                              />
                            );
                          }}
                        />
                      )}
                    />
                  </Grid2>
                  <Grid2 size={4}>
                    <Controller
                      control={form.control}
                      name={`transactions.${index}.transactionType`}
                      rules={{
                        required: {
                          value: true,
                          message: "Please select interest type",
                        },
                      }}
                      render={({ field, fieldState: { error } }) => (
                        <GKSearchSelect
                          {...field}
                          requiredField
                          onChange={(e, val) => field.onChange(val?.value)}
                          disableClearable={false}
                          value={
                            (form.getValues(
                              `transactions.${index}.interestType`,
                            ) === "PAYOUT"
                              ? FD_PAYOUT_TRANSACTION_TYPE
                              : FD_PAYOUT_CUMULATIVE_TYPE
                            )?.find((item) => item?.value === field.value)
                              ?.name || []
                          }
                          options={
                            form.getValues(
                              `transactions.${index}.interestType`,
                            ) === "PAYOUT"
                              ? FD_PAYOUT_TRANSACTION_TYPE
                              : FD_PAYOUT_CUMULATIVE_TYPE
                          }
                          getOptionLabel={(opt) => opt?.name || opt}
                          inputLabel={"Transaction Type"}
                          renderInput={(params) => {
                            return (
                              <TextField
                                {...params}
                                placeholder={handleReturnAutocompletePlaceholder(
                                  {
                                    placeholder: "Select Transaction Type",
                                    value: field.value,
                                  },
                                )}
                                error={!!error}
                                helperText={error?.message}
                              />
                            );
                          }}
                        />
                      )}
                    />
                  </Grid2>
                  <Grid2 size={4}>
                    <Controller
                      control={form.control}
                      name={`transactions.${index}.price`}
                      rules={{
                        required: {
                          value: true,
                          message: "Please enter price",
                        },
                      }}
                      render={({ field, fieldState: { error } }) => (
                        <GKTextField
                          {...field}
                          requiredField
                          inputLabel="Amount"
                          onKeyDown={blockInvalidChar}
                          onChange={(
                            event: React.ChangeEvent<
                              HTMLInputElement | HTMLTextAreaElement
                            >,
                          ) => {
                            if (event.target instanceof HTMLInputElement) {
                              const { valueAsNumber } = event.target;
                              field.onChange(valueAsNumber);
                            }
                          }}
                          type="number"
                          error={!!error}
                          helperText={error?.message}
                        />
                      )}
                    />
                  </Grid2>
                  <Grid2 size={4}>
                    <Controller
                      control={form.control}
                      name={`transactions.${index}.tds`}
                      render={({ field }) => (
                        <GKTextField
                          {...field}
                          inputLabel={"TDS"}
                          type="number"
                          onKeyDown={blockInvalidChar}
                          onChange={(
                            event: React.ChangeEvent<
                              HTMLInputElement | HTMLTextAreaElement
                            >,
                          ) => {
                            if (event.target instanceof HTMLInputElement) {
                              const { valueAsNumber } = event.target;
                              field.onChange(valueAsNumber);
                            }
                          }}
                        />
                      )}
                    />
                  </Grid2>
                </Grid2>
              );
            })}
          </Grid2>
          <Grid2 size={12}>
            <Divider />
          </Grid2>
          <Grid2
            size={12}
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
            gap={1}
          >
            <Typography
              fontWeight={500}
              fontSize={14}
              color={paletteLightTheme.palette.secondaryText.main}
              display={"flex"}
              alignItems={"center"}
              gap={1}
            >
              Total Amount:
              <Typography fontWeight={600} fontSize={16}>
                {numberToCurrency(getTotalAmount(0))}
              </Typography>
            </Typography>
            <div style={{ gap: "8px", display: "flex" }}>
              {id && (
                <LoadingButton
                  variant="outlined"
                  size="medium"
                  color="error"
                  loading={deleteLoading}
                  onClick={() => setIsDeleteModalOpen(true)}
                >
                  Delete Trade
                </LoadingButton>
              )}
              <LoadingButton
                variant="contained"
                size="medium"
                type="submit"
                loading={createLoading || mutationUpdateLoading}
              >
                {id ? "Update" : "Save"}
              </LoadingButton>
            </div>
          </Grid2>
        </Grid2>
      </form>

      {isDeleteModalOpen && (
        <ConfirmDeleteModal
          open={isDeleteModalOpen}
          setOpen={setIsDeleteModalOpen}
          title="Transaction"
          onDelete={() => handleDelete(id)}
          isDelete
          deleteMessage="Are you sure you want to delete this transaction? This transaction will permanently be removed. This action cannot be undone."
        />
      )}
    </DashboardLayout>
  );
};

export default FixedDepositForm;
