import { useFetchQuery, useMutation } from "hooks/useQueries";
import { useNavigate, useParams } from "react-router-dom";
import { GET_ALL_FEE_PLAN, GET_FEE_PLAN_BY_ID } from "utils/apiRoutes";
import axiosInstance from "utils/axiosInstance";
import { refetchQuery } from "utils/helpers";
import { QueryKeys } from "utils/queryKey";

const useRevenueManager = () => {
  const navigate = useNavigate();

  const { id } = useParams();

  const { mutate: createFeePlan } = useMutation({
    mutationKey: ["CREATE_FEE_PLAN"],
    mutationFn: (data) => axiosInstance.post("/fee-plans/", data),
    onSuccess: () => {
      navigate(-1);
      refetchQuery(QueryKeys.GET_USER_DETAIL);
    },
  });

  const {
    data: getFeePlanById,
    isFetching: getFeePlanFetching,
    isFetched: getFeePlanFetched,
  } = useFetchQuery({
    key: ["GET_FEE_PLAN_BY_ID"],
    route: GET_FEE_PLAN_BY_ID(id),
    enabled: Boolean(id),
  });

  const { mutate: updateFeePlan, isLoading: updateFeePlanLoading } =
    useMutation({
      mutationKey: ["UPDATE_FEE_PLAN"],
      mutationFn: (data) => axiosInstance.patch(`/fee-plans/${id}/`, data),
      onSuccess: () => {
        navigate(-1);
      },
    });

  const { data: feePlansList, isFetching: getFeePlansFetching } = useFetchQuery(
    {
      key: ["GET_FEE_PLANS_DROPDOWN"],
      route: GET_ALL_FEE_PLAN,
    },
  );

  return {
    createFeePlan,
    getFeePlanById,
    getFeePlanFetching,
    updateFeePlan,
    getFeePlanFetched,
    feePlansList,
    getFeePlansFetching,
    updateFeePlanLoading,
  };
};

export default useRevenueManager;
