import { blueGrey } from "@mui/material/colors";
import {
  Box,
  Button,
  GKButton,
  GKTypography,
  Grid2,
  IoIosArrowBack,
  IoIosArrowForward,
  Show,
  Slide,
  Typography,
} from "components";
import { useMutation } from "hooks/useQueries";
import { split } from "lodash";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
import axiosInstance from "utils/axiosInstance";
import { refetchQuery } from "utils/helpers";
import { useInvestorContext } from "./InvestorContext";

export const handleReturnRiskColors = (text: any) => {
  switch (text.toLowerCase()) {
    case "moderate":
      return "#ECC704";
    case "low":
      return "#E04101";
    case "high":
      return "#46A900";
    default:
      return "#000";
  }
};

interface Props {
  basketId: string;
  handleNext: () => void;
}

const AssessmentTab = (props: Props) => {
  const form = useFormContext();

  const {
    state: { token },
  } = useInvestorContext();

  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [checked, setChecked] = useState(true);
  const [answers, setAnswers] = useState<any[]>([]);

  const retakeAssessmentWatch = form.watch("retakeAssessment");
  form.watch("clientObject.riskprofileDetails");

  const current =
    form.getValues(
      "clientObject.riskprofileQuestionnaire.data.riskQuestions",
    ) &&
    form.getValues("clientObject.riskprofileQuestionnaire.data.riskQuestions")[
      currentQuestion
    ];

  const { mutate: handleRiskProfiling } = useMutation({
    mutationKey: ["RISK_PROFILE_CREATE"],
    mutationFn: ({ clientId, basketId }: { clientId: any; basketId: any }) =>
      axiosInstance.post(
        `/client-basket/${clientId}/onboard-risk-response/?basket_id=${basketId}`,
        {
          client: clientId,
          response: answers,
        },
        {
          headers: {
            Authorization: `Token ${token}`,
            "x-gridkey-user-role": "client",
          },
        },
      ),
    onSuccess: () => {
      form.setValue("retakeAssessment", false);
      refetchQuery("GET_CLIENT_FOR_CREATION");
      setChecked(true);

      setTimeout(() => {
        setCurrentQuestion(0);
      }, 300);
    },
  });

  const handleNextQuestion = (option: any) => {
    setChecked(false);
    setAnswers((prevAnswers: any) => [
      ...prevAnswers,
      {
        question: current?.id,
        option: option.id,
        optionPoints: option.optionPoints,
      },
    ]);
    if (
      currentQuestion + 1 ===
      form.getValues("clientObject.riskprofileQuestionnaire.data.riskQuestions")
        .length
    ) {
      handleRiskProfiling({
        clientId: form.getValues("clientObject.id"),
        basketId: props?.basketId,
      });
    } else {
      setTimeout(() => {
        setChecked(true);
        setCurrentQuestion((prev) => prev + 1);
      }, 300);
    }
  };

  const riskProfileMatch =
    +form.getValues("clientObject.riskprofileDetails.points") >
    +split(
      form.getValues("clientObject.basket.riskProfilePoints") || "0 - 0",
      " ",
    )[0];

  return (
    <Grid2 container spacing={2} p={2}>
      <Grid2 size={12}>
        <Typography
          fontWeight={600}
          pt={2}
          sx={{ fontSize: "clamp(1.125rem, 0.6563rem + 1.5vw, 1.5rem)" }}
        >
          Complete your Risk profile assessment
        </Typography>
      </Grid2>
      <Show.When isTrue={retakeAssessmentWatch}>
        <Grid2 size={12} minHeight={"250px"}>
          <div style={{ overflow: "none" }}>
            {form.getValues(
              "clientObject.riskprofileQuestionnaire.data.riskQuestions",
            )?.length && (
              <Slide direction="left" in={checked} mountOnEnter unmountOnExit>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: "column",
                    gap: 16,
                    overflow: "none",
                  }}
                >
                  <GKTypography
                    fontSize={14}
                    display={"flex"}
                    justifyContent={"center"}
                  >
                    {current.questionText}
                  </GKTypography>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: "column",
                      gap: 8,
                      overflow: "none",
                    }}
                  >
                    {current.options.map((opt: any, index: number) => (
                      <Button
                        fullWidth
                        key={index}
                        // disabled={data?.step4}
                        color={"dark"}
                        variant={"outlined"}
                        onClick={() => handleNextQuestion(opt)}
                      >
                        {opt.option}
                      </Button>
                    ))}
                  </div>
                </div>
              </Slide>
            )}
          </div>
        </Grid2>
        <Grid2 size={12} gap={1} display={"flex"} justifyContent={"center"}>
          <Button
            variant="outlined"
            color="dark"
            onClick={() => setCurrentQuestion((p) => p - 1)}
            disabled={currentQuestion === 0}
          >
            <IoIosArrowBack />
          </Button>
          <Button
            variant="outlined"
            color="dark"
            onClick={() => setCurrentQuestion((p) => p + 1)}
            disabled={
              form.getValues(
                "clientObject.riskprofileQuestionnaire.data.riskQuestions",
              )?.length -
                1 ===
              currentQuestion
            }
          >
            <IoIosArrowForward />
          </Button>
        </Grid2>
      </Show.When>
      <Show.When
        isTrue={
          form.getValues("clientObject.riskprofileDetails.points") &&
          !retakeAssessmentWatch
        }
      >
        <Grid2 container size={12} p={2} bgcolor={"#F8FAFC"}>
          <Grid2 size={12} bgcolor={"#F8FAFC"}>
            <Typography
              color={blueGrey[600]}
              display={"flex"}
              justifyContent={"center"}
              gap={0.5}
            >
              Your risk score is
              <Typography fontWeight={600}>
                {form.getValues("clientObject.riskprofileDetails.points")}
              </Typography>
              and risk profile status is
              <Typography
                fontWeight={600}
                color={handleReturnRiskColors(
                  form.getValues(
                    `clientObject.riskprofileDetails.riskStatus`,
                  ) || "-",
                )}
              >
                {form.getValues(`clientObject.riskprofileDetails.riskStatus`)}
              </Typography>
            </Typography>
          </Grid2>
        </Grid2>
        <Grid2 size={12} container bgcolor={"#F8FAFC"} p={4} borderRadius={2}>
          <Grid2 size={12} position={"relative"}>
            <Box
              sx={{
                width: "100%",
                position: "relative",
                background: `linear-gradient(90deg, rgba(255,0,0,1) 0%, rgba(235,233,58,1) 51%, rgba(141,224,43,1) 100%)`,
                height: 10,
                borderRadius: 2,
                "::before": {
                  content: `" "`,
                  position: "absolute",
                  height: "150%",
                  width: "2px",
                  background: "#000000",
                  top: -2,
                  left: `${
                    (form.getValues(`clientObject.riskprofileDetails.points`) /
                      form.getValues(
                        `clientObject.riskprofileDetails.totalPoints`,
                      )) *
                    100
                  }%`,
                },
                "::after": {
                  content: `"${form.getValues(`clientObject.riskprofileDetails.points`)}"`,
                  position: "absolute",
                  height: "100%",
                  left: `${
                    (form.getValues(`clientObject.riskprofileDetails.points`) /
                      form.getValues(
                        `clientObject.riskprofileDetails.totalPoints`,
                      )) *
                    99
                  }%`,
                  top: 16,
                  fontSize: 12,
                  fontWeight: 600,
                },
              }}
            >
              <div
                style={{
                  position: "absolute",
                  bottom: -20,
                  left: 0,
                  fontSize: 12,
                  color: "#9AA4B2",
                }}
              >
                {form.getValues(
                  "clientObject.riskprofileQuestionnaire.data.lowPoints",
                )}
              </div>
              <div
                style={{
                  position: "absolute",
                  top: -20,
                  left: 0,
                  color: "#9AA4B2",
                  fontSize: 12,
                }}
              >
                {form.getValues(
                  `clientObject.riskprofileDetails.pointsDetails`,
                ) &&
                  form.getValues(
                    `clientObject.riskprofileDetails.pointsDetails`,
                  )[0]}
              </div>
              <Show.When
                isTrue={
                  form.getValues(
                    `clientObject.riskprofileDetails.pointsDetails`,
                  )?.length === 3
                }
              >
                <div
                  style={{
                    position: "absolute",
                    bottom: -20,
                    right: 0,
                    color: "#9AA4B2",
                    fontSize: 12,
                  }}
                >
                  {form.getValues(
                    "clientObject.riskprofileQuestionnaire.data.highPoints",
                  )}
                </div>
                <div
                  style={{
                    position: "absolute",
                    top: -20,
                    left: 0,
                    fontSize: 12,
                    color: "#9AA4B2",
                    right: 0,
                    marginInline: "auto",
                    width: "fit-content",
                  }}
                >
                  {form.getValues(
                    `clientObject.riskprofileDetails.pointsDetails`,
                  ) &&
                    form.getValues(
                      `clientObject.riskprofileDetails.pointsDetails`,
                    )[1]}
                </div>
                <div
                  style={{
                    position: "absolute",
                    color: "#9AA4B2",
                    top: -20,
                    right: 0,
                    fontSize: 12,
                  }}
                >
                  {form.getValues(
                    `clientObject.riskprofileDetails.pointsDetails`,
                  ) &&
                    form.getValues(
                      `clientObject.riskprofileDetails.pointsDetails`,
                    )[2]}
                </div>
              </Show.When>
              <Show.When
                isTrue={
                  form.getValues(
                    `clientObject.riskprofileDetails.pointsDetails`,
                  )?.length === 5
                }
              >
                <div
                  style={{
                    position: "absolute",
                    bottom: -20,
                    right: 0,
                    color: "#9AA4B2",
                    fontSize: 12,
                  }}
                >
                  {form.getValues(
                    "clientObject.riskprofileQuestionnaire.data.highPoints",
                  )}
                </div>
                <div
                  style={{
                    position: "absolute",
                    top: -20,
                    left: "25%",
                    fontSize: 12,
                    color: "#9AA4B2",
                  }}
                >
                  {form.getValues(
                    `clientObject.riskprofileDetails.pointsDetails`,
                  ) &&
                    form.getValues(
                      `clientObject.riskprofileDetails.pointsDetails`,
                    )[1]}
                </div>
                <div
                  style={{
                    position: "absolute",
                    top: -20,
                    left: "50%",
                    fontSize: 12,
                    color: "#9AA4B2",
                  }}
                >
                  {form.getValues(
                    `clientObject.riskprofileDetails.pointsDetails`,
                  ) &&
                    form.getValues(
                      `clientObject.riskprofileDetails.pointsDetails`,
                    )[2]}
                </div>
                <div
                  style={{
                    position: "absolute",
                    top: -20,
                    left: "74%",
                    fontSize: 12,
                    color: "#9AA4B2",
                  }}
                >
                  {form.getValues(
                    `clientObject.riskprofileDetails.pointsDetails`,
                  ) &&
                    form.getValues(
                      `clientObject.riskprofileDetails.pointsDetails`,
                    )[3]}
                </div>
                <div
                  style={{
                    position: "absolute",
                    color: "#9AA4B2",
                    top: -20,
                    right: 0,
                    fontSize: 12,
                  }}
                >
                  {form.getValues(
                    `clientObject.riskprofileDetails.pointsDetails`,
                  ) &&
                    form.getValues(
                      `clientObject.riskprofileDetails.pointsDetails`,
                    )[4]}
                </div>
              </Show.When>
            </Box>
          </Grid2>
        </Grid2>
        <Grid2 size={12} textAlign={"center"}>
          {riskProfileMatch ? (
            <img src="/investor/risk-success.svg" alt="" />
          ) : (
            <img src="/investor/risk-error.svg" alt="" />
          )}
        </Grid2>
        <Grid2 size={12} textAlign={"center"}>
          <Typography fontWeight={600}>
            {riskProfileMatch
              ? "Congratulations! Your risk profile matches with the basket’s risk profile, we can move ahead with the subscription."
              : "Your risk profile doesn't match with the basket’s risk profile.Do you still want to proceed?"}
          </Typography>
        </Grid2>
        {!riskProfileMatch && (
          <Grid2
            size={12}
            display={"flex"}
            gap={1}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <GKButton variant="contained" onClick={() => props.handleNext()}>
              Yes
            </GKButton>
            <GKButton
              variant="outlined"
              color="dark"
              onClick={() => {
                form.setValue("retakeAssessment", true);
                form.setValue("clientObject.riskprofileDetails", null);
              }}
            >
              Retake Assessment
            </GKButton>
          </Grid2>
        )}
      </Show.When>
    </Grid2>
  );
};

export default AssessmentTab;
