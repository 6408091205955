import { CKEditorContext, CKEditor as Editor } from "@ckeditor/ckeditor5-react";
import { Box, Typography } from "@mui/material";
import {
  Alignment,
  BlockQuote,
  Bold,
  ClassicEditor,
  Code,
  Context,
  ContextWatchdog,
  Essentials,
  FontBackgroundColor,
  FontColor,
  FontSize,
  Heading,
  Image,
  Italic,
  Link,
  List,
  Paragraph,
  SimpleUploadAdapter,
  Strikethrough,
  Subscript,
  Superscript,
  Table,
  TableCellProperties,
  TableEditing,
  TableToolbar,
  Underline,
} from "ckeditor5";
import "ckeditor5/ckeditor5.css";
import { ThemeContainer } from "utils/Theme";

function CKEditor({
  value,
  onChange,
  ref,
  onReady,
  placeholder,
  toolbar,
  disabled,
  charLimit,
}: {
  value: string;
  onChange: (value: any) => void;
  onReady: (value: any) => void;
  ref?: any;
  charLimit?: any;
  disabled?: boolean;
  placeholder?: string;
  toolbar?: string[];
}) {
  const { theme } = ThemeContainer();

  return (
    <CKEditorContext context={Context} contextWatchdog={ContextWatchdog}>
      <Editor
        editor={ClassicEditor}
        ref={ref}
        disabled={disabled}
        config={{
          placeholder: placeholder,
          licenseKey: "GPL",
          plugins: [
            Essentials,
            Bold,
            Italic,
            Paragraph,
            Table,
            SimpleUploadAdapter,
            FontColor,
            Alignment,
            FontBackgroundColor,
            Image,
            FontSize,
            Underline,
            Strikethrough,
            BlockQuote,
            List,
            Subscript,
            Superscript,
            Link,
            Code,
            Heading,
            TableCellProperties,
            TableEditing,
            TableToolbar,
          ],
          toolbar: toolbar || [
            "undo",
            "redo",
            "|",
            "heading",
            "bold",
            "italic",
            "underline",
            "strikethrough",
            "blockQuote",
            "|",
            "fontSize",
            "fontColor",
            "fontBackgroundColor",
            "|",
            "alignment",
            "subscript",
            "superscript",
            "link",
            "code",
            "|",
            "bulletedList",
            "numberedList",
            "insertTable",
            "tableColumn",
            "tableRow",
            "mergeTableCells",
            "tableToolbar",
          ],
        }}
        data={value}
        onChange={(event: any, editor: any) => {
          const data = editor.getData();
          onChange(data);
        }}
        onReady={onReady}
      />
      {charLimit && (
        <Box display={"flex"} justifyContent={"flex-end"} p={1}>
          <Typography
            fontWeight={600}
            color={
              value?.length > charLimit
                ? theme.palette.error.main
                : theme.palette.grey[600]
            }
          >{`${value?.length} / ${charLimit}`}</Typography>
        </Box>
      )}
    </CKEditorContext>
  );
}

export default CKEditor;
