import { useFetchQuery, useMutation } from "hooks/useQueries";
import { useState } from "react";
import { GET_RISK_PROFILE } from "utils/apiRoutes";
import axiosInstance from "utils/axiosInstance";

const useRiskProfileAPI = () => {
  const [loading, setLoading] = useState(false);

  const { data, isFetching, refetch } = useFetchQuery({
    key: ["RISK_PROFILE"],
    route: GET_RISK_PROFILE,
    success: () => {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
      }, 1500);
    },
  });

  const { mutate: handleUpdate } = useMutation({
    mutationKey: ["UPDATE_RISK_PROFILE"],
    mutationFn: (data: any) =>
      axiosInstance.patch(`/riskprofile/${data?.id}/`, data.data),
    onSuccess: () => {
      setLoading(false);
      refetch();
    },
    onError: () => {
      setLoading(false);
    },
  });

  const { mutate: handleCreate } = useMutation({
    mutationKey: ["CREATE_RISK_PROFILE"],
    mutationFn: (data: any) => axiosInstance.patch(`/riskprofile/`, data),
    onSuccess: () => {
      setLoading(false);
      refetch();
    },
    onError: () => {
      setLoading(false);
    },
  });

  // ===xxx===xxx===xxx===xxx===xxx===xxx===xxx===xxx===xxx===xxx===xxx===xxx
  const { mutate: handleDeleteQuestion } = useMutation({
    mutationKey: ["DELETE_RISK_PROFILE_QUESTION"],
    mutationFn: (id: number) =>
      axiosInstance.delete(`/deleteRiskProfileQuestion/${id}/`),
    onSuccess: () => {
      refetch();
      setLoading(false);
    },
    onError: () => {
      setLoading(false);
    },
  });

  // ===xxx===xxx===xxx===xxx===xxx===xxx===xxx===xxx===xxx===xxx===xxx===xxx
  const { mutate: handleDeleteQuestionOptions } = useMutation({
    mutationKey: ["DELETE_RISK_PROFILE_QUESTION_OPTION"],
    mutationFn: ({ id, option }: { id: number; option: any }) =>
      axiosInstance.delete(
        `/deleteRiskProfileQuestionOption/${id}/?option=${option}`,
      ),
    onSuccess: () => {
      refetch();
      setLoading(false);
    },
    onError: () => {
      setLoading(false);
    },
  });

  return {
    data,
    isFetching,
    refetch,
    handleUpdate,
    loading,
    setLoading,
    handleCreate,
    handleDeleteQuestion,
    handleDeleteQuestionOptions,
  };
};

export default useRiskProfileAPI;
