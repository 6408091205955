import { GKClientDataGrid } from "components";
import { useFetchQuery, useMutation } from "hooks/useQueries";
import DashboardLayout from "layouts/DashboardLayout";
import { useReducer, useState } from "react";
import toast from "react-hot-toast";
import axiosInstance from "utils/axiosInstance";
import { ArchiveManagerPageColumns } from "utils/columns";
import { QueryKeys } from "utils/queryKey";
import useDocumentTitle from "utils/useDocumentTitle";
import ViewUpload from "./UploadManagerViewUpload";

interface LoadingStatesProps {
  isViewFileLoading: boolean;
  isArchiveLoading: boolean;
}

const ArchiveManagerPage = () => {
  useDocumentTitle("Archive Manager");

  const [clickedButtonId, setClickedButtonId] = useState<number>();

  const [isViewModalOpen, setIsViewModalOpen] = useState(false);

  const [viewFileFormat, setViewFileFormat] = useState<string>();

  const [viewModalData, setViewModalData] = useState<any>();

  const [loadingStates, updateLoadingState] = useReducer(
    (oldPath: LoadingStatesProps, newStates: LoadingStatesProps) => ({
      ...oldPath,
      ...newStates,
    }),
    {
      isViewFileLoading: false,
      isArchiveLoading: false,
    },
  );

  const { data, isFetched, refetch } = useFetchQuery({
    key: [QueryKeys.TRANSACTION_UPLOAD_ARCHIVE],
    route: "/transaction_upload/archive/",
  });

  const { mutate: unArchiveFile } = useMutation({
    mutationKey: ["UNARCHIVE_FILE"],
    mutationFn: (id: number) =>
      axiosInstance.put(`/transaction_upload/${id}/undo_archive/`),
    onSuccess: () => {
      refetch();
    },
  });

  const handleViewFileClick = async (
    id: number,
    fileType: string,
    fileFormat?: string,
  ) => {
    updateLoadingState({ ...loadingStates, isViewFileLoading: true });
    await axiosInstance
      .post(`/transaction_upload/review/?is_archive=true&id=${id}`)
      .then((reviewResponse) => {
        setViewModalData(reviewResponse?.data?.transactions);
        setClickedButtonId(id);
        setIsViewModalOpen(true);
        setViewFileFormat(fileFormat);
      })
      .catch((error: any) => {
        toast.error(
          error?.response?.data?.message ||
            "Something went wrong, please try again.",
        );
      });

    updateLoadingState({ ...loadingStates, isViewFileLoading: false });
  };

  return (
    <DashboardLayout title="Archive Manager">
      {isViewModalOpen && (
        <ViewUpload
          open={isViewModalOpen}
          setOpen={setIsViewModalOpen}
          recordData={viewModalData}
          recordID={clickedButtonId}
          recordName={
            data?.data?.find((item: any) => item?.id === clickedButtonId)
              ?.fileName
          }
          recordFormat={viewFileFormat}
        />
      )}

      <GKClientDataGrid
        name="archiveManagerPage"
        loading={!isFetched}
        columns={ArchiveManagerPageColumns(
          handleViewFileClick,
          setClickedButtonId,
          loadingStates,
          clickedButtonId,
          unArchiveFile,
        )}
        rows={data?.data || []}
        exportFile={{
          path: "/transaction_upload/archive/",
          exportName: "ArchiveManagerUploadList",
        }}
      />
    </DashboardLayout>
  );
};

export default ArchiveManagerPage;
