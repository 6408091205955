import { GridColDef } from "@mui/x-data-grid-pro";
import { CgMathPlus, GKButton, GKDataGrid, Grid2, Show } from "components";
import useGenerateQueryParams from "hooks/useGenerateQueryParams";
import { useFetchQuery } from "hooks/useQueries";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { extraColumnsField } from "utils/helpers";
import useDocumentTitle from "utils/useDocumentTitle";
import PartnerModal from "./PartnerModal";

const columns: GridColDef[] = [
  {
    ...extraColumnsField("Name", "name", 150, 1),
  },
  {
    ...extraColumnsField("Code", "code", 150, 1),
  },
  {
    ...extraColumnsField("Email", "email", 150, 1),
    valueGetter: (params) => params?.row?.email || "-",
  },
  {
    ...extraColumnsField("Phone", "phone", 150, 1),
    valueGetter: (params) => params?.row?.phone || "-",
  },
  {
    ...extraColumnsField("Branch", "headBranch", 150, 1),
  },
];

const Partner = () => {
  useDocumentTitle("Partner");

  const navigate = useNavigate();

  const [openPartnerModal, setOpenPartnerModal] = useState(false);

  const requestBody = useGenerateQueryParams("partner");

  const { data, isFetching } = useFetchQuery({
    key: ["PARTNER_LIST", requestBody],
    route: "/partner/",
    requestBody,
  });

  return (
    <Grid2 container>
      <Grid2 size={12}>
        <GKDataGrid
          rows={data?.data?.data || []}
          columns={columns}
          loading={isFetching}
          maxHeight={"calc(100vh - 312px)"}
          onRowClick={(row) => navigate(`/app/partner/${row.id}`)}
          totalCount={data?.data?.totalCount || 0}
          name="partner"
          headerComponent={
            <GKButton
              variant="contained"
              onClick={() => setOpenPartnerModal(true)}
              startIcon={<CgMathPlus size={14} />}
            >
              Add Partner
            </GKButton>
          }
          tableName="partner_list"
          columnsButton={false}
        />
      </Grid2>

      <Show.When isTrue={openPartnerModal}>
        <PartnerModal open={openPartnerModal} setOpen={setOpenPartnerModal} />
      </Show.When>
    </Grid2>
  );
};

export default Partner;
