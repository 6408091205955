import {
  Chip,
  GKButton,
  GKModal,
  GKTextField,
  Grid2,
  LuTrash2,
  Show,
  Typography,
} from "components";
import { useFetchQuery, useMutation } from "hooks/useQueries";
import { Controller, useForm } from "react-hook-form";
import { GET_LEAD_STATUS } from "utils/apiRoutes";
import axiosInstance from "utils/axiosInstance";
import { GKModalProps } from "utils/commonInterface";
import { getHexaColor } from "utils/constants";
import { refetchQuery } from "utils/helpers";
import { QueryKeys } from "utils/queryKey";

interface Props {
  type?: string;
  sourceRefetch?: any;
  updateValue?: any;
}

const AddStatusAndSouceModal = (props: Props & GKModalProps) => {
  const { open, setOpen, type, sourceRefetch, updateValue } = props;

  const fieldType = type === "STATUS";

  const { mutate: createLeadStatus } = useMutation({
    mutationFn: (data) => axiosInstance.post("/lead-status/", data),
    onSuccess: () => {
      refetchQuery(QueryKeys.GET_STATUS);
      setOpen(false);
      refetchQuery("GET_ALL_LEADS");
      refetchQuery(QueryKeys.GET_STATUS);
    },
  });

  const { mutate: createLeadSource } = useMutation({
    mutationFn: (data) => axiosInstance.post("/lead-source/", data),
    onSuccess: () => {
      setOpen(false);
      sourceRefetch();
      refetchQuery("GET_ALL_LEADS");
      refetchQuery(QueryKeys.GET_STATUS);
    },
  });

  const { mutate: editStatus } = useMutation({
    mutationFn: (data) =>
      axiosInstance.put(`/lead-status/${updateValue.value}/`, data),
    onSuccess: () => {
      refetchQuery(QueryKeys.GET_STATUS);
      setOpen(false);
      refetchQuery("GET_ALL_LEADS");
      refetchQuery(QueryKeys.GET_STATUS);
    },
  });

  const { mutate: editSource } = useMutation({
    mutationFn: (data) =>
      axiosInstance.put(`/lead-source/${updateValue.value}/`, data),
    onSuccess: () => {
      sourceRefetch();
      setOpen(false);
      refetchQuery("GET_ALL_LEADS");
      refetchQuery(QueryKeys.GET_STATUS);
    },
  });

  const { mutate: handleDeleteStatus } = useMutation({
    mutationFn: (data: any) => axiosInstance.delete(`/lead-status/${data}/`),
    mutationKey: ["DELETE_LEAD_STATUS"],
    onSuccess: () => {
      refetchQuery("GET_ALL_LEAD_STATUS");
      refetchQuery(QueryKeys.GET_STATUS);
    },
  });

  const form = useForm({
    defaultValues: fieldType
      ? {
          leadstatus: updateValue?.name || "",
        }
      : {
          leadsource: updateValue?.name || "",
        },
  });

  const { data: allStatusList } = useFetchQuery({
    key: ["GET_ALL_LEAD_STATUS"],
    route: GET_LEAD_STATUS,
    enabled: fieldType,
  });

  return (
    <GKModal
      open={open}
      setOpen={setOpen}
      modalTitle={fieldType ? "Add New Status" : "Add New Source"}
      footer={
        <Grid2 container justifyContent={"flex-end"} gap={1}>
          <GKButton
            variant="outlined"
            size="medium"
            onClick={() => setOpen(false)}
          >
            Cancel
          </GKButton>
          <GKButton
            variant="contained"
            type="submit"
            form="create-source-status-lead-form"
            size="medium"
          >
            Submit
          </GKButton>
        </Grid2>
      }
    >
      <form
        id="create-source-status-lead-form"
        onSubmit={form.handleSubmit((values: any) => {
          if (fieldType) {
            if (updateValue?.value) {
              editStatus(values);
            } else {
              createLeadStatus(values);
            }
          } else {
            if (updateValue?.value) {
              editSource(values);
            } else {
              createLeadSource(values);
            }
          }
        })}
      >
        <Grid2 container spacing={1}>
          <Grid2 size={12}>
            <Controller
              name={fieldType ? "leadstatus" : "leadsource"}
              control={form.control}
              render={({ field }) => (
                <GKTextField
                  {...field}
                  inputLabel={fieldType ? "Lead Status" : "Lead Source"}
                  requiredField
                />
              )}
            />
          </Grid2>
          <Show.When isTrue={fieldType}>
            <Grid2 size={2}>
              <Typography whiteSpace={"nowrap"}>Existing Status</Typography>
            </Grid2>
            <Grid2 size={10}>
              {allStatusList?.data?.data?.map((dataItem: any) => {
                return (
                  <Chip
                    label={dataItem?.leadstatus}
                    sx={{
                      borderRadius: 2,
                      mr: 1,
                      mb: 1,
                      background: `${getHexaColor(dataItem?.leadstatus)}11`,
                      color: getHexaColor(dataItem?.leadstatus),
                    }}
                    onDelete={() => {
                      handleDeleteStatus(dataItem?.id);
                    }}
                    deleteIcon={
                      <LuTrash2
                        size={16}
                        color={getHexaColor(dataItem?.leadstatus)}
                      />
                    }
                  />
                );
              })}
            </Grid2>
          </Show.When>
        </Grid2>
      </form>
    </GKModal>
  );
};

export default AddStatusAndSouceModal;
