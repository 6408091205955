import { blueGrey } from "@mui/material/colors";
import {
  Box,
  Card,
  CgCreditCard,
  CgFileDocument,
  GKButton,
  Grid2,
  PiCardsThree,
  PiUserCheck,
  PiUserRectangle,
  RiListRadio,
  Typography,
} from "components";
import { useLocation, useNavigate } from "react-router-dom";
import { ThemeContainer } from "utils/Theme";
import useDocumentTitle from "utils/useDocumentTitle";

const cardDetails = [
  {
    title: "Personal Details",
    description: "Provide your basic information to set up your profile.",
    icon: PiUserRectangle,
  },
  {
    title: "Basket & Fee plan",
    description: "Review the investment basket and fees selected for you.",
    icon: PiCardsThree,
  },
  {
    title: "KYC Verification",
    description: "Complete your KYC process for compliance purposes.",
    icon: PiUserCheck,
  },
  {
    title: "Risk Assessment Quiz",
    description: "Find out your Risk appetite to suit your investment goals.",
    icon: RiListRadio,
  },
  {
    title: "Sign Agreement",
    description:
      "Review the agreement and terms & conditions, e-sign to proceed.",
    icon: CgFileDocument,
  },
  {
    title: "Payment",
    description: "Complete the payment to activate your account.",
    icon: CgCreditCard,
  },
];

const WelcomePage = () => {
  useDocumentTitle("Investor / Get Started");

  const { theme } = ThemeContainer();

  const navigate = useNavigate();

  const location = useLocation();

  const clientName = new URLSearchParams(location.search).get("client_name");

  return (
    <Grid2 container>
      <Grid2 size={12}>
        <Box
          sx={{
            borderRadius: "24px",
            background: "linear-gradient(180deg, #EEF2F6 2.42%, #FFF 100%)",
            p: { xs: 4, md: 10 },
            textAlign: "center",
          }}
        >
          <Grid2 container spacing={2}>
            <Grid2 size={12}>
              <Typography
                fontWeight={600}
                sx={{ fontSize: "clamp(1.375rem, 0.5938rem + 2.5vw, 2rem)" }}
              >
                Welcome, {clientName}! <br />
                Your Investment journey begins here
              </Typography>
            </Grid2>
            <Grid2 size={12}>
              <Typography
                fontWeight={500}
                color={blueGrey[400]}
                sx={{ fontSize: "clamp(1rem, 0.6875rem + 1vw, 1.25rem)" }}
              >
                Click <span style={{ color: blueGrey[600] }}>Get started</span>{" "}
                and complete your onboarding in no time!
              </Typography>
            </Grid2>

            <Grid2 size={12} mt={2}>
              <GKButton
                variant="contained"
                size="large"
                onClick={() => {
                  navigate(`/investor/onboarding${location.search}`);
                }}
              >
                Get Started
              </GKButton>
            </Grid2>
          </Grid2>
        </Box>
      </Grid2>
      <Grid2 size={12} mt={8}>
        <Grid2 container spacing={3}>
          <Grid2 size={12}>
            <Typography
              fontWeight={600}
              color="#4B5565"
              textAlign={"center"}
              sx={{ fontSize: "clamp(0.875rem, 0.25rem + 2vw, 1.375rem)" }}
            >
              Just 6 quick steps stand between you and your investment journey
            </Typography>
          </Grid2>
          <Grid2 size={12}>
            <Grid2 container size={12} spacing={3}>
              {cardDetails.map((dataItem) => (
                <Grid2 size={{ xs: 12, md: 4 }}>
                  <Card sx={{ p: 1, background: "#F8FAFC" }}>
                    <Grid2 size={12} container spacing={1}>
                      <Grid2 size={12}>
                        <Typography
                          fontWeight={600}
                          display={"flex"}
                          alignItems={"center"}
                          gap={1}
                          sx={{
                            fontSize:
                              "clamp(0.875rem, 0.7188rem + 0.5vw, 1rem)",
                          }}
                        >
                          <dataItem.icon size={18} /> {dataItem?.title}
                        </Typography>
                      </Grid2>
                      <Grid2 size={12} display={{ md: "flex", xs: "none" }}>
                        <Box
                          sx={{
                            p: 2,
                            background: theme.palette.light.main,
                            border: "1px solid #E3E8EF",
                            borderRadius: "8px",
                          }}
                        >
                          <Typography
                            fontSize={16}
                            fontWeight={400}
                            color="#697586"
                          >
                            {dataItem?.description}
                          </Typography>
                        </Box>
                      </Grid2>
                    </Grid2>
                  </Card>
                </Grid2>
              ))}
            </Grid2>
          </Grid2>
        </Grid2>
      </Grid2>
    </Grid2>
  );
};

export default WelcomePage;
