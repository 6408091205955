import { GridColDef } from "@mui/x-data-grid-pro";
import { GKDataGrid, Grid2, StatusField, Typography } from "components";
import useGenerateQueryParams from "hooks/useGenerateQueryParams";
import { useFetchQuery } from "hooks/useQueries";
import { useNavigate } from "react-router-dom";
import { moneyCommaSeparator } from "utils";
import { extraColumnsField, matchDate } from "utils/helpers";
import useDocumentTitle from "utils/useDocumentTitle";

const SubscriptionHistory = () => {
  useDocumentTitle("Subscriptions");

  const navigate = useNavigate();

  const requestBody = useGenerateQueryParams("subscribedHistory");

  const { data, isFetching } = useFetchQuery({
    key: ["GET_ALL_SUBSCRIBED_HISTORY", requestBody],
    route: "/subscription-history/",
    requestBody,
  });

  const columns: GridColDef[] = [
    {
      ...extraColumnsField("Portfolio Name", "clientName", 250, 1),
      renderCell: (params) => {
        return (
          <Grid2
            container
            display={"flex"}
            flexDirection={"column"}
            alignItems={"flex-start"}
          >
            <Typography
              fontWeight={700}
              maxWidth={"100%"}
              textOverflow={"ellipsis"}
              overflow={"hidden"}
            >
              {params?.row?.clientName}
            </Typography>
            <Typography fontSize={12}>
              {params?.row?.portfolioName} - {params?.row?.portfolioId}
            </Typography>
          </Grid2>
        );
      },
    },
    {
      ...extraColumnsField("Start Date", "subscriptionStartDate", 100, 1),
      type: "date",
      valueFormatter: (params) => matchDate(params?.value),
    },
    {
      ...extraColumnsField("Max Amount", "subscriptionAmount", 150, 1),
      valueGetter: (row) => row?.row?.subscriptionAmount,
      valueFormatter: (params) => moneyCommaSeparator(+params.value || 0),
      type: "number",
    },
    {
      ...extraColumnsField("Frequency", "subscriptionFrequency", 100, 1),
      valueGetter: (row) => row?.row?.subscriptionFrequency || "-",
    },
    {
      ...extraColumnsField("Status", "subscriptionStatus", 150, 1),
      renderCell: (row) => (
        <StatusField statusString={row?.row?.subscriptionStatus} />
      ),
    },
  ];

  return (
    <GKDataGrid
      loading={isFetching}
      columns={columns}
      rows={data?.data?.data || []}
      maxHeight={"calc(100vh - 321px)"}
      name="subscribedHistory"
      tableName="subscribed_history"
      columnsButton={false}
      totalCount={data?.data?.totalCount}
      onRowClick={(params) =>
        navigate(`/app/subscription-history/${params.row.id}/payment-history/`)
      }
    />
  );
};

export default SubscriptionHistory;
