import { blueGrey, grey } from "@mui/material/colors";
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  GKDatePicker,
  GKRadio,
  GKSearchSelect,
  GKTextField,
  GoInfo,
  Grid2,
  RadioGroup,
  TextField,
  Tooltip,
  Typography,
} from "components";
import GKImageUploader from "components/GKImageUploader";
import dayjs from "dayjs";
import { EquitybasketFormInterface } from "interfaces/EquityBasket";
import { isEmpty } from "lodash";
import { useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { INVESTMENT_STRATEGIES, minimumInvestmentData } from "utils/constants";
import { handleReturnAutocompletePlaceholder } from "utils/StringFunctions";
import { ThemeContainer } from "utils/Theme";
import {
  assetClassType,
  backetCategoriesData,
  basketTypeDropdown,
  comparableIndexData,
  subscriptionDropdown,
  timePerdioDropdown,
  volatilityDropdown,
} from "../equityBasketHelper";

const MetaTab = ({
  riskProfileDataLoading,
  riskProfileData,
  companyMasterData,
}: {
  riskProfileDataLoading: boolean;
  companyMasterData: any;
  riskProfileData: any;
}) => {
  const { theme } = ThemeContainer();

  const form = useFormContext<EquitybasketFormInterface>();

  const [selectedImage, setSelectedImage] = useState<string | null>(null);

  const [croppedImage, setCroppedImage] = useState<string | null>(null);

  const basketCategoryWatch = form.watch("basketCategory");

  return (
    <Grid2 container spacing={2}>
      <Grid2 size={4}>
        <Controller
          name="managementStyle"
          control={form.control}
          rules={{
            required: {
              value: true,
              message: "Select Advisor Types",
            },
          }}
          render={({ field, fieldState: { error } }) => {
            return (
              <GKSearchSelect
                {...field}
                requiredField
                disabled={!isEmpty(field.value)}
                onChange={(e: any, val) => field.onChange(val)}
                disableClearable={false}
                value={field.value}
                options={subscriptionDropdown}
                inputLabel="Select Advisor Types"
                renderInput={(params) => {
                  return (
                    <TextField
                      {...params}
                      placeholder={handleReturnAutocompletePlaceholder({
                        placeholder: "Select Advisor Types",
                        value: field.value,
                      })}
                      error={!!error}
                      helperText={error?.message}
                    />
                  );
                }}
              />
            );
          }}
        />
      </Grid2>
      <Grid2 size={4}>
        <Controller
          name="basketType"
          control={form.control}
          rules={{
            required: {
              value: true,
              message: "Select basket type",
            },
          }}
          render={({ field, fieldState: { error } }) => {
            return (
              <GKSearchSelect
                {...field}
                onChange={(e, val) => field.onChange(val?.value)}
                requiredField
                disableClearable={false}
                disabled={!isEmpty(field.value)}
                value={
                  basketTypeDropdown?.find(
                    (item) => item?.value === field.value,
                  )?.name || []
                }
                options={basketTypeDropdown}
                getOptionLabel={(opt) => opt?.name || opt}
                inputLabel="Basket Type"
                renderInput={(params) => {
                  return (
                    <TextField
                      {...params}
                      placeholder={handleReturnAutocompletePlaceholder({
                        placeholder: "Select Basket Type",
                        value: field.value,
                      })}
                      error={!!error}
                      helperText={error?.message}
                    />
                  );
                }}
              />
            );
          }}
        />
      </Grid2>
      <Grid2 size={4}>
        <Controller
          name="managedBy"
          control={form.control}
          render={({ field }) => {
            return (
              <GKTextField
                {...field}
                inputLabel="Managed By"
                placeholder="Managed By"
              />
            );
          }}
        />
      </Grid2>
      <Grid2 size={2}>
        <Controller
          name="basketCategory"
          control={form.control}
          render={({ field, fieldState: { error } }) => {
            return (
              <GKSearchSelect
                {...field}
                requiredField
                onChange={(e: any, val) => field.onChange(val.value)}
                disableClearable={false}
                getOptionLabel={(option) => option.name || option}
                value={
                  backetCategoriesData.find((d) => d.value === field?.value)
                    ?.name
                }
                options={backetCategoriesData}
                inputLabel="Select Basket Category"
                renderInput={(params) => {
                  return (
                    <TextField
                      {...params}
                      placeholder={handleReturnAutocompletePlaceholder({
                        placeholder: "Select Basket Category",
                        value: field.value,
                      })}
                      error={!!error}
                      helperText={error?.message}
                    />
                  );
                }}
              />
            );
          }}
        />
      </Grid2>
      <Grid2 size={4}>
        <Controller
          rules={{
            required: {
              value: true,
              message: "Please Enter Name",
            },
          }}
          name="name"
          control={form.control}
          render={({ field, fieldState: { error } }) => {
            return (
              <GKTextField
                {...field}
                requiredField
                inputLabel="Basket Name"
                placeholder="Basket Name"
                error={!!error}
                helperText={error?.message}
                slotProps={{
                  input: {
                    endAdornment: (
                      <Typography
                        color={grey[600]}
                        width={"-webkit-fill-available"}
                        textAlign={"right"}
                      >
                        {basketCategoryWatch || "Basket Category"}
                      </Typography>
                    ),
                  },
                }}
              />
            );
          }}
        />
      </Grid2>
      <Grid2 size={3}>
        <Controller
          name="code"
          control={form.control}
          rules={{
            required: {
              value: true,
              message: "Please Enter Name",
            },
          }}
          render={({ field, fieldState: { error } }) => {
            return (
              <GKTextField
                {...field}
                requiredField
                inputLabel="Basket Code"
                placeholder="Basket Code"
                error={!!error}
                helperText={error?.message}
              />
            );
          }}
        />
      </Grid2>
      <Grid2 size={3}>
        <Controller
          name="basketCreationDate"
          control={form.control}
          render={({ field }) => (
            <GKDatePicker
              {...field}
              inputLabel="Basket Creation Date"
              value={dayjs(field.value)}
              onChange={(value) => {
                field.onChange(dayjs(value).format("YYYY-MM-DD"));
              }}
              slotProps={{
                textField: {
                  error: false,
                  helperText: false,
                },
              }}
            />
          )}
        />
      </Grid2>
      <Grid2 size={12}>
        <Controller
          name="basketLogo"
          control={form.control}
          render={({ field }) => (
            <GKImageUploader
              croppedImage={croppedImage}
              handleProfilePicDelete={() => {
                field.onChange([]);
              }}
              selectedImage={selectedImage || form.getValues("basketLogo")}
              setCroppedImage={setCroppedImage}
              setSelectedImage={setSelectedImage}
              form={form}
              logoName={"basketLogo"}
              buttonName="Upload Basket Logo"
            />
          )}
        />
      </Grid2>
      <Grid2 size={12}>
        <Controller
          name="overview"
          control={form.control}
          render={({ field }) => {
            return (
              <GKTextField
                {...field}
                inputLabel="Overview"
                placeholder="Overview"
              />
            );
          }}
        />
      </Grid2>
      <Grid2 size={3}>
        <Controller
          name="riskProfile"
          control={form.control}
          rules={{
            required: {
              value: true,
              message: "Select risk profile",
            },
          }}
          render={({ field, fieldState: { error } }) => {
            return (
              <GKSearchSelect
                {...field}
                requiredField
                loading={riskProfileDataLoading}
                onChange={(e, val) => field.onChange([val])}
                options={riskProfileData?.data?.data?.data?.pointsDetails}
                disableClearable={false}
                inputLabel="Risk Profile"
                renderInput={(params) => {
                  return (
                    <TextField
                      {...params}
                      placeholder={handleReturnAutocompletePlaceholder({
                        placeholder: "Select Risk Profile",
                        value: field.value,
                      })}
                      error={!!error}
                      helperText={error?.message}
                    />
                  );
                }}
              />
            );
          }}
        />
      </Grid2>
      <Grid2 size={3}>
        <Controller
          name="volatility"
          control={form.control}
          render={({ field }) => {
            return (
              <GKSearchSelect
                {...field}
                onChange={(e, val) => {
                  field.onChange(val?.value);
                }}
                value={
                  volatilityDropdown?.find((da) => da?.value === field?.value)
                    ?.name || []
                }
                options={volatilityDropdown}
                disableClearable={false}
                getOptionLabel={(opt) => opt?.name || opt}
                inputLabel="Volatility"
                renderInput={(params) => {
                  return (
                    <TextField
                      {...params}
                      placeholder={handleReturnAutocompletePlaceholder({
                        placeholder: "Select Volatility",
                        value: field.value,
                      })}
                    />
                  );
                }}
              />
            );
          }}
        />
      </Grid2>
      <Grid2 size={3}>
        <Controller
          name="timePeriod"
          control={form.control}
          render={({ field }) => {
            return (
              <GKSearchSelect
                {...field}
                onChange={(e, val) => {
                  field.onChange(val?.value);
                }}
                value={
                  timePerdioDropdown?.find((da) => da?.value === field?.value)
                    ?.name || []
                }
                options={timePerdioDropdown}
                disableClearable={false}
                getOptionLabel={(opt) => opt?.name || opt}
                inputLabel="Time Horizon"
                renderInput={(params) => {
                  return (
                    <TextField
                      {...params}
                      placeholder={handleReturnAutocompletePlaceholder({
                        placeholder: "Select Time Horizon",
                        value: field.value,
                      })}
                    />
                  );
                }}
              />
            );
          }}
        />
      </Grid2>
      <Grid2 size={3}>
        <Controller
          name="minimumInvestmentValueCheck"
          control={form.control}
          render={({ field }) => {
            return (
              <GKSearchSelect
                {...field}
                onChange={(e, val) => {
                  field.onChange(val?.value);
                }}
                value={
                  minimumInvestmentData?.find(
                    (da) => da?.value === `${field?.value}`,
                  )?.name || []
                }
                options={minimumInvestmentData}
                disableClearable={false}
                getOptionLabel={(opt) => opt?.name || opt}
                inputLabel={"Minimum Investment Amount"}
                tooltipText="The minimum amount a subscriber can invest after subscribing to the basket."
                renderInput={(params) => {
                  return (
                    <TextField
                      {...params}
                      placeholder={handleReturnAutocompletePlaceholder({
                        placeholder: "Select Minimum Invested Amount",
                        value: field.value,
                      })}
                    />
                  );
                }}
              />
            );
          }}
        />
      </Grid2>
      <Grid2 size={12} textAlign={"end"}>
        <Divider />
      </Grid2>
      <Grid2 size={12} container>
        <Grid2 size={4}>
          <Controller
            name="constituentsType"
            control={form.control}
            render={({ field }) => {
              return (
                <FormControl>
                  <FormLabel>Constituents Type</FormLabel>
                  <RadioGroup {...field}>
                    <FormControlLabel
                      value="Stocks & ETFs"
                      control={
                        <GKRadio
                          checked
                          disableLeftPadding
                          value={"Stocks & ETFs"}
                        />
                      }
                      label="Stocks & ETFs"
                    />
                  </RadioGroup>
                </FormControl>
              );
            }}
          />{" "}
        </Grid2>
        <Grid2 size={4}>
          <Controller
            name="assetClassType"
            control={form.control}
            render={({ field }) => {
              return (
                <FormControl>
                  <FormLabel>Asset Class Type</FormLabel>
                  <RadioGroup {...field}>
                    {assetClassType?.map((dataItem) => {
                      return (
                        <FormControlLabel
                          key={dataItem.value}
                          value={dataItem.value}
                          control={
                            <GKRadio
                              disableLeftPadding
                              value={dataItem.value}
                              checked={field.value === dataItem.value}
                            />
                          }
                          label={
                            <Box display={"flex"} alignItems={"center"} gap={1}>
                              <Typography>{dataItem.name}</Typography>
                              <Tooltip title={dataItem.description}>
                                <Box display={"flex"} alignItems={"center"}>
                                  <GoInfo size={14} color={blueGrey[600]} />
                                </Box>
                              </Tooltip>
                            </Box>
                          }
                        />
                      );
                    })}
                  </RadioGroup>
                </FormControl>
              );
            }}
          />{" "}
        </Grid2>
        <Grid2 size={4}>
          <Controller
            name="comparableIndex"
            control={form.control}
            render={({ field }) => {
              return (
                <FormControl>
                  <FormLabel>Comparable Index</FormLabel>
                  <RadioGroup {...field}>
                    {comparableIndexData?.map((dataItem) => {
                      return (
                        <FormControlLabel
                          key={dataItem.value}
                          control={
                            <GKRadio
                              disableLeftPadding
                              value={dataItem.value}
                              checked={field.value === dataItem.value}
                            />
                          }
                          label={
                            <Box display={"flex"} alignItems={"center"} gap={1}>
                              <Typography>{dataItem.name}</Typography>
                              <Tooltip title={dataItem.description}>
                                <Box display={"flex"} alignItems={"center"}>
                                  <GoInfo size={14} color={blueGrey[600]} />
                                </Box>
                              </Tooltip>
                            </Box>
                          }
                        />
                      );
                    })}
                  </RadioGroup>
                </FormControl>
              );
            }}
          />{" "}
        </Grid2>
      </Grid2>
      <Grid2 size={12} textAlign={"end"}>
        <Divider />
      </Grid2>
      <Grid2 size={4}>
        <Controller
          name="benchmark"
          control={form.control}
          render={({ field }) => {
            return (
              <GKSearchSelect
                {...field}
                onChange={(e, val) => {
                  field.onChange(val?.value);
                }}
                value={
                  companyMasterData?.data?.data?.find(
                    (item: any) => item?.gridCode === field.value,
                  )?.companyName || []
                }
                options={companyMasterData?.data?.data?.map((item: any) => ({
                  ...item,
                  name: item?.companyName,
                  value: item?.gridCode,
                }))}
                getOptionLabel={(opt) => opt?.name || opt}
                disableClearable={false}
                inputLabel="Benchmark"
                renderInput={(params) => {
                  return (
                    <TextField
                      {...params}
                      placeholder={handleReturnAutocompletePlaceholder({
                        placeholder: "Select Benchmark",
                        value: field.value,
                      })}
                    />
                  );
                }}
              />
            );
          }}
        />
      </Grid2>
      <Grid2 size={12}>
        <Typography color={theme.palette.dark.main}>
          Investment Strategies{" "}
          <span
            style={{
              color: theme.palette.secondaryText.main,
              fontSize: 12,
            }}
          >
            (Select upto 3)
          </span>
        </Typography>
        <Typography fontSize={10} color={blueGrey[500]}>
          Note: Users can find your smallcase faster when searching for specific
          themes.
        </Typography>
        <Controller
          name="investmentStyle"
          control={form.control}
          render={({ field }) => {
            return (
              <>
                {INVESTMENT_STRATEGIES?.map((dataItem) => (
                  <Button
                    size="medium"
                    key={dataItem.value}
                    style={{ margin: 2 }}
                    variant={
                      field.value?.includes(dataItem.value)
                        ? "contained"
                        : "outlined"
                    }
                    color={
                      field.value?.includes(dataItem.value) ? "primary" : "dark"
                    }
                    onClick={() => {
                      const updatedOptions = form
                        .getValues("investmentStyle")
                        .includes(dataItem.value)
                        ? form
                            .getValues("investmentStyle")
                            .filter((item: any) => item !== dataItem.value)
                        : [
                            ...form.getValues("investmentStyle"),
                            dataItem.value,
                          ];
                      field?.onChange(updatedOptions);
                    }}
                  >
                    {dataItem?.name}
                  </Button>
                ))}
              </>
            );
          }}
        />
      </Grid2>
    </Grid2>
  );
};

export default MetaTab;
