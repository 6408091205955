import { LoadingButton } from "@mui/lab";
import { Grid2 } from "@mui/material";
import { useMutation } from "hooks/useQueries";
import axiosInstance from "utils/axiosInstance";
import { refetchQuery } from "utils/helpers";
import CompanyDetailsForm from "./CompanyDetailsForm";
import { CompanyData, CompanyGetByIdInterface } from "./helper";

interface Props {
  data: CompanyGetByIdInterface;
}

const FundamentalDetails = (props: Props) => {
  const { data } = props;

  const { mutate: handleUpdateCompany, isLoading } = useMutation({
    mutationKey: ["UPDATE_ADVISOR_COMPANY"],
    mutationFn: (values: CompanyData) =>
      axiosInstance.patch(`/advisor-cm/${data.data.gcode}/`, values),
    onSuccess: () => {
      refetchQuery("GET_PRIVATE_EQUITY_BY_ID");
    },
  });

  return (
    <Grid2 container spacing={2}>
      <Grid2 size={12}>
        <CompanyDetailsForm
          type={data?.data?.instType}
          companyData={data}
          isEdit
          onUpdate={handleUpdateCompany}
        />
      </Grid2>
      <Grid2
        size={12}
        display={"flex"}
        justifyContent={"flex-end"}
        alignItems={"center"}
      >
        <LoadingButton
          loading={isLoading}
          type="submit"
          size="medium"
          variant="contained"
          form="update-company-form"
        >
          Save
        </LoadingButton>
      </Grid2>
    </Grid2>
  );
};

export default FundamentalDetails;
