import { LoadingButton } from "@mui/lab";
import { useAppContext } from "AppContext";
import { Box, Button, DragDrop, Grid2, Modal, Typography } from "components";
import { useMutation } from "hooks/useQueries";
import { AdvisorOnboardFormInterface } from "interfaces/OnboardingInterface";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
import axiosInstance from "utils/axiosInstance";
import { GKModalProps } from "utils/commonInterface";
import { ThemeContainer } from "utils/Theme";

const UploadHoldingsModal = ({ setOpen, open }: GKModalProps) => {
  const {
    state: { companies },
  } = useAppContext();

  const { theme } = ThemeContainer();

  const [allFiles, setAllFiles] = useState([]);

  const [uploadError, setUploadError] = useState<any>();

  const form = useFormContext<AdvisorOnboardFormInterface>();

  const { mutate, isLoading } = useMutation({
    mutationKey: ["HANDLE_HOLDINGS_PARSING"],
    mutationFn: (data: any) =>
      axiosInstance.post(`/plans/parse-holdings/`, data),
    onSuccess: (response) => {
      const plansHoldings = response?.data?.data?.map((da: any, i: number) => {
        return {
          name: companies?.find((d) => da?.gridCode === d.value)?.name,
          quantity: da?.quantity || 0,
          gridCode: da?.gridCode,
          cmp: da?.cmp || 0,
          id: i,
        };
      });
      form.setValue("planDataObject.plansHoldings", plansHoldings);

      setOpen(false);
    },
  });

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      sx={{ bgcolor: "#F2F2F2" }}
      disableEscapeKeyDown
      component={"div"}
    >
      <Box
        sx={{
          display: "flex",
          height: "100vh",
          width: "100vw",
          justifyContent: "center",

          alignItems: {
            md: "center",
            xs: "flex-end",
          },
          borderRadius: 0,
        }}
      >
        <Grid2
          container
          sx={{
            width: {
              xs: "100%",
              md: "70%",
            },
          }}
          bgcolor={"#F2F2F2"}
          borderRadius={2}
        >
          <Grid2
            p={2}
            size={12}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
            bgcolor={"#F2F2F2"}
            borderRadius={"10px 10px 0 0"}
          >
            <Typography fontWeight={600}>Holdings Upload</Typography>
          </Grid2>
          <Grid2
            size={12}
            mx={1}
            bgcolor={theme.palette.light.main}
            border={`solid 1px #E2E8F0`}
            borderRadius={2}
            p={2}
          >
            <DragDrop
              supportName="CSV & XLSX"
              acceptType={".csv,.xlsx"}
              value={allFiles}
              multiple
              onChange={(e: any) => {
                setAllFiles(e);
              }}
              setUploadError={setUploadError}
              uploadError={uploadError}
            />
          </Grid2>
          <Grid2
            size={12}
            display={"flex"}
            justifyContent={"space-between"}
            gap={1}
            p={2}
          >
            <Button
              variant="outlined"
              color="dark"
              onClick={() => {
                setOpen(false);
              }}
            >
              Close
            </Button>
            <LoadingButton
              loading={isLoading}
              variant="contained"
              onClick={() => {
                const formData = new FormData();
                formData.append("file", allFiles[0]);
                mutate(formData);
              }}
            >
              Upload
            </LoadingButton>
          </Grid2>
        </Grid2>
      </Box>
    </Modal>
  );
};

export default UploadHoldingsModal;
