/* eslint-disable react/no-danger */
import { grey } from "@mui/material/colors";
import { Box, GKNoDataFound, GKTypography, Grid2, Show } from "components";
import { ViewEquityBasketInterface } from "interfaces/EquityBasket";
import { isEmpty } from "lodash";
import { AiOutlineSwap } from "react-icons/ai";
import { BiGlobeAlt } from "react-icons/bi";
import { LuSearchCheck } from "react-icons/lu";
import { MdOutlineAccountBalanceWallet } from "react-icons/md";
import { RiListSettingsLine } from "react-icons/ri";

interface Props {
  data: ViewEquityBasketInterface;
}

const MethodologiesTab = (props: Props) => {
  const { data: basketData } = props;

  const allDataArray = [
    {
      name: "Defining the Universe",
      value: basketData.methodologyUniverse,
      icon: BiGlobeAlt,
    },
    {
      name: "Research",
      value: basketData.methodologyResearch,
      icon: LuSearchCheck,
    },
    {
      name: "Constituent Screening",
      value: basketData.methodologyConstituentScreening,
      icon: RiListSettingsLine,
    },
    {
      name: "Weightage",
      value: basketData.methodologyWeightage,
      icon: MdOutlineAccountBalanceWallet,
    },
    {
      name: "Rebalance",
      value: basketData.methodologyRebalance,
      icon: AiOutlineSwap,
    },
    {
      name: "Asset Allocation",
      value: basketData.methodologyAllocation,
      icon: AiOutlineSwap,
    },
  ];

  const areValueEmpty =
    isEmpty(basketData.methodologyAllocation) &&
    isEmpty(basketData.methodologyRebalance) &&
    isEmpty(basketData.methodologyWeightage) &&
    isEmpty(basketData.methodologyConstituentScreening) &&
    isEmpty(basketData.methodologyResearch) &&
    isEmpty(basketData.methodologyUniverse);

  return (
    <Grid2 container>
      <Show.When isTrue={areValueEmpty}>
        <Grid2 size={12}>
          <GKNoDataFound />
        </Grid2>
      </Show.When>

      <Grid2 size={12}>
        {allDataArray?.map(
          (dataItem) =>
            dataItem?.value && (
              <Grid2 size={12}>
                <Box gap={1} alignItems={"center"} display={"flex"} mb={1}>
                  <dataItem.icon size={20} color={grey[500]} />
                  <GKTypography
                    color={grey[500]}
                    fontWeight={600}
                    fontSize={16}
                  >
                    {dataItem?.name}
                  </GKTypography>
                </Box>

                <div
                  className="ck"
                  dangerouslySetInnerHTML={{ __html: dataItem?.value || "-" }}
                />
              </Grid2>
            ),
        )}
      </Grid2>
    </Grid2>
  );
};

export default MethodologiesTab;
