import { LoadingButton } from "@mui/lab";
import { useAppContext } from "AppContext";
import {
  Box,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  GKRadio,
  GKSearchSelect,
  GKSelect,
  GKTextField,
  Grid2,
  MenuItem,
  PhoneInput,
  RadioGroup,
  Show,
  SimpleLoader,
  Switch,
  TextField,
  Typography,
} from "components";
import { useFetchQuery, useMutation } from "hooks/useQueries";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import axiosInstance from "utils/axiosInstance";
import {
  AGREEMENT_SIGNATURE_SOURCE,
  KYC_OPTIONS,
  ONBOARDING_DATE_SELECTION,
} from "utils/constants";
import { PAYMENT_METHOD } from "utils/helpers";
import { handleReturnAutocompletePlaceholder } from "utils/StringFunctions";
import { ThemeContainer } from "utils/Theme";
import { emailRegex } from "utils/validationSchema";

interface Props {
  type: string;
}

interface OnboardflowInterface {
  id?: number;
  flowType: string;
  kycIdentificationType: string;
  onboardingDate: string;
  kycType: string;
  agreementType: string;
  defaultClientSignType: string;
  defaultAdvisorSignType: string;
  twoWaySign: boolean;
  name: string;
  estampDescription: string;
  estampTag: string;
  frequency: string;
  countryCode: string;
  phoneNumber: string;
  email: string;
  paymentMethods: string;
  company: number;
  feePlan: number;
  agreementTemplate: string;
  revenueWithGst: boolean;
}

const SubscriptionFlow = (props: Props) => {
  const { type } = props;

  const { theme } = ThemeContainer();

  const {
    state: { userData },
  } = useAppContext();

  const [hasError, setHasError] = useState(false);

  const [customAgreementTemplate, setCustomAgreementTemplate] = useState([]);

  const { isFetching: customAgreementLoading } = useFetchQuery({
    key: ["ON_BOARDING_FLOW"],
    route: "/custom-agreement/",
    success: (response: any) => {
      setCustomAgreementTemplate(
        response?.data?.data.map((dataItem: { title: string; id: number }) => {
          return {
            name: dataItem.title,
            value: dataItem.id,
          };
        }),
      );
    },
  });

  const { data: feePlanData, isFetching: feeplanLoading } = useFetchQuery({
    key: ["ON_BOARDING_FEE_PLAN"],
    route: "/fee-plans/",
    requestBody: {
      revenue_products: JSON.stringify(["management_fee"]),
    },
  });

  const { data: estampTags } = useFetchQuery({
    key: ["ESTAMP_TAGS_QUERY_KEY"],
    route: "/estamp-tags/",
  });

  const form = useForm<OnboardflowInterface>({
    defaultValues: {
      flowType: type,
      kycIdentificationType: "only_kyc",
      kycType: "",
      agreementTemplate: "",
      agreementType: "standard",
      defaultAdvisorSignType: "",
      twoWaySign: false,
      name: "",
      countryCode: "+91",
      phoneNumber: "",
      defaultClientSignType: "",
      estampTag: "",
      estampDescription: "",
      email: "",
      frequency: "",
      onboardingDate: "",
      feePlan: null,
      paymentMethods: "",
      revenueWithGst: false,
    },
  });

  const { isFetching } = useFetchQuery({
    key: ["ON_BOARDING_FLOW", type],
    route: "/onboardflow/",
    requestBody: {
      flow_type: type,
    },
    success: (response: any) => {
      form.reset({ ...response?.data?.data, flowType: type });
    },
  });

  const twoWaySignWatch = form.watch("twoWaySign");

  const { mutate, isLoading } = useMutation({
    mutationFn: (data: OnboardflowInterface) =>
      axiosInstance.patch(`/onboardflow/${userData?.companyId}/`, data),
  });

  if (isFetching || feeplanLoading) return <SimpleLoader />;

  return (
    <form
      id="subscription-flow-form"
      onSubmit={form.handleSubmit((values) => mutate(values))}
    >
      <Grid2 container spacing={2}>
        <Grid2 size={3}>
          <Typography
            fontSize={16}
            fontWeight={500}
            color={theme.palette.secondaryText.main}
          >
            Onboarding Dashboard
          </Typography>
        </Grid2>
        <Grid2 size={9}>
          <Controller
            name="onboardingDate"
            control={form.control}
            rules={{
              required: {
                value: true,
                message: "Please select client onboarding date",
              },
            }}
            render={({ field, fieldState: { error } }) => (
              <GKSearchSelect
                {...field}
                requiredField
                inputLabel="Which date do you prefer to show onboarding date?"
                disableClearable={false}
                options={ONBOARDING_DATE_SELECTION}
                value={
                  ONBOARDING_DATE_SELECTION?.find(
                    (dataItem) => dataItem.value === field.value,
                  )?.name || ""
                }
                onChange={(event, val) => field.onChange(val?.value)}
                getOptionLabel={(option) => option.name || option}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder={handleReturnAutocompletePlaceholder({
                      placeholder: "Select advisor sign type",
                      value: field.value,
                    })}
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              />
            )}
          />
        </Grid2>
        <Grid2 size={3}>
          <Typography
            fontSize={16}
            fontWeight={500}
            color={theme.palette.secondaryText.main}
          >
            KYC
          </Typography>
        </Grid2>

        <Grid2 container size={9} spacing={2}>
          <Grid2 size={12}>
            <Controller
              name="kycType"
              control={form.control}
              render={({ field }) => (
                <FormControl>
                  <FormLabel sx={{ color: theme.palette.dark.main }}>
                    Mode Of KYC
                  </FormLabel>
                  <RadioGroup
                    {...field}
                    row
                    onChange={(e) => field.onChange(e.target.value)}
                  >
                    <FormControlLabel
                      label="Only KRA"
                      value="only_kra"
                      checked={field.value === "only_kra"}
                      control={<GKRadio disableLeftPadding />}
                    />
                    <FormControlLabel
                      label="If KRA not found then KYC"
                      value="if_kra_not_found_then_kyc"
                      checked={field.value === "if_kra_not_found_then_kyc"}
                      control={<GKRadio />}
                    />
                    <FormControlLabel
                      label="KRA+KYC"
                      value="kra_and_kyc"
                      checked={field.value === "kra_and_kyc"}
                      control={<GKRadio />}
                    />
                    <FormControlLabel
                      label="Only KYC"
                      value="only_kyc"
                      checked={field.value === "only_kyc"}
                      control={<GKRadio />}
                    />
                  </RadioGroup>
                </FormControl>
              )}
            />
          </Grid2>
          <Grid2 size={6}>
            <Controller
              name="kycIdentificationType"
              control={form.control}
              render={({ field }) => (
                <GKSelect {...field} inputLabel="KYC Type">
                  <MenuItem style={{ display: "none" }} value="">
                    Select Identification Type
                  </MenuItem>
                  {KYC_OPTIONS?.map((data) => (
                    <MenuItem value={data?.value} key={data?.value}>
                      {data?.name}
                    </MenuItem>
                  ))}
                </GKSelect>
              )}
            />
          </Grid2>
        </Grid2>

        <Grid2 size={12}>
          <Divider />
        </Grid2>

        <Grid2 size={3}>
          <Typography
            fontSize={16}
            fontWeight={500}
            color={theme.palette.secondaryText.main}
          >
            Agreement
          </Typography>
        </Grid2>

        <Grid2 container size={9} spacing={2}>
          <Grid2 size={4}>
            <Controller
              name="agreementTemplate"
              control={form.control}
              rules={{
                required: {
                  value: true,
                  message: "Please select agreement template",
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <GKSearchSelect
                  {...field}
                  requiredField
                  loading={customAgreementLoading}
                  inputLabel="Agreement Template"
                  disableClearable={false}
                  options={customAgreementTemplate}
                  value={
                    customAgreementTemplate?.find(
                      (dataItem) => dataItem.value === field.value,
                    )?.name || ""
                  }
                  onChange={(event, val) => field.onChange(val?.value)}
                  getOptionLabel={(option) => option.name || option}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder={handleReturnAutocompletePlaceholder({
                        placeholder: "Select agreement template",
                        value: field.value,
                      })}
                      error={!!error}
                      helperText={error?.message}
                    />
                  )}
                />
              )}
            />
          </Grid2>
          <Grid2 size={8}>
            <Typography>Agreement Type</Typography>
            <Box sx={{ display: "flex" }}>
              <Controller
                name="agreementType"
                control={form.control}
                render={({ field }) => (
                  <FormControl>
                    <RadioGroup
                      {...field}
                      row
                      onChange={(e) => field.onChange(e.target.value)}
                    >
                      <FormControlLabel
                        label="Standard"
                        value="standard"
                        control={<GKRadio disableLeftPadding />}
                      />
                      <FormControlLabel
                        label="Stamp"
                        value="stamp"
                        control={<GKRadio />}
                      />
                    </RadioGroup>
                  </FormControl>
                )}
              />
            </Box>
          </Grid2>
          <Show.When isTrue={form.watch("agreementType") === "stamp"}>
            <Grid2 size={12}>
              <Controller
                name="estampTag"
                control={form.control}
                rules={{
                  required: {
                    value: form.watch("agreementType") === "stamp",
                    message: "Please select an estamp",
                  },
                }}
                render={({ field, fieldState: { error } }) => (
                  <GKSearchSelect
                    {...field}
                    requiredField
                    inputLabel="Select Estamp"
                    disableClearable={false}
                    options={estampTags?.data?.data?.map((d: any) => {
                      return {
                        ...d,
                        label: d.tags,
                        value: d.id,
                      };
                    })}
                    value={
                      estampTags?.data?.data?.find(
                        (dataItem: any) => dataItem.id === field.value,
                      )?.tags || ""
                    }
                    onChange={(event, val) => field.onChange(val?.value)}
                    getOptionLabel={(option) => option.label || option}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder={handleReturnAutocompletePlaceholder({
                          placeholder: "Select advisor sign type",
                          value: field.value,
                        })}
                        error={!!error}
                        helperText={error?.message}
                      />
                    )}
                  />
                )}
              />
            </Grid2>
          </Show.When>
          <Grid2 size={12}>
            <Divider />
          </Grid2>
          <Grid2 size={12}>
            <Controller
              control={form.control}
              name="twoWaySign"
              render={({ field }) => (
                <FormControlLabel
                  control={
                    <Switch
                      checked={field.value}
                      onChange={(event) => field.onChange(event.target.checked)}
                    />
                  }
                  labelPlacement="end"
                  label={
                    <Typography fontSize={16} color="#121926">
                      2-way signing (Client & Advisor)
                    </Typography>
                  }
                />
              )}
            />
          </Grid2>
          <Grid2 size={6}>
            <Controller
              name="defaultClientSignType"
              control={form.control}
              rules={{
                required: {
                  value: true,
                  message: "Please select client sign type",
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <GKSearchSelect
                  {...field}
                  requiredField
                  inputLabel="Default Client sign type"
                  disableClearable={false}
                  options={AGREEMENT_SIGNATURE_SOURCE?.filter(
                    (d) => d.value === "aadhaar",
                  )}
                  value={
                    AGREEMENT_SIGNATURE_SOURCE?.find(
                      (dataItem) => dataItem.value === field.value,
                    )?.name || ""
                  }
                  onChange={(event, val) => field.onChange(val?.value)}
                  getOptionLabel={(option) => option.name || option}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder={handleReturnAutocompletePlaceholder({
                        placeholder: "Select advisor sign type",
                        value: field.value,
                      })}
                      error={!!error}
                      helperText={error?.message}
                    />
                  )}
                />
              )}
            />
          </Grid2>
          <Show.When isTrue={twoWaySignWatch}>
            <Grid2 size={6}>
              <Controller
                name="defaultAdvisorSignType"
                control={form.control}
                rules={{
                  required: {
                    value: true,
                    message: "Please select advisor sign type",
                  },
                }}
                render={({ field, fieldState: { error } }) => (
                  <GKSearchSelect
                    {...field}
                    requiredField
                    inputLabel="Default Advisor sign type"
                    disableClearable={false}
                    options={AGREEMENT_SIGNATURE_SOURCE?.filter(
                      (d) => d.value === "aadhaar",
                    )}
                    value={
                      AGREEMENT_SIGNATURE_SOURCE?.find(
                        (dataItem) => dataItem.value === field.value,
                      )?.name || ""
                    }
                    onChange={(event, val) => field.onChange(val?.value)}
                    getOptionLabel={(option) => option.name || option}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder={handleReturnAutocompletePlaceholder({
                          placeholder: "Select advisor sign type",
                          value: field.value,
                        })}
                        error={!!error}
                        helperText={error?.message}
                      />
                    )}
                  />
                )}
              />
            </Grid2>
          </Show.When>

          <Grid2 size={12}>
            <Typography color="#697586">
              Advisor Communication Details
            </Typography>
          </Grid2>

          <Grid2 size={4}>
            <Controller
              name="name"
              control={form.control}
              render={({ field, fieldState: { error } }) => (
                <GKTextField
                  {...field}
                  inputLabel="Name"
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
          </Grid2>
          <Grid2 size={4}>
            <Controller
              name="phoneNumber"
              control={form.control}
              render={({ field, fieldState: { error } }) => (
                <PhoneInput
                  {...field}
                  onSelectChange={(event: any, val: any) => {
                    form.setValue("countryCode", val?.callingCodes);
                  }}
                  onTextChange={(event: any) => {
                    field.onChange(event.target.value);
                  }}
                  onTextValue={field.value}
                  onSelectValue={form.getValues("countryCode")}
                  hasError={hasError}
                  setHasError={setHasError}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
          </Grid2>
          <Grid2 size={4}>
            <Controller
              name="email"
              control={form.control}
              rules={{
                pattern: {
                  value: emailRegex,
                  message: "Please enter a valid email",
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <GKTextField
                  {...field}
                  inputLabel="Email"
                  error={!!error}
                  helperText={!!error && error?.message}
                />
              )}
            />
          </Grid2>
        </Grid2>

        <Grid2 size={12}>
          <Divider />
        </Grid2>

        <Grid2 size={3}>
          <Typography
            fontSize={16}
            fontWeight={500}
            color={theme.palette.secondaryText.main}
          >
            Payment
          </Typography>
        </Grid2>

        <Grid2 container size={9} spacing={2}>
          <Grid2 size={6}>
            <Controller
              name="paymentMethods"
              control={form.control}
              rules={{
                required: {
                  value: true,
                  message: "Please select payment collection method",
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <GKSearchSelect
                  {...field}
                  requiredField
                  inputLabel="Payment Collection Method"
                  disableClearable={false}
                  options={PAYMENT_METHOD}
                  value={
                    PAYMENT_METHOD?.find(
                      (dataItem) => dataItem.value === field.value,
                    )?.name || ""
                  }
                  onChange={(event, val) => {
                    field.onChange(val?.value);
                  }}
                  getOptionLabel={(option) => option.name || option}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder={handleReturnAutocompletePlaceholder({
                        placeholder: "Select payment collection method",
                        value: field.value,
                      })}
                      error={!!error}
                      helperText={error?.message}
                    />
                  )}
                />
              )}
            />
          </Grid2>
          <Grid2 size={12}>
            <Controller
              name="revenueWithGst"
              control={form.control}
              render={({ field }) => (
                <FormControl>
                  <FormLabel>Revenue with GST</FormLabel>
                  <RadioGroup
                    {...field}
                    row
                    onChange={(e) => field.onChange(e.target.value === "true")}
                  >
                    <FormControlLabel
                      label="Yes"
                      value="true"
                      control={<GKRadio disableLeftPadding />}
                    />
                    <FormControlLabel
                      label="No"
                      value="false"
                      control={<GKRadio />}
                    />
                  </RadioGroup>
                </FormControl>
              )}
            />
          </Grid2>
        </Grid2>

        <Grid2 size={12}>
          <Divider />
        </Grid2>
        <Grid2 size={3}>
          <Typography
            fontSize={16}
            fontWeight={500}
            color={theme.palette.secondaryText.main}
          >
            Advance Fees{" "}
          </Typography>
        </Grid2>

        <Grid2 container size={9} spacing={2}>
          <Grid2 size={6}>
            <Controller
              name="feePlan"
              control={form.control}
              render={({ field }) => {
                return (
                  <GKSearchSelect
                    {...field}
                    inputLabel="Advance Fee Plan"
                    onChange={(e, val) => {
                      field.onChange(val.value);
                    }}
                    value={
                      feePlanData?.data?.data?.find(
                        (dataItem: any) => dataItem.id === field.value,
                      )?.name
                    }
                    options={
                      feePlanData?.data?.data?.map((d: any) => {
                        return {
                          label: d.name,
                          value: d.id,
                          ...d,
                        };
                      }) || []
                    }
                    renderInput={(p) => (
                      <TextField {...p} placeholder="Select Advance Fee Plan" />
                    )}
                  />
                );
              }}
            />
          </Grid2>
          <Grid2 size={6}>
            <Controller
              name="frequency"
              control={form.control}
              render={({ field }) => {
                return (
                  <GKSearchSelect
                    {...field}
                    inputLabel="Fee Plan Frequency"
                    onChange={(e, val) => {
                      field.onChange(val.stringVaue);
                    }}
                    value={
                      [
                        {
                          name: "Monthly",
                          stringVaue: "MONTHLY",
                          value: 12,
                        },
                        {
                          name: "Quarterly",
                          stringVaue: "QUARTERLY",
                          value: 4,
                        },
                        {
                          name: "Semiannually",
                          stringVaue: "SEMIANNUALLY",
                          value: 2,
                        },
                        {
                          name: "Yearly",
                          stringVaue: "YEARLY",
                          value: 1,
                        },
                      ]?.find(
                        (dataItem: any) => dataItem.stringVaue === field.value,
                      )?.name
                    }
                    options={
                      [
                        {
                          name: "Monthly",
                          stringVaue: "MONTHLY",
                          value: 12,
                        },
                        {
                          name: "Quarterly",
                          stringVaue: "QUARTERLY",
                          value: 4,
                        },
                        {
                          name: "Semiannually",
                          stringVaue: "SEMIANNUALLY",
                          value: 2,
                        },
                        {
                          name: "Yearly",
                          stringVaue: "YEARLY",
                          value: 1,
                        },
                      ]?.map((d: any) => {
                        return {
                          label: d.name,
                          value: d.stringVaue,
                          ...d,
                        };
                      }) || []
                    }
                    renderInput={(p) => (
                      <TextField
                        {...p}
                        placeholder="Select Fee Plan Frequence"
                      />
                    )}
                  />
                );
              }}
            />
          </Grid2>
        </Grid2>

        <Grid2 size={12} textAlign={"right"}>
          <LoadingButton
            form="subscription-flow-form"
            variant="contained"
            type="submit"
            loading={isLoading}
            disabled={hasError}
          >
            Save
          </LoadingButton>
        </Grid2>
      </Grid2>
    </form>
  );
};

export default SubscriptionFlow;
