import {
  Button,
  FormControlLabel,
  GKTextField,
  Grid2,
  Switch,
  Typography,
} from "components";
import useAccountSetting from "Pages/AccountSetting/useAccountSetting";
import { Controller, useForm } from "react-hook-form";
import useDocumentTitle from "utils/useDocumentTitle";

const EquityBasketSetting = () => {
  useDocumentTitle("Basket");

  const { getCompanySetting, handleUpdateCompanySetting } = useAccountSetting();

  const form = useForm({
    defaultValues: {
      orderPriceRangePercentage: 0,
      capToLedgerBalance: false,
      useBrokerBalance: false,
      useCeAmount: false,
    },
    values: {
      orderPriceRangePercentage:
        getCompanySetting?.data?.orderPriceRangePercentage,
      capToLedgerBalance: getCompanySetting?.data?.capToLedgerBalance,
      useBrokerBalance: getCompanySetting?.data?.useBrokerBalance,
      useCeAmount: getCompanySetting?.data?.useCeAmount,
    },
  });

  return (
    <form
      id="equity-basket-setting-form"
      onSubmit={form.handleSubmit((values) =>
        handleUpdateCompanySetting(values),
      )}
    >
      <Grid2 container spacing={2}>
        <Grid2 size={12} display={"flex"} alignItems={"center"} gap={1}>
          <Controller
            name="capToLedgerBalance"
            control={form.control}
            render={({ field }) => (
              <FormControlLabel
                control={
                  <Switch
                    {...field}
                    checked={field.value}
                    onChange={(e) => {
                      field.onChange(e.target.checked);
                    }}
                  />
                }
                label="Limit allocation amount to ledger balance"
              />
            )}
          />
        </Grid2>
        <Grid2 size={12} display={"flex"} alignItems={"center"} gap={1}>
          <Controller
            name="useBrokerBalance"
            control={form.control}
            render={({ field }) => (
              <FormControlLabel
                control={
                  <Switch
                    {...field}
                    checked={field.value}
                    onChange={(e) => {
                      field.onChange(e.target.checked);
                    }}
                  />
                }
                label="Use available margin for allocation/rebalance/exit"
              />
            )}
          />
        </Grid2>
        <Grid2 size={12}>
          <Controller
            name="useCeAmount"
            control={form.control}
            render={({ field }) => {
              return (
                <FormControlLabel
                  control={
                    <Switch
                      {...field}
                      checked={field.value}
                      onChange={(e) => {
                        field.onChange(e.target.checked);
                      }}
                    />
                  }
                  label="Use cash equivalents as cash while allocation"
                />
              );
            }}
          />
        </Grid2>
        <Grid2 size={12} display={"flex"} alignItems={"center"} gap={2}>
          <Typography width={"70%"}>
            Price range percentage for sending orders to clients
          </Typography>

          <Controller
            name="orderPriceRangePercentage"
            control={form.control}
            render={({ field }) => (
              <GKTextField
                {...field}
                sx={{ width: "20%" }}
                type="number"
                inputLabel={""}
                onChange={(e: any) => field.onChange(e.target.valueAsNumber)}
              />
            )}
          />
        </Grid2>
        <Grid2 size={12} display={"flex"} justifyContent={"flex-end"}>
          <Button variant="contained" type="submit">
            Save
          </Button>
        </Grid2>
      </Grid2>
    </form>
  );
};

export default EquityBasketSetting;
