import {
  Divider,
  GKTextField,
  Grid2,
  IconButton,
  MdAdd,
  MdDeleteOutline,
  Show,
  Typography,
} from "components";
import { AdvisorOnboardFormInterface } from "interfaces/OnboardingInterface";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { ThemeContainer } from "utils/Theme";

const AdvisorOnboardingClientDemat = () => {
  const form = useFormContext<AdvisorOnboardFormInterface>();

  const { theme } = ThemeContainer();

  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: "dematAccounts",
  });

  return (
    <Grid2 container spacing={1}>
      <Grid2 size={12}>
        <Show.When isTrue={fields.length === 0}>
          <Typography color={theme.palette.secondaryText.main} fontSize={12}>
            *Please add a bank details by clicking below button.
          </Typography>
        </Show.When>
        {fields.map((demat: any, index: number) => (
          <Grid2 container size={12} spacing={1} my={1} key={index}>
            <Grid2 size={2.6}>
              <Controller
                name={`dematAccounts.${index}.brokerName`}
                control={form.control}
                render={({ field }) => (
                  <GKTextField {...field} inputLabel="Demat Broker Name" />
                )}
              />
            </Grid2>
            <Grid2 size={2.6}>
              <Controller
                name={`dematAccounts.${index}.dematId`}
                control={form.control}
                render={({ field }) => (
                  <GKTextField {...field} inputLabel="Demat Id" />
                )}
              />
            </Grid2>
            <Grid2 size={2.6}>
              <Controller
                name={`dematAccounts.${index}.uniqClientCode`}
                control={form.control}
                render={({ field }) => (
                  <GKTextField
                    {...field}
                    inputLabel="UCC"
                    placeholder="Like UCC of Demat A/c"
                  />
                )}
              />
            </Grid2>
            <Grid2 size={2.6}>
              <Controller
                name={`dematAccounts.${index}.depositary`}
                control={form.control}
                render={({ field }) => (
                  <GKTextField
                    {...field}
                    inputLabel="Depository"
                    placeholder="Like NSDL, CDSL"
                  />
                )}
              />
            </Grid2>
            <Grid2 size={1.6} textAlign={"right"} alignSelf={"center"} mt={2}>
              <IconButton
                color="error"
                disabled={fields.length === 1}
                onClick={() => {
                  remove(index);
                }}
              >
                <MdDeleteOutline />
              </IconButton>
            </Grid2>
          </Grid2>
        ))}
      </Grid2>
      <Grid2 size={12}>
        <Divider>
          <IconButton
            onClick={() =>
              append({
                brokerName: "",
                dematId: "",
                uniqClientCode: "",
                depositary: "",
              })
            }
          >
            <MdAdd />
          </IconButton>
        </Divider>
      </Grid2>
    </Grid2>
  );
};

export default AdvisorOnboardingClientDemat;
