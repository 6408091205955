import { Grid2, TextField, Typography } from "@mui/material";
import { GKSearchSelect } from "components";
import GKTextField from "components/GKTextField";
import { Controller, useFormContext } from "react-hook-form";
import { FeeType } from "utils/constants";
import { handleReturnAutocompletePlaceholder } from "utils/StringFunctions";

const AdvanceDeposit = () => {
  const form = useFormContext();

  return (
    <Grid2 container rowSpacing={2} spacing={1} size={12}>
      <Grid2 size={12}>
        <Typography fontSize={18} fontWeight={500}>
          Advance Deposit
        </Typography>
      </Grid2>
      <Grid2 size={6}>
        <Controller
          name="advanceDeposit.productName"
          control={form.control}
          render={({ field }) => (
            <GKTextField {...field} inputLabel={"productName"} />
          )}
        />
      </Grid2>
      <Grid2 size={6}>
        <Controller
          name="advanceDeposit.hsnCode"
          control={form.control}
          render={({ field }) => (
            <GKTextField
              {...field}
              inputLabel={"HSN Code"}
              tooltipTitle="This code will appear on invoices to classify your product for tax compliance."
            />
          )}
        />
      </Grid2>
      <Grid2 size={4}>
        <Controller
          name="advanceDeposit.fee"
          control={form.control}
          rules={{
            required: {
              value: true,
              message: "Please enter fee",
            },
          }}
          render={({ field, fieldState: { error } }) => (
            <GKTextField
              {...field}
              inputLabel="fee"
              type="number"
              error={!!error}
              helperText={error?.message}
            />
          )}
        />
      </Grid2>
      <Grid2 size={4}>
        <Controller
          name="advanceDeposit.feeType"
          control={form.control}
          rules={{
            required: {
              value: true,
              message: "Please select a Fee Type",
            },
          }}
          render={({ field, fieldState: { error } }) => (
            <GKSearchSelect
              {...field}
              requiredField
              inputLabel="Fee Type"
              disableClearable={false}
              options={FeeType || []}
              onChange={(e, val) => field.onChange(val?.value)}
              value={
                FeeType.find((dataItem) => dataItem.value === field.value) || ""
              }
              getOptionLabel={(option) => option.name || option}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name="managementFee.feeType"
                  placeholder={handleReturnAutocompletePlaceholder({
                    placeholder: "Select Fee Type",
                    value: field.value,
                  })}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
          )}
        />
      </Grid2>
    </Grid2>
  );
};

export default AdvanceDeposit;
