import {
  Divider,
  GKSelect,
  GKTextField,
  Grid2,
  IconButton,
  MdAdd,
  MdDeleteOutline,
  MenuItem,
  Show,
  Typography,
} from "components";
import { AdvisorOnboardFormInterface } from "interfaces/OnboardingInterface";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { BANK_ACCOUNT_TYPES } from "utils/constants";
import { ThemeContainer } from "utils/Theme";

const AdvisorOnboardingClientBank = () => {
  const form = useFormContext<AdvisorOnboardFormInterface>();

  const { theme } = ThemeContainer();

  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: "bankAccounts",
  });

  return (
    <Grid2 size={12} container spacing={1}>
      <Show.When isTrue={fields.length === 0}>
        <Typography color={theme.palette.secondaryText.main} fontSize={12}>
          *Please add a bank details by clicking below button.
        </Typography>
      </Show.When>
      <Grid2 size={12}>
        {fields?.map((row: any, index) => {
          return (
            <Grid2 container size={12} spacing={1} my={1} key={row.id}>
              <Grid2 size={2.6}>
                <Controller
                  name={`bankAccounts.${index}.name`}
                  control={form.control}
                  render={({ field }) => (
                    <GKTextField {...field} inputLabel="Bank Account Name" />
                  )}
                />
              </Grid2>
              <Grid2 size={2.6}>
                <Controller
                  name={`bankAccounts.${index}.accountNum`}
                  control={form.control}
                  render={({ field }) => (
                    <GKTextField {...field} inputLabel="Bank Account Number" />
                  )}
                />
              </Grid2>
              <Grid2 size={2.6}>
                <Controller
                  name={`bankAccounts.${index}.type`}
                  control={form.control}
                  render={({ field }) => (
                    <GKSelect {...field} inputLabel="Bank Account Type">
                      <MenuItem style={{ display: "none" }} value="">
                        Select Account Type
                      </MenuItem>
                      {BANK_ACCOUNT_TYPES?.map((dataItem) => (
                        <MenuItem value={dataItem?.value} key={dataItem?.value}>
                          {dataItem?.name}
                        </MenuItem>
                      ))}
                    </GKSelect>
                  )}
                />
              </Grid2>
              <Grid2 size={2.6}>
                <Controller
                  name={`bankAccounts.${index}.ifsc`}
                  control={form.control}
                  render={({ field }) => (
                    <GKTextField {...field} inputLabel="IFSC Code" />
                  )}
                />
              </Grid2>
              <Grid2 size={1.6} textAlign={"right"} mt={2} alignSelf={"center"}>
                <IconButton
                  color="error"
                  disabled={fields.length === 1}
                  onClick={() => {
                    remove(index);
                  }}
                >
                  <MdDeleteOutline />
                </IconButton>
              </Grid2>
            </Grid2>
          );
        })}
      </Grid2>
      <Grid2 size={12}>
        <Divider>
          <IconButton
            onClick={() =>
              append({
                name: "",
                accountNum: "",
                type: "",
                ifsc: "",
              })
            }
          >
            <MdAdd />
          </IconButton>
        </Divider>
      </Grid2>
    </Grid2>
  );
};

export default AdvisorOnboardingClientBank;
