import { useAppContext } from "AppContext";
import { GKSearchSelect, GKTextField, Grid2, TextField } from "components";
import { Controller, useFormContext } from "react-hook-form";
import { getActiveBrokers } from "utils/helpers";
import { handleReturnAutocompletePlaceholder } from "utils/StringFunctions";

const BasicInfoTab = ({ isDisable }: { isDisable: boolean }) => {
  const form = useFormContext();

  const {
    state: {
      clientList,
      partnerList,
      userData: { brokerSupport },
    },
  } = useAppContext();

  return (
    <Grid2 container spacing={1}>
      <Grid2 size={2.4}>
        <Controller
          name="portfolio.name"
          control={form.control}
          render={({ field }) => (
            <GKTextField
              {...field}
              inputLabel="Portfolio name"
              slotProps={{
                htmlInput: {
                  readOnly: isDisable,
                },
              }}
            />
          )}
        />
      </Grid2>
      <Grid2 size={2.4}>
        <Controller
          name="portfolio.portfolioId"
          control={form.control}
          render={({ field }) => (
            <GKTextField
              {...field}
              inputLabel="Portfolio Code"
              slotProps={{
                htmlInput: {
                  readOnly: isDisable,
                },
              }}
            />
          )}
        />
      </Grid2>
      <Grid2 size={2.4}>
        <Controller
          name="portfolio.committedValue"
          control={form.control}
          render={({ field }) => (
            <GKTextField
              {...field}
              inputLabel="Committed Value"
              type="number"
              slotProps={{
                htmlInput: {
                  readOnly: isDisable,
                },
              }}
            />
          )}
        />
      </Grid2>
      <Grid2 size={2.4}>
        <Controller
          name="portfolio.client"
          control={form.control}
          render={({ field }) => (
            <GKSearchSelect
              {...field}
              disableClearable
              options={clientList || []}
              getOptionLabel={(option) => option?.name || option}
              renderInput={(params) => <TextField {...params} />}
              inputLabel="Client"
              onChange={(event, val) => field.onChange(val?.id)}
              value={
                clientList?.find((data: any) => data?.id === field.value)?.name
              }
              readOnly={isDisable}
            />
          )}
        />
      </Grid2>
      <Grid2 size={2.4}>
        <Controller
          name="portfolio.partners"
          control={form.control}
          render={({ field }) => (
            <GKSearchSelect
              inputLabel="Partner"
              options={partnerList || []}
              value={
                partnerList?.filter((option: { value: string | number }) =>
                  field.value?.includes(+option.value),
                )[0]?.name || []
              }
              onChange={(e, val) => {
                field.onChange(val?.value ? [val.value] : []);
              }}
              getOptionLabel={(option) => option.name || option}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder={handleReturnAutocompletePlaceholder({
                    placeholder: "Select Partner",
                    value: field.value,
                  })}
                />
              )}
            />
          )}
        />
      </Grid2>
      {getActiveBrokers(brokerSupport).length > 1 && (
        <Grid2 size={6}>
          <Controller
            name="portfolio.broker"
            control={form.control}
            render={({ field }) => (
              <GKSearchSelect
                disableClearable
                inputLabel="Select Broker"
                options={getActiveBrokers(brokerSupport)}
                value={
                  getActiveBrokers(brokerSupport).find(
                    (broker) => broker.value === field.value,
                  )?.name || ""
                }
                onChange={(e, val) => field.onChange(val?.value)}
                getOptionLabel={(option) => option.name || option}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder={handleReturnAutocompletePlaceholder({
                      placeholder: "Select Broker",
                      value: field.value,
                    })}
                  />
                )}
              />
            )}
          />
        </Grid2>
      )}
    </Grid2>
  );
};

export default BasicInfoTab;
