import {
  Box,
  Button,
  Divider,
  DragDrop,
  GKModal,
  Grid2,
  Typography,
} from "components";
import { AdvisorCompliaceInterface } from "interfaces/SettingsInterface";
import { useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { GKModalProps } from "utils/commonInterface";

const AuditFilesUpload = ({
  setOpen,
  open,
  index,
}: GKModalProps & { index: number }) => {
  const form = useFormContext<AdvisorCompliaceInterface>();

  const [uploadError, setUploadError] = useState<string | null>(null);

  form.watch();

  return (
    <GKModal
      open={open}
      setOpen={setOpen}
      modalTitle="Upload Audit Files"
      footer={
        <Box display={"flex"} justifyContent={"flex-end"} gap={1}>
          <Button
            variant="outlined"
            onClick={() => {
              setOpen(false);
              form.setValue(`complianceAudit.${index}.report1`, []);
              form.setValue(`complianceAudit.${index}.report2`, []);
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              setOpen(false);
            }}
          >
            Save
          </Button>
        </Box>
      }
    >
      <Grid2 container>
        <Grid2 size={12}>
          <Typography>Audit Report</Typography>
          <Controller
            name={`complianceAudit.${index}.report1`}
            control={form.control}
            rules={{
              required: {
                value: true,
                message: "Please upload a document",
              },
            }}
            render={({ field, fieldState: { error } }) => (
              <DragDrop
                {...field}
                uploadError={uploadError}
                setUploadError={setUploadError}
                value={field.value}
                onChange={(e) => {
                  field.onChange(e);
                }}
                supportName=".png, .jpg, .pdf"
                acceptType={".pdf,.png,.jpg"}
                error={!!error}
              />
            )}
          />
        </Grid2>
        <Grid2 size={12}>
          <Divider />
        </Grid2>
        <Grid2 size={12}>
          <Typography>Adverse Findings</Typography>
          <Controller
            name={`complianceAudit.${index}.report2`}
            control={form.control}
            rules={{
              required: {
                value: true,
                message: "Please upload a document",
              },
            }}
            render={({ field, fieldState: { error } }) => (
              <DragDrop
                uploadError={uploadError}
                setUploadError={setUploadError}
                {...field}
                onChange={(e) => {
                  field.onChange(e);
                }}
                supportName=".png, .jpg, .pdf"
                acceptType={".pdf,.png,.jpg"}
                error={!!error}
              />
            )}
          />
        </Grid2>
      </Grid2>
    </GKModal>
  );
};

export default AuditFilesUpload;
