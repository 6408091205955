import { load } from "@cashfreepayments/cashfree-js";
import {
  BiBasket,
  Divider,
  GKButton,
  Grid2,
  LuCalendarClock,
  Typography,
} from "components";
import useEnvironmentCheck from "hooks/useEnvironmentCheck";
import { useMutation } from "hooks/useQueries";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import axiosInstance from "utils/axiosInstance";
// import { ThemeContainer } from "utils/Theme";
import { useAppContext } from "AppContext";
import { isEmpty, sum } from "lodash";
import { useLocation } from "react-router-dom";
import { numberToCurrency } from "utils";
import { daysCalculate } from "./utils";

const PaymentTab = () => {
  const form = useFormContext();

  const location = useLocation();

  const {
    state: { investorOnBoardflowData },
  } = useAppContext();

  const { getLocalEnvironment } = useEnvironmentCheck();

  const skipPayment =
    new URLSearchParams(location.search).get("skip_advance_fee") === "True";

  const [cashFeeSDK, setCashFreeSDK] = useState(null);

  const handleCalculateFee = ({ total, feePercent }: any) => {
    const time =
      daysCalculate(form.getValues("clientObject.advanceFeePlan.frequency")) ||
      0;
    const calc = (time * total * feePercent) / 365 / 100;

    return calc || 0;
  };

  const initializeSDK = async () => {
    const data = await load({
      mode: getLocalEnvironment ? "sandbox" : "production",
    });
    setCashFreeSDK(data);
  };

  useEffect(() => {
    initializeSDK();
  }, []);

  const { mutate: handlePayment } = useMutation({
    mutationKey: ["HANDLE_PAYMENT"],
    mutationFn: (data: any) =>
      axiosInstance.post("/client-onboarding-payment-history/", data),
    onSuccess: (response) => {
      if (!isEmpty(response?.data?.data?.paymentLink)) {
        window.location.replace(response?.data?.data?.paymentLink);
        // window.open(response?.data?.data?.paymentLink);
      } else {
        cashFeeSDK.subscriptionsCheckout({
          subsSessionId: response?.data?.data?.subscriptionData?.sessionId,
        });
      }
    },
  });

  return (
    <Grid2 container spacing={2}>
      <Grid2 size={12}>
        <Typography
          fontWeight={600}
          pt={2}
          sx={{ fontSize: "clamp(1.125rem, 0.6563rem + 1.5vw, 1.5rem)" }}
        >
          {skipPayment ? "Setup Mandate" : "Complete your payment"}
        </Typography>
      </Grid2>
      <Grid2 size={12} container spacing={2}>
        <Grid2
          // size={{ md: isCashFee ? 8 : 5.9, xs: 12 }}
          size={7}
          m={"auto"}
          textAlign={"center"}
          borderRadius="16px"
          border={"1px solid #E3E8EF"}
        >
          <Grid2
            size={12}
            container
            spacing={1}
            sx={{
              p: {
                xs: 2,
                md: 5,
              },
            }}
            bgcolor={"#F8FAFC"}
          >
            <Grid2
              size={12}
              container
              spacing={1}
              display={"flex"}
              alignItems={"center"}
            >
              <Grid2 size={{ md: 8, xs: 12 }}>
                <Typography
                  fontWeight={500}
                  color="#9AA4B2"
                  display={"flex"}
                  sx={{ fontSize: "clamp(0.875rem, 0.7188rem + 0.5vw, 1rem)" }}
                >
                  Your current fee
                </Typography>
                <Typography
                  fontSize={20}
                  fontWeight={600}
                  color="#202939"
                  display={"flex"}
                  alignItems={"center"}
                  gap={0.5}
                >
                  {skipPayment
                    ? 0
                    : numberToCurrency(
                        handleCalculateFee({
                          feePercent: form.getValues(
                            "clientObject.advanceFeePlan.feePlan.managementFee.fee",
                          ),
                          total:
                            +form.getValues("planDataObject.aumCash") +
                            sum(
                              form
                                .getValues("planDataObject.plansHoldings")
                                .map(
                                  (d: { cmp: string; quantity: string }) =>
                                    +d.cmp * +d.quantity,
                                ),
                            ),
                        }) * 1.18,
                      )}
                  <Typography fontSize={12} color="#94A3B8">
                    (inclusive of GST)
                  </Typography>
                </Typography>
              </Grid2>
              <Grid2
                size={{ md: 4, xs: 12 }}
                display={"flex"}
                justifyContent={{ md: "flex-end", xs: "center" }}
              >
                {investorOnBoardflowData?.paymentMethods !==
                  "manual_payment" && (
                  <GKButton
                    variant="contained"
                    onClick={() => {
                      handlePayment({
                        isSendEmail: false,
                        client: form.getValues("clientObject.id"),
                        basket: form.getValues("clientObject.basket.id"),
                        feePlan: form.getValues("selectedFeePlan.id"),
                        portfolio: form.getValues("clientObject.portfolio.id"),
                        frequency: skipPayment
                          ? "QUARTERLY"
                          : form.getValues(
                              "clientObject.advanceFeePlan.frequency",
                            ),
                        advanceFeePlan: skipPayment
                          ? null
                          : form.getValues(
                              "clientObject.advanceFeePlan.feePlan.id",
                            ),
                        flowType: "client_initiated_advisory_flow",
                      });
                    }}
                  >
                    {skipPayment ? "Setup" : "Pay Now"}
                  </GKButton>
                )}
              </Grid2>
            </Grid2>
          </Grid2>
          <Grid2 size={12}>
            <Divider />
          </Grid2>
          <Grid2
            size={12}
            container
            spacing={2}
            sx={{
              p: {
                xs: 2,
                md: 5,
              },
            }}
          >
            <Grid2 size={12} container spacing={2}>
              <Grid2 size={{ md: 6, xs: 12 }} textAlign={"start"}>
                <Typography
                  fontWeight={400}
                  color="#64748B"
                  sx={{
                    fontSize: "clamp(0.875rem, 0.5625rem + 1vw, 1.125rem)",
                  }}
                  display={"flex"}
                  alignItems={"center"}
                  gap={0.5}
                >
                  <BiBasket size={20} /> Basket
                </Typography>
                <Typography
                  fontWeight={500}
                  sx={{
                    fontSize: "clamp(0.875rem, 0.5625rem + 1vw, 1.125rem)",
                  }}
                >
                  {form.getValues("clientObject.basket.name") || "-"}
                </Typography>
              </Grid2>
              <Grid2 size={{ md: 6, xs: 12 }} textAlign={"start"}>
                <Typography
                  sx={{
                    fontSize: "clamp(0.875rem, 0.5625rem + 1vw, 1.125rem)",
                  }}
                  fontWeight={400}
                  color="#64748B"
                  display={"flex"}
                  alignItems={"center"}
                  gap={0.5}
                >
                  <LuCalendarClock size={20} /> Renews every
                </Typography>
                <Typography
                  fontWeight={500}
                  sx={{
                    fontSize: "clamp(0.875rem, 0.5625rem + 1vw, 1.125rem)",
                  }}
                >
                  {skipPayment
                    ? "QUARTERLY"
                    : form.getValues("clientObject.advanceFeePlan.frequency") ||
                      "-"}
                </Typography>
              </Grid2>
            </Grid2>
          </Grid2>
        </Grid2>
        {/* <Show.When isTrue={!isCashFee}>
          <Grid2 size={{ md: 0.2, xs: 12 }} textAlign={"center"}>
            <Divider orientation="vertical" />
          </Grid2>
        </Show.When>
        <Show.When isTrue={isCashFee}>
          <Grid2 size={{ md: 5.9, xs: 12 }} textAlign={"center"}>
            <Grid2 container spacing={2}>
              <Grid2 size={12} textAlign={"center"}>
                <Typography
                  fontSize={14}
                  fontWeight={600}
                  color={theme.palette.dark.main}
                  textAlign={"left"}
                >
                  1. Scan the QR Code & Save Your Payment Proof
                </Typography>
                <Typography
                  fontSize={12}
                  fontWeight={500}
                  color={theme.palette.grey[500]}
                  textAlign={"left"}
                >
                  Use your preferred UPI app or payment platform to scan the QR
                  code and complete the payment.
                </Typography>
                <img
                  src={""}
                  style={{ width: "40%", height: "auto", marginTop: 8 }}
                  alt=""
                />
              </Grid2>
              <Grid2 size={12}>
                <Divider />
              </Grid2>
              <Grid2 size={12}>
                <Typography
                  fontSize={14}
                  fontWeight={600}
                  color={theme.palette.dark.main}
                  textAlign={"left"}
                >
                  2. Upload Payment Proof
                </Typography>
                <Typography
                  fontSize={12}
                  fontWeight={500}
                  color={theme.palette.grey[500]}
                  mb={2}
                  textAlign={"left"}
                >
                  Upload the saved payment proof using the upload section
                  located beside the QR code.
                </Typography>
                <DragDrop
                  value={form.getValues("paymentUpload") || []}
                  supportName="png, jpeg, jpg"
                  setUploadError={setUploadError}
                  uploadError={uploadError}
                  acceptType={".png, .jpeg, .jpg"}
                  onChange={(e) => {
                    form.setValue("paymentUpload", e);
                  }}
                />
              </Grid2>
            </Grid2>
          </Grid2>
        </Show.When> */}
      </Grid2>
    </Grid2>
  );
};

export default PaymentTab;
