import { LoadingButton } from "@mui/lab";
import { grey } from "@mui/material/colors";
import {
  GKModal,
  GKSearchSelect,
  GKTextField,
  Grid2,
  Show,
  TextField,
  Typography,
} from "components";
import { useMutation } from "hooks/useQueries";
import { EquitybasketFormInterface } from "interfaces/EquityBasket";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import axiosInstance from "utils/axiosInstance";
import { GKModalProps } from "utils/commonInterface";
import { handleCreateFormData } from "utils/formHelpers";
import { refetchQuery } from "utils/helpers";
import { QueryKeys } from "utils/queryKey";
import { handleReturnAutocompletePlaceholder } from "utils/StringFunctions";
import {
  backetCategoriesData,
  basketTypeDropdown,
  subscriptionDropdown,
} from "./equityBasketHelper";

const CreateBasketModal = ({ open, setOpen }: GKModalProps) => {
  const navigate = useNavigate();

  const form = useForm<EquitybasketFormInterface>({
    defaultValues: {
      name: "",
      basketCategory: "",
      managementStyle: [],
      basketType: "",
      code: "",
    },
  });

  /** ========================================================================================================= */
  const { mutate: handleCreateEquityBasket, isLoading: createLoading } =
    useMutation({
      mutationFn: (data: any) => axiosInstance.post(`/model_portfolio/`, data),
      onSuccess: (response) => {
        refetchQuery(QueryKeys.MODEL_PORTFOLIO_LIST);
        setOpen(false);
        navigate(`/app/equity-basket/${response?.data?.data?.id}/edit`);
      },
    });

  const basketCategoryWatch = form.watch("basketCategory") || "";
  const managementStylesWatch = form.watch("managementStyle") || "";

  return (
    <form
      onSubmit={form.handleSubmit((values) => {
        handleCreateEquityBasket(
          handleCreateFormData({
            values: values,
            dirtyFields: form.formState.dirtyFields,
          }),
        );
      })}
      id="add-basket-form"
    >
      <GKModal
        open={open}
        setOpen={setOpen}
        modalTitle="Create Basket"
        footer={
          <LoadingButton
            type="submit"
            variant="contained"
            form="add-basket-form"
            loading={createLoading}
          >
            Submit
          </LoadingButton>
        }
      >
        <Grid2 container spacing={2}>
          <Grid2 size={4}>
            <Controller
              name="managementStyle"
              control={form.control}
              rules={{
                required: {
                  value: true,
                  message: "Select Advisor Types",
                },
              }}
              render={({ field, fieldState: { error } }) => {
                return (
                  <GKSearchSelect
                    {...field}
                    requiredField
                    onChange={(e: any, val) => field.onChange(val)}
                    disableClearable={false}
                    value={field.value}
                    options={subscriptionDropdown}
                    inputLabel="Select Advisor Types"
                    renderInput={(params) => {
                      return (
                        <TextField
                          {...params}
                          placeholder={handleReturnAutocompletePlaceholder({
                            placeholder: "Select Advisor Types",
                            value: field.value,
                          })}
                          error={!!error}
                          helperText={error?.message}
                        />
                      );
                    }}
                  />
                );
              }}
            />
          </Grid2>
          <Grid2 size={4}>
            <Controller
              name="basketType"
              control={form.control}
              rules={{
                required: {
                  value: true,
                  message: "Select basket type",
                },
              }}
              render={({ field, fieldState: { error } }) => {
                return (
                  <GKSearchSelect
                    {...field}
                    onChange={(e, val) => field.onChange(val?.value)}
                    requiredField
                    disableClearable={false}
                    value={
                      basketTypeDropdown?.find(
                        (item) => item?.value === field.value,
                      )?.name || []
                    }
                    options={basketTypeDropdown}
                    getOptionLabel={(opt) => opt?.name || opt}
                    inputLabel="Basket Type"
                    renderInput={(params) => {
                      return (
                        <TextField
                          {...params}
                          placeholder={handleReturnAutocompletePlaceholder({
                            placeholder: "Select Basket Type",
                            value: field.value,
                          })}
                          error={!!error}
                          helperText={error?.message}
                        />
                      );
                    }}
                  />
                );
              }}
            />
          </Grid2>
          <Show.When isTrue={managementStylesWatch === "Subscription"}>
            <Grid2 size={4}>
              <Controller
                name="basketCategory"
                control={form.control}
                render={({ field, fieldState: { error } }) => {
                  return (
                    <GKSearchSelect
                      {...field}
                      requiredField
                      onChange={(e: any, val) => field.onChange(val.value)}
                      disableClearable={false}
                      getOptionLabel={(option) => option.name || option}
                      value={
                        backetCategoriesData?.find(
                          (item) => item?.value === field.value,
                        )?.name || []
                      }
                      options={backetCategoriesData}
                      inputLabel="Select Basket Category"
                      renderInput={(params) => {
                        return (
                          <TextField
                            {...params}
                            placeholder={handleReturnAutocompletePlaceholder({
                              placeholder: "Select Basket Category",
                              value: field.value,
                            })}
                            error={!!error}
                            helperText={error?.message}
                          />
                        );
                      }}
                    />
                  );
                }}
              />
            </Grid2>
          </Show.When>
          <Grid2 size={6}>
            <Controller
              rules={{
                required: {
                  value: true,
                  message: "Please Enter Name",
                },
              }}
              name="name"
              control={form.control}
              render={({ field, fieldState: { error } }) => {
                return (
                  <GKTextField
                    {...field}
                    requiredField
                    inputLabel="Basket Name"
                    placeholder="Basket Name"
                    error={!!error}
                    helperText={error?.message}
                    slotProps={{
                      input: {
                        endAdornment: managementStylesWatch ===
                          "Subscription" && (
                          <Typography
                            color={grey[600]}
                            width={"-webkit-fill-available"}
                            textAlign={"right"}
                          >
                            {basketCategoryWatch || "Basket Category"}
                          </Typography>
                        ),
                      },
                    }}
                  />
                );
              }}
            />
          </Grid2>
          <Grid2 size={6}>
            <Controller
              name="code"
              control={form.control}
              rules={{
                required: {
                  value: true,
                  message: "Please Enter Name",
                },
              }}
              render={({ field, fieldState: { error } }) => {
                return (
                  <GKTextField
                    {...field}
                    requiredField
                    inputLabel="Basket Code"
                    placeholder="Basket Code"
                    error={!!error}
                    helperText={error?.message}
                  />
                );
              }}
            />
          </Grid2>
        </Grid2>
      </GKModal>
    </form>
  );
};

export default CreateBasketModal;
