import { Box, Button, ClickOutside, IconButton, Popper } from "components";
import { useMutation } from "hooks/useQueries";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import axiosInstance from "utils/axiosInstance";
import { handleDowloadFile } from "utils/fileHelper";
import { ThemeContainer } from "utils/Theme";
import PreTradeConfirmationModal from "./PreTradeConfirmationModal";

interface Props {
  item: any;
  type: string[];
  icon: any;
}

const OrderByIdPopOverAction = ({ item, type, icon }: Props) => {
  const { theme } = ThemeContainer();

  const { id: orderById } = useParams();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [downloadName, setDownloadName] = useState("");
  const [orderId, setOrderId] = useState();
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [sendEmailType, setSendEmailType] = useState("");

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);

  const id = open ? "upload-popper" : undefined;

  const { mutate: DownloadZIP } = useMutation({
    mutationFn: (data: any) => {
      setDownloadName(data?.name);
      return axiosInstance.get(
        `/model_portfolio/${orderById}/download-clients-zip/?order=${data?.orderId}`,
        { responseType: "arraybuffer" },
      );
    },
    retry: false,
    onSuccess: (data: any) => {
      handleDowloadFile({
        fileData: data?.data,
        fileName: `${downloadName}.zip`,
        applicaionType: "application/zip",
        extensionType: "zip",
      });
    },
  });

  const { mutate: DownloadCSV } = useMutation({
    mutationFn: (data: any) => {
      setDownloadName(data?.name);
      return axiosInstance.get(
        `/model_portfolio/${orderById}/order-download/?order=${data?.orderId}`,
      );
    },
    retry: false,
    onSuccess: (data: any) => {
      handleDowloadFile({
        fileData: data?.data,
        fileName: `${downloadName}.csv`,
        applicaionType: "text/csv",
        extensionType: "csv",
      });
    },
  });

  return (
    <>
      <IconButton onClick={handleClick}>{icon}</IconButton>

      <Popper
        id={id}
        open={open}
        anchorEl={anchorEl}
        placement="bottom-start"
        style={{
          zIndex: 4,
          width: type.includes("ORDER") && type.includes("TRADE") ? 170 : 150,
        }}
      >
        <ClickOutside
          onClose={() => {
            setAnchorEl(null);
          }}
        >
          <Box
            sx={{
              border: `solid 1px ${theme.palette.border.main}`,
              borderRadius: "10px",
              boxShadow: `0px 0px 5px 4px ${theme.palette.primary.main}44`,
              p: 1,
              bgcolor: "background.paper",
              display: "flex",
              flexDirection: "column",
              gap: 1,
            }}
          >
            {type.includes("ZIP") && (
              <Button
                variant="outlined"
                onClick={() => {
                  DownloadZIP({
                    orderId: item.id,
                    name: `${item.orderTemplate}`,
                  });
                }}
              >
                Download Zip
              </Button>
            )}

            {type.includes("CSV") && (
              <Button
                variant="outlined"
                onClick={() => {
                  DownloadCSV({
                    orderId: item.id,
                    name: `${item.orderTemplate}`,
                  });
                }}
              >
                Download CSV
              </Button>
            )}

            {type.includes("TRADE") && (
              <Button
                variant="outlined"
                onClick={() => {
                  setOrderId(item.id);
                  setSendEmailType("TRADE");
                  setConfirmationModal(true);
                }}
              >
                Send Order Sheet
              </Button>
            )}

            {type.includes("ORDER") && (
              <Button
                variant="outlined"
                onClick={() => {
                  setOrderId(item.id);
                  setSendEmailType("ORDER");
                  setConfirmationModal(true);
                }}
              >
                Send for Execution
              </Button>
            )}
          </Box>
        </ClickOutside>
      </Popper>

      {confirmationModal && (
        <PreTradeConfirmationModal
          open={confirmationModal}
          setOpen={setConfirmationModal}
          orderId={orderId}
          sendEmailType={sendEmailType}
        />
      )}
    </>
  );
};

export default OrderByIdPopOverAction;
