import { useQuery } from "@tanstack/react-query";
import { useAppContext } from "AppContext";
import { useFetchQuery } from "hooks/useQueries";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "store/store";
import { ThemeContainer } from "utils/Theme";
import axiosInstance from "utils/axiosInstance";
import { QueryKeys } from "utils/queryKey";

const useAdviceRegister = () => {
  const { theme } = ThemeContainer();

  const [openNoteModel, setOpenNoteModel] = useState(false);

  const noteFilter = useAppSelector((state) => state?.app?.noteFilter);

  const dispatch = useAppDispatch();

  const {
    state: { userData },
  } = useAppContext();

  const [noteDetails, setNoteDetails] = useState({});

  const [noteList, setNoteList] = useState([]);

  const [pageData, setPageData] = useState(10);

  const [selectedFilter, setSelectedFilter] = useState([]);

  const requestBody = {
    take: pageData,
    filter: JSON.stringify(noteFilter ? { noteTypes: noteFilter } : []),
    operator: "or",
  };

  const { data: getNoteTypeList, isFetching: noteTypesLoading } = useFetchQuery(
    {
      key: ["GET_NOTE_TYPE_LIST"],
      route: "/note-types/",
    },
  );

  const { data, refetch, isFetching, isLoading } = useQuery({
    queryKey: [QueryKeys.GET_ALL_NOTES_LISTS, requestBody],
    queryFn: () =>
      axiosInstance.get("/advice-register-notes/", { params: requestBody }),
    keepPreviousData: true,
    onSuccess: (data) => setNoteList(data?.data?.data),
  });

  return {
    theme,
    openNoteModel,
    setOpenNoteModel,
    noteDetails,
    setNoteDetails,
    dispatch,
    userData,
    noteList,
    setNoteList,
    selectedFilter,
    setSelectedFilter,
    data,
    refetch,
    isFetching,
    isLoading,
    pageData,
    setPageData,
    noteFilter,
    getNoteTypeList,
    noteTypesLoading,
  };
};

export default useAdviceRegister;
