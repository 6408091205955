import {
  ConfirmDeleteModal,
  GKButton,
  GKTextField,
  Grid2,
  IconButton,
  MdDeleteOutline,
  SimpleLoader,
} from "components";
import { useFetchQuery, useMutation } from "hooks/useQueries";
import { useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { CLIENT_NOMINEE_BY_ID } from "utils/apiRoutes";
import axiosInstance from "utils/axiosInstance";
import { QueryKeys } from "utils/queryKey";

const NomineePage = () => {
  const { id } = useParams<{ id: string }>();

  const [confirmationModal, setConfirmationModal] = useState(false);

  const [clientBankId, setClientBankId] = useState(0);

  const { data, isFetching, refetch } = useFetchQuery({
    key: [QueryKeys.CLIENT_NOMINEE_ACCOUNT],
    route: CLIENT_NOMINEE_BY_ID(id),
  });

  const { mutate: onSubmitHandler } = useMutation({
    mutationKey: ["UPDATE_CLIENT_BANK"],
    mutationFn: (data) => axiosInstance.patch(`/client/${id}/nominees/`, data),
    onSuccess: () => {
      refetch();
    },
  });

  const { mutate: handleDeleteMutation } = useMutation({
    mutationKey: ["DELETE_CLIENT_NOMINEE"],
    mutationFn: ({ clientBankId }: { clientBankId: any }) =>
      axiosInstance.delete(`/client/${clientBankId}/nominees/`),
    onSuccess: () => {
      refetch();
    },
  });

  const form = useForm({
    defaultValues: {
      nominees: [
        {
          name: "",
          percentage: "",
          relationship: "",
        },
      ],
    },
    values: {
      nominees: data?.data?.nominees.length
        ? data?.data?.nominees.map((dataItem: any) => {
            return {
              ...dataItem,
              rowId: dataItem.id,
            };
          })
        : [
            {
              name: "",
              percentage: "",
              relationship: "",
            },
          ],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: "nominees",
  });

  return (
    <>
      <form
        id="nominees-form"
        onSubmit={form.handleSubmit((values: any) => onSubmitHandler(values))}
      >
        {isFetching ? (
          <SimpleLoader />
        ) : (
          <Grid2 size={12} container spacing={1}>
            <Grid2 size={12}>
              {fields.map((bank: any, index: number) => (
                <Grid2 container size={12} spacing={1} my={1} key={index}>
                  <Grid2 size={3}>
                    <Controller
                      name={`nominees.${index}.name`}
                      control={form.control}
                      render={({ field }) => (
                        <GKTextField {...field} inputLabel="Nominee Name" />
                      )}
                    />
                  </Grid2>
                  <Grid2 size={3}>
                    <Controller
                      name={`nominees.${index}.percentage`}
                      control={form.control}
                      render={({ field }) => (
                        <GKTextField
                          {...field}
                          inputLabel="Nominee Percentage"
                        />
                      )}
                    />
                  </Grid2>
                  <Grid2 size={3}>
                    <Controller
                      name={`nominees.${index}.relationship`}
                      control={form.control}
                      render={({ field }) => (
                        <GKTextField {...field} inputLabel="Relationship" />
                      )}
                    />
                  </Grid2>
                  <Grid2
                    size={3}
                    textAlign={"right"}
                    alignSelf={"center"}
                    mt={2}
                  >
                    <IconButton
                      color="error"
                      disabled={fields?.length === 1}
                      onClick={() => {
                        if (bank?.rowId) {
                          setClientBankId(bank?.rowId);
                          setConfirmationModal(true);
                        } else {
                          remove(index);
                        }
                      }}
                    >
                      <MdDeleteOutline />
                    </IconButton>
                  </Grid2>
                </Grid2>
              ))}
            </Grid2>
            <Grid2
              size={12}
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <GKButton
                variant="outlined"
                color="dark"
                size="small"
                onClick={() =>
                  append({
                    name: "",
                    percentage: "",
                    relationship: "",
                  })
                }
              >
                Add Nominee
              </GKButton>
              <GKButton type="submit" variant="contained" size="medium">
                Save
              </GKButton>
            </Grid2>
          </Grid2>
        )}
      </form>

      {confirmationModal && (
        <ConfirmDeleteModal
          open={confirmationModal}
          setOpen={setConfirmationModal}
          onDelete={() => handleDeleteMutation({ clientBankId: clientBankId })}
          title={"Nominee"}
          isDelete
        />
      )}
    </>
  );
};

export default NomineePage;
