import { yupResolver } from "@hookform/resolvers/yup";
import { useAppContext } from "AppContext";
import axios from "axios";
import { useMutation } from "hooks/useQueries";
import { useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import axiosInstance from "utils/axiosInstance";
import { UserRoleTypes } from "utils/common";
import { currentVersion } from "utils/constants";
import { ThemeContainer } from "utils/Theme";
import useDocumentTitle from "utils/useDocumentTitle";
import { object, string } from "yup";

export const useLogin = () => {
  const { dispatch } = useAppContext();

  const { theme } = ThemeContainer();

  const [showPassword, setShowPassword] = useState(false);

  const [hasError, setHasError] = useState(false);

  useDocumentTitle("Login");

  const navigate = useNavigate();

  const { mutate: handleLogin, isLoading: isSubmitting } = useMutation({
    mutationFn: (values: any) =>
      axios.post(`${process.env.REACT_APP_BASE_URL}/login/`, {
        phone: values.phone,
        password: values.password,
        countryCode: values.countryCode,
      }),

    onSuccess: (response: any) => {
      axiosInstance.interceptors.request.use((config) => {
        config.headers.Authorization = `Token ${response?.data?.token}`;
        config.headers[`x-gridkey-user-role`] = response?.data?.userRole;
        return config;
      });
      dispatch({
        type: "CURRENT_USER",
        data: response.data,
      });

      localStorage?.setItem("token", JSON.stringify(response?.data?.token));
      localStorage?.setItem("role", response?.data?.userRole);
      localStorage?.setItem("product-version", currentVersion);

      if (response.data?.userRole === UserRoleTypes.trialUser) {
        navigate("/app/holdings", { replace: true });
      } else if (
        response?.data?.isFirst ||
        !response?.data?.profileDetails?.profileComplete
      ) {
        navigate("/app/company-onboarding", { replace: true });
      } else {
        navigate("/app/holdings");
      }
      window.location.reload();
    },
  });

  const validationSchema = object({
    phone: string().required("Please enter phone number"),
    countryCode: string(),
    password: string().required("Please enter password"),
  });

  const form = useForm({
    defaultValues: {
      password: "",
      phone: "",
      countryCode: "+91",
    },
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = (values: any) => {
    if (hasError) {
      toast.error("Please enter valid phone number");
      return;
    }
    handleLogin(values);
  };

  form.watch();

  return {
    isSubmitting,
    navigate,
    form,
    onSubmit,
    theme,
    showPassword,
    setShowPassword,
    hasError,
    setHasError,
  };
};
