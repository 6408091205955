import {
  Divider,
  Grid2,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "components";
import { useFetchQuery } from "hooks/useQueries";
import { useParams } from "react-router-dom";
import { CLIENT_SUMMARY_BY_ID } from "utils/apiRoutes";
import { paletteLightTheme } from "utils/ColorTheme";
import { moneyCommaSeparator, numberToCurrency } from "utils/MathFunction";
import { ThemeContainer } from "utils/Theme";

interface Props {
  clientData: any;
}

const ClientCard = (props: Props) => {
  const { clientData } = props;

  const { theme } = ThemeContainer();

  const { id } = useParams();

  const { data: clientSummaryData } = useFetchQuery({
    key: ["GET_CLIENT_SUMMARY_DATA", id],
    route: CLIENT_SUMMARY_BY_ID(id),
  });

  return (
    <Grid2 container spacing={2}>
      <Grid2 size={6} container>
        <Grid2 size={12} display={"flex"} alignItems={"center"} gap={1}>
          <Typography fontWeight={600} fontSize={20}>
            {clientData?.personalDetails?.name}
          </Typography>
          <Typography fontWeight={500} fontSize={12}>
            {clientData?.clientCode}
          </Typography>
          {/* <Typography fontWeight={500} fontSize={12}>
            <Chip
              size="small"
              variant="outlined"
              label={clientData?.type}
              color={clientData?.type === "PMS" ? "warning" : "success"}
              sx={{
                borderRadius: "8px",
                color: clientData?.type === "PMS" ? "" : "",
              }}
            />
          </Typography> */}
        </Grid2>
        <Grid2 size={12} display={"flex"} alignItems={"center"} gap={2}>
          <Typography fontWeight={600} fontSize={12} minWidth={"fit-content"}>
            <span
              style={{
                marginRight: 8,
                color: theme.palette.secondaryText.main,
                fontWeight: 400,
                fontSize: 12,
              }}
            >
              Family:
            </span>
            {clientData?.familyName || "-"}
          </Typography>
          <Typography fontWeight={600} fontSize={12}>
            <span
              style={{
                marginRight: 8,
                color: theme.palette.secondaryText.main,
                fontWeight: 400,
                fontSize: 12,
              }}
            >
              Phone:
            </span>
            {clientData?.phone}
          </Typography>
          <Typography fontWeight={600} fontSize={12}>
            <span
              style={{
                marginRight: 8,
                color: theme.palette.secondaryText.main,
                fontWeight: 400,
                fontSize: 12,
              }}
            >
              PAN:
            </span>
            {clientData?.personalDetails?.pan}
          </Typography>
        </Grid2>
      </Grid2>
      <Grid2 size={12}>
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell
                  sx={{
                    p: "16px !important",
                    borderBottom: "none",
                    borderRight: `solid 1px ${paletteLightTheme.palette.border.main}`,
                  }}
                  width={"20%"}
                >
                  <Typography
                    fontWeight={400}
                    fontSize={12}
                    color={theme.palette.secondaryText.main}
                  >
                    Net Contribution
                  </Typography>
                  <Typography fontWeight={600} fontSize={18}>
                    {numberToCurrency(clientSummaryData?.data?.netContribution)}
                  </Typography>
                </TableCell>
                <TableCell
                  sx={{
                    p: "16px !important",
                    borderBottom: "none",
                    borderRight: `solid 1px ${paletteLightTheme.palette.border.main}`,
                  }}
                  width={"20%"}
                >
                  <Typography
                    fontWeight={400}
                    fontSize={12}
                    color={theme.palette.secondaryText.main}
                  >
                    Invested Value
                  </Typography>
                  <Typography fontWeight={600} fontSize={18}>
                    {numberToCurrency(clientSummaryData?.data?.investedValue)}
                  </Typography>
                </TableCell>
                <TableCell
                  sx={{
                    p: "16px !important",
                    borderBottom: "none",
                    borderRight: `solid 1px ${paletteLightTheme.palette.border.main}`,
                  }}
                  width={"20%"}
                >
                  <Typography
                    fontWeight={400}
                    fontSize={12}
                    color={theme.palette.secondaryText.main}
                  >
                    Current Value
                  </Typography>
                  <Typography fontWeight={600} fontSize={18}>
                    {numberToCurrency(clientSummaryData?.data?.currentValue)}
                  </Typography>
                </TableCell>
                <TableCell
                  sx={{
                    p: "16px !important",
                    borderBottom: "none",
                    borderRight: `solid 1px ${paletteLightTheme.palette.border.main}`,
                  }}
                  width={"20%"}
                >
                  <Typography
                    fontWeight={400}
                    fontSize={12}
                    color={theme.palette.secondaryText.main}
                  >
                    Unrealised Gain
                  </Typography>
                  <span
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: 4,
                    }}
                  >
                    <Typography fontWeight={600} fontSize={18}>
                      {numberToCurrency(
                        clientSummaryData?.data?.unrealisedGain,
                      )}
                    </Typography>
                    <Typography
                      fontSize={10}
                      color={
                        clientSummaryData?.data?.absoluteReturn > 0
                          ? paletteLightTheme.palette.success.main
                          : paletteLightTheme.palette.error.main
                      }
                    >
                      (
                      {moneyCommaSeparator(
                        clientSummaryData?.data?.absoluteReturn,
                      )}
                      % )
                    </Typography>
                  </span>
                </TableCell>
                <TableCell
                  sx={{
                    borderBottom: "none",
                    p: "16px !important",
                  }}
                  width={"20%"}
                >
                  <Typography
                    fontWeight={400}
                    fontSize={12}
                    color={theme.palette.secondaryText.main}
                  >
                    {`Today's Gain`}
                  </Typography>
                  <span
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: 4,
                    }}
                  >
                    <Typography fontWeight={600} fontSize={18}>
                      {numberToCurrency(clientSummaryData?.data?.todaysGain)}
                    </Typography>
                    <Typography
                      fontSize={10}
                      color={
                        clientSummaryData?.data?.todaysGainPercentage > 0
                          ? paletteLightTheme.palette.success.main
                          : paletteLightTheme.palette.error.main
                      }
                    >
                      (
                      {moneyCommaSeparator(
                        clientSummaryData?.data?.todaysGainPercentage,
                      )}
                      % )
                    </Typography>
                  </span>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid2>
      <Grid2 size={12}>
        <Divider />
      </Grid2>
    </Grid2>
  );
};

export default ClientCard;
