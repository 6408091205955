import {
  Divider,
  GKTextField,
  Grid2,
  IconButton,
  MdAdd,
  MdDeleteOutline,
  Show,
  Typography,
} from "components";
import { AdvisorOnboardFormInterface } from "interfaces/OnboardingInterface";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { ThemeContainer } from "utils/Theme";

const AdvisorOnboardingClientNominee = () => {
  const form = useFormContext<AdvisorOnboardFormInterface>();

  const { theme } = ThemeContainer();

  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: "nominees",
  });

  return (
    <Grid2 container spacing={1}>
      <Show.When isTrue={fields.length === 0}>
        <Typography color={theme.palette.secondaryText.main} fontSize={12}>
          *Please add a nominee by clicking below button.
        </Typography>
      </Show.When>
      <Show.When isTrue={fields.length !== 0}>
        <Grid2 size={12}>
          {fields.map((bank: any, index: number) => (
            <Grid2 container size={12} spacing={1} my={1} key={index}>
              <Grid2 size={3}>
                <Controller
                  name={`nominees.${index}.name`}
                  control={form.control}
                  render={({ field }) => (
                    <GKTextField {...field} inputLabel="Nominee Name" />
                  )}
                />
              </Grid2>
              <Grid2 size={3}>
                <Controller
                  name={`nominees.${index}.percentage`}
                  control={form.control}
                  render={({ field }) => (
                    <GKTextField {...field} inputLabel="Nominee Percentage" />
                  )}
                />
              </Grid2>
              <Grid2 size={3}>
                <Controller
                  name={`nominees.${index}.relationship`}
                  control={form.control}
                  render={({ field }) => (
                    <GKTextField {...field} inputLabel="Relationship" />
                  )}
                />
              </Grid2>
              <Grid2 size={3} textAlign={"right"} alignSelf={"center"} mt={2}>
                <IconButton
                  color="error"
                  disabled={fields?.length === 1}
                  onClick={() => {
                    remove(index);
                  }}
                >
                  <MdDeleteOutline />
                </IconButton>
              </Grid2>
            </Grid2>
          ))}
        </Grid2>
      </Show.When>
      <Grid2 size={12}>
        <Divider>
          <IconButton
            onClick={() =>
              append({
                name: "",
                percentage: "",
                relationship: "",
              })
            }
          >
            <MdAdd />
          </IconButton>
        </Divider>
      </Grid2>
    </Grid2>
  );
};

export default AdvisorOnboardingClientNominee;
