import { GridColDef } from "@mui/x-data-grid-pro";
import { useAppContext } from "AppContext";
import {
  ConfirmDeleteModal,
  FiSend,
  FiTrash2,
  GKDataGrid,
  GKSearchSelect,
  Grid2,
  IconButton,
  MdOutlineFileDownload,
  Show,
  StatusField,
  TextField,
  Tooltip,
  Typography,
} from "components";
import dayjs from "dayjs";
import useGenerateQueryParams from "hooks/useGenerateQueryParams";
import { useFetchQuery, useMutation } from "hooks/useQueries";
import { CommonFilterState } from "interfaces/CommonFilter";
import { orderBy } from "lodash";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { moneyCommaSeparator } from "utils/MathFunction";
import { handleReturnAutocompletePlaceholder } from "utils/StringFunctions";
import { INVOICE_HISTORY } from "utils/apiRoutes";
import axiosInstance from "utils/axiosInstance";
import { UserRoleTypes } from "utils/common";
import {
  extraColumnsField,
  handleDownloadFile,
  matchDate,
} from "utils/helpers";
import useDocumentTitle from "utils/useDocumentTitle";
import { financialYearFilter } from "../utils";
import PresentationInvoiceModal from "./PresentationInvoiceModal";

interface Props {
  name?: string;
  tableName?: string;
}

const InvoiceTab = (props: Props) => {
  useDocumentTitle("Invoice");

  const { name, tableName } = props;

  // const navigate = useNavigate();
  const { id } = useParams();

  const {
    state: { commonFilter, userData },
    dispatch,
  } = useAppContext();

  const [invoiceId, setInvoiceId] = useState(null);
  const [confirmationModal, setConfirmationModal] = useState(false);
  // const [openPaymentModal, setOpenPaymentModal] = useState(false);
  const [openPresentationModal, setOpenPresentationModal] = useState(false);

  const requestBody = useGenerateQueryParams(name || "invoice", {
    portfolio_id: id,
  });

  const {
    data: invoiceHistory,
    isFetching,
    refetch,
  } = useFetchQuery({
    key: ["INVOICE_LIST", requestBody],
    route: INVOICE_HISTORY,
    requestBody,
  });

  const { mutate: deleteInvoice, isLoading } = useMutation({
    mutationKey: ["DELETE_INVOICE"],
    mutationFn: (id) => axiosInstance.delete(`/invoice-history/${id}/`),
    onSuccess: () => {
      refetch();
      setInvoiceId(null);
    },
  });

  const updateFilter = (filterName: string, val: any) => {
    const targetName = filterName || "invoice";
    dispatch({
      type: "FILTER",
      data: {
        ...commonFilter,
        [targetName]: {
          ...commonFilter[targetName as keyof CommonFilterState],
          customFilter: {
            ...commonFilter[targetName as keyof CommonFilterState].customFilter,
            financialYear: val?.value,
            start_date: val.dates.startDate,
            end_date: val.dates.endDate,
          },
        },
      },
    });
  };

  const columns: GridColDef[] = [
    {
      ...extraColumnsField("Invoice No.", "invoiceNumber", 150, 1),
    },
    {
      ...extraColumnsField("Date", "invoiceDate", 150, 1),
      valueFormatter: (value) => matchDate(value?.value),
      renderCell: (params) => {
        return (
          <Typography>
            {dayjs(params?.row?.invoiceDate).format("D MMM YYYY")}
          </Typography>
        );
      },
    },
    {
      ...extraColumnsField("Portfolio", "clientName", 200, 1),
      renderCell: (params) => {
        return (
          <Grid2
            container
            display={"flex"}
            flexDirection={"column"}
            alignItems={"flex-start"}
          >
            <Typography fontWeight={700}>{params?.row?.clientName}</Typography>
            <Typography fontSize={12}>
              {params?.row?.portfolioName} - {params?.row?.portfolioId}
            </Typography>
          </Grid2>
        );
      },
    },
    {
      ...extraColumnsField("Taxable Amount", "totalAmount", 150, 1),
      valueGetter: (row) => row?.row?.totalAmount,
      valueFormatter: (params) => moneyCommaSeparator(params.value) || 0,
      type: "number",
    },
    {
      ...extraColumnsField("Amount", "feeWithGst", 150, 1),
      valueGetter: (row) => row?.row?.feeWithGst,
      valueFormatter: (params) => moneyCommaSeparator(params.value) || 0,
      type: "number",
    },
    {
      ...extraColumnsField("Proforma No.", "proformaNumber", 150, 1),
    },
    {
      ...extraColumnsField("Payment Status", "isPayment", 150, 1),
      renderCell: (row) => (
        <StatusField statusString={row?.row?.isPayment.toString()} />
      ),
    },
    {
      ...extraColumnsField("Actions", "action", 150, 1),
      align: "right",
      headerAlign: "right",
      sortable: false,
      renderCell: (params) => (
        <Grid2 container gap={1} display={"flex"} justifyContent={"flex-end"}>
          <Show.When isTrue={userData?.userRole === UserRoleTypes.advisor}>
            <Tooltip title="Send Payment Link">
              <IconButton
                // disabled={
                //   params?.row?.isPayment === "completed" ||
                //   params?.row?.isRazorpaySend
                // }
                onClick={(event) => {
                  event.stopPropagation();
                  setInvoiceId(params.row.id);
                  setOpenPresentationModal(true);
                }}
              >
                <FiSend size={20} />
              </IconButton>
            </Tooltip>
            <IconButton
              className="outline"
              color="error"
              disabled={
                (params.row.id === invoiceId && isLoading) ||
                params.row.isPayment === "completed"
              }
              onClick={(event) => {
                event.stopPropagation();
                setInvoiceId(params.row.id);
                setConfirmationModal(true);
              }}
            >
              <FiTrash2 size={20} />
            </IconButton>
          </Show.When>

          <Tooltip title="Download Invoice">
            <IconButton
              onClick={() => {
                handleDownloadFile({
                  link: params?.row?.invoicePdf,
                  name: params?.row?.invoiceName,
                });
              }}
            >
              <MdOutlineFileDownload size={20} />
            </IconButton>
          </Tooltip>
        </Grid2>
      ),
    },
  ];

  return (
    <>
      <GKDataGrid
        loading={isFetching}
        columns={columns}
        rows={invoiceHistory?.data?.data || []}
        maxHeight={"calc(100vh - 325px)"}
        totalCount={invoiceHistory?.data?.totalCount || 0}
        name={name || "invoice"}
        tableName={tableName || "invoice_list"}
        headerComponent={
          <Grid2
            container
            justifyContent={"flex-end"}
            gap={1}
            display={"flex"}
            size={12}
            alignItems={"center"}
          >
            {/* {!id && (
              <GKButton
                variant="outlined"
                color="dark"
                onClick={() => navigate("/app/invoice/payment-link")}
              >
                Payment Links
              </GKButton>
            )} */}
            <Grid2 size={4}>
              <GKSearchSelect
                size="small"
                loading={isFetching}
                options={orderBy(financialYearFilter, "name", "desc")}
                value={
                  financialYearFilter?.find(
                    (dataItem) =>
                      dataItem.value ===
                      (name
                        ? commonFilter?.[name as keyof CommonFilterState]
                            ?.customFilter?.financialYear
                        : commonFilter?.invoice?.customFilter?.financialYear),
                  )?.name
                }
                onChange={(e, val) => {
                  if (name) {
                    updateFilter(name, val);
                  } else {
                    updateFilter("invoice", val);
                  }
                }}
                getOptionLabel={(option) => option.name || option}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder={handleReturnAutocompletePlaceholder({
                      placeholder: "Select Financial Year",
                      value: commonFilter?.invoice?.customFilter?.financialYear,
                    })}
                  />
                )}
              />
            </Grid2>
          </Grid2>
        }
      />

      <Show.When isTrue={confirmationModal}>
        <ConfirmDeleteModal
          open={confirmationModal}
          setOpen={setConfirmationModal}
          onDelete={() => deleteInvoice(invoiceId)}
          title={"Invoice"}
          isDelete
        />
      </Show.When>

      <Show.When isTrue={openPresentationModal}>
        <PresentationInvoiceModal
          open={openPresentationModal}
          setOpen={setOpenPresentationModal}
          invoiceId={invoiceId}
        />
      </Show.When>

      {/* <Show.When isTrue={openPaymentModal}>
        <SendPaymentModal
          open={openPaymentModal}
          setOpen={setOpenPaymentModal}
          invoiceId={invoiceId}
        />
      </Show.When> */}
    </>
  );
};

export default InvoiceTab;
