import { LoadingButton } from "@mui/lab";
import { Divider, GKButton, Grid2, PhoneInput, Typography } from "components";
import { useForgotPassword } from "hooks/useForgotPassword";
import { Controller } from "react-hook-form";

const ForgotPassword = () => {
  const { isLoading, navigate, form, onSubmit, hasError, setHasError } =
    useForgotPassword();

  return (
    <form onSubmit={form.handleSubmit(onSubmit)}>
      <Grid2 container gap={5} justifyContent={"center"}>
        <Grid2
          size={12}
          position={"relative"}
          gap="10px"
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          flexDirection={"column"}
        >
          <Typography
            fontWeight={700}
            fontSize={24}
            className="textUnderlineLogin"
            sx={{
              "&.textUnderlineLogin::before": {
                width: "210px !important",
              },
            }}
          >
            Forgot Password !
          </Typography>
          <Typography textAlign={"center"}>
            Welcome to GridKey, please enter your phone number to continue
          </Typography>
        </Grid2>
        <Grid2
          size={{ xs: 12, md: 7, sm: 8 }}
          container
          spacing={1}
          justifyContent={"center"}
        >
          <Grid2 size={12}>
            <Controller
              name="phone"
              control={form.control}
              rules={{
                required: {
                  value: true,
                  message: "Please enter phone number",
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <PhoneInput
                  {...field}
                  requiredField
                  onSelectChange={(event: any, val: any) => {
                    form.setValue("countryCode", val?.callingCodes);
                  }}
                  onTextChange={(event: any) => {
                    field.onChange(event.target.value);
                  }}
                  onTextValue={field.value}
                  onSelectValue={form.getValues("countryCode")}
                  error={!!error || hasError}
                  hasError={hasError}
                  setHasError={setHasError}
                />
              )}
            />
          </Grid2>
          <Grid2 size={12} textAlign={"end"}>
            <LoadingButton
              loading={isLoading}
              type="submit"
              size="medium"
              variant="contained"
            >
              Continue
            </LoadingButton>
          </Grid2>
        </Grid2>
        <Grid2 size={12}>
          <Typography fontSize={12} textAlign={"center"}>
            By clicking continue, you agree to our{" "}
            <GKButton
              style={{ padding: 0 }}
              onClick={() => navigate("/privacy-policy")}
            >
              terms & conditions
            </GKButton>
          </Typography>
          <Divider style={{ margin: "6px 0" }} />
          <Typography fontSize={12} textAlign={"center"}>
            Already have an account?
            <GKButton
              style={{ padding: 0 }}
              onClick={() => {
                navigate("/login");
              }}
            >
              Sign In
            </GKButton>
          </Typography>
        </Grid2>
      </Grid2>
    </form>
  );
};

export default ForgotPassword;
