import { LoadingButton } from "@mui/lab";
import { GKClientDataGrid, Grid2, Typography } from "components";
import { useLocation } from "react-router-dom";
import { extraColumnsField } from "utils/helpers";
import { moneyCommaSeparatorPoint } from "utils/MathFunction";
import { ThemeContainer } from "utils/Theme";

const SmallCaseOrderTable = ({
  orders,
  orderTradeAuthLoading,
  form,
  orderTradeAuth,
  show,
  showErrorMessage,
}: any) => {
  const { theme } = ThemeContainer();

  const location = useLocation();

  const logo = location.search.split("logo=")[1];

  return (
    <Grid2
      container
      style={{
        width: "100vw",
        display: "flex",
        height: "100vh",
        alignItems: "center",
        justifyContent: "center",
        background: theme.palette.light.main,
      }}
      boxShadow={`inset 0 0 63px -26px #63A387, inset 0 0 63px -16px #00566e`}
      p={{
        xs: 2,
        md: 5,
        lg: 10,
      }}
    >
      {show ? (
        <Grid2 container spacing={2}>
          <Grid2
            size={12}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            flexDirection={"column"}
            gap={1}
          >
            <img
              onError={({ currentTarget }) => {
                currentTarget.src = "/Logos/errorImg.svg";
              }}
              src={logo}
              alt="brokeImage"
              style={{
                maxWidth: "120px",
              }}
            />
          </Grid2>

          <Grid2
            size={12}
            justifyContent={"center"}
            alignItems={"center"}
            display={"flex"}
          >
            <Typography
              fontWeight={600}
              fontSize={18}
              color={
                !showErrorMessage
                  ? theme.palette.error.main
                  : theme.palette.dark.main
              }
            >
              {!showErrorMessage
                ? "Order cancelled"
                : "Order executed successfully"}
            </Typography>
          </Grid2>
        </Grid2>
      ) : (
        <>
          <Grid2
            size={12}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            flexDirection={"column"}
            gap={1}
          >
            <img
              onError={({ currentTarget }) => {
                currentTarget.src = "/Logos/errorImg.svg";
              }}
              src={logo}
              alt="brokerImage"
              style={{
                maxWidth: "120px",
              }}
            />
          </Grid2>
          <Grid2 size={12} mt={1}>
            <GKClientDataGrid
              name="smallcaseOrderTable"
              density="compact"
              maxHeight={`calc(100vh - 480px)`}
              columns={[
                {
                  ...extraColumnsField("Company Name", "company", 150, 1),
                  editable: false,
                },
                {
                  ...extraColumnsField("BSE / NSE Code", "code", 180, 1),
                  editable: false,
                  valueGetter: (params: any) =>
                    `${params.row.bse || "-"} / ${params.row.nse || "-"}`,
                },
                {
                  ...extraColumnsField("ISIN", "isin", 150, 1),
                  editable: false,
                },
                {
                  ...extraColumnsField("Quantity", "quantity", 150, 1),
                  editable: false,
                  type: "number",
                  valueFormatter: (params) =>
                    moneyCommaSeparatorPoint(params.value),
                  valueGetter: (params) => params.row.quantity || "0",
                },
                {
                  ...extraColumnsField("Transaction Type", "tansType", 150, 1),
                  editable: false,
                  valueFormatter: (params) => params?.value,
                },
                {
                  ...extraColumnsField("Status", "status", 250, 1),
                  editable: false,
                  renderCell: (params) => {
                    return (
                      <Typography
                        color={
                          !params.row.nse
                            ? theme.palette.error.main
                            : theme.palette.primary.main
                        }
                      >
                        {!params.row.nse ? params?.row?.status : "-"}
                      </Typography>
                    );
                  },
                },
              ]}
              rows={orders || []}
              getRowClassName={(params) => {
                return !params.row.nse ? "small-case-row-background" : "";
              }}
              showHeaderGrid={false}
            />
          </Grid2>
          <Grid2 size={12} textAlign={"right"} mt={2}>
            <LoadingButton
              loading={orderTradeAuthLoading}
              variant="contained"
              size="medium"
              onClick={form.handleSubmit((values: any) =>
                orderTradeAuth(values),
              )}
            >
              Approve
            </LoadingButton>
          </Grid2>
        </>
      )}
    </Grid2>
  );
};

export default SmallCaseOrderTable;
