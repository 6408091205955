import { LoadingButton } from "@mui/lab";
import {
  DragDrop,
  GKModal,
  GKTextArea,
  Grid2,
  IconButton,
  IoCloudDownloadOutline,
  Typography,
} from "components";
import { useMutation } from "hooks/useQueries";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { ThemeContainer } from "utils/Theme";
import axiosInstance from "utils/axiosInstance";
import { GKModalProps } from "utils/commonInterface";
import { refetchQuery } from "utils/helpers";

interface Props {
  updateData?: any;
}

const MarketingMaterialModal = (props: Props & GKModalProps) => {
  const { open, setOpen, updateData } = props;

  const { theme } = ThemeContainer();

  const [uploadError, setUploadError] = useState("");

  const { mutate: handleAddMaterial, isLoading: addLoading } = useMutation({
    mutationFn: (data: any) =>
      axiosInstance.post(`/marketing-materials/`, data),
    onSuccess: () => {
      toast.success("Marketing material successfully added.");
      setOpen(false);
      refetchQuery("GET_ALL_MARKETING_MATERIAL");
    },
  });

  const { mutate: handleUpdateMaterial, isLoading: updateLoading } =
    useMutation({
      mutationFn: (data: any) =>
        axiosInstance.patch(`/marketing-materials/${updateData?.id}/`, data),
      onSuccess: () => {
        toast.success("Marketing material successfully updated.");
        setOpen(false);
        refetchQuery("GET_ALL_MARKETING_MATERIAL");
      },
    });

  const { mutate: handleDeleteMaterial, isLoading: deleteLoading } =
    useMutation({
      mutationFn: (data: any) =>
        axiosInstance.delete(`/marketing-materials/${data?.id}/`),
      onSuccess: () => {
        refetchQuery("GET_ALL_MARKETING_MATERIAL");
        setOpen(false);
        toast.success("Marketing material successfully deleted.");
      },
    });

  const { control, handleSubmit } = useForm({
    defaultValues: {
      marketing_materials: [],
      marketing_text: updateData?.id ? updateData?.marketingText : "",
    },
  });

  const onSubmit = (values: any) => {
    const formData = new FormData();
    formData.append("marketing_materials", values?.marketing_materials[0]);
    formData.append("marketing_text", values?.marketing_text);

    if (updateData?.id) {
      handleUpdateMaterial({ marketing_text: values?.marketing_text });
    } else {
      handleAddMaterial(formData);
    }
  };

  return (
    <GKModal
      open={open}
      setOpen={setOpen}
      modalTitle={
        updateData?.id ? "Update Marketing Material" : "Add Marketing Material"
      }
      titleComponent={
        updateData?.id && (
          <LoadingButton
            loading={deleteLoading}
            color="error"
            variant="outlined"
            onClick={() => {
              handleDeleteMaterial(updateData);
            }}
          >
            Delete Material
          </LoadingButton>
        )
      }
      footer={
        <LoadingButton
          form="marketing-material-form"
          variant="contained"
          type="submit"
          loading={addLoading || updateLoading}
        >
          Save
        </LoadingButton>
      }
    >
      <form onSubmit={handleSubmit(onSubmit)} id="marketing-material-form">
        <Grid2 container spacing={2}>
          <Grid2 size={12}>
            {updateData?.id ? (
              <Grid2
                container
                border={`solid 1px ${theme.palette.border.main}`}
                borderRadius={2}
                p={1}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Typography
                  width={"50%"}
                  textOverflow={`ellipsis`}
                  whiteSpace={"nowrap"}
                  overflow={"hidden"}
                >
                  {updateData?.fileName}
                </Typography>
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    fetch(updateData?.marketingMaterials)
                      .then((response) => response.blob())
                      .then((blob) => {
                        const link = document.createElement("a");
                        link.href = window.URL.createObjectURL(blob);
                        link.download = updateData?.fileName;
                        link.click();
                      });
                  }}
                >
                  <IoCloudDownloadOutline />
                </IconButton>
              </Grid2>
            ) : (
              <Controller
                name="marketing_materials"
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field, fieldState: { error } }) => (
                  <DragDrop
                    {...field}
                    acceptType=".pdf, .csv, .docx"
                    supportName="PDF, CSV & DOCX"
                    onChange={(files) => {
                      field.onChange(files);
                      setUploadError("");
                    }}
                    uploadError={uploadError}
                    setUploadError={setUploadError}
                    error={!!error}
                  />
                )}
              />
            )}
          </Grid2>
          <Grid2 size={12}>
            <Controller
              name="marketing_text"
              control={control}
              rules={{
                required: { value: true, message: "Description is required" },
              }}
              render={({ field, fieldState: { error } }) => (
                <GKTextArea
                  {...field}
                  minRows={5}
                  inputLabel="Description"
                  requiredField
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
          </Grid2>
        </Grid2>
      </form>
    </GKModal>
  );
};

export default MarketingMaterialModal;
