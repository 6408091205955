import { useAppContext } from "AppContext";
import { Grid2, Show, Tab, Tabs } from "components";
import React from "react";
import useDocumentTitle from "utils/useDocumentTitle";
import AdviceRegister from "../AdviceRegister";
import ComplianceAgreement from "./ComplianceAgreement";
import ComplianceKYCPage from "./ComplianceKYCPage";
import CompliancePortfolios from "./CompliancePortfolios";
import RiskProfileTab from "./RiskProfileTab";
import UploadedProof from "./UploadedProof";

const ComplianceTab = () => {
  const {
    state: { tab: tabValue },
    dispatch,
  } = useAppContext();

  useDocumentTitle("Client Onboarding");

  const tabs = [
    { label: "Portfolios", component: CompliancePortfolios },
    { label: "KYC", component: ComplianceKYCPage },
    { label: "Agreement", component: ComplianceAgreement },
    {
      label: "Risk Profile",
      component: RiskProfileTab,
    },
    { label: "Verify Proofs", component: UploadedProof },
    { label: "Advice Registry", component: AdviceRegister },
  ];

  return (
    <Grid2 container spacing={2}>
      <Grid2 size={6} display={"flex"}>
        <Tabs
          variant="standard"
          className="light"
          TabIndicatorProps={{
            style: {
              display: "none",
            },
          }}
          onChange={(e, val) => {
            dispatch({
              type: "TAB",
              data: { ...tabValue, complianceTabs: val },
            });
          }}
          value={tabValue?.complianceTabs}
        >
          {tabs.map((tab, index) => (
            <Tab key={index} label={tab.label} disableRipple />
          ))}
        </Tabs>
      </Grid2>
      <Grid2 size={12}>
        {tabs.map((tab, index) => (
          <Show.When key={index} isTrue={tabValue?.complianceTabs === index}>
            {React.createElement(tab.component)}
          </Show.When>
        ))}
      </Grid2>
    </Grid2>
  );
};

export default ComplianceTab;
