import { useAppContext } from "AppContext";
import { Grid2 } from "components";
import { useFetchQuery } from "hooks/useQueries";
import DashboardLayout from "layouts/DashboardLayout";
import { useParams } from "react-router-dom";
import DetailsCardSection from "./DetailsCardSection";
import DetailsHeaderSection from "./DetailsHeaderSection";
import DetailsTabView from "./DetailsTabView";

const DetailsPage = () => {
  const {
    state: { tab },
    dispatch,
  } = useAppContext();

  const { id } = useParams();

  const { data, isLoading } = useFetchQuery({
    key: ["GET_PARTNER_BY_ID"],
    route: `/partner/${id}/`,
  });

  return (
    <DashboardLayout title="Partner Details" loading={isLoading}>
      <Grid2 container spacing={2}>
        <Grid2 size={12}>
          <DetailsHeaderSection data={data?.data} id={id} />
        </Grid2>
        <Grid2 size={12}>
          <DetailsCardSection id={id} />
        </Grid2>
        <Grid2 size={12}>
          <DetailsTabView tab={tab} dispatch={dispatch} id={id} />
        </Grid2>
      </Grid2>
    </DashboardLayout>
  );
};

export default DetailsPage;
