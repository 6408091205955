/* eslint-disable new-cap */
import { LoadingButton } from "@mui/lab";
import { Alert, Box, GKClientDataGrid, Typography } from "components";
import { useFetchQuery, useMutation } from "hooks/useQueries";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useLocation } from "react-router-dom";
import axiosInstance from "utils/axiosInstance";
import { paletteLightTheme } from "utils/ColorTheme";
import { extraColumnsField } from "utils/helpers";
import { moneyCommaSeparatorPoint } from "utils/MathFunction";
import { QueryKeys } from "utils/queryKey";

declare global {
  interface Window {
    scDK: any;
  }
}

const RASmallcase = () => {
  const { search } = useLocation();

  const [gatewayInstance, setGatewayInstance] = useState<any>(null);

  const [transactionObject, setTransactionObject] = useState({
    isCompleted: false,
    message: "",
    type: "",
  });

  const userToken = new URLSearchParams(search).get("userToken");
  const userId = new URLSearchParams(search).get("userId");
  const basketId = new URLSearchParams(search).get("basketId");
  const userPhone = new URLSearchParams(search).get("userPhone");
  const gatewayName = new URLSearchParams(search).get("gatewayName");
  const jwtAuth = new URLSearchParams(search).get("jwtAuth");
  const orderId = new URLSearchParams(search).get("orderId");
  const transactionOrders = JSON.parse(
    new URLSearchParams(search).get("transactionOrders"),
  );

  // ===###===###===###===###===###===###===###===###===###===###===###===###

  const { data: userDetails } = useFetchQuery({
    key: [QueryKeys.GET_USER_DETAIL, userToken],
    route: `/user-details/`,
    enabled: Boolean(userToken),
    headers: {
      Authorization: `Token ${userToken}`,
    },
  });

  // ===###===###===###===###===###===###===###===###===###===###===###===###
  const { data: basketData } = useFetchQuery({
    key: ["GET_BASKET_BY_ID", userId, basketId],
    route: `/basket_publish/${basketId}/`,
    requestBody: {
      user: userId,
    },
  });

  // ===###===###===###===###===###===###===###===###===###===###===###===###
  const { mutate: transactionErrorDetails } = useMutation({
    mutationKey: ["TRANSACTION_ERROR,DETAILS"],
    mutationFn: (data: any) =>
      axiosInstance.post(`/smallcase-trade/transaction-details-error/`, data, {
        headers: { Authorization: `Token ${userToken}` },
      }),
    onSuccess: () => {
      setTransactionObject({
        message: "There was an issue while executing your orders please retry.",
        isCompleted: true,
        type: "error",
      });
    },
  });

  // ===###===###===###===###===###===###===###===###===###===###===###===###
  const { mutate: transactionDetails } = useMutation({
    mutationKey: ["TRANSACTION_DETAILS"],
    mutationFn: (data) =>
      axiosInstance.post(`/smallcase-trade/transaction-details-add/`, data, {
        headers: { Authorization: `Token ${userToken}` },
      }),
    onSuccess: () => {
      setTransactionObject({
        message: "Order executed successfully",
        type: "success",
        isCompleted: true,
      });
    },
  });

  // ===###===###===###===###===###===###===###===###===###===###===###===###
  const { mutate: orderTradeAuth, isLoading: orderTradeAuthLoading } =
    useMutation({
      mutationKey: ["ORDER_TRADE_AUTH"],
      mutationFn: (data: any) =>
        axiosInstance.post("/smallcase-trade/order-trade-auth/", data, {
          headers: { Authorization: `Token ${userToken}` },
        }),
      onSuccess: (response) => {
        if (gatewayInstance) {
          gatewayInstance
            .triggerTransaction({
              transactionId: response?.data?.data?.transactionId,
            })
            .then((response: any) => transactionDetails(response))
            .catch((error: any) => {
              if (error.message === "user_mismatch") {
                gatewayInstance.brokerLogout();
              } else {
                transactionErrorDetails({
                  error_code: error.message,
                  order_id: orderId,
                  transactionId: response?.data?.data?.transactionId,
                });
              }
            });
        } else {
          toast.error("Gateway instance not available yet.");
        }
      },
    });

  useEffect(() => {
    const instance = new window.scDK({
      gateway: gatewayName || "gridkey",
      smallcaseAuthToken: jwtAuth || "",
      config: {
        amo: true,
      },
    });
    setGatewayInstance(instance);
  }, []);

  return (
    <Box
      sx={{
        height: "100vh",
        width: "100vw",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        maxWidth: "98%",
        margin: "auto",
        flexDirection: "column",
      }}
    >
      <img
        src={userDetails?.data?.data?.companyLogo}
        alt=""
        height={"10%"}
        width={"auto"}
      />
      {!transactionObject?.isCompleted && transactionOrders?.order && (
        <GKClientDataGrid
          name="smallcaseOrderTable"
          density="compact"
          rows={transactionOrders?.order || []}
          headerComponent={
            <LoadingButton
              loading={orderTradeAuthLoading}
              variant="contained"
              onClick={() => {
                orderTradeAuth({
                  phoneNumber: userPhone,
                  countryCode: "+91",
                  portfolioId: basketData?.data?.data?.portfolio?.id,
                  orderId: orderId,
                });
              }}
            >
              Approve
            </LoadingButton>
          }
          columns={[
            {
              ...extraColumnsField("Company Name", "company", 150, 1),
              editable: false,
            },
            {
              ...extraColumnsField("BSE / NSE Code", "code", 180, 1),
              editable: false,
              valueGetter: (params) =>
                `${params?.row?.bse || "-"} / ${params?.row?.nse || "-"}`,
            },
            {
              ...extraColumnsField("ISIN", "isin", 150, 1),
              editable: false,
            },
            {
              ...extraColumnsField("Quantity", "quantity", 150, 1),
              editable: false,
              type: "number",
              valueFormatter: (params) =>
                moneyCommaSeparatorPoint(params.value),
              valueGetter: (params) => params?.row?.quantity || "0",
            },
            {
              ...extraColumnsField("Transaction Type", "tansType", 150, 1),
              editable: false,
              valueFormatter: (params) => params?.value,
            },
            {
              ...extraColumnsField("Status", "status", 250, 1),
              editable: false,
              renderCell: (params) => {
                return (
                  <Typography
                    color={
                      !params.row.nse
                        ? paletteLightTheme.palette.error.main
                        : paletteLightTheme.palette.primary.main
                    }
                  >
                    {!params.row.nse ? params?.row?.status : "-"}
                  </Typography>
                );
              },
            },
          ]}
          getRowClassName={(params) => {
            return !params.row.nse ? "small-case-row-background" : "";
          }}
        />
      )}
      {transactionObject?.isCompleted && (
        <Alert severity={transactionObject.type as any}>
          {transactionObject?.message}
        </Alert>
      )}
    </Box>
  );
};

export default RASmallcase;
