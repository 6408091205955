/* eslint-disable import/no-cycle */
import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import { useAppContext } from "AppContext";
import {
  CgClose,
  GiCheckMark,
  GKTextField,
  Grid2,
  IconButton,
  IoEyeOffOutline,
  IoMdEye,
  Typography,
} from "components";
import { useMutation } from "hooks/useQueries";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import axiosInstance from "utils/axiosInstance";
import { currentVersion } from "utils/constants";
import { ThemeContainer } from "utils/Theme";
import useDocumentTitle from "utils/useDocumentTitle";
import * as Yup from "yup";

type FormValues = {
  fullName?: string;
  email?: string;
  password?: string;
  confirmPassword?: string;
  phone?: string;
};

const RegisterDetails = () => {
  useDocumentTitle("RegisterDetails");

  const navigate = useNavigate();

  const { theme } = ThemeContainer();

  const { dispatch } = useAppContext();

  const { search, state } = useLocation();

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const validationSchema = Yup.object().shape({
    fullName: Yup.string()
      .required("Please enter Full name")
      .test(
        "whitespace-only",
        "Full Name cannot be empty or whitespace only",
        (value) => {
          return typeof value === "string" && value.trim().length > 0;
        },
      ),
    email: Yup.string()
      .required("Please enter email")
      .matches(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g, "Invalid email"),
    password: Yup.string()
      .required("Password is required")
      .min(6, "Password must be at least 6 characters"),
    confirmPassword: Yup.string()
      .required("Confirm Password is required")
      .oneOf([Yup.ref("password")], "Passwords must match"),
  });

  const form = useForm<FormValues>({
    defaultValues: {
      fullName:
        new URLSearchParams(search).get("name") ||
        state?.prevResponse?.name ||
        "",
      email: new URLSearchParams(search).get("email") || "",
      password: "",
      confirmPassword: "",
      phone: new URLSearchParams(search).get("phone") || state?.phone,
    },
    resolver: yupResolver(validationSchema),
  });

  form.watch();

  const { mutate, isLoading } = useMutation({
    mutationKey: ["REGISTER_USER"],
    mutationFn: (data) =>
      axiosInstance.put(
        `${process.env.REACT_APP_BASE_URL}/user/${state?.prevResponse?.id}/current/`,
        data,
        {
          headers: {
            Authorization: `Token ${new URLSearchParams(search).get("token")}`,
          },
        },
      ),
    onSuccess: (response) => {
      localStorage.setItem(
        "breadcumbs",
        JSON.stringify([
          { name: "Home", path: "/app/dashboard" },
          { name: "Dashboard", path: "/app/dashboard" },
        ]),
      );
      localStorage.setItem("role", response?.data?.userRole);
      localStorage?.setItem("product-version", currentVersion);
      axiosInstance.interceptors.request.use((config) => {
        config.headers.Authorization = `Token ${response?.data?.token}`;
        config.headers[`x-gridkey-user-role`] = response?.data?.userRole;
        return config;
      });
      dispatch({
        type: "CURRENT_USER",
        data: response.data,
      });
      navigate("/login/");
    },
  });

  const onSubmit = async (values: any) => {
    const dataToSubmit: any = {
      personalDetails: {
        bankAccount: {},
        name: values?.fullName,
        email: values?.email,
      },
      family: {},
      phone: values?.phone,
      password1: values?.password,
      password2: values?.password,
    };

    mutate(dataToSubmit);
  };

  return (
    <Grid2 container gap={2} justifyContent={"center"} alignItems={"center"}>
      <Grid2
        size={12}
        position={"relative"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        flexDirection={"column"}
        gap={1}
      >
        <Typography
          fontWeight={700}
          fontSize={24}
          color={theme.palette.dark.main}
          className="textUnderlineLogin"
          sx={{
            "&.textUnderlineLogin::before": {
              width: "200px !important",
            },
          }}
        >
          Create Account !
        </Typography>
        <Typography color={theme.palette.dark.main}>
          Enter your details to create your account
        </Typography>
      </Grid2>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        style={{
          width: "80%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid2 container spacing={1}>
          <Grid2 size={12}>
            <Controller
              name="fullName"
              control={form.control}
              render={({ field, formState: { errors } }) => (
                <GKTextField
                  {...field}
                  disabled={Boolean(new URLSearchParams(search).get("name"))}
                  inputLabel="Full Name"
                  error={Boolean(errors.fullName)}
                  helperText={errors.fullName?.message}
                />
              )}
            />{" "}
          </Grid2>
          <Grid2 size={12}>
            <Controller
              name="email"
              control={form.control}
              render={({ field, formState: { errors } }) => (
                <GKTextField
                  {...field}
                  inputLabel="Email Address"
                  disabled={Boolean(new URLSearchParams(search).get("email"))}
                  error={Boolean(errors.email)}
                  helperText={errors.email?.message}
                />
              )}
            />{" "}
          </Grid2>
          <Grid2 size={12}>
            <Controller
              name="password"
              control={form.control}
              render={({ field, formState: { errors } }) => (
                <GKTextField
                  {...field}
                  inputLabel="Password"
                  type={showPassword ? "text" : "password"}
                  slotProps={{
                    input: {
                      style: {
                        paddingRight: 12,
                      },
                      endAdornment: (
                        <IconButton
                          onClick={() => {
                            setShowPassword(!showPassword);
                          }}
                          style={{
                            padding: 0,
                          }}
                        >
                          {!showPassword ? <IoMdEye /> : <IoEyeOffOutline />}
                        </IconButton>
                      ),
                    },
                  }}
                  error={Boolean(errors.password)}
                  helperText={errors.password?.message}
                />
              )}
            />
            <Grid2
              container
              mt={1}
              p={1}
              style={{
                border: "solid 1px",
                borderRadius: "10px",
                background: `${theme.palette.warning.main}22`,
                borderColor: `${theme.palette.warning.main}`,
              }}
            >
              <Grid2 size={12}>
                <Typography
                  whiteSpace={"nowrap"}
                  fontSize={12}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                    textDecoration: /[A-Z]/.test(form.getValues("password"))
                      ? "line-through"
                      : "",
                  }}
                >
                  {/[A-Z]/.test(form.getValues("password")) ? (
                    <GiCheckMark size={10} color={theme.palette.success.main} />
                  ) : (
                    <CgClose size={10} color={theme.palette.error.main} />
                  )}
                  Password should contain an Upper Case Letter
                </Typography>
                <Typography
                  fontSize={12}
                  whiteSpace={"nowrap"}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                    textDecoration: /[a-z]/.test(form.getValues("password"))
                      ? "line-through"
                      : "",
                  }}
                >
                  {/[a-z]/.test(form.getValues("password")) ? (
                    <GiCheckMark size={10} color={theme.palette.success.main} />
                  ) : (
                    <CgClose size={10} color={theme.palette.error.main} />
                  )}
                  Password should contain a Lower Case Letter
                </Typography>
                <Typography
                  fontSize={12}
                  whiteSpace={"nowrap"}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                    textDecoration: /[@$!%*?&_]/.test(
                      form.getValues("password"),
                    )
                      ? "line-through"
                      : "",
                  }}
                >
                  {/[@$!%*?&_]/.test(form.getValues("password")) ? (
                    <GiCheckMark size={10} color={theme.palette.success.main} />
                  ) : (
                    <CgClose size={10} color={theme.palette.error.main} />
                  )}
                  Password should contain a Special character
                </Typography>
                <Typography
                  fontSize={12}
                  whiteSpace={"nowrap"}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                    textDecoration: /\d/.test(form.getValues("password"))
                      ? "line-through"
                      : "",
                  }}
                >
                  {/\d/.test(form.getValues("password")) ? (
                    <GiCheckMark size={10} color={theme.palette.success.main} />
                  ) : (
                    <CgClose size={10} color={theme.palette.error.main} />
                  )}
                  Password should contain a number
                </Typography>
                <Typography
                  fontSize={12}
                  whiteSpace={"nowrap"}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                    textDecoration: /^([a-zA-Z0-9_*&^%$#@!]){8,}$/.test(
                      form.getValues("password"),
                    )
                      ? "line-through"
                      : "",
                  }}
                >
                  {/^([a-zA-Z0-9_*&^%$#@!]){8,}$/.test(
                    form.getValues("password"),
                  ) ? (
                    <GiCheckMark size={10} color={theme.palette.success.main} />
                  ) : (
                    <CgClose size={10} color={theme.palette.error.main} />
                  )}
                  Password should atleast contain minimum 8 Characters
                </Typography>
              </Grid2>
            </Grid2>{" "}
          </Grid2>
          <Grid2 size={12}>
            <Controller
              name="confirmPassword"
              control={form.control}
              render={({ field, formState: { errors } }) => (
                <GKTextField
                  {...field}
                  inputLabel="Confirm Password"
                  type={showConfirmPassword ? "text" : "password"}
                  error={Boolean(errors.confirmPassword)}
                  helperText={errors.confirmPassword?.message}
                  slotProps={{
                    input: {
                      style: {
                        paddingRight: 12,
                      },
                      endAdornment: (
                        <IconButton
                          onClick={() => {
                            setShowConfirmPassword(!showConfirmPassword);
                          }}
                          style={{
                            padding: 0,
                          }}
                        >
                          {!showConfirmPassword ? (
                            <IoMdEye />
                          ) : (
                            <IoEyeOffOutline />
                          )}
                        </IconButton>
                      ),
                    },
                  }}
                />
              )}
            />{" "}
          </Grid2>
          <Grid2 size={12} textAlign={"end"}>
            <LoadingButton
              loading={isLoading}
              type="submit"
              size="medium"
              variant="contained"
            >
              Create Account
            </LoadingButton>
          </Grid2>
        </Grid2>
      </form>
    </Grid2>
  );
};

export default RegisterDetails;
