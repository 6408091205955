import { useFetchQuery, useMutation } from "hooks/useQueries";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { BRANCH_HAS_MANAGER } from "utils/apiRoutes";
import axiosInstance from "utils/axiosInstance";
import { SelectOption } from "utils/common";
import { refetchQuery } from "utils/helpers";
import { QueryKeys } from "utils/queryKey";

interface Props {
  updateData: any;
  setOpen: any;
  isEditModal: any;
}

const useAddBranchModal = ({ updateData, setOpen, isEditModal }: Props) => {
  const [branchData, setBranchData] = useState<SelectOption[]>([]);

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const { isFetching } = useFetchQuery({
    key: [QueryKeys.GET_ALL_BRANCHES],
    route: BRANCH_HAS_MANAGER,
    success: (data: any) => {
      setBranchData(
        data?.data?.map((x: any) => ({ name: x.name, value: x.id })),
      );
    },
  });

  const { mutate: createBranch } = useMutation({
    mutationKey: ["UPDATE_BRANCH"],
    mutationFn: (data: any) => axiosInstance.post("/branch/", data),
    onSuccess: () => {
      refetchQuery(QueryKeys.GET_ALL_BRANCHES);
      refetchQuery(QueryKeys.BRANCH_LIST);
      setIsSubmitting(false);
      setOpen(false);
    },
    onError: () => {
      setIsSubmitting(false);
    },
  });

  const { mutate: updateBranch } = useMutation({
    mutationKey: ["UPDATE_BRANCH"],
    mutationFn: (data) =>
      axiosInstance.patch(`/branch/${updateData?.id}/`, data),
    onSuccess: () => {
      refetchQuery(QueryKeys.GET_ALL_BRANCHES);
      refetchQuery(QueryKeys.BRANCH_LIST);
      setIsSubmitting(false);
      setOpen(false);
    },
    onError: () => {
      setIsSubmitting(false);
    },
  });

  const form = useForm({
    defaultValues: {
      name: isEditModal ? updateData?.name : "",
      parent: isEditModal ? updateData?.parent : "",
      address: isEditModal ? updateData?.address : "",
      state: isEditModal ? updateData?.state : "",
      country: isEditModal ? updateData?.country : "",
      city: isEditModal ? updateData?.city : "",
      pincode: isEditModal ? updateData?.pincode : "",
    },
  });

  const onSubmit = (values: any) => {
    setIsSubmitting(true);
    if (isEditModal) {
      updateBranch({
        ...updateData,
        ...values,
      });
    } else {
      createBranch(values);
    }
  };

  return {
    branchData,
    isSubmitting,
    isFetching,
    form,
    onSubmit,
  };
};

export default useAddBranchModal;
