import { LoadingButton } from "@mui/lab";
import {
  Box,
  ClickOutside,
  ConfirmDeleteModal,
  GKButton,
  IconButton,
  IoSettingsOutline,
  Popper,
} from "components";
import { useMutation } from "hooks/useQueries";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ThemeContainer } from "utils/Theme";
import axiosInstance from "utils/axiosInstance";
import UploadManagerModal from "./UploadManagerModal";
import UploadPasswordModal from "./UploadPasswordModal";

interface Props {
  item: any;
  refetch?: any;
}

const UploadPopOverActions = ({ item, refetch }: Props) => {
  const { theme } = ThemeContainer();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);

  const id = open ? "upload-popper" : undefined;

  const [isArchiveModalOpen, setIsArchiveModalOpen] = useState(false);

  const [openUploadManagerModal, setOpenUploadManagerModal] = useState(false);

  const [showPasswordModal, setShowPasswordModal] = useState(false);

  const [isUndoModalOpen, setIsUndoModalOpen] = useState(false);

  const navigate = useNavigate();

  const [uploadManagerBulkData, setUploadManagerBulkData] = useState<any>([]);

  /** ============================================================================================================================ */
  const { mutate: handleDownloadErrors, isLoading: errorLoading } = useMutation(
    {
      mutationFn: (fileId: any) =>
        axiosInstance?.get(
          `/transaction_upload/${fileId}/download_errors/?full=false`,
        ),
      onSuccess: async (response: any) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.download = `${item?.fileName?.split(".csv")[0]}_error.csv`;
        link.click();
        link.remove();
      },
    },
  );
  /** ============================================================================================================================ */
  const { mutate: handleUndoTransaction } = useMutation({
    mutationFn: () =>
      axiosInstance?.post(`/transaction_upload/${item?.id}/undo_transactions/`),
    onSuccess: () => {
      refetch();
    },
  });
  /** ============================================================================================================================ */
  const { mutate: handleArchiveTransaction } = useMutation({
    mutationFn: ({ id }: { id: any }) =>
      axiosInstance?.post(`/transaction_upload/${id}/add_archive/`),
    onSuccess: () => {
      refetch();
      setAnchorEl(null);
      setUploadManagerBulkData(
        uploadManagerBulkData.filter((upload: any) => upload.id !== item.id),
      );
    },
  });

  return (
    <>
      <IconButton onClick={handleClick}>
        <IoSettingsOutline size={20} />
      </IconButton>

      <Popper
        id={id}
        open={open}
        anchorEl={anchorEl}
        placement="bottom-start"
        style={{
          zIndex: 4,
          width: 150,
        }}
      >
        <ClickOutside
          onClose={() => {
            setAnchorEl(null);
          }}
        >
          <Box
            sx={{
              border: `solid 1px ${theme.palette.border.main}`,
              borderRadius: "10px",
              boxShadow: `0px 0px 5px 4px ${theme.palette.dark.main}11`,
              p: 1,
              bgcolor: "background.paper",
              display: "flex",
              flexDirection: "column",
              gap: 1,
            }}
          >
            {item?.hasErrors && (
              <LoadingButton
                variant="outlined"
                color="dark"
                size="small"
                fullWidth
                onClick={() => {
                  handleDownloadErrors(item?.id);
                }}
                loading={errorLoading}
              >
                Error{" "}
              </LoadingButton>
            )}
            {item.source === "Manual Entry" && (
              <GKButton
                variant="outlined"
                color="dark"
                size="small"
                fullWidth
                disabled
                onClick={() => {
                  navigate(`/app/edit-transaction/${item.transactionId}`);
                  setAnchorEl(null);
                }}
              >
                Edit
              </GKButton>
            )}
            {(item.status === "FINISHED" ||
              item.status === "PARTIALLY FINISHED") && (
              <GKButton
                variant="outlined"
                color="dark"
                size="small"
                fullWidth
                onClick={() => {
                  setIsUndoModalOpen(true);
                  setAnchorEl(null);
                }}
              >
                Undo Import
              </GKButton>
            )}
            {item.source === "Auto Contract Note" &&
              !item?.invalidPassword &&
              item.status !== "FINISHED" && (
                <LoadingButton
                  variant="outlined"
                  color="dark"
                  size="small"
                  fullWidth
                  onClick={() => {
                    setOpenUploadManagerModal(true);
                  }}
                >
                  Approve
                </LoadingButton>
              )}
            {item?.invalidPassword && (
              <GKButton
                variant="outlined"
                color="dark"
                size="small"
                fullWidth
                onClick={() => {
                  setAnchorEl(null);
                  setShowPasswordModal(true);
                }}
              >
                Retry
              </GKButton>
            )}
            <GKButton
              variant="outlined"
              color="dark"
              size="small"
              fullWidth
              onClick={() => {
                setAnchorEl(null);
                setIsArchiveModalOpen(true);
              }}
            >
              Archive
            </GKButton>
          </Box>
        </ClickOutside>
      </Popper>

      {openUploadManagerModal && (
        <UploadManagerModal
          setOpen={setOpenUploadManagerModal}
          open={openUploadManagerModal}
          isApproveModal
          fileData={item}
        />
      )}

      {isArchiveModalOpen && (
        <ConfirmDeleteModal
          open={isArchiveModalOpen}
          setOpen={setIsArchiveModalOpen}
          title="Archive Transaction"
          messageContent="Are you sure you want to archive this item? This item will be removed from the upload manager, you can un-archive it from the archive manager."
          onDelete={() => handleArchiveTransaction({ id: item?.id })}
          customButtonText="Archive"
        />
      )}

      {isUndoModalOpen && (
        <ConfirmDeleteModal
          open={isUndoModalOpen}
          setOpen={setIsUndoModalOpen}
          title="Undo Transaction"
          messageContent="This action will undo the imports of all the transactions in the file, are you sure you want to continue?"
          onDelete={() => handleUndoTransaction({ id: item?.id })}
        />
      )}

      {showPasswordModal && (
        <UploadPasswordModal
          open={showPasswordModal}
          setOpen={setShowPasswordModal}
          portfolio={item?.portfolio}
          broker={item?.broker}
          fileId={item?.id}
        />
      )}
    </>
  );
};

export default UploadPopOverActions;
