import { LoadingButton } from "@mui/lab";
import { GridColDef } from "@mui/x-data-grid-pro";
import {
  ConfirmationModal,
  GKClientDataGrid,
  GKSideModal,
  Grid2,
  Typography,
} from "components";
import { useFetchQuery, useMutation } from "hooks/useQueries";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { PORTFOLIO_FEE_PLAN_MAPPING } from "utils/apiRoutes";
import axiosInstance from "utils/axiosInstance";
import { extraColumnsField, refetchQuery } from "utils/helpers";

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
  name?: string;
};

const MapPortfolioModal = (props: Props) => {
  const { open, setOpen, name } = props;

  const { id } = useParams();

  const [confirmationModal, setConfirmationModal] = useState(false);

  const {
    data: portfolioFeePlanMappingList,
    isFetching: portfolioMappingFetching,
  } = useFetchQuery({
    key: ["PORTFOLIO_FEE_PLAN_MAPPING"],
    route: PORTFOLIO_FEE_PLAN_MAPPING,
  });

  const { mutate: mappedFeePlanPortfolio, isLoading } = useMutation({
    mutationKey: ["MAPPED_FEE_PLAN_PORTFOLIO"],
    mutationFn: (data) =>
      axiosInstance.post("/portfolio-feeplan-mapping/", data),
    onSuccess: () => {
      setOpen(false);
      refetchQuery("PORTFOLIO_FEE_PLAN_MAPPING_LIST");
    },
  });

  const form = useForm({
    defaultValues: {
      portfolio: [],
    },
  });

  const columns: GridColDef[] = [
    {
      ...extraColumnsField("Portfolio Name / Code", "portfolio", 200, 1),
      renderCell: (params) => {
        return (
          <Grid2
            container
            display={"flex"}
            flexDirection={"column"}
            alignItems={"flex-start"}
          >
            <Typography fontWeight={700}>{params?.row?.clientName}</Typography>
            <Typography fontSize={12}>
              {params?.row?.portfolioName} - {params?.row?.portfolioId}
            </Typography>
          </Grid2>
        );
      },
    },
    {
      ...extraColumnsField("Fee Plan", "feePlan", 200, 1),
      valueGetter: (params) => params?.row?.feePlan?.name || "-",
    },
  ];

  return (
    <Grid2 container>
      <GKSideModal
        width="40%"
        open={open}
        setOpen={setOpen}
        onClose={() => {
          setOpen(false);
        }}
        modalTitle="Mapped Portfolios"
        footerButton={
          <Grid2 container justifyContent={"flex-end"}>
            <LoadingButton
              variant="contained"
              type="submit"
              loading={isLoading}
              disabled={isLoading || !form.formState.isDirty}
              form="portfolio-mapping-form"
            >
              Map
            </LoadingButton>
          </Grid2>
        }
      >
        <form
          id="portfolio-mapping-form"
          // onSubmit={form.handleSubmit((values: any) => {
          //   mappedFeePlanPortfolio({ ...values, fee_plan: +id });
          // })}
          onSubmit={(e) => {
            e.preventDefault();
            setConfirmationModal(true);
          }}
        >
          <Controller
            name="portfolio"
            control={form.control}
            render={({ field }) => (
              <GKClientDataGrid
                name="editFeePlanPortfolio"
                loading={portfolioMappingFetching}
                checkboxSelection
                onRowSelectionModelChange={(params) => {
                  field.onChange(params);
                }}
                rowSelectionModel={field.value}
                columns={columns}
                rows={portfolioFeePlanMappingList?.data?.data || []}
                maxHeight={"calc(100vh - 304px)"}
              />
            )}
          />
        </form>
      </GKSideModal>

      <ConfirmationModal
        open={confirmationModal}
        setOpen={setConfirmationModal}
        title="Map Portfolios"
        onConfirm={form.handleSubmit((values: any) =>
          mappedFeePlanPortfolio({ ...values, fee_plan: +id }),
        )}
        messageContent={
          <Typography>
            Some of the portfolios are already mapped to other fee plans. Are
            you sure you want to map all the selected portfolios to
            <Typography fontWeight={700}>{name} ?</Typography>
          </Typography>
        }
        customButtonText="Map"
      />
    </Grid2>
  );
};

export default MapPortfolioModal;
