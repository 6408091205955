import { useFetchQuery, useMutation } from "hooks/useQueries";
import { useState } from "react";
import {
  GET_ALL_BENCHMARK,
  GET_ALL_PORTFOLIO,
  REPORT_QUOTE,
} from "utils/apiRoutes";
import axiosInstance from "utils/axiosInstance";
import { refetchQuery } from "utils/helpers";
import { QueryKeys } from "utils/queryKey";

const useReport = () => {
  const [quoteList, setQuoteList] = useState([]);

  const [benchmarkList, setBenchmarkList] = useState([]);

  const { mutate: handleDeleteQuote } = useMutation({
    mutationKey: ["DELETE_QUOTE"],
    mutationFn: (id) =>
      axiosInstance.delete(`/report_quotes/${id}/?role=advisor`),
    onSuccess: () => {
      refetchQuery("QUOTE_PMS_REPORT");
    },
  });

  const generateQuote = (data: any, form: any) => {
    const randomIndex = Math.floor(Math.random() * data.length);
    const randomQuote = data[randomIndex];

    form.setValue("quote", randomQuote.value);
  };

  const { isLoading: quoteLoading, isFetched: quoteFetched } = useFetchQuery({
    key: ["QUOTE_PMS_REPORT"],
    route: REPORT_QUOTE,
    requestBody: {
      role: "advisor",
    },
    success: (data: any) => {
      setQuoteList(
        data?.data?.map((dataItem: any) => ({
          ...dataItem,
          name: dataItem.quote,
          value: dataItem.quote,
        })),
      );
    },
  });

  const { data: portfolioList, isInitialLoading } = useFetchQuery({
    key: [QueryKeys.PORTFOLIO_OVERVIEW],
    route: GET_ALL_PORTFOLIO,
  });

  const { isInitialLoading: benchmarkLoading } = useFetchQuery({
    key: ["BENCHMARK"],
    route: GET_ALL_BENCHMARK,
    success: (data: any) => {
      setBenchmarkList(
        data?.data?.data?.map((dataItem: any) => ({
          name: dataItem.name,
          value: dataItem.gcode,
        })),
      );
    },
  });

  return {
    handleDeleteQuote,
    generateQuote,
    quoteList,
    quoteLoading,
    quoteFetched,
    portfolioList,
    isInitialLoading,
    benchmarkList,
    benchmarkLoading,
  };
};

export default useReport;
