import { GridColDef } from "@mui/x-data-grid-pro";
import {
  ConfirmDeleteModal,
  FiTrash2,
  GKButton,
  GKClientDataGrid,
  Grid2,
  IconButton,
  MdAdd,
  Show,
  Typography,
} from "components";
import { useLeadNotes } from "hooks/useLeadNotes";
import { useMutation } from "hooks/useQueries";
import { useState } from "react";
import axiosInstance from "utils/axiosInstance";
import { extraColumnsField, matchDate, refetchQuery } from "utils/helpers";
import { QueryKeys } from "utils/queryKey";
import AddNewNotes from "./AddNotes";

const LeadNotes = ({ lead_id }: any) => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const [itemId, setItemId] = useState(0);

  const [noteData, setNoteData] = useState(null);

  const [leadId, setLeadId] = useState(0);

  const [open, setOpen] = useState(false);

  const { getLeadNotes, isLeadNotesLoading, refetch } = useLeadNotes();

  const { mutate: deleteLead } = useMutation({
    mutationKey: ["CONVERT_TO_CUSTOMER"],
    mutationFn: (id: number) =>
      axiosInstance.delete(`/lead/${lead_id}/notes/${id}/`),
    onSuccess: () => {
      refetchQuery(QueryKeys.NOTES_LIST);
      refetchQuery(QueryKeys.MEETING_LIST);
    },
  });

  const columns: GridColDef[] = [
    {
      ...extraColumnsField("Note", "note", 150, 1),
    },
    {
      ...extraColumnsField("Note Type", "noteType", 150, 1),
      renderCell: (value) => {
        return <Typography>{value?.row?.noteTypeStr || "-"}</Typography>;
      },
    },
    {
      ...extraColumnsField("Note Date", "noteDate", 150, 1),
      renderCell: (value) => {
        return (
          <Typography>{matchDate(value?.row?.noteDate) || "N/A"}</Typography>
        );
      },
    },
    {
      ...extraColumnsField("Updated Date", "updatedAt", 150, 1),
      renderCell: (value) => {
        return (
          <Typography>{matchDate(value?.row?.updatedAt) || "N/A"}</Typography>
        );
      },
    },
    {
      ...extraColumnsField("Action", "action", 80, 1),
      align: "right",
      headerAlign: "right",
      renderCell: (props) => {
        return (
          <Grid2 container justifyContent={"flex-end"} gap={1}>
            <IconButton
              className="outline"
              color="error"
              onClick={(e) => {
                e.stopPropagation();
                setItemId(props.row.id);
                setIsDeleteModalOpen(true);
              }}
            >
              <FiTrash2 size={20} />
            </IconButton>
          </Grid2>
        );
      },
    },
  ];

  return (
    <Grid2 container spacing={1}>
      <Grid2 size={12}>
        <GKClientDataGrid
          name="leadNote"
          rows={getLeadNotes?.data?.data || []}
          columns={columns}
          loading={isLeadNotesLoading}
          exportFile={{
            path: `/lead/${lead_id}/notes/`,
            exportName: "LeadNotesList",
          }}
          onRowClick={(params) => {
            setOpen(true);
            setNoteData(params.row?.id);
          }}
          headerComponent={
            <GKButton
              variant="contained"
              size="medium"
              className="lead-note"
              onClick={() => {
                setLeadId(lead_id);
                setNoteData(null);
                setOpen(true);
              }}
            >
              <MdAdd />
              Add Note
            </GKButton>
          }
        />
      </Grid2>

      <Show.When isTrue={isDeleteModalOpen}>
        <ConfirmDeleteModal
          open={isDeleteModalOpen}
          setOpen={setIsDeleteModalOpen}
          title="Note"
          onDelete={() => deleteLead(itemId)}
          isDelete
        />
      </Show.When>

      <Show.When isTrue={open}>
        <AddNewNotes
          open={open}
          setOpen={setOpen}
          leadId={leadId}
          refetch={refetch}
          noteData={noteData}
        />
      </Show.When>
    </Grid2>
  );
};

export default LeadNotes;
