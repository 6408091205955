import {
  Divider,
  GKDatePicker,
  GKSelect,
  Grid2,
  MenuItem,
  Typography,
} from "components";
import dayjs from "dayjs";
import { EquitybasketFormInterface } from "interfaces/EquityBasket";
import { Controller, useFormContext } from "react-hook-form";
import { MODEL_PORTFOLIO_REBALANCING_TIME } from "utils/constants";
import { ThemeContainer } from "utils/Theme";

const RebalanceTab = ({ data }: { data: any }) => {
  const form = useFormContext<EquitybasketFormInterface>();

  const { theme } = ThemeContainer();

  return (
    <Grid2 container spacing={2}>
      <Grid2 size={4}>
        <Controller
          name="rebalanceTime"
          control={form.control}
          render={({ field }) => {
            return (
              <GKSelect
                {...field}
                inputLabel="Rebalancing Time"
                placeholder="Rebalancing Time"
                displayEmpty
                renderValue={(fieldValue: any) => fieldValue || "Select time"}
              >
                <MenuItem style={{ display: "none" }} value="">
                  Select Rebalance Time
                </MenuItem>
                {MODEL_PORTFOLIO_REBALANCING_TIME?.map((dataItem, i) => (
                  <MenuItem key={i} value={dataItem.value}>
                    {dataItem.name}
                  </MenuItem>
                ))}
              </GKSelect>
            );
          }}
        />
      </Grid2>
      <Grid2 size={4}>
        <Controller
          name="nextRebalanceDate"
          control={form.control}
          render={({ field }) => {
            return (
              <GKDatePicker
                {...field}
                inputLabel="Next Rebalance Date"
                value={dayjs(field.value)}
                onChange={(value) => {
                  field.onChange(dayjs(value).format("YYYY-MM-DD"));
                }}
                disableFuture
                maxDate={dayjs()}
              />
            );
          }}
        />
      </Grid2>
      <Grid2 size={12}>
        <Divider />
      </Grid2>
      <Grid2 size={12}>
        <Typography color={theme.palette.secondaryText.main}>
          Last Rebalance Date:
          <span
            style={{
              color: theme.palette.dark.main,
              fontWeight: 600,
            }}
          >
            {" "}
            {data?.lastRebalanceDate || "The basket has not been rebalanced"}
          </span>
        </Typography>
      </Grid2>
    </Grid2>
  );
};

export default RebalanceTab;
