import { LoadingButton } from "@mui/lab";
import { useQuery } from "@tanstack/react-query";
import {
  ConfirmationModal,
  GKClientDataGrid,
  GKTypography,
  Grid2,
  Show,
  Typography,
} from "components";
import { useMutation } from "hooks/useQueries";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
import axiosInstance from "utils/axiosInstance";
import { paletteLightTheme } from "utils/ColorTheme";
import { extraColumnsField } from "utils/helpers";
import { moneyCommaSeparatorPoint } from "utils/MathFunction";
import { ThemeContainer } from "utils/Theme";

interface Props {
  orderId: string | number | any;
  token: string;
  logo?: string;
  portfolioCode: string | number;
  portfolioId: string | number;
}

const LmOrderPlace = ({
  orderId,
  token,
  portfolioCode,
  logo,
  portfolioId,
}: Props) => {
  const form = useFormContext();

  const { theme } = ThemeContainer();

  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);

  const { data: orders, isFetching } = useQuery({
    queryKey: ["ORDER_GENERATION_LIST"],
    queryFn: () =>
      axiosInstance.get(`/lm-order/verify/`, {
        params: {
          order_id: orderId,
          portfolio_code: portfolioCode,
          portfolio_id: portfolioId,
          email_otp: form.getValues("emailOtp"),
          phone_otp: form.getValues("phoneOtp"),
        },
        headers: { Authorization: `Token ${token}` },
      }),
  });

  const {
    mutate: orderPlace,
    isLoading,
    isSuccess,
    isError,
  } = useMutation({
    mutationKey: ["ORDER_PLACE"],
    mutationFn: (id) =>
      axiosInstance.post(
        `/lm-order/`,
        { order_id: id },
        {
          headers: { Authorization: `Token ${token}` },
        },
      ),
    onSuccess: () => {
      setOpenConfirmationModal(false);
    },
  });

  return (
    <Grid2 container bgcolor={"#F8FAFC"} maxHeight={"100vh"} overflow={"auto"}>
      <Grid2
        size={12}
        py={2}
        px={4}
        display="flex"
        alignItems="center"
        borderBottom={`1px solid ${paletteLightTheme.palette.divider}`}
      >
        <img
          src={logo || "/Logos/iconFullLogo.png"}
          alt=""
          height={`-webkit-fill-available`}
          width={"auto"}
          style={{ maxHeight: "32px" }}
          onError={({ currentTarget }) => {
            currentTarget.src = "https://gridkey.in/Logos/iconFullLogo.png";
          }}
        />
      </Grid2>
      <Grid2 container size={12} p={4} spacing={2}>
        {!isLoading && !isSuccess && !isError && (
          <Grid2 size={12}>
            <GKTypography
              fontSize={24}
              fontWeight={600}
              display={"flex"}
              alignItems={"center"}
            >
              Review your Trades
            </GKTypography>
            <GKTypography
              fontSize={12}
              fontWeight={500}
              display={"flex"}
              alignItems={"center"}
              color={theme.palette.secondaryText.main}
            >
              Stay informed with a complete list of your recent trades
            </GKTypography>
          </Grid2>
        )}

        {!isLoading && !isSuccess && !isError && (
          <Grid2 size={12}>
            <GKClientDataGrid
              name="smallcaseOrderTable"
              density="compact"
              minHeight={`calc(100vh - 418px)`}
              maxHeight={`calc(100vh - 418px)`}
              loading={isFetching}
              columns={[
                {
                  ...extraColumnsField("Company Name", "company", 150, 1),
                },
                {
                  ...extraColumnsField("BSE / NSE Code", "code", 180, 1),
                  valueGetter: (params) =>
                    `${params?.row?.bse || "-"} / ${params?.row?.nse || "-"}`,
                },
                {
                  ...extraColumnsField("ISIN", "isin", 150, 1),
                },
                {
                  ...extraColumnsField("Quantity", "quantity", 150, 1),
                  type: "number",
                  valueFormatter: (params) =>
                    moneyCommaSeparatorPoint(params.value),
                  valueGetter: (params) => params.row.quantity || "0",
                },
                {
                  ...extraColumnsField("Transaction Type", "tansType", 150, 1),
                  valueFormatter: (params) => params?.value,
                },
                {
                  ...extraColumnsField("Price", "price", 250, 1),
                },
              ]}
              rows={orders?.data?.data?.order || []}
              columnsButton={false}
            />
          </Grid2>
        )}

        {!isLoading && isSuccess && !isError && (
          <Grid2
            size={12}
            justifyContent={"center"}
            display={"flex"}
            alignItems={"center"}
            minHeight={`calc(100vh - 113px)`}
          >
            <Typography
              fontWeight={600}
              fontSize={18}
              color={paletteLightTheme.palette.primary.main}
            >
              Order executed successfully
            </Typography>
          </Grid2>
        )}

        {!isLoading && !isSuccess && isError && (
          <Grid2
            size={12}
            justifyContent={"center"}
            display={"flex"}
            alignItems={"center"}
            minHeight={`calc(100vh - 113px)`}
          >
            <Typography
              fontWeight={600}
              fontSize={18}
              color={paletteLightTheme.palette.error.main}
            >
              Order cancelled
            </Typography>
          </Grid2>
        )}

        {!isLoading && !isSuccess && !isError && (
          <Grid2 size={12} textAlign={"right"} mt={2}>
            <LoadingButton
              loading={isLoading}
              variant="contained"
              size="medium"
              onClick={() => setOpenConfirmationModal(true)}
            >
              Confirm Trades
            </LoadingButton>
          </Grid2>
        )}
      </Grid2>

      <Show.When isTrue={openConfirmationModal}>
        <ConfirmationModal
          open={openConfirmationModal}
          setOpen={setOpenConfirmationModal}
          title="Order Confirmation"
          onConfirm={() => orderPlace(orderId)}
          messageContent={
            <Typography>
              Are you sure you want to execute these orders?
            </Typography>
          }
          customButtonText="Confirm"
        />
      </Show.When>
    </Grid2>
  );
};

export default LmOrderPlace;
