import {
  Chip,
  GKButton,
  GKModal,
  GKTextField,
  Grid2,
  MdOutlineClose,
} from "components";
import { useFetchQuery, useMutation } from "hooks/useQueries";
import { Controller, useForm } from "react-hook-form";
import { GET_ALL_LEAD_TAG } from "utils/apiRoutes";
import axiosInstance from "utils/axiosInstance";
import { GKModalProps } from "utils/commonInterface";
import { getHexaColor } from "utils/constants";
import { refetchQuery } from "utils/helpers";

interface Props {
  updateData?: any;
}

const TagModal = (props: Props & GKModalProps) => {
  const { open, setOpen } = props;

  const { data: tagsList } = useFetchQuery({
    route: GET_ALL_LEAD_TAG,
    key: ["GET_ALL_LEAD_TAGS"],
  });

  const { mutate: createLeadTag } = useMutation({
    mutationFn: (data) => axiosInstance.post("/lead-tags/", data),
    onSuccess: () => {
      refetchQuery("GET_ALL_LEAD_TAGS");
      setOpen(false);
    },
  });

  const { mutate: deleteTags } = useMutation({
    mutationFn: (data) => axiosInstance.delete(`/lead-tags/${data}/`),
    onSuccess: () => {
      refetchQuery("GET_ALL_LEAD_TAGS");
      setOpen(false);
    },
  });

  const form = useForm({
    defaultValues: {
      tag: "",
    },
  });

  return (
    <GKModal
      open={open}
      setOpen={setOpen}
      modalTitle={"Tags"}
      footer={
        <Grid2 container justifyContent={"flex-end"} gap={1}>
          <GKButton
            variant="outlined"
            size="medium"
            onClick={() => setOpen(false)}
          >
            Cancel
          </GKButton>
          <GKButton
            variant="contained"
            type="submit"
            form="create-lead-tag-form"
            size="medium"
          >
            Submit
          </GKButton>
        </Grid2>
      }
    >
      <form
        id="create-lead-tag-form"
        onSubmit={form.handleSubmit((values: any) => {
          createLeadTag(values);
        })}
      >
        <Grid2 container spacing={1}>
          <Grid2 size={12}>
            <Controller
              name={"tag"}
              rules={{
                required: {
                  value: true,
                  message: "Please enter a valid tag",
                },
              }}
              control={form.control}
              render={({ field, fieldState: { error } }) => (
                <GKTextField
                  {...field}
                  inputLabel={"Tag Name"}
                  requiredField
                  error={!!error}
                  helperText={!!error && error?.message}
                />
              )}
            />
          </Grid2>
          <Grid2 size={12} display={"flex"} alignItems={"center"} gap={1}>
            {tagsList?.data?.data?.map((dataItem: any) => {
              return (
                <Chip
                  deleteIcon={<MdOutlineClose size={14} />}
                  sx={{
                    background: `${getHexaColor(dataItem?.tag)}22`,
                    color: `${getHexaColor(dataItem?.tag)}`,
                    borderRadius: 2,
                    fontWeight: 600,
                    "& .MuiChip-deleteIcon": {
                      color: `${getHexaColor(dataItem?.tag)}`,
                    },
                  }}
                  label={dataItem?.tag}
                  onDelete={() => {
                    deleteTags(dataItem?.id);
                  }}
                />
              );
            })}
          </Grid2>
        </Grid2>
      </form>
    </GKModal>
  );
};

export default TagModal;
