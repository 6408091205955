/* eslint-disable no-bitwise */
import { ThemeContainer } from "./Theme";

export const currentVersion = "3.5.5";

export const BANK_ACCOUNT_TYPES = [
  { name: "Savings", value: "Savings" },
  { name: "Current", value: "Current" },
];

export const TRANSACTION_TYPES = [
  { name: "BUY", value: "BUY" },
  { name: "SELL", value: "SELL" },
  { name: "IPO", value: "IPO" },
  // { name: "RIGHT ISSUE", value: "RIGHT_ISSUE" },
  { name: "BUY BACK", value: "BUY_BACK" },
  // { name: "PARTLY PAID", value: "PARTLY_PAID" },
];
export const FD_INTEREST_TYPES = [
  { name: "Payout", value: "PAYOUT" },
  { name: "Cumulative", value: "CUMULATIVE" },
];
export const FD_PAYOUT_TRANSACTION_TYPE = [
  { name: "Investment", value: "INVESTMENT" },
  { name: "Withdrawal", value: "WITHDRAWAL" },
  { name: "Investment Payout", value: "INTEREST_PAYOUT" },
];
export const FD_PAYOUT_CUMULATIVE_TYPE = [
  { name: "Investment", value: "INVESTMENT" },
  { name: "Withdrawal", value: "WITHDRAWAL" },
];
export const CASH_TRANSACTION_TYPES = [
  { name: "Deposit", value: "DEPOSIT" },
  { name: "Withdraw", value: "WITHDRAW" },
  // { name: "Income", value: "INCOME" },
  // { name: "Expense", value: "EXPENSE" },
];
export const EXPENSE_TYPES = [
  { name: "Late Payment Charges", value: "latePaymentCharges" },
  { name: "Misc Expense", value: "miscExp" },
  { name: "Total Charges", value: "totalCharges" },
  { name: "Brokerage", value: "brokerage" },
  { name: "AMC Charges", value: "amcCharges" },
  { name: "DP Charges", value: "dpCharges" },
  { name: "Interest", value: "interest" },
];
export const INCOME_TYPES = [
  { name: "Interest", value: "interest" },
  { name: "Misc Income", value: "miscIncome" },
];

export const INCOME = [
  { name: "Expense Reversal", value: "expense_reversal" },
  { name: "Misc Income", value: "misc_income" },
  { name: "Dividend", value: "dividend" },
];

export const EXPENSE = [
  { name: "Late Payment Charges", value: "late_payment_charges" },
  { name: "Misc Expense", value: "misc_exp" },
  { name: "Total Charges", value: "total_charges" },
  { name: "Brokerage", value: "brokerage" },
  { name: "AMC Charges", value: "amc_charges" },
  { name: "DP Charges", value: "dp_charges" },
  { name: "Interest", value: "interest" },
  { name: "Income Reversal", value: "income_reversal" },
  { name: "AUM Charges", value: "aum_charges" },
  { name: "Turnover Fees", value: "turnover_fees" },
];

export const SETTLEMENT_STATUS = [
  { name: "bse", value: "bse" },
  { name: "nse", value: "nse" },
];

export const PERSONAL_GENDER = [
  {
    name: "Male",
    value: "Male",
  },
  {
    name: "Female",
    value: "Female",
  },
];
export const MARITAL_STATUS = [
  {
    name: "Married",
    value: "Married",
  },
  {
    name: "Unmarried",
    value: "Unmarried",
  },
];

export const NOMINEE_ADDED = [
  { name: "Yes", value: true },
  { name: "No", value: false },
];

export const UPLOAD_MANAGER_TEMPLATES = [
  { name: "GridKey template", value: "GridKey template" },
  { name: "Zerodha template", value: "Zerodha template" },
];
export const UPLOAD_MANAGER_FILE_TYPES = [
  { name: "CSV", value: "csv" },
  { name: "PDF", value: "pdf" },
];
export const UPLOAD_MANAGER_VERSIONS = [
  { name: "v1", value: "v1" },
  { name: "v2", value: "v2" },
  { name: "v3", value: "v3" },
];

export const PORTFOLIO_BILLING_CYCLE = [
  { name: "Yearly", value: "Yearly" },
  { name: "Year End", value: "Year End" },
];

export const minimumInvestmentData = [
  { name: "Manual", value: "false" },
  { name: "System Generated", value: "true" },
];

// model portfolio constants
export const MODEL_PORTFOLIO_REBALANCING_TIME = [
  { name: "Weekly", value: "Weekly" },
  { name: "Monthly", value: "Monthly" },
  { name: "Quarterly", value: "Quarterly" },
  { name: "Half Yearly", value: "Half yearly" },
  { name: "Yearly", value: "Yearly" },
];

export const MODEL_PORTFOLIO_BENCCHMARK = [
  { name: "NIFTY 50", value: "NIFTY 50" },
  { name: "S&P BSE SENSEX", value: "S&P BSE SENSEX" },
  { name: "NIFTY MIDCAP 100", value: "NIFTY MIDCAP 100" },
  { name: "S&P BSE 500", value: "S&P BSE 500" },
  { name: "NIFTY 500", value: "NIFTY 500" },
];

export const INVESTMENT_STRATEGIES = [
  { name: "Asset Allocation", value: "Asset Allocation" },
  { name: "Factor Investing", value: "Factor Investing" },
  { name: "Quantitative ", value: "Quantitative" },
  { name: "Technical", value: "Technical" },
  { name: "Quality", value: "Quality" },
  { name: "Growth", value: "Growth" },
  { name: "Value", value: "Value" },
  { name: "Thematic", value: "Thematic" },
  { name: "Sector Tracker ", value: "Sector Tracker" },
  { name: "ESG", value: "ESG" },
  { name: "Corporate Governance", value: "Corporate Governance" },
  { name: "Quantamental", value: "Quantamental" },
  { name: "Fundamental", value: "Fundamental" },
  { name: "Momentum", value: "Momentum" },
  { name: "Goal Based", value: "Goal Based" },
  { name: "Dividend", value: "Dividend" },
];

export const ORGANIZATION_SELECT_OPTIONS = [
  { name: "Branch", value: "Branch" },
  { name: "Admin", value: "Admin" },
  { name: "Associate", value: "Associate" },
];

export const RADIO_BOX_TIME_PERIOD = [
  { name: "1D", value: "1D" },
  { name: "1M", value: "1M" },
  { name: "6M", value: "6M" },
  { name: "1Y", value: "1Y" },
  { name: "3Y", value: "3Y" },
  { name: "5Y", value: "5Y" },
  { name: "Max", value: "Max" },
];

export const LEAD_STATUS = [
  { value: "New Lead", name: "New Lead" },
  { value: "Contacted", name: "Contacted" },
  { value: "Qualified", name: "Qualified" },
  { value: "Unqualified", name: "Unqualified" },
  { value: "Converted", name: "Converted" },
  { value: "Lost", name: "Lost" },
  { value: "Follow-up", name: "Follow-up" },
  { value: "Not Interested", name: "Not Interested" },
  { value: "Pending", name: "Pending" },
  { value: "In Progress", name: "In Progress" },
  { value: "Closed", name: "Closed" },
  { value: "On Hold", name: "On Hold" },
  { value: "Hot Lead", name: "Hot Lead" },
  { value: "Cold Lead", name: "Cold Lead" },
  { value: "Warm Lead", name: "Warm Lead" },
];

export const LEAD_SOURCE = [
  { value: "Existing Customer", name: "Existing Customer" },
  { value: "Website", name: "Website" },
  { value: "Reference", name: "Reference" },
  { value: "Facebook", name: "Facebook" },
  { value: "Google", name: "Google" },
  { value: "Email", name: "Email" },
  { value: "Phone", name: "Phone" },
  { value: "Event", name: "Event" },
  { value: "Direct Mail", name: "Direct Mail" },
];

export const MEETING_TYPE = [
  { name: "Google Meet", value: "Google Meet" },
  { name: "Zoom", value: "Zoom" },
  { name: "Offline", value: "Offline" },
];

export const NOTES_TYPE = [
  { name: "Meeting Notes", value: "Meeting Notes" },
  { name: "Advice Notes", value: "Advice Notes" },
];

export const customAutoImportData = [
  {
    id: 1,
    title: "Gmail",
    img: "/Logos/google.png",
    disabled: false,
  },
  {
    id: 2,
    title: "OutLook / Hotmail",
    img: "/Logos/outlook.png",
    disabled: true,
  },
  {
    id: 3,
    title: "Other",
    img: "/Logos/mail.jpg",
    disabled: true,
  },
];

export const UPLOAD_FILE_TYPES = [
  { name: "Stock", value: "Stock" },
  { name: "Cash", value: "Cash" },
  { name: "Portfolio", value: "Portfolio" },
];

export const TRANSACTIONS_UPLOAD_FILE_TYPES = [
  { name: "Stock", value: "Stock" },
  { name: "Cash", value: "Cash" },
];

export const INSIGHTS_TRANSACTIONS_PINS = [
  { name: "Buy", value: "Buy" },
  { name: "Sell", value: "Sell" },
  { name: "Buy & Sell", value: "Buy & Sell" },
];

export const colors = [
  "#006580",
  "#002d74",
  "#268588",
  "#2f76e980",
  "#25b174",
  "#808080",
  "#1E90FF",
  "#6A5ACD",
];

export const LARGE_CAP_COLOR = `#2b2b2b`;
export const MID_CAP_COLOR = `#808080`;
export const SMALL_CAP_COLOR = `#d5d5d5`;

export const capWiseColor = {
  large: "#2b2b2b",
  medium: "#808080",
  small: "#d5d5d5",
};

export const largeCapColors = [
  "#008000",
  "#228B22",
  "#006400",
  "#3D3D3D",
  "#008B8B",
  "#2E8B57",
  "#6B8E23",
  "#90EE90",
  "#98FB98",
  "#9ACD32",
];

export const mediumCapColors = [
  "#00008B",
  "#0000CD",
  "#1E90FF",
  "#6495ED",
  "#87CEFA",
  "#ADD8E6",
  "#B0C4DE",
  "#B0E0E6",
  "#87CEEB",
  "#6A5ACD",
];

export const smallCapColors = [
  "#8B4513",
  "#A0522D",
  "#CD853F",
  "#D2691E",
  "#DEB887",
  "#BC8F8F",
  "#A52A2A",
  "#800000",
  "#8B0000",
  "#964B00",
];

export const AGREEMENT_SIGNATURE_SOURCE = [
  { value: "aadhaar", name: "Aadhaar" },
  { value: "electronic", name: "Electronic" },
  { value: "document_review", name: "Document Review" },
  // { value: "DSC", name: "DSC" },
];

export const ONBOARDING_DATE_SELECTION = [
  { value: "subscriptionDate", name: "Subscription Date" },
  { value: "agreementDate", name: "Agreement Date" },
];

export const AGREEMENT_SEND_OPTIONS_SOURCE = [
  { value: "Mobile", name: "Mobile" },
  { value: "Email", name: "Email" },
];

export const EMAIL_CONFIGURE_TSL = [
  { value: "true", name: "True" },
  { value: "false", name: "False" },
];

export const KYC_OPTIONS = [
  { value: "GENERALSEBIFLOW", name: "Full KYC" },
  { value: "PAN BASED SIGNATURE", name: "PAN Base Signature" },
  { value: "AADHAAR TEST", name: "Image & Selfie KYC" },
];

export const FILTER_OPRRATOR = [
  { value: "icontains", name: "Icontains" },
  { value: "=", name: "Equals" },
  { value: "startsWith", name: "Starts With" },
  { value: "endsWith", name: "Ends With" },
];

export const MEETING_STATUS = [
  { value: "UPCOMING", name: "UPCOMING" },
  { value: "COMPLETED", name: "COMPLETED" },
  { value: "CANCELED", name: "CANCELED" },
  { value: "EXPIRED", name: "EXPIRED" },
];

export const FEED_ACTIVITY_LOG_COLOR = {
  transaction: "#D6D8F7",
  lead: "#FFFCC8",
};
export const FEED_ACTIVITY_LOG_TITLE_COLOR = {
  transaction: "#454761",
  lead: "#96A008",
};

export const handleStatusReturn = (str: string) => {
  const { theme } = ThemeContainer();

  switch (str?.toLowerCase()) {
    case "create":
      return theme.palette.success.main;
    case "update":
      return theme.palette.info.main;
    case "delete":
      return theme.palette.error.main;
    case "mapping":
      return `#AC1B73`;
    case "all portfolio mapping":
      return `#AC1B73`;
    case "generate":
      return `#681BAC`;

    default:
      return theme.palette.info.main;
  }
};

export const getBackgroundColor = (stringInput: any) => {
  const stringUniqueHash = [...stringInput].reduce((acc, char) => {
    return char.charCodeAt(0) + ((acc << 5) - acc);
  }, 0);
  return `hsl(${stringUniqueHash % 360}, 95%, 35%)`;
};

export const getHexaColor = (letters: string) => {
  const newletter = letters || "ABCDEF";
  let hash = 0;
  for (let i = 0; i < newletter.length; i++) {
    hash = newletter.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";
  const darkenFactor = 0.5; // Factor to darken the color (0.0 to 1.0, where 1.0 is the original color)

  for (let i = 0; i < 3; i++) {
    let value = (hash >> (i * 8)) & 0xff;
    value = Math.floor(value * darkenFactor); // Darken the color
    color += `00${value.toString(16)}`.substr(-2);
  }

  return color;
};
export const feedFilters = [
  {
    name: "#transactions",
    value: ["transaction"],
    color: "#02A6B3",
  },
  {
    name: "#equity Basket",
    value: [
      "model_portfolio",
      "model_portfolio_version",
      "model_portfolio_rebalance",
      "order_manager",
      "model_portfolio_mapping",
    ],
    color: "#F36A61",
  },
  {
    name: "#portfolio",
    value: ["portfolio", "portfolio_group"],
    color: "#DF8327",
  },
  {
    name: "#client",
    value: ["client", "note", "meeting", "family", "riskprofile_response"],
    color: "#900C3F",
  },
  {
    name: "#compliance",
    value: ["riskprofile", "agreement", "kyc", "riskprofile_response"],
    color: "#72CCD2",
  },
  {
    name: "#upload",
    value: ["transaction_upload", "setup_auto_forward", "daily_upload"],
    color: "#D78180",
  },
  {
    name: "#lead",
    value: ["lead", "lead_notes", "lead_meeting"],
    color: "#5F7F8D",
  },
  {
    name: "#company",
    value: ["branch", "profile", "employee"],
    color: "#B192C3",
  },
  {
    name: "#report",
    value: ["report"],
    color: "#53B795",
  },
];

export const PieChartColors = [
  "#006580",
  "#2487A1",
  "#65C1DA",
  "#FECA54",
  "#FFE19C",
  "#FF8E50",
  "#FFC09C",
  "#EA498C",
  "#F686B5",
  "#4CA585",
  "#68C1A1",
  "#A1EED2",
];

export const NOTES_TYPES = [
  { value: "General", name: "General" },
  { value: "Advice", name: "Advice" },
  { value: "Meeting", name: "Meeting" },
  { value: "Call", name: "Call" },
];

export const noteFilters = [
  {
    name: "#Meeting",
    value: ["Meeting"],
    color: "#02A6B3",
    id: 3,
  },
  {
    name: "#Call",
    value: ["Call"],
    color: "#F36A61",
    id: 1,
  },
  {
    name: "#General",
    value: ["General"],
    color: "#DF8327",
    id: 2,
  },
  {
    name: "#Advice",
    value: ["Advice"],
    color: "#72CCD2",
    id: 4,
  },
];

export const adviseRegisterFilters = [
  {
    name: "#risk Profile",
    value: ["riskprofile"],
    color: "#02A6B3",
  },
  {
    name: "#KYC",
    value: ["kyc"],
    color: "#F36A61",
  },
  {
    name: "#agreement",
    value: ["agreement"],
    color: "#DF8327",
  },
  {
    name: "#advice note",
    value: ["advice"],
    color: "#900C3F",
  },
  {
    name: "#orders",
    value: ["order"],
    color: "#72CCD2",
  },
];

export const kycStatus = (type: string) => {
  let value = "";

  if (type?.includes("AADHAAR TEST")) {
    value = "Image & Selfie KYC";
  } else if (type?.includes("PAN BASED SIGNATURE")) {
    value = "PAN Base Signature";
  } else if (type?.includes("GENERALSEBIFLOW")) {
    value = "Full KYC";
  } else {
    value = "";
  }

  return value;
};

export const PMS_REPORT_OPTION_TO_INCLUDE = [
  { name: "Portfolio Summary", value: "portfolio_summary" },
  { name: "Benchmark Comparison", value: "performance_comparison" },
  { name: "Portfolio Performance", value: "portfolio_performance" },
  { name: "Holding Statement", value: "holding_statement" },
  { name: "Capital Flow", value: "bank_statement" },
];

export const PMS_REPORT_HOLDING_STATEMENT = [
  { name: "Consolidated", value: "hs_consolidated" },
  { name: "Portfolio Wise", value: "hs_portfolio_wise" },
];

export const PMS_REPORT_BANK_STATEMENT = [
  { name: "Consolidated", value: "bs_consolidated" },
  { name: "Portfolio Wise", value: "bs_portfolio_wise" },
];

export const PMS_REPORT_OPTIONAL_FIELD = [
  { name: "STT", value: "stt" },
  { name: "Brokerage", value: "brokerage" },
  { name: "Dividend", value: "dividend" },
  // { name: "TWRR", value: "twrr" },
  { name: "Other Charges", value: "other_charges" },
  { name: "Ledger Balance", value: "ledger_balance" },
];

export const PMS_REPORT_EXTRA_OPTIONS_FIELD = [
  { name: "Trades", value: false },
  { name: "Trades + Cash", value: true },
];

export const CUSTOM_REPORT_RETURN_METRICS = [
  { name: "TWRR", value: "twrr" },
  { name: "XIRR", value: "xirr" },
];

export const revenuePlanType = [
  { name: "Management Fee", value: "management_fee" },
  { name: "Performance Fee", value: "performance_fee" },
  { name: "Subscription Fee", value: "subscription_fee" },
  { name: "Advance Deposit", value: "advance_deposit" },
  //   { name: "Custodian Fee", value: "custodian_fee" },
  //   { name: "Brokerage", value: "brokerage" },
  //   { name: "Exit Loan", value: "exit_load" },
];

export const FeeType = [
  { name: "Flat Fee", value: "fixed" },
  { name: "AUM Based", value: "aum_based" },
];

export const FeeMethod = [
  { name: "Simple", value: "simple" },
  // { name: "Slabs", value: "slabs" },
];

export const PERFORMANCE_PLAN_FEE_PARAMETER = [
  { name: "Trades", value: "invested" },
  { name: "Cash Flow", value: "cash_flow" },
];

export const FeeParameter = [
  { name: "Daily AUM", value: "aum" },
  // { name: "XIRR", value: "xirr" },
  { name: "Trades", value: "invested" },
  { name: "Cash Flow", value: "cash_flow" },
  { name: "Daily AUM(excluding ledger balance)", value: "current_value" },
  {
    name: "Daily AUM(excluding cash and cash equivalents)",
    value: "current_value_excl_cash",
  },
  { name: "Opening AUM Current Value", value: "opening_aum_current_value" },
  { name: "Opening AUM Portfolio Value", value: "opening_aum_portfolio_value" },
];

export const calculationFrequency = [
  { name: "Daily", value: "daily" },
  { name: "Weekly", value: "weekly" },
  { name: "Monthly", value: "monthly" },
  { name: "Quarterly", value: "quarterly" },
  { name: "Half Yearly", value: "half_yearly" },
  { name: "Yearly", value: "yearly" },
];

export const billingFrequency = [
  { name: "Monthly", value: "monthly" },
  { name: "Quarterly", value: "quarterly" },
  { name: "Half Yearly", value: "half_yearly" },
  { name: "Yearly", value: "yearly" },
];

export const calculationMethod = [
  { name: "Flat", value: "Flat" },
  { name: "Flat With Reduction", value: "Flat_With_Reduction" },
  { name: "Equalisation", value: "Equalisation" },
  { name: "Stepped Up", value: "Stepped_Up" },
];

export const performanceFeeCalculationFrequency = [
  { name: "Same as above", value: "Same as above" },
];

export const brokerageTransactionType = [
  { name: "ALL", value: "ALL" },
  { name: "Buy", value: "Buy" },
  { name: "Sell", value: "Sell" },
];

export const managementFeeCalculationMethod = [
  { name: "Advance", value: "advance" },
  { name: "Trailing", value: "trailing" },
];

export const SMALL_CASE_DIRECT_TRADE_TRANSACTION_TYPE = [
  { name: "Buy", value: "BUY" },
  { name: "Sell", value: "SELL" },
];
export const mutualFundTransactionType = [
  { name: "Purchase", value: "BUY" },
  { name: "Redemption", value: "SELL" },
];

export const SUBSCRIPTION_FREQUENCY = [
  { name: "Monthly", value: "MONTHLY" },
  { name: "Quarterly", value: "QUARTERLY" },
  { name: "Semi Annually", value: "SEMIANNUALLY" },
  { name: "Yearly", value: "YEARLY" },
];

export const returnParticularColor = (text: string) => {
  switch (text?.toLowerCase()) {
    case "interest":
      return `#19B4D2`;
    case "dp_charges":
      return `#705193`;
    case "amc_charges":
      return `#6FA9D7`;
    case "brokerage":
      return `#E85A60`;
    case "other_charges":
      return `#F3BA1C`;
    case "misc_exp":
      return `#6B253D`;
    case "last_payment_charges  ":
      return `#EC8D87`;
    case "dividend":
      return `#6B943E`;
    case "misc_income":
      return `#198C90`;
    case "deposit":
      return `#F0811E`;
    case "withdraw":
      return `#4B317B`;
    case "buy":
      return `#C61575`;
    case "sell":
      return `#2E15C3`;
    case "income":
      return `#6B943E`;
    default:
      return `#FF6D41`;
  }
};

export const IncomeExpenseTransactionType = [
  { name: "Income", value: "INCOME" },
  { name: "Expense", value: "EXPENSE" },
];
