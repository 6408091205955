import { Box, Grid2, Tab, Tabs } from "@mui/material";
import { Show } from "components/Show";
import { useState } from "react";
import { ThemeContainer } from "utils/Theme";
import CorporateActionsDetails from "./CorporateActionsDetails";
import FundamentalDetails from "./FundamentalDetails";
import HistoricalPricesDetails from "./HistoricalPricesDetails";
import IdentifierDetails from "./IdentifierDetails";
import { CompanyGetByIdInterface } from "./helper";

interface Props {
  data: CompanyGetByIdInterface;
}

const EditPrivateEquityTabSection = (props: Props) => {
  const { data } = props;

  const { theme } = ThemeContainer();

  const [tabValue, setTabValue] = useState(0);

  const includeTab = ["PEQ", "AIF"];

  const tabs = [
    {
      label: "Fundamental Details",
      component: <FundamentalDetails data={data} />,
    },
    includeTab.includes(data?.data?.instType) && {
      label: "ISIN",
      component: <IdentifierDetails />,
    },
    includeTab.includes(data?.data?.instType) && {
      label: "Historical Prices",
      component: <HistoricalPricesDetails />,
    },
    includeTab.includes(data?.data?.instType) && {
      label: "Corporate Actions",
      component: <CorporateActionsDetails instType={data?.data?.instType} />,
    },
  ];

  return (
    <Grid2 container spacing={2}>
      <Grid2 size={12}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            sx={{
              "& .MuiTabs-flexContainer": {
                backgroundColor: theme.palette.light.main,
              },
            }}
            onChange={(e, val) => setTabValue(val)}
            value={tabValue}
          >
            {tabs.map((tab, index) => (
              <Tab key={index} label={tab.label} disableRipple />
            ))}
          </Tabs>
        </Box>
      </Grid2>
      <Grid2 size={12}>
        {tabs.map((tab, index) => (
          <Show.When key={index} isTrue={tabValue === index}>
            {tab.component}
          </Show.When>
        ))}
      </Grid2>
    </Grid2>
  );
};

export default EditPrivateEquityTabSection;
