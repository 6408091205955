import { Radio, RadioProps } from "@mui/material";
import { RiRadioButtonFill } from "react-icons/ri";
import { ThemeContainer } from "utils/Theme";

const GKRadio = (
  props: RadioProps & {
    disableLeftPadding?: boolean;
    disableRightPadding?: boolean;
    disableTopPadding?: boolean;
    disableBottomPadding?: boolean;
    padding?: any;
  },
) => {
  const { theme } = ThemeContainer();

  const {
    disableLeftPadding,
    padding,
    disableBottomPadding,
    disableTopPadding,
    disableRightPadding,
  } = props;

  return (
    <Radio
      {...props}
      sx={{
        ...props.sx,
        paddingLeft: disableLeftPadding ? 0 : padding,
        paddingRight: disableRightPadding ? 0 : padding,
        paddingTop: disableTopPadding ? 0 : padding,
        paddingBottom: disableBottomPadding ? 0 : padding,
      }}
      checkedIcon={
        <RiRadioButtonFill size={22} color={theme.palette.primary.main} />
      }
    />
  );
};

export default GKRadio;
