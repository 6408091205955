import { GKDataGrid } from "components";
import useGenerateQueryParams from "hooks/useGenerateQueryParams";
import { useFetchQuery } from "hooks/useQueries";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { PORTFOLIO_ACCESS_BY_ID } from "utils/apiRoutes";
import { PortfolioAccessClientListColumn } from "utils/columns";
import { QueryKeys } from "utils/queryKey";

const PortfolioAccess = () => {
  const { id } = useParams();

  const [employeeList, setEmployeeList]: any = useState([]);

  // const [selectedEmployeeList, setSelectedEmployeeList] = useState([]);

  // ===#===#===#===#===#===#===#===#===#===#===#===#===#===#===#===#===#===#
  const requestBody = useGenerateQueryParams("portfolioAccess");

  const { isLoading } = useFetchQuery({
    key: [QueryKeys.PORTFOLIO_ACCESS, requestBody],
    route: PORTFOLIO_ACCESS_BY_ID(id),
    requestBody,
    success: (response: any) => {
      setEmployeeList(response?.data);
    },
  });

  // ===#===#===#===#===#===#===#===#===#===#===#===#===#===#===#===#===#===#

  return (
    <GKDataGrid
      columns={PortfolioAccessClientListColumn()}
      rows={employeeList?.data || []}
      loading={isLoading}
      totalCount={employeeList.totalCount || 0}
      name="portfolioAccess"
    />
  );
};

export default PortfolioAccess;
