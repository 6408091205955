import { LoadingButton } from "@mui/lab";
import { useQueries } from "@tanstack/react-query";
import {
  Alert,
  Divider,
  FaGoogle,
  GKButton,
  GKModal,
  GKSearchSelect,
  GKTextField,
  Grid2,
  IconButton,
  IoMailOutline,
  LinearProgress,
  MdDone,
  PiMicrosoftOutlookLogo,
  RxClipboard,
  Step,
  StepConnector,
  StepIconProps,
  StepLabel,
  Stepper,
  TextField,
  Typography,
  stepConnectorClasses,
  styled,
} from "components";
import { useMutation } from "hooks/useQueries";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { handleReturnAutocompletePlaceholder } from "utils/StringFunctions";
import { ThemeContainer } from "utils/Theme";
import axiosInstance from "utils/axiosInstance";
import { copyTextToClipboard } from "utils/helpers";
import { QueryKeys } from "utils/queryKey";

interface Props {
  isModalOpen: boolean;
  setIsModalOpen: (value: boolean) => void;
  isPortfolioAutoImport?: boolean;
  portfolioAutoImportData?: any;
  hasEmail?: string;
}

const AutoImportModalParent = (props: Props) => {
  const { theme } = ThemeContainer();
  const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 20,
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundImage: `linear-gradient( 90deg, ${theme.palette.primary.main} 0%, ${theme.palette.secondary.main} 100%)`,
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundImage: `linear-gradient( 90deg, ${theme.palette.primary.main} 0%, ${theme.palette.secondary.main} 100%)`,
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      height: 2,
      border: 0,
      backgroundColor: theme.palette.grey[200],
      borderRadius: 1,
    },
  }));

  const ColorlibStepIconRoot = styled("div")<{
    ownerState: { completed?: boolean; active?: boolean };
  }>(({ theme, ownerState }) => ({
    backgroundColor: theme.palette.grey[200],
    zIndex: 1,
    color: theme.palette.light.main,
    width: 40,
    height: 40,
    fontWeight: 700,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    ...(ownerState.active && {
      backgroundImage: `linear-gradient( 90deg, ${theme.palette.primary.main} 0%, ${theme.palette.secondary.main} 100%)`,
      boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
    }),
    ...(ownerState.completed && {
      backgroundImage: `linear-gradient( 90deg, ${theme.palette.primary.main} 0%, ${theme.palette.secondary.main} 100%)`,
    }),
  }));

  const ColorlibStepIcon = (props: StepIconProps) => {
    const { active, completed, className, icon } = props;

    return (
      <ColorlibStepIconRoot
        ownerState={{ completed, active }}
        className={className}
      >
        {icon}
      </ColorlibStepIconRoot>
    );
  };

  const customAutoImportData = [
    {
      id: 1,
      title: "Gmail",
      img: <FaGoogle size={50} />,
      disabled: false,
    },
    {
      id: 2,
      title: "OutLook / Hotmail",
      img: <PiMicrosoftOutlookLogo size={50} />,
      disabled: true,
    },
    {
      id: 3,
      title: "Other",
      img: <IoMailOutline size={50} />,
      disabled: true,
    },
  ];

  const { isModalOpen, setIsModalOpen, hasEmail } = props;

  const [portfolio, setPortfolio] = useState<any>([]);

  const [brokers, setBrokers] = useState<any>([]);

  const [activeStep, setActiveStep] = useState(0);

  const [password, setPassword] = useState("");

  const [selectedPortfolio, setSelectedPortfolio] = useState(null);

  const [selectedBroker, setSelectedBroker] = useState(null);

  const [selectedMailingLists, setSelectedMailingLists] = useState(
    customAutoImportData[0],
  );
  const [generatedEmailID, setGeneratedEmailID] = useState("");

  const [email, setEmail] = useState("");

  const [code, setCode] = useState();

  const [copy, setCopy] = useState(true);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (hasEmail) {
      setActiveStep(4);
      setEmail(hasEmail);
      setGeneratedEmailID(hasEmail.split("@")[0].split("+")[1]);
    }
  }, [hasEmail]);

  const copyText = () => {
    const isCopied = copyTextToClipboard(email);
    if (isCopied) setCopy(false);
  };

  const [{ isFetching: portfoliosDataLoading }] = useQueries({
    queries: [
      {
        queryKey: [QueryKeys.PORTFOLIOS_SHORT_DATA],
        queryFn: () => axiosInstance.get("/portfolios/all/"),
        onSuccess: (data: any) => setPortfolio(data?.data),
      },
      {
        queryKey: [QueryKeys.BROKERS_LIST],
        queryFn: () => axiosInstance.get("/auto_forward/get-brokers/"),
        onSuccess: (data: any) =>
          setBrokers(
            data?.data?.map((item: any) => ({
              ...item,
              name: item?.name || item,
              value: item?.name,
            })),
          ),
      },
    ],
  });

  const handleCodeRenew = async () => {
    setIsLoading(true);
    await axiosInstance
      .get(`/auto_forward/${generatedEmailID}/confirm/`)
      .then((response) => {
        setCode(response?.data?.code);
        setIsLoading(false);
        setActiveStep(5);
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error(
          error?.response?.data?.message ||
            "Something went wrong, please try again.",
        );
      });
  };

  const steps = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  const { mutate: handleSetPassword, isLoading: passwordLoading } = useMutation(
    {
      mutationFn: (data: { portfolio: any; broker: any; password: any }) =>
        axiosInstance?.post(`/auto-forward-password/`, data),
      onSuccess: () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      },
    },
  );

  const generateEmail = async () => {
    await axiosInstance
      .post(`/auto_forward/`, {
        portfolio: +selectedPortfolio,
        broker: selectedBroker,
      })
      .then((res) => {
        setEmail(res?.data?.email);
        setGeneratedEmailID(res?.data?.id);
        if (
          brokers?.find((dataItem: any) => dataItem?.name === selectedBroker)
            ?.isRequired
        ) {
          handleSetPassword({
            broker: selectedBroker,
            password: password,
            portfolio: selectedPortfolio,
          });
        } else {
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
      });
  };

  return (
    <GKModal
      open={isModalOpen}
      setOpen={setIsModalOpen}
      modalTitle={"Set Auto Import"}
      width={"70%"}
      footer={
        <Grid2 container size={12}>
          <Grid2 size={6}>
            {activeStep === 0 && (
              <Typography>
                Already have a confirmation code?{" "}
                <GKButton
                  size="large"
                  variant="text"
                  onClick={() => {
                    setActiveStep(5);
                  }}
                  disabled={!selectedPortfolio || !selectedBroker}
                >
                  Skip to code step
                </GKButton>
              </Typography>
            )}
          </Grid2>
          <Grid2
            size={6}
            display={"flex"}
            justifyContent={"flex-end"}
            alignItems={"center"}
            gap={1}
          >
            {activeStep !== 0 && (
              <GKButton
                size="large"
                variant="outlined"
                onClick={() => {
                  setActiveStep((prevActiveStep) => prevActiveStep - 1);
                }}
              >
                Back
              </GKButton>
            )}
            {activeStep === 9 && (
              <GKButton
                size="large"
                variant="contained"
                onClick={() => {
                  setIsModalOpen(false);
                }}
              >
                Finish
              </GKButton>
            )}
            {activeStep !== 9 && (
              <LoadingButton
                loading={(isLoading || passwordLoading) && !hasEmail}
                size="large"
                variant="contained"
                disabled={
                  (!selectedPortfolio ||
                    !selectedBroker ||
                    isLoading ||
                    passwordLoading) &&
                  !hasEmail
                }
                onClick={() => {
                  if (activeStep === 0) {
                    generateEmail();
                  } else if (activeStep === 4) {
                    handleCodeRenew();
                  } else {
                    setActiveStep((prevActiveStep) => prevActiveStep + 1);
                  }
                }}
              >
                Next
              </LoadingButton>
            )}
          </Grid2>
        </Grid2>
      }
    >
      <Grid2 container spacing={1}>
        {portfoliosDataLoading && <LinearProgress />}
        <Grid2 size={12}>
          <Stepper
            activeStep={activeStep}
            alternativeLabel
            connector={<ColorlibConnector />}
          >
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel slots={{ stepIcon: ColorlibStepIcon }}> </StepLabel>
              </Step>
            ))}
          </Stepper>
        </Grid2>
        {activeStep === 0 && (
          <Grid2 container spacing={1} size={12}>
            <Grid2 size={12}>
              <Alert severity="info">
                Please select a portfolio and a broker to set up auto import of
                transactions
              </Alert>
            </Grid2>
            <Grid2 size={6}>
              <GKSearchSelect
                value={
                  portfolio?.find((data: any) => data?.id === selectedPortfolio)
                    ?.name
                }
                disableClearable
                renderOption={(props, option) => {
                  return (
                    <li
                      {...props}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                      }}
                    >
                      <Typography fontWeight={600}>
                        {option.clientName}
                      </Typography>
                      <Typography>{option.name}</Typography>
                    </li>
                  );
                }}
                inputLabel="Select portfolio"
                onChange={(event, val) => {
                  setSelectedPortfolio(val?.value);
                }}
                options={
                  portfolio?.map((item: any) => ({
                    ...item,
                    name: `${item?.name} - ${item?.portfolioId}` || "",
                    value: item.id,
                  })) || []
                }
                getOptionLabel={(option) => option?.name || option}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder={handleReturnAutocompletePlaceholder({
                      placeholder: "Select Portfolio",
                      value: selectedPortfolio,
                    })}
                  />
                )}
              />
            </Grid2>
            <Grid2 size={6}>
              <GKSearchSelect
                disableClearable
                value={
                  brokers?.find(
                    (data: any) => data?.value === selectedPortfolio,
                  )?.name
                }
                inputLabel="Select broker"
                onChange={(event, val) => {
                  setSelectedBroker(val?.value);
                }}
                options={brokers || []}
                getOptionLabel={(option) => option?.name || option}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder={handleReturnAutocompletePlaceholder({
                      placeholder: "Select Broker",
                      value: selectedPortfolio,
                    })}
                  />
                )}
              />
            </Grid2>
            {brokers?.find((dataItem: any) => dataItem?.name === selectedBroker)
              ?.isRequired && (
              <Grid2 size={12}>
                <GKTextField
                  inputLabel="Password"
                  placeholder="Password"
                  onChange={(event) => {
                    setPassword(event.target.value);
                  }}
                  error={Boolean(generatedEmailID) && !password}
                  helperText={
                    Boolean(generatedEmailID) &&
                    !password &&
                    "Please enter a valid password"
                  }
                />
              </Grid2>
            )}
          </Grid2>
        )}
        {activeStep === 1 && (
          <Grid2
            size={12}
            display={"flex"}
            gap={2}
            justifyContent={"space-around"}
          >
            {customAutoImportData?.map((dataItem) => {
              return (
                <GKButton
                  key={dataItem?.id}
                  onClick={() => setSelectedMailingLists(dataItem)}
                  disabled={dataItem.disabled}
                  fullWidth
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    padding: 5,
                  }}
                  variant={
                    dataItem?.id === selectedMailingLists?.id
                      ? "lightBg"
                      : "outlined"
                  }
                >
                  {dataItem.img}
                  <Typography fontWeight={600}>{dataItem.title}</Typography>
                </GKButton>
              );
            })}
          </Grid2>
        )}
        {activeStep === 2 && (
          <Grid2 size={12} container>
            <Grid2 size={6}>
              <Typography
                fontWeight={600}
                fontSize={20}
                color={theme.palette.primary.main}
              >
                Step 3
              </Typography>
              <Divider />
              <Typography fontWeight={600} fontSize={14} my={2}>
                Login to your Google ID Click the settings icon (top-right) and
                then click{" "}
                <strong
                  style={{
                    color: theme.palette.primary.main,
                  }}
                >
                  See all settings
                </strong>{" "}
                from the dropdown.
              </Typography>
              <Typography fontWeight={600} fontSize={12}>
                Forward to :-
                <span
                  style={{
                    padding: "6px",
                    border: `solid 1px ${theme.palette.primary.main}`,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    maxWidth: "70%",
                    borderRadius: 10,
                  }}
                >
                  <Typography
                    fontWeight={600}
                    fontSize={14}
                    color={theme.palette.primary.main}
                  >
                    {email}
                  </Typography>
                  <IconButton
                    onClick={copyText}
                    color={copy ? "info" : "success"}
                  >
                    {copy ? <RxClipboard size={16} /> : <MdDone size={16} />}
                  </IconButton>
                </span>
              </Typography>
            </Grid2>
            <Grid2
              size={6}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              style={{
                background: `${theme.palette.primary.main}44`,
                padding: 10,
                borderRadius: 10,
              }}
            >
              <img
                src="/AutoImportPics/Gmail1.1.svg"
                style={{
                  width: "auto",
                  height: 350,
                }}
                alt="step-1"
              />
            </Grid2>
          </Grid2>
        )}
        {activeStep === 3 && (
          <Grid2 container size={12}>
            <Grid2 size={6} pr={2}>
              <Typography
                fontWeight={600}
                fontSize={20}
                color={theme.palette.primary.main}
              >
                Step 4
              </Typography>
              <Divider />
              <Typography fontWeight={600} fontSize={14} my={2}>
                Next, go to the{" "}
                <strong
                  style={{
                    color: theme.palette.primary.main,
                  }}
                >
                  Forwarding and POP/IMAP
                </strong>{" "}
                . Then click{" "}
                <strong
                  style={{
                    color: theme.palette.primary.main,
                  }}
                >
                  add a forwarding address.
                </strong>
              </Typography>
              <Typography fontWeight={600} fontSize={12}>
                Forward to :-
                <span
                  style={{
                    padding: "6px",
                    border: `solid 1px ${theme.palette.primary.main}`,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    maxWidth: "70%",
                    borderRadius: 10,
                  }}
                >
                  <Typography
                    fontWeight={600}
                    fontSize={14}
                    color={theme.palette.primary.main}
                  >
                    {email}
                  </Typography>
                  <IconButton
                    onClick={copyText}
                    color={copy ? "info" : "success"}
                  >
                    {copy ? <RxClipboard size={16} /> : <MdDone size={16} />}
                  </IconButton>
                </span>
              </Typography>
            </Grid2>
            <Grid2
              size={6}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              style={{
                background: `${theme.palette.primary.main}44`,
                padding: 10,
                borderRadius: 10,
              }}
            >
              {" "}
              <img
                src="/AutoImportPics/Gmail2.1.svg"
                style={{
                  width: "auto",
                  height: 350,
                }}
                alt="step-1"
              />
            </Grid2>
          </Grid2>
        )}
        {activeStep === 4 && (
          <Grid2 size={12} container>
            <Grid2 size={6} pr={2}>
              <Typography
                fontWeight={600}
                fontSize={20}
                color={theme.palette.primary.main}
              >
                Step 5
              </Typography>
              <Divider />
              <Typography fontWeight={600} fontSize={14} my={2}>
                Copy the email address mentioned below, paste it into Gmail:{" "}
                {""}
                <strong
                  style={{
                    color: theme.palette.primary.main,
                  }}
                >
                  Add a forwarding address
                </strong>{" "}
                {""}
                field, click Next, confirm the pop-up, and click {""}
                <strong
                  style={{
                    color: theme.palette.primary.main,
                  }}
                >
                  Proceed.
                </strong>
              </Typography>
              <Typography fontWeight={600} fontSize={12}>
                Forward to :-
                <span
                  style={{
                    padding: "6px",
                    border: `solid 1px ${theme.palette.primary.main}`,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    maxWidth: "70%",
                    borderRadius: 10,
                  }}
                >
                  <Typography
                    fontWeight={600}
                    fontSize={14}
                    color={theme.palette.primary.main}
                  >
                    {email}
                  </Typography>
                  <IconButton
                    onClick={copyText}
                    color={copy ? "info" : "success"}
                  >
                    {copy ? <RxClipboard size={16} /> : <MdDone size={16} />}
                  </IconButton>
                </span>
              </Typography>
            </Grid2>
            <Grid2
              size={6}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              style={{
                background: `${theme.palette.primary.main}44`,
                padding: 10,
                borderRadius: 10,
              }}
            >
              <img
                src="/AutoImportPics/Gmail3.1.svg"
                style={{
                  width: "auto",
                  height: 350,
                }}
                alt="step-1"
              />
            </Grid2>
          </Grid2>
        )}
        {activeStep === 5 && (
          <Grid2 size={12} container>
            <Grid2 size={6} pr={2}>
              <Typography
                fontWeight={600}
                fontSize={20}
                color={theme.palette.primary.main}
              >
                Step 6
              </Typography>
              <Divider />
              <Typography fontWeight={600} fontSize={14} my={2}>
                Please click the link below to verify your permission. After
                clicking, you will be redirected to a confirmation page. Once
                confirmed, please return here and continue.
              </Typography>
              <GKButton
                size="large"
                fullWidth
                variant="contained"
                href={code}
                target="_blank"
              >
                Confirm Your Email
              </GKButton>
            </Grid2>
            <Grid2
              size={6}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              style={{
                background: `${theme.palette.primary.main}44`,
                padding: 10,
                borderRadius: 10,
              }}
            >
              <img
                src="/AutoImportPics/Gmail4.1.svg"
                style={{
                  width: "auto",
                  height: 350,
                }}
                alt="step-1"
              />
            </Grid2>
          </Grid2>
        )}
        {activeStep === 6 && (
          <Grid2 size={12} container>
            <Grid2 size={6} pr={2}>
              <Typography
                fontWeight={600}
                fontSize={20}
                color={theme.palette.primary.main}
              >
                Step 7
              </Typography>
              <Divider />
              <Typography fontWeight={600} fontSize={14} my={2}>
                Next, make sure to select{" "}
                <strong
                  style={{
                    color: theme.palette.primary.main,
                  }}
                >
                  Disable forwarding
                </strong>{" "}
                before proceeding. Once you have disabled forwarding, you can
                proceed to create the forwarding filter.
              </Typography>
            </Grid2>
            <Grid2
              size={6}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              style={{
                background: `${theme.palette.primary.main}44`,
                padding: 10,
                borderRadius: 10,
              }}
            >
              <img
                src="/AutoImportPics/Gmail5.1.svg"
                style={{
                  width: "auto",
                  height: 350,
                }}
                alt="step-1"
              />
            </Grid2>
          </Grid2>
        )}
        {activeStep === 7 && (
          <Grid2 size={12} container>
            <Grid2 size={6} pr={2}>
              <Typography
                fontWeight={600}
                fontSize={20}
                color={theme.palette.primary.main}
              >
                Step 8
              </Typography>
              <Divider />
              <Typography fontWeight={600} fontSize={14} my={2}>
                Next, return to your inbox and search for the email containing
                your file. Once you find it, click on the three dots in the top
                right corner of the email. Then select{" "}
                <strong
                  style={{
                    color: theme.palette.primary.main,
                  }}
                >
                  Filter messages like these.
                </strong>
              </Typography>
            </Grid2>
            <Grid2
              size={6}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              style={{
                background: `${theme.palette.primary.main}44`,
                padding: 10,
                borderRadius: 10,
              }}
            >
              {" "}
              <img
                src="/AutoImportPics/Gmail6.1.svg"
                style={{
                  width: "auto",
                  height: 350,
                }}
                alt="step-1"
              />
            </Grid2>
          </Grid2>
        )}
        {activeStep === 8 && (
          <Grid2 size={12} container>
            <Grid2 size={6} pr={2}>
              <Typography
                fontWeight={600}
                fontSize={20}
                color={theme.palette.primary.main}
              >
                Step 9
              </Typography>
              <Divider />
              <Typography fontWeight={600} fontSize={14} my={2}>
                <li style={{ marginBottom: 2 }}>
                  Add the senders email address in the{" "}
                  <strong
                    style={{
                      color: theme.palette.primary.main,
                    }}
                  >
                    From
                  </strong>{" "}
                  field.
                </li>
                <li style={{ marginBottom: 2 }}>
                  Under{" "}
                  <strong
                    style={{
                      color: theme.palette.primary.main,
                    }}
                  >
                    Subject
                  </strong>
                  , enter keywords that are always part of the email subject.
                </li>
                <li style={{ marginBottom: 2 }}>
                  Check{" "}
                  <strong
                    style={{
                      color: theme.palette.primary.main,
                    }}
                  >
                    Has attachment
                  </strong>{" "}
                  to ensure that only emails that contain a file attachment are
                  forwarded.
                </li>
                <p
                  style={{
                    fontSize: "1.125rem",
                    marginTop: "8px",
                    marginRight: "8px",
                    fontWeight: "500",
                  }}
                >
                  Next, click on the
                  <strong
                    style={{
                      color: theme.palette.primary.main,
                    }}
                  >
                    {" "}
                    Create Filter{" "}
                  </strong>
                  option.
                </p>
              </Typography>
            </Grid2>
            <Grid2
              size={6}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              style={{
                background: `${theme.palette.primary.main}44`,
                padding: 10,
                borderRadius: 10,
              }}
            >
              <img
                src="/AutoImportPics/Gmail7.1.svg"
                style={{
                  width: "auto",
                  height: 350,
                }}
                alt="step-1"
              />
            </Grid2>
          </Grid2>
        )}
        {activeStep === 9 && (
          <Grid2 size={12} container>
            <Grid2 size={6} pr={2}>
              <Typography
                fontWeight={600}
                fontSize={20}
                color={theme.palette.primary.main}
              >
                Step 10
              </Typography>
              <Divider />
              <Typography fontWeight={600} fontSize={14} my={2}>
                Finally, select the ({" "}
                <strong
                  style={{
                    color: theme.palette.primary.main,
                  }}
                >
                  Forward it to
                </strong>{" "}
                ) option and choose the correct auto-forward Gridkey email
                address from the dropdown. Then click{" "}
                <strong
                  style={{
                    color: theme.palette.primary.main,
                  }}
                >
                  {" "}
                  Create Filter{" "}
                </strong>{" "}
                . Your auto-forward rule is now set up for this template. Your
                future files will be automatically forwarded and imported to
                your Gridkey account.
              </Typography>
            </Grid2>
            <Grid2
              size={6}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              style={{
                background: `${theme.palette.primary.main}44`,
                padding: 10,
                borderRadius: 10,
              }}
            >
              {" "}
              <img
                src="/AutoImportPics/Gmail8.1.svg"
                style={{
                  width: "auto",
                  height: 350,
                }}
                alt="step-1"
              />
            </Grid2>
          </Grid2>
        )}
      </Grid2>
    </GKModal>
  );
};
export default AutoImportModalParent;
