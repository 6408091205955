import { grey } from "@mui/material/colors";
import {
  Box,
  Button,
  ButtonGroup,
  GKEditor,
  Grid2,
  Typography,
} from "components";
import { EquitybasketFormInterface } from "interfaces/EquityBasket";
import { useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { LuChevronRight } from "react-icons/lu";
import { ThemeContainer } from "utils/Theme";

const MethodologyTab = () => {
  const [tabs, setTabs] = useState("methodologyUniverse");

  const { theme } = ThemeContainer();

  const form = useFormContext<EquitybasketFormInterface>();

  const methodologyButtons = [
    {
      name: "Defining the Universe",
      value: "methodologyUniverse",
      placeholder:
        "Highlight any specific selection criteria. For example, all listed companies on NSE are included. ",
    },
    {
      name: "Research",
      value: "methodologyResearch",
      placeholder:
        " Mention the key factors used for screening, such as market cap, growth metrics, or sector focus. For example, the team conducts thorough research to define the screening criteria for selecting stocks. ",
    },
    {
      name: "Constituent Screening",
      value: "methodologyConstituentScreening",
      placeholder:
        "Mention key criteria used for screening. For example, the team selects stocks based on reports and financials.",
    },
    {
      name: "Weightage",
      value: "methodologyWeightage",
      placeholder:
        "Specify how each of these factors influences the weight allocation. For example, the stock weights in this smallcase are determined based on several key factors, including market capitalization, management quality and vision, theme exposure, revenue, and earnings visibility.",
    },
    {
      name: "Rebalance",
      value: "methodologyRebalance",
      placeholder:
        "Use this section to specify the rebalancing frequency and criteria followed for updating the portfolio. For example, this smallcase follows a yearly rebalance, where the research team evaluates the portfolio and adjusts stock/ETF holdings as needed.",
    },
    {
      name: "Asset Allocation",
      value: "methodologyAllocation",
      placeholder:
        "Describe the approach used for assigning weights to different stocks. For example, the selected stocks are weighted according to the defined asset allocation algorithm to maintain portfolio balance.",
    },
  ];

  return (
    <Grid2 container spacing={2}>
      <Grid2 size={2}>
        <ButtonGroup orientation="vertical" fullWidth>
          {methodologyButtons?.map((dItem) => {
            return (
              <Button
                sx={{
                  fontWeight: 600,
                  textAlign: "left",
                  justifyContent: "flex-start",
                  bgcolor:
                    tabs === dItem?.value
                      ? `${theme.palette.primary.main}22`
                      : theme.palette.light.main,
                  "& .MuiButton-icon": {
                    ml: "auto",
                  },
                }}
                fullWidth
                onClick={() => {
                  setTabs(dItem.value);
                  form.trigger();
                }}
                endIcon={tabs === dItem?.value && <LuChevronRight />}
              >
                {dItem.name}
              </Button>
            );
          })}
        </ButtonGroup>
      </Grid2>
      <Grid2 size={10}>
        {methodologyButtons?.map(
          (dataItem) =>
            dataItem.value === tabs && (
              <Controller
                name={dataItem.value as any}
                control={form.control}
                render={({ field }) => {
                  return (
                    <Box>
                      <GKEditor
                        {...field}
                        value={field.value || ""}
                        toolbar={[
                          "undo",
                          "redo",
                          "|",
                          "heading",
                          "bold",
                          "italic",
                          "underline",
                          "strikethrough",
                          "|",
                          "fontColor",
                          "fontBackgroundColor",
                          "|",
                          "alignment",
                          "link",
                          "|",
                          "bulletedList",
                          "numberedList",
                        ]}
                      />
                      <Typography fontSize={12} color={grey[500]}>
                        Note: {dataItem.placeholder}
                      </Typography>
                    </Box>
                  );
                }}
              />
            ),
        )}
      </Grid2>
    </Grid2>
  );
};

export default MethodologyTab;
