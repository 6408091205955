import { GridColDef } from "@mui/x-data-grid-pro";
import {
  CircularProgress,
  GKClientDataGrid,
  Grid2,
  StatusField,
  Switch,
  Tooltip,
  Typography,
} from "components";
import { useFetchQuery, useMutation } from "hooks/useQueries";
import DashboardLayout from "layouts/DashboardLayout";
import { useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { moneyCommaSeparator } from "utils";
import axiosInstance from "utils/axiosInstance";
import { handleCapitalize } from "utils/common";
import { extraColumnsField, matchDate, refetchQuery } from "utils/helpers";
import useDocumentTitle from "utils/useDocumentTitle";
import PortfolioStatusModal from "./PortfolioStatusModal";

const EquityBasketPortfolioMapping = () => {
  useDocumentTitle("Basket Portfolio Mapping");

  const { id } = useParams();

  const location = useLocation();

  const basketType = new URLSearchParams(location.search).get("basketType");

  const [selectedId, setSelectedId] = useState(null);

  const [openStausModal, setOpenStausModal] = useState(false);

  const { isFetching: isStatusGetLoading, data: allstatusByIdData } =
    useFetchQuery({
      key: ["GET_STATUS_BY_PORTFOLIO_ID"],
      route: `/model_portfolio/${id}/portfolio_status/?portfolio_id=${selectedId}`,
      enabled: Boolean(selectedId),
      success: () => {
        setOpenStausModal(true);
      },
    });

  const { isFetching: isLoading, data } = useFetchQuery({
    key: ["ALL_MAPPED_PORTFOLIOS"],
    route: `/model_portfolio/${id}/map/?get_all=true`,
  });

  const { isLoading: mapLoading, mutate: mapMutation } = useMutation({
    mutationFn: (data: { portfolioId: number[]; method: string }) =>
      axiosInstance.post(`/model_portfolio/${id}/${data.method}/`, {
        portfolio: data?.portfolioId,
      }),
    onSuccess: () => {
      refetchQuery("ALL_MAPPED_PORTFOLIOS");
    },
  });

  const columns: (GridColDef & { visibleType?: string[] })[] = [
    {
      headerName: "Client Name",
      disableColumnMenu: true,
      flex: 1,
      minWidth: 250,
      visibleType: ["Advisor", "Subscription"],
      field: "name",
      renderCell: (params) => {
        return (
          <Tooltip title={params?.row?.name}>
            <Grid2 size={11}>
              <Typography
                fontWeight={700}
                maxWidth={"100%"}
                textOverflow={"ellipsis"}
                overflow={"hidden"}
              >
                {params?.row?.clientName}
              </Typography>
              <Typography fontSize={12}>
                {params?.row?.name} - {params?.row?.portfolioId}
              </Typography>
            </Grid2>
          </Tooltip>
        );
      },
    },
    {
      ...extraColumnsField("Subscription Date", "subscriptionDate", 1, 1),
      valueFormatter: (params) => matchDate(params?.value),
      visibleType: ["Subscription"],
    },
    {
      ...extraColumnsField("Last Renewal Date", "lastRenewalDate", 1, 1),
      valueFormatter: (params) => matchDate(params?.value),
      visibleType: ["Subscription"],
    },
    {
      ...extraColumnsField("Next Renewal Date", "nextRenewalDate", 1, 1),
      valueFormatter: (params) => matchDate(params?.value),
      visibleType: ["Subscription"],
    },
    {
      ...extraColumnsField("Fee Plan Frequency", "frequency", 1, 1),
      valueFormatter: (params) => handleCapitalize(params?.value) || "-",
      visibleType: ["Subscription"],
    },
    {
      ...extraColumnsField("Renewal Type", "renewalType", 1, 1),
      valueFormatter: (params) => handleCapitalize(params?.value) || "-",
      visibleType: ["Subscription"],
    },
    {
      ...extraColumnsField("Onboarding Date", "onBoardingDate", 1, 1),
      valueFormatter: (params) => matchDate(params?.value),
      visibleType: ["Advisor"],
    },
    {
      ...extraColumnsField("Portfolio Value", "portfolioValue", 1, 1),
      visibleType: ["Advisor"],
      valueFormatter: (params) => moneyCommaSeparator(params?.value || 0),
      type: "number",
    },
    {
      ...extraColumnsField("Ledger/Margin Available", "availableCash", 1, 1),
      valueFormatter: (params) => moneyCommaSeparator(params?.value || 0),
      type: "number",
      visibleType: ["Advisor"],
    },
    {
      ...extraColumnsField(
        "Broker Gateway Status",
        "brokerGatewayStatus",
        1,
        1,
      ),
      renderCell: (params) => {
        return (
          <StatusField
            statusString={params?.row?.brokerGatewayStatus || "Pending"}
          />
        );
      },
    },
    {
      ...extraColumnsField("Status", "status", 1, 1),
      visibleType: ["Advisor"],
      renderCell: (params) => {
        return <StatusField statusString={params?.row?.status || "Pending"} />;
      },
    },
    {
      ...extraColumnsField("Mapped Status", "mappedStatus", 100, 1),
      align: "right",
      visibleType: ["Advisor"],
      headerAlign: "right",
      renderCell: (row) =>
        selectedId === row?.row?.id && mapLoading ? (
          <CircularProgress size={20} />
        ) : (
          <Switch
            checked={row?.row?.status?.toLowerCase() === "mapped"}
            onChange={(e) => {
              e.stopPropagation();
              e.preventDefault();
              mapMutation({
                method:
                  row?.row?.status?.toLowerCase() === "unmapped"
                    ? "map"
                    : "unmap",
                portfolioId: [row?.row?.id],
              });
            }}
          />
        ),
    },
  ];

  return (
    <DashboardLayout title="Model Portfolio Mapping">
      <Grid2 container spacing={1}>
        <Grid2 size={12}>
          <GKClientDataGrid
            name="mappedPortfolio"
            columns={columns?.filter((d) =>
              d?.visibleType?.includes(basketType),
            )}
            rows={data?.data || []}
            loading={isLoading || isStatusGetLoading}
            exportFile={{
              path: `/model_portfolio/${id}/map/`,
              exportName: "modelportfolio-map",
            }}
          />
        </Grid2>
      </Grid2>
      {openStausModal && (
        <PortfolioStatusModal
          setOpen={setOpenStausModal}
          open={openStausModal}
          data={allstatusByIdData?.data?.data}
          setSelectedId={setSelectedId}
          portfolioId={selectedId}
        />
      )}
    </DashboardLayout>
  );
};

export default EquityBasketPortfolioMapping;
