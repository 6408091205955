export const CHECK_FILE_TYPE_FOR_LOGO = (type: string) =>
  type === "image/jpeg" || type === "image/png" || type === "image/jpg";

export const CHECK_FILE_SIZE_FOR_LOGO = (size: number) =>
  size / 1024 / 1024 < 10;

interface Option {
  optionPoints: number;
}

interface QuestionField {
  options: Option[];
}

interface ReturnPointsParams {
  questionFields: QuestionField[];
  step: number;
}

export const returnPoints = ({
  questionFields,
  step,
}: ReturnPointsParams): string[] => {
  const sum = (arr: number[]): number => arr.reduce((acc, val) => acc + val, 0);
  const max = (arr: number[]): number => Math.max(...arr);
  const min = (arr: number[]): number => Math.min(...arr);

  if (!questionFields || questionFields.length === 0 || step <= 0) {
    return [];
  }

  const optionPointsList = questionFields.map((item) =>
    item.options.map((option) => +option.optionPoints),
  );

  const maxVal = sum(optionPointsList.map((points) => max(points)));
  const minValue = sum(optionPointsList.map((points) => min(points)));

  const totalRange = maxVal - minValue + 1;
  const baseSize = Math.floor(totalRange / step);
  const remainder = totalRange % step;

  const ranges: [number, number][] = [];
  let start = minValue;

  for (let i = 0; i < step; i++) {
    const end = start + baseSize - 1 + (i < remainder ? 1 : 0);
    ranges.push([start, end]);
    start = end + 1;
  }

  if (ranges[ranges.length - 1][1] < maxVal) {
    ranges[ranges.length - 1][1] = maxVal;
  }

  return ranges.map(([start, end]) => `${start} - ${end}`);
};
