import { useAppContext } from "AppContext";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/store";
import { createPortfolio } from "store/slices/portfolioSlice";
import { getActiveBrokers } from "utils/helpers";

interface FormData {
  name?: string;
  portfolioId?: string;
  client?: number;
  partners?: number[];
  broker?: string;
}

const useAddPortfolioModal = ({ setOpen }: any) => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const { loading: isSubmitting } = useAppSelector((state) => state.portfolio);

  const {
    state: {
      userData: { brokerSupport },
    },
  } = useAppContext();

  const [isClientCreateModalOpen, setIsClientCreateModalOpen] = useState(false);

  const form = useForm<FormData>({
    defaultValues: {
      name: "",
      portfolioId: "",
      client: Number(id) || undefined,
      partners: [],
      broker: getActiveBrokers(brokerSupport).length > 1 ? "" : "OTHER",
    },
  });

  const onSubmit = (data: FormData) => {
    dispatch(createPortfolio(data))
      .unwrap()
      .then(() => {
        setOpen(false);
      })
      .catch(() => {
        // Error is handled in the slice
      });
  };

  /** =========================================================================================== */
  return {
    isSubmitting,
    isClientCreateModalOpen,
    setIsClientCreateModalOpen,
    id,
    form,
    onSubmit,
  };
};

export default useAddPortfolioModal;
