import {
  AiOutlineLineChart,
  BiTachometer,
  Card,
  Chip,
  Grid2,
  LuSparkle,
  NoData,
  SimpleLoader,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "components";
import { useFetchQuery } from "hooks/useQueries";
import { PartnerBasketListInterface } from "interfaces/Partner";
import { handleRemoveUnderscore, numberToCurrency } from "utils";
import { paletteLightTheme } from "utils/ColorTheme";
import { handleCapitalize } from "utils/common";
import { getHexaColor } from "utils/constants";
import { matchDate } from "utils/helpers";
import useDocumentTitle from "utils/useDocumentTitle";

const blockStyle = {
  borderRight: `solid 1px ${paletteLightTheme.palette.grey[300]}`,
  padding: 16,
  width: "20%",
  background: "#F8FAFC",
};

interface Props {
  id: string;
}

const BasketTab = (props: Props) => {
  useDocumentTitle("Partner Basket");

  const { id } = props;

  const { data, isFetching } = useFetchQuery({
    key: ["PARTNER_BY_CLIENTS"],
    route: `/partner/${id}/baskets/`,
  });

  if (isFetching) return <SimpleLoader />;

  return data?.data?.totalCount !== 0 ? (
    <Grid2 container spacing={3}>
      {data?.data?.data?.map(
        (modelPortfolio: PartnerBasketListInterface, index: number) => {
          return (
            <TableContainer key={index}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell
                      colSpan={5}
                      sx={{
                        padding: "16px !important",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: 8,
                        }}
                      >
                        <Typography fontSize={18} fontWeight={600}>
                          {modelPortfolio?.name}
                        </Typography>
                        {modelPortfolio?.riskProfile?.map((dataItem) => {
                          return (
                            <Chip
                              icon={
                                <BiTachometer
                                  color={`${getHexaColor(dataItem)}`}
                                  size={16}
                                />
                              }
                              label={dataItem || "-"}
                              sx={{
                                borderRadius: 2,
                                p: "4px 8px 4px 3px",
                                background: `${getHexaColor(dataItem)}22`,
                                height: "unset",
                                color: `${getHexaColor(dataItem)}`,
                                "& .MuiChip-label": {
                                  pr: 0,
                                  pl: "10px",
                                },
                              }}
                            />
                          );
                        })}
                        {modelPortfolio?.volatility && (
                          <Chip
                            icon={<LuSparkle color="#8C1823" size={16} />}
                            label={
                              `${handleCapitalize(
                                modelPortfolio?.volatility,
                              )} volatility` || "-"
                            }
                            sx={{
                              borderRadius: 2,
                              p: "4px 8px 4px 3px",
                              background: `#8C182322`,
                              height: "unset",
                              color: "#8C1823",
                              textTransform: "capitalize",
                              "& .MuiChip-label": {
                                pr: 0,
                                pl: "10px",
                              },
                            }}
                          />
                        )}
                        {modelPortfolio?.timePeriod && (
                          <Chip
                            icon={
                              <AiOutlineLineChart color="#0F172A" size={16} />
                            }
                            label={
                              handleCapitalize(
                                handleRemoveUnderscore(
                                  modelPortfolio?.timePeriod,
                                ),
                              ) || "-"
                            }
                            sx={{
                              borderRadius: 2,
                              p: "4px 8px 4px 3px",
                              background: `#0F172A22`,
                              height: "unset",
                              textTransform: "capitalize",
                              color: "#0F172A",
                              "& .MuiChip-label": {
                                pr: 0,
                                pl: "10px",
                              },
                            }}
                          />
                        )}
                      </div>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={blockStyle}>
                      <Typography fontSize={12} color={"#697586"}>
                        AUM Mapped
                      </Typography>
                      <Typography fontWeight={600} fontSize={16}>
                        {numberToCurrency(modelPortfolio.mappedAum) || "-"}
                      </Typography>
                    </TableCell>
                    <TableCell style={blockStyle}>
                      <Typography fontSize={12} color={"#697586"}>
                        Mapped Portfolios
                      </Typography>
                      <Typography fontWeight={600} fontSize={16}>
                        {modelPortfolio.totalMappedPortfolios || "-"}
                      </Typography>
                    </TableCell>
                    <TableCell style={blockStyle}>
                      <Typography fontSize={12} color={"#697586"}>
                        Mapped Revenue
                      </Typography>
                      <Typography fontWeight={600} fontSize={16}>
                        {numberToCurrency(modelPortfolio.mappedRevenue) || "-"}
                      </Typography>
                    </TableCell>
                    <TableCell style={blockStyle}>
                      <Typography
                        fontSize={12}
                        color={"#697586"}
                        display={"flex"}
                        alignItems={"center"}
                        gap={1}
                      >
                        XIRR
                        {/* <LuRefreshCw size={12} /> */}
                      </Typography>
                      <Typography
                        fontWeight={600}
                        fontSize={16}
                        display={"flex"}
                        alignItems={"center"}
                        gap={1}
                      >
                        {modelPortfolio.xirr || "-"}
                        {/* <span
                        style={{
                          fontSize: "10px",
                          fontWeight: 400,
                          color: "#9AA4B2",
                        }}
                      >
                        (*As of 23/04/24 11:59)
                      </span> */}
                      </Typography>
                    </TableCell>
                    <TableCell style={blockStyle}>
                      <Typography fontSize={12} color={"#697586"}>
                        Last Updated on
                      </Typography>
                      <Typography fontWeight={600} fontSize={16}>
                        {matchDate(modelPortfolio.lastChange[1]) || "-"}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          );
        },
      )}
    </Grid2>
  ) : (
    <Grid2 container>
      <Grid2 size={12}>
        <Card style={{ height: "100%" }}>
          <NoData text="No equity basket found." />
        </Card>
      </Grid2>
    </Grid2>
  );
};

export default BasketTab;
