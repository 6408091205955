import { Avatar, Grid2, IconButton, styled } from "@mui/material";
import EditLogoModal from "Pages/CompanyInformation/EditLogoModal";
import { useState } from "react";
import { FiTrash2 } from "react-icons/fi";
import GKButton from "./GKButton";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const GKImageUploader = ({
  handleProfilePicDelete,
  selectedImage,
  setSelectedImage,
  croppedImage,
  setCroppedImage,
  form,
  logoName,
  buttonName,
}: {
  handleProfilePicDelete: any;
  selectedImage: any;
  setSelectedImage: any;
  croppedImage: any;
  setCroppedImage: any;
  form: any;
  logoName: string;
  buttonName: string;
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleProfilePicChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImage(reader.result as string);
        form.setValue(logoName, [file]);
        setIsModalOpen(true);
      };
      reader.readAsDataURL(file);
      event.target.value = "";
    }
  };

  const handleCropComplete = (croppedImageUrl: string) => {
    setCroppedImage(croppedImageUrl);
    setIsModalOpen(false);
    fetch(croppedImageUrl)
      .then((res) => res.blob())
      .then((blob) => {
        const file = new File(
          [blob],
          `company-profile.${blob.type.split("/")[1]}`,
          {
            type: blob?.type,
          },
        );
        form.setValue(logoName, [file], { shouldDirty: true });
      });
  };

  return (
    <>
      <Grid2 container size={12} display={"flex"} alignItems={"center"} gap={1}>
        <Grid2>
          <Avatar
            alt=""
            src={croppedImage || selectedImage || "/Logos/errorImg.svg"}
            sx={{
              width: 72,
              height: 72,
              border: "1px solid #eee",
            }}
          />
        </Grid2>
        <Grid2 ml={1}>
          <GKButton
            variant="contained"
            component="label"
            role={undefined}
            tabIndex={-1}
          >
            {buttonName}
            <VisuallyHiddenInput
              type="file"
              accept={".jpg, .png, .svg"}
              onChange={handleProfilePicChange}
            />
          </GKButton>
        </Grid2>
        <Grid2>
          <IconButton
            sx={{ border: "1px solid #CDD5DF", borderRadius: "8px", p: 1 }}
            onClick={handleProfilePicDelete}
          >
            <FiTrash2 size={18} />
          </IconButton>
        </Grid2>
      </Grid2>
      {isModalOpen && (
        <EditLogoModal
          open={isModalOpen}
          setOpen={setIsModalOpen}
          img={selectedImage || form.getValues(logoName)}
          onCropComplete={handleCropComplete}
        />
      )}
    </>
  );
};

export default GKImageUploader;
