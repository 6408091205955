import {
  Box,
  GKSelect,
  GKTextField,
  Grid2,
  MenuItem,
  Show,
  StatusField,
  Typography,
} from "components";
import { useMutation } from "hooks/useQueries";
import { AdvisorOnboardFormInterface } from "interfaces/OnboardingInterface";
import { isEmpty } from "lodash";
import { Controller, useFormContext } from "react-hook-form";
import { BsExclamationDiamond } from "react-icons/bs";
import { TbRosetteDiscountCheck } from "react-icons/tb";
import { handleRemoveUnderscore } from "utils";
import axiosInstance from "utils/axiosInstance";
import { handleCapitalize } from "utils/common";
import { KYC_OPTIONS } from "utils/constants";
import { refetchQuery } from "utils/helpers";
import { QueryKeys } from "utils/queryKey";
import { ThemeContainer } from "utils/Theme";
import { panRegex } from "utils/validationSchema";

const AdvisorOnboardingKYC = () => {
  const form = useFormContext<AdvisorOnboardFormInterface>();

  const { theme } = ThemeContainer();

  // ================================================================================================================= clientData={addedClientData}====
  const { mutate: handleKYC } = useMutation({
    mutationKey: ["HANDLE_KYC_ADVISOR"],
    mutationFn: (values: any) =>
      axiosInstance.post(`/kyc/${form.getValues("id")}/`, {
        client: values?.id,
        pan: values.personalDetails.pan,
        identificationType: values.identification_type,
        kycStatus: values.kyc_status,
      }),
    onSuccess: () => {
      refetchQuery(QueryKeys.GET_COMPLIANCE_BY_CLIENT_ID);
      refetchQuery(QueryKeys.GET_CLIENT_BY_ID);
      form.setValue("activeStep", form.getValues("activeStep") + 1);
    },
  });

  return (
    <form
      id="advisor-onboarding-kyc-form"
      onSubmit={form.handleSubmit((values) => {
        if (
          isEmpty(form.getValues("kyc.kycId")) ||
          isEmpty(form.getValues("kyc.kycStatus"))
        ) {
          handleKYC(values);
        } else {
          form.setValue("activeStep", form.getValues("activeStep") + 1);
        }
      })}
      style={{
        height: form.getValues("kyc") && "100%",
        width: form.getValues("kyc") && "100%",
        display: form.getValues("kyc") && "flex",
      }}
    >
      <Show.When isTrue={!form.getValues("kyc")}>
        <Grid2 container spacing={1}>
          <Grid2 size={12}>
            <Typography fontSize={24} fontWeight={600}>
              KYC{" "}
            </Typography>
          </Grid2>
          <Grid2 size={6}>
            <Controller
              name="identification_type"
              control={form.control}
              rules={{
                required: {
                  value: true,
                  message: "Please select identification type",
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <GKSelect
                  {...field}
                  requiredField
                  inputLabel="KYC Type"
                  error={Boolean(error)}
                >
                  <MenuItem style={{ display: "none" }} value="">
                    Select Identification Type
                  </MenuItem>
                  {KYC_OPTIONS?.map((data) => (
                    <MenuItem value={data?.value} key={data?.value}>
                      {data?.name}
                    </MenuItem>
                  ))}
                </GKSelect>
              )}
            />
          </Grid2>
          <Grid2 size={6}>
            <Controller
              rules={{
                required: {
                  value: true,
                  message: "Please enter PAN",
                },
                pattern: {
                  value: panRegex,
                  message: "PAN should have this format ABCDE1234A",
                },
                maxLength: {
                  value: 10,
                  message: "PAN should have 10 character",
                },
                minLength: {
                  value: 10,
                  message: "PAN should have 10 character",
                },
              }}
              name="personalDetails.pan"
              control={form.control}
              render={({ field, fieldState: { error } }) => (
                <GKTextField
                  {...field}
                  inputLabel="PAN"
                  requiredField
                  error={!!error}
                  onChange={(e) => field.onChange(e.target.value.toUpperCase())}
                  helperText={error?.message}
                />
              )}
            />
          </Grid2>
        </Grid2>
      </Show.When>
      <Show.When isTrue={Boolean(form.getValues("kyc"))}>
        <Grid2
          spacing={2}
          container
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          width={"70%"}
          m={"auto"}
        >
          <Grid2 size={12} textAlign={"center"}>
            {["completed", "success"].includes(
              form.getValues("kyc.kycStatus")?.toLowerCase(),
            ) ? (
              <TbRosetteDiscountCheck
                color={theme.palette.success.main}
                size={30}
              />
            ) : (
              <BsExclamationDiamond
                color={theme.palette.warning.main}
                size={30}
              />
            )}
            <Typography fontWeight={600} textTransform={"capitalize"}>
              KYC{" "}
              {handleCapitalize(
                handleRemoveUnderscore(
                  form.getValues("kyc.kycStatus") || "pending",
                ),
              )}
            </Typography>
          </Grid2>
          <Grid2 size={12}>
            <Box
              sx={{
                p: 1,
                background: "#F8FAFC",
                borderRadius: 2,
              }}
            >
              <Grid2 container spacing={2}>
                <Grid2
                  size={12}
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  <Typography fontWeight={600} fontSize={20}>
                    {form.getValues("kyc.name")}
                  </Typography>
                  <StatusField
                    statusString={handleCapitalize(
                      handleRemoveUnderscore(
                        form.getValues("kyc.kycStatus") || "pending",
                      ),
                    )}
                  />
                </Grid2>
                <Grid2
                  size={12}
                  bgcolor={theme.palette.light.main}
                  display={"grid"}
                  gridTemplateColumns={"auto auto auto"}
                  p={1}
                >
                  <Box>
                    <Typography color={theme.palette.secondaryText.main}>
                      KYC Type
                    </Typography>
                    <Typography fontWeight={600}>
                      {
                        KYC_OPTIONS?.find(
                          (d) =>
                            d.value ===
                            form.getValues("kyc.identificationType"),
                        )?.name
                      }
                    </Typography>
                  </Box>
                  <Box>
                    <Typography color={theme.palette.secondaryText.main}>
                      PAN
                    </Typography>
                    <Typography fontWeight={600}>
                      {form.getValues("kyc.pan")}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography color={theme.palette.secondaryText.main}>
                      KYC ID
                    </Typography>
                    <Typography fontWeight={600}>
                      {form.getValues("kyc.kycId")}
                    </Typography>
                  </Box>
                </Grid2>
              </Grid2>
            </Box>
          </Grid2>
        </Grid2>
      </Show.When>
    </form>
  );
};

export default AdvisorOnboardingKYC;
