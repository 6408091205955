import { LoadingButton } from "@mui/lab";
import { GKModal, GKTextField, Grid2 } from "components";
import { useMutation } from "hooks/useQueries";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import axiosInstance from "utils/axiosInstance";
import { GKModalProps } from "utils/commonInterface";
import ApproveAutoContract from "./ApproveAutoContract";

interface Props {
  portfolio: any;
  broker: any;
  fileId: any;
}

const UploadPasswordModal = ({
  open,
  setOpen,
  portfolio,
  broker,
  fileId,
}: Props & GKModalProps) => {
  const [approveModalData, setApproveModalData] = React.useState<any>([]);

  const [isApproveModalOpen, setIsApproveModalOpen] = React.useState(false);

  const { mutate: handleRetry, isLoading: retryLoading } = useMutation({
    mutationFn: (data: { portfolio: any; broker: any; id: any }) =>
      axiosInstance.post(`/refresh-auto-forward/`, data),
    onSuccess: (response: any) => {
      setApproveModalData(response?.data?.data?.transactions);
      setIsApproveModalOpen(true);
    },
  });

  const { mutate: handleSetPassword, isLoading: passwordLoading } = useMutation(
    {
      mutationFn: (data: { portfolio: any; broker: any; password: any }) =>
        axiosInstance?.post(`/auto-forward-password/`, data),
      onSuccess: () => {
        handleRetry({
          portfolio: portfolio,
          broker: broker,
          id: fileId,
        });
      },
    },
  );

  const form = useForm({
    defaultValues: {
      portfolio: portfolio,
      broker: broker,
      password: "",
    },
  });

  return (
    <>
      <GKModal
        open={open}
        setOpen={setOpen}
        modalTitle="Confirm Password"
        footer={
          <LoadingButton
            variant="contained"
            form="password-form"
            type="submit"
            loading={passwordLoading || retryLoading}
          >
            {"Submit"}
          </LoadingButton>
        }
      >
        <form
          onSubmit={form.handleSubmit((values) => handleSetPassword(values))}
          id="password-form"
        >
          <Grid2 container>
            <Grid2 size={12}>
              <Controller
                control={form.control}
                name="password"
                rules={{
                  required: {
                    value: true,
                    message: "Please enter password",
                  },
                }}
                render={({ field, fieldState: { error } }) => (
                  <GKTextField
                    {...field}
                    requiredField
                    inputLabel="Password"
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              />
            </Grid2>
          </Grid2>
        </form>
      </GKModal>
      {isApproveModalOpen && (
        <ApproveAutoContract
          open={isApproveModalOpen}
          setOpen={setIsApproveModalOpen}
          recordID={fileId}
          recordData={approveModalData}
          setPasswordModal={setOpen}
          passwordModal={open}
        />
      )}
    </>
  );
};

export default UploadPasswordModal;
