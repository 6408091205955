import { GridColDef } from "@mui/x-data-grid-pro";
import MutualFundModal from "Pages/MutualFunds/MutualFundModal";
import {
  Box,
  BsExclamationOctagon,
  CgMathPlus,
  Chip,
  ConfirmDeleteModal,
  GKButton,
  GKDataGrid,
  Grid2,
  IconButton,
  Show,
  TableColumnModal,
  Tooltip,
  Typography,
} from "components";
import GKCommonTableFilterModal from "components/GKCommonTableFilterModal";
import usePortfolioHoldingDetails from "hooks/usePortfolioHoldingDetails";
import { useState } from "react";
import { paletteLightTheme } from "utils/ColorTheme";
import { moneyCommaSeparator } from "utils/MathFunction";
import { PORTFOLIO_HOLDING_BY_ID } from "utils/apiRoutes";
import {
  handleReturnPositiveNegative,
  returnInstaTypeColor,
} from "utils/colorHelper";
import { UserRoleTypes } from "utils/common";
import { extraColumnsField } from "utils/helpers";
import useDocumentTitle from "utils/useDocumentTitle";

const PortfolioHoldingDetails = ({
  portfolioExtendedViewTabs,
}: {
  portfolioExtendedViewTabs: number;
}) => {
  useDocumentTitle("Holding Details");

  const {
    id,
    deletePortfolio,
    loading,
    openConfirmationModal,
    setOpenConfirmationModal,
    navigate,
    allHoldings,
    columnsPersist,
    tableColumnModalOpen,
    setTableColumnModalOpen,
    filterModal,
    setFilterModal,
    holdingsData,
    userData,
  } = usePortfolioHoldingDetails({ portfolioExtendedViewTabs });

  const [openMutualFundModal, setOpenMutualFundModal] = useState(false);

  const columns: (GridColDef & { visibleType?: number[] })[] = [
    {
      ...extraColumnsField("Company Name", "name", 230, 1),
      headerName: `Company Name  (${allHoldings?.length})`,
      renderCell: ({ row }) => {
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
              gap: 4,
            }}
          >
            <div style={{ display: "flex", alignItems: "center", gap: 4 }}>
              {portfolioExtendedViewTabs === 1 && Boolean(row?.instType) && (
                <Chip
                  sx={{
                    borderRadius: 1,
                    p: "4px",
                    background: `${returnInstaTypeColor(row?.instType)}22`,
                    height: "unset",
                    color: returnInstaTypeColor(row?.instType),
                    "& .MuiChip-label": {
                      pr: 0,
                      pl: 0,
                    },
                  }}
                  label={row?.instType || "-"}
                />
              )}
              <Tooltip title={row?.name} arrow placement="top">
                <Typography
                  sx={{
                    maxWidth: 110,
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                  }}
                >
                  {row?.name || " "}
                </Typography>
              </Tooltip>
              {!row?.isValid && Boolean(row?.isin) && (
                <Tooltip title="There might be some discrepancies in the trades of this asset.">
                  <IconButton
                    color="error"
                    sx={{
                      background: "transparent",
                      "&:hover": {
                        background: "transparent",
                        borderColor: "transparent",
                        color: paletteLightTheme.palette.error.main,
                      },
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    <BsExclamationOctagon size={14} />
                  </IconButton>
                </Tooltip>
              )}
            </div>
            {row?.ltHoldingsCount > 0 && (
              <Tooltip title="Long Term Holding" arrow placement="top">
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    color: paletteLightTheme.palette.primary.main,
                    padding: 0,
                  }}
                >
                  <img src="/Logos/longTerm.svg" alt="" />
                  &nbsp;
                  {row?.instSubType !== "CE" || row.isin === "INGridkeyCash"
                    ? row?.ltHoldingsCount
                    : row?.ltHoldingsCount}
                </Box>
              </Tooltip>
            )}
          </div>
        );
      },
      visibleType: [1, 2, 3, 4, 5, 6, 7, 8],
    },
    {
      ...extraColumnsField("Folio No.", "folioNo", 100, 1),
      visibleType: [3],
    },
    {
      ...extraColumnsField("Fd Account No", "fdAccountNo", 120, 1),
      visibleType: [7],
    },
    {
      ...extraColumnsField("Quantity", "rawQuantity", 130, 1),
      type: "number",
      visibleType: [1, 2, 3, 4, 5, 6, 8],
    },
    {
      ...extraColumnsField("Avg. Buy Price", "avgBuyPrice", 150, 1),
      type: "number",
      valueFormatter: (params) => moneyCommaSeparator(params.value),
      visibleType: [1, 2, 3, 4, 5, 6, 8],
    },
    {
      ...extraColumnsField("Invested Value", "investedAmount", 150, 1),
      valueFormatter: (params) => moneyCommaSeparator(params.value),
      type: "number",
      visibleType: [1, 2, 3, 4, 5, 6, 7, 8],
    },
    {
      ...extraColumnsField("CMP", "cmp", 110, 1),
      valueFormatter: (params) => moneyCommaSeparator(params.value),
      type: "number",
      visibleType: [1, 2, 3, 4, 5, 6, 8],
    },
    {
      ...extraColumnsField("Current Value", "currentAmount", 140, 1),
      valueFormatter: (params) => moneyCommaSeparator(params.value),
      type: "number",
      visibleType: [1, 2, 3, 4, 5, 6, 7, 8],
    },
    {
      ...extraColumnsField("Unrealised Gain", "unrealisedGain", 140, 1),
      valueFormatter: (params) => moneyCommaSeparator(params.value),
      type: "number",
      renderCell: (params) => {
        return (
          <Typography
            color={handleReturnPositiveNegative(params?.row?.unrealisedGain)}
          >
            {moneyCommaSeparator(params?.row?.unrealisedGain)}
          </Typography>
        );
      },
      visibleType: [1, 2, 3, 4, 5, 6, 7, 8],
    },
    {
      ...extraColumnsField("Unrealised Gain %", "absoluteReturn", 150, 1),
      valueFormatter: (params) => moneyCommaSeparator(params.value),
      type: "number",
      renderCell: (params) => {
        return (
          <Typography
            color={handleReturnPositiveNegative(params?.row?.absoluteReturn)}
          >
            {moneyCommaSeparator(params?.row?.absoluteReturn)}
          </Typography>
        );
      },
      visibleType: [1, 2, 3, 4, 5, 6, 7, 8],
    },
    {
      ...extraColumnsField("Today's Gain", "todaysGain", 150, 1),
      valueFormatter: (params) => moneyCommaSeparator(params.value),
      type: "number",
      renderCell: (params) => {
        return (
          <Typography
            color={handleReturnPositiveNegative(params?.row?.todaysGain)}
          >
            {moneyCommaSeparator(params?.row?.todaysGain)}
          </Typography>
        );
      },
      visibleType: [1, 2, 3, 4, 5, 6, 7, 8],
    },
    {
      ...extraColumnsField("Today's Gain %", "todaysGainPercentage", 150, 1),
      renderCell: (params) => {
        return (
          <Typography
            color={handleReturnPositiveNegative(
              params?.row?.todaysGainPercentage,
            )}
          >
            {moneyCommaSeparator(params?.row?.todaysGainPercentage)}
          </Typography>
        );
      },
      type: "number",
      visibleType: [1, 2, 3, 4, 5, 6, 7, 8],
    },
    {
      ...extraColumnsField("Weightage(%)", "weightage", 130, 1),
      valueFormatter: (params) => moneyCommaSeparator(params.value),
      type: "number",
      disableColumnMenu: true,
      visibleType: [1, 2, 3, 4, 5, 6, 7, 8],
    },
  ];

  const tabActions: Record<
    number,
    {
      navigateTo?: string;
      transactionType?: string;
      openModal?: string;
      assetType?: string;
    }
  > = {
    "2": { navigateTo: "/app/create-transaction", transactionType: "" },
    "3": { openModal: "mutualFund" },
    "4": { navigateTo: "/app/create-transaction", transactionType: "ETF" },
    "6": {
      navigateTo: "/app/create-transaction",
      transactionType: "OTHER-ASSETS",
      assetType: "PEQ",
    },
    "7": {
      navigateTo: "/app/create-fixed-deposit-transaction",
      transactionType: "OTHER-ASSETS",
      assetType: "FD",
    },
    "8": {
      navigateTo: "/app/create-transaction",
      transactionType: "OTHER-ASSETS",
      assetType: "AIF",
    },
  };

  return (
    <>
      <Grid2 container spacing={1}>
        <Grid2 size={12}>
          <GKDataGrid
            loading={loading}
            density="compact"
            name="portfolioHoldingsTable"
            columns={columns.filter((column) => {
              return column.visibleType?.includes(portfolioExtendedViewTabs);
            })}
            rows={
              allHoldings?.map((d) => {
                return {
                  isValid: d?.isValid,
                  instType: d?.instType,
                  instSubType: d?.instSubType,
                  id: d?.id,
                  isin: d?.isin,
                  gcode: d?.gcode,
                  name: d?.name,
                  cap: d?.cap,
                  sector: d?.sector,
                  currentAmount: d?.currentAmount,
                  folioNo: d?.folioNo,
                  fdAccountNo: d?.fdAccountNo,
                  bse: d?.bse,
                  nse: d?.nse,
                  unrealisedGain:
                    d?.isin === "INGridkeyCash" ? null : d?.unrealisedGain,
                  realisedGain:
                    d?.isin === "INGridkeyCash" ? null : d?.realisedGain,
                  otherGains:
                    d?.isin === "INGridkeyCash" ? null : d?.otherGains,
                  quantity: d?.isin === "INGridkeyCash" ? null : d?.quantity,
                  rawQuantity:
                    d?.isin === "INGridkeyCash" ? null : d?.rawQuantity,
                  investedAmount:
                    d?.isin === "INGridkeyCash" ? null : d?.investedAmount,
                  totalNetInvestment:
                    d?.isin === "INGridkeyCash" ? null : d?.totalNetInvestment,
                  totalAmount:
                    d?.isin === "INGridkeyCash" ? null : d?.totalAmount,
                  todaysGain:
                    d?.isin === "INGridkeyCash" ? null : d?.todaysGain,
                  todaysGainPercentage:
                    d?.isin === "INGridkeyCash"
                      ? null
                      : d?.todaysGainPercentage,
                  lastTradedPrice:
                    d?.isin === "INGridkeyCash" ? null : d?.lastTradedPrice,
                  avgBuyPrice:
                    d?.isin === "INGridkeyCash" ? null : d?.avgBuyPrice,
                  cmp: d?.isin === "INGridkeyCash" ? null : d?.cmp,
                  yesterdayValue:
                    d?.isin === "INGridkeyCash" ? null : d?.yesterdayValue,
                  unrealisedGainPercentage:
                    d?.isin === "INGridkeyCash"
                      ? null
                      : d?.unrealisedGainPercentage,
                  absoluteReturn:
                    d?.isin === "INGridkeyCash" ? null : d?.absoluteReturn,
                  ltHoldingsCount:
                    d?.isin === "INGridkeyCash" ? null : d?.ltHoldingsCount,
                  weightage: d?.weightage,
                  lastPrice: d?.isin === "INGridkeyCash" ? null : d?.lastPrice,
                  pastPrice: d?.isin === "INGridkeyCash" ? null : d?.pastPrice,
                };
              }) || []
            }
            exportFile={{
              path: PORTFOLIO_HOLDING_BY_ID(+id),
              exportName: "portfolios-holdings",
            }}
            zeroHolding
            onRowClick={(params) => {
              if (
                params.row?.instType === "CASH" &&
                params.row?.name === "Ledger Balance"
              )
                return null;

              if (params.row.id === 100000) {
                return null;
              }

              navigate(
                `/app/holdings/${params?.row.gcode}/transactions?type=${params?.row?.instType}`,
                {
                  state: {
                    holdingId: id,
                    gcode: params?.row?.gcode,
                    name: params?.row?.name,
                    folioNo: params?.row?.folioNo,
                    quantity: params?.row?.rawQuantity,
                    investedAmount: params?.row?.investedAmount,
                    currentAmount: params?.row?.currentAmount,
                    fdAccountNo: params?.row?.fdAccountNo,
                    type: "portfolios",
                  },
                },
              );

              return null;
            }}
            totalCount={holdingsData?.data?.totalCount}
            tableName="portfolio_holdings_table"
            headerComponent={
              userData?.userRole !== UserRoleTypes.client && (
                <Grid2
                  container
                  display={"flex"}
                  alignItems={"center"}
                  gap={1}
                  justifyContent={"flex-end"}
                >
                  {tabActions[portfolioExtendedViewTabs] && (
                    <GKButton
                      variant="outlined"
                      color="dark"
                      onClick={() => {
                        const action = tabActions[portfolioExtendedViewTabs];
                        if (action?.navigateTo) {
                          navigate(action.navigateTo, {
                            state: {
                              id: id,
                              transactionType: action.transactionType || "",
                              assetType: action.assetType || "",
                            },
                          });
                        } else if (action?.openModal === "mutualFund") {
                          setOpenMutualFundModal(true);
                        }
                      }}
                      startIcon={<CgMathPlus size={14} />}
                    >
                      Add Trade
                    </GKButton>
                  )}
                  {portfolioExtendedViewTabs === 4 && (
                    <GKButton
                      variant="outlined"
                      color="dark"
                      onClick={() =>
                        navigate(
                          `/app/holdings/portfolio/${id}/etf-transaction`,
                        )
                      }
                    >
                      ETF Interest
                    </GKButton>
                  )}
                </Grid2>
              )
            }
            pinnedRows={{
              top:
                allHoldings.length > 0
                  ? [
                      {
                        unrealisedGain: holdingsData?.data?.unrealisedGain,
                        realisedGain: holdingsData?.data?.realisedGain,
                        otherGains: holdingsData?.data?.otherGains,
                        investedAmount: holdingsData?.data?.investedAmount,
                        totalNetInvestment:
                          holdingsData?.data?.totalNetInvestment,
                        currentAmount: holdingsData?.data?.currentAmount,
                        totalAmount: holdingsData?.data?.totalAmount,
                        todaysGain: holdingsData?.data?.todaysGain,
                        todaysGainPercentage:
                          holdingsData?.data?.todaysGainPercentage,
                        lastTradedPrice: holdingsData?.data?.lastTradedPrice,
                        yesterdayValue: holdingsData?.data?.yesterdayValue,
                        unrealisedGainPercentage:
                          holdingsData?.data?.unrealisedGainPercentage,
                        absoluteReturn: holdingsData?.data?.absoluteReturn,
                        ltHoldingsCount: holdingsData?.data?.ltHoldingsCount,
                        instType: null,
                        folioNo: holdingsData?.data?.folioNo,
                        weightage: holdingsData?.data?.totalWeightage,
                        id: 100000,
                        instSubType: null,
                        cmp: " ",
                        isin: " ",
                        isValid: true,
                        quantity: "",
                        rawQuantity: " ",
                        avgBuyPrice: " ",
                        gcode: " ",
                        name: " ",
                        fullName: " ",
                        cap: " ",
                        sector: " ",
                        bse: " ",
                        nse: " ",
                        lastPrice: holdingsData?.data?.lastPrice,
                        pastPrice: holdingsData?.data?.pastPrice,
                      },
                    ]
                  : [],
            }}
          />
        </Grid2>

        {openConfirmationModal && (
          <ConfirmDeleteModal
            open={openConfirmationModal}
            setOpen={setOpenConfirmationModal}
            title="Portfolio"
            onDelete={() => deletePortfolio(Number(id))}
            isDelete
          />
        )}
      </Grid2>

      <Show.When isTrue={filterModal}>
        <GKCommonTableFilterModal
          open={filterModal}
          setOpen={setFilterModal}
          tableName={"portfolio_holding_list"}
          name={"portfolioHolding"}
          zeroHolding
        />
      </Show.When>
      <Show.When isTrue={tableColumnModalOpen}>
        <TableColumnModal
          open={tableColumnModalOpen}
          setOpen={setTableColumnModalOpen}
          columns={columns}
          columnVisibilityModel={columnsPersist}
          name={"portfolioHoldingList"}
        />
      </Show.When>

      <Show.When isTrue={openMutualFundModal}>
        <MutualFundModal
          open={openMutualFundModal}
          setOpen={setOpenMutualFundModal}
          mutualFundData={{}}
          portfolioId={id}
        />
      </Show.When>
    </>
  );
};

export default PortfolioHoldingDetails;
