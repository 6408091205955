/* eslint-disable prefer-const */
import { LoadingButton } from "@mui/lab";
import { useAppContext } from "AppContext";
import {
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  GKRadio,
  GKSearchSelect,
  GKTextField,
  Grid2,
  Radio,
  RadioGroup,
  SimpleLoader,
  TextField,
  Typography,
} from "components";
import { useFetchQuery, useMutation } from "hooks/useQueries";
import { isEmpty } from "lodash";
import InvestorSettingsConfirmation from "Pages/Settings/InvestorSettingsConfirmation";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import axiosInstance from "utils/axiosInstance";
import { AGREEMENT_SIGNATURE_SOURCE, KYC_OPTIONS } from "utils/constants";
import { handleFreshReturnFormData } from "utils/formHelpers";
import { handleReturnAutocompletePlaceholder } from "utils/StringFunctions";
import { ThemeContainer } from "utils/Theme";
import useDocumentTitle from "utils/useDocumentTitle";
import { validateNotWhitespaceOnly } from "utils/validationSchema";

interface FormValues {
  id?: number;
  domainName?: string;
  paymentMethods?: string;
  paymentStep?: true;
  kycType?: string;
  agreement?: string;
  codePrefix?: string;
  revenueWithGst?: string;
  codeLength?: number;
  companyLogo?: any;
  qrCode?: any;
  companyMetaName?: string;
  tagLine?: string;
  tagLineBody?: string;
  colorPalette?: {
    primary: string;
    secondary: string;
  };
  advisorPhoto?: any;
  advisorName?: string;
  invoiceSeries?: string;
  sebiRegistered?: string;
  yearsOfExperience?: number;
  advisorInfo?: string;
  advisorPhone?: string;
  advisorEmail?: string;
  advisorAddress?: string;
  company?: number;
  isVerified?: boolean;
}

const WebsiteConfigurations = () => {
  useDocumentTitle("Investor Settings");

  const { theme } = ThemeContainer();

  const {
    state: { userData },
  } = useAppContext();

  const [investorSettingsConfirmation, setInvestorSettingsConfirmation] =
    useState(false);

  const { data, refetch, isFetching } = useFetchQuery({
    key: ["GET_INVESTOR_PROFILE"],
    route: `/company/${userData?.companyId}/publish_settings/`,
  });

  const { mutate: updateInvestorProfile, isLoading: updateInvestorLoading } =
    useMutation({
      mutationKey: ["GET_INVESTOR_PROFILE_DOMAIN"],
      mutationFn: (data: any) =>
        axiosInstance.patch(
          `/company/${userData?.companyId}/publish_settings/`,
          data,
        ),
      onSuccess: () => {
        refetch();
        setInvestorSettingsConfirmation(false);
      },
    });

  const form = useForm<FormValues>({
    defaultValues: {
      domainName: "",
      revenueWithGst: "false",
      paymentMethods: "",
      invoiceSeries: "",
      paymentStep: null,
      kycType: "",
      agreement: "",
      codePrefix: "",
      codeLength: 3,
      companyLogo: null,
      qrCode: null,
      companyMetaName: "",
      tagLine: "",
      tagLineBody: "",
      advisorPhoto: null,
      advisorName: "",
      sebiRegistered: "",
      yearsOfExperience: 0,
      advisorInfo: "",
      advisorPhone: "",
      advisorEmail: "",
      advisorAddress: "",
      colorPalette: {
        primary: "#006580",
        secondary: "#63A387",
      },
      isVerified: false,
    },
    values: {
      ...data?.data,
      agreementTemplate: "",
      colorPalette: data?.data?.colorPalette
        ? JSON.parse(data?.data?.colorPalette)
        : {
            primary: "#006580",
            secondary: "#63A387",
          },
    },
  });

  const prefixWatch = form.watch(["codePrefix", "codeLength"]);

  if (isFetching) return <SimpleLoader />;

  return (
    <form
      onSubmit={form.handleSubmit((values: FormValues) => {
        let newObject = { ...values };
        if (
          isEmpty(newObject?.companyLogo) ||
          typeof newObject?.companyLogo === "string"
        ) {
          delete newObject.companyLogo;
        } else {
          newObject = { ...newObject, companyLogo: values.companyLogo[0] };
        }
        if (
          isEmpty(newObject?.qrCode) ||
          typeof newObject?.qrCode === "string"
        ) {
          delete newObject.qrCode;
        } else {
          newObject = { ...newObject, qrCode: values.qrCode[0] };
        }
        if (
          isEmpty(newObject?.advisorPhoto) ||
          typeof newObject?.advisorPhoto === "string"
        ) {
          delete newObject.advisorPhoto;
        } else {
          newObject = { ...newObject, advisorPhoto: values.advisorPhoto[0] };
        }

        updateInvestorProfile(
          handleFreshReturnFormData({
            isEditForm: false,
            obj: {
              ...newObject,
            },
          }),
        );
      })}
      id="investor-setting-form"
    >
      <Grid2 container spacing={2}>
        <Grid2 size={3}>
          <Typography
            fontSize={16}
            fontWeight={500}
            color={theme.palette.secondaryText.main}
          >
            Onboarding
          </Typography>
        </Grid2>

        <Grid2
          container
          size={9}
          spacing={2}
          display={"flex"}
          alignItems={"center"}
        >
          <Grid2 size={6}>
            <Controller
              name="kycType"
              control={form.control}
              rules={{
                required: {
                  value: true,
                  message: "Please select KYC type",
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <GKSearchSelect
                  {...field}
                  disableClearable
                  requiredField
                  inputLabel="KYC Type"
                  options={KYC_OPTIONS}
                  onChange={(event, val) => field.onChange(val?.value)}
                  value={
                    KYC_OPTIONS.find((type) => type.value === field.value)
                      ?.name || ""
                  }
                  getOptionLabel={(option) => option.name || option}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="kycType"
                      placeholder={handleReturnAutocompletePlaceholder({
                        placeholder: "Select KYC",
                        value: field.value,
                      })}
                      error={!!error}
                      helperText={error?.message}
                    />
                  )}
                />
              )}
            />
          </Grid2>
          <Grid2 size={6}>
            <Controller
              name="agreement"
              control={form.control}
              rules={{
                required: {
                  value: true,
                  message: "Please select agreement",
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <GKSearchSelect
                  {...field}
                  disableClearable
                  requiredField
                  inputLabel="Agreement"
                  options={AGREEMENT_SIGNATURE_SOURCE}
                  onChange={(event, val) => field.onChange(val?.value)}
                  value={
                    AGREEMENT_SIGNATURE_SOURCE.find(
                      (type) => type.value === field.value,
                    )?.name || []
                  }
                  getOptionLabel={(option) => option.name || option}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="agreement"
                      placeholder={handleReturnAutocompletePlaceholder({
                        placeholder: "Select Agreement",
                        value: field.value,
                      })}
                      error={!!error}
                      helperText={error?.message}
                    />
                  )}
                />
              )}
            />
          </Grid2>
        </Grid2>

        <Grid2 size={12} my={1}>
          <Divider />
        </Grid2>

        <Grid2 size={3}>
          <Typography
            fontSize={16}
            fontWeight={500}
            color={theme.palette.secondaryText.main}
          >
            Default Portfolio code
          </Typography>
        </Grid2>

        <Grid2
          container
          size={9}
          spacing={2}
          display={"flex"}
          alignItems={"center"}
        >
          <Grid2 size={4}>
            <Controller
              name="codePrefix"
              control={form.control}
              rules={{
                required: {
                  value: true,
                  message: "Please enter prefix",
                },
                validate: validateNotWhitespaceOnly,
              }}
              render={({ field, fieldState: { error } }) => (
                <GKTextField
                  {...field}
                  requiredField
                  inputLabel="Prefix"
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
          </Grid2>
          <Grid2
            size={8}
            display={"flex"}
            alignItems={"center"}
            gap={2}
            mt={"20px"}
          >
            <Typography>Code Length</Typography>
            <Controller
              name={"codeLength"}
              control={form.control}
              render={({ field }) => (
                <FormControl component="fieldset">
                  <RadioGroup
                    {...field}
                    onChange={(e) => field.onChange(+e.target.value)}
                    value={+field.value}
                  >
                    <Grid2
                      container
                      size={12}
                      display={"flex"}
                      alignItems={"center"}
                    >
                      <Grid2>
                        <FormControlLabel
                          value={3}
                          control={<Radio />}
                          label={<Typography>3-digits</Typography>}
                          sx={{ mr: "8px" }}
                        />
                      </Grid2>
                      <Grid2>
                        <FormControlLabel
                          value={4}
                          control={<Radio />}
                          label={<Typography>4-digits</Typography>}
                          sx={{ mr: "8px" }}
                        />
                      </Grid2>
                      <Grid2>
                        <FormControlLabel
                          value={5}
                          control={<Radio />}
                          label={<Typography>5-digits</Typography>}
                        />
                      </Grid2>
                    </Grid2>
                  </RadioGroup>
                </FormControl>
              )}
            />
          </Grid2>

          {prefixWatch && (
            <Grid2
              size={12}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"flex-start"}
              gap={1}
            >
              <Typography
                fontSize={12}
                fontWeight={400}
                color={theme.palette.secondaryText.main}
              >
                Portfolio code will be
              </Typography>
              <Typography fontWeight={500}>{`${form.getValues(
                "codePrefix",
              )}${"0".repeat(form.getValues("codeLength"))}`}</Typography>
            </Grid2>
          )}
          <Grid2 size={4}>
            <Controller
              name="invoiceSeries"
              control={form.control}
              render={({ field }) => {
                return <GKTextField {...field} inputLabel={"Invoice Series"} />;
              }}
            />{" "}
          </Grid2>
          <Grid2 size={8}>
            <Controller
              name="revenueWithGst"
              control={form.control}
              render={({ field }) => {
                return (
                  <FormControl>
                    <FormLabel>Revenue with GST</FormLabel>
                    <RadioGroup
                      {...field}
                      value={String(field.value)}
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <FormControlLabel
                        value="true"
                        control={<GKRadio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="false"
                        control={<GKRadio />}
                        label="No"
                      />
                    </RadioGroup>
                  </FormControl>
                );
              }}
            />{" "}
          </Grid2>
        </Grid2>
        <Grid2 size={12} my={1}>
          <Divider />
        </Grid2>
        <Grid2 size={12} display={"flex"} justifyContent={"flex-end"}>
          <LoadingButton
            type="submit"
            variant="contained"
            loading={updateInvestorLoading}
          >
            Save
          </LoadingButton>
        </Grid2>
      </Grid2>
      {investorSettingsConfirmation && (
        <InvestorSettingsConfirmation
          open={investorSettingsConfirmation}
          setOpen={setInvestorSettingsConfirmation}
          investorData={data?.data}
        />
      )}
    </form>
  );
};

export default WebsiteConfigurations;
