import { LoadingButton } from "@mui/lab";
import { ClearIcon } from "@mui/x-date-pickers-pro";
import {
  CgMathPlus,
  FiTrash2,
  GKButton,
  GKModal,
  GKTextField,
  Grid2,
  IconButton,
  LuSearch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "components";
import { useState } from "react";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { GKModalProps } from "utils/commonInterface";
import { ThemeContainer } from "utils/Theme";

const UserSpecificCouponModal = (props: GKModalProps) => {
  const { open, setOpen } = props;

  const form = useFormContext();

  const { theme } = ThemeContainer();

  const [searchQuery, setSearchQuery] = useState("");

  const { fields, append, remove } = useFieldArray({
    name: "couponUsage",
    control: form.control,
  });

  const filteredFields = fields.filter((field: any) =>
    field.phone.includes(searchQuery),
  );

  return (
    <GKModal
      open={open}
      setOpen={setOpen}
      modalTitle="Add Phone Number"
      footer={
        <LoadingButton variant="contained" onClick={() => setOpen(false)}>
          Save
        </LoadingButton>
      }
    >
      <Grid2 container spacing={1}>
        <Grid2 size={12}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <GKTextField
                      placeholder="Search"
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                      slotProps={{
                        input: {
                          startAdornment: (
                            <LuSearch
                              size={22}
                              color={theme.palette.grey[500]}
                            />
                          ),
                          endAdornment: searchQuery && (
                            <IconButton onClick={() => setSearchQuery("")}>
                              <ClearIcon />
                            </IconButton>
                          ),
                        },
                      }}
                    />
                  </TableCell>
                  <TableCell align="right">
                    <GKButton
                      variant="outlined"
                      color="dark"
                      onClick={() => append({ phone: "" })}
                      startIcon={<CgMathPlus size={14} />}
                    >
                      Add Phone
                    </GKButton>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableHead>
                <TableRow>
                  <TableCell style={{ padding: 16 }}>Phone</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredFields.map((row) => {
                  const originalIndex = fields.findIndex(
                    (field) => field.id === row.id,
                  );

                  return (
                    <TableRow
                      key={row.id}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell component="th" scope="row">
                        <Controller
                          name={`couponUsage.${originalIndex}.phone`}
                          control={form.control}
                          render={({ field }) => (
                            <GKTextField
                              {...field}
                              type="number"
                              placeholder="Phone Number"
                            />
                          )}
                        />
                      </TableCell>

                      <TableCell align="right">
                        <IconButton
                          color="error"
                          onClick={() => remove(originalIndex)}
                        >
                          <FiTrash2 size={18} />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid2>
      </Grid2>
    </GKModal>
  );
};

export default UserSpecificCouponModal;
