import { GKButton, GKModal, GKTextField, Grid2 } from "components";
import { Controller, useForm } from "react-hook-form";
import axiosInstance from "utils/axiosInstance";
import { GKModalProps } from "utils/commonInterface";

interface Props {
  clientId?: any;
  refetch?: any;
  updateData?: any;
  allAccounts?: AccountsInterface;
}

interface AccountsInterface {
  dematAccounts: {
    id?: number;
    dematId?: string;
    brokerName?: string;
    depositary?: string;
    uniqClientCode?: null;
    client?: number;
  }[];
}

const DematModal = ({
  open,
  setOpen,
  updateData,
  allAccounts,
  clientId,
  refetch,
}: Props & GKModalProps) => {
  const form = useForm({
    defaultValues: {
      dematId: updateData?.id ? updateData?.dematId : "",
      brokerName: updateData?.id ? updateData?.brokerName : "",
      depositary: updateData?.id ? updateData?.depositary : "",
      uniqClientCode: updateData?.id ? updateData?.uniqClientCode : "",
    },
  });

  const onSubmit = async (values: any) => {
    const newAcc = updateData?.id
      ? allAccounts.dematAccounts?.map((data) => {
          if (data?.id === updateData?.id) {
            return {
              dematId: values?.dematId,
              brokerName: values?.brokerName,
              depositary: values?.depositary,
              uniqClientCode: values?.uniqClientCode,
              client: updateData?.client,
              id: updateData?.id,
            };
          }
          return data;
        })
      : [...allAccounts.dematAccounts, values];

    await axiosInstance
      .patch(`/client/${clientId}/demats/`, { dematAccounts: newAcc })
      .then(() => {
        setOpen(false);
        refetch();
      });
  };

  return (
    <GKModal
      open={open}
      setOpen={setOpen}
      modalTitle="Add Demat Account"
      footer={
        <Grid2 container justifyContent={"flex-end"} gap={1}>
          <GKButton
            type="submit"
            variant="contained"
            size="medium"
            form="bank-account-form"
          >
            Submit
          </GKButton>
        </Grid2>
      }
    >
      <form id="bank-account-form" onSubmit={form.handleSubmit(onSubmit)}>
        <Grid2 container spacing={1}>
          <Grid2 size={6}>
            <Controller
              name="brokerName"
              control={form.control}
              render={({ field }) => (
                <GKTextField {...field} inputLabel="Broker Name" />
              )}
            />
          </Grid2>
          <Grid2 size={6}>
            <Controller
              name="dematId"
              control={form.control}
              render={({ field }) => (
                <GKTextField {...field} inputLabel="Demat ID" />
              )}
            />
          </Grid2>
          <Grid2 size={6}>
            <Controller
              name="depositary"
              control={form.control}
              render={({ field }) => (
                <GKTextField
                  {...field}
                  inputLabel="Depository"
                  placeholder="Like NSDL, CDSL"
                />
              )}
            />
          </Grid2>
          <Grid2 size={6}>
            <Controller
              name="uniqClientCode"
              control={form.control}
              render={({ field }) => (
                <GKTextField
                  {...field}
                  inputLabel="UCC"
                  placeholder="Like UCC of Demat A/c"
                />
              )}
            />
          </Grid2>
        </Grid2>
      </form>
    </GKModal>
  );
};

export default DematModal;
