import { useAppContext } from "AppContext";
import queryClient from "client";
import { Drawer, GKButton, GKTextField, Grid2, Typography } from "components";
import { useMutation } from "hooks/useQueries";
import { Controller, useForm } from "react-hook-form";
import axiosInstance from "utils/axiosInstance";
import { paletteLightTheme } from "utils/ColorTheme";
import { refetchQuery } from "utils/helpers";
import { QueryKeys } from "utils/queryKey";
import useDocumentTitle from "utils/useDocumentTitle";
import { emailRegex, validateNotWhitespaceOnly } from "utils/validationSchema";

interface Props {
  open: boolean;
  setOpen: any;
}

const CompanyNameModal = ({ open, setOpen }: Props) => {
  const {
    state: { userData: userInfo },
  } = useAppContext();

  const companyID = userInfo?.companyId;
  useDocumentTitle(`Welcome ${userInfo?.name}`);

  const { mutate } = useMutation({
    mutationFn: (data: any) =>
      axiosInstance.patch(`/company/${companyID}/`, data),
    onSuccess: () => {
      setOpen(false);
      refetchQuery(QueryKeys.COMPANY_INFO);
      window.location.reload();
    },
  });

  const form = useForm({
    defaultValues: {
      name: "",
      phoneNumber: "",
      primaryContactEmail: "",
    },
    values: {
      name: "",
      phoneNumber: userInfo?.phone || "",
      primaryContactEmail: userInfo?.email || "",
    },
  });

  const handleFormSubmit = (values: any) => {
    mutate(values);
  };

  return (
    <form onSubmit={form?.handleSubmit(handleFormSubmit)} id="company-form">
      <Drawer
        anchor="right"
        open={open}
        PaperProps={{
          sx: {
            width: "100%",
            background: paletteLightTheme.palette.bgColor.main,
          },
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "flex-start",
          }}
        >
          <Grid2
            container
            size={6}
            py={4}
            px={8}
            spacing={2}
            width={"50%"}
            justifyContent={"center"}
          >
            <Grid2
              size={12}
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
              mb={5}
            >
              <img
                alt="asd"
                src="/Logos/iconFullLogo.png"
                width={150}
                height={"auto"}
              />
              <GKButton
                variant="link"
                color="dark"
                onClick={() => {
                  window.location.reload();
                  localStorage.removeItem(`role`);
                  localStorage.removeItem(`token`);
                  localStorage.removeItem(`isInvestor`);
                  localStorage.removeItem(`isAdvisor`);
                  localStorage.removeItem(`userInfo`);
                  localStorage.removeItem("columns");
                  localStorage.removeItem(`version`);
                  localStorage.removeItem("breadcumbs");
                  queryClient.clear();
                }}
              >
                Logout
              </GKButton>
            </Grid2>
            <Grid2 size={12} mb={2}>
              <Typography fontWeight={600} fontSize={26}>
                Get Started: Set up your Company Profile
              </Typography>
            </Grid2>
            <Grid2 size={12}>
              <Controller
                name="name"
                control={form.control}
                rules={{
                  required: {
                    value: true,
                    message: "Please enter company name",
                  },
                  validate: validateNotWhitespaceOnly,
                }}
                render={({ field, fieldState: { error } }) => (
                  <GKTextField
                    {...field}
                    requiredField
                    inputLabel="Company Name"
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              />
            </Grid2>
            <Grid2 size={12}>
              <Controller
                name="phoneNumber"
                control={form.control}
                rules={{
                  required: {
                    value: true,
                    message: "Please enter mobile number",
                  },
                }}
                render={({ field, fieldState: { error } }) => (
                  <GKTextField
                    {...field}
                    inputLabel="Mobile Number"
                    requiredField
                    error={!!error}
                    helperText={error?.message}
                    slotProps={{ htmlInput: { readOnly: true } }}
                  />
                )}
              />
            </Grid2>
            <Grid2 size={12}>
              <Controller
                name="primaryContactEmail"
                control={form.control}
                rules={{
                  required: {
                    value: true,
                    message: "Please enter email",
                  },
                  pattern: {
                    value: emailRegex,
                    message: "Please enter valid email",
                  },
                }}
                render={({ field, fieldState: { error } }) => (
                  <GKTextField
                    {...field}
                    inputLabel="Email"
                    requiredField
                    error={!!error}
                    helperText={error?.message}
                    slotProps={{ htmlInput: { readOnly: true } }}
                  />
                )}
              />
            </Grid2>
            <Grid2 size={12} mt={4}>
              <GKButton
                fullWidth
                variant="contained"
                type="submit"
                form="company-form"
              >
                Submit
              </GKButton>
            </Grid2>
          </Grid2>
          <img
            alt=""
            src="/Images/getStartedImg.svg"
            style={{
              maxHeight: "100vh",
              height: "100vh",
              background: "#006580",
              width: "50%",
            }}
          />
        </div>
      </Drawer>
    </form>
  );
};

export default CompanyNameModal;
