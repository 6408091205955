import {
  CircularProgress,
  ConfirmDeleteModal,
  DeleteIcon,
  EditIcon,
  FiCheck,
  FiDownloadCloud,
  GKButton,
  GKClientDataGrid,
  GKTextField,
  Grid2,
  IconButton,
  IoClose,
  Show,
  Switch,
} from "components";
import { useFetchQuery, useMutation } from "hooks/useQueries";
import AutoImportModal from "Pages/AutoImport/AutoImportModal";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { AUTO_FORWARD_DATA } from "utils/apiRoutes";
import axiosInstance from "utils/axiosInstance";
import { extraColumnsField, refetchQuery } from "utils/helpers";

const AutoImportTab = () => {
  const { id } = useParams();

  const [isAutoImportOpen, setIsAutoImportOpen] = useState(false);

  const [selectedId, setSelectedId] = useState(null);

  const [editPassword, setEditPassword] = useState(false);

  const [allAutoData, setAllAutoData] = useState([]);

  const [confirmationModal, setConfirmationModal] = useState(false);

  // ===###===###===###===###===###===###===###===###===###===###===###===###===###===###
  const { mutate: handleDeleteNote } = useMutation({
    mutationKey: ["DELETE_NOTES"],
    mutationFn: (notedId: any) =>
      axiosInstance.delete(`/auto_forward/${notedId}/`),
    onSuccess: () => {
      refetchQuery("AUTO_FORWARD_DATA");
    },
  });

  // ================================================================================================================================
  const { refetch, isFetching: dataLoading } = useFetchQuery({
    key: ["AUTO_FORWARD_DATA"],
    route: AUTO_FORWARD_DATA,
    requestBody: {
      portfolio_id: id,
    },
    success: (data: any) => {
      setAllAutoData(data?.data?.data);
    },
  });

  // ================================================================================================================================
  const { mutate: updateMutation, isLoading: updateLoading } = useMutation({
    mutationFn: (data: any) =>
      axiosInstance.patch(`/auto_forward/${selectedId}/`, data),
    onSuccess: () => {
      refetch();
      setSelectedId(null);
      setEditPassword(false);
    },
  });

  return (
    <Grid2 container spacing={2} alignItems={"center"}>
      <Grid2 size={12}>
        <GKClientDataGrid
          name="autoImport"
          loading={dataLoading}
          rows={allAutoData || []}
          columns={[
            {
              ...extraColumnsField("email", "email", 0, 1),
            },
            {
              ...extraColumnsField("Sender's Email", "setUpEmail", 0, 1),
            },
            {
              ...extraColumnsField("Broker", "broker", 0, 1),
            },
            {
              ...extraColumnsField("Auto Approve", "autoApprove", 0, 1),
              renderCell: (params) => {
                return updateLoading && params?.row?.id === selectedId ? (
                  <CircularProgress size={20} />
                ) : (
                  <Switch
                    checked={params?.row?.autoApproved}
                    onChange={() => {
                      setSelectedId(params?.row?.id);
                      setTimeout(() => {
                        updateMutation({
                          autoApproved: !params?.row?.autoApproved,
                        });
                      }, 300);
                    }}
                  />
                );
              },
            },
            {
              ...extraColumnsField("Contract Notes Password", "password", 0, 1),
              renderCell: (params) => {
                return (
                  <GKTextField
                    placeholder="Password"
                    disabled={!editPassword}
                    value={params?.row?.password}
                    onChange={({ target }) => {
                      setAllAutoData((prev) =>
                        prev?.map((item) => {
                          return {
                            ...item,
                            password:
                              params?.row?.id === item?.id
                                ? target?.value
                                : item?.password,
                          };
                        }),
                      );
                    }}
                    slotProps={{
                      input: {
                        endAdornment: (
                          <Grid2 container justifyContent={"flex-end"} gap={1}>
                            {!editPassword && (
                              <IconButton
                                onClick={() => {
                                  setSelectedId(params?.row?.id);
                                  setEditPassword(true);
                                }}
                              >
                                <EditIcon size={16} />
                              </IconButton>
                            )}
                            {editPassword && selectedId === params?.row?.id && (
                              <IconButton
                                color="success"
                                onClick={() => {
                                  updateMutation({
                                    password: params?.row?.password,
                                  });
                                }}
                              >
                                <FiCheck />
                              </IconButton>
                            )}
                            {editPassword && selectedId === params?.row?.id && (
                              <IconButton
                                color="error"
                                onClick={() => {
                                  setEditPassword(false);
                                  setSelectedId(null);
                                }}
                              >
                                <IoClose />
                              </IconButton>
                            )}
                          </Grid2>
                        ),
                      },
                    }}
                  />
                );
              },
            },
            {
              ...extraColumnsField("Actions", "action", 0, 1),
              align: "right",
              headerAlign: "right",
              renderCell: (params) => {
                return (
                  <Grid2 container justifyContent={"flex-end"} gap={1}>
                    <IconButton
                      onClick={() => {
                        setSelectedId(params?.row?.id);
                        setConfirmationModal(true);
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Grid2>
                );
              },
            },
          ]}
          headerComponent={
            <Grid2 container justifyContent={"flex-end"}>
              <GKButton
                onClick={() => {
                  setIsAutoImportOpen(true);
                }}
                variant="contained"
                startIcon={<FiDownloadCloud size={16} />}
              >
                Set Auto Import
              </GKButton>
            </Grid2>
          }
        />
      </Grid2>

      <Show.When isTrue={isAutoImportOpen}>
        <AutoImportModal
          open={isAutoImportOpen}
          setOpen={setIsAutoImportOpen}
        />
      </Show.When>
      <Show.When isTrue={confirmationModal}>
        <ConfirmDeleteModal
          open={confirmationModal}
          setOpen={setConfirmationModal}
          onDelete={() => handleDeleteNote(selectedId)}
          title={"Auto Forward"}
          isDelete
        />
      </Show.When>
    </Grid2>
  );
};

export default AutoImportTab;
