import { grey } from "@mui/material/colors";
import { useAppContext } from "AppContext";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  GKTypography,
  Grid2,
  Show,
  Typography,
} from "components";
import { useMutation } from "hooks/useQueries";
import {
  AdvisorOnboardFormInterface,
  SelectedaBasketInterface,
} from "interfaces/OnboardingInterface";
import { Controller, useFormContext } from "react-hook-form";
import toast from "react-hot-toast";
import axiosInstance from "utils/axiosInstance";
import { refetchQuery } from "utils/helpers";
import { QueryKeys } from "utils/queryKey";
import { ThemeContainer } from "utils/Theme";

const AdvisorOnboardingEquityBasket = ({
  basketList: { data: basketList },
  setOpen,
}: any) => {
  const form = useFormContext<AdvisorOnboardFormInterface>();

  const {
    state: { userData },
  } = useAppContext();

  const { theme } = ThemeContainer();

  // ================================================================================================================= clientData={addedClientData}====
  const { mutate: handleBasketFeePlan } = useMutation({
    mutationKey: ["HANDLE_CLIENT_EB_MUTATUION"],
    mutationFn: (values: AdvisorOnboardFormInterface) =>
      axiosInstance.post(`/client-basket/`, {
        client: values.id,
        basketId: values.basketObject.id,
        feePlan: values.selectedFeePlan.id,
      }),
    onSuccess: () => {
      refetchQuery(QueryKeys.GET_COMPLIANCE_BY_CLIENT_ID);
      refetchQuery(QueryKeys.GET_CLIENT_BY_ID);
      form.setValue("activeStep", form.getValues("activeStep") + 1);
    },
  });
  // ================================================================================================================= clientData={addedClientData}====
  const { mutate: handleClientSelfOnboarding } = useMutation({
    mutationKey: ["HANDLE_CLIENT_SEND_LINK_MUTATUION"],
    mutationFn: (values: AdvisorOnboardFormInterface) =>
      axiosInstance.post(`/client/self-onboarding/`, {
        basketId: values.basketObject.id,
        feePlanId: values.selectedFeePlan.id,
        partnerId: values.partner || userData?.partnerId,
        clientName: values.personalDetails.name,
        phone: values.phone,
        clientEmail: values.personalDetails.email,
        companyId: userData.companyId,
        skipAdvanceFee: values.skipAdvanceFee,
      }),
    onSuccess: () => {
      setOpen(false);
    },
  });

  // ================================================================================================================= clientData={addedClientData}====

  const handleSubmit = (value: any) => {
    if (value === "manual") {
      handleClientSelfOnboarding(form.getValues());
    } else {
      handleBasketFeePlan(form.getValues());
    }
  };
  // ================================================================================================================= clientData={addedClientData}====

  return (
    <form
      id="advisor-onboarding-equity-basket-form"
      onSubmit={form.handleSubmit(() => {
        if (!form.getValues("selectedFeePlan.id")) {
          toast.error("Please select a fee plan");
        } else {
          handleSubmit(form.getValues("type"));
        }
      })}
    >
      <Grid2 container spacing={2}>
        <Grid2
          size={5.8}
          textAlign={"center"}
          display={"flex"}
          flexDirection={"column"}
          gap={1}
          sx={{
            overflow: "auto",
            maxHeight: "calc(100vh - 446px)",
          }}
        >
          <Typography
            fontSize={18}
            fontWeight={600}
            width={"100%"}
            textAlign={"left"}
          >
            Select Basket
          </Typography>
          {basketList?.map((dataItem: SelectedaBasketInterface) => {
            return (
              <Button
                key={dataItem.id}
                fullWidth
                size="large"
                variant="outlined"
                color={
                  dataItem?.id === form.getValues("basketObject.id")
                    ? "primary"
                    : "dark"
                }
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
                onClick={() => {
                  form.setValue("basketObject", dataItem);
                  form.setValue("selectedFeePlan", null);
                }}
              >
                {/* <img
                  src={dataItem?.basketLogo || ""}
                  alt=""
                  width={"10%"}
                  height={"auto"}
                /> */}
                <GKTypography
                  fontSize={18}
                  fontWeight={600}
                  color={
                    dataItem?.id === form.getValues("basketObject.id")
                      ? theme.palette.primary.main
                      : theme.palette.dark.main
                  }
                  textAlign={"left"}
                  width={"100%"}
                >
                  {dataItem.name}
                </GKTypography>
                <GKTypography fontSize={12} textAlign={"left"} width={"100%"}>
                  {dataItem.overview}
                </GKTypography>
              </Button>
            );
          })}
        </Grid2>
        <Grid2 size={0.2} textAlign={"center"}>
          <Divider orientation="vertical" />
        </Grid2>
        <Show.When
          isTrue={form.getValues("basketObject.feePlansList")?.length !== 0}
        >
          <Grid2
            size={5.8}
            textAlign={"center"}
            display={"flex"}
            gap={1}
            flexDirection={"column"}
            alignItems={"center"}
            sx={{
              overflow: "auto",
              maxHeight: "calc(100vh - 446px)",
            }}
          >
            <div
              style={{
                width: "100%",
              }}
            >
              <Typography
                fontSize={18}
                fontWeight={600}
                textAlign={"left"}
                width={"100%"}
              >
                Select Fee Plan
              </Typography>
              <Typography
                fontWeight={400}
                fontSize={12}
                textAlign={"left"}
                width={"100%"}
                color={grey[500]}
              >
                This fee is applicable for subscribing to the selected basket.
              </Typography>
            </div>
            {form
              .getValues("basketObject.feePlansList")
              ?.filter((d) => d?.managementFee?.fee)
              ?.map((dataItem, index) => {
                return (
                  <Button
                    key={index}
                    variant={"outlined"}
                    color={
                      form.getValues("selectedFeePlan.id") === dataItem?.id
                        ? "primary"
                        : "dark"
                    }
                    fullWidth
                    onClick={() => {
                      form.setValue("selectedFeePlan", dataItem);
                    }}
                  >
                    <Box display={"flex"} width={"100%"} gap={1}>
                      <div
                        style={{
                          height: "20%",
                          fontWeight: 600,
                          width: "10%",
                          background:
                            form.getValues("selectedFeePlan.id") ===
                            dataItem?.id
                              ? theme.palette.primary.main
                              : grey[100],
                          color:
                            form.getValues("selectedFeePlan.id") !==
                            dataItem?.id
                              ? theme.palette.primary.main
                              : "white",
                          borderRadius: 2,
                          textTransform: "uppercase",
                        }}
                      >
                        {dataItem?.name?.charAt(0)}
                        {dataItem?.name?.charAt(1)}
                      </div>
                      <Typography fontSize={16} fontWeight={600}>
                        {dataItem?.name}
                      </Typography>
                    </Box>
                  </Button>
                );
              })}
          </Grid2>
        </Show.When>
        <Grid2 size={12}>
          <Divider />
        </Grid2>
        <Grid2 size={12}>
          <Controller
            name="skipAdvanceFee"
            control={form.control}
            render={({ field }) => {
              return (
                <FormControlLabel
                  control={
                    <Checkbox
                      {...field}
                      checked={field.value}
                      onChange={(e) => {
                        field.onChange(e.target.checked);
                      }}
                    />
                  }
                  label="Skip Advance Fee"
                />
              );
            }}
          />
        </Grid2>
      </Grid2>
    </form>
  );
};

export default AdvisorOnboardingEquityBasket;
