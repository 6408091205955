import { useQueryClient } from "@tanstack/react-query";
import { useAppContext } from "AppContext";
import { useFetchQuery, useMutation } from "hooks/useQueries";
import { useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import axiosInstance from "utils/axiosInstance";
import { FetchDataURL } from "utils/common";
import { QueryKeys } from "utils/queryKey";
import useDocumentTitle from "utils/useDocumentTitle";
import useGenerateQueryParams from "./useGenerateQueryParams";

export const useLead = () => {
  const {
    state: { commonFilter },
  } = useAppContext();

  useDocumentTitle("Leads");

  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const [addStatusModal, setAddStuatusModal] = useState(false);

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const [isLeadStatus, setIsLeadStatus] = useState(true);

  const [itemId, setItemId] = useState(0);

  const [updateModal, setUpdateModal] = useState(false);

  const [leadModalOpen, setLeadModalOpen] = useState(false);

  const requestBody = useGenerateQueryParams("lead");

  const {
    data: getAllLead,
    isFetching: isLeadLoading,
    refetch,
  } = useFetchQuery({
    key: ["GET_ALL_LEADS", requestBody],
    route: FetchDataURL.LEAD_LIST,
    requestBody,
  });

  const { mutate: deleteLead } = useMutation({
    mutationKey: ["DELETE_LEAD"],
    mutationFn: (id: number) => axiosInstance.delete(`/lead/${id}/`),
    onSuccess: () => {
      refetch();
      queryClient.invalidateQueries([QueryKeys.PAGINATED_TABLE]);
    },
    onError: (err: any) => {
      toast.error(err?.response?.data?.message || "Something went wrong");
    },
  });

  return {
    getAllLead: getAllLead?.data,
    isLeadLoading,
    commonFilter,
    refetch,
    deleteLead,
    navigate,
    isDeleteModalOpen,
    setIsDeleteModalOpen,
    leadModalOpen,
    setLeadModalOpen,
    itemId,
    setItemId,
    updateModal,
    setUpdateModal,
    isLeadStatus,
    setIsLeadStatus,
    addStatusModal,
    setAddStuatusModal,
  };
};
