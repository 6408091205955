import { LoadingButton } from "@mui/lab";
import { useAppContext } from "AppContext";
import {
  Box,
  Card,
  Divider,
  GKDatePicker,
  GKSearchSelect,
  GKTextField,
  Grid2,
  Show,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "components";
import dayjs from "dayjs";
import { useFetchQuery, useMutation } from "hooks/useQueries";
import DashboardLayout from "layouts/DashboardLayout";
import { sum } from "lodash";
import { useEffect } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { CLIENT_FEE_COLLECTION_BY_ID } from "utils/apiRoutes";
import axiosInstance from "utils/axiosInstance";
import { UserRoleTypes } from "utils/common";
import { moneyCommaSeparator } from "utils/MathFunction";
import { handleReturnAutocompletePlaceholder } from "utils/StringFunctions";
import useDocumentTitle from "utils/useDocumentTitle";

type FormValues = {
  client?: string;
  paymentType?: string;
  paymentDate?: any;
  amount?: string;
  refNumber?: string;
  outStandingAmount?: string;
  adjustedAmount?: string;
  invoices?: {
    invoiceFeeCollection?: any[];
    totalAmount?: string;
  };
};

const paymentType = [
  { name: "Cash", value: "cash" },
  { name: "E-mandate ", value: "emandates" },
  { name: "Bank", value: "bank" },
];

// const paymentStatus = [
//   { name: "Partially Paid", value: "partially_paid" },
//   { name: "Completed ", value: "completed" },
//   { name: "Pending", value: "pending" },
// ];

const CreateCollection = () => {
  useDocumentTitle("Collection");

  const { id } = useParams();

  const navigate = useNavigate();

  const {
    state: { clientList, userData },
  } = useAppContext();

  const { data, isFetching } = useFetchQuery({
    key: ["EDIT_FEE_COLLECTION"],
    route: CLIENT_FEE_COLLECTION_BY_ID(id),
    enabled: Boolean(id),
  });

  const form = useForm<FormValues>({
    defaultValues: {
      client: "",
      paymentType: "",
      paymentDate: dayjs().format("YYYY-MM-DD"),
      amount: "",
      refNumber: "",
      outStandingAmount: "",
      adjustedAmount: "",
      invoices: {
        invoiceFeeCollection: [],
        totalAmount: "",
      },
    },
    values: Boolean(id) && {
      ...data?.data?.data,
      client: {
        ...clientList.find((dataItem: any) => {
          return dataItem.id === data?.data?.data?.client;
        }),
        name:
          clientList.find((dataItem: any) => {
            return dataItem.id === data?.data?.data?.client;
          })?.name || " ",
        value: clientList.find((dataItem: any) => {
          return dataItem.id === data?.data?.data?.client;
        })?.id,
      },
    },
  });

  const { fields } = useFieldArray({
    control: form.control,
    name: "invoices.invoiceFeeCollection",
  });

  form.watch();

  const { mutate: fetchClientInvoices, isLoading } = useMutation({
    mutationKey: ["FETCH_CLIENT_INVOICES"],
    mutationFn: (id) =>
      axiosInstance.post("/invoice-history/unpaid-invoice/", { client_id: id }),
    onSuccess: (response) => {
      form.setValue("invoices.invoiceFeeCollection", response?.data?.data);
    },
  });

  const calculateTotals = () => {
    const adjustedAmountTotal: any = sum(
      form
        ?.getValues("invoices.invoiceFeeCollection")
        ?.map((item) => +item?.paidAmount),
    );

    const totalAmount = parseFloat(form?.getValues("amount") || "0");
    const totalAdjustedAmount = parseFloat(adjustedAmountTotal || "0");
    const totalUnadjustedAmount = totalAmount - totalAdjustedAmount;

    return { totalAdjustedAmount, totalUnadjustedAmount };
  };

  const { totalAdjustedAmount, totalUnadjustedAmount } = calculateTotals();

  useEffect(() => {
    form.setValue("adjustedAmount", totalAdjustedAmount.toString());
    form.setValue("outStandingAmount", totalUnadjustedAmount.toString());
    form.setValue("invoices.totalAmount", totalAdjustedAmount.toString());
  }, [totalAdjustedAmount, totalUnadjustedAmount, form.setValue]);

  const { mutate: handleFeeCollection, isLoading: fetchClientInvoiceLoading } =
    useMutation({
      mutationKey: ["FETCH_CLIENT_INVOICES"],
      mutationFn: (data) => axiosInstance.post("/client-fee-collection/", data),
      onSuccess: () => {
        navigate(-1);
      },
    });

  const {
    mutate: handleUpdateFeeCollection,
    isLoading: updateClientInvoiceLoading,
  } = useMutation({
    mutationKey: ["UPDATE_FEE_COLLECTION"],
    mutationFn: (data) =>
      axiosInstance.patch(`/client-fee-collection/${id}/`, data),
    onSuccess: () => {
      navigate(-1);
    },
  });

  const onSubmit = (values: any) => {
    const data = {
      ...values,
      client: values?.client?.id,
      status: "partially_paid",
    };

    if (id) {
      handleUpdateFeeCollection(data);
    } else {
      handleFeeCollection(data);
    }
  };

  return (
    <DashboardLayout title={"Add Collection"} loading={isFetching}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <Grid2 container>
          <Grid2 size={5}>
            <Grid2 container spacing={1}>
              <Grid2 size={12}>
                <Typography fontSize={20} fontWeight={600}>
                  Fee Collection
                </Typography>
              </Grid2>
              <Grid2 size={12}>
                <Controller
                  name="client"
                  control={form.control}
                  rules={{
                    required: {
                      value: true,
                      message: "Please select client",
                    },
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <GKSearchSelect
                      {...field}
                      requiredField
                      readOnly={Boolean(id)}
                      disableClearable={false}
                      inputLabel="Client"
                      options={clientList || []}
                      onChange={(e, val) => field.onChange(val)}
                      getOptionLabel={(option) => option.name || option}
                      renderInput={(params) => (
                        <TextField
                          placeholder={handleReturnAutocompletePlaceholder({
                            placeholder: "Select Client",
                            value: field.value,
                          })}
                          {...params}
                          helperText={error?.message}
                        />
                      )}
                    />
                  )}
                />
              </Grid2>
              <Grid2 size={12}>
                <Controller
                  name="paymentType"
                  control={form.control}
                  rules={{
                    required: {
                      value: true,
                      message: "Please select payment type",
                    },
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <GKSearchSelect
                      {...field}
                      requiredField
                      readOnly={Boolean(id)}
                      disableClearable={false}
                      inputLabel="Payment Type"
                      options={paymentType || []}
                      onChange={(e, val) => field.onChange(val?.value)}
                      value={
                        paymentType.find(
                          (dataItem) => dataItem.value === field.value,
                        ) || ""
                      }
                      getOptionLabel={(option) => option.name || option}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder={handleReturnAutocompletePlaceholder({
                            placeholder: "Select  Payment Status",
                            value: field.value,
                          })}
                          name="Client"
                          error={!!error}
                          helperText={error?.message}
                        />
                      )}
                    />
                  )}
                />
              </Grid2>
              <Grid2 size={12}>
                <Controller
                  name={"paymentDate"}
                  control={form.control}
                  rules={{
                    required: {
                      value: true,
                      message: "Please select payment date",
                    },
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <GKDatePicker
                      {...field}
                      inputLabel="Date"
                      readOnly={Boolean(id)}
                      value={dayjs(field.value)}
                      onChange={(value) => {
                        field.onChange(dayjs(value).format("YYYY-MM-DD"));
                      }}
                      disableFuture
                      maxDate={dayjs()}
                      requiredField
                      slotProps={{
                        textField: {
                          error: !!error,
                          helperText: error?.message,
                        },
                      }}
                    />
                  )}
                />
              </Grid2>
              <Grid2 size={12}>
                <Controller
                  name={`amount`}
                  control={form.control}
                  rules={{
                    required: {
                      value: true,
                      message: "Please enter amount",
                    },
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <GKTextField
                      {...field}
                      requiredField
                      inputLabel={"Amount"}
                      type="number"
                      slotProps={{ htmlInput: { readOnly: Boolean(id) } }}
                      error={!!error}
                      helperText={error?.message}
                    />
                  )}
                />
              </Grid2>
              <Grid2 size={12}>
                <Controller
                  name={"refNumber"}
                  control={form.control}
                  render={({ field }) => (
                    <GKTextField
                      {...field}
                      inputLabel={"Ref Number"}
                      slotProps={{ htmlInput: { readOnly: Boolean(id) } }}
                    />
                  )}
                />
              </Grid2>
              <Show.When isTrue={!id}>
                <Grid2 size={12} display={"flex"} gap={1} mt={1}>
                  <LoadingButton
                    variant="contained"
                    size="medium"
                    loading={isLoading}
                    onClick={form.handleSubmit((values: any) =>
                      fetchClientInvoices(values?.client?.id),
                    )}
                  >
                    Fetch Unpaid Invoices
                  </LoadingButton>
                  <LoadingButton
                    variant="outlined"
                    size="medium"
                    color="dark"
                    onClick={() => form.reset()}
                  >
                    Reset
                  </LoadingButton>
                </Grid2>
              </Show.When>
            </Grid2>
          </Grid2>
          <Grid2 size={0.2} display={"flex"} justifyContent={"center"}>
            <Divider orientation="vertical" />
          </Grid2>
          <Grid2 size={6.8}>
            <Grid2 container size={12} spacing={1}>
              <Grid2 size={6}>
                <Card sx={{ p: 2 }}>
                  <Typography fontWeight={500}>
                    Total Unadjusted Amount
                  </Typography>
                  <Typography>{totalUnadjustedAmount.toFixed(2)}</Typography>
                </Card>
              </Grid2>
              <Grid2 size={6}>
                <Card sx={{ p: 2 }}>
                  <Typography fontWeight={500}>
                    Total Adjusted Amount
                  </Typography>
                  <Typography>{totalAdjustedAmount.toFixed(2)}</Typography>
                </Card>
              </Grid2>
              <Grid2 size={12}>
                {form.getValues("invoices.invoiceFeeCollection")?.length >
                  0 && (
                  <Box
                    sx={{
                      height: "calc(100vh - 219px)",
                      overflow: "auto",
                      overflowX: "hidden",
                    }}
                  >
                    <TableContainer>
                      <Table size="small" stickyHeader>
                        <TableHead>
                          <TableRow>
                            <TableCell>Sr.No</TableCell>
                            <TableCell>Date</TableCell>
                            <TableCell>Invoice Number</TableCell>
                            <TableCell>Amount</TableCell>
                            <TableCell>Adjusted Amount</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {fields.map((row: any, index: number) => {
                            return (
                              <TableRow
                                key={row.id}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell component="th" scope="row">
                                  {index + 1}
                                </TableCell>
                                <TableCell>
                                  {dayjs(row.createdAt).format("DD-MM-YYYY")}
                                </TableCell>
                                <TableCell>{row.invoiceNumber}</TableCell>
                                <TableCell>
                                  {moneyCommaSeparator(+row.remainingAmount)}
                                </TableCell>
                                <TableCell>
                                  <Controller
                                    name={`invoices.invoiceFeeCollection.${index}.paidAmount`}
                                    control={form.control}
                                    render={({ field }) => (
                                      <GKTextField
                                        {...field}
                                        onFocus={(e) => {
                                          if (!e.target.value) {
                                            form.setValue(
                                              `invoices.invoiceFeeCollection.${index}.paidAmount`,
                                              row.remainingAmount.toFixed(2),
                                            );
                                          }
                                        }}
                                        type="number"
                                        slotProps={{
                                          htmlInput: {
                                            readOnly:
                                              Boolean(id) &&
                                              row?.isPayment === "completed",
                                          },
                                        }}
                                        // onChange={(e) => {
                                        //   const newNumber = Number(
                                        //     e.target.value
                                        //   );

                                        //   if (
                                        //     newNumber >= 0 &&
                                        //     newNumber <=
                                        //       Number(form.getValues("amount"))
                                        //   ) {
                                        //     field.onChange(newNumber);
                                        //   } else {
                                        //     field.onChange(
                                        //       form.getValues("amount")
                                        //     );
                                        //   }
                                        // }}
                                      />
                                    )}
                                  />
                                </TableCell>
                              </TableRow>
                            );
                          })}
                          <TableRow>
                            <TableCell colSpan={3} />
                            <TableCell
                              sx={{ fontSize: "12px", fontWeight: 600 }}
                            >
                              Total Amount
                            </TableCell>
                            <TableCell sx={{ width: "40%" }}>
                              <Controller
                                name={`invoices.totalAmount`}
                                control={form.control}
                                render={({ field }) => (
                                  <GKTextField
                                    {...field}
                                    value={totalAdjustedAmount.toFixed(2)}
                                    type="number"
                                    slotProps={{
                                      htmlInput: { readOnly: true },
                                    }}
                                    error={
                                      +field.value > +form.getValues("amount")
                                    }
                                    helperText={
                                      +field.value >
                                        +form.getValues("amount") &&
                                      "Adjusted amount should be less than amount."
                                    }
                                  />
                                )}
                              />
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                )}
              </Grid2>
              <Show.When isTrue={userData?.userRole === UserRoleTypes.advisor}>
                {fields.length !== 0 && (
                  <Grid2
                    size={12}
                    mt={2}
                    display={"flex"}
                    justifyContent={"flex-end"}
                  >
                    <LoadingButton
                      variant="contained"
                      type="submit"
                      loading={
                        fetchClientInvoiceLoading || updateClientInvoiceLoading
                      }
                      disabled={
                        +form.getValues("invoices.totalAmount") >
                        +form.getValues("amount")
                      }
                    >
                      {id ? "Update" : "Save"}
                    </LoadingButton>
                  </Grid2>
                )}
              </Show.When>
            </Grid2>
          </Grid2>
        </Grid2>
      </form>
    </DashboardLayout>
  );
};

export default CreateCollection;
