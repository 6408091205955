import {
  DragDrop,
  FiTrash2,
  GKClientDataGrid,
  GKEditor,
  Grid2,
  IconButton,
  IoCloudDownloadOutline,
  Show,
  Switch,
  Typography,
} from "components";
import { EquitybasketFormInterface } from "interfaces/EquityBasket";
import { useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import toast from "react-hot-toast";
import { ThemeContainer } from "utils/Theme";

const RationaleTabs = ({
  publishFile,
  deleteFile,
  researchFiles,
}: {
  publishFile: any;
  deleteFile: any;
  researchFiles: any;
}) => {
  const { theme } = ThemeContainer();

  const form = useFormContext<EquitybasketFormInterface>();

  const [uploadError, setUploadError] = useState(null);

  return (
    <Grid2 container spacing={2}>
      <Grid2 size={12}>
        <Controller
          name="rationale"
          control={form.control}
          render={({ field }) => {
            return (
              <GKEditor
                {...field}
                placeholder="Mention the key factors used for screening, such as market cap, growth metrics, or sector focus. For example, the team conducts thorough research to define the screening criteria for selecting stocks."
                inputLabel="Rationale"
                toolbar={[
                  "undo",
                  "redo",
                  "|",
                  "heading",
                  "bold",
                  "italic",
                  "underline",
                  "strikethrough",
                  "|",
                  "fontColor",
                  "fontBackgroundColor",
                  "|",
                  "alignment",
                  "link",
                  "|",
                  "bulletedList",
                  "numberedList",
                ]}
              />
            );
          }}
        />
      </Grid2>
      <Grid2 size={12}>
        <Typography color={theme.palette.dark.main}>Factsheets</Typography>
        <Controller
          name="factSheet"
          control={form.control}
          render={({ field }) => {
            return (
              <DragDrop
                uploadError={uploadError}
                supportName={"CSV & PDF"}
                setUploadError={setUploadError}
                value={field.value}
                onChange={(e) => {
                  field.onChange(e);
                }}
                onDelete={() => {
                  field.onChange([]);
                }}
                multiple
              />
            );
          }}
        />
      </Grid2>
      <Grid2 size={12}>
        <Typography color={theme.palette.dark.main}>Research File</Typography>
        <Controller
          name="researchFiles"
          control={form.control}
          render={({ field }) => {
            return (
              <DragDrop
                uploadError={uploadError}
                supportName={"CSV & PDF"}
                setUploadError={setUploadError}
                value={field.value}
                onChange={(e) => {
                  field.onChange(e);
                }}
                multiple
              />
            );
          }}
        />
      </Grid2>
      <Show.When isTrue={researchFiles?.length !== 0}>
        <Grid2 size={12} textAlign={"end"}>
          <GKClientDataGrid
            name="researchFiles"
            density="compact"
            pagination={false}
            showHeaderGrid={false}
            rows={researchFiles || []}
            columns={[
              {
                field: "name",
                headerName: "File name",
                flex: 1,
                disableColumnMenu: true,
                disableExport: true,
              },
              {
                field: "uploadedBy",
                headerName: "Uploaded By",
                flex: 1,
                disableColumnMenu: true,
                disableExport: true,
              },
              {
                field: "visible",
                headerName: "Published",
                flex: 1,
                disableColumnMenu: true,
                disableExport: true,
                renderCell: (params) => {
                  return (
                    <Switch
                      onChange={() => {
                        publishFile(params?.row?.id);
                        toast.loading(
                          `Please wait we are publishing ${params?.row?.name} file`,
                        );
                      }}
                      checked={params?.row?.visible}
                    />
                  );
                },
              },
              {
                field: "actions",
                headerName: "Action",
                align: "right",
                headerAlign: "right",
                flex: 1,
                disableColumnMenu: true,
                disableExport: true,
                renderCell: (params) => {
                  return (
                    <Grid2
                      container
                      justifyContent={"flex-end"}
                      alignItems={"center"}
                      gap={1}
                    >
                      <IconButton
                        className="outline"
                        color="error"
                        onClick={() => {
                          deleteFile(params?.row?.id);
                          toast.loading(
                            `Please wait we are deleting ${params?.row?.name} file`,
                          );
                        }}
                      >
                        <FiTrash2 />
                      </IconButton>
                      <IconButton href={params.row?.file}>
                        <IoCloudDownloadOutline />
                      </IconButton>
                    </Grid2>
                  );
                },
              },
            ]}
          />
        </Grid2>
      </Show.When>
    </Grid2>
  );
};

export default RationaleTabs;
