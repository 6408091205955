import { GridColDef } from "@mui/x-data-grid-pro";
import { useAppContext } from "AppContext";
import {
  CgMathPlus,
  ConfirmDeleteModal,
  GKButton,
  GKDataGrid,
  Grid2,
  Typography,
} from "components";
import useGenerateQueryParams from "hooks/useGenerateQueryParams";
import { useFetchQuery, useMutation } from "hooks/useQueries";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  moneyCommaSeparator,
  moneyCommaSeparatorPoint,
} from "utils/MathFunction";
import axiosInstance from "utils/axiosInstance";
import { handleCapitalize, UserRoleTypes } from "utils/common";
import { extraColumnsField, matchDate, refetchQuery } from "utils/helpers";
import useDocumentTitle from "utils/useDocumentTitle";

interface Props {
  title: string;
  name: string;
  apiRoute: string;
  apiRouteKey: string;
  tableName: string;
  routeNavigate: {
    navigate: string;
    transactionType?: string;
    assetType?: string;
    editNavigate?: string;
  };
  bulkDelete: string;
  requestParams?: {
    key?: string;
    value?: string;
  };
}

const EquityEtf = (props: Props) => {
  const {
    title,
    name,
    apiRoute,
    apiRouteKey,
    tableName,
    routeNavigate,
    bulkDelete,
    requestParams,
  } = props;

  useDocumentTitle(title);

  const navigate = useNavigate();

  const {
    state: { userData: userInfo },
  } = useAppContext();

  const [selectedIds, setSelectedIds] = useState([]);
  const [deleteConfirmation, setDeleteConfirmation] = useState<boolean>(false);

  const requestBody = useGenerateQueryParams(name, {
    [requestParams?.key]: requestParams?.value,
  });

  const { data, isFetching } = useFetchQuery({
    route: apiRoute,
    key: [apiRouteKey, requestBody],
    requestBody,
  });

  const { mutate: handleMultiDelete } = useMutation({
    mutationFn: ({ ids }: { ids: any }) =>
      axiosInstance?.delete(bulkDelete, {
        data: { ids: ids },
      }),
    onSuccess: () => {
      refetchQuery(apiRouteKey);
      setDeleteConfirmation(false);
      setSelectedIds([]);
    },
  });

  const columns: (GridColDef & { visibleType?: string[] })[] = [
    {
      ...extraColumnsField("Date", "date", 150, 1),
      valueFormatter: (value) => matchDate(value.value),
      visibleType: ["tradeBook", "privateEquityList", "aif", "FDList"],
    },
    {
      ...extraColumnsField("Portfolio", "clientName", 250, 1),
      renderCell: (params) => {
        return (
          <Grid2
            container
            display={"flex"}
            flexDirection={"column"}
            alignItems={"flex-start"}
          >
            <Typography
              fontWeight={700}
              maxWidth={"100%"}
              textOverflow={"ellipsis"}
              overflow={"hidden"}
            >
              {params?.row?.clientName}
            </Typography>
            <Typography fontSize={12}>
              {params?.row?.portfolioName} - {params?.row?.portfolioCode}
            </Typography>
          </Grid2>
        );
      },
      visibleType: [
        "tradeBook",
        "privateEquityList",
        "aif",
        "FDList",
        "FDPayoutList",
      ],
    },
    {
      ...extraColumnsField("Company Name", "companyName", 150, 1),
      visibleType: [
        "tradeBook",
        "privateEquityList",
        "aif",
        "FDList",
        "FDPayoutList",
      ],
    },
    {
      ...extraColumnsField("FD Account No.", "fdAccountNo", 150, 1),
      visibleType: ["FDList", "FDPayoutList"],
    },
    {
      ...extraColumnsField("Interest Type", "interestType", 150, 1),
      visibleType: ["FDList", "FDPayoutList"],
    },
    {
      ...extraColumnsField("NSE/BSE Code", "nseCode", 150, 1),
      valueGetter: (params) => params?.row?.nseCode || params?.row?.bseCode,
      visibleType: ["tradeBook"],
    },
    {
      ...extraColumnsField("Transaction Type", "transactionType", 150, 1),
      valueFormatter: (params) => handleCapitalize(params?.value),
      visibleType: [
        "tradeBook",
        "privateEquityList",
        "aif",
        "FDList",
        "FDPayoutList",
      ],
    },
    {
      ...extraColumnsField("Quantity", "quantity", 150, 1),
      align: "right",
      headerAlign: "right",
      valueGetter: (params) => params?.row?.quantity || "0",
      valueFormatter: (params: any) => moneyCommaSeparatorPoint(params?.value),
      visibleType: [
        "tradeBook",
        "privateEquityList",
        "aif",
        "FDList",
        "FDPayoutList",
      ],
    },
    {
      ...extraColumnsField("Price", "price", 150, 1),
      align: "right",
      headerAlign: "right",
      valueGetter: (params) => params?.row?.price || "0",
      valueFormatter: (params) => moneyCommaSeparator(params.value),
      visibleType: [
        "tradeBook",
        "privateEquityList",
        "aif",
        "FDList",
        "FDPayoutList",
      ],
    },
    {
      ...extraColumnsField("Brokerage", "brokerage", 150, 1),
      align: "right",
      headerAlign: "right",
      valueGetter: (params) => params.row.brokerage || "0",
      valueFormatter: (params) => moneyCommaSeparator(params.value),
      visibleType: ["tradeBook", "privateEquityList", "aif", "FDList"],
    },
    {
      ...extraColumnsField(
        "Amount With Brokerage",
        "amountWithBrokerage",
        190,
        1,
      ),
      align: "right",
      headerAlign: "right",
      valueGetter: (params) => params.row.amountWithBrokerage || "-",
      valueFormatter: (params) => moneyCommaSeparator(params.value),
      visibleType: ["tradeBook", "privateEquityList", "aif", "FDList"],
    },
    {
      ...extraColumnsField("TDS", "tds", 100, 1),
      type: "number",
      visibleType: ["FDList"],
    },
    {
      ...extraColumnsField("STT", "stt", 150, 1),
      align: "right",
      headerAlign: "right",
      valueGetter: (params) => params.row.stt || "0",
      valueFormatter: (params) => moneyCommaSeparator(params.value),
      visibleType: ["tradeBook", "privateEquityList", "aif"],
    },
    {
      ...extraColumnsField("GST", "gst", 150, 1),
      align: "right",
      headerAlign: "right",
      valueGetter: (params) => params.row.gst || "0",
      valueFormatter: (params) => moneyCommaSeparator(params.value),
      visibleType: ["tradeBook", "privateEquityList", "aif"],
    },
    {
      ...extraColumnsField("Stamp Charges", "stampCharges", 170, 1),
      align: "right",
      headerAlign: "right",
      valueGetter: (params) => params.row.stampCharges || "0",
      valueFormatter: (params) => moneyCommaSeparator(params.value),
      visibleType: ["tradeBook", "privateEquityList", "aif"],
    },
    {
      ...extraColumnsField("Transaction Charges", "transactionCharges", 170, 1),
      align: "right",
      headerAlign: "right",
      valueGetter: (params) => params.row.transactionCharges || "0",
      valueFormatter: (params) => moneyCommaSeparator(params.value),
      visibleType: ["tradeBook", "privateEquityList", "aif"],
    },
    {
      ...extraColumnsField("SEBI Turnover Fees", "sebiTurnoverFees", 170, 1),
      align: "right",
      headerAlign: "right",
      valueGetter: (params) => params.row.sebiTurnoverFees || "0",
      valueFormatter: (params) => moneyCommaSeparator(params.value),
      visibleType: ["tradeBook"],
    },
    {
      ...extraColumnsField("Other Charges", "otherCharges", 170, 1),
      align: "right",
      headerAlign: "right",
      valueGetter: (params) => params.row.otherCharges || "0",
      valueFormatter: (params) => moneyCommaSeparator(params.value),
      visibleType: ["tradeBook", "privateEquityList", "aif"],
    },
    {
      ...extraColumnsField("Bill Amount", "billAmount", 150, 1),
      align: "right",
      headerAlign: "right",
      valueGetter: (params) => params.row.billAmount || "0",
      valueFormatter: (params) => moneyCommaSeparator(params.value),
      visibleType: ["tradeBook", "privateEquityList", "aif", "FDList"],
    },
    {
      ...extraColumnsField("ISIN Code", "isinCode", 150, 1),
      align: "right",
      headerAlign: "right",
      visibleType: ["tradeBook", "privateEquityList", "aif"],
    },
    {
      ...extraColumnsField("Contract Note Number", "contractNoteNum", 190, 1),
      valueGetter: (params) => params.row.contractNoteNum || "-",
      valueFormatter: (params) => params.value,
      visibleType: ["tradeBook"],
    },
    {
      ...extraColumnsField("Broker Name", "brokerName", 150, 1),
      visibleType: ["tradeBook"],
    },
  ];

  return (
    <>
      <GKDataGrid
        checkboxSelection={userInfo?.userRole !== UserRoleTypes.client}
        onRowSelectionModelChange={(params) => setSelectedIds(params)}
        rowSelectionModel={selectedIds}
        loading={isFetching}
        columns={columns.filter((column) => {
          return column.visibleType?.includes(name);
        })}
        rows={data?.data?.data || []}
        maxHeight={"calc(100vh - 312px)"}
        onRowClick={(row) => {
          if (row?.row?.transactionType !== "BUY_BACK") {
            navigate(`${routeNavigate?.editNavigate}/${row?.id}`, {
              state: {
                transactionType: routeNavigate?.transactionType || "",
                assetType: routeNavigate?.assetType || "",
                id: row?.row?.portfolio,
              },
            });
          }
        }}
        initialState={{
          columns: {
            columnVisibilityModel: {
              stt: false,
              gst: false,
              stampCharges: false,
              otherCharges: false,
              transactionCharges: false,
              sebiTurnoverFees: false,
            },
          },
        }}
        totalCount={data?.data?.totalCount || 0}
        name={name}
        headerComponent={
          userInfo?.userRole !== UserRoleTypes.client && (
            <Grid2 container gap={1} justifyContent={"end"}>
              {selectedIds?.length !== 0 && (
                <GKButton
                  variant="outlined"
                  size="medium"
                  color="error"
                  onClick={() => {
                    setDeleteConfirmation(true);
                  }}
                >
                  Delete
                </GKButton>
              )}
              {selectedIds?.length !== 0 && (
                <GKButton
                  variant="outlined"
                  size="medium"
                  color="primary"
                  onClick={() => {
                    setSelectedIds([]);
                  }}
                >
                  Cancel
                </GKButton>
              )}

              {name === "FDList" && selectedIds?.length === 0 && (
                <GKButton
                  variant="outlined"
                  size="medium"
                  onClick={() => {
                    navigate("/app/reports/fixed-deposit-interest");
                  }}
                  startIcon={<CgMathPlus size={14} />}
                >
                  Interest Payout
                </GKButton>
              )}

              {selectedIds?.length === 0 && (
                <>
                  <GKButton
                    variant="contained"
                    size="medium"
                    onClick={() => {
                      navigate(routeNavigate.navigate, {
                        state: {
                          transactionType: routeNavigate?.transactionType || "",
                          assetType: routeNavigate?.assetType || "",
                        },
                      });
                    }}
                    startIcon={<CgMathPlus size={14} />}
                  >
                    Add Trade
                  </GKButton>
                </>
              )}
            </Grid2>
          )
        }
        tableName={tableName}
        exportFile={{
          path: apiRoute,
          exportName: "TransactionList",
          paramsValue: {
            key: requestParams?.key,
            value: requestParams?.value,
          },
        }}
      />

      {deleteConfirmation && (
        <ConfirmDeleteModal
          open={deleteConfirmation}
          setOpen={setDeleteConfirmation}
          onDelete={() => handleMultiDelete({ ids: selectedIds })}
          title={"Bulk Statement"}
          isDelete
          deleteMessage={`Are you sure that you want to delete the selected stocks & once deleted it cannot be reverted?`}
        />
      )}
    </>
  );
};

export default EquityEtf;
