import { LoadingButton } from "@mui/lab";
import { GKModal, GKOtpInput, Grid2 } from "components";
import { useMutation } from "hooks/useQueries";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import axiosInstance from "utils/axiosInstance";
import { GKModalProps } from "utils/commonInterface";

interface Props {
  optApi: string;
  onSuccess: () => void;
}

const VerifyModal = (props: Props & GKModalProps) => {
  const { open, setOpen, optApi, onSuccess } = props;

  const form = useFormContext();

  const [otpCode, setOtpCode] = useState("");
  const [isDoneTyping, setIsDoneTyping] = useState(false);

  const { mutate: handleVerifyOPT } = useMutation({
    mutationFn: (otpCode: any) =>
      axiosInstance.post(`/${optApi}/verify/`, {
        otp: otpCode,
      }),
    onSuccess: (response) => {
      form.setValue("token", response?.data?.data?.token);
      onSuccess();
    },
  });

  const { mutate: handleGetOPT, isLoading } = useMutation({
    mutationFn: (optApi: any) => axiosInstance.get(`/${optApi}/resend/`),
  });

  useEffect(() => {
    if (isDoneTyping) handleVerifyOPT(otpCode);
  }, [isDoneTyping]);

  return (
    <GKModal
      open={open}
      setOpen={setOpen}
      modalTitle="Verify OTP"
      titleComponent={
        <LoadingButton
          variant="outlined"
          size="medium"
          loading={isLoading}
          onClick={() => handleGetOPT(optApi)}
        >
          Resend OTP
        </LoadingButton>
      }
    >
      <Grid2 container spacing={1}>
        <Grid2 size={12}>
          <GKOtpInput
            value={otpCode}
            onChange={setOtpCode}
            setIsTyping={setIsDoneTyping}
          />
        </Grid2>
      </Grid2>
    </GKModal>
  );
};

export default VerifyModal;
