/* eslint-disable no-bitwise */
import { paletteLightTheme } from "./ColorTheme";
import { UserRoleTypes } from "./common";

export const returnInstaTypeColor = (str: string) => {
  switch (str) {
    case "CE":
      return paletteLightTheme.palette.primary.main;
    case "NCE":
      return paletteLightTheme.palette.secondary.main;
    case "MF":
      return paletteLightTheme.palette.warning.main;
    default:
      return paletteLightTheme.palette.primary.main;
  }
};

export const handleReturnPositiveNegative = (val: number) => {
  return val < 0
    ? paletteLightTheme.palette.error.main
    : paletteLightTheme.palette.success.main;
};

export const handleReturnRiskColors = (text: any) => {
  switch (text.toLowerCase()) {
    case "moderate":
      return "#ECC704";
    case "low":
      return "#E04101";
    case "high":
      return "#46A900";
    default:
      return "#000";
  }
};

export const profileBorderColorRoleWise = (role: string) => {
  if (role === UserRoleTypes.advisor) {
    return paletteLightTheme.palette.info.main;
  }

  if (role === UserRoleTypes.client) {
    return paletteLightTheme.palette.success.main;
  }

  return paletteLightTheme.palette.warning.main;
};

export function getColorFromString(str: string) {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  let color = "#";
  for (let i = 0; i < 3; i++) {
    color += `00${((hash >> (i * 8)) & 0xff).toString(16)}`.slice(-2);
  }
  return color;
}
