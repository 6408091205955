/* eslint-disable import/no-named-as-default-member */
import { LoadingButton } from "@mui/lab";
import { useAppContext } from "AppContext";
import {
  GKModal,
  GKSearchSelect,
  GKSelect,
  Grid2,
  MenuItem,
  Show,
  TextField,
  ViewPdf,
} from "components";
import { useFetchQuery, useMutation } from "hooks/useQueries";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import {
  CUSTOM_AGREEMENT_MODAL_PORTFOLIO,
  GET_ALL_AGREEMENT,
  GET_ALL_FEE_PLAN,
} from "utils/apiRoutes";
import axiosInstance from "utils/axiosInstance";
import { GKModalProps } from "utils/commonInterface";
import {
  AGREEMENT_SEND_OPTIONS_SOURCE,
  AGREEMENT_SIGNATURE_SOURCE,
} from "utils/constants";
import { refetchQuery } from "utils/helpers";
import { QueryKeys } from "utils/queryKey";
import { handleReturnAutocompletePlaceholder } from "utils/StringFunctions";

interface Props {
  clientId?: any;
  feePlanId?: any;
  portfolioId?: any;
  basketId?: any;
}

const ClientOnboardingCustomAgreement = (props: Props & GKModalProps) => {
  const { setOpen, open, clientId, portfolioId } = props;

  const {
    state: { onBoardflowData },
  } = useAppContext();

  const [viewPdf, setViewPdf] = useState(false);

  const { data } = useFetchQuery({
    key: ["GET_ALL_CLIENT_ONBOARDING_CUSTOM_AGREEMENTS"],
    route: GET_ALL_AGREEMENT,
  });

  const { data: feePlanDropdownData } = useFetchQuery({
    key: ["GET_ALL_CLIENT_ONBOARDING_FEE_PLAN"],
    route: GET_ALL_FEE_PLAN,
  });

  const { data: equityBasketData } = useFetchQuery({
    key: ["GET_ALL_CLIENT_ONBOARDING_EQUITY_BASKET"],
    route: CUSTOM_AGREEMENT_MODAL_PORTFOLIO,
  });

  const form = useForm({
    defaultValues: {
      document: [],
      signatureType: onBoardflowData?.defaultAdvisorSignType || "",
      sendOptions: "",
      portfolio: portfolioId,
      template: onBoardflowData.agreementTemplate || "",
      selectedTemplate: null,
      generateResponse: null,
      feePlan: props?.feePlanId || "",
      equityBasket: props?.basketId || "",
    },
  });

  form.watch();

  const { mutate: handleUploadAgreement, isLoading } = useMutation({
    mutationKey: ["CLIENT_ONBOARDING_SEND_CUSTOM_AGREEMENT"],
    mutationFn: (data: any) =>
      axiosInstance.post(`/agreements/${clientId}/`, data),
    onSuccess: (response) => {
      refetchQuery(QueryKeys.GET_COMPLIANCE_BY_CLIENT_ID);
      refetchQuery(QueryKeys.GET_CLIENT_BY_ID);
      toast.success(
        response?.data?.message || "Agreement uploaded successfully",
      );
      setOpen(false);
    },
  });

  const { mutate: handleGenerateAgreement, isLoading: generateLoading } =
    useMutation({
      mutationKey: ["CLIENT_ONBOARDING_GENERATE_AGREEMENT"],
      mutationFn: (data: any) =>
        axiosInstance.post(
          `/custom-agreement/${clientId}/generate-agreement/`,
          data,
        ),
      onSuccess: (response: any) => {
        refetchQuery(QueryKeys.GET_COMPLIANCE_BY_CLIENT_ID);
        refetchQuery(QueryKeys.GET_CLIENT_BY_ID);
        form.setValue("generateResponse", response?.data?.data);
        toast.success(
          response?.data?.message || "Agreement generated successfully",
        );
      },
    });

  const handleSubmitForm = (values: any) => {
    const fileData = new FormData();
    fileData.append("sendOptions", values.sendOptions);
    fileData.append("signatureType", values.signatureType);
    fileData.append("client", clientId);
    fileData.append("template", values.template);
    fileData.append("portfolio", portfolioId);

    handleUploadAgreement(fileData);
  };

  return (
    <GKModal
      open={open}
      setOpen={setOpen}
      modalTitle={"Custom Agreement"}
      footer={
        <Grid2 container justifyContent={"flex-end"} gap={1}>
          <Show.When isTrue={Boolean(form.getValues("generateResponse"))}>
            <LoadingButton
              variant="outlined"
              size="medium"
              color="dark"
              loading={generateLoading}
              onClick={() => {
                setViewPdf(true);
              }}
            >
              Preview Agreement
            </LoadingButton>
          </Show.When>
          <LoadingButton
            sx={{ ml: "auto" }}
            variant="contained"
            size="medium"
            loading={generateLoading}
            onClick={() => {
              handleGenerateAgreement({
                portfolioId: portfolioId,
                template: form.getValues("template"),
                feePlan: form.getValues("feePlan.id"),
                modelPortfolio: form.getValues("equityBasket"),
              });
            }}
          >
            Generate Agreement
          </LoadingButton>
          <LoadingButton
            type="submit"
            variant="contained"
            form="client-onboarding-agreement-form"
            size="medium"
            loading={isLoading}
            disabled={!form.getValues("generateResponse")}
          >
            Send Agreement
          </LoadingButton>
        </Grid2>
      }
    >
      <form
        id="client-onboarding-agreement-form"
        onSubmit={form.handleSubmit(handleSubmitForm)}
      >
        <Grid2 container spacing={2}>
          <Grid2 size={12}>
            <Controller
              name="template"
              control={form.control}
              render={({ field }) => (
                <GKSelect
                  {...field}
                  inputLabel={"Template"}
                  displayEmpty
                  onChange={(e) => {
                    field.onChange(e);
                    form.setValue(
                      "selectedTemplate",
                      data?.data?.data?.find(
                        (d: any) => d.id === e.target.value,
                      ),
                    );
                    form.setValue("generateResponse", null);
                  }}
                >
                  <MenuItem style={{ display: "none" }} value="">
                    Select Template
                  </MenuItem>
                  {data?.data?.data?.length === 0 ? (
                    <MenuItem value={"no-data"} disabled>
                      No data found
                    </MenuItem>
                  ) : (
                    data?.data?.data?.map((dataItem: any, i: any) => (
                      <MenuItem key={i} value={dataItem.id}>
                        {dataItem.title}
                      </MenuItem>
                    ))
                  )}
                </GKSelect>
              )}
            />
          </Grid2>
          <Show.When
            isTrue={form.getValues("selectedTemplate")?.isModelPortfolio}
          >
            <Grid2 size={6}>
              <Controller
                name="equityBasket"
                control={form.control}
                render={({ field }) => (
                  <GKSearchSelect
                    inputLabel="Equity Basket"
                    options={equityBasketData?.data?.data || []}
                    getOptionLabel={(opt) => opt?.name || opt}
                    renderInput={(props: any) => (
                      <TextField
                        {...props}
                        placeholder={handleReturnAutocompletePlaceholder({
                          placeholder: `Select Equity Basket`,
                          value: field.value,
                        })}
                      />
                    )}
                    onChange={(event, val) => {
                      field.onChange(val.id);
                    }}
                    value={
                      equityBasketData?.data?.data?.find(
                        (item: any) => item?.id === field?.value,
                      )?.name
                    }
                  />
                )}
              />
            </Grid2>
          </Show.When>
          <Show.When isTrue={form.getValues("selectedTemplate")?.isFeePlan}>
            <Grid2 size={6}>
              <Controller
                name="feePlan"
                control={form.control}
                render={({ field }) => (
                  <GKSearchSelect
                    options={feePlanDropdownData?.data?.data || []}
                    getOptionLabel={(opt) => opt?.name || opt}
                    inputLabel="Fee Plan"
                    renderInput={(props: any) => (
                      <TextField
                        {...props}
                        placeholder={handleReturnAutocompletePlaceholder({
                          placeholder: `Select Fee Plan`,
                          value: field.value,
                        })}
                      />
                    )}
                    onChange={(event, val) => {
                      field.onChange(val?.value);
                    }}
                    value={
                      equityBasketData?.data?.data?.find(
                        (item: any) => item?.value === field?.value,
                      )?.name
                    }
                  />
                )}
              />
            </Grid2>
          </Show.When>
          <Grid2 container size={12} spacing={1}>
            <Grid2
              size={12}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Show.When isTrue={viewPdf}>
                <ViewPdf
                  file={form.getValues("generateResponse")?.file}
                  setOpen={setViewPdf}
                  open={viewPdf}
                />
              </Show.When>
            </Grid2>
            <Show.When isTrue={Boolean(form.getValues("generateResponse"))}>
              <Grid2 size={6}>
                <Controller
                  name="signatureType"
                  control={form.control}
                  rules={{
                    required: {
                      value: true,
                      message: "Signature Type is required",
                    },
                  }}
                  render={({ field, formState: { errors } }) => (
                    <GKSelect
                      {...field}
                      className="col-span-6"
                      inputLabel="Sign Type"
                      requiredField
                      displayEmpty
                      error={Boolean(errors.signatureType)}
                      helperText={
                        Boolean(errors.signatureType) &&
                        errors.signatureType?.message
                      }
                    >
                      <MenuItem style={{ display: "none" }} value="">
                        Select Sign Type
                      </MenuItem>
                      {AGREEMENT_SIGNATURE_SOURCE?.map((dataItem, i) => (
                        <MenuItem key={i} value={dataItem.value}>
                          {dataItem.name}
                        </MenuItem>
                      ))}
                    </GKSelect>
                  )}
                />
              </Grid2>
              <Grid2 size={6}>
                <Controller
                  name="sendOptions"
                  control={form.control}
                  rules={{
                    required: {
                      value: true,
                      message: "Send Agreement Via is required",
                    },
                  }}
                  render={({ field, formState: { errors } }) => (
                    <GKSelect
                      {...field}
                      className="col-span-6"
                      inputLabel="Send Agreement Via"
                      displayEmpty
                      requiredField
                      error={Boolean(errors.sendOptions)}
                      helperText={
                        Boolean(errors.sendOptions) &&
                        errors.sendOptions?.message
                      }
                    >
                      <MenuItem style={{ display: "none" }} value="">
                        Send Agreement Via
                      </MenuItem>
                      {AGREEMENT_SEND_OPTIONS_SOURCE?.map((dataItem, i) => (
                        <MenuItem key={i} value={dataItem.value}>
                          {dataItem.name}
                        </MenuItem>
                      ))}
                    </GKSelect>
                  )}
                />
              </Grid2>
            </Show.When>
          </Grid2>
        </Grid2>
      </form>
    </GKModal>
  );
};

export default ClientOnboardingCustomAgreement;
