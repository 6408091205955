import {
  Bars,
  Card,
  Divider,
  GKTypography,
  Grid2,
  Tab,
  Tabs,
  Typography,
} from "components";
import { useDashboard } from "hooks/useDashboard";
import DashboardLayout from "layouts/DashboardLayout";
import _ from "lodash";
import useDocumentTitle from "utils/useDocumentTitle";
import CardsCollection from "./CardsCollection";
import HighChartLine from "./HighChartLine";

const Dashboard = () => {
  useDocumentTitle("Dashboard");

  const {
    convertedData,
    AUMData,
    loading,
    isPortfolioTab,
    setIsPortfolioTab,
    isCurrentTab,
    setIsCurrentTab,
    returnTopTenData,
    theme,
    LineChart,
  } = useDashboard();

  return (
    <DashboardLayout title="Dashboard">
      <Grid2 container spacing={1}>
        <Grid2 container size={12} spacing={1}>
          {convertedData?.map((item, index) => (
            <Grid2
              key={index}
              size={2.4}
              sx={{
                height: {
                  xs: "25vh",
                  xl: "20vh",
                },
              }}
              display={"flex"}
              position={"relative"}
            >
              <Card
                style={{
                  margin: 0,
                  padding: 8,
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  flexDirection: "column",
                  marginTop: "auto",
                  width: "100%",
                  height: "80%",
                }}
                key={item?.id}
              >
                <Grid2 container spacing={1} size={12}>
                  <Typography
                    style={{
                      position: "absolute",
                      width: 50,
                      height: 50,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      background: `linear-gradient(to right,${theme.palette.primary.main}, ${theme.palette.secondary?.main})`,
                      borderRadius: 10,
                      top: 8,
                      left: 30,
                      boxShadow: `3px 3px 8px -2px ${theme.palette.secondary.main}`,
                    }}
                  >
                    <item.icon
                      color={theme.palette.light?.main}
                      size={24}
                      fontWeight={600}
                    />
                  </Typography>
                  <Grid2 size={12} textAlign={"right"}>
                    <Typography fontWeight={700} fontSize={13}>
                      {item?.name}
                    </Typography>
                  </Grid2>
                  <Grid2 size={12}>
                    <Divider />
                  </Grid2>
                  <Grid2 size={12} textAlign={"right"}>
                    <GKTypography
                      fontWeight={700}
                      fontSize={16}
                      width={"100%"}
                      color={theme.palette.primary.main}
                      loading={loading}
                    >
                      {item?.value}
                    </GKTypography>
                  </Grid2>
                </Grid2>
              </Card>
            </Grid2>
          ))}
        </Grid2>
        <Grid2 size={8}>
          {/** ----------------------------------------------------------------------------------------------------------- */}

          <HighChartLine data={LineChart?.data} loading={loading} />
        </Grid2>
        <Grid2 size={4}>
          {/** ----------------------------------------------------------------------------------------------------------- */}
          <CardsCollection data={AUMData?.data?.tickets} isLoading={loading} />
        </Grid2>
        {!loading && (
          <Grid2 size={12}>
            <Card>
              <Grid2 container spacing={1}>
                <Grid2 size={8}>
                  <Typography
                    fontWeight={600}
                    color={theme.palette.primary.main}
                    fontSize={18}
                  >
                    Top 10 Assets Under Management
                  </Typography>
                  <Typography fontWeight={500} fontSize={14}>
                    Showing top 10 AUM for the selected value
                  </Typography>
                </Grid2>
                <Grid2
                  size={4}
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"flex-end"}
                  gap={2}
                >
                  <Tabs
                    className="gridkey"
                    variant="standard"
                    value={isPortfolioTab}
                    onChange={(e, val) => setIsPortfolioTab(val)}
                  >
                    <Tab disableRipple label="Portfolio" />
                    <Tab disableRipple label="Client" />
                  </Tabs>
                  <Tabs
                    className="gridkey"
                    variant="standard"
                    value={isCurrentTab}
                    onChange={(e, val) => setIsCurrentTab(val)}
                  >
                    <Tab disableRipple label="Current" />
                    <Tab disableRipple label="Invested" />
                  </Tabs>
                </Grid2>
                <Grid2 size={12}>
                  {returnTopTenData(isPortfolioTab)?.map(
                    (dataItem: any, index: number) => {
                      return (
                        <div key={index}>
                          <Bars
                            value={
                              isCurrentTab === 0
                                ? dataItem.currentPlusCash
                                : dataItem.investment
                            }
                            name={dataItem?.name}
                            max={_.max(
                              returnTopTenData(isPortfolioTab)?.map(
                                (labelData: any) =>
                                  isCurrentTab === 0
                                    ? Math.abs(labelData.currentPlusCash)
                                    : Math.abs(labelData.investment),
                              ),
                            )}
                          />
                        </div>
                      );
                    },
                  )}
                </Grid2>
              </Grid2>
            </Card>
          </Grid2>
        )}
      </Grid2>
    </DashboardLayout>
  );
};

export default Dashboard;
