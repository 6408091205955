import { GridColDef } from "@mui/x-data-grid-pro";
import {
  CgMathPlus,
  ConfirmDeleteModal,
  FiTrash2,
  GKButton,
  GKClientDataGrid,
  Grid2,
  IconButton,
} from "components";
import { useFetchQuery, useMutation } from "hooks/useQueries";
import { useState } from "react";
import { ADVISOR_COMPANY_MAPPING } from "utils/apiRoutes";
import axiosInstance from "utils/axiosInstance";
import { extraColumnsField } from "utils/helpers";
import useDocumentTitle from "utils/useDocumentTitle";
import AddCompanyMappingModal from "./AddCompanyMappingModal";

const CompanyMappingPreferences = () => {
  useDocumentTitle("Company Mapping");
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [deleteCompanyMappingId, setSelectCompanyMappingId] = useState<
    number | string
  >();
  const [addCompanyMapping, setAddCompanyMapping] = useState(false);

  const requestBody = {
    requireTotalCount: true,
    show_all: true,
  };

  const { data, isFetching, refetch } = useFetchQuery({
    key: ["COMPANY_MAPPING", requestBody],
    route: ADVISOR_COMPANY_MAPPING,
    requestBody,
  });

  const { mutate: deleteCompanyMapping } = useMutation({
    mutationKey: ["DELETE_COMPANY_MAPPING"],
    mutationFn: ({ id }: { id: any }) =>
      axiosInstance.delete(`/advisor-company-mappings/${id}/`),
    onSuccess: () => {
      refetch();
    },
  });

  const columns: GridColDef[] = [
    {
      ...extraColumnsField("Company Name", "companyName", 150, 1),
    },
    {
      ...extraColumnsField("Mappings", "advisorCompany", 150, 1),
    },
    {
      ...extraColumnsField("Actions", "action", 100, 1),
      align: "right",
      headerAlign: "right",
      renderCell: (params) => {
        return (
          <IconButton
            color="error"
            className="outline"
            onClick={(e) => {
              e.stopPropagation();
              setSelectCompanyMappingId(params?.id);
              setConfirmationModal(true);
            }}
          >
            <FiTrash2 size={20} />
          </IconButton>
        );
      },
    },
  ];

  return (
    <>
      <Grid2 container spacing={1}>
        <Grid2 size={12}>
          <GKClientDataGrid
            name="companyMappingPreferences"
            columnsButton={false}
            loading={isFetching}
            columns={columns}
            rows={data?.data?.data || []}
            headerComponent={
              <GKButton
                variant="contained"
                onClick={(e) => {
                  e.stopPropagation();
                  setAddCompanyMapping(true);
                }}
                startIcon={<CgMathPlus size={18} />}
              >
                Add Mapping
              </GKButton>
            }
            maxHeight={"calc(100vh - 340px)"}
          />
        </Grid2>

        {confirmationModal && (
          <ConfirmDeleteModal
            open={confirmationModal}
            setOpen={setConfirmationModal}
            onDelete={() =>
              deleteCompanyMapping({ id: deleteCompanyMappingId })
            }
            title={"Company Mapping"}
            isDelete
          />
        )}

        {addCompanyMapping && (
          <AddCompanyMappingModal
            open={addCompanyMapping}
            setOpen={setAddCompanyMapping}
          />
        )}
      </Grid2>
    </>
  );
};

export default CompanyMappingPreferences;
