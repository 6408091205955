import { LoadingButton } from "@mui/lab";
import { useAppContext } from "AppContext";
import {
  Avatar,
  Divider,
  FiTrash2,
  GKButton,
  GKDatePicker,
  GKSearchSelect,
  GKTextField,
  Grid2,
  IconButton,
  PhoneInput,
  SimpleLoader,
  styled,
  TextField,
  Typography,
} from "components";
import dayjs from "dayjs";
import { ProfileAPI } from "hooks/ProfileAPI";
import { useFetchQuery } from "hooks/useQueries";
import { isEmpty } from "lodash";
import EditLogoModal from "Pages/CompanyInformation/EditLogoModal";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { USER_PROFILE_DETAILS_BY_ID } from "utils/apiRoutes";
import { PERSONAL_GENDER } from "utils/constants";
import { handleReturnAutocompletePlaceholder } from "utils/StringFunctions";
import { ThemeContainer } from "utils/Theme";
import useDocumentTitle from "utils/useDocumentTitle";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const UserProfile = () => {
  useDocumentTitle("User Profile");

  const [hasError, setHasError] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [croppedImage, setCroppedImage] = useState<string | null>(null);

  const { theme } = ThemeContainer();

  const {
    state: { userData, countryList, stateList, cityList },
    dispatch,
  } = useAppContext();

  const { handleUpdateProfile, updateProfileUpdateLoading } = ProfileAPI();

  const { data: profileData, isLoading } = useFetchQuery({
    route: USER_PROFILE_DETAILS_BY_ID(userData?.id),
    key: ["GET_PROFILE_DETAILS"],
    enabled: Boolean(userData?.id),
    success: (response: any) => {
      if (!isEmpty(response?.data?.data?.country)) {
        dispatch({
          type: "SELECTED_COUNTRY",
          data: countryList.find(
            (dataItem) => dataItem.name === response?.data?.data?.country,
          )?.id,
        });
      }

      if (!isEmpty(response?.data?.data?.state)) {
        dispatch({
          type: "SELECTED_STATE",
          data: stateList.find(
            (dataItem) => dataItem.name === response?.data?.data?.state,
          )?.id,
        });
      }

      if (!isEmpty(response?.data?.data?.city)) {
        dispatch({
          type: "SELECTED_CITY",
          data: cityList.find(
            (dataItem) => dataItem.name === response?.data?.data?.city,
          )?.id,
        });
      }
    },
  });

  const form = useForm({
    defaultValues: {
      profilePic: [],
      email: "",
      name: "",
      gender: "",
      maritalStatus: "",
      fatherName: "",
      motherName: "",
      dateOfBirth: "",
      address: "",
      city: "",
      state: "",
      country: "",
      pincode: "",
      occupation: "",
      pan: "",
      aadhar: "",
      emergencyCountryCode: "+91",
      emergencyContact: "",
      emergencyContactNum: "",
    },
    values: { ...profileData?.data?.data, profilePic: [] },
  });

  if (isLoading) return <SimpleLoader />;

  const onSubmit = (values: any) => {
    const payload = {
      email: values?.email,
      name: values?.name,
      gender: values?.gender,
      maritalStatus: values?.maritalStatus,
      fatherName: values?.fatherName,
      motherName: values?.motherName,
      dateOfBirth: values?.dateOfBirth,
      address: values?.address,
      city: values?.city,
      state: values?.state,
      country: values?.country,
      pincode: values?.pincode,
      occupation: values?.occupation,
      pan: values?.pan,
      aadhar: values?.aadhar,
      emergencyContact: values?.emergencyContact,
      emergencyContactNum: values?.emergencyContactNum,
    };

    handleUpdateProfile(payload);
  };

  const handleProfilePicChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImage(reader.result as string);
        setIsModalOpen(true);
      };
      reader.readAsDataURL(file);
      event.target.value = "";
    }
  };

  const handleProfilePicDelete = () => {
    const formData = new FormData();
    formData.append("profilePic", "");
    handleUpdateProfile(formData);
    setCroppedImage(null);
  };

  const handleCropComplete = (croppedImageUrl: string) => {
    setCroppedImage(croppedImageUrl);
    setIsModalOpen(false);

    fetch(croppedImageUrl)
      .then((res) => res.blob())
      .then((blob) => {
        const file = new File(
          [blob],
          `profile-picture.${blob.type.split("/")[1]}`,
          {
            type: blob?.type,
          },
        );
        form.setValue("profilePic", [file]);

        const formData = new FormData();
        formData.append("profilePic", file);
        handleUpdateProfile(formData);
      });
  };

  return (
    <form onSubmit={form.handleSubmit(onSubmit)} id="profile-form">
      <Grid2 container spacing={2}>
        <Grid2 size={3}>
          <Typography
            fontSize={16}
            fontWeight={500}
            color={theme.palette.secondaryText.main}
          >
            Personal info
          </Typography>
        </Grid2>
        <Grid2 container size={9} spacing={2}>
          <Grid2
            container
            size={12}
            display={"flex"}
            alignItems={"center"}
            gap={1}
          >
            <Grid2>
              <Avatar
                alt=""
                src={croppedImage || userData?.profilePic}
                sx={{ width: 72, height: 72, border: "1px solid #eee" }}
              />
            </Grid2>
            <Grid2 ml={1}>
              <GKButton
                variant="contained"
                component="label"
                role={undefined}
                tabIndex={-1}
              >
                Upload Profile Photo{" "}
                <VisuallyHiddenInput
                  type="file"
                  accept={".jpg, .png, .svg"}
                  onChange={handleProfilePicChange}
                />
              </GKButton>
            </Grid2>
            <Grid2>
              <IconButton
                sx={{ border: "1px solid #CDD5DF", borderRadius: "8px", p: 1 }}
                onClick={handleProfilePicDelete}
              >
                <FiTrash2 size={18} />
              </IconButton>
            </Grid2>
          </Grid2>

          <Grid2 size={6}>
            <Controller
              name="name"
              control={form.control}
              // rules={{
              //   required: {
              //     value: true,
              //     message: "Please enter a valid name",
              //   },
              //   validate: validateNotWhitespaceOnly,
              // }}
              render={({ field, fieldState: { error } }) => {
                return (
                  <GKTextField
                    {...field}
                    inputLabel="Name"
                    // requiredField
                    error={!!error}
                    helperText={error?.message}
                  />
                );
              }}
            />
          </Grid2>
          <Grid2 size={6}>
            <Controller
              name="aadhar"
              control={form.control}
              render={({ field }) => (
                <GKTextField {...field} inputLabel="Aadhar Number" />
              )}
            />
          </Grid2>
          <Grid2 size={4}>
            <Controller
              name="dateOfBirth"
              control={form.control}
              // rules={{
              //   required: {
              //     value: true,
              //     message: "Please Select Birth of Date",
              //   },
              // }}
              render={({ field, fieldState: { error } }) => (
                <GKDatePicker
                  {...field}
                  // requiredField
                  inputLabel="Date Of Birth"
                  value={dayjs(field.value)}
                  onChange={(value) => {
                    field.onChange(dayjs(value).format("YYYY-MM-DD"));
                  }}
                  minDate={dayjs("01-01-1901")}
                  slotProps={{
                    textField: {
                      error: !!error,
                      helperText: error?.message,
                    },
                  }}
                />
              )}
            />
          </Grid2>
          <Grid2 size={4}>
            <Controller
              name="gender"
              control={form.control}
              render={({ field, fieldState: { error } }) => (
                <GKSearchSelect
                  {...field}
                  disableClearable={false}
                  inputLabel="Gender"
                  options={PERSONAL_GENDER || []}
                  value={
                    PERSONAL_GENDER.find(
                      (dataItem) => dataItem.value === field?.value,
                    ) || ""
                  }
                  onChange={(event, val) => field.onChange(val?.value)}
                  getOptionLabel={(option) => option.name || option}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder={handleReturnAutocompletePlaceholder({
                        placeholder: "Select Gender",
                        value: field.value,
                      })}
                      error={!!error}
                      helperText={!!error && error.message}
                    />
                  )}
                />
              )}
            />
          </Grid2>
          <Grid2 size={4}>
            <Controller
              name="occupation"
              control={form.control}
              // rules={{
              //   required: {
              //     value: true,
              //     message: "Please Enter Occupation",
              //   },
              // }}
              render={({ field, fieldState: { error } }) => (
                <GKTextField
                  {...field}
                  inputLabel="Occupation"
                  // requiredField
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
          </Grid2>
          <Grid2 size={6}>
            <Controller
              name="fatherName"
              control={form.control}
              render={({ field }) => (
                <GKTextField {...field} inputLabel="Father's name" />
              )}
            />
          </Grid2>
          <Grid2 size={6}>
            <Controller
              name="motherName"
              control={form.control}
              render={({ field }) => (
                <GKTextField {...field} inputLabel="Mother's name" />
              )}
            />
          </Grid2>
        </Grid2>

        <Grid2 size={12} my={1}>
          <Divider />
        </Grid2>

        <Grid2 size={3}>
          <Typography
            fontSize={16}
            fontWeight={500}
            color={theme.palette.secondaryText.main}
          >
            Address Details
          </Typography>
        </Grid2>
        <Grid2 container size={9} spacing={2}>
          <Grid2 size={12}>
            <Controller
              name="address"
              control={form.control}
              // rules={{
              //   required: {
              //     value: true,
              //     message: "Please Enter Address",
              //   },
              //   validate: validateNotWhitespaceOnly,
              // }}
              render={({ field, fieldState: { error } }) => (
                <GKTextField
                  {...field}
                  inputLabel="Address"
                  // requiredField
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
          </Grid2>

          <Grid2 size={3}>
            <Controller
              name="pincode"
              control={form.control}
              // rules={{
              //   required: {
              //     value: true,
              //     message: "Please Enter Pincode",
              //   },
              // }}
              render={({ field, fieldState: { error } }) => (
                <GKTextField
                  {...field}
                  type="number"
                  inputLabel="Pincode"
                  // requiredField
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
          </Grid2>
          <Grid2 size={3}>
            <Controller
              name="country"
              control={form.control}
              render={({ field, fieldState: { error } }) => (
                <GKSearchSelect
                  {...field}
                  inputLabel="Country"
                  value={
                    countryList.find(
                      (dataItem) => dataItem.name === field?.value,
                    ) || ""
                  }
                  options={countryList || []}
                  getOptionLabel={(e) => e.name || e}
                  onChange={(e, val) => {
                    field.onChange(val.name);
                    form.setValue("city", "");
                    form.setValue("state", "");
                    dispatch({
                      type: "SELECTED_COUNTRY",
                      data: val.id,
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder={handleReturnAutocompletePlaceholder({
                        placeholder: "Select Country",
                        value: field.value,
                      })}
                      error={!!error}
                      helperText={error?.message}
                    />
                  )}
                />
              )}
            />
          </Grid2>
          <Grid2 size={3}>
            <Controller
              name="state"
              control={form.control}
              render={({ field, fieldState: { error } }) => (
                <GKSearchSelect
                  {...field}
                  inputLabel="State"
                  value={
                    stateList.find(
                      (dataItem) => dataItem.name === field?.value,
                    ) || ""
                  }
                  options={stateList || []}
                  getOptionLabel={(e) => e.name || e}
                  onChange={(e, val) => {
                    field.onChange(val.name);
                    form.setValue("city", "");
                    dispatch({
                      type: "SELECTED_STATE",
                      data: val.id,
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder={handleReturnAutocompletePlaceholder({
                        placeholder: "Select State",
                        value: field.value,
                      })}
                      error={!!error}
                      helperText={error?.message}
                    />
                  )}
                />
              )}
            />
          </Grid2>
          <Grid2 size={3}>
            <Controller
              name="city"
              control={form.control}
              render={({ field, fieldState: { error } }) => (
                <GKSearchSelect
                  {...field}
                  inputLabel="City"
                  renderInput={(er) => (
                    <TextField
                      {...er}
                      placeholder={handleReturnAutocompletePlaceholder({
                        placeholder: "Select City",
                        value: field.value,
                      })}
                      error={!!error}
                      helperText={error?.message}
                    />
                  )}
                  value={
                    cityList.find(
                      (dataItem) => dataItem.name === field?.value,
                    ) || ""
                  }
                  options={cityList || []}
                  getOptionLabel={(e) => e.name || e}
                  onChange={(e, val) => {
                    field.onChange(val.name);
                    dispatch({
                      type: "SELECTED_CITY",
                      data: val.id,
                    });
                  }}
                />
              )}
            />
          </Grid2>
        </Grid2>

        <Grid2 size={12} my={1}>
          <Divider />
        </Grid2>

        <Grid2 size={3}>
          <Typography
            fontSize={16}
            fontWeight={500}
            color={theme.palette.secondaryText.main}
          >
            Secondary Contact Details
          </Typography>
        </Grid2>

        <Grid2 container size={9} spacing={2}>
          <Grid2 size={4}>
            <Controller
              name="emergencyContact"
              control={form.control}
              render={({ field }) => (
                <GKTextField {...field} inputLabel="Contact Name" />
              )}
            />
          </Grid2>
          <Grid2 size={4}>
            <Controller
              name="emergencyContactNum"
              control={form.control}
              render={({ field }) => (
                <PhoneInput
                  {...field}
                  onSelectChange={(event: any, val: any) => {
                    form.setValue("emergencyCountryCode", val?.callingCodes);
                  }}
                  onTextChange={(event: any) => {
                    field.onChange(event.target.value);
                  }}
                  onTextValue={field.value}
                  onSelectValue={form.getValues("emergencyCountryCode")}
                  hasError={hasError}
                  setHasError={setHasError}
                />
              )}
            />
          </Grid2>
          <Grid2 size={4}>
            <Controller
              name="email"
              control={form.control}
              render={({ field }) => (
                <GKTextField {...field} inputLabel="Contact Email" />
              )}
            />
          </Grid2>
        </Grid2>

        <Grid2 size={12} textAlign={"right"} my={1}>
          <LoadingButton
            form="profile-form"
            variant="contained"
            type="submit"
            disabled={hasError}
            loading={updateProfileUpdateLoading}
          >
            Save
          </LoadingButton>
        </Grid2>

        {isModalOpen && (
          <EditLogoModal
            open={isModalOpen}
            setOpen={setIsModalOpen}
            img={selectedImage}
            onCropComplete={handleCropComplete}
          />
        )}
      </Grid2>
    </form>
  );
};

export default UserProfile;
