import { GridColDef } from "@mui/x-data-grid-pro";
import { GKClientDataGrid, Grid2 } from "components";
import DashboardLayout from "layouts/DashboardLayout";
import { extraColumnsField } from "utils/helpers";

const CommissionEntries = () => {
  const columns: GridColDef[] = [
    { ...extraColumnsField("Portfolio", "portfolio", 150, 1) },
    { ...extraColumnsField("Invoice Date", "partnerName", 150, 1) },
    { ...extraColumnsField("Invoice Number", "commissionAmount", 150, 1) },
    { ...extraColumnsField("Invoice Amount", "portfolioCount", 150, 1) },
    { ...extraColumnsField("Invoice Amount", "amount", 150, 1) },
    { ...extraColumnsField("Invoice Status", "invoiceStatus", 150, 1) },
    { ...extraColumnsField("Commission Amount", "commissionAmount", 150, 1) },
    { ...extraColumnsField("Commission Status", "status", 150, 1) },
  ];
  return (
    <DashboardLayout title="Commission Entries">
      <Grid2 container>
        <GKClientDataGrid
          name="commissionEntries"
          columns={columns}
          rows={[]}
          columnsButton={false}
        />
      </Grid2>
    </DashboardLayout>
  );
};

export default CommissionEntries;
