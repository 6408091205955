import { LoadingButton } from "@mui/lab";
import {
  GKTextField,
  Grid2,
  IconButton,
  IoEyeOffOutline,
  IoMdEye,
  Typography,
} from "components";
import { useChangePassword } from "hooks/useChangePassword";
import { Controller } from "react-hook-form";
import { ThemeContainer } from "utils/Theme";
import useDocumentTitle from "utils/useDocumentTitle";

const ChangePassword = () => {
  const { theme } = ThemeContainer();
  const {
    isSubmitting,
    showPassword,
    setShowPassword,
    showConfirmPassword,
    setShowConfirmPassword,
    form,
    onSubmit,
  } = useChangePassword();
  useDocumentTitle("Change Password");

  return (
    <form onSubmit={form.handleSubmit(onSubmit)}>
      <Grid2 container gap={2} justifyContent={"center"}>
        <Grid2
          size={12}
          position={"relative"}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          gap={"20px"}
          flexDirection={"column"}
        >
          <Typography
            fontWeight={700}
            fontSize={24}
            className="textUnderlineLogin"
            color={theme.palette.dark.main}
            sx={{
              "&.textUnderlineLogin::before": {
                width: "210px !important",
              },
            }}
          >
            Change Password !
          </Typography>
          <Typography color={theme.palette.dark.main}>
            Please fill in your details to change your password
          </Typography>
        </Grid2>
        <Grid2 container size={{ xs: 12, md: 7, sm: 8 }} gap={2}>
          <Grid2 size={12}>
            <Controller
              name="password"
              control={form.control}
              render={({ field, fieldState: { error } }) => (
                <GKTextField
                  {...field}
                  inputLabel="Password"
                  type={showPassword ? "text" : "password"}
                  autoComplete="off"
                  error={!!error}
                  helperText={error?.message}
                  slotProps={{
                    input: {
                      style: { paddingRight: 12 },
                      endAdornment: (
                        <IconButton
                          style={{ padding: 0 }}
                          onClick={() => setShowPassword(!showPassword)}
                        >
                          {!showPassword ? <IoMdEye /> : <IoEyeOffOutline />}
                        </IconButton>
                      ),
                    },
                  }}
                />
              )}
            />
          </Grid2>
          <Grid2 size={12}>
            <Controller
              name="confirmPassword"
              control={form.control}
              render={({ field }) => (
                <GKTextField
                  {...field}
                  inputLabel="Confirm Password"
                  type={showConfirmPassword ? "text" : "password"}
                  autoComplete="off"
                  slotProps={{
                    input: {
                      style: {
                        paddingRight: 12,
                      },
                      endAdornment: (
                        <IconButton
                          style={{ padding: 0 }}
                          onClick={() =>
                            setShowConfirmPassword(!showConfirmPassword)
                          }
                        >
                          {!showConfirmPassword ? (
                            <IoMdEye />
                          ) : (
                            <IoEyeOffOutline />
                          )}
                        </IconButton>
                      ),
                    },
                  }}
                />
              )}
            />
          </Grid2>
          <Grid2 size={12} textAlign={"end"}>
            <LoadingButton
              loading={isSubmitting}
              size="medium"
              variant="contained"
              type="submit"
            >
              Change Password
            </LoadingButton>
          </Grid2>
        </Grid2>
        <Grid2 size={12} textAlign={"center"}>
          <Typography color={theme.palette.dark.main}>
            GridKey is Private and Secure
          </Typography>
        </Grid2>
      </Grid2>
    </form>
  );
};

export default ChangePassword;
