import { LoadingButton } from "@mui/lab";
import {
  GKModal,
  GKSearchSelect,
  Grid2,
  TextField,
  Typography,
} from "components";
import { useFetchQuery, useMutation } from "hooks/useQueries";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { handleReturnAutocompletePlaceholder } from "utils/StringFunctions";
import { ThemeContainer } from "utils/Theme";
import { GET_COMPANY_LIST } from "utils/apiRoutes";
import axiosInstance from "utils/axiosInstance";
import { GKModalProps } from "utils/commonInterface";
import { refetchQuery } from "utils/helpers";
import { QueryKeys } from "utils/queryKey";

const SelectBenchmarkModal = ({ open, setOpen }: GKModalProps) => {
  const { theme } = ThemeContainer();
  const { id } = useParams();

  const { control, handleSubmit } = useForm();

  const { data: companyMasterData, isFetching } = useFetchQuery({
    key: [QueryKeys.COMPANY_NAMES],
    route: GET_COMPANY_LIST(),
    requestBody: {
      inst_type: JSON.stringify(["INDEX", "EQ"]),
    },
  });

  const [value, setValue] = useState({
    name: "",
    value: "",
    gridCode: "",
    companyName: "",
    instType: "",
    instSubType: "",
    isin: "",
    bse: "",
    nse: "",
    shortName: "",
  });

  const { mutate, isLoading } = useMutation({
    mutationFn: (data: any) =>
      axiosInstance.patch(`/portfolios/${id}/performance_comparision/`, data),
    onSuccess: () => {
      refetchQuery("GET_PORTFOLIO_DETAIL_BY_ID");
      refetchQuery("GET_ALL_BENCHMARK_CHART");
      setOpen(false);
    },
  });

  const onSubmit = (data: any) => {
    mutate({
      benchmark: data.benchmark?.gridCode,
    });
  };

  return (
    <GKModal
      open={open}
      setOpen={setOpen}
      modalTitle="Change Benchmark"
      footer={
        <LoadingButton
          loading={isLoading}
          variant="contained"
          onClick={handleSubmit(onSubmit)}
        >
          Save
        </LoadingButton>
      }
    >
      <Grid2 container>
        <Grid2 size={12}>
          <Controller
            name="benchmark"
            control={control}
            rules={{ required: "Please select a company" }}
            render={({ field, fieldState: { error } }) => (
              <GKSearchSelect
                {...field}
                inputLabel="Select Company"
                disableClearable
                requiredField
                renderOption={(props, option) => (
                  <li
                    {...props}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: 4,
                    }}
                  >
                    <Typography
                      color={theme.palette.light.main}
                      fontSize={8}
                      style={{
                        background:
                          option?.instType === "EQ"
                            ? theme.palette.secondary.main
                            : theme.palette.warning.main,
                        padding: "2px 4px",
                        borderRadius: 4,
                      }}
                    >
                      {option?.instType}
                    </Typography>
                    <Typography whiteSpace={"nowrap"}>
                      {option?.name}
                    </Typography>
                  </li>
                )}
                options={
                  companyMasterData?.data?.data?.map((item: any) => ({
                    name: item?.companyName,
                    value: item?.isin,
                    ...item,
                  })) || []
                }
                getOptionLabel={(option) => {
                  return option?.name || option;
                }}
                onChange={(ev, val) => {
                  field.onChange(val);
                  setValue(val);
                }}
                loading={isFetching}
                value={value?.name || ""}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder={handleReturnAutocompletePlaceholder({
                      placeholder: "Select Company",
                      value: field.value,
                    })}
                    error={!!error}
                    helperText={error ? error.message : ""}
                  />
                )}
              />
            )}
          />
        </Grid2>
      </Grid2>
    </GKModal>
  );
};

export default SelectBenchmarkModal;
