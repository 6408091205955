import {
  CgMathPlus,
  Divider,
  GKButton,
  GKTextField,
  Grid2,
  IconButton,
  MdClose,
} from "components";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";

const DematDetails = () => {
  const form = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: "clientCreation.dematAccounts",
  });

  return (
    <Grid2 container spacing={2}>
      <Grid2 size={12}>
        {fields.map((demat: any, index: number) => (
          <Grid2
            container
            size={12}
            spacing={2}
            my={2}
            key={demat.id}
            sx={{
              p: {
                xs: 2,
                md: 0,
              },
              border: {
                xs: "1px solid #E3E8EF",
                md: "none",
              },
              borderRadius: {
                xs: 2,
                md: 0,
              },
            }}
          >
            <Grid2 size={{ md: 2.8, xs: 12 }}>
              <Controller
                name={`clientCreation.dematAccounts.${index}.brokerName`}
                control={form.control}
                render={({ field }) => (
                  <GKTextField {...field} inputLabel="Demat Broker Name" />
                )}
              />
            </Grid2>
            <Grid2 size={{ md: 2.8, xs: 12 }}>
              <Controller
                name={`clientCreation.dematAccounts.${index}.dematId`}
                control={form.control}
                render={({ field }) => (
                  <GKTextField {...field} inputLabel="Demat Id" />
                )}
              />
            </Grid2>
            <Grid2 size={{ md: 2.8, xs: 12 }}>
              <Controller
                name={`clientCreation.dematAccounts.${index}.uniqClientCode`}
                control={form.control}
                render={({ field }) => (
                  <GKTextField
                    {...field}
                    inputLabel="UCC"
                    placeholder="Like UCC of Demat A/c"
                  />
                )}
              />
            </Grid2>
            <Grid2 size={{ md: 2.8, xs: 12 }}>
              <Controller
                name={`clientCreation.dematAccounts.${index}.depositary`}
                control={form.control}
                render={({ field }) => (
                  <GKTextField
                    {...field}
                    inputLabel="Depository"
                    placeholder="Like NSDL, CDSL"
                  />
                )}
              />
            </Grid2>
            <Grid2
              size={{ md: 0.5, xs: 12 }}
              textAlign="center"
              mt={{ md: 3, xs: 0 }}
            >
              <IconButton
                color="error"
                disabled={fields.length === 1}
                onClick={() => remove(index)}
              >
                <MdClose />
              </IconButton>
            </Grid2>
          </Grid2>
        ))}
      </Grid2>
      <Grid2 size={12}>
        <Divider>
          <GKButton
            variant="outlined"
            color="dark"
            size="small"
            startIcon={<CgMathPlus size={10} />}
            onClick={() =>
              append({
                brokerName: "",
                dematId: "",
                uniqClientCode: "",
                depositary: "",
              })
            }
          >
            Add Demat
          </GKButton>
        </Divider>
      </Grid2>
    </Grid2>
  );
};

export default DematDetails;
