const useEnvironmentCheck = () => {
  const getLocalEnvironment = ["stage.gridkey.in", "localhost"].includes(
    window.location.hostname,
  );

  const getLiveEnvironment = ["gridkey.in"].includes(window.location.hostname);

  return { getLocalEnvironment, getLiveEnvironment };
};

export default useEnvironmentCheck;
