import { LoadingButton } from "@mui/lab";
import { useAppContext } from "AppContext";
import {
  CgMathPlus,
  ConfirmDeleteModal,
  Divider,
  FiTrash2,
  GKButton,
  GKDatePicker,
  GKSearchSelect,
  GKTextField,
  Grid2,
  IconButton,
  Show,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "components";
import dayjs from "dayjs";
import { useFetchQuery, useMutation } from "hooks/useQueries";
import DashboardLayout from "layouts/DashboardLayout";
import { useState } from "react";
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
} from "react-hook-form";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { INCOME_EXPENSE_LIST_BY_TRANSACTION_ID } from "utils/apiRoutes";
import axiosInstance from "utils/axiosInstance";
import { EXPENSE, INCOME, IncomeExpenseTransactionType } from "utils/constants";
import { handleReturnAutocompletePlaceholder } from "utils/StringFunctions";
import useDocumentTitle from "utils/useDocumentTitle";
import ExtraChargesModal from "./ExtraChargesModal";

const padding = { padding: 16 };
const bodyCellStyle = (minWidth: string) => {
  return { border: "none", minWidth: minWidth };
};

const IncomeExpenseForm = () => {
  const navigate = useNavigate();
  const { portfolioId, id } = useParams();
  useDocumentTitle(id ? "Update Income/Expense" : "Add Income/Expense");

  const [openChargeModal, setOpenChargeModal] = useState(false);
  const [chargesIndex, setChargesIndex] = useState<number>(0);
  const [confirmationModal, setConfirmationModal] = useState(false);

  const {
    state: { portfolios },
  } = useAppContext();

  const { data, isLoading: getIncomeExpenseLoading } = useFetchQuery({
    key: ["GET_INCOME_EXPENSE_BY_ID"],
    route: INCOME_EXPENSE_LIST_BY_TRANSACTION_ID(id),
    enabled: !!id,
  });

  const form = useForm({
    defaultValues: {
      transactionType: "INCOME",
      portfolio: +portfolioId || "",
      date: "",
      transactions: [
        {
          billAmount: "",
          subTransactionType: "",
          remarks: "",
          tax: 0,
          totalCharges: {
            other_charges: 0,
            stt: 0,
            gst: 0,
            stamp_charges: 0,
            transaction_charges: 0,
            sebi_turnover_fees: 0,
          },
        },
      ],
    },
    values: {
      transactionType: data?.data?.transactionType || "INCOME",
      portfolio: data?.data?.portfolio || +portfolioId || "",
      date: data?.data?.date || "",
      transactions: [
        {
          billAmount: data?.data?.billAmount || "",
          subTransactionType: data?.data?.subTransactionType || "",
          remarks: data?.data?.remarks || "",
          tax: data?.data?.tax || 0,
          totalCharges: {
            other_charges: data?.data?.totalCharges?.otherCharges || 0,
            stt: data?.data?.totalCharges?.stt || 0,
            gst: data?.data?.totalCharges?.gst || 0,
            stamp_charges: data?.data?.totalCharges?.stampCharges || 0,
            transaction_charges:
              data?.data?.totalCharges?.transactionCharges || 0,
            sebi_turnover_fees: data?.data?.totalCharges?.sebiTurnoverFees || 0,
          },
        },
      ],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: "transactions",
  });

  const { mutate: updateIncomeExpense, isLoading: updateIncomeExpenseLoading } =
    useMutation({
      mutationFn: (values: any) =>
        axiosInstance.patch(`/income_expense/${id}/`, values),
      onSuccess: () => {
        navigate(-1);
      },
    });

  const { mutate: createIncomeExpense, isLoading } = useMutation({
    mutationFn: (data: any) => axiosInstance.post("/income_expense/", data),
    onSuccess: () => {
      navigate(-1);
    },
  });

  const { mutate: deleteIncomeExpense, isLoading: deleteIncomeExpenseLoading } =
    useMutation({
      mutationFn: ({ id }: { id: any }) =>
        axiosInstance.delete(`/income_expense/${id}/`),
      onSuccess: () => {
        navigate(-1);
      },
    });

  const getTransactionType =
    form.getValues("transactionType") === "INCOME"
      ? "Select Income Type"
      : "Select Expense Type";

  return (
    <DashboardLayout
      title="Income/Expense"
      loading={(!!id && getIncomeExpenseLoading) || isLoading}
    >
      <FormProvider {...form}>
        <form
          id="income-expense-form"
          onSubmit={form.handleSubmit((values: any) => {
            if (dayjs(values?.date).year() < 1980) {
              toast.error(
                "You are not allowed to add transaction for this date.",
              );
            } else {
              id
                ? updateIncomeExpense({
                    transactionType: values?.transactionType,
                    portfolio: values?.portfolio,
                    date: values?.date,
                    billAmount: values?.transactions[0]?.billAmount,
                    subTransactionType:
                      values?.transactions[0]?.subTransactionType,
                    remarks: values?.transactions[0]?.remarks,
                    tax: values?.transactions[0]?.tax,
                    totalCharges: values?.transactions[0]?.totalCharges,
                  })
                : createIncomeExpense(values);
            }
          })}
        >
          <Grid2 container spacing={2} mt={1}>
            <Grid2 size={4}>
              <Controller
                name="transactionType"
                control={form.control}
                rules={{
                  required: {
                    value: true,
                    message: "Please select type",
                  },
                }}
                render={({ field, fieldState: { error } }) => {
                  return (
                    <GKSearchSelect
                      {...field}
                      disableClearable
                      inputLabel="Select Type"
                      requiredField
                      options={IncomeExpenseTransactionType || []}
                      value={
                        IncomeExpenseTransactionType?.find((data) =>
                          data?.value?.includes(field.value),
                        )?.name
                      }
                      onChange={(e, val) => {
                        field.onChange(val?.value);
                        form.reset({
                          transactionType: val?.value,
                          portfolio: +portfolioId
                            ? form.getValues("portfolio")
                            : "",
                          date: "",
                          transactions: [
                            {
                              billAmount: "",
                              subTransactionType: "",
                              remarks: "",
                              tax: 0,
                              totalCharges: {
                                other_charges: 0,
                                stt: 0,
                                gst: 0,
                                stamp_charges: 0,
                                transaction_charges: 0,
                                sebi_turnover_fees: 0,
                              },
                            },
                          ],
                        });
                      }}
                      getOptionLabel={(option) => option.name || option}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder={handleReturnAutocompletePlaceholder({
                            placeholder: "Select Type",
                            value: field.value,
                          })}
                          error={!!error}
                          helperText={error?.message}
                        />
                      )}
                    />
                  );
                }}
              />
            </Grid2>
            <Grid2 size={4}>
              <Controller
                name="portfolio"
                control={form.control}
                rules={{
                  required: {
                    value: true,
                    message: "Please select portfolio",
                  },
                }}
                render={({ field, formState: { errors } }) => (
                  <GKSearchSelect
                    {...field}
                    disableClearable={false}
                    disabled={!!portfolioId}
                    inputLabel="Select Portfolio"
                    requiredField
                    renderOption={(props, option) => {
                      return (
                        <li
                          {...props}
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                          }}
                        >
                          <Typography fontWeight={600}>
                            {option.clientName}
                          </Typography>
                          <Typography>{option.name}</Typography>
                        </li>
                      );
                    }}
                    options={portfolios || []}
                    value={
                      portfolios.find(
                        (dataItem) => dataItem.value === field.value,
                      )?.name || ""
                    }
                    onChange={(e, val) => field.onChange(val?.value || null)}
                    getOptionLabel={(option) => option.name || option}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="portfolio"
                        placeholder={handleReturnAutocompletePlaceholder({
                          placeholder: "Select Portfolio",
                          value: field.value,
                        })}
                        error={Boolean(errors.portfolio)}
                        helperText={errors.portfolio?.message as string}
                      />
                    )}
                  />
                )}
              />
            </Grid2>
            <Grid2 size={4}>
              <Controller
                name="date"
                control={form.control}
                rules={{
                  required: {
                    value: true,
                    message: "Please select transaction date",
                  },
                }}
                render={({ field, formState: { errors } }) => (
                  <GKDatePicker
                    {...field}
                    requiredField
                    inputLabel="Date"
                    value={dayjs(field.value)}
                    onChange={(value) => {
                      field.onChange(dayjs(value).format("YYYY-MM-DD"));
                    }}
                    disableFuture
                    slotProps={{
                      textField: {
                        error: Boolean(errors.date),
                        helperText: errors.date?.message as string,
                      },
                    }}
                  />
                )}
              />
            </Grid2>
            <Grid2 size={12}>
              <Divider />
            </Grid2>
            <Grid2
              size={12}
              height={`calc(100vh - 260px)`}
              overflow={`hidden auto`}
              pr={"2px"}
            >
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell style={padding}>
                        {getTransactionType}
                      </TableCell>
                      <TableCell style={padding}>Amount</TableCell>
                      <TableCell style={padding}>TDS </TableCell>
                      <TableCell style={padding}>Remarks</TableCell>
                      <TableCell style={padding} align="right">
                        Actions
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {fields?.map((row, index) => {
                      const watchTransactionSubType = form.watch(
                        `transactions.${index}.subTransactionType`,
                      );

                      return (
                        <TableRow key={row.id}>
                          <TableCell style={bodyCellStyle("200px")}>
                            <Controller
                              name={`transactions.${index}.subTransactionType`}
                              control={form.control}
                              render={({ field }) => {
                                const findTransactionType =
                                  form.getValues("transactionType") === "INCOME"
                                    ? INCOME
                                    : EXPENSE;

                                return (
                                  <GKSearchSelect
                                    {...field}
                                    disableClearable={false}
                                    inputLabel={""}
                                    options={findTransactionType}
                                    getOptionLabel={(option) =>
                                      option?.name || option
                                    }
                                    value={
                                      findTransactionType.find(
                                        (dataItem) =>
                                          dataItem.value === field.value,
                                      )?.name || null
                                    }
                                    onChange={(e, val) => {
                                      field.onChange(val?.value);
                                      form.setValue(
                                        `transactions.${index}.tax`,
                                        0,
                                      );
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        placeholder={handleReturnAutocompletePlaceholder(
                                          {
                                            placeholder: `Select ${getTransactionType}`,
                                            value: field.value,
                                          },
                                        )}
                                      />
                                    )}
                                  />
                                );
                              }}
                            />
                          </TableCell>
                          <TableCell style={bodyCellStyle("120px")}>
                            <Controller
                              name={`transactions.${index}.billAmount`}
                              control={form.control}
                              rules={{
                                required: {
                                  value: true,
                                  message: "Please enter amount",
                                },
                                min: {
                                  value: 0.00000000001,
                                  message: "Amount Should be greater than 1",
                                },
                              }}
                              render={({ field, fieldState: { error } }) => (
                                <GKTextField
                                  {...field}
                                  type="number"
                                  disabled={
                                    form.getValues(
                                      `transactions.${index}.subTransactionType`,
                                    ) === "total_charges"
                                  }
                                  inputLabel={""}
                                  requiredField
                                  error={!!error}
                                  helperText={error?.message}
                                  onChange={(e: any) =>
                                    field.onChange(e.target.valueAsNumber)
                                  }
                                />
                              )}
                            />
                            {watchTransactionSubType === "total_charges" && (
                              <Tooltip title="Add other charges">
                                <IconButton
                                  style={{ marginTop: 5 }}
                                  color="primary"
                                  onClick={() => {
                                    setChargesIndex(index);
                                    setOpenChargeModal(true);
                                  }}
                                >
                                  <CgMathPlus />
                                </IconButton>
                              </Tooltip>
                            )}
                          </TableCell>
                          <TableCell style={bodyCellStyle("120px")}>
                            {watchTransactionSubType === "dividend" ? (
                              <Controller
                                name={`transactions.${index}.tax`}
                                control={form.control}
                                rules={{
                                  required: {
                                    value: true,
                                    message: "Please enter tax amount",
                                  },
                                }}
                                render={({ field }) => (
                                  <GKTextField
                                    {...field}
                                    type="number"
                                    inputLabel=""
                                    onChange={(e: any) =>
                                      field.onChange(e.target.valueAsNumber)
                                    }
                                  />
                                )}
                              />
                            ) : (
                              <Typography>-</Typography>
                            )}
                          </TableCell>
                          <TableCell style={bodyCellStyle("120px")}>
                            <Controller
                              name={`transactions.${index}.remarks`}
                              control={form.control}
                              render={({ field }) => <GKTextField {...field} />}
                            />
                          </TableCell>
                          <TableCell align="right" style={{ border: "none" }}>
                            <IconButton
                              className="outline"
                              color="error"
                              disabled={fields.length === 1}
                              onClick={() => remove(index)}
                            >
                              <FiTrash2 size={20} />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                    {!id && (
                      <TableRow>
                        <TableCell colSpan={8} sx={{ border: "none" }}>
                          <GKButton
                            variant="outlined"
                            color="dark"
                            startIcon={<CgMathPlus size={16} />}
                            onClick={() =>
                              append({
                                billAmount: "",
                                subTransactionType: "",
                                remarks: "",
                                tax: 0,
                                totalCharges: {
                                  other_charges: 0,
                                  stt: 0,
                                  gst: 0,
                                  stamp_charges: 0,
                                  transaction_charges: 0,
                                  sebi_turnover_fees: 0,
                                },
                              })
                            }
                          >
                            {form.getValues("transactionType") === "INCOME"
                              ? "Add Income"
                              : "Add Expense"}
                          </GKButton>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid2>
            <Grid2 size={12}>
              <Divider />
            </Grid2>
            <Grid2
              size={12}
              display={"flex"}
              justifyContent={"flex-end"}
              alignItems={"center"}
              gap={1}
            >
              {id && (
                <LoadingButton
                  loading={deleteIncomeExpenseLoading}
                  color="error"
                  variant="outlined"
                  onClick={() => setConfirmationModal(true)}
                >
                  Delete
                </LoadingButton>
              )}
              <LoadingButton
                variant="contained"
                size="medium"
                type="submit"
                form="income-expense-form"
                loading={isLoading || updateIncomeExpenseLoading}
              >
                Save
              </LoadingButton>
            </Grid2>

            <Show.When isTrue={openChargeModal}>
              <ExtraChargesModal
                open={openChargeModal}
                setOpen={setOpenChargeModal}
                index={chargesIndex}
              />
            </Show.When>

            <Show.When isTrue={confirmationModal}>
              <ConfirmDeleteModal
                open={confirmationModal}
                setOpen={setConfirmationModal}
                onDelete={() => deleteIncomeExpense({ id: id })}
                title={"Income/Expense"}
                isDelete
              />
            </Show.When>
          </Grid2>
        </form>
      </FormProvider>
    </DashboardLayout>
  );
};

export default IncomeExpenseForm;
