import {
  AnimationComponent,
  Divider,
  Grid2,
  Show,
  Tab,
  Tabs,
} from "components";
import { useState } from "react";
import { ThemeContainer } from "utils/Theme";
import SubscriptionFlow from "./SubscriptionFlow";

const OnBoardingFlowTab = () => {
  const { theme } = ThemeContainer();

  const [tab, setTab] = useState(0);

  const tabs = [
    {
      label: "Advisor initiated Advisory Flow",
      component: <SubscriptionFlow type="advisor_initiated_advisory_flow" />,
    },
    {
      label: "Client initiated Advisory Flow",
      component: <SubscriptionFlow type="client_initiated_advisory_flow" />,
    },
  ];

  return (
    <Grid2 container spacing={2}>
      <Grid2 size={12} display={"flex"} flexDirection={"column"}>
        <Tabs
          sx={{
            "& .MuiTabs-flexContainer": {
              backgroundColor: theme.palette.light.main,
            },
          }}
          onChange={(e, val) => setTab(val)}
          value={tab}
        >
          {tabs.map((tab, index) => (
            <Tab key={index} label={tab.label} disableRipple />
          ))}
        </Tabs>
        <Divider />
      </Grid2>
      <Grid2 size={12} mt={1}>
        {tabs.map((tabItem, index) => (
          <Show.When key={index} isTrue={tab === index}>
            <AnimationComponent>{tabItem.component}</AnimationComponent>
          </Show.When>
        ))}
      </Grid2>
    </Grid2>
  );
};

export default OnBoardingFlowTab;
