/* eslint-disable no-prototype-builtins */
import { GridColDef } from "@mui/x-data-grid-pro";
import { useAppContext } from "AppContext";
import {
  BsExclamationOctagon,
  BsInfoCircle,
  CgMathPlus,
  CircularProgress,
  EditIcon,
  GKButton,
  GKDataGrid,
  Grid2,
  IconButton,
  Tooltip,
  Typography,
} from "components";
import dayjs from "dayjs";
import useGenerateQueryParams from "hooks/useGenerateQueryParams";
import { useFetchQuery } from "hooks/useQueries";
import {
  GetPortfolioListInterface,
  PortfolioDetailsInterface,
} from "interfaces/Portfolio";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { handleRemoveUnderscore } from "utils";
import { moneyCommaSeparator } from "utils/MathFunction";
import { handleReturnTripleDots } from "utils/StringFunctions";
import { ThemeContainer } from "utils/Theme";
import { GET_ALL_PORTFOLIO_LIST, GET_PORTFOLIO_BY_ID } from "utils/apiRoutes";
import { handleReturnPositiveNegative } from "utils/colorHelper";
import { UserRoleTypes } from "utils/common";
import { extraColumnsField, matchDate } from "utils/helpers";
import useDocumentTitle from "utils/useDocumentTitle";
import AddPortfolioModal from "./AddPortfolioModal";
import PortfolioProfileEdit from "./ProfilePages";

interface Props {
  path?: string;
  showAddButton?: boolean;
  csvName?: string;
  rowData?: any[];
}

const PortfolioList = ({
  path,
  csvName,
  rowData,
  showAddButton = true,
}: Props) => {
  const navigate = useNavigate();

  useDocumentTitle("Portfolio");

  const { theme } = ThemeContainer();

  const {
    state: { userData },
  } = useAppContext();

  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);

  const [openPortfolioModal, setOpenPortfolioModal] = useState(false);

  const [portfolioData, setPortfolioData] =
    useState<PortfolioDetailsInterface>(null);

  const [editPortfolioId, setEditPortfolioId] = useState<string | number>();

  const requestBody = useGenerateQueryParams("portfolio");

  const { data, isFetching } = useFetchQuery<GetPortfolioListInterface>({
    key: ["GET_ALL_PORTFOLIOS", requestBody],
    route: path || GET_ALL_PORTFOLIO_LIST,
    requestBody,
  });

  const { isFetching: isLoading } = useFetchQuery({
    key: ["PORTFOLIO_UPDATE_DATA_BY_ID"],
    route: GET_PORTFOLIO_BY_ID(editPortfolioId),
    enabled: Boolean(editPortfolioId),
    success: (data: any) => {
      setPortfolioData(data?.data);
      setOpenPortfolioModal(true);
    },
  });

  const columns: GridColDef[] = [
    {
      ...extraColumnsField("Portfolio", "clientName", 250, 1),
      renderCell: (params) => {
        const portfolioNameCode = `${params?.row?.name} - ${params?.row?.portfolioId}`;

        return (
          <Grid2 container justifyContent={"flex-start"} size={12}>
            <Grid2 size={11}>
              <Tooltip title={portfolioNameCode}>
                <Grid2 size={11}>
                  <Typography fontWeight={700}>
                    {handleReturnTripleDots({
                      str: params?.row?.clientName,
                      length: 25,
                    })}
                  </Typography>
                  <Typography fontSize={12}>
                    {handleReturnTripleDots({ str: portfolioNameCode })}
                  </Typography>
                </Grid2>
              </Tooltip>{" "}
            </Grid2>
            <Grid2 size={1} textAlign={"right"}>
              {!params?.row?.isValid && (
                <Tooltip title="There might be some discrepancies in the holdings of this portfolio.">
                  <IconButton
                    color="error"
                    sx={{
                      background: "transparent",
                      "&:hover": {
                        background: "transparent",
                        borderColor: "transparent",
                        color: theme.palette.error.main,
                      },
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    <BsExclamationOctagon size={14} />
                  </IconButton>
                </Tooltip>
              )}
            </Grid2>
          </Grid2>
        );
      },
    },
    {
      ...extraColumnsField("Net Contribution", "netContribution", 150, 1),
      type: "number",
      valueGetter: (param) => param?.row?.netContribution,
      valueFormatter: (params) => moneyCommaSeparator(params.value),
    },
    {
      ...extraColumnsField("Invested Value", "investedValue", 150, 1),
      type: "number",
      valueGetter: (param) => param?.row?.investedValue,
      valueFormatter: (params) => moneyCommaSeparator(params.value),
    },
    {
      ...extraColumnsField("Current Value", "currentValue", 150, 1),
      type: "number",
      valueGetter: (param) => param?.row?.currentValue,
      valueFormatter: (params) => moneyCommaSeparator(params.value),
    },
    {
      ...extraColumnsField("Unrealised Gain", "unrealisedGain", 150, 1),
      type: "number",
      valueGetter: (params) => params.row.unrealisedGain,
      valueFormatter: (params) => moneyCommaSeparator(params.value),
      renderCell: (value) => {
        const { unrealisedGain } = value?.row;
        return (
          <Typography color={handleReturnPositiveNegative(unrealisedGain)}>
            {moneyCommaSeparator(unrealisedGain)}
          </Typography>
        );
      },
    },
    {
      ...extraColumnsField("Unrealised Gain %", "absoluteReturn", 150, 1),
      type: "number",
      valueGetter: (params) => params.row.absoluteReturn,
      valueFormatter: (params) => moneyCommaSeparator(params.value),
      renderCell: (value) => {
        return (
          <Typography
            color={handleReturnPositiveNegative(value?.row?.absoluteReturn)}
          >
            {moneyCommaSeparator(value?.row?.absoluteReturn)}%
          </Typography>
        );
      },
    },
    {
      ...extraColumnsField("Today's Gain", "todaysGain", 150, 1),
      type: "number",
      valueGetter: (params) => params.row.todaysGain,
      valueFormatter: (params) => moneyCommaSeparator(params.value),
      renderCell: (value) => {
        const { todaysGain } = value?.row;
        return (
          <Typography color={handleReturnPositiveNegative(todaysGain)}>
            {moneyCommaSeparator(todaysGain)}
          </Typography>
        );
      },
    },
    {
      ...extraColumnsField("Today's Gain %", "todaysGainPercentage", 150, 1),
      type: "number",
      valueGetter: (params) => params.row.todaysGainPercentage,
      valueFormatter: (params) => moneyCommaSeparator(params.value),
      renderCell: (value) => {
        return (
          <Typography
            color={handleReturnPositiveNegative(
              value?.row?.todaysGainPercentage,
            )}
          >
            {moneyCommaSeparator(value?.row?.todaysGainPercentage)}%
          </Typography>
        );
      },
    },
    {
      ...extraColumnsField(
        "Returns",
        userData?.showTwrr ? "twrr" : "xirr",
        100,
        1,
      ),
      type: "number",
      renderHeader: () => {
        return (
          <Tooltip title={`As of ${dayjs().format("DD/MM/YY ")} 12:01 am`}>
            <Typography
              fontSize={12}
              color={theme.palette.grey[600]}
              display={"flex"}
              alignItems={"center"}
            >
              Returns&nbsp;
              <BsInfoCircle />
            </Typography>
          </Tooltip>
        );
      },
      valueGetter: (param) =>
        userData?.showTwrr ? param?.row?.twrr : param?.row?.xirr,
      valueFormatter: (params) => moneyCommaSeparator(params.value),
      renderCell: (param) => (
        <Typography>
          {userData?.showTwrr
            ? param?.row?.twrr?.toFixed(2)
            : param?.row?.xirr?.toFixed(2) || "0"}
        </Typography>
      ),
    },
    {
      ...extraColumnsField("Equity %", "equityPercentage", 100, 1),
      valueGetter: (params) =>
        moneyCommaSeparator(params?.row?.equityPercentage),
    },
    {
      ...extraColumnsField("Cash %", "cash", 100, 1),
      valueGetter: (params) =>
        moneyCommaSeparator(params?.row?.ledgerPercentage),
    },
    {
      ...extraColumnsField("Committed Value", "commitedValue", 130, 1),
      type: "number",
      valueGetter: (param) => param?.row?.commitedValue,
      valueFormatter: (params) => moneyCommaSeparator(params.value),
    },
    {
      ...extraColumnsField("Inception Date", "inceptionDate", 150, 1),
      valueFormatter: (params) => matchDate(params.value),
    },
    {
      ...extraColumnsField(
        "Last Transaction Date",
        "lastTransactionDate",
        200,
        1,
      ),
      valueFormatter: (params) => matchDate(params.value),
    },
    {
      ...extraColumnsField("Last Uploaded Date", "lastUploadDate", 200, 1),
      valueFormatter: (params) => matchDate(params?.value),
    },
    {
      ...extraColumnsField("Equity Basket", "equityBasket", 200, 1),
      valueGetter: (row) => row?.row?.equityBasket || "-",
    },
    {
      ...extraColumnsField("Last Order Date", "lastOrderDate", 200, 1),
      valueFormatter: (params) => matchDate(params?.value),
      valueGetter: (row) => row?.row?.lastOrderDate || "-",
    },
    {
      ...extraColumnsField("Last Order Version", "lastOrderVersion", 200, 1),
      valueGetter: (row) => row?.row?.lastOrderVersion || "-",
    },
    {
      ...extraColumnsField("Last Order Template", "lastOrderTemplate", 200, 1),
      valueFormatter: (params) => handleRemoveUnderscore(params?.value),
      valueGetter: (row) => row?.row?.lastOrderTemplate || "-",
    },
    {
      ...extraColumnsField("Last Order Type", "lastOrderType", 200, 1),
      renderCell: (row) => {
        return (
          <Typography textTransform={"capitalize"}>
            {row?.row?.lastOrderType || "-"}
          </Typography>
        );
      },
    },
    {
      ...extraColumnsField("Execution Sheet", "lastExecutionSheet", 200, 1),
      valueFormatter: (params) => handleRemoveUnderscore(params?.value),
      valueGetter: (row) => row?.row?.lastExecutionSheet || "-",
    },

    {
      ...extraColumnsField("Actions", "action", 80, 1),
      headerAlign: "right",
      align: "right",
      renderCell: (item) => {
        return isLoading && editPortfolioId === item.row.id ? (
          <CircularProgress size={18} />
        ) : (
          <IconButton
            className="outline"
            onClick={(event) => {
              event.stopPropagation();
              setEditPortfolioId(item.row.id);
            }}
          >
            <EditIcon size={20} />
          </IconButton>
        );
      },
    },
  ];

  return (
    <>
      <GKDataGrid
        loading={isFetching}
        columns={columns?.filter((dataItem) =>
          userData?.userRole !== UserRoleTypes.client
            ? ![
                "lastOrderDate",
                "lastOrderVersion",
                "lastOrderTemplate",
                "lastOrderType",
                "lastExecutionSheet",
              ].includes(dataItem?.field)
            : dataItem.field,
        )}
        rows={rowData || data?.data?.data || []}
        maxHeight={"calc(100vh - 321px)"}
        onRowClick={(row) => {
          navigate(`/app/holdings/portfolio/${row?.id}`);
        }}
        totalCount={data?.data?.totalCount || 0}
        pinnedColumns={{
          left: ["hierarchy", "clientName"],
        }}
        zeroHolding
        name="portfolio"
        headerComponent={
          showAddButton &&
          userData?.userRole !== UserRoleTypes.client && (
            <GKButton
              className="add-new-portfolio"
              onClick={() => {
                setIsCreateModalOpen(true);
              }}
              variant="contained"
              startIcon={<CgMathPlus size={14} />}
            >
              Create Portfolio
            </GKButton>
          )
        }
        initialState={{
          columns: {
            columnVisibilityModel: {
              lastOrderDate: true,
              lastOrderVersion: false,
              lastOrderTemplate: false,
              lastOrderType: false,
              lastExecutionSheet: false,
            },
          },
        }}
        tableName="portfolio_list"
        exportFile={{
          path: path || GET_ALL_PORTFOLIO_LIST,
          exportName: csvName || "portfolios",
        }}
      />

      {openPortfolioModal && (
        <PortfolioProfileEdit
          open={openPortfolioModal}
          setOpen={setOpenPortfolioModal}
          portfolioData={portfolioData}
          portfolioId={editPortfolioId}
          setPortfolioId={setEditPortfolioId}
        />
      )}

      {isCreateModalOpen && (
        <AddPortfolioModal
          setOpen={setIsCreateModalOpen}
          open={isCreateModalOpen}
        />
      )}
    </>
  );
};

export default PortfolioList;
