import { grey } from "@mui/material/colors";
import { Box, Button, Grid2, StatusField, Typography } from "components";
import { AdvisorOnboardFormInterface } from "interfaces/OnboardingInterface";
import { useFormContext } from "react-hook-form";
import { BiHome } from "react-icons/bi";
import { LuPlus } from "react-icons/lu";
import { TbRosetteDiscountCheck } from "react-icons/tb";
import { numberToCurrency } from "utils";
import { handleCalculateFee } from "utils/helpers";
import { ThemeContainer } from "utils/Theme";

const AdvisorOnboardingCompleted = ({ setOpen }: { setOpen: any }) => {
  const form = useFormContext<AdvisorOnboardFormInterface>();

  const { theme } = ThemeContainer();
  return (
    <Grid2
      spacing={2}
      container
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
      sx={{
        width: {
          xs: "80%",
          md: "70%",
        },
      }}
      m={"auto"}
    >
      <Grid2 size={12} textAlign={"center"}>
        <TbRosetteDiscountCheck color={theme.palette.success.main} size={30} />
        <Typography fontWeight={600} fontSize={18}>
          You have successfully onboarded client
        </Typography>
      </Grid2>
      <Grid2 size={12}>
        <Box
          sx={{
            p: 2,
            background: "#F8FAFC",
            borderRadius: 2,
          }}
        >
          <Grid2 container mb={1}>
            <Grid2 size={12}>
              <Typography fontWeight={600} fontSize={14}>
                {form.getValues("personalDetails.name")}
                <span
                  style={{
                    color: grey[400],
                    fontSize: 12,
                  }}
                >
                  {" "}
                  ({form.getValues("clientCode")})
                </span>
              </Typography>
              <Typography fontWeight={600} color={grey[400]} fontSize={12}>
                Phone
                <span
                  style={{
                    color: grey[600],
                    fontSize: 14,
                  }}
                >
                  {" "}
                  {form.getValues("phone")}
                </span>
              </Typography>
            </Grid2>
            <Grid2
              size={12}
              bgcolor={theme.palette.light.main}
              display={"grid"}
              gridTemplateColumns={"auto auto auto"}
              p={2}
            >
              <Box>
                <Typography
                  color={theme.palette.secondaryText.main}
                  fontSize={12}
                >
                  Basket
                </Typography>
                <Typography fontWeight={600}>
                  {form.getValues("basketObject.name") || "-"}
                </Typography>
              </Box>
              <Box>
                <Typography
                  color={theme.palette.secondaryText.main}
                  fontSize={12}
                >
                  Fee Plan
                </Typography>
                <Typography fontWeight={600}>
                  {form.getValues("selectedFeePlan.name") || "-"}
                </Typography>
              </Box>
              <Box>
                <Typography
                  color={theme.palette.secondaryText.main}
                  fontSize={12}
                >
                  Payment Amount{" "}
                </Typography>
                <Box display={"flex"} alignItems={"center"} gap={0.5}>
                  <Typography fontWeight={600}>
                    {numberToCurrency(
                      form.getValues("skipAdvanceFee")
                        ? 0
                        : handleCalculateFee({
                            feePercent: Number(
                              form.getValues(
                                "advanceFeePlan.feePlan.managementFee.fee",
                              ),
                            ),
                            frequency: form.getValues(
                              "advanceFeePlan.frequency",
                            ),
                            total:
                              Number(
                                form.getValues("planDataObject.holdingsValue"),
                              ) +
                              Number(form.getValues("planDataObject.aumCash")),
                          }) * 1.18 || 0,
                    )}
                  </Typography>
                  <Typography fontSize={12} color="#94A3B8">
                    (inclusive of GST)
                  </Typography>
                </Box>
              </Box>
            </Grid2>
            <Grid2
              size={12}
              bgcolor={theme.palette.light.main}
              display={"grid"}
              gridTemplateColumns={"auto auto auto"}
              p={2}
              borderTop={`1px solid ${theme.palette.border.main}`}
            >
              <Box>
                <Typography
                  color={theme.palette.secondaryText.main}
                  fontSize={12}
                >
                  KYC
                </Typography>
                <StatusField
                  statusString={form.getValues("kyc.kycStatus") || "Pending"}
                />
              </Box>
              <Box>
                <Typography
                  color={theme.palette.secondaryText.main}
                  fontSize={12}
                >
                  Risk Profile
                </Typography>
                <StatusField
                  statusString={
                    form.getValues("riskProfile.riskStatus") || "Pending"
                  }
                />
              </Box>
              <Box>
                <Typography
                  color={theme.palette.secondaryText.main}
                  fontSize={12}
                >
                  Agreement
                </Typography>
                <StatusField
                  statusString={
                    form.getValues("agreement.agreementStatus.status") ||
                    "Pending"
                  }
                />
              </Box>
            </Grid2>
          </Grid2>
        </Box>
      </Grid2>
      <Grid2
        size={12}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        gap={1}
      >
        <Button
          variant="outlined"
          startIcon={<BiHome />}
          onClick={() => {
            setOpen(false);
          }}
        >
          Back to Home
        </Button>
        <Button
          variant="contained"
          startIcon={<LuPlus />}
          onClick={() => {
            form.reset({
              activeStep: 0,
              type: "",
              personalDetails: {
                email: "",
                name: "",
                gender: "",
                maritalStatus: "",
                fatherName: "",
                motherName: "",
                dateOfBirth: "",
                address: "",
                city: "",
                state: "",
                country: "",
                pincode: "",
                income: "",
                occupation: "",
                pan: "",
                aadhar: "",
                emergencyContact: "",
                emergencyContactNum: "",
              },
              branch: "",
              family: [],
              signatureType: "",
              isExistingClient: false,
              phone: "",
              countryCode: "+91",
              bankAccounts: [],
              dematAccounts: [],
              nominees: [],
              kyc: null,
              document: [],
              id: "",
              sendOptions: "",
              manual: "",
              identification_type: "",
              riskProfile: null,
              agreement: "",
              basketObject: null,
              partner: null,
              portfolioObject: null,
              riskProfileObject: null,
              selectedFeePlan: null,
              planDataObject: {
                aumCash: "",
                showHoldingsModal: false,
                aumBasedPlan: "",
                subscriptionPlan: null,
                holdingsValue: null,
                subscriptionPlanAccountType: null,
                holdingsProofs: [],
                cashProofs: [],
                plansHoldings: [
                  {
                    gridCode: "",
                    quantity: 0,
                    cmp: "",
                    id: 0,
                  },
                ],
              },
            });
          }}
        >
          Add New Client
        </Button>
      </Grid2>
    </Grid2>
  );
};

export default AdvisorOnboardingCompleted;
