import { blueGrey } from "@mui/material/colors";
import {
  Box,
  Button,
  Card,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  GKTextField,
  Grid2,
  Radio,
  RadioGroup,
  Show,
  Typography,
} from "components";
import dayjs from "dayjs";
import { useMutation } from "hooks/useQueries";
import { AdvisorOnboardFormInterface } from "interfaces/OnboardingInterface";
import { isEmpty } from "lodash";
import { Controller, useFormContext } from "react-hook-form";
import toast from "react-hot-toast";
import { numberToCurrency } from "utils";
import axiosInstance from "utils/axiosInstance";
import { handleCalculateFee, refetchQuery } from "utils/helpers";
import { QueryKeys } from "utils/queryKey";
import PlansHoldings from "./PlansHoldings";

const AdvisorOnboardingInvestments = () => {
  const form = useFormContext<AdvisorOnboardFormInterface>();

  const { mutate } = useMutation({
    mutationKey: ["ADD_AUM_BASED_SUBSCRIPTION"],
    mutationFn: (values: any) =>
      axiosInstance.patch(
        `/plans/${form.getValues("portfolioObject.id")}/`,
        values,
      ),

    onSuccess: () => {
      refetchQuery(QueryKeys.GET_COMPLIANCE_BY_CLIENT_ID);
      refetchQuery(QueryKeys.GET_CLIENT_BY_ID);
      form.setValue("activeStep", form.getValues("activeStep") + 1);
    },
  });

  const handleSubmit = (values: AdvisorOnboardFormInterface) => {
    const formData = new FormData();
    formData.append("freshCapital", values.planDataObject.aumCash);
    formData.append(
      "transactions",
      isEmpty(values?.planDataObject?.plansHoldings[0].gridCode)
        ? JSON.stringify([])
        : JSON.stringify(
            values?.planDataObject?.plansHoldings?.map((d) => {
              return {
                gridCode: d.gridCode,
                quantity: d.quantity,
                cmp: d.cmp,
              };
            }),
          ),
    );
    formData.append(
      "portfolio",
      JSON.stringify(form.getValues("portfolioObject.id")),
    );
    formData.append("frequency", form.getValues("advanceFeePlan.frequency"));
    formData.append(
      "amount",
      JSON.stringify(
        handleCalculateFee({
          feePercent: Number(
            form.getValues("advanceFeePlan.feePlan.managementFee.fee"),
          ),
          frequency: form.getValues("advanceFeePlan.frequency"),
          total:
            Number(form.getValues("planDataObject.holdingsValue")) +
            Number(form.getValues("planDataObject.aumCash")),
        }) || 0,
      ),
    );
    formData.append(
      "holdingsValue",
      (values.planDataObject.holdingsValue as any) || 0,
    );
    formData.append("startDate", dayjs(new Date()).format("YYYY-MM-DD"));
    values.planDataObject?.holdingsProofs?.forEach(
      (file: string | Blob, index: any) => {
        formData.append(`holdingsProof[${index}]`, file);
      },
    );
    values.planDataObject?.cashProofs?.forEach(
      (file: string | Blob, index: any) => {
        formData.append(`capitalProof[${index}]`, file);
      },
    );
    if (form.getValues("planDataObject.aumBasedPlan") === "") {
      toast.error("You need to add value to proceed forward");
    } else {
      mutate(formData);
    }
  };

  return (
    <form
      id="aum-fee-plan-form"
      onSubmit={form.handleSubmit((values) => {
        handleSubmit(values);
      })}
    >
      <Show.When isTrue={!form.getValues("uploadHoldingsModal")}>
        <Grid2 container spacing={2} p={2}>
          <Show.When
            isTrue={
              !form.getValues("skipAdvanceFee") &&
              form.getValues("advanceFeePlan.feePlan.managementFee.fee") !==
                null
            }
          >
            <Grid2 size={12}>
              <Typography
                sx={{
                  borderRadius: "8px",
                  border: `1px solid #F1F5F9`,
                  background: "#F8FAFC",
                }}
                fontSize={12}
                color="#94A3B8"
                p={1}
              >
                We charge an AUA based fee of{" "}
                {Number(
                  form.getValues("advanceFeePlan.feePlan.managementFee.fee"),
                )}
                % annually for this basket, only users with a minimum investment
                of{" "}
                {numberToCurrency(
                  Number(
                    form.getValues(
                      "advanceFeePlan.feePlan.managementFee.minimumInvestment",
                    ),
                  ),
                )}{" "}
                can subscribe.
              </Typography>
            </Grid2>
          </Show.When>
          <Grid2 size={12}>
            <Controller
              name="planDataObject.aumBasedPlan"
              control={form.control}
              render={({ field }) => {
                return (
                  <FormControl>
                    <FormLabel sx={{ fontSize: 12 }}>
                      How do you want to invest in this basket?{" "}
                    </FormLabel>
                    <RadioGroup
                      {...field}
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                      }}
                      onChange={(e) => {
                        field.onChange(e.target.value);
                        form.setValue("planDataObject.aumCash", "");
                        form.setValue("planDataObject.holdingsValue", 0);
                        form.setValue("planDataObject.plansHoldings", [
                          {
                            gridCode: "",
                            quantity: 0,
                            cmp: "",
                            id: 0,
                            name: "",
                          },
                        ]);
                      }}
                    >
                      <FormControlLabel
                        value="withHoldings"
                        sx={{
                          "& .MuiFormControlLabel-label": { fontSize: 14 },
                        }}
                        control={
                          <Radio sx={{ padding: "0 8px 0 0", fontSize: 10 }} />
                        }
                        label="With existing holdings"
                      />
                      <FormControlLabel
                        value="withCash"
                        sx={{
                          "& .MuiFormControlLabel-label": { fontSize: 14 },
                        }}
                        control={<Radio sx={{ padding: "0 8px 0 0" }} />}
                        label="With fresh cash/capital"
                      />
                      <FormControlLabel
                        value="both"
                        sx={{
                          "& .MuiFormControlLabel-label": { fontSize: 14 },
                        }}
                        control={<Radio sx={{ padding: "0 8px 0 0" }} />}
                        label="Both"
                      />
                    </RadioGroup>
                  </FormControl>
                );
              }}
            />
          </Grid2>
          <Show.When
            isTrue={
              form.getValues("planDataObject.aumBasedPlan") === "both" ||
              form.getValues("planDataObject.aumBasedPlan") === "withHoldings"
            }
          >
            <Grid2 size={12}>
              <Card sx={{ p: 2 }}>
                <Grid2 container>
                  <Grid2 size={{ xs: 12, md: 6 }}>
                    <Typography fontWeight={600}>
                      With existing holdings
                    </Typography>
                    <Typography color={blueGrey[600]}>
                      Add your holdings by clicking on the button or enter the
                      total holding amount in the textbox.
                    </Typography>
                  </Grid2>
                  <Grid2
                    size={{ xs: 4 }}
                    sx={{
                      display: "flex",
                      gap: 1,
                      alignItems: "center",
                      justifyContent: {
                        xs: "center",
                        md: "flex-end",
                      },
                    }}
                  >
                    <Controller
                      control={form.control}
                      name="planDataObject.holdingsValue"
                      rules={{
                        required: {
                          message: "Please enter a valid amount",
                          value:
                            form.getValues("planDataObject.aumBasedPlan") ===
                              "both" ||
                            form.getValues("planDataObject.aumBasedPlan") ===
                              "withCash",
                        },
                      }}
                      render={({ field, fieldState: { error } }) => {
                        return (
                          <GKTextField
                            {...field}
                            placeholder="Enter holding value"
                            error={!!error}
                            helperText={
                              !!error && "Please enter a valid amount"
                            }
                          />
                        );
                      }}
                    />
                  </Grid2>
                  <Grid2
                    size={{ xs: 2 }}
                    sx={{
                      display: "flex",
                      gap: 1,
                      alignItems: "center",
                      justifyContent: {
                        xs: "center",
                        md: "flex-end",
                      },
                    }}
                  >
                    <Button
                      size="small"
                      variant="contained"
                      onClick={() => {
                        form.setValue("uploadHoldingsModal", true);
                      }}
                    >
                      {form.getValues("planDataObject.plansHoldings")[0]
                        ?.gridCode !== ""
                        ? "View Holdings"
                        : "Add Holdings"}
                    </Button>
                  </Grid2>
                </Grid2>
              </Card>
            </Grid2>
          </Show.When>
          <Show.When
            isTrue={
              form.getValues("planDataObject.aumBasedPlan") === "both" ||
              form.getValues("planDataObject.aumBasedPlan") === "withCash"
            }
          >
            <Grid2 size={12}>
              <Card sx={{ p: 2 }}>
                <Grid2 container>
                  <Grid2 size={{ xs: 12, md: 6 }}>
                    <Typography fontWeight={600}>
                      With fresh cash/capital
                    </Typography>
                    <Typography color={blueGrey[600]}>
                      Add the fresh capital amount you want to invest.
                    </Typography>
                  </Grid2>
                  <Grid2
                    size={{ xs: 12, md: 6 }}
                    sx={{
                      display: "flex",
                      gap: 1,
                      alignItems: "center",
                      justifyContent: {
                        xs: "center",
                        md: "flex-end",
                      },
                    }}
                  >
                    <Controller
                      control={form.control}
                      name="planDataObject.aumCash"
                      rules={{
                        required: {
                          message: "Please enter a valid amount",
                          value:
                            form.getValues("planDataObject.aumBasedPlan") ===
                              "both" ||
                            form.getValues("planDataObject.aumBasedPlan") ===
                              "withCash",
                        },
                      }}
                      render={({ field, fieldState: { error } }) => {
                        return (
                          <GKTextField
                            {...field}
                            sx={{ width: "40%" }}
                            type="number"
                            placeholder="Enter Cash Amount"
                            error={!!error}
                            helperText={error?.message}
                          />
                        );
                      }}
                    />
                  </Grid2>
                </Grid2>
              </Card>
            </Grid2>{" "}
          </Show.When>
          <Grid2 size={12}>
            <Divider />
          </Grid2>
          <Grid2 size={6}>
            <Box display={"flex"} alignItems={"center"}>
              <Typography fontSize={12} color={blueGrey[500]}>
                Fees
              </Typography>
            </Box>
            <Typography fontSize={14} fontWeight={600}>
              {numberToCurrency(
                form.getValues("skipAdvanceFee")
                  ? 0
                  : handleCalculateFee({
                      feePercent: Number(
                        form.getValues(
                          "advanceFeePlan.feePlan.managementFee.fee",
                        ),
                      ),
                      frequency: form.getValues("advanceFeePlan.frequency"),
                      total:
                        Number(form.getValues("planDataObject.holdingsValue")) +
                        Number(form.getValues("planDataObject.aumCash")),
                    }) || 0,
              )}
            </Typography>
          </Grid2>
          <Grid2
            size={6}
            display={"flex"}
            alignItems={"flex-end"}
            flexDirection={"column"}
          >
            <Typography fontSize={12} color={blueGrey[500]}>
              Total Investment Value
            </Typography>
            <Typography fontWeight={600}>
              {numberToCurrency(
                +form.getValues("planDataObject.aumCash") +
                  +form.getValues("planDataObject.holdingsValue"),
              )}
            </Typography>
          </Grid2>
        </Grid2>
      </Show.When>
      <Show.When isTrue={form.getValues("uploadHoldingsModal")}>
        <Grid2 size={12}>
          <PlansHoldings />
        </Grid2>
      </Show.When>
    </form>
  );
};

export default AdvisorOnboardingInvestments;
