import { LoadingButton } from "@mui/lab";
import { useAppContext } from "AppContext";
import {
  Divider,
  GKButton,
  Grid2,
  Show,
  Tab,
  Tabs,
  Typography,
} from "components";
import { useFetchQuery, useMutation } from "hooks/useQueries";
import DashboardLayout from "layouts/DashboardLayout";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { EMPLOYEE_ASSIGN_ROLE, GET_EMPLOYEE_BY_ID } from "utils/apiRoutes";
import axiosInstance from "utils/axiosInstance";
import { QueryKeys } from "utils/queryKey";
import AdminAccess from "./AdminAccess";
import AssignRoleModal from "./AssignRoleModal";
import EmployeesPermissions from "./BranchAdminsPermissions";
import EditAdminModal from "./EditAdminModal";

const AdminDetails = () => {
  const {
    state: { tab },
    dispatch,
  } = useAppContext();

  const { id } = useParams<string>();

  const [openEditModal, setOpenEditModal] = useState(false);
  const [assignRoleModal, setAssignRoleModal] = useState(false);

  const { data: employeeDetails, isFetching: isInitialLoading } = useFetchQuery(
    {
      key: [QueryKeys.EMPLOYEE_LIST, id],
      route: GET_EMPLOYEE_BY_ID(id),
    },
  );

  const { mutate: sendRegistrationLink, isLoading: linkLoading } = useMutation({
    mutationFn: (data: any) =>
      axiosInstance.post("/send-admin-reg/", { employee: data }),
    mutationKey: ["SEND_REGISTRATION_LINK"],
  });

  return (
    <DashboardLayout title="Branch Admin" loading={isInitialLoading}>
      <Grid2 container spacing={1}>
        <Grid2 size={6}>
          <Typography
            fontWeight={600}
            fontSize={18}
            textTransform={"capitalize"}
          >
            {employeeDetails?.data?.user?.personalDetails?.name || "-"}:{" "}
            {employeeDetails?.data?.code || "-"}
          </Typography>
          <Typography fontSize={12} textTransform={"capitalize"}>
            {employeeDetails?.data?.role || "-"}
          </Typography>
        </Grid2>
        <Grid2 size={6} textAlign={"right"}>
          <LoadingButton
            loading={linkLoading}
            onClick={() => {
              sendRegistrationLink(id);
            }}
            variant="outlined"
            color="dark"
          >
            Send Registration Link
          </LoadingButton>
          &nbsp;
          <GKButton
            variant="outlined"
            color="dark"
            onClick={() => setAssignRoleModal(true)}
          >
            Assign Role
          </GKButton>{" "}
          &nbsp;
          <GKButton
            variant="outlined"
            color="dark"
            onClick={() => setOpenEditModal(true)}
          >
            Profile Details
          </GKButton>
        </Grid2>
        <Grid2 size={12} my={1}>
          <Tabs
            onChange={(e, val) => {
              dispatch({ type: "TAB", data: { ...tab, employeeERMTabs: val } });
            }}
            value={tab?.employeeERMTabs}
          >
            <Tab label="Permissions" value={0} />
            <Tab label="Access" value={1} />
          </Tabs>
          <Divider />
        </Grid2>
        <Grid2 size={12}>
          <Show.When isTrue={tab?.employeeERMTabs === 0}>
            <EmployeesPermissions />
          </Show.When>
        </Grid2>
        <Grid2 size={12}>
          <Show.When isTrue={tab?.employeeERMTabs === 1}>
            <AdminAccess />
          </Show.When>
        </Grid2>
      </Grid2>

      <Show.When isTrue={openEditModal}>
        <EditAdminModal open={openEditModal} setOpen={setOpenEditModal} />
      </Show.When>

      <Show.When isTrue={assignRoleModal}>
        <AssignRoleModal
          open={assignRoleModal}
          setOpen={setAssignRoleModal}
          getAssignRoleApi={{
            key: ["ASSIGN_ROLE_FOR_EMPLOYEE"],
            route: EMPLOYEE_ASSIGN_ROLE(id),
          }}
          roleType="employee"
          refetchQueryName={QueryKeys.EMPLOYEE_PERMISSION}
        />
      </Show.When>
    </DashboardLayout>
  );
};

export default AdminDetails;
