import { LoadingButton } from "@mui/lab";
import { useAppContext } from "AppContext";
import {
  Divider,
  GKButton,
  GKDatePicker,
  GKSearchSelect,
  Grid2,
  Show,
  TextField,
  Typography,
} from "components";
import dayjs from "dayjs";
import { useFetchQuery, useMutation } from "hooks/useQueries";
import DashboardLayout from "layouts/DashboardLayout";
import { useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import {
  FEE_CALCULATION_HISTORY,
  GET_ALL_FEE_PLAN,
  PORTFOLIO_FEE_PLAN_MAPPING_BY_ID,
} from "utils/apiRoutes";
import axiosInstance from "utils/axiosInstance";
import { handleReturnAutocompletePlaceholder } from "utils/StringFunctions";
import useDocumentTitle from "utils/useDocumentTitle";
import CalculationPage from "./CalculationPage";
import CalculationProformaModal from "./CalculationProformaModal";
import ManagementDeductionPage from "./ManagementDeductionPage";

const CreateCalculation = () => {
  useDocumentTitle("Calculation");

  const { id, portfolioId } = useParams();

  const navigate = useNavigate();

  const {
    state: { portfolios },
  } = useAppContext();

  const [stateModal, setStateModal] = useState(false);
  const [openDeductModal, setOpenDeductModal] = useState(false);
  const [selected, setSelected] = useState<readonly number[]>([]);
  // const [openWaterMarkModal, setOpenWaterMarkModal] = useState(false);

  const { data: calculationById, isFetching: calculationByIdLoading } =
    useFetchQuery({
      key: ["CALCULATION_BY_ID"],
      route: `${FEE_CALCULATION_HISTORY}${id}/`,
      enabled: Boolean(id),
    });

  const { data: feePlansList, isFetching: getFeePlansFetching } = useFetchQuery(
    {
      key: ["GET_FEE_PLANS_DROPDOWN"],
      route: GET_ALL_FEE_PLAN,
    },
  );

  const form = useForm({
    defaultValues: {
      portfolio: "",
      isManagement: false,
      managementFee: 0,
      additionalFee: 0,
      isUpdateHighwaterMark: false,
      feePlans: [{ feePlan: "", startDate: "", endDate: "" }],
      calculationBenchmark: "",
    },
    values: {
      portfolio: portfolios.find(
        (dataItem: any) => dataItem.value === +portfolioId,
      ),
      isManagement: false,
      managementFee: 0,
      additionalFee: 0,
      isUpdateHighwaterMark: false,
      feePlans: [{ feePlan: "", startDate: "", endDate: "" }],
      calculationBenchmark: true,
    },
  });

  form.watch();

  const { isFetching: feePlanByPortfolioLoading } = useFetchQuery({
    key: ["FEE_PLAN_BY_PORTFOLIO_ID"],
    route: PORTFOLIO_FEE_PLAN_MAPPING_BY_ID(
      +portfolioId || form.getValues("portfolio")?.id,
    ),
    enabled: !!+portfolioId || !!form.getValues("portfolio"),
    success: (response: any) => {
      form.setValue(`feePlans.${0}.feePlan`, response?.data?.data?.id);
      form.setValue("isManagement", response?.data?.data?.isGetManagement);
      form.setValue(
        "calculationBenchmark",
        response?.data?.data?.calculationBenchmark,
      );
    },
  });

  const { fields } = useFieldArray({
    control: form.control,
    name: "feePlans",
  });

  const {
    mutate: createCalculation,
    data,
    isLoading,
    reset,
  } = useMutation({
    mutationKey: ["CREATE_CALCULATION"],
    mutationFn: (data) =>
      axiosInstance.post("/portfolio-fee-calculation/", data),
  });

  const { mutate: handleSaveCalculation, isLoading: saveCalculationLoading } =
    useMutation({
      mutationKey: ["SAVE_CALCULATION"],
      mutationFn: ({
        calculationData,
        portfolio,
        feePlanName,
        feeAmount,
        feeDateRange,
        isUpdateHighwaterMark,
      }: {
        calculationData: any;
        portfolio: any;
        feePlanName: any;
        feeAmount: any;
        feeDateRange: any;
        isUpdateHighwaterMark: any;
      }) =>
        axiosInstance.post("/fee-calculation-history/", {
          calculationData: calculationData,
          portfolio: portfolio,
          feePlanName: feePlanName,
          feeAmount: feeAmount,
          feeDateRange: feeDateRange,
          isUpdateHighwaterMark: isUpdateHighwaterMark,
        }),
      onSuccess: () => {
        navigate(-1);
      },
    });

  const {
    data: feeDeductionData,
    mutate: getManagementFeeForDeduction,
    isLoading: feeDeductionLoading,
    reset: feeDeductionDataReset,
  } = useMutation({
    mutationKey: ["GET_MANAGEMENT_FEE"],
    mutationFn: (data) => axiosInstance.post("/get-management-fee/", data),
    onSuccess: () => {
      reset();
    },
  });

  const onSubmit = (data: any) => {
    delete data.isManagement;
    delete data.additionalFee;

    createCalculation({ ...data, portfolio: data.portfolio.id });
    feeDeductionDataReset();
  };

  return (
    <DashboardLayout
      title="Create Calculation"
      loading={calculationByIdLoading}
    >
      <form onSubmit={form.handleSubmit(onSubmit)} style={{ width: "100%" }}>
        {id ? (
          <Grid2 container spacing={1}>
            <Grid2 size={12} my={1}>
              <Typography fontSize={20} fontWeight={600}>
                {`${calculationById?.data?.data?.portfolioName} - ${calculationById?.data?.data?.portfolioId}`}
              </Typography>
              <Typography fontSize={12} display={"flex"} alignItems={"center"}>
                Client {"  "}
                <span style={{ fontWeight: 500, marginLeft: "4px" }}>
                  {calculationById?.data?.data?.clientName}
                  {" - "}
                  {calculationById?.data?.data?.clientCode}
                </span>{" "}
              </Typography>
            </Grid2>
          </Grid2>
        ) : (
          <Grid2 container spacing={1}>
            {!id && (
              <Grid2
                size={12}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
                my={1}
              >
                <Typography fontSize={18} fontWeight={600}>
                  Create calculation
                </Typography>
                <div style={{ gap: 1 }}>
                  <GKButton
                    variant="outlined"
                    color="dark"
                    size="medium"
                    onClick={() => {
                      form.reset();
                      reset();
                      feeDeductionDataReset();
                      form.setValue("portfolio", "");
                    }}
                  >
                    Reset
                  </GKButton>{" "}
                  &nbsp;
                  <LoadingButton
                    type="submit"
                    size="medium"
                    variant="contained"
                    loading={isLoading}
                  >
                    Calculate
                  </LoadingButton>
                </div>
              </Grid2>
            )}
            <Grid2 container spacing={1} size={12}>
              <Grid2 size={form.getValues("isManagement") ? 3 : 4}>
                <Controller
                  name="portfolio"
                  control={form.control}
                  rules={{
                    required: {
                      value: true,
                      message: "Please select portfolio",
                    },
                  }}
                  render={({ field, formState: { errors } }) => {
                    return (
                      <GKSearchSelect
                        {...field}
                        requiredField
                        disabled={!!portfolioId}
                        disableClearable={false}
                        inputLabel="Portfolio"
                        renderOption={(props, option) => {
                          return (
                            <li
                              {...props}
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "flex-start",
                              }}
                            >
                              <Typography fontWeight={600}>
                                {option.clientName}
                              </Typography>
                              <Typography>{option.name}</Typography>
                            </li>
                          );
                        }}
                        options={portfolios || []}
                        onChange={(e, val) => field.onChange(val || "")}
                        getOptionLabel={(option) => option.name || option}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            name="portfolio"
                            placeholder={handleReturnAutocompletePlaceholder({
                              placeholder: "Select Portfolio",
                              value: field.value,
                            })}
                            error={Boolean(errors?.portfolio)}
                            helperText={errors?.portfolio?.message as string}
                          />
                        )}
                      />
                    );
                  }}
                />
              </Grid2>

              <Show.When
                isTrue={
                  form.getValues("portfolio") && !feePlanByPortfolioLoading
                }
              >
                {fields.map((row, index) => {
                  return (
                    <>
                      <Grid2 size={form.getValues("isManagement") ? 2.5 : 4}>
                        <Controller
                          name={`feePlans.${index}.feePlan`}
                          control={form.control}
                          render={({ field }) => (
                            <GKSearchSelect
                              {...field}
                              disableClearable={false}
                              inputLabel="Fee Plan"
                              loading={getFeePlansFetching}
                              options={feePlansList?.data?.data || []}
                              onChange={(e, val) => {
                                field.onChange(val?.value);
                                form.setValue(
                                  "isManagement",
                                  val?.isGetManagement,
                                );
                                form.setValue(
                                  "calculationBenchmark",
                                  val?.calculationBenchmark,
                                );
                              }}
                              value={
                                feePlansList?.data?.data?.find(
                                  (data: { value: string | number }) =>
                                    data?.value === field.value,
                                )?.name
                              }
                              getOptionLabel={(option) => option.name || option}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  name={`feePlans.${index}.feePlan`}
                                  placeholder={handleReturnAutocompletePlaceholder(
                                    {
                                      placeholder: "Select Fee Plan",
                                      value: field.value,
                                    },
                                  )}
                                />
                              )}
                            />
                          )}
                        />
                      </Grid2>
                      <Grid2 size={2}>
                        <Controller
                          control={form.control}
                          name={`feePlans.${index}.startDate`}
                          render={({ field }) => {
                            return (
                              <GKDatePicker
                                {...field}
                                inputLabel="Start Date"
                                value={dayjs(field.value)}
                                onChange={(value) => {
                                  field.onChange(
                                    dayjs(value).format("YYYY-MM-DD"),
                                  );
                                }}
                                disableToday={Boolean(
                                  form.getValues("calculationBenchmark"),
                                )}
                                disableFuture={Boolean(
                                  form.getValues("calculationBenchmark"),
                                )}
                                slotProps={{
                                  textField: {
                                    error: false,
                                    helperText: false,
                                  },
                                }}
                              />
                            );
                          }}
                        />
                      </Grid2>
                      <Grid2 size={2}>
                        <Controller
                          control={form.control}
                          name={`feePlans.${index}.endDate`}
                          render={({ field }) => {
                            return (
                              <GKDatePicker
                                {...field}
                                inputLabel="End Date"
                                value={dayjs(field.value)}
                                onChange={(value) => {
                                  field.onChange(
                                    dayjs(value).format("YYYY-MM-DD"),
                                  );
                                }}
                                disableToday={Boolean(
                                  form.getValues("calculationBenchmark"),
                                )}
                                disableFuture={Boolean(
                                  form.getValues("calculationBenchmark"),
                                )}
                                maxDate={dayjs(
                                  form.getValues(`feePlans.${index}.startDate`),
                                ).add(365, "days")}
                                slotProps={{
                                  textField: {
                                    error: false,
                                    helperText: false,
                                  },
                                }}
                              />
                            );
                          }}
                        />
                      </Grid2>
                      {/* <Grid2

                          size={1}
                          display={"flex"}
                          alignItems={"center"}
                          justifyContent={"center"}
                          mt={2.7}
                        >
                          <IconButton
                            color="error"
                            disabled={fields.length === 1}
                            onClick={() => remove(index)}
                          >
                            <FiTrash2 size={16} />
                          </IconButton>
                        </Grid2> */}
                    </>
                  );
                })}
                {/* <Grid2 size={12} mt={1}>
                    <Divider>
                      <IconButton
                        onClick={() => {
                          append({ feePlan: "", startDate: "", endDate: "" });
                        }}
                      >
                        <AiOutlinePlus />
                      </IconButton>
                    </Divider>
                  </Grid2> */}

                <Show.When isTrue={form.getValues("isManagement")}>
                  <Grid2 size={2.5} mt={2.3}>
                    <LoadingButton
                      loading={feeDeductionLoading}
                      size="large"
                      variant="contained"
                      disabled={!isLoading && data?.data?.data}
                      onClick={form.handleSubmit((values: any) => {
                        getManagementFeeForDeduction({
                          ...values,
                          portfolio: values.portfolio.id,
                        });
                        setOpenDeductModal(true);
                      })}
                    >
                      Deduct Management Fees
                    </LoadingButton>
                  </Grid2>
                </Show.When>
              </Show.When>
            </Grid2>
          </Grid2>
        )}

        <Show.When isTrue={(!isLoading && data?.data?.data) || id}>
          <Grid2 container spacing={1}>
            <Grid2 size={12} mb={1} mt={2}>
              {!id && <Divider />}
            </Grid2>
            <Grid2 size={12}>
              <CalculationPage
                calculationData={
                  id
                    ? calculationById?.data?.data?.calculationData
                    : data?.data?.data
                }
              />
            </Grid2>
            <Show.When isTrue={!id}>
              <Grid2 size={12} display={"flex"} justifyContent={"flex-end"}>
                <LoadingButton
                  variant="outlined"
                  color="dark"
                  size="medium"
                  loading={saveCalculationLoading}
                  onClick={(e) => {
                    e.preventDefault();
                    handleSaveCalculation({
                      calculationData: data?.data?.data,
                      portfolio: data?.data?.data?.portfolio,
                      feePlanName: data?.data?.data?.feePlanList,
                      feeAmount: data?.data?.data?.totalAmount,
                      feeDateRange: data?.data?.data?.feeDateRange,
                      isUpdateHighwaterMark: form.getValues(
                        "isUpdateHighwaterMark",
                      ),
                    });
                  }}
                >
                  Save
                </LoadingButton>{" "}
                &nbsp;
                <LoadingButton
                  variant="contained"
                  size="medium"
                  onClick={() => setStateModal(true)}
                >
                  Save And Create Proforma
                </LoadingButton>
              </Grid2>
            </Show.When>
          </Grid2>
        </Show.When>
      </form>

      <Show.When isTrue={stateModal}>
        <CalculationProformaModal
          open={stateModal}
          setOpen={setStateModal}
          calculationData={data?.data?.data}
          isDefault={false}
        />
      </Show.When>

      {/* <Show.When isTrue={openWaterMarkModal}>
        <HighWaterMarkModal
          open={openWaterMarkModal}
          setOpen={setOpenWaterMarkModal}
          form={form}
          handleSaveCalculation={handleSaveCalculation}
        />
      </Show.When> */}

      <Show.When isTrue={openDeductModal}>
        <ManagementDeductionPage
          open={openDeductModal}
          setOpen={setOpenDeductModal}
          form={form}
          feeDeductionData={feeDeductionData}
          selected={selected}
          setSelected={setSelected}
        />
      </Show.When>
    </DashboardLayout>
  );
};

export default CreateCalculation;
