import { useAppContext } from "AppContext";
import {
  AnimationComponent,
  Box,
  Grid2,
  Show,
  Tab,
  Tabs,
  Typography,
} from "components";
import DashboardLayout from "layouts/DashboardLayout";
import { CompliancePage, Lead } from "Pages";
import Client from "Pages/Client";
import Family from "Pages/Family";
import React from "react";
import { BiLockAlt } from "react-icons/bi";
import { ThemeContainer } from "utils/Theme";

const CRMMenu = () => {
  const {
    state: { tab: tabValue, userData },
    dispatch,
  } = useAppContext();

  const { theme } = ThemeContainer();

  const tabs = [
    { label: "Client", component: Client, canAccess: true },
    { label: "Lead", component: Lead, canAccess: userData?.grantAccess },
    {
      label: "Onboarding",
      component: CompliancePage,
      canAccess: userData?.grantAccess,
    },
    { label: "Family", component: Family, canAccess: userData?.grantAccess },
  ];

  return (
    <DashboardLayout title="Dashboard">
      <Grid2 container spacing={2}>
        <Grid2 size={12}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              sx={{
                "& .MuiTabs-flexContainer": {
                  backgroundColor: theme.palette.light.main,
                },
              }}
              onChange={(e, val) => {
                dispatch({
                  type: "TAB",
                  data: { ...tabValue, tabValueOfCRM: val },
                });
              }}
              value={tabValue?.tabValueOfCRM}
            >
              {tabs.map((tab, index) => (
                <Tab
                  key={index}
                  disabled={!tab.canAccess}
                  label={
                    <Box display={"flex"} gap={1}>
                      <Typography>{tab.label}</Typography>
                      {!tab.canAccess && <BiLockAlt size={16} />}
                    </Box>
                  }
                  disableRipple
                />
              ))}
            </Tabs>
          </Box>
        </Grid2>
        <Grid2 size={12}>
          {tabs.map((tab, index) => (
            <Show.When key={index} isTrue={tabValue?.tabValueOfCRM === index}>
              <AnimationComponent>
                {React.createElement(tab.component)}
              </AnimationComponent>
            </Show.When>
          ))}
        </Grid2>
      </Grid2>
    </DashboardLayout>
  );
};

export default CRMMenu;
