import { useAppContext } from "AppContext";
import {
  Button,
  Divider,
  DragDrop,
  GKClientDataGrid,
  GKModal,
  GKSearchSelect,
  GKTextField,
  Grid2,
  Show,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "components";
import SimpleLoader from "components/SimpleLoader";
import { useMutation } from "hooks/useQueries";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import axiosInstance from "utils/axiosInstance";
import { handleCapitalize } from "utils/common";
import { GKModalProps } from "utils/commonInterface";
import { matchDate, refetchQuery } from "utils/helpers";
import { moneyCommaSeparator } from "utils/MathFunction";
import { handleReturnAutocompletePlaceholder } from "utils/StringFunctions";

interface Props {
  setRebalanceModal?: any;
  rebalanceData?: any;
  isRebalanceModal: boolean;
}

const ImportModelPortfolio = (props: Props & GKModalProps) => {
  const { open, setOpen, isRebalanceModal, setRebalanceModal, rebalanceData } =
    props;

  const {
    state: { companies },
  } = useAppContext();

  const [currentStep, setCurrentSteps] = useState(0);

  const [reviewTabs, setReviewTabs] = useState(0);

  const [uploadError, setUploadError] = useState<string | null>(null);

  const handleNext = () => {
    setCurrentSteps((prev) => prev + 1);
  };

  const { id } = useParams();

  const form = useForm({
    defaultValues: {
      companies: [],
      version: "",
      tradeFile: [],
      cashFile: [],
      response: null,
      reviewResponse: null,
    },
  });

  form.watch();

  // ===###===###===###===###===###===###===###===###===###===###===###===###===###
  const {
    mutate: handleReviewNoCommit,
    isLoading: handleReviewNoCommitLoading,
  } = useMutation({
    mutationFn: (data: any) =>
      axiosInstance.post(
        `/model_portfolio/${id}/version/review_trades/?commit=false&rebalance=${isRebalanceModal}`,
        data,
      ),

    onSuccess: (response) => {
      form.setValue("reviewResponse", response?.data);
    },
  });

  // ===###===###===###===###===###===###===###===###===###===###===###===###===###
  const { mutate: handleReviewCommit, isLoading: handleReviewCommitLoading } =
    useMutation({
      mutationFn: (data: any) =>
        axiosInstance.post(
          `/model_portfolio/${id}/version/review_trades/?commit=true&rebalance=${isRebalanceModal}`,
          data,
        ),

      onSuccess: () => {
        setOpen(false);
        setRebalanceModal && setRebalanceModal(false);
        refetchQuery("GET_ALL_VERSIONS");
      },
    });

  // ===###===###===###===###===###===###===###===###===###===###===###===###===###
  const {
    mutate: handleGetCompanyMappings,
    isLoading: handleGetCompanyMappingsLoading,
  } = useMutation({
    mutationFn: (data: any) =>
      axiosInstance.get(`/transaction_upload/${data}/company_mappings/`),

    onSuccess: (response) => {
      if (
        response?.data?.data?.filter((data: any) => data?.value === null)
          ?.length !== 0
      ) {
        form.setValue(
          "companies",
          response?.data?.data?.filter((data: any) => data?.value === null),
        );
      } else {
        handleNext();
        handleReviewNoCommit(form.getValues("response"));
      }
    },
  });

  // ===###===###===###===###===###===###===###===###===###===###===###===###===###
  const {
    mutate: handleSubmitCompanyMappings,
    isLoading: handleSubmitCompanyMappingsLoading,
  } = useMutation({
    mutationFn: (data: any) =>
      axiosInstance.post(`/advisor-company-mappings/`, data),

    onSuccess: () => {
      handleNext();
      handleReviewNoCommit(form.getValues("response"));
    },
  });

  // ===###===###===###===###===###===###===###===###===###===###===###===###===###
  const {
    mutate: handleUploadModelPortfolio,
    isLoading: handleUploadModelPortfolioLoading,
  } = useMutation({
    mutationFn: (data: any) =>
      axiosInstance.post(
        isRebalanceModal
          ? `/model_portfolio/${id}/version/${rebalanceData}/rebalance_trades/`
          : `/model_portfolio/${id}/version/upload_trades/`,
        data,
      ),
    onSuccess: (response) => {
      form.setValue("response", response?.data?.data);
      handleNext();
      handleGetCompanyMappings(response?.data?.data?.trades?.id);
    },
  });

  // ===###===###===###===###===###===###===###===###===###===###===###===###===###
  const handleFromSubmit = (values: any) => {
    const data = new FormData();
    data.append("version", values?.version);
    data.append("cash", values?.cashFile[0]);
    data.append("trades", values?.tradeFile[0]);
    data.append("startValue", values?.startValue);

    handleUploadModelPortfolio(data);
  };

  // ===###===###===###===###===###===###===###===###===###===###===###===###===###
  const loading =
    handleGetCompanyMappingsLoading ||
    handleSubmitCompanyMappingsLoading ||
    handleUploadModelPortfolioLoading ||
    handleReviewNoCommitLoading ||
    handleReviewCommitLoading;

  return (
    <GKModal
      modalTitle="Import Model Portfolio"
      open={open}
      setOpen={setOpen}
      footer={
        <Grid2 container justifyContent={"flex-end"}>
          <Show.When isTrue={currentStep === 2}>
            <Button
              variant="contained"
              onClick={() => {
                handleReviewCommit({
                  ...form.getValues("response"),
                  lenTrades:
                    form?.getValues("reviewResponse")?.trades?.transactions
                      ?.length,
                  lenCash:
                    form?.getValues("reviewResponse")?.cash?.transactions
                      ?.length,
                });
              }}
            >
              Finished
            </Button>
          </Show.When>
          <Show.When isTrue={currentStep === 1}>
            <Button
              variant="contained"
              onClick={() => {
                handleSubmitCompanyMappings(form.getValues("companies"));
              }}
            >
              Finished
            </Button>
          </Show.When>
          <Show.When isTrue={currentStep === 0}>
            <Button
              variant="contained"
              type="submit"
              form="model-portfolio-upload-form"
            >
              Next
            </Button>
          </Show.When>
        </Grid2>
      }
    >
      {loading ? (
        <SimpleLoader />
      ) : (
        <form
          id="model-portfolio-upload-form"
          onSubmit={form.handleSubmit(handleFromSubmit)}
        >
          <Show.When isTrue={currentStep === 0}>
            <Grid2 container spacing={2}>
              <Show.When isTrue={!isRebalanceModal}>
                <Grid2 size={6}>
                  <Controller
                    control={form.control}
                    name={`version`}
                    render={({ field }) => (
                      <GKTextField
                        {...field}
                        placeholder="Model Portfolio Name"
                        inputLabel="Model Portfolio Name"
                      />
                    )}
                  />
                </Grid2>
              </Show.When>
              <Grid2 size={12}>
                <Typography>Select Trade Sheet</Typography>
                <Controller
                  control={form.control}
                  name={`tradeFile`}
                  render={({ field }) => (
                    <DragDrop
                      uploadError={uploadError}
                      setUploadError={setUploadError}
                      {...field}
                      onChange={(e) => {
                        field.onChange(e);
                      }}
                      acceptType={".csv, .xlsx"}
                      supportName="CSV or XLSX"
                    />
                  )}
                />
              </Grid2>
              <Grid2 size={12}>
                <Typography>Select Cash Sheet</Typography>
                <Controller
                  control={form.control}
                  name={`cashFile`}
                  render={({ field }) => (
                    <DragDrop
                      uploadError={uploadError}
                      setUploadError={setUploadError}
                      {...field}
                      onChange={(e) => {
                        field.onChange(e);
                      }}
                      acceptType={".csv, .xlsx"}
                      supportName="CSV or XLSX"
                    />
                  )}
                />
              </Grid2>
            </Grid2>
          </Show.When>
          <Show.When
            isTrue={
              currentStep === 1 && form.getValues("companies")?.length !== 0
            }
          >
            <Grid2 container>
              {form.getValues("companies")?.map((dataItem) => {
                return (
                  <Grid2 size={12} key={dataItem?.advisorCompany}>
                    <GKSearchSelect
                      options={companies || []}
                      getOptionLabel={(opt) => opt?.name || opt}
                      disableClearable={false}
                      onChange={(e, val) => {
                        const data = form.getValues("companies")?.map((da) => {
                          return {
                            ...da,
                            value:
                              dataItem?.advisorCompany === da?.advisorCompany
                                ? val?.value
                                : da?.value,
                            nseBseCode:
                              dataItem?.advisorCompany === da?.advisorCompany
                                ? val?.bse || val?.nse
                                : da?.value,
                          };
                        });
                        form.setValue("companies", data);
                      }}
                      value={
                        companies?.find(
                          (item: any) => item?.value === dataItem?.value,
                        )?.name
                      }
                      inputLabel={dataItem?.advisorCompany}
                      renderInput={(props) => {
                        return (
                          <TextField
                            {...props}
                            placeholder={handleReturnAutocompletePlaceholder({
                              placeholder: "Select Companies",
                              value: dataItem.value,
                            })}
                          />
                        );
                      }}
                    />
                  </Grid2>
                );
              })}
            </Grid2>
          </Show.When>
          <Show.When isTrue={currentStep === 2}>
            <Grid2 container spacing={2}>
              <Grid2 size={12}>
                <Tabs
                  value={reviewTabs}
                  onChange={(e, val) => {
                    setReviewTabs(val);
                  }}
                >
                  <Tab label="Trades" />
                  <Tab label="Ledger" />
                </Tabs>
                <Divider />
              </Grid2>
              <Grid2 size={12} display={"flex"}>
                {reviewTabs === 0 ? (
                  <GKClientDataGrid
                    rows={
                      form?.getValues("reviewResponse")?.trades?.transactions ||
                      []
                    }
                    columns={[
                      {
                        field: "date",
                        headerName: "Date",
                        disableColumnMenu: true,
                        valueFormatter: (params) => matchDate(params?.value),
                        minWidth: 170,
                        flex: 1,
                        editable: false,
                      },
                      {
                        field: "companyName",
                        headerName: "Company Name",
                        disableColumnMenu: true,
                        minWidth: 170,
                        flex: 1,
                        editable: false,
                      },
                      {
                        field: "bseCode",
                        headerName: "BSE / NSE Code",
                        disableColumnMenu: true,
                        minWidth: 170,
                        flex: 1,
                        editable: false,
                        valueGetter: (params: any) =>
                          `${params.row.bseCode || "-"} / ${
                            params.row.nseCode || "-"
                          }`,
                      },
                      {
                        field: "transactionType",
                        headerName: "Transaction Type",
                        disableColumnMenu: true,
                        minWidth: 170,
                        flex: 1,
                        editable: false,
                        valueFormatter: (params) =>
                          handleCapitalize(params?.value),
                      },
                      {
                        field: "quantity",
                        headerName: "Quantity",
                        disableColumnMenu: true,
                        minWidth: 170,
                        flex: 1,
                        editable: false,
                      },
                      {
                        field: "price",
                        headerName: "Price",
                        disableColumnMenu: true,
                        minWidth: 170,
                        flex: 1,
                        editable: false,
                      },
                      {
                        headerName: "Bill Amount",
                        flex: 1,
                        minWidth: 150,
                        field: "billAmount",
                        disableColumnMenu: true,
                        align: "right",
                        headerAlign: "right",
                        valueGetter: (params) => params.row.billAmount || "0",
                        valueFormatter: (params) =>
                          moneyCommaSeparator(params.value),
                      },
                      {
                        field: "error",
                        headerName: "Error",
                        disableColumnMenu: true,
                        minWidth: 250,
                        flex: 1,
                        editable: false,
                      },
                    ]}
                    density="compact"
                    name="equityBasketImport"
                    maxHeight={`calc(100vh - 438px)`}
                  />
                ) : (
                  <GKClientDataGrid
                    rows={
                      form?.getValues("reviewResponse")?.cash?.transactions ||
                      []
                    }
                    columns={[
                      {
                        field: "date",
                        headerName: "Date",
                        flex: 1,
                        disableColumnMenu: true,
                        valueFormatter: (params) => matchDate(params?.value),
                        minWidth: 150,
                      },
                      {
                        field: "transactionType",
                        headerName: "Transaction Type",
                        flex: 1,
                        disableColumnMenu: true,
                        minWidth: 150,
                        valueFormatter: (params) =>
                          handleCapitalize(params?.value),
                        renderCell: (data: any) => data?.value || " ",
                      },
                      {
                        field: "billAmount",
                        headerName: "Amount",
                        valueGetter: (data: any) =>
                          moneyCommaSeparator(data?.value),
                        flex: 1,
                        disableColumnMenu: true,
                        minWidth: 150,
                        align: "right",
                        headerAlign: "right",
                      },
                      {
                        field: "remarks",
                        renderCell: (data: any) => data?.value || " ",
                        headerName: "Remarks",
                        flex: 1,
                        disableColumnMenu: true,
                        minWidth: 150,
                      },
                      {
                        field: "error",
                        renderCell: (data: any) => data?.value || "-",
                        headerName: "Error",
                        flex: 1,
                        disableColumnMenu: true,
                        minWidth: 150,
                      },
                    ]}
                    density="compact"
                    name="equityBasketImport"
                    maxHeight={`calc(100vh - 438px)`}
                  />
                )}
              </Grid2>
            </Grid2>
          </Show.When>
        </form>
      )}
    </GKModal>
  );
};

export default ImportModelPortfolio;
