/* eslint-disable prefer-const */
import { LoadingButton } from "@mui/lab";
import { useAppContext } from "AppContext";
import {
  Divider,
  GKEditor,
  GKSearchSelect,
  GKTextArea,
  GKTextField,
  GoUnverified,
  Grid2,
  InputAdornment,
  MdVerified,
  Show,
  SimpleLoader,
  TextField,
  Typography,
} from "components";
import GKImageUploader from "components/GKImageUploader";
import { useFetchQuery, useMutation } from "hooks/useQueries";
import { isEmpty } from "lodash";
import InvestorSettingsConfirmation from "Pages/Settings/InvestorSettingsConfirmation";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import axiosInstance from "utils/axiosInstance";
import { handleFreshReturnFormData } from "utils/formHelpers";
import { PAYMENT_METHOD } from "utils/helpers";
import { handleReturnAutocompletePlaceholder } from "utils/StringFunctions";
import { ThemeContainer } from "utils/Theme";
import useDocumentTitle from "utils/useDocumentTitle";
import { emailRegex, validateNotWhitespaceOnly } from "utils/validationSchema";

interface FormValues {
  id?: number;
  domainName?: string;
  paymentMethods?: string;
  paymentStep?: true;
  kycType?: string;
  agreement?: string;
  codePrefix?: string;
  revenueWithGst?: string;
  codeLength?: number;
  companyLogo?: any;
  qrCode?: any;
  companyMetaName?: string;
  tagLine?: string;
  tagLineBody?: string;
  colorPalette?: {
    primary: string;
    secondary: string;
  };
  advisorPhoto?: any;
  advisorName?: string;
  invoiceSeries?: string;
  sebiRegistered?: string;
  yearsOfExperience?: number;
  advisorInfo?: string;
  advisorPhone?: string;
  advisorEmail?: string;
  advisorAddress?: string;
  company?: number;
  isVerified?: boolean;
}

const WebsiteSettings = () => {
  useDocumentTitle("Investor Settings");

  const { theme } = ThemeContainer();

  const {
    state: { userData },
  } = useAppContext();

  const [investorSettingsConfirmation, setInvestorSettingsConfirmation] =
    useState(false);

  const [selectedImage, setSelectedImage] = useState<string | null>(null);

  const [croppedImage, setCroppedImage] = useState<string | null>(null);

  const [croppedAdvisorImage, setCroppedAdvisorImage] = useState<string | null>(
    null,
  );

  const [selectedAdvisorImage, setSelectedAdvisorImage] = useState<
    string | null
  >(null);

  const { data, refetch, isFetching } = useFetchQuery({
    key: ["GET_INVESTOR_PROFILE"],
    route: `/company/${userData?.companyId}/publish_settings/`,
    success: (response: any) => {
      setCroppedAdvisorImage(response?.data?.advisorPhoto);
      setCroppedImage(response?.data?.companyLogo);
    },
  });

  const { data: formData } = useFetchQuery({
    key: ["GET_CHECK_INVESTOR_DATA"],
    route: `/company/${userData?.companyId}/publish_settings/verify/`,
  });

  const { mutate: updateInvestorProfile, isLoading: updateInvestorLoading } =
    useMutation({
      mutationKey: ["GET_INVESTOR_PROFILE_DOMAIN"],
      mutationFn: (data: any) =>
        axiosInstance.patch(
          `/company/${userData?.companyId}/publish_settings/`,
          data,
        ),
      onSuccess: () => {
        refetch();
        setInvestorSettingsConfirmation(false);
      },
    });

  const form = useForm<FormValues>({
    defaultValues: {
      domainName: "",
      revenueWithGst: "false",
      paymentMethods: "",
      invoiceSeries: "",
      paymentStep: null,
      kycType: "",
      agreement: "",
      codePrefix: "",
      codeLength: 3,
      companyLogo: null,
      qrCode: null,
      companyMetaName: "",
      tagLine: "",
      tagLineBody: "",
      advisorPhoto: null,
      advisorName: "",
      sebiRegistered: "",
      yearsOfExperience: 0,
      advisorInfo: "",
      advisorPhone: "",
      advisorEmail: "",
      advisorAddress: "",
      colorPalette: {
        primary: "#006580",
        secondary: "#63A387",
      },
      isVerified: false,
    },
    values: {
      ...data?.data,
      agreementTemplate: "",
      colorPalette: data?.data?.colorPalette
        ? JSON.parse(data?.data?.colorPalette)
        : {
            primary: "#006580",
            secondary: "#63A387",
          },
    },
  });

  const paymentMethodWatch = form.watch("paymentMethods");

  const { mutate: confirmDomainName, isLoading } = useMutation({
    mutationKey: ["GET_INVESTOR_PROFILE_DOMAIN"],
    mutationFn: (data: string) =>
      axiosInstance.get(`/company/check_domain_name/?domain_name=${data}`),
    onSuccess: (response) => {
      const { available } = response.data;
      if (!available) {
        form.setError("domainName", {
          type: "manual",
          message: "Domain is not available. Please choose another one.",
        });
      } else {
        form.clearErrors("domainName");
        form.setValue("isVerified", true);
      }
    },
  });

  if (isFetching) return <SimpleLoader />;

  const handlePhotoDelete = (field: string) => {
    const formData: any = new FormData();
    formData.append(field, "");
    updateInvestorProfile(formData);
  };

  return (
    <form
      onSubmit={form.handleSubmit((values: FormValues) => {
        let newObject = { ...values };
        if (
          isEmpty(newObject?.companyLogo) ||
          typeof newObject?.companyLogo === "string"
        ) {
          delete newObject.companyLogo;
        } else {
          newObject = { ...newObject, companyLogo: values.companyLogo[0] };
        }
        if (
          isEmpty(newObject?.qrCode) ||
          typeof newObject?.qrCode === "string"
        ) {
          delete newObject.qrCode;
        } else {
          newObject = { ...newObject, qrCode: values.qrCode[0] };
        }
        if (
          isEmpty(newObject?.advisorPhoto) ||
          typeof newObject?.advisorPhoto === "string"
        ) {
          delete newObject.advisorPhoto;
        } else {
          newObject = { ...newObject, advisorPhoto: values.advisorPhoto[0] };
        }

        updateInvestorProfile(
          handleFreshReturnFormData({
            isEditForm: false,
            obj: {
              ...newObject,
            },
          }),
        );
      })}
      id="investor-setting-form"
    >
      <Grid2 container spacing={2}>
        <Grid2 size={3}>
          <Typography
            fontSize={16}
            fontWeight={500}
            color={theme.palette.secondaryText.main}
          >
            Micro-website Domain
          </Typography>
        </Grid2>

        <Grid2
          container
          size={9}
          spacing={2}
          display={"flex"}
          alignItems={"center"}
        >
          <Grid2 size={6}>
            <Controller
              name="domainName"
              control={form.control}
              rules={{
                required: {
                  value: true,
                  message: "Please enter a valid domain name",
                },
                validate: validateNotWhitespaceOnly,
              }}
              render={({ field, fieldState: { error } }) => {
                return (
                  <GKTextField
                    {...field}
                    inputLabel="Domain Name"
                    requiredField
                    sx={{ borderColor: "green" }}
                    onChange={(event) => {
                      field.onChange(
                        event.target.value.toLowerCase().replace(/\s/g, ""),
                      );
                    }}
                    slotProps={{
                      input: {
                        endAdornment: (
                          <InputAdornment position="end" sx={{ fontSize: 14 }}>
                            .gridkey.in
                          </InputAdornment>
                        ),
                      },
                    }}
                    error={!!error}
                    helperText={error?.message}
                  />
                );
              }}
            />
          </Grid2>
          <Grid2
            size={6}
            mt={"18px"}
            display={"flex"}
            alignItems={"center"}
            gap={2}
          >
            <LoadingButton
              variant="contained"
              loading={isLoading}
              onClick={() => confirmDomainName(form.getValues("domainName"))}
            >
              Confirm domain
            </LoadingButton>
            {form.getValues("isVerified") ? (
              <Typography
                display={"flex"}
                alignItems={"center"}
                gap={1}
                color={theme.palette.success.main}
                border={`1px solid ${theme.palette.success.main}`}
                p={1}
                borderRadius={2}
              >
                <MdVerified size={20} /> Verified
              </Typography>
            ) : (
              <Typography
                display={"flex"}
                alignItems={"center"}
                gap={1}
                color={theme.palette.error.main}
                border={`1px solid ${theme.palette.error.main}`}
                p={1}
                borderRadius={2}
              >
                <GoUnverified /> Not Verified
              </Typography>
            )}
          </Grid2>
          <Grid2 size={12} container gap={2}>
            <Grid2 size={2}>
              <Controller
                control={form.control}
                name="colorPalette.primary"
                render={({ field }) => (
                  <GKTextField
                    {...field}
                    inputLabel={"Primary Color"}
                    type="color"
                    style={{ borderRadius: "100%" }}
                    sx={{
                      width: 50,
                      mr: 1,
                      "& .MuiInputBase-input": {
                        p: 0,
                        height: 50,
                        width: 50,
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                    }}
                  />
                )}
              />
            </Grid2>
            <Grid2 size={2}>
              <Controller
                control={form.control}
                name="colorPalette.secondary"
                render={({ field }) => (
                  <GKTextField
                    inputLabel={"secondary Color"}
                    type="color"
                    style={{ borderRadius: "100%" }}
                    {...field}
                    sx={{
                      width: 50,
                      mr: 1,
                      "& .MuiInputBase-input": {
                        p: 0,
                        height: 50,
                        width: 50,
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                    }}
                  />
                )}
              />
            </Grid2>
          </Grid2>
        </Grid2>

        <Grid2 size={12} my={1}>
          <Divider />
        </Grid2>

        <Grid2 size={3}>
          <Typography
            fontSize={16}
            fontWeight={500}
            color={theme.palette.secondaryText.main}
          >
            Micro-website Profile
          </Typography>
        </Grid2>
        <Grid2
          container
          size={9}
          spacing={2}
          display={"flex"}
          alignItems={"center"}
        >
          <Grid2 size={12}>
            <Controller
              name="companyLogo"
              control={form.control}
              render={() => (
                <GKImageUploader
                  croppedImage={croppedImage}
                  handleProfilePicDelete={() => {
                    handlePhotoDelete("companyLogo");
                  }}
                  selectedImage={selectedImage}
                  setCroppedImage={setCroppedImage}
                  setSelectedImage={setSelectedImage}
                  form={form}
                  logoName={"companyLogo"}
                  buttonName="Upload Company Logo"
                />
              )}
            />
          </Grid2>
          <Grid2 size={12}>
            <Controller
              name="companyMetaName"
              control={form.control}
              rules={{
                required: {
                  value: true,
                  message: "Please enter company name",
                },
                validate: validateNotWhitespaceOnly,
              }}
              render={({ field, fieldState: { error } }) => {
                return (
                  <GKEditor
                    {...field}
                    inputLabel="Company Meta Name"
                    requiredField
                    error={!!error}
                    helperText={error?.message}
                    toolbar={[
                      "undo",
                      "redo",
                      "|",
                      "fontColor",
                      "fontBackgroundColor",
                    ]}
                  />
                );
              }}
            />
          </Grid2>
          <Grid2 size={12}>
            <Controller
              name="tagLine"
              control={form.control}
              rules={{
                required: {
                  value: true,
                  message: "Please enter tag line",
                },
                validate: validateNotWhitespaceOnly,
              }}
              render={({ field, fieldState: { error } }) => {
                return (
                  <GKEditor
                    {...field}
                    inputLabel="Tagline"
                    requiredField
                    error={!!error}
                    helperText={error?.message}
                    toolbar={[
                      "undo",
                      "redo",
                      "|",
                      "heading",
                      "bold",
                      "italic",
                      "underline",
                      "strikethrough",
                      "|",
                      "fontColor",
                      "fontBackgroundColor",
                      "|",
                      "alignment",
                    ]}
                  />
                );
              }}
            />
          </Grid2>
          <Grid2 size={12}>
            <Controller
              name="tagLineBody"
              control={form.control}
              rules={{
                required: {
                  value: true,
                  message: "Please enter tag line description",
                },
                validate: validateNotWhitespaceOnly,
              }}
              render={({ field, fieldState: { error } }) => {
                return (
                  <GKEditor
                    {...field}
                    inputLabel="Tagline Body"
                    requiredField
                    error={!!error}
                    helperText={error?.message}
                    toolbar={[
                      "undo",
                      "redo",
                      "|",
                      "heading",
                      "bold",
                      "italic",
                      "underline",
                      "strikethrough",
                      "|",
                      "fontColor",
                      "fontBackgroundColor",
                      "|",
                      "alignment",
                      "link",
                      "|",
                      "bulletedList",
                      "numberedList",
                    ]}
                  />
                );
              }}
            />
          </Grid2>
          <Grid2 size={12}>
            <Typography
              fontSize={16}
              fontWeight={500}
              color={theme.palette.secondaryText.main}
            >
              Advisor Details
            </Typography>
          </Grid2>
          <Grid2 size={12}>
            <Controller
              name="advisorPhoto"
              control={form.control}
              render={() => (
                <GKImageUploader
                  croppedImage={croppedAdvisorImage}
                  handleProfilePicDelete={() => {
                    handlePhotoDelete("advisorPhoto");
                  }}
                  selectedImage={selectedAdvisorImage}
                  setCroppedImage={setCroppedAdvisorImage}
                  setSelectedImage={setSelectedAdvisorImage}
                  form={form}
                  logoName={"advisorPhoto"}
                  buttonName="Upload Advisor Photo"
                />
              )}
            />
          </Grid2>
          <Grid2 size={4}>
            <Controller
              name="advisorName"
              control={form.control}
              rules={{
                required: {
                  value: true,
                  message: "Please enter advisor name",
                },
                validate: validateNotWhitespaceOnly,
              }}
              render={({ field, fieldState: { error } }) => (
                <GKTextField
                  {...field}
                  inputLabel="Advisor name"
                  requiredField
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
          </Grid2>
          <Grid2 size={4}>
            <Controller
              name="sebiRegistered"
              control={form.control}
              rules={{
                required: {
                  value: true,
                  message: "Please enter SEBI Registered number",
                },
                validate: validateNotWhitespaceOnly,
              }}
              render={({ field, fieldState: { error } }) => (
                <GKTextField
                  {...field}
                  requiredField
                  inputLabel="SEBI Registered No."
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
          </Grid2>
          <Grid2 size={4}>
            <Controller
              name="yearsOfExperience"
              control={form.control}
              rules={{
                required: {
                  value: true,
                  message: "Please enter years of experience",
                },
                validate: validateNotWhitespaceOnly,
              }}
              render={({ field, fieldState: { error } }) => (
                <GKTextField
                  {...field}
                  requiredField
                  inputLabel="Years of Experience"
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
          </Grid2>
          <Grid2 size={12}>
            <Controller
              name="advisorInfo"
              control={form.control}
              rules={{
                required: {
                  value: true,
                  message: "Please enter description",
                },
                validate: validateNotWhitespaceOnly,
              }}
              render={({ field, fieldState: { error } }) => (
                <GKEditor
                  {...field}
                  requiredField
                  inputLabel="Advisor Introduction"
                  error={!!error}
                  helperText={error?.message}
                  toolbar={[
                    "undo",
                    "redo",
                    "|",
                    "heading",
                    "bold",
                    "italic",
                    "underline",
                    "strikethrough",
                    "|",
                    "fontColor",
                    "fontBackgroundColor",
                    "|",
                    "alignment",
                    "link",
                    "|",
                    "bulletedList",
                    "numberedList",
                  ]}
                />
              )}
            />
          </Grid2>
          <Grid2 size={4}>
            <Controller
              name="advisorEmail"
              control={form.control}
              rules={{
                required: {
                  value: true,
                  message: "Please enter email",
                },
                pattern: {
                  value: emailRegex,
                  message: "Please enter a valid email",
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <GKTextField
                  {...field}
                  inputLabel="Email"
                  requiredField
                  error={!!error}
                  helperText={!!error && error?.message}
                />
              )}
            />
          </Grid2>
          <Grid2 size={4}>
            <Controller
              name="advisorPhone"
              control={form.control}
              render={({ field, fieldState: { error } }) => (
                <GKTextField
                  {...field}
                  type="number"
                  inputLabel="Phone Number"
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
          </Grid2>
          <Grid2 size={12}>
            <Controller
              name="advisorAddress"
              control={form.control}
              rules={{
                required: {
                  value: true,
                  message: "Please enter address",
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <GKTextArea
                  {...field}
                  requiredField
                  inputLabel="Address"
                  rows={4}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
          </Grid2>
        </Grid2>

        <Grid2 size={12}>
          <Divider />
        </Grid2>

        <Grid2 size={3}>
          <Typography
            fontSize={16}
            fontWeight={500}
            color={theme.palette.secondaryText.main}
          >
            Payment Method
          </Typography>
        </Grid2>

        <Grid2 container size={9} spacing={2}>
          <Grid2 size={6}>
            <Controller
              name="paymentMethods"
              control={form.control}
              rules={{
                required: {
                  value: true,
                  message: "Please select payment collection method",
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <GKSearchSelect
                  {...field}
                  requiredField
                  inputLabel="Payment Collection Method"
                  disableClearable={false}
                  options={PAYMENT_METHOD}
                  value={
                    PAYMENT_METHOD?.find(
                      (dataItem) => dataItem.value === field.value,
                    )?.name || ""
                  }
                  onChange={(event, val) => {
                    field.onChange(val?.value);
                  }}
                  getOptionLabel={(option) => option.name || option}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder={handleReturnAutocompletePlaceholder({
                        placeholder: "Select payment collection method",
                        value: field.value,
                      })}
                      error={!!error}
                      helperText={error?.message}
                    />
                  )}
                />
              )}
            />
          </Grid2>
        </Grid2>

        <Show.When isTrue={paymentMethodWatch === "manual_payment"}>
          <Grid2 size={12} my={1}>
            <Divider />
          </Grid2>
          <Grid2 size={3}>
            <Typography
              fontSize={16}
              fontWeight={500}
              color={theme.palette.secondaryText.main}
            >
              Payment Details
            </Typography>
          </Grid2>
          <Grid2 container size={9}>
            <Grid2 size={12}>
              <Controller
                name="qrCode"
                control={form.control}
                render={() => (
                  <GKImageUploader
                    croppedImage={croppedImage}
                    handleProfilePicDelete={() => {
                      handlePhotoDelete("qrCode");
                    }}
                    selectedImage={selectedImage}
                    setCroppedImage={setCroppedImage}
                    setSelectedImage={setSelectedImage}
                    form={form}
                    logoName={"qrCode"}
                    buttonName="Upload QR Code"
                  />
                )}
              />
            </Grid2>
          </Grid2>
        </Show.When>

        <Grid2
          size={12}
          mt={2}
          p={1}
          gap={1}
          display={"flex"}
          justifyContent={"flex-end"}
          position={"sticky"}
          bgcolor={theme.palette.light.main}
          bottom={-7}
        >
          {formData?.data?.isEquityBasket && (
            <LoadingButton
              variant="lightBg"
              color="dark"
              loading={updateInvestorLoading}
              onClick={() => {
                const link = document.createElement("a");
                link.href = `https://${form.getValues(
                  "domainName",
                )}.gridkey.in/preview`;
                document.body.appendChild(link);
                link.target = "_blank";
                link.click();
              }}
            >
              Preview
            </LoadingButton>
          )}
          <LoadingButton
            type="submit"
            variant="outlined"
            loading={updateInvestorLoading}
          >
            Save
          </LoadingButton>
          <LoadingButton
            variant="contained"
            onClick={() => {
              setInvestorSettingsConfirmation(true);
            }}
            loading={updateInvestorLoading}
          >
            Publish
          </LoadingButton>
        </Grid2>
      </Grid2>
      {investorSettingsConfirmation && (
        <InvestorSettingsConfirmation
          open={investorSettingsConfirmation}
          setOpen={setInvestorSettingsConfirmation}
          investorData={data?.data}
        />
      )}
    </form>
  );
};

export default WebsiteSettings;
