import { Grid2 } from "@mui/material";
import { useFetchQuery } from "hooks/useQueries";
import DashboardLayout from "layouts/DashboardLayout";
import { useParams } from "react-router-dom";
import { ADVISOR_COMPANY_MASTER_LIST } from "utils/apiRoutes";
import useDocumentTitle from "utils/useDocumentTitle";
import EditPrivateEquityTabSection from "./EditPrivateEquityTabSection";
import PrivateEquityPreviewCard from "./PrivateEquityPreviewCard";

const EditPrivateEquity = () => {
  useDocumentTitle("Update Equity");

  const { id } = useParams();

  const { data, isFetching } = useFetchQuery({
    key: ["GET_PRIVATE_EQUITY_BY_ID"],
    route: `${ADVISOR_COMPANY_MASTER_LIST}${id}/`,
  });

  return (
    <DashboardLayout title="Update Equity" loading={isFetching}>
      <Grid2 container spacing={2}>
        <Grid2 size={12}>
          <PrivateEquityPreviewCard data={data?.data} />
        </Grid2>
        <Grid2 size={12}>
          <EditPrivateEquityTabSection data={data?.data} />
        </Grid2>
      </Grid2>
    </DashboardLayout>
  );
};

export default EditPrivateEquity;
