import { LoadingButton } from "@mui/lab";
import { GridColDef } from "@mui/x-data-grid-pro";
import { GKDataGrid, GKModal } from "components";
import useGenerateQueryParams from "hooks/useGenerateQueryParams";
import { useFetchQuery } from "hooks/useQueries";
import { useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useParams } from "react-router-dom";
import { FEE_PLAN_LIST } from "utils/apiRoutes";
import { handleCapitalize } from "utils/common";
import { GKModalProps } from "utils/commonInterface";
import { handleCreateFormData } from "utils/formHelpers";
import { moneyCommaSeparator } from "utils/MathFunction";
import useDocumentTitle from "utils/useDocumentTitle";

interface Props {
  updateLoading: boolean;
  handleUpdateEquityBasket: any;
}

const EquityBasketFeePlanSelection = ({
  setOpen,
  open,
  handleUpdateEquityBasket,
  updateLoading,
}: Props & GKModalProps) => {
  useDocumentTitle("Basket Fee Plan Setup");

  const { id } = useParams();

  const form = useFormContext();

  const [selectedFeePlans, setSelectFeePlans] = useState(
    form?.getValues("feePlans"),
  );

  const requestBody = useGenerateQueryParams("feePlans");

  const { data: getFeePlans, isFetching: feePlansFetching } = useFetchQuery({
    key: ["GET_FEE_PLAN_LIST", requestBody],
    route: FEE_PLAN_LIST,
    requestBody,
  });

  const columns: GridColDef[] = [
    {
      headerName: "Fee Plan Name",
      flex: 1,
      minWidth: 150,
      field: "name",
      disableColumnMenu: true,
      valueGetter: (params) => params.row.name || "-",
    },
    {
      headerName: "Revenue Products",
      flex: 1,
      minWidth: 150,
      field: "revenueProducts",
      disableColumnMenu: true,
      valueGetter: (params) =>
        handleCapitalize(
          params.row.revenueProducts
            ?.map((dataItem: any) => dataItem.replace("_", " "))
            .join(", ") || "-",
        ),
    },
    {
      headerName: "AUM Mapped",
      flex: 1,
      minWidth: 150,
      field: "totalAumMapped",
      disableColumnMenu: true,
      valueGetter: (param) => param?.row?.totalAumMapped,
      valueFormatter: (params) => moneyCommaSeparator(params.value),
    },
    {
      headerName: "Portfolio Mapped",
      flex: 1,
      minWidth: 150,
      field: "totalPortfolio",
      disableColumnMenu: true,
      valueGetter: (param) => param?.row?.totalPortfolio,
    },
    {
      headerName: "Fee Billed",
      flex: 1,
      minWidth: 150,
      field: "totalFeeGenerated",
      disableColumnMenu: true,
      valueGetter: (param) => param?.row?.totalFeeGenerated,
      valueFormatter: (params) => moneyCommaSeparator(params.value),
    },
    {
      headerName: "Fee Collected",
      flex: 1,
      minWidth: 150,
      field: "totalFeeCollected",
      disableColumnMenu: true,
      valueGetter: (param) => param?.row?.totalFeeCollected,
      valueFormatter: (params) => moneyCommaSeparator(params.value || 0),
    },
  ];

  return (
    <GKModal
      open={open}
      setOpen={setOpen}
      modalTitle="Add Fee Plans To Basket"
      footer={
        <LoadingButton
          variant="contained"
          loading={updateLoading}
          onClick={() => {
            const feePlansList = getFeePlans?.data?.data
              ?.filter((data: any) => selectedFeePlans?.includes(data?.id))
              ?.map((feeItem: any) => {
                return {
                  id: feeItem?.id,
                  name: feeItem?.name,
                  status: feeItem?.status,
                  managementFee: {
                    name: "Management Fee",
                    fee: feeItem?.managementFee?.fee,
                  },
                  performanceFee: {
                    name: "Management Fee",
                    fee: feeItem?.performanceFee?.fee,
                  },
                };
              });
            form.setValue("feePlansList", feePlansList);
            setTimeout(() => {
              if (id) {
                handleUpdateEquityBasket(
                  handleCreateFormData({
                    values: form.getValues(),
                    dirtyFields: form.formState.dirtyFields,
                  }),
                );
              } else {
                setOpen(false);
              }
            }, 100);
          }}
        >
          Save
        </LoadingButton>
      }
    >
      <Controller
        control={form.control}
        name="feePlans"
        render={({ field }) => {
          return (
            <GKDataGrid
              name="feePlans"
              columns={columns}
              density="compact"
              checkboxSelection
              loading={feePlansFetching}
              maxHeight={"calc(100vh - 312px)"}
              rowSelectionModel={field.value}
              totalCount={getFeePlans?.data?.totalCount || 0}
              rows={
                getFeePlans?.data?.data?.filter((data: any) => data?.status) ||
                []
              }
              onRowSelectionModelChange={(params: any) => {
                field?.onChange(params);
                setSelectFeePlans(params);
              }}
            />
          );
        }}
      />
    </GKModal>
  );
};

export default EquityBasketFeePlanSelection;
