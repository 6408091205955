import { Divider, Grid2, Show, Tab, Tabs } from "components";
import { useFetchQuery } from "hooks/useQueries";
import { EquitybasketFormInterface } from "interfaces/EquityBasket";
import DashboardLayout from "layouts/DashboardLayout";
import { useState } from "react";
import { useParams } from "react-router-dom";
import useDocumentTitle from "utils/useDocumentTitle";
import EquityBasketAssets from "../EquityBasketAssets";
import ModelPortfolioVersion from "../ModelPortfolioVersion";

const EquityBasketManage = () => {
  const [tabIndex, setTabIndex] = useState(0);

  const { id } = useParams();

  /** ========================================================================================================= */
  const { data, isFetching } = useFetchQuery({
    key: ["GET_MODEL_PORTFOLIO_DETAILS_DATA"],
    route: `/model_portfolio/${id}/details/`,
    enabled: Boolean(id),
  });

  const basketDetails: EquitybasketFormInterface = data?.data;

  useDocumentTitle(basketDetails?.name || "Equity Basket Manage");

  const allTabs = [
    {
      name: "Model Portfolio",
      element: <ModelPortfolioVersion />,
      show: basketDetails?.basketType === "Model",
    },
    {
      name: "Assets",
      element: <EquityBasketAssets />,
      show: true,
    },
  ];

  return (
    <DashboardLayout title="Equity Basket Manage" loading={isFetching}>
      <Grid2 container spacing={2}>
        <Grid2 size={12}>
          <Tabs
            value={tabIndex}
            onChange={(e, val) => {
              setTabIndex(val);
            }}
          >
            {allTabs
              ?.filter((item) => item?.show)
              ?.map((d, index) => {
                return <Tab label={d.name} value={index} key={index} />;
              })}
          </Tabs>
          <Divider />
        </Grid2>
        <Grid2 size={12}>
          {" "}
          {allTabs
            ?.filter((item) => item?.show)
            .map((d, i) => {
              return <Show.When isTrue={tabIndex === i}>{d.element}</Show.When>;
            })}
        </Grid2>
      </Grid2>
    </DashboardLayout>
  );
};

export default EquityBasketManage;
