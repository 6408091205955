import { GKButton, GKModal, GKTextField, Grid2 } from "components";
import { Controller, useFormContext } from "react-hook-form";
import { GKModalProps } from "utils/commonInterface";

interface Props {
  index: any;
}

const ExtraChargesModal = (props: Props & GKModalProps) => {
  const form = useFormContext();

  const { open, setOpen, index } = props;

  return (
    <GKModal
      open={open}
      setOpen={setOpen}
      modalTitle="Extra Charges"
      footer={
        <Grid2 container justifyContent={"flex-end"}>
          <GKButton
            variant="contained"
            onClick={() => {
              const getChargesValue =
                form.getValues(`transactions.${index}.totalCharges.stt`) +
                form.getValues(`transactions.${index}.totalCharges.gst`) +
                form.getValues(
                  `transactions.${index}.totalCharges.otherCharges`,
                ) +
                form.getValues(
                  `transactions.${index}.totalCharges.sebiTurnoverFees`,
                ) +
                form.getValues(
                  `transactions.${index}.totalCharges.stampCharges`,
                ) +
                form.getValues(
                  `transactions.${index}.totalCharges.transactionCharges`,
                );

              form.setValue(
                `transactions.${index}.totalTransactionCharges`,
                getChargesValue,
              );

              setOpen(false);
            }}
          >
            Save
          </GKButton>
        </Grid2>
      }
    >
      <Grid2 container spacing={1}>
        <Grid2 size={4}>
          <Controller
            name={`transactions.${index}.totalCharges.stt`}
            control={form.control}
            render={({ field }) => (
              <GKTextField
                {...field}
                placeholder="Enter STT"
                inputLabel="STT"
                type="number"
                onChange={(e: any) => field.onChange(e.target.valueAsNumber)}
              />
            )}
          />
        </Grid2>
        <Grid2 size={4}>
          <Controller
            name={`transactions.${index}.totalCharges.gst`}
            control={form.control}
            render={({ field }) => (
              <GKTextField
                {...field}
                placeholder="Enter gst"
                inputLabel="gst"
                type="number"
                onChange={(e: any) => field.onChange(e.target.valueAsNumber)}
              />
            )}
          />
        </Grid2>
        <Grid2 size={4}>
          <Controller
            name={`transactions.${index}.totalCharges.otherCharges`}
            control={form.control}
            render={({ field }) => (
              <GKTextField
                {...field}
                placeholder="Enter other Charges"
                inputLabel="other Charges"
                type="number"
                onChange={(e: any) => field.onChange(e.target.valueAsNumber)}
              />
            )}
          />
        </Grid2>
        <Grid2 size={4}>
          <Controller
            name={`transactions.${index}.totalCharges.sebiTurnoverFees`}
            control={form.control}
            render={({ field }) => (
              <GKTextField
                {...field}
                placeholder="Enter sebi Turnover Fees"
                inputLabel="sebi Turnover Fees"
                type="number"
                onChange={(e: any) => field.onChange(e.target.valueAsNumber)}
              />
            )}
          />
        </Grid2>
        <Grid2 size={4}>
          <Controller
            name={`transactions.${index}.totalCharges.stampCharges`}
            control={form.control}
            render={({ field }) => (
              <GKTextField
                {...field}
                placeholder="Enter stampCharges"
                inputLabel="stamp Charges"
                type="number"
                onChange={(e: any) => field.onChange(e.target.valueAsNumber)}
              />
            )}
          />
        </Grid2>
        <Grid2 size={4}>
          <Controller
            name={`transactions.${index}.totalCharges.transactionCharges`}
            control={form.control}
            render={({ field }) => (
              <GKTextField
                {...field}
                placeholder="Enter transactionCharges"
                inputLabel="transaction Charges"
                type="number"
                onChange={(e: any) => field.onChange(e.target.valueAsNumber)}
              />
            )}
          />
        </Grid2>
      </Grid2>
    </GKModal>
  );
};

export default ExtraChargesModal;
