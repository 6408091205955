import { Grid2, SimpleLoader } from "components";
import { useFetchQuery, useMutation } from "hooks/useQueries";
import { EMAIL_CONFIGURATION } from "utils/apiRoutes";
import axiosInstance from "utils/axiosInstance";
import useDocumentTitle from "utils/useDocumentTitle";
import EmailConfig from "./EmailConfig";
import EmailHeaderSection from "./EmailHeaderSection";

const SmtpSetup = () => {
  useDocumentTitle("Email Configuration");

  const { data, refetch, isFetching } = useFetchQuery({
    key: ["EMAIL_CONFIGURATION"],
    route: EMAIL_CONFIGURATION,
  });

  const { mutate: handleUpdate, isLoading } = useMutation({
    mutationKey: ["UPDATE_EMAIL_CONFIGURATION"],
    mutationFn: (values: any) =>
      axiosInstance.patch(
        `/email-configuration/${data?.data?.data?.id}/`,
        values,
      ),
    onSuccess: () => {
      refetch();
    },
  });

  const { mutate: handleUpdateEmailConfig, isLoading: emailConfigLoading } =
    useMutation({
      mutationKey: ["HANDLE_UPDATE_EMAIL_CONFIGURATION"],
      mutationFn: (values: any) =>
        axiosInstance.patch(
          `/email-configuration-header/${data?.data?.data?.id}/`,
          values,
        ),
      onSuccess: () => {
        refetch();
      },
    });

  if (isFetching) return <SimpleLoader />;

  return (
    <Grid2 container spacing={2}>
      <Grid2 size={12}>
        <EmailConfig
          data={data?.data?.data}
          handleUpdate={handleUpdate}
          isLoading={isLoading}
          isFetching={isFetching}
        />
      </Grid2>
      <Grid2 size={12}>
        <EmailHeaderSection
          data={data?.data?.data}
          handleUpdate={handleUpdateEmailConfig}
          isLoading={emailConfigLoading}
        />
      </Grid2>
    </Grid2>
  );
};

export default SmtpSetup;
