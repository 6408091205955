import { FormControlLabel, GKDataGrid, Switch } from "components";
import useGenerateQueryParams from "hooks/useGenerateQueryParams";
import { useFetchQuery, useMutation } from "hooks/useQueries";
import { useState } from "react";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import { CLIENT_ACCESS_BY_ID } from "utils/apiRoutes";
import axiosInstance from "utils/axiosInstance";
import { clientPageClientAccess } from "utils/columns";
import { QueryKeys } from "utils/queryKey";
import useDocumentTitle from "utils/useDocumentTitle";

const PortfolioAccess = () => {
  const { id } = useParams();

  useDocumentTitle("Client Access");

  const [employeeList, setEmployeeList]: any = useState([]);

  const [switchLoading, setSwitchLoading] = useState("");

  const [selectedEmployeeList, setSelectedEmployeeList] = useState([]);

  const requestBody = useGenerateQueryParams("clientAccess");

  const { isFetching, refetch, isRefetching } = useFetchQuery({
    key: [QueryKeys.CLIENT_ACCESS, requestBody],
    route: CLIENT_ACCESS_BY_ID(id),
    requestBody,
    success: (response: any) => {
      setEmployeeList(response?.data);
      setSelectedEmployeeList(response?.data?.employees);
    },
  });

  const { mutate: enableDisableCLient, isLoading: enableDisableCLientLodaing } =
    useMutation({
      mutationFn: (arr: any) =>
        axiosInstance.patch(`/client/access/${id}/`, {
          employees: arr,
        }),
      onSuccess: () => {
        refetch();
      },
    });

  return (
    <GKDataGrid
      columns={clientPageClientAccess(
        enableDisableCLientLodaing,
        isRefetching,
        isFetching,
        switchLoading,
        selectedEmployeeList,
        setSwitchLoading,
        enableDisableCLient,
      )}
      maxHeight={"calc(100vh - 228px)"}
      rows={employeeList?.data || []}
      loading={selectedEmployeeList?.length === 0 && isFetching}
      headerComponent={
        <FormControlLabel
          control={
            <Switch
              checked={employeeList?.enableAll}
              onChange={() => {
                axiosInstance
                  .patch(`/client/access/${id}/all_employees/`)
                  .then(() => {
                    refetch();
                  })
                  .catch((error) => {
                    toast.error(
                      error?.response?.data?.message ||
                        "Something went wrong, please try again.",
                    );
                  });
              }}
            />
          }
          label={employeeList?.enableAll ? "Disable All" : "Enable All"}
          labelPlacement="end"
        />
      }
      totalCount={employeeList.totalCount || 0}
      name="clientAccess"
      exportFile={{
        path: CLIENT_ACCESS_BY_ID(id),
        exportName: "ClientAccessList",
      }}
    />
  );
};

export default PortfolioAccess;
