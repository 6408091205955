/* eslint-disable react/no-danger */
import { GridColDef } from "@mui/x-data-grid-pro";
import {
  CgMathPlus,
  Divider,
  GKButton,
  GKClientDataGrid,
  Grid2,
  Typography,
} from "components";
import { useFetchQuery } from "hooks/useQueries";
import { useState } from "react";
import { extraColumnsField } from "utils/helpers";
import { ThemeContainer } from "utils/Theme";
import DigioDetails from "./DigioDetails";
import EstampModal from "./EstampModal";

const Digio = () => {
  const { theme } = ThemeContainer();

  const [showSheetModal, setShowSheetModal] = useState(false);

  const [selectedRow, setSelectedRow] = useState<any>(null);

  const { data: estampTags } = useFetchQuery({
    key: ["ESTAMP_TAGS_QUERY_KEY"],
    route: "/estamp-tags/",
  });

  const columns: GridColDef[] = [
    {
      ...extraColumnsField("Tag", "tags", 0, 1),
    },
    {
      ...extraColumnsField("Description", "description", 0, 1),
      renderCell: (params) => {
        return (
          <div
            dangerouslySetInnerHTML={{
              __html: params?.row?.description || "-",
            }}
          />
        );
      },
    },
  ];

  const handleRowClick = (params: any) => {
    setSelectedRow(params.row);
    setShowSheetModal(true);
  };

  return (
    <>
      <Grid2 container spacing={2}>
        <Grid2 size={3}>
          <Typography
            fontSize={16}
            fontWeight={500}
            color={theme.palette.secondaryText.main}
          >
            Estamp
          </Typography>
        </Grid2>
        <Grid2 size={9}>
          <DigioDetails />
        </Grid2>
        <Grid2 size={12}>
          <Divider />
        </Grid2>
        <Grid2 size={3}>
          <Typography
            fontSize={16}
            fontWeight={500}
            color={theme.palette.secondaryText.main}
          >
            Estamp
          </Typography>
        </Grid2>
        <Grid2 size={9}>
          <GKClientDataGrid
            name="digio"
            columns={columns}
            rows={estampTags?.data?.data || []}
            onRowClick={handleRowClick}
            density="compact"
            headerComponent={
              <GKButton
                onClick={() => {
                  setSelectedRow(null);
                  setShowSheetModal(true);
                }}
                variant="contained"
                size="small"
                startIcon={<CgMathPlus size={18} />}
              >
                Add Estamp
              </GKButton>
            }
          />
        </Grid2>
      </Grid2>
      {showSheetModal && (
        <EstampModal
          open={showSheetModal}
          setOpen={setShowSheetModal}
          sheetDetails={selectedRow}
        />
      )}
    </>
  );
};

export default Digio;
