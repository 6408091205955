import { GridColDef } from "@mui/x-data-grid-pro";
import { useAppContext } from "AppContext";
import { GKButton, GKDataGrid, Grid2, Show, Typography } from "components";
import useGenerateQueryParams from "hooks/useGenerateQueryParams";
import { useFetchQuery } from "hooks/useQueries";
import { PortfolioDetailsInterface } from "interfaces/Portfolio";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { GET_ALL_ANCHOR_TRANSACTION_BY_PORTFOLIO_ID } from "utils/apiRoutes";
import { handleReturnPositiveNegative } from "utils/colorHelper";
import { UserRoleTypes } from "utils/common";
import { extraColumnsField, matchDate } from "utils/helpers";
import { moneyCommaSeparator } from "utils/MathFunction";
import { ThemeContainer } from "utils/Theme";
import PortfolioImportModal from "./PortfolioImportModal";

interface Props {
  portfolioDetailData: PortfolioDetailsInterface;
}

const StockAsCapital = (props: Props) => {
  const { portfolioDetailData } = props;

  const { id } = useParams();

  const { theme } = ThemeContainer();

  const [openImportModal, setOpenImportModal] = useState(false);

  const {
    state: { userData },
  } = useAppContext();

  const requestBody = useGenerateQueryParams("anchorTransaction");

  const { data, isFetching } = useFetchQuery({
    key: ["GET_ALL_ANCHOR_TRANSACTION", requestBody],
    route: GET_ALL_ANCHOR_TRANSACTION_BY_PORTFOLIO_ID(id),
    requestBody,
  });

  const columns: GridColDef[] = [
    {
      ...extraColumnsField("Company Name", "name", 230, 1),
    },
    {
      ...extraColumnsField("Quantity", "rawQuantity", 130, 1),
      type: "number",
    },
    {
      ...extraColumnsField("Avg. Buy Price", "avgBuyPrice", 150, 1),
      type: "number",
      valueFormatter: (params) => moneyCommaSeparator(params.value),
    },
    {
      ...extraColumnsField("Invested Value", "investedAmount", 150, 1),
      valueFormatter: (params) => moneyCommaSeparator(params.value),
      type: "number",
    },
    {
      ...extraColumnsField("CMP", "cmp", 110, 1),
      valueFormatter: (params) => moneyCommaSeparator(params.value),
      type: "number",
    },
    {
      ...extraColumnsField("Current Value", "currentAmount", 140, 1),
      valueFormatter: (params) => moneyCommaSeparator(params.value),
      type: "number",
    },
    {
      ...extraColumnsField("Unrealised Gain", "unrealisedGain", 140, 1),
      valueFormatter: (params) => moneyCommaSeparator(params.value),
      type: "number",
      renderCell: (params) => {
        return (
          <Typography
            color={handleReturnPositiveNegative(params?.row?.unrealisedGain)}
          >
            {moneyCommaSeparator(params?.row?.unrealisedGain)}
          </Typography>
        );
      },
    },
    {
      ...extraColumnsField("Unrealised Gain %", "absoluteReturn", 150, 1),
      valueFormatter: (params) => moneyCommaSeparator(params.value),
      type: "number",
      renderCell: (params) => {
        return (
          <Typography
            color={handleReturnPositiveNegative(params?.row?.absoluteReturn)}
          >
            {moneyCommaSeparator(params?.row?.absoluteReturn)}
          </Typography>
        );
      },
    },
    {
      ...extraColumnsField("Today's Gain", "todaysGain", 150, 1),
      valueFormatter: (params) => moneyCommaSeparator(params.value),
      type: "number",
      renderCell: (params) => {
        return (
          <Typography
            color={handleReturnPositiveNegative(params?.row?.todaysGain)}
          >
            {moneyCommaSeparator(params?.row?.todaysGain)}
          </Typography>
        );
      },
    },
    {
      ...extraColumnsField("Today's Gain %", "todaysGainPercentage", 150, 1),
      renderCell: (params) => {
        return (
          <Typography
            color={handleReturnPositiveNegative(
              params?.row?.todaysGainPercentage,
            )}
          >
            {moneyCommaSeparator(params?.row?.todaysGainPercentage)}
          </Typography>
        );
      },
      type: "number",
    },
    {
      ...extraColumnsField("Weightage(%)", "weightage", 130, 1),
      valueFormatter: (params) => moneyCommaSeparator(params.value),
      type: "number",
      disableColumnMenu: true,
    },
  ];

  return (
    <Grid2 container>
      <GKDataGrid
        loading={isFetching}
        columns={columns}
        density="compact"
        rows={data?.data?.data || []}
        zeroHolding
        name="anchorTransaction"
        totalCount={data?.data?.totalCount || 0}
        headerComponent={
          <Grid2 container gap={2} justifyContent={"end"} alignItems={"center"}>
            <Typography
              fontSize={12}
              color={theme.palette.secondaryText.main}
              gap={"4px"}
              display={"flex"}
              alignItems={"center"}
            >
              Anchor Date:
              <Typography color={"#000"} fontWeight={600} fontSize={12}>
                {matchDate(portfolioDetailData?.anchorDate, "DD-MM-YYYY")}
              </Typography>
            </Typography>
            {userData?.userRole !== UserRoleTypes.client && (
              <GKButton
                variant="contained"
                onClick={() => setOpenImportModal(true)}
              >
                Import
              </GKButton>
            )}
          </Grid2>
        }
        tableName="anchor_transaction"
      />

      <Show.When isTrue={openImportModal}>
        <PortfolioImportModal
          open={openImportModal}
          setOpen={setOpenImportModal}
          anchorDate={portfolioDetailData?.anchorDate}
        />
      </Show.When>
    </Grid2>
  );
};

export default StockAsCapital;
