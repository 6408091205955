import { LoadingButton } from "@mui/lab";
import {
  ConfirmDeleteModal,
  FiTrash2,
  GKButton,
  GKModal,
  GKTextField,
  Grid2,
  IconButton,
} from "components";
import { useMutation } from "hooks/useQueries";
import { useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import axiosInstance from "utils/axiosInstance";
import { GKModalProps } from "utils/commonInterface";
import { refetchQuery } from "utils/helpers";

interface NomineeInterface {
  id?: string;
  name: string;
  percentage: number;
  relationship: string;
  client?: number;
}

interface Props {
  nomineeData: NomineeInterface[];
  clientId?: number;
}

const NomineeModal = (props: Props & GKModalProps) => {
  const { open, setOpen, nomineeData, clientId } = props;

  const [confirmationModal, setConfirmationModal] = useState(false);
  const [clientBankId, setClientBankId] = useState(0);

  const form = useForm({
    defaultValues: {
      nominees: nomineeData.length
        ? nomineeData.map((dataItem: any) => {
            return {
              ...dataItem,
              rowId: dataItem.id,
            };
          })
        : [{ name: "", percentage: "", relationship: "" }],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: "nominees",
  });

  const { mutate: handleDeleteMutation } = useMutation({
    mutationKey: ["DELETE_CLIENT_NOMINEE"],
    mutationFn: ({ id }: { id: any }) =>
      axiosInstance.delete(`/client/${id}/nominees/`),
    onSuccess: () => {
      refetchQuery("CLIENT_NOMINEE_ACCOUNT");
      setOpen(false);
    },
  });

  const { mutate: handleUpdateNominee, isLoading: nomineeLoading } =
    useMutation({
      mutationKey: ["UPDATE_CLIENT_NOMINEE"],
      mutationFn: (data: any) =>
        axiosInstance.patch(`/client/${clientId}/nominees/`, data),
      onSuccess: () => {
        refetchQuery("CLIENT_NOMINEE_ACCOUNT");

        setOpen(false);
      },
    });

  return (
    <>
      <GKModal
        modalTitle="Add Nominee"
        open={open}
        setOpen={setOpen}
        width={"60%"}
        footer={
          <Grid2 container justifyContent={"space-between"}>
            <GKButton
              onClick={() =>
                append({ name: "", percentage: "", relationship: "" })
              }
              variant="outlined"
              size="small"
              color="dark"
            >
              Add New
            </GKButton>
            <LoadingButton
              variant="contained"
              type="submit"
              form="nominee-form"
              loading={nomineeLoading}
            >
              Save
            </LoadingButton>
          </Grid2>
        }
      >
        <form
          id="nominee-form"
          onSubmit={form.handleSubmit((values) => handleUpdateNominee(values))}
        >
          <Grid2 container>
            {fields.map((row: any, index: number) => (
              <Grid2
                container
                size={12}
                spacing={1}
                my={1}
                display={"flex"}
                alignItems={"center"}
              >
                <Grid2 size={3}>
                  <Controller
                    control={form.control}
                    name={`nominees.${index}.name`}
                    render={({ field }) => (
                      <GKTextField {...field} inputLabel="Nominee Name" />
                    )}
                  />
                </Grid2>
                <Grid2 size={3}>
                  <Controller
                    control={form.control}
                    name={`nominees.${index}.percentage`}
                    render={({ field }) => (
                      <GKTextField {...field} inputLabel="Nominee Percentage" />
                    )}
                  />
                </Grid2>
                <Grid2 size={3}>
                  <Controller
                    control={form.control}
                    name={`nominees.${index}.relationship`}
                    render={({ field }) => (
                      <GKTextField {...field} inputLabel="Relationship" />
                    )}
                  />
                </Grid2>
                <Grid2 size={3} display={"flex"} justifyContent={"flex-end"}>
                  <IconButton
                    className="outline"
                    style={{
                      marginTop: 18,
                    }}
                    color="error"
                    disabled={fields?.length === 1}
                    onClick={() => {
                      if (row?.rowId) {
                        setClientBankId(row?.rowId);
                        setConfirmationModal(true);
                      } else {
                        remove(index);
                      }
                    }}
                  >
                    <FiTrash2 size={18} />
                  </IconButton>
                </Grid2>
              </Grid2>
            ))}
          </Grid2>
        </form>
      </GKModal>

      {confirmationModal && (
        <ConfirmDeleteModal
          open={confirmationModal}
          setOpen={setConfirmationModal}
          onDelete={() => handleDeleteMutation({ id: clientBankId })}
          title={"Nominee"}
          isDelete
        />
      )}
    </>
  );
};

export default NomineeModal;
