import { yupResolver } from "@hookform/resolvers/yup";
import { useAppContext } from "AppContext";
import { useMutation } from "hooks/useQueries";
import { useForm } from "react-hook-form";
import axiosInstance from "utils/axiosInstance";
import { refetchQuery } from "utils/helpers";
import { QueryKeys } from "utils/queryKey";
import { addPortfolioGroupSchema } from "utils/validationSchema";

const useAddPortfolioGroup = ({ setOpen }: any) => {
  const {
    state: { portfolios },
  } = useAppContext();

  // =================================================================================================
  const { mutate: handleAddGroup, isLoading: addGroupLoading } = useMutation({
    mutationFn: (state: any) =>
      axiosInstance.post(`/portfolio-groups/`, {
        ...state,
        portfolios: state?.portfolios?.map((data: any) => data?.value),
      }),
    onSuccess: () => {
      setOpen(false);
      refetchQuery(QueryKeys.PORTFOLIO_GROUP_LIST);
      refetchQuery("FETCH_ALL_PORTFOLIO_GROUP");
    },
  });

  // =================================================================================================
  const form = useForm({
    defaultValues: {
      name: "",
      portfolios: [],
    },
    resolver: yupResolver(addPortfolioGroupSchema),
  });

  const onSubmit = (values: any) => handleAddGroup(values);

  return {
    portfolios,
    addGroupLoading,
    form,
    onSubmit,
  };
};

export default useAddPortfolioGroup;
