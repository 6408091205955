import { LoadingButton } from "@mui/lab";
import { useAppContext } from "AppContext";
import {
  GKSearchSelect,
  GKTextField,
  Grid2,
  Show,
  SimpleLoader,
  TextField,
} from "components";
import { useFetchQuery, useMutation } from "hooks/useQueries";
import { PaymentGatewayFormValues } from "interfaces/PaymentGateway";
import { useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import axiosInstance from "utils/axiosInstance";
import { handleReturnAutocompletePlaceholder } from "utils/StringFunctions";
import useDocumentTitle from "utils/useDocumentTitle";
import { validateNotWhitespaceOnly } from "utils/validationSchema";
import { PAYMENT_GATEWAY_TYPE } from "./helper";
import VerifyAccountModal from "./VerifyAccountModal";

const ConfigurePaymentForm = () => {
  useDocumentTitle("Settings");

  const {
    state: { userData },
  } = useAppContext();

  const [verifyModal, setVerifyModal] = useState(false);

  const { data, isLoading } = useFetchQuery({
    key: ["PAYMENT_GATEWAY_ACCOUNT_LIST"],
    route: `/company/${userData?.companyId}/payment-gateway-configurations/`,
  });

  const form = useForm<PaymentGatewayFormValues>({
    defaultValues: {
      paymentGateway: "",
      merchantId: "",
      clientKey: "",
      clientSecret: "",
      token: "",
      maxAmount: 0,
    },
    values: data?.data?.data,
  });

  const { mutate: sendOtp, isLoading: sendOtpLoading } = useMutation({
    mutationKey: ["PAYMENT_GATEWAY_SEND_OTP"],
    mutationFn: (data: any) => axiosInstance.post(`/company-otp/${data}/`),
    onSuccess: () => {
      setVerifyModal(true);
    },
  });

  if (isLoading) return <SimpleLoader />;

  return (
    <>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(() => sendOtp("send"))}>
          <Grid2 container size={12} spacing={2}>
            <Grid2 size={6}>
              <Controller
                name="paymentGateway"
                control={form.control}
                rules={{
                  required: {
                    value: true,
                    message: "Please select payment gateway",
                  },
                }}
                render={({ field, fieldState: { error } }) => (
                  <GKSearchSelect
                    {...field}
                    requiredField
                    disableClearable={false}
                    inputLabel="Payment Gateway"
                    options={PAYMENT_GATEWAY_TYPE || []}
                    value={
                      PAYMENT_GATEWAY_TYPE.find(
                        (dataItem) => dataItem?.value === field?.value,
                      )?.name || ""
                    }
                    onChange={(event, val) => field.onChange(val?.value)}
                    getOptionLabel={(option) => option.name || option}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder={handleReturnAutocompletePlaceholder({
                          placeholder: "Select payment gateway",
                          value: field.value,
                        })}
                        error={!!error}
                        helperText={error?.message}
                      />
                    )}
                  />
                )}
              />
            </Grid2>
            <Grid2 size={6}>
              <Controller
                control={form.control}
                name="merchantId"
                rules={{
                  required: {
                    value: true,
                    message: "Please enter merchant id",
                  },
                  validate: validateNotWhitespaceOnly,
                }}
                render={({ field, fieldState: { error } }) => (
                  <GKTextField
                    {...field}
                    requiredField
                    inputLabel="Merchant Id"
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              />
            </Grid2>

            <Grid2 size={6}>
              <Controller
                control={form.control}
                name="clientKey"
                rules={{
                  required: {
                    value: true,
                    message: "Please enter client key",
                  },
                  validate: validateNotWhitespaceOnly,
                }}
                render={({ field, fieldState: { error } }) => (
                  <GKTextField
                    {...field}
                    requiredField
                    inputLabel="Client Key"
                    error={!!error}
                    helperText={error?.message}
                    tooltipTitle="App ID"
                  />
                )}
              />
            </Grid2>
            <Grid2 size={6}>
              <Controller
                control={form.control}
                name="clientSecret"
                rules={{
                  required: {
                    value: true,
                    message: "Please enter client secret",
                  },
                  validate: validateNotWhitespaceOnly,
                }}
                render={({ field, fieldState: { error } }) => (
                  <GKTextField
                    {...field}
                    requiredField
                    inputLabel="Client Secret Id"
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              />
            </Grid2>

            <Grid2 size={6}>
              <Controller
                control={form.control}
                name="maxAmount"
                render={({ field }) => (
                  <GKTextField
                    {...field}
                    type="number"
                    inputLabel="Maximum amount allowed for subscription"
                    onChange={(
                      event: React.ChangeEvent<
                        HTMLInputElement | HTMLTextAreaElement
                      >,
                    ) => {
                      if (event.target instanceof HTMLInputElement) {
                        const { valueAsNumber } = event.target;
                        field.onChange(valueAsNumber);
                      }
                    }}
                  />
                )}
              />
            </Grid2>

            <Grid2
              size={12}
              sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}
            >
              <LoadingButton
                variant="contained"
                loading={sendOtpLoading}
                type="submit"
              >
                Submit
              </LoadingButton>
            </Grid2>
          </Grid2>
        </form>

        <Show.When isTrue={verifyModal}>
          <VerifyAccountModal
            open={verifyModal}
            setOpen={setVerifyModal}
            companyId={userData?.companyId}
          />
        </Show.When>
      </FormProvider>
    </>
  );
};

export default ConfigurePaymentForm;
