import { LoadingButton } from "@mui/lab";
import { GKModal, GKSearchSelect, Grid2, TextField } from "components";
import { useFetchQuery, useMutation } from "hooks/useQueries";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { ROLE_WISE_PERMISSION } from "utils/apiRoutes";
import axiosInstance from "utils/axiosInstance";
import { GKModalProps } from "utils/commonInterface";
import { refetchQuery } from "utils/helpers";
import { handleReturnAutocompletePlaceholder } from "utils/StringFunctions";

interface Props {
  getAssignRoleApi: {
    key: string[];
    route: string;
  };
  roleType: string;
  refetchQueryName: string;
}

const AssignRoleModal = (props: GKModalProps & Props) => {
  const { open, setOpen, getAssignRoleApi, roleType, refetchQueryName } = props;

  const [roleList, setRoleList] = useState([]);

  const { data } = useFetchQuery({
    key: getAssignRoleApi.key,
    route: getAssignRoleApi?.route,
  });

  const { isFetching } = useFetchQuery({
    key: ["LIST_OF_ROLE"],
    route: ROLE_WISE_PERMISSION,
    success: (response: any) =>
      setRoleList(
        response.data.data
          .filter(
            (dataItem: { permissionType: string }) =>
              dataItem.permissionType === roleType,
          )
          .map((role: { name: string; id: string | number }) => {
            return {
              name: role.name,
              value: role.id,
            };
          }),
      ),
  });

  const { mutate: handleAssignRole, isLoading: assignRoleLoading } =
    useMutation({
      mutationFn: (data: { role: boolean }) =>
        axiosInstance.patch(getAssignRoleApi?.route, data),
      onSuccess: () => {
        setOpen(false);
        refetchQuery(refetchQueryName);
      },
    });

  const form = useForm({
    defaultValues: {
      role: "",
    },
    values: data?.data,
  });

  return (
    <GKModal
      open={open}
      setOpen={setOpen}
      modalTitle={"Assign Role"}
      width={"40%"}
      footer={
        <Grid2 container justifyContent={"flex-end"} gap={1}>
          <LoadingButton
            loading={assignRoleLoading}
            type="submit"
            variant="contained"
            size="medium"
            form="assign-role-form"
          >
            Update
          </LoadingButton>
        </Grid2>
      }
    >
      <form
        id="assign-role-form"
        onSubmit={form.handleSubmit((values) => handleAssignRole(values))}
      >
        <Grid2 container>
          <Grid2 size={12}>
            <Controller
              control={form.control}
              name="role"
              render={({ field }) => {
                return (
                  <GKSearchSelect
                    {...field}
                    loading={isFetching}
                    disableClearable
                    onChange={(e, val) => field.onChange(val?.value)}
                    inputLabel="Select Role"
                    options={roleList || []}
                    value={
                      roleList?.find((role) => role.value === field.value)
                        ?.name || ""
                    }
                    getOptionLabel={(option) => option?.name || option}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder={handleReturnAutocompletePlaceholder({
                          placeholder: "Select Role",
                          value: field.value,
                        })}
                      />
                    )}
                  />
                );
              }}
            />
          </Grid2>
        </Grid2>
      </form>
    </GKModal>
  );
};

export default AssignRoleModal;
