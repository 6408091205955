import { useAppContext } from "AppContext";
import {
  Divider,
  GKDatePicker,
  GKSearchSelect,
  GKTextField,
  Grid2,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Typography,
} from "components";
import dayjs from "dayjs";
import { sumBy } from "lodash";
import { Controller, useFormContext } from "react-hook-form";
import { useLocation, useParams } from "react-router-dom";
import { paletteLightTheme } from "utils/ColorTheme";
import { numberToCurrency } from "utils/MathFunction";
import { handleReturnAutocompletePlaceholder } from "utils/StringFunctions";
import { ThemeContainer } from "utils/Theme";
import { schema } from "utils/validationSchema";

interface Transaction {
  transactionType: string;
  quantity?: number | string;
  price?: number | string;
  totalTransactionCharges?: number | string;
  brokerage?: number | string;
}

const EquityFormHeader = () => {
  const form = useFormContext();
  const { theme } = ThemeContainer();

  const { state } = useLocation();
  const { id } = useParams();

  const {
    state: { portfolios },
  } = useAppContext();

  return (
    <Grid2 container spacing={2}>
      <Grid2 size={state?.transactionType === "OTHER-ASSETS" ? 4 : 3}>
        <Controller
          name="portfolio"
          control={form.control}
          rules={{
            required: {
              value: !Number(state?.id),
              message: "Please select portfolio",
            },
          }}
          render={({ field, fieldState: { error } }) => {
            return (
              <>
                {!!id || !!state?.id ? (
                  <GKTextField
                    inputLabel="Select Portfolio"
                    disabled
                    value={
                      portfolios?.find(
                        (data: any) => +data?.value === +field.value,
                      )?.name
                    }
                  />
                ) : (
                  <GKSearchSelect
                    {...field}
                    requiredField
                    disableClearable
                    inputLabel="Select Portfolio"
                    renderOption={(props, option) => {
                      return (
                        <li
                          {...props}
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                          }}
                        >
                          <Typography fontWeight={600}>
                            {option.clientName}
                          </Typography>
                          <Typography>{option.name}</Typography>
                        </li>
                      );
                    }}
                    options={portfolios || []}
                    getOptionLabel={(option) => option?.name || option}
                    onChange={(event, val) => field.onChange(val?.value)}
                    value={
                      portfolios?.find(
                        (data: any) => +data?.value === +field.value,
                      )?.name
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder={handleReturnAutocompletePlaceholder({
                          placeholder: "Select Portfolio",
                          value: field.value,
                        })}
                        error={!!error}
                        helperText={error?.message}
                      />
                    )}
                  />
                )}
              </>
            );
          }}
        />
      </Grid2>
      <Grid2 size={state?.transactionType === "OTHER-ASSETS" ? 4 : 3}>
        <Controller
          name="date"
          control={form.control}
          rules={{
            required: {
              value: true,
              message: "Please select date",
            },
            validate: async (value) => {
              try {
                await schema("date").validate({
                  date: dayjs(value).format("YYYY-MM-DD"),
                });
              } catch (error: any) {
                return error.message;
              }

              return {};
            },
          }}
          render={({ field, fieldState: { error } }) => (
            <GKDatePicker
              requiredField
              inputLabel="Trade Date"
              name="date"
              value={dayjs(field.value)}
              onChange={(value) => {
                field.onChange(dayjs(value).format("YYYY-MM-DD"));
              }}
              disableFuture
              maxDate={dayjs()}
              slotProps={{
                textField: {
                  error: !!error,
                  helperText: error?.message,
                },
              }}
            />
          )}
        />
      </Grid2>
      <Grid2 size={state?.transactionType === "OTHER-ASSETS" ? 4 : 3}>
        <Controller
          name={"brokerName"}
          control={form.control}
          render={({ field }) => (
            <GKTextField {...field} inputLabel={"Broker Name"} />
          )}
        />
      </Grid2>
      {state?.transactionType !== "OTHER-ASSETS" && (
        <Grid2 size={3}>
          <Controller
            name="contractNoteNum"
            control={form.control}
            render={({ field }) => (
              <GKTextField {...field} inputLabel="Contract Note Number" />
            )}
          />
        </Grid2>
      )}
      <Grid2 size={12}>
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell
                  style={{
                    background: "#F8FAFC",
                    padding: 16,
                    borderBottom: "none",
                    borderRight: `1px solid ${paletteLightTheme.palette.border.main}`,
                  }}
                >
                  <div
                    style={{
                      gap: 8,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      fontWeight={500}
                      fontSize={14}
                      color={theme.palette.secondaryText.main}
                    >
                      Total Brokerage
                    </Typography>
                    <Typography fontWeight={600} fontSize={16}>
                      {numberToCurrency(
                        sumBy(
                          form.getValues("transactions"),
                          (data: any) => +data?.brokerage || 0,
                        ),
                      )}
                    </Typography>
                  </div>
                </TableCell>
                <TableCell
                  style={{
                    padding: 16,
                    background: "#F8FAFC",
                    borderBottom: "none",
                    borderRight: `1px solid ${paletteLightTheme.palette.border.main}`,
                  }}
                >
                  <div
                    style={{
                      gap: 8,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      fontWeight={500}
                      fontSize={14}
                      color={theme.palette.secondaryText.main}
                    >
                      Total Other Charges
                    </Typography>

                    <Typography fontWeight={600} fontSize={16}>
                      {numberToCurrency(
                        sumBy(
                          form.getValues("transactions"),
                          (data: any) => +data?.totalTransactionCharges || 0,
                        ),
                      )}
                    </Typography>
                  </div>
                </TableCell>
                <TableCell
                  style={{
                    padding: 16,
                    background: "#F8FAFC",
                    borderBottom: "none",
                  }}
                >
                  <div
                    style={{
                      gap: 8,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      fontWeight={500}
                      fontSize={14}
                      color={theme.palette.secondaryText.main}
                    >
                      Total Amount
                    </Typography>
                    <Typography fontWeight={600} fontSize={16}>
                      {numberToCurrency(
                        sumBy(
                          form.getValues("transactions") || [],
                          (dataItem: Transaction) => {
                            const quantity = Number(dataItem?.quantity) || 0;
                            const price = Number(dataItem?.price) || 0;
                            const totalTransactionCharges =
                              Number(dataItem?.totalTransactionCharges) || 0;
                            const brokerage = Number(dataItem?.brokerage) || 0;

                            if (
                              ["SELL", "BUY_BACK"].includes(
                                dataItem?.transactionType,
                              )
                            ) {
                              return (
                                quantity * price -
                                (totalTransactionCharges + brokerage)
                              );
                            }
                            return (
                              quantity * price +
                              (totalTransactionCharges + brokerage)
                            );
                          },
                        ),
                      )}
                    </Typography>
                  </div>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid2>
      <Grid2 size={12}>
        <Divider />
      </Grid2>
    </Grid2>
  );
};

export default EquityFormHeader;
