import { GridColDef } from "@mui/x-data-grid-pro";
import {
  GKButton,
  GKClientDataGrid,
  Grid2,
  RiDownloadLine,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "components";
import GKCharts from "components/GKCharts";
import dayjs from "dayjs";
import Highcharts from "highcharts/highstock";
import { useFetchQuery, useMutation } from "hooks/useQueries";
import DashboardLayout from "layouts/DashboardLayout";
import toast from "react-hot-toast";
import { useLocation, useParams } from "react-router-dom";
import { moneyCommaSeparator, numberToCurrency } from "utils/MathFunction";
import { ThemeContainer } from "utils/Theme";
import { NAV_LIST_BY_PORTFOLIO_ID } from "utils/apiRoutes";
import axiosInstance from "utils/axiosInstance";
import { handleDowloadFile } from "utils/fileHelper";
import { extraColumnsField, formatYAxisLabel, matchDate } from "utils/helpers";
import { QueryKeys } from "utils/queryKey";
import useDocumentTitle from "utils/useDocumentTitle";

const NAV = () => {
  useDocumentTitle("NAV");

  const { id } = useParams();
  const { state } = useLocation();

  const { theme } = ThemeContainer();

  const { data: navData, isFetching } = useFetchQuery({
    key: [QueryKeys.PORTFOLIO_NAV_LIST, id],
    route: NAV_LIST_BY_PORTFOLIO_ID(id),
  });

  const { mutate } = useMutation({
    mutationKey: ["EXPORT_NAV_DATA"],
    mutationFn: ({ id }: { id: any }) =>
      axiosInstance.get(`${NAV_LIST_BY_PORTFOLIO_ID(id)}export_csv/`),
    onSuccess: (response) => {
      handleDowloadFile({
        fileData: response.data,
        applicaionType: "text/csv",
        fileName: `NavList`,
        extensionType: "csv",
      });
      toast.success("Your file is successfully downloaded.");
    },
  });

  const NAVcolumns: GridColDef[] = [
    {
      ...extraColumnsField("Date", "date", 130, 0),
      valueFormatter: (value) => matchDate(value.value),
      renderCell: (item: any) => (
        <Typography>
          {dayjs(item?.row?.date).format("DD MMM YYYY") || "N/A"}
        </Typography>
      ),
    },
    {
      ...extraColumnsField("NAV", "nav", 60, 0),
      type: "number",
      renderCell: (item: any) => (
        <Typography>{moneyCommaSeparator(item?.row?.nav) || "-"}</Typography>
      ),
    },
    {
      ...extraColumnsField("Units", "units", 60, 0),
      type: "number",
      renderCell: (item: any) => (
        <Typography>{moneyCommaSeparator(item?.row?.units) || "-"}</Typography>
      ),
    },
    {
      ...extraColumnsField("Daily Ledger Balance", "cashBalance", 150, 0),
      type: "number",
      valueGetter: (item) => item?.row?.avlBal || "0",
      valueFormatter: (item) => moneyCommaSeparator(item?.value) || "0",
    },
    {
      ...extraColumnsField("Current Value", "currentValue", 130, 0),
      type: "number",
      renderCell: (item: any) => (
        <Typography>
          {moneyCommaSeparator(item?.row?.currentValue) || "-"}
        </Typography>
      ),
    },
    {
      ...extraColumnsField("Portfolio Value", "portfolioValue", 130, 0),
      type: "number",
      renderCell: (item: any) => (
        <Typography>
          {moneyCommaSeparator(item?.row?.portfolioValue) || "-"}
        </Typography>
      ),
    },
  ];

  return (
    <DashboardLayout title="Portfolio NAV" loading={isFetching}>
      <Grid2 container spacing={2}>
        <Grid2 size={12}>
          <TableContainer>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell
                    style={{ padding: "16px" }}
                    width={"25%"}
                    sx={{
                      borderRight: "1px solid #E3E8EF",
                      p: 100,
                    }}
                  >
                    <Typography
                      color={theme.palette.secondaryText.main}
                      fontSize={12}
                    >
                      Portfolio Name
                    </Typography>
                    <Typography fontWeight={600} fontSize={18}>
                      {state?.portfolioName}
                    </Typography>
                  </TableCell>
                  <TableCell
                    style={{ padding: "16px" }}
                    width={"25%"}
                    sx={{
                      borderRight: "1px solid #E3E8EF",
                    }}
                  >
                    <Typography
                      color={theme.palette.secondaryText.main}
                      fontSize={12}
                    >
                      Portfolio Code
                    </Typography>
                    <Typography fontWeight={600} fontSize={18}>
                      {state?.portfolioCode}
                    </Typography>
                  </TableCell>
                  <TableCell
                    style={{
                      padding: "16px",
                    }}
                    width={"25%"}
                    sx={{
                      borderRight: "1px solid #E3E8EF",
                    }}
                  >
                    <Typography
                      color={theme.palette.secondaryText.main}
                      fontSize={12}
                    >
                      Ledger Balance
                    </Typography>
                    <Typography fontWeight={600} fontSize={18}>
                      {numberToCurrency(state?.ledgerBalance)}
                    </Typography>
                  </TableCell>
                  <TableCell
                    style={{
                      padding: "16px",
                    }}
                    width={"25%"}
                  >
                    <Typography
                      color={theme.palette.secondaryText.main}
                      fontSize={12}
                    >
                      Last Cash Entry Date
                    </Typography>
                    <Typography fontWeight={600} fontSize={18}>
                      {matchDate(state?.lastCashDate)}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid2>
        <Grid2
          size={12}
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Typography fontSize={16} fontWeight={600}>
            Portfolio Metrics
          </Typography>
          <GKButton
            style={{
              padding: "6px 10px",
              color: theme.palette.secondaryText.main,
              border: "1px solid #E3E8EF",
              whiteSpace: "nowrap",
            }}
            startIcon={<RiDownloadLine size={14} />}
            onClick={() => mutate({ id: id })}
          >
            Export NAVs
          </GKButton>
        </Grid2>
        <Grid2 size={6}>
          <GKClientDataGrid
            name="navData"
            columns={NAVcolumns}
            rows={navData?.data || []}
            loading={isFetching}
            showHeaderGrid={false}
            maxHeight={`calc(100vh - 300px)`}
            density="compact"
          />
        </Grid2>
        <Grid2
          size={6}
          p={2}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <GKCharts
            navigator
            chartOptions={{
              title: {
                text: "NAV Line Chart",
                align: "left",
              },
              chart: {
                borderRadius: 5,
                backgroundColor: theme.palette.light.main,
                height: 450,
                spacing: [10, 10, 10, 10],
                marginRight: 50,
                style: {
                  fontFamily: "Inter",
                },
              },
              tooltip: {
                formatter(this: any) {
                  const formattedDate = Highcharts.dateFormat(
                    "%b %e, %Y",
                    this.x,
                  );

                  let content = `<b>${formattedDate}</b><br/>`;

                  this.points.forEach((point: any) => {
                    content += `<span style="color:${
                      point.color
                    }">\u25CF</span> ${
                      point.series.name
                    }: <b>${moneyCommaSeparator(point.y)}</b><br/>`;
                  });

                  return content;
                },
                pointFormatter(this: any) {
                  return `<span style="color:${this?.series?.color}">${
                    this?.series?.name
                  }</span>: ${moneyCommaSeparator(this?.y)}<br/>`;
                },
                valueDecimals: 2,
                split: true,
              },
              plotOptions: {
                series: {
                  states: {
                    hover: {
                      enabled: false,
                    },
                  },
                  animation: false,
                  fillColor: {
                    linearGradient: [0, 0, 0, 300],
                    stops: [
                      [0, Highcharts.getOptions().colors[0]],
                      [
                        1,
                        Highcharts.color(Highcharts.getOptions().colors[0])
                          .setOpacity(0)
                          .get("rgba"),
                      ],
                    ],
                  },
                },
              },
              yAxis: {
                opposite: false,
                gridLineColor: `${theme.palette.border.main}44`,
                color: theme.palette.error.main,
                labels: {
                  style: {
                    color: theme.palette.primary.main,
                    fontSize: "12px",
                    fontWeight: "bold",
                  },
                  formatter(this: any) {
                    return formatYAxisLabel(this?.value);
                  },
                },
              },
              accessibility: {
                enabled: false,
              },
              exporting: {
                enabled: false,
              },
              credits: {
                text: "",
                enabled: false,
              },
              legend: {
                enabled: true,
                layout: "horizontal",
                align: "center",
                verticalAlign: "top",
                itemStyle: {
                  color: theme.palette.dark.main,
                  fontWeight: "bold",
                },
                symbolWidth: 20,
                symbolHeight: 10,
              },
              xAxis: {
                type: "date",
                dateTimeLabelFormats: {
                  day: "%b %e, %Y",
                  week: "%b %e, %Y",
                  month: "%b %Y",
                  year: "%Y",
                },
                labels: {
                  style: {
                    color: theme.palette.primary.main,
                    fontSize: "12px",
                    fontWeight: "bold",
                  },
                  formatter(this: any) {
                    return Highcharts.dateFormat("%b %e, %Y", this.value);
                  },
                },
              },
              responsive: {
                rules: [
                  {
                    condition: {
                      maxWidth: 500,
                    },
                    chartOptions: {
                      legend: {
                        layout: "horizontal",
                        align: "center",
                        verticalAlign: "bottom",
                      },
                    },
                  },
                ],
              },
              rangeSelector: {
                enabled: true,
                inputStyle: {
                  color: theme.palette.primary.main,
                  fontWeight: "normal",
                },
                labelStyle: {
                  color: theme.palette.primary.main,
                  fontWeight: "normal",
                },
                buttonTheme: {
                  width: 50,
                  fill: `#ffffff`,
                  style: {
                    background: theme.palette.light.main,
                    color: theme.palette.grey[600],
                    fontWeight: "normal",
                    textTransform: "uppercase",
                  },
                  states: {
                    select: {
                      fill: `#F8FAFC`,
                      style: {
                        fontWeight: "normal",
                        color: theme.palette.grey[900],
                      },
                    },
                  },
                },
              },
              navigator: {
                enabled: true,
                height: 30, // Adjust the height as needed
                xAxis: {
                  type: "datetime",
                  dateTimeLabelFormats: {
                    day: "%e of %b, %Y",
                  },
                },
              },
              series: [
                {
                  type: "area",
                  name: "NAV",
                  color: theme.palette.primary.main,
                  data: navData?.data?.map((item: any) => {
                    return [new Date(item?.date).getTime(), item?.nav];
                  }),
                },
              ],
            }}
          />
        </Grid2>
      </Grid2>
    </DashboardLayout>
  );
};

export default NAV;
