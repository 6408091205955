import { LoadingButton } from "@mui/lab";
import {
  GKButton,
  GKDatePicker,
  GKModal,
  GKSelect,
  GKTextArea,
  GKTextField,
  Grid2,
  MenuItem,
} from "components";
import dayjs from "dayjs";
import { useFetchQuery, useMutation } from "hooks/useQueries";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { GET_ALL_NOTE_TYPE, GET_LEAD_DATA_BY_ID } from "utils/apiRoutes";
import axiosInstance from "utils/axiosInstance";
import { GKModalProps } from "utils/commonInterface";
import { refetchQuery } from "utils/helpers";
import { QueryKeys } from "utils/queryKey";
import AddNoteTypeModal from "./AddNoteTypeModal";

const AddNewNotes = ({
  open,
  setOpen,
  leadId,
  refetch,
  noteData,
}: any & GKModalProps) => {
  const [openNoteModal, setOpenNoteModal] = useState(false);

  const { id } = useParams();

  const { data: noteType } = useFetchQuery({
    key: [QueryKeys.NOTE_TYPE],
    route: GET_ALL_NOTE_TYPE,
  });

  const { data: getNote } = useFetchQuery({
    key: ["GET_LEAD_DATA_BY_ID"],
    retry: 0,
    route: GET_LEAD_DATA_BY_ID(leadId || id, noteData),
    enabled: Boolean(noteData),
  });

  const { mutate: handleNoteCreate, isLoading: createNoteLoading } =
    useMutation({
      mutationFn: (data: any) =>
        axiosInstance.post("/notes/", { lead: leadId || id, ...data }),
      onSuccess: () => {
        refetch();
        setOpen(false);
        refetchQuery(QueryKeys.GET_LEAD);
      },
    });

  const { mutate: handleNoteUpdate, isLoading: updateNoteLoading } =
    useMutation({
      mutationFn: (data: any) =>
        axiosInstance.patch(`/notes/${noteData}/`, {
          lead: leadId || id,
          ...data,
        }),
      onSuccess: () => {
        refetch();
        setOpen(false);
        refetchQuery(QueryKeys.GET_LEAD);
      },
    });

  const form = useForm({
    defaultValues: {
      title: getNote?.data?.data?.title || "",
      note: getNote?.data?.data?.note || "",
      noteType: getNote?.data?.data?.noteType || "",
      noteDate: getNote?.data?.data?.noteDate || "",
    },
    values: {
      title: getNote?.data?.data?.title || "",
      note: getNote?.data?.data?.note || "",
      noteType: getNote?.data?.data?.noteType || "",
      noteDate: getNote?.data?.data?.noteDate || "",
    },
  });

  return (
    <>
      <GKModal
        modalTitle={noteData ? "Update Note" : "Add New Note"}
        open={open}
        setOpen={setOpen}
        footer={
          <Grid2 size={12} textAlign={"right"}>
            <LoadingButton
              variant="contained"
              size="medium"
              type="submit"
              form="note-form"
              loading={createNoteLoading || updateNoteLoading}
            >
              {noteData ? "Update Note" : "Add Note"}
            </LoadingButton>
          </Grid2>
        }
      >
        <form
          id="note-form"
          onSubmit={form.handleSubmit((values) => {
            if (noteData) {
              handleNoteUpdate(values);
            } else {
              handleNoteCreate(values);
            }
          })}
        >
          <Grid2 container spacing={1}>
            <Grid2 size={12}>
              <Controller
                name="title"
                control={form.control}
                rules={{
                  required: {
                    value: true,
                    message: "Please enter content",
                  },
                }}
                render={({ field, formState: { errors } }) => (
                  <GKTextField
                    {...field}
                    requiredField
                    inputLabel="Title"
                    minRows={3}
                    error={!!errors?.title}
                    helperText={errors?.title?.message as string}
                  />
                )}
              />
            </Grid2>
            <Grid2 size={12}>
              <Controller
                name="noteType"
                control={form.control}
                rules={{
                  required: {
                    value: true,
                    message: "Please select note type",
                  },
                }}
                render={({ field, formState: { errors } }) => (
                  <GKSelect
                    {...field}
                    requiredField
                    inputLabel="Note Type"
                    error={!!errors?.noteType}
                    helperText={errors?.noteType?.message as string}
                  >
                    <MenuItem style={{ display: "none" }} value="">
                      Select Note Type
                    </MenuItem>
                    {noteType?.data?.data?.map((dataItem: any) => (
                      <MenuItem value={dataItem?.id} key={dataItem?.id}>
                        {dataItem?.noteType}
                      </MenuItem>
                    ))}
                    <MenuItem
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        gap: "10px",
                      }}
                    >
                      <GKButton
                        onClick={(e) => {
                          e.stopPropagation();
                          setOpenNoteModal(true);
                        }}
                      >
                        Add New Note Type
                      </GKButton>
                    </MenuItem>
                  </GKSelect>
                )}
              />
            </Grid2>
            <Grid2 size={12}>
              <Controller
                name="noteDate"
                control={form.control}
                rules={{
                  required: {
                    value: true,
                    message: "Please enter date",
                  },
                }}
                render={({ field, formState: { errors } }) => (
                  <GKDatePicker
                    {...field}
                    inputLabel="Note Date"
                    requiredField
                    name="noteDate"
                    value={dayjs(field.value)}
                    onChange={(value) => {
                      field.onChange(dayjs(value).format("YYYY-MM-DD"));
                    }}
                    disableFuture
                    slotProps={{
                      textField: {
                        error: !!errors?.noteDate,
                        helperText: errors?.noteDate?.message as string,
                      },
                    }}
                  />
                )}
              />
            </Grid2>
            <Grid2 size={12}>
              <Controller
                name="note"
                control={form.control}
                rules={{
                  required: {
                    value: true,
                    message: "Please enter note",
                  },
                }}
                render={({ field, formState: { errors } }) => (
                  <GKTextArea
                    {...field}
                    inputLabel="Notes"
                    requiredField
                    minRows={5}
                    error={!!errors.note}
                    helperText={errors.note?.message as string}
                  />
                )}
              />
            </Grid2>
          </Grid2>
        </form>
      </GKModal>

      {openNoteModal && (
        <AddNoteTypeModal open={openNoteModal} setOpen={setOpenNoteModal} />
      )}
    </>
  );
};

export default AddNewNotes;
