import { GridColDef } from "@mui/x-data-grid-pro";
import queryClient from "client";
import dayjs from "dayjs";
import { paletteLightTheme } from "./ColorTheme";
import { handleCapitalize } from "./common";
import { handleFloatValues, moneyCommaSeparator } from "./MathFunction";
import { handleRemoveUnderscore } from "./StringFunctions";

export const formatDate = (date: string) => {
  if (date) {
    return date.replace(/-/g, "/");
  }
  return date;
};

export const errorHandler = (error: any) => {
  if (error.status === 401) return "Please login again, session expired";
  return error.errors.message || "Something went wrong, please try again later";
};

export const copyTextToClipboard = async (text: string) => {
  try {
    await navigator.clipboard.writeText(text);
    return true;
  } catch (err) {
    return false;
  }
};

export const defaultLottieOptions = {
  loop: true,
  autoplay: true,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};
export const passwordRegex =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

export const matchDate = (date: string, format?: string) => {
  if (date === null || date === "-" || date === "") {
    return "N/A";
  }

  return dayjs(date).format(format || "DD MMM YYYY");
};

export const validationForPercentage = (number: number | string) => {
  const newNumber = Number(number);

  if (newNumber >= 0 && newNumber <= 100) {
    return newNumber;
  }

  return 100;
};

export const getRowIndex = (params: any) => {
  return params.api.getRowIndexRelativeToVisibleRows(params?.row?.id);
};

export const generateRandomNumberId = (length: number) => {
  let id = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  for (let i = 0; i < length; i++) {
    id += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return id;
};

export const extraColumnsField = (
  headerName: string,
  field: string,
  minWidth?: number,
  flex?: number,
) => {
  return {
    disableColumnMenu: true,
    disableExport: true,
    headerName: headerName,
    field: field,
    minWidth: minWidth || 150,
    flex: flex,
    sortable: field !== "action",
  };
};

export const returnReviewHeaders = (text: string) => {
  switch (text.toLowerCase()) {
    case "cash":
      return [
        {
          ...extraColumnsField("Date", "date", 150, 1),
          valueFormatter: (params) => matchDate(params?.value),
        },
        {
          ...extraColumnsField("Portfolio Code", "portfolioCode", 150, 1),
        },
        {
          ...extraColumnsField("Portfolio Name", "portfolioName", 150, 1),
        },
        {
          ...extraColumnsField("Transaction Type", "transactionType", 150, 1),
          valueFormatter: (params) => handleCapitalize(params?.value),
          renderCell: (data: any) => data?.value || " ",
        },
        {
          ...extraColumnsField(
            "Transaction Sub Type",
            "subTransactionType",
            150,
            1,
          ),
          valueFormatter: (params) => handleCapitalize(params?.value),
          renderCell: (data: any) =>
            handleRemoveUnderscore(handleCapitalize(data?.value)) || " ",
        },
        {
          ...extraColumnsField("Amount", "billAmount", 150, 1),
          valueGetter: (data: any) => moneyCommaSeparator(data?.value),
          align: "right",
          headerAlign: "right",
        },
        {
          ...extraColumnsField("Remarks", "remarks", 150, 1),
          renderCell: (data: any) => data?.value || " ",
        },
        {
          ...extraColumnsField("Error", "error", 150, 1),
          renderCell: (data: any) => data?.value || "-",
        },
      ] as GridColDef[];
    case "portfolio":
      return [
        {
          ...extraColumnsField("Portfolio Name", "name", 150, 1),
        },
        {
          ...extraColumnsField("Client name", "clientName", 150, 1),
          valueGetter: (params) => {
            return params?.row?.client?.personalDetails?.name || "-";
          },
        },
        {
          ...extraColumnsField("Portfolio Code", "portfolioId", 150, 1),
          valueGetter: (params) => {
            return params?.row?.portfolioId || "-";
          },
        },
        {
          ...extraColumnsField("Phone Number", "phoneNumber", 150, 1),
          valueGetter: (params) => {
            return params?.row?.client?.phone || "-";
          },
        },
        {
          ...extraColumnsField("PAN", "pan", 150, 1),
          valueGetter: (params) => {
            return params?.row?.client?.personalDetails?.pan || "-";
          },
        },
        {
          ...extraColumnsField("Email", "email", 150, 1),
          valueGetter: (params) => {
            return params?.row?.client?.personalDetails?.email || "-";
          },
        },
        {
          ...extraColumnsField("Joining Date", "joiningDate", 150, 1),
        },
        {
          ...extraColumnsField("Billing Cycle", "billingCycle", 150, 1),
        },
        {
          ...extraColumnsField("AUM Fee", "aumFee", 150, 1),
          align: "right",
          headerAlign: "right",
        },
        {
          ...extraColumnsField("Performance Fee", "performanceFee", 150, 1),
          align: "right",
          headerAlign: "right",
        },
        {
          ...extraColumnsField("Soft Hurdle", "softHurdle", 150, 1),
        },
        {
          ...extraColumnsField("Hard Hurdle", "hardHurdle", 150, 1),
        },
        {
          ...extraColumnsField("Date Of Birth", "dateOfBirth", 150, 1),
          valueGetter: (params) => {
            return params?.row?.client?.personalDetails?.dateOfBirth || "-";
          },
        },
        {
          ...extraColumnsField("Gender", "gender", 150, 1),
          valueGetter: (params) => {
            return params?.row?.client?.personalDetails?.gender || "-";
          },
        },
        {
          ...extraColumnsField("Marital Status", "maritalStatus", 150, 1),
          valueGetter: (params) => {
            return params?.row?.client?.personalDetails?.maritalStatus || "-";
          },
        },
        {
          ...extraColumnsField("Father's Name", "fatherName", 150, 1),
          valueGetter: (params) => {
            return params?.row?.client?.personalDetails?.fatherName || "-";
          },
        },
        {
          ...extraColumnsField("Mother's Name", "motherName", 150, 1),
          valueGetter: (params) => {
            return params?.row?.client?.personalDetails?.motherName || "-";
          },
        },
        {
          ...extraColumnsField("Family Name", "familyName", 150, 1),
          valueGetter: (params) => {
            return params?.row?.client?.family[0]?.name || "-";
          },
        },
        {
          ...extraColumnsField("Nominee Added", "nominees", 150, 1),
          valueGetter: (params) => {
            return params?.row?.client?.nominees?.length || "-";
          },
        },
        {
          ...extraColumnsField("Address", "address", 150, 1),
          valueGetter: (params) => {
            return params?.row?.client?.personalDetails?.address || "-";
          },
        },
        {
          ...extraColumnsField("Occupation", "occupation", 150, 1),
          valueGetter: (params) => {
            return params?.row?.client?.personalDetails?.occupation || "-";
          },
        },
        {
          ...extraColumnsField("Income", "income", 150, 1),
          valueGetter: (params) => {
            return params?.row?.client?.personalDetails?.income || "-";
          },
        },
        {
          ...extraColumnsField("Error", "error", 150, 1),
          renderCell: (data: any) => data?.value || "-",
        },
      ] as GridColDef[];
    case "stock":
      return [
        {
          ...extraColumnsField("Date", "date", 170, 1),
          valueFormatter: (params) => matchDate(params?.value),
          editable: false,
        },
        {
          ...extraColumnsField("Portfolio", "portfolioName", 170, 1),
          editable: false,
          valueGetter: (params: any) =>
            `${params.row.portfolioName} / ${params.row.portfolioCode}`,
        },
        {
          ...extraColumnsField("Company Name", "companyName", 170, 1),
          editable: false,
        },
        {
          ...extraColumnsField("BSE / NSE Code", "bseCode", 170, 1),
          editable: false,
          valueGetter: (params: any) =>
            `${params.row.bseCode || "-"} / ${params.row.nseCode || "-"}`,
        },
        {
          ...extraColumnsField("Transaction Type", "transactionType", 170, 1),
          editable: false,
          valueFormatter: (params) => handleCapitalize(params?.value),
        },
        {
          ...extraColumnsField("Quantity", "quantity", 170, 1),
          editable: false,
          renderCell: (params) => {
            return handleFloatValues({
              num: params?.row?.quantity,
              toFixed: 4,
            });
          },
        },
        {
          ...extraColumnsField("Price", "price", 170, 1),
          editable: false,
        },
        {
          ...extraColumnsField("Brokerage", "brokerage", 170, 1),
          editable: false,
        },
        {
          ...extraColumnsField(
            "Amount With Brokerage",
            "amountWithBrokerage",
            200,
            1,
          ),
          editable: false,
          valueFormatter: (params: any) => moneyCommaSeparator(params?.value),
        },
        {
          ...extraColumnsField("STT", "stt", 170, 1),
          valueFormatter: (params: any) => moneyCommaSeparator(params?.value),
          editable: false,
        },
        {
          ...extraColumnsField("GST", "gst", 170, 1),
          valueFormatter: (params: any) => moneyCommaSeparator(params?.value),
          editable: false,
        },
        {
          ...extraColumnsField("Stamp Charges", "stampCharges", 170, 1),
          editable: false,
          valueFormatter: (params: any) => moneyCommaSeparator(params?.value),
        },
        {
          ...extraColumnsField(
            "Transaction Charges",
            "transactionCharges",
            170,
            1,
          ),
          editable: false,
          valueFormatter: (params: any) => moneyCommaSeparator(params?.value),
        },
        {
          ...extraColumnsField(
            "Sebi Turnover Fees",
            "sebiTurnoverFees",
            170,
            1,
          ),
          editable: false,
          valueFormatter: (params: any) => moneyCommaSeparator(params?.value),
        },
        {
          ...extraColumnsField("Other Charges", "otherCharges", 170, 1),
          align: "right",
          headerAlign: "right",
          valueGetter: (params) => params.row.otherCharges || "0",
          valueFormatter: (params) => moneyCommaSeparator(params.value),
        },
        {
          ...extraColumnsField("Bill Amount", "billAmount", 150, 1),
          align: "right",
          headerAlign: "right",
          valueGetter: (params) => params.row.billAmount || "0",
          valueFormatter: (params) => moneyCommaSeparator(params.value),
        },
        {
          ...extraColumnsField(
            "Contract Note Number",
            "contractNoteNum",
            170,
            1,
          ),
          editable: false,
        },
        {
          ...extraColumnsField("Broker Name", "brokerName", 170, 1),
          editable: false,
        },
        {
          ...extraColumnsField("Error", "error", 150, 1),
          renderCell: (data: any) => data?.value || "-",
        },
      ] as GridColDef[];
    case "mutual":
      return [
        {
          ...extraColumnsField("Date", "date", 170, 1),
          valueFormatter: (params) => matchDate(params?.value),
          editable: false,
        },
        {
          ...extraColumnsField("Portfolio", "portfolioName", 170, 1),
          editable: false,
          valueGetter: (params: any) =>
            `${params.row.portfolioName} / ${params.row.portfolioCode}`,
        },
        {
          ...extraColumnsField("Company Name", "companyName", 170, 1),
          editable: false,
        },
        {
          ...extraColumnsField("Company Code", "isinCode", 170, 1),
          editable: false,
          valueGetter: (params: any) => `${params.row.isinCode || "-"}`,
        },
        {
          ...extraColumnsField("Transaction Type", "transactionType", 170, 1),
          editable: false,
          valueFormatter: (params) => handleCapitalize(params?.value),
        },
        {
          ...extraColumnsField("Quantity", "quantity", 170, 1),
          editable: false,
          renderCell: (params) => {
            return handleFloatValues({
              num: params?.row?.quantity,
              toFixed: 4,
            });
          },
        },
        {
          ...extraColumnsField("Price", "price", 170, 1),
          editable: false,
        },
        {
          ...extraColumnsField("Brokerage", "brokerage", 170, 1),
          editable: false,
        },
        {
          ...extraColumnsField(
            "Amount With Brokerage",
            "amountWithBrokerage",
            200,
            1,
          ),
          editable: false,
          valueFormatter: (params: any) => moneyCommaSeparator(params?.value),
        },
        {
          ...extraColumnsField("STT", "stt", 170, 1),
          valueFormatter: (params: any) => moneyCommaSeparator(params?.value),
          editable: false,
        },
        {
          ...extraColumnsField("GST", "gst", 170, 1),
          valueFormatter: (params: any) => moneyCommaSeparator(params?.value),
          editable: false,
        },
        {
          ...extraColumnsField("Stamp Charges", "stampCharges", 170, 1),
          editable: false,
          valueFormatter: (params: any) => moneyCommaSeparator(params?.value),
        },
        {
          ...extraColumnsField(
            "Transaction Charges",
            "transactionCharges",
            170,
            1,
          ),
          editable: false,
          valueFormatter: (params: any) => moneyCommaSeparator(params?.value),
        },
        {
          ...extraColumnsField(
            "Sebi Turnover Fees",
            "sebiTurnoverFees",
            170,
            1,
          ),
          editable: false,
          valueFormatter: (params: any) => moneyCommaSeparator(params?.value),
        },
        {
          ...extraColumnsField("Other Charges", "otherCharges", 170, 1),
          align: "right",
          headerAlign: "right",
          valueGetter: (params) => params.row.otherCharges || "0",
          valueFormatter: (params) => moneyCommaSeparator(params.value),
        },
        {
          ...extraColumnsField("Bill Amount", "billAmount", 150, 1),
          align: "right",
          headerAlign: "right",
          valueGetter: (params) => params.row.billAmount || "0",
          valueFormatter: (params) => moneyCommaSeparator(params.value),
        },
        {
          ...extraColumnsField(
            "Contract Note Number",
            "contractNoteNum",
            170,
            1,
          ),
          editable: false,
        },
        {
          ...extraColumnsField("Broker Name", "brokerName", 170, 1),
          editable: false,
        },
        {
          ...extraColumnsField("Error", "error", 150, 1),
          renderCell: (data: any) => data?.value || "-",
        },
      ] as GridColDef[];
    default:
      return [
        {
          ...extraColumnsField("Date", "date", 170, 1),
          valueFormatter: (params) => matchDate(params?.value),
          editable: false,
        },
        {
          ...extraColumnsField("Portfolio", "portfolioName", 170, 1),
          editable: false,
          valueGetter: (params: any) =>
            `${params.row.portfolioName} / ${params.row.portfolioCode}`,
        },
        {
          ...extraColumnsField("Company Name", "companyName", 170, 1),
          editable: false,
        },
        {
          ...extraColumnsField("BSE / NSE Code", "bseCode", 170, 1),
          editable: false,
          valueGetter: (params: any) =>
            `${params.row.bseCode || "-"} / ${params.row.nseCode || "-"}`,
        },
        {
          ...extraColumnsField("Transaction Type", "transactionType", 170, 1),
          editable: false,
          valueFormatter: (params) => handleCapitalize(params?.value),
        },
        {
          ...extraColumnsField("Quantity", "quantity", 170, 1),
          editable: false,
          renderCell: (params) => {
            return handleFloatValues({
              num: params?.row?.quantity,
              toFixed: 4,
            });
          },
        },
        {
          ...extraColumnsField("Price", "price", 170, 1),
          editable: false,
        },
        {
          ...extraColumnsField("Brokerage", "brokerage", 170, 1),
          editable: false,
        },
        {
          ...extraColumnsField(
            "Amount With Brokerage",
            "amountWithBrokerage",
            200,
            1,
          ),
          editable: false,
          valueFormatter: (params: any) => moneyCommaSeparator(params?.value),
        },
        {
          ...extraColumnsField("STT", "stt", 170, 1),
          valueFormatter: (params: any) => moneyCommaSeparator(params?.value),
          editable: false,
        },
        {
          ...extraColumnsField("GST", "gst", 170, 1),
          valueFormatter: (params: any) => moneyCommaSeparator(params?.value),
          editable: false,
        },
        {
          ...extraColumnsField("Stamp Charges", "stampCharges", 170, 1),
          editable: false,
          valueFormatter: (params: any) => moneyCommaSeparator(params?.value),
        },
        {
          ...extraColumnsField(
            "Transaction Charges",
            "transactionCharges",
            170,
            1,
          ),
          editable: false,
          valueFormatter: (params: any) => moneyCommaSeparator(params?.value),
        },
        {
          ...extraColumnsField(
            "Sebi Turnover Fees",
            "sebiTurnoverFees",
            170,
            1,
          ),
          editable: false,
          valueFormatter: (params: any) => moneyCommaSeparator(params?.value),
        },
        {
          ...extraColumnsField("Other Charges", "otherCharges", 170, 1),
          align: "right",
          headerAlign: "right",
          valueGetter: (params) => params.row.otherCharges || "0",
          valueFormatter: (params) => moneyCommaSeparator(params.value),
        },
        {
          ...extraColumnsField("Bill Amount", "billAmount", 150, 1),
          align: "right",
          headerAlign: "right",
          valueGetter: (params) => params.row.billAmount || "0",
          valueFormatter: (params) => moneyCommaSeparator(params.value),
        },
        {
          ...extraColumnsField(
            "Contract Note Number",
            "contractNoteNum",
            170,
            1,
          ),
          editable: false,
        },
        {
          ...extraColumnsField("Broker Name", "brokerName", 170, 1),
          editable: false,
        },
        {
          ...extraColumnsField("Error", "error", 150, 1),
          renderCell: (data: any) => data?.value || "-",
        },
      ] as GridColDef[];
  }
};

export const generateUUID = (size: number) => {
  const nums = Array.from({ length: 10 }, (_, i) =>
    String.fromCharCode("0".charCodeAt(0) + i),
  );
  const alphabets = Array.from({ length: 26 }, (_, i) =>
    String.fromCharCode("a".charCodeAt(0) + i),
  );
  const chars = [...nums, ...alphabets];
  const rand = (length: number) => Math.floor(Math.random() * length);
  return Array.from({ length: size }, () => chars[rand(chars.length)]).join("");
};

export const refetchQuery = (query: string) => {
  return queryClient.refetchQueries([query]);
};

export const smallCaseBroker = [
  { name: "Zerodha", value: "kite" },
  { name: "5Paisa", value: "fivepaisa" },
  { name: "Alice Blue", value: "aliceblue" },
  { name: "Angel One", value: "angelbroking" },
  { name: "Dhan", value: "dhan" },
  { name: "Edelweiss", value: "edelweiss" },
  { name: "Fisdom", value: "fisdom" },
  { name: "FundzBazar", value: "Frame" },
  { name: "Groww", value: "groww" },
  { name: "HDFC Securities", value: "hdfc" },
  { name: "ICICIdirect", value: "icici" },
  { name: "IIFL", value: "iifl" },
  { name: "Kotak Securities", value: "kotak" },
  { name: "Motilal Oswal ", value: "motilal" },
  { name: "Trustline", value: "trustline" },
  { name: "Upstox", value: "upstox" },
  { name: "Axis Securities", value: "axis" },
];

export const handleOnboardingCalculation = ({
  obj,
  objKey,
}: {
  obj: any;
  objKey: string;
}) => {
  const numTrue = Object.values(obj[objKey] || {}).filter(
    (value) => value === true,
  ).length;
  const percentTrue = (numTrue / Object.values(obj[objKey] || {}).length) * 100;

  const str = `${numTrue || 0}/${Object.values(obj[objKey] || {}).length || 0}`;
  return { value: percentTrue, str: str };
};

export const handleDownloadFile = ({
  name,
  link,
}: {
  link: string;
  name?: string;
}) => {
  const aLink = document.createElement("a");
  aLink.href = link;
  aLink.download = name || "Sample_File";
  aLink.click();
};

export const returnPositiveNegativeColor = (num: number) => {
  return Number(num) > 0
    ? paletteLightTheme.palette.success.main
    : paletteLightTheme.palette.error.main;
};

export const formatYAxisLabel = (value: number): string => {
  const absValue = Math.abs(value);
  if (absValue >= 10000000) {
    return `${(value / 10000000).toFixed(2)} Cr`;
  }
  if (absValue >= 100000) {
    return `${(value / 100000).toFixed(2)} L`;
  }
  if (absValue >= 1000) {
    return `${(value / 1000).toFixed(2)} K`;
  }
  return value.toString();
};

export const convertQuillToEditorJS = (quillHtml: string) => {
  if (quillHtml?.includes('"blocks":')) {
    return quillHtml;
  }

  const parser = new DOMParser();
  const doc = parser.parseFromString(quillHtml, "text/html");
  const blocks: any[] = [];

  doc.body.childNodes.forEach((node) => {
    if (node instanceof Element) {
      if (node.tagName === "P") {
        blocks.push({
          type: "paragraph",
          data: {
            text: node.innerHTML,
          },
        });
      } else if (node.tagName === "IMG") {
        const src = node.getAttribute("src");
        if (src) {
          blocks.push({
            type: "image",
            data: {
              file: { url: src }, // Image URL
              caption: node.getAttribute("alt") || "", // Optional alt text
            },
          });
        }
      } else if (["H1", "H2", "H3"].includes(node.tagName)) {
        blocks.push({
          type: "header",
          data: {
            level: parseInt(node.tagName.charAt(1), 10),
            text: node.innerHTML,
          },
        });
      }
    }
  });

  return JSON.stringify({ time: Date.now(), blocks });
};

export const returnFileSizeConvert = (file: any) => {
  const totalBytes = file?.size;
  if (totalBytes < 1000000) {
    return `${(totalBytes / 1000).toFixed(2)}KB`;
  }
  return `${(totalBytes / 1000000).toFixed(2)}MB`;
};

export const handleReturnData = (
  data: [number, number][],
): [number, number | null][] => {
  let foundNonZero = false;
  return data?.map(([timestamp, value]) => {
    if (value > 0) {
      foundNonZero = true;
    }
    return [timestamp, foundNonZero ? value : null];
  });
};

type BrokerSupport = {
  adityaBirlaSupport?: boolean;
  lmSupport?: boolean;
  iiflSupport?: boolean;
  [key: string]: boolean | undefined;
};

const brokerMapping: Record<string, string> = {
  adityaBirlaSupport: "ADITYA_BIRLA_MONEY",
  lmSupport: "LATIN_MANHARLAL",
  iiflSupport: "IIFL",
  OTHER: "OTHER",
};

const brokerNameMapping: Record<string, string> = {
  adityaBirlaSupport: "Aditya Birla",
  lmSupport: "Latin Manharlal",
  iiflSupport: "IIFL",
  OTHER: "OTHER",
};

export function getActiveBrokers(supportData: BrokerSupport) {
  const activeBrokers = Object.entries(supportData || {})
    .filter(
      ([key, value]) => value && brokerMapping[key] && brokerNameMapping[key],
    )
    .map(([key]) => ({
      key,
      value: brokerMapping[key],
      name: brokerNameMapping[key],
    }));

  activeBrokers.push({
    key: "OTHER",
    value: brokerMapping.OTHER,
    name: "Other",
  });

  return activeBrokers;
}

export const formatPercentage = (value: number, fixValue?: number) =>
  value != null ? `${+value?.toFixed(fixValue || 2)}%` : "-";

export const feeplanTimePeriod = [
  {
    name: "Monthly",
    stringVaue: "MONTHLY",
    value: 1,
  },
  {
    name: "Quarterly",
    stringVaue: "QUARTERLY",
    value: 3,
  },
  {
    name: "Semiannually",
    stringVaue: "SEMIANNUALLY",
    value: 6,
  },
  {
    name: "Yearly",
    stringVaue: "YEARLY",
    value: 12,
  },
];

export const daysCalculate = (frequency: string) => {
  const startData = dayjs();

  const frequencyCount = +feeplanTimePeriod.find(
    (d) => d.stringVaue === frequency,
  )?.value;

  const endDate = startData.add(frequencyCount, "month");

  return endDate.diff(startData, "days") || 0;
};

export const handleCalculateFee = ({
  total,
  feePercent,
  frequency,
}: {
  total: number;
  frequency: string;
  feePercent: number;
}) => {
  const time = daysCalculate(frequency) || 0 || 0;
  const calc = (time * total * feePercent) / 365 / 100;
  return calc || 0;
};

export const handleReturnDropdownSingleValue = ({
  options,
  valueKey,
  isMultiple = false,
}: {
  options: any[];
  valueKey?: string;
  isMultiple?: boolean;
}) => {
  if (isMultiple) {
    return options?.length === 1 ? [options[0]] : [];
  }

  return options?.length === 1 ? options[0][valueKey || "value"] : "";
};

export const PAYMENT_METHOD = [
  { name: "Payment Link", value: "payment_links" },
  { name: "Mandate", value: "mandate" },
];
