import { Box, Button, Grid2, Typography } from "components";
import { AdvisorOnboardFormInterface } from "interfaces/OnboardingInterface";
import { useFormContext } from "react-hook-form";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { PiUserCirclePlus } from "react-icons/pi";
import { ThemeContainer } from "utils/Theme";

const AdvisorOnboardingTypeSelect = () => {
  const { theme } = ThemeContainer();

  const { setValue } = useFormContext<AdvisorOnboardFormInterface>();

  const buttonData = [
    {
      title: "New / via KRA / Existing",
      description:
        "Seamlessly onboard new clients by manually entering details or fetching their data from KRA to begin their investment journey.",
      disable: false,
      icon: <AiOutlinePlusCircle size={24} />,
      onClick: () => {
        setValue("activeStep", 1);
        setValue("type", "new");
      },
    },
    {
      title: "Client Self-Onboarding",
      description:
        "Enable hassle-free onboarding by sending a secure link for clients to complete their compliances and investment setup",
      icon: <PiUserCirclePlus size={24} />,
      disable: false,
      onClick: () => {
        setValue("activeStep", 1);
        setValue("type", "manual");
      },
    },
  ];

  return (
    <Grid2 container spacing={2}>
      <Grid2 size={2} />
      {buttonData?.map((dataItem, index) => {
        return (
          <Grid2 size={4} key={index}>
            <Button
              disabled={dataItem.disable}
              onClick={dataItem.onClick}
              variant="outlined"
              color="dark"
              fullWidth
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                p: 4,
                gap: 0.5,
              }}
            >
              <Box
                sx={{
                  p: 1.2,
                  border: `1px solid ${theme.palette.border.main}`,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: 2,
                }}
              >
                {dataItem.icon}
              </Box>
              <Typography fontWeight={600} color={theme.palette.dark.main}>
                {dataItem.title}
              </Typography>
              <Typography textTransform={"none"}>
                {dataItem.description}
              </Typography>
            </Button>
          </Grid2>
        );
      })}
      <Grid2 size={2} />
    </Grid2>
  );
};

export default AdvisorOnboardingTypeSelect;
