import { LoadingButton } from "@mui/lab";
import { grey } from "@mui/material/colors";
import {
  Box,
  Card,
  DragDrop,
  FormControlLabel,
  GKButton,
  GKCheckbox,
  GKSelect,
  Grid2,
  MenuItem,
  Show,
  StatusField,
  Typography,
} from "components";
import { useMutation } from "hooks/useQueries";
import { AdvisorOnboardFormInterface } from "interfaces/OnboardingInterface";
import { useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import axiosInstance from "utils/axiosInstance";
import {
  AGREEMENT_SEND_OPTIONS_SOURCE,
  AGREEMENT_SIGNATURE_SOURCE,
} from "utils/constants";
import { refetchQuery } from "utils/helpers";
import { QueryKeys } from "utils/queryKey";
import { ThemeContainer } from "utils/Theme";
import ClientOnboardingCustomAgreement from "./ClientOnboardingCustomAgreement";

const AdvisorOnboardingAgreement = () => {
  const { theme } = ThemeContainer();

  const form = useFormContext<AdvisorOnboardFormInterface>();

  const [customAgreement, setCustomAgreement] = useState<boolean>(false);

  const [uploadError, setUploadError] = useState<string | null>(null);

  const { mutate: handleAgreement } = useMutation({
    mutationKey: ["HANDLE_ADD_AGREMENT_MUTATUION"],
    mutationFn: (values: any) =>
      axiosInstance.post(
        `/agreements/${form.getValues("id")}/?manual=${form.getValues(
          "manual",
        )}`,
        values,
      ),
    onSuccess: () => {
      refetchQuery(QueryKeys.GET_COMPLIANCE_BY_CLIENT_ID);
      refetchQuery(QueryKeys.GET_CLIENT_BY_ID);
      form.setValue("activeStep", form.getValues("activeStep") + 1);
    },
  });

  return (
    <>
      <form
        onSubmit={form.handleSubmit((values) => {
          if (form.getValues("agreement")) {
            form.setValue("activeStep", form.getValues("activeStep") + 1);
          } else {
            const filedata = new FormData();
            filedata.append("document", values.document[0]);
            filedata.append("sendOptions", values.sendOptions);
            filedata.append("signatureType", values.signatureType);
            filedata.append("client", values?.id);
            filedata.append(
              "portfolio",
              values?.portfolioObject?.id?.toString(),
            );
            handleAgreement(filedata);
          }
        })}
        id="advisor-onboarding-agreement-form"
      >
        <Show.When isTrue={form.getValues("agreement")}>
          <Grid2 container spacing={1} m={"auto"}>
            <Grid2
              size={6}
              display={"flex"}
              justifyContent={"flex-start"}
              alignItems={"center"}
            >
              <Typography fontSize={24} fontWeight={600}>
                Agreement
              </Typography>
            </Grid2>
            <Grid2
              size={6}
              display={"flex"}
              justifyContent={"flex-end"}
              alignItems={"center"}
              gap={1}
            >
              <GKButton
                variant="contained"
                onClick={() => {
                  form.setValue("agreement", null);
                  form.setValue("resignAgreement", true);
                }}
              >
                Re-sign Agreement
              </GKButton>
            </Grid2>
            <Grid2 size={6} m={"auto"}>
              <Card
                sx={{
                  bgcolor: "#F8FAFC",
                  border: "none",
                  p: 1,
                }}
              >
                <Grid2 container spacing={1}>
                  <Grid2
                    container
                    size={12}
                    bgcolor={theme.palette.light.main}
                    p={1}
                    borderRadius={1}
                  >
                    <Grid2
                      size={12}
                      display={"flex"}
                      alignItems={"center"}
                      gap={2}
                    >
                      <Typography
                        color={grey[600]}
                        textTransform={"capitalize"}
                      >
                        Agreement Status:
                      </Typography>
                      <StatusField
                        statusString={
                          form.getValues("agreement.agreementStatus.status") ||
                          "Pending"
                        }
                      />
                    </Grid2>

                    <Grid2
                      size={12}
                      display={"flex"}
                      alignItems={"center"}
                      gap={2}
                    >
                      <Typography
                        color={grey[600]}
                        textTransform={"capitalize"}
                      >
                        Signature Type:
                      </Typography>
                      <Typography
                        textTransform={"capitalize"}
                        textAlign={"left"}
                        fontWeight={600}
                      >
                        {form.getValues("agreement.signatureType") || "-"}{" "}
                      </Typography>
                    </Grid2>

                    <Grid2
                      size={12}
                      display={"flex"}
                      alignItems={"center"}
                      gap={2}
                    >
                      <Typography
                        color={grey[600]}
                        textTransform={"capitalize"}
                      >
                        Send Via :
                      </Typography>
                      <Typography
                        textTransform={"capitalize"}
                        textAlign={"left"}
                        fontWeight={600}
                      >
                        {form.getValues("agreement.sendOptions") || "-"}
                      </Typography>
                    </Grid2>
                  </Grid2>
                  <Grid2 size={12} display={"flex"} justifyContent={"center"}>
                    <LoadingButton
                      variant="outlined"
                      href={
                        form.getValues(
                          "agreement.agreementStatus.signedDocument",
                        ) || form.getValues("agreement.document")
                      }
                      target="_blank"
                    >
                      View Document
                    </LoadingButton>
                  </Grid2>
                </Grid2>
              </Card>
            </Grid2>
          </Grid2>
        </Show.When>
        <Show.When isTrue={!form.getValues("agreement")}>
          <Grid2 container spacing={1} overflow={`hidden auto`}>
            <Grid2 container size={12} spacing={1}>
              <Grid2
                size={12}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
                mb={1}
              >
                <Typography fontSize={24} fontWeight={600}>
                  Agreement
                </Typography>
                <Box sx={{ display: "flex", gap: 1 }}>
                  <Show.When isTrue={form.getValues("resignAgreement")}>
                    <GKButton
                      variant="outlined"
                      color="dark"
                      onClick={() => {
                        refetchQuery(QueryKeys.GET_COMPLIANCE_BY_CLIENT_ID);
                        refetchQuery(QueryKeys.GET_CLIENT_BY_ID);
                      }}
                    >
                      Back
                    </GKButton>
                  </Show.When>
                  <GKButton
                    variant="outlined"
                    color="primary"
                    onClick={() => setCustomAgreement(true)}
                  >
                    Generate Agreement
                  </GKButton>
                </Box>
              </Grid2>
              <Grid2 container size={12} spacing={1}>
                <Grid2 size={12}>
                  <Controller
                    control={form.control}
                    name="document"
                    rules={{
                      required: {
                        value: form.getValues("activeStep") === 6,
                        message: "Please upload a document",
                      },
                    }}
                    render={({ field, fieldState: { error } }) => {
                      return (
                        <DragDrop
                          uploadError={uploadError}
                          setUploadError={setUploadError}
                          value={field.value}
                          onChange={(e) => {
                            field.onChange(e);
                          }}
                          supportName=".png, .jpg, .pdf"
                          acceptType={".pdf,.png,.jpg"}
                          error={!!error}
                        />
                      );
                    }}
                  />
                </Grid2>
                <Grid2 size={12}>
                  <Controller
                    name="manual"
                    control={form.control}
                    render={({ field }) => {
                      return (
                        <FormControlLabel
                          {...field}
                          style={{
                            marginLeft: 0,
                          }}
                          label="Skip Signature"
                          control={
                            <GKCheckbox
                              checked={field.value}
                              onChange={(event) => {
                                field.onChange(event.target.checked);
                              }}
                            />
                          }
                        />
                      );
                    }}
                  />
                </Grid2>
                <Show.When isTrue={!form.getValues("manual")}>
                  <Grid2 size={6}>
                    <Controller
                      control={form.control}
                      name="signatureType"
                      render={({ field }) => {
                        return (
                          <GKSelect
                            {...field}
                            inputLabel="Sign Type"
                            displayEmpty
                          >
                            <MenuItem style={{ display: "none" }} value="">
                              Select Sign Type
                            </MenuItem>
                            {AGREEMENT_SIGNATURE_SOURCE?.map((dataItem, i) => (
                              <MenuItem key={i} value={dataItem.value}>
                                {dataItem.name}
                              </MenuItem>
                            ))}
                          </GKSelect>
                        );
                      }}
                    />
                  </Grid2>
                </Show.When>
                <Show.When isTrue={!form.getValues("manual")}>
                  <Grid2 size={6}>
                    <Controller
                      control={form.control}
                      name="sendOptions"
                      render={({ field }) => {
                        return (
                          <GKSelect
                            {...field}
                            inputLabel="Send Agreement Via"
                            displayEmpty
                          >
                            <MenuItem style={{ display: "none" }} value="">
                              Send Agreement Via
                            </MenuItem>
                            {AGREEMENT_SEND_OPTIONS_SOURCE?.map(
                              (dataItem, i) => (
                                <MenuItem key={i} value={dataItem.value}>
                                  {dataItem.name}
                                </MenuItem>
                              ),
                            )}
                          </GKSelect>
                        );
                      }}
                    />
                  </Grid2>
                </Show.When>
              </Grid2>
            </Grid2>
          </Grid2>
        </Show.When>
      </form>
      <Show.When isTrue={customAgreement}>
        <ClientOnboardingCustomAgreement
          setOpen={setCustomAgreement}
          open={customAgreement}
          clientId={form.getValues("id")}
          portfolioId={form.getValues("portfolioObject.id")}
          basketId={form.getValues("basketObject.id")}
          feePlanId={form.getValues("selectedFeePlan")}
        />
      </Show.When>
    </>
  );
};

export default AdvisorOnboardingAgreement;
