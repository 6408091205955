import { GKButton, Grid2, Typography } from "components";
import { useNavigate } from "react-router-dom";

const WiseSection = () => {
  const navigate = useNavigate();

  return (
    <Grid2 container size={12}>
      <Grid2
        size={{ xs: 12, lg: 6 }}
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"center"}
        sx={{
          alignItems: {
            xs: "center",
            lg: "flex-start",
          },
        }}
      >
        <Typography
          fontWeight={600}
          fontSize={{
            xs: 24,
            lg: 36,
          }}
          sx={{
            background:
              "linear-gradient(90deg, rgba(0,101,128,1) 0%, rgba(37,131,130,1) 100%)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
          }}
        >
          Wealth Wise
        </Typography>
        <Typography
          pt={3}
          fontSize={{
            xs: 14,
            lg: 18,
          }}
          sx={{
            textAlign: {
              xs: "center",
              lg: "left",
            },
          }}
        >
          Track manage assess multi-asset family-level investment
        </Typography>
        <Typography
          py={2}
          fontSize={{
            xs: 12,
            lg: 16,
          }}
          color={"#999999"}
          sx={{
            textAlign: {
              xs: "center",
              lg: "left",
            },
          }}
        >
          Designed for Individual Investors, Family Offices, Financial Advisors
          & Sub-brokers seeking to streamline their investments.
        </Typography>
        <GKButton
          sx={{
            my: 3,
          }}
          variant="landingBtn"
          onClick={() => {
            navigate("/product/wealth-wise");
          }}
        >
          Read More
        </GKButton>
      </Grid2>
      <Grid2
        size={{ xs: 12, lg: 6 }}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <img
          style={{
            width: "100%",
            height: "auto",
          }}
          src={"/LandingPage/wealthWise.svg"}
          alt=""
        />
      </Grid2>
    </Grid2>
  );
};

export default WiseSection;
