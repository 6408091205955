import { LoadingButton } from "@mui/lab";
import { Grid2, TextField } from "@mui/material";
import GKModal from "components/GKModal";
import GKSearchSelect from "components/GKSearchSelect";
import GKTextField from "components/GKTextField";
import { useMutation } from "hooks/useQueries";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import axiosInstance from "utils/axiosInstance";
import { GKModalProps } from "utils/commonInterface";
import { refetchQuery } from "utils/helpers";
import { handleReturnAutocompletePlaceholder } from "utils/StringFunctions";
import { COMPANY_STATUS } from "./helper";

interface IdentifierInterface {
  id: string;
  code: string;
  status: string;
  instrument: number;
}

interface Props {
  identifierData: IdentifierInterface;
}

interface FormValues {
  code: string;
  status: string;
}

const IdentifierModal = (props: Props & GKModalProps) => {
  const { id } = useParams();

  const { open, setOpen, identifierData } = props;

  const form = useForm<FormValues>({
    defaultValues: {
      code: identifierData?.code || "",
      status: identifierData?.status || "",
    },
  });

  const { mutate: createIdentifier, isLoading } = useMutation({
    mutationKey: ["CREATE_ADVISOR_IDENTIFIER"],
    mutationFn: (values: FormValues) =>
      axiosInstance.post(`/advisor-cm/${id}/isin/`, values),
    onSuccess: () => {
      setOpen(false);
      refetchQuery("GET_COMPANY_IDENTIFIERS");
    },
  });

  const { mutate: updateIdentifier, isLoading: updateLoading } = useMutation({
    mutationKey: ["UPDATE_ADVISOR_IDENTIFIER"],
    mutationFn: (values: FormValues) =>
      axiosInstance.patch(
        `/advisor-cm/${id}/isin/${identifierData?.id}/`,
        values,
      ),
    onSuccess: () => {
      setOpen(false);
      refetchQuery("GET_COMPANY_IDENTIFIERS");
    },
  });

  return (
    <GKModal
      open={open}
      setOpen={setOpen}
      modalTitle={identifierData?.id ? "Edit Identifier" : "Add New Identifier"}
      footer={
        <LoadingButton
          type="submit"
          variant="contained"
          size="medium"
          form="identifier-form"
          loading={isLoading || updateLoading}
        >
          Save
        </LoadingButton>
      }
    >
      <form
        id="identifier-form"
        onSubmit={form.handleSubmit((values) => {
          identifierData?.id
            ? updateIdentifier(values)
            : createIdentifier(values);
        })}
      >
        <Grid2 container spacing={2}>
          <Grid2 size={6}>
            <Controller
              name="code"
              control={form.control}
              render={({ field }) => {
                return <GKTextField {...field} inputLabel={"Code"} />;
              }}
            />
          </Grid2>
          <Grid2 size={6}>
            <Controller
              name="status"
              control={form.control}
              render={({ field }) => {
                return (
                  <GKSearchSelect
                    {...field}
                    disableClearable
                    inputLabel="Status"
                    options={COMPANY_STATUS}
                    onChange={(event, val) => {
                      field.onChange(val?.value);
                    }}
                    value={
                      COMPANY_STATUS?.find(
                        (option) => option.value === field.value,
                      )?.name || ""
                    }
                    getOptionLabel={(option) => option.name || option}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder={handleReturnAutocompletePlaceholder({
                          placeholder: "Select Cap",
                          value: field.value,
                        })}
                      />
                    )}
                  />
                );
              }}
            />
          </Grid2>
        </Grid2>
      </form>
    </GKModal>
  );
};

export default IdentifierModal;
