import { useAppContext } from "AppContext";
import dayjs from "dayjs";
import { useFetchQuery, useMutation } from "hooks/useQueries";
import { useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { useAppSelector } from "store/store";
import { ThemeContainer } from "utils/Theme";
import axiosInstance from "utils/axiosInstance";
import { refetchQuery } from "utils/helpers";
import { v4 as uuid } from "uuid";

const useModelPortfolioVersion = () => {
  const { id, modelPortfolioId: modelId } = useParams();

  const modelPortfolioId = modelId;

  const { theme } = ThemeContainer();

  const columnsPersist = useAppSelector((state) => state?.app?.columnsPersist);

  const {
    state: { userData, companies },
    dispatch,
  } = useAppContext();

  const navigate = useNavigate();

  const [openUploadModal, setOpenUploadModal] = useState(false);

  const [editModelPortfolioVersion, setModelPortfolioVersion] = useState(true);

  const [tabs, setTabs] = useState(0);

  const [opeRationaleModal, setOpeRationaleModal] = useState(false);

  const [isRebalanceModelOpen, setIsRebalanceModelOpen] = useState(false);

  const [showAdditionalFields, setShowAdditionalFields] = useState(false);

  const [isAllocation, setIsAllocation] = useState(false);

  const [opeRationaleIndex, setOpeRationaleIndex] = useState(null);

  /** ========================================================================================================= */
  const [oldIdSelected, setOldIdSelected] = useState(null);

  /** ================================================================================== */
  const form = useForm({
    defaultValues: {
      versionName: "",
      equalWeightage: "false",
      portfolioValue: "",
      date: dayjs(),
      startValue: "10000000",
      minimumInvestement: 0,
      totalWeightage: 0,
      stocks: [
        {
          isinCode: "",
          allocation: null,
          targetPrice: null,
          stopLoss: null,
          weightage: null,
          rationale: "",
          sector: "",
          gridCode: "",
          cap: "",
          companyName: "",
          uuid: uuid(),
        },
      ],
    },
  });

  /** ================================================================================== */
  const { data: equityBasketDetailsData } = useFetchQuery({
    key: ["GET_EQUITY_BASKET_BY_ID"],
    route: `/model_portfolio/${id}/details/`,
  });
  /** ================================================================================== */
  const { mutate: handleUpdateModelPortfolio, isLoading } = useMutation({
    mutationFn: (item: any) =>
      axiosInstance.patch(
        `/model_portfolio/${id}/version/${item?.versionId}/`,
        {
          version: item.versionName,
          id: item?.versionId,
          versionId: modelPortfolioId,
          date: item.date,
          startValue: item.startValue,
          minimumInvestement: item.minimumInvestement,
          allocItem: item.allocItem,
        },
      ),
    onSuccess: () => {
      toast.success("Basket is successfully updated.");
      refetchQuery("GET_ALL_VERSIONS");
    },
  });

  /** ================================================================================== */
  const handleUpdateVersion = (values: any) => {
    handleUpdateModelPortfolio({
      versionId: modelPortfolioId,
      date: dayjs(values.date).format("YYYY-MM-DD"),
      startValue: Number(values.startValue),
      minimumInvestement: values.minimumInvestement,
      allocItem: values?.stocks?.map((dataItem: any) => {
        return {
          ...dataItem,
          allocation: Number(dataItem?.allocation),
          targetPrice: Number(dataItem?.targetPrice),
          stopLoss: Number(dataItem?.stopLoss),
        };
      }),
      versionName: values.versionName,
    });
  };

  /** ================================================================================== */
  const { isFetching: getDataByIdLoading, data: modelPortdolioData } =
    useFetchQuery({
      key: ["GET_VERSION_BY_ID", modelPortfolioId, oldIdSelected],
      route: `model_portfolio/${id}/version/${
        oldIdSelected?.snapshotVersion || modelPortfolioId
      }/`,
      enabled: Boolean(modelPortfolioId),
      success: (response: any) => {
        setModelPortfolioVersion(false);
        setTimeout(() => {
          form.setValue("versionName", response?.data?.version);
          form.setValue("startValue", response?.data?.startValue);
          form.setValue(
            "minimumInvestement",
            response?.data?.minimumInvestement,
          );
          form.setValue("date", response?.data?.date);
          form.setValue(
            "stocks",
            response?.data?.allocItem?.map((item: any) => {
              return {
                ...item,
                uuid: uuid(),
              };
            }),
          );
        }, 100);
      },
    });
  /** ================================================================================== */
  const { data: getAllOldModels } = useFetchQuery({
    key: ["GET_ALL_OLD_MODEL_PORTFOLIO"],
    route: `model_portfolio/${id}/version/${modelPortfolioId}/version_rebalances/`,
    enabled: Boolean(modelPortfolioId),
  });

  /** ================================================================================== */
  const { remove, insert, replace, update } = useFieldArray({
    control: form.control,
    name: "stocks",
  });

  /** ================================================================================== */
  const { mutate: handleAddNewModelPortfolio, isLoading: submitLoading } =
    useMutation({
      mutationKey: ["MODEL_PORTFOLI_VERSIONS"],
      mutationFn: (data: any) =>
        axiosInstance.post(`/model_portfolio/${id}/version/`, data),
      onSuccess: () => {
        refetchQuery("GET_ALL_VERSIONS");
        form?.reset();
        navigate(-1);
      },
    });

  /** ================================================================================== */
  const handleAddSubmit = (values: any) => {
    handleAddNewModelPortfolio({
      latestVersion: {
        version: values.versionName,
        date: dayjs(values.date).format("YYYY-MM-DD"),
        startValue: Number(values.startValue),
        minimumInvestement: values.minimumInvestement || 0,
        allocItem: values?.stocks?.map((dataItem: any) => {
          delete dataItem?.id;
          return {
            ...dataItem,
            allocation: Number(dataItem?.allocation),
            targetPrice: Number(dataItem?.targetPrice),
            stopLoss: Number(dataItem?.stopLoss),
          };
        }),
      },
    });
  };

  const getIndexByRow = (arr: any[], id: string | number) => {
    return arr ? arr.findIndex((r) => r.uuid === id) : -1;
  };

  /** ================================================================================== */
  const { mutate: modalPortfolioDelete } = useMutation({
    mutationFn: (versionId: any) =>
      axiosInstance.delete(`/model_portfolio/${id}/version/${versionId}/`),
    onSuccess: () => {
      refetchQuery("GET_ALL_VERSIONS");
      navigate(-1);
    },
  });

  /** ================================================================================== */
  const { mutate: modalPortfolioDefault } = useMutation({
    mutationFn: (versionId: any) =>
      axiosInstance.patch(
        `/model_portfolio/${id}/version/${versionId}/primary/`,
      ),
    onSuccess: () => {
      refetchQuery("GET_ALL_VERSIONS");
      navigate(-1);
    },
  });

  /** ================================================================================== */
  const { data: modelCardData } = useFetchQuery({
    key: ["GET_MODEL_PORTFOLIO_VERSION_SUMMARY", oldIdSelected],
    route: `/model_portfolio/${id}/version/${
      oldIdSelected?.snapshotVersion || modelPortfolioId
    }/version_summary/`,
    enabled: Boolean(modelPortfolioId),
  });

  return {
    id,
    getIndexByRow,
    handleAddSubmit,
    remove,
    insert,
    theme,
    openUploadModal,
    opeRationaleModal,
    showAdditionalFields,
    isAllocation,
    opeRationaleIndex,
    setOpenUploadModal,
    submitLoading,
    form,
    setIsAllocation,
    setOpeRationaleModal,
    setShowAdditionalFields,
    setOpeRationaleIndex,
    tabs,
    setTabs,
    isRebalanceModelOpen,
    setIsRebalanceModelOpen,
    handleUpdateVersion,
    isLoading,
    editModelPortfolioVersion,
    setModelPortfolioVersion,
    getDataByIdLoading,
    modalPortfolioDelete,
    modelPortdolioData,
    modelCardData,
    columnsPersist,
    dispatch,
    getAllOldModels,
    setOldIdSelected,
    oldIdSelected,
    modelPortfolioId,
    replace,
    update,
    equityBasketDetailsData,
    modalPortfolioDefault,
    userData,
    companies,
    navigate,
  };
};

export default useModelPortfolioVersion;
