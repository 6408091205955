import { LoadingButton } from "@mui/lab";
import { GridColDef } from "@mui/x-data-grid-pro";
import {
  Divider,
  GKButton,
  GKDataGrid,
  GKSearchSelect,
  GKTextField,
  Grid2,
  TextField,
  Typography,
} from "components";
import DashboardLayout from "layouts/DashboardLayout";
import { Controller, useForm } from "react-hook-form";
import { extraColumnsField, matchDate } from "utils/helpers";
import useDocumentTitle from "utils/useDocumentTitle";

const PayCommission = () => {
  useDocumentTitle("Pay Commission");

  const form = useForm({
    defaultValues: {
      partner: "",
      paymentMethod: "",
      remark: "",
      amount: "",
    },
  });

  const columns: GridColDef[] = [
    {
      ...extraColumnsField("Client Name", "name", 150, 1),
      valueFormatter: (value) => matchDate(value.value),
    },
    {
      ...extraColumnsField("Invoice Date", "date", 150, 1),
      valueFormatter: (value) => matchDate(value.value),
    },
    {
      ...extraColumnsField("Invoice No.", "invoiceNumber", 150, 1),
      valueFormatter: (value) => matchDate(value.value),
    },
    {
      ...extraColumnsField("Amount", "amount", 100, 1),
      valueFormatter: (value) => matchDate(value.value),
    },
    {
      ...extraColumnsField("Commission Amount", "commissionAmount", 150, 1),
      valueFormatter: (value) => matchDate(value.value),
    },
    {
      ...extraColumnsField("Payment Status", "status", 150, 1),
      valueFormatter: (value) => matchDate(value.value),
    },
  ];

  return (
    <DashboardLayout title="Pay Commission">
      <form onSubmit={form.handleSubmit((values) => console.log(values))}>
        <Grid2 container spacing={2}>
          <Grid2 size={12}>
            <Typography fontSize={20} fontWeight={600} color="#121926">
              Pay Commission
            </Typography>
          </Grid2>
          <Grid2 container size={12} spacing={3}>
            <Grid2 size={6}>
              <Controller
                name="partner"
                control={form.control}
                rules={{
                  required: {
                    value: true,
                    message: "Please select a partner",
                  },
                }}
                render={({ field, fieldState: { error } }) => (
                  <GKSearchSelect
                    {...field}
                    inputLabel="Partner"
                    requiredField
                    options={[]}
                    getOptionLabel={(e) => e.name || e}
                    onChange={(e, val) => {
                      field.onChange(val.value);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Select partner"
                        error={!!error}
                        helperText={error?.message}
                      />
                    )}
                  />
                )}
              />
            </Grid2>
            <Grid2 size={6} mt={"20px"}>
              <LoadingButton variant="contained" type="submit">
                Fetch Commission
              </LoadingButton>
            </Grid2>
          </Grid2>
          <Grid2 size={12}>
            <Divider />
          </Grid2>
          <Grid2 size={12}>
            <Typography color="#4B5565">
              Select clients to pay commission
            </Typography>
          </Grid2>
          <Grid2 size={12}>
            <GKDataGrid
              checkboxSelection
              onRowSelectionModelChange={() => {}}
              rowSelectionModel={[]}
              loading={false}
              columns={columns}
              rows={[]}
              name="payCommission"
              showHeaderGrid={false}
              maxHeight={"calc(100vh - 485px)"}
            />
          </Grid2>
          <Grid2 size={12}>
            <Divider />
          </Grid2>
          <Grid2 size={4}>
            <Controller
              name="paymentMethod"
              control={form.control}
              render={({ field }) => (
                <GKSearchSelect
                  {...field}
                  inputLabel="Payment Method"
                  options={[]}
                  getOptionLabel={(e) => e.name || e}
                  onChange={(e, val) => field.onChange(val.value)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Select payment method"
                    />
                  )}
                />
              )}
            />
          </Grid2>
          <Grid2 size={4}>
            <Controller
              control={form.control}
              name="remark"
              render={({ ...field }) => (
                <GKTextField {...field} inputLabel={"Remarks"} />
              )}
            />
          </Grid2>
          <Grid2 size={4}>
            <Controller
              control={form.control}
              name="amount"
              render={({ ...field }) => (
                <GKTextField
                  {...field}
                  type="number"
                  inputLabel={"Total Payable Amount"}
                />
              )}
            />
          </Grid2>
          <Grid2 size={12}>
            <Divider />
          </Grid2>
          <Grid2 size={12} gap={1} display={"flex"} justifyContent={"flex-end"}>
            <GKButton variant="outlined" color="dark">
              Cancel
            </GKButton>
            <GKButton variant="contained">Pay Commission</GKButton>
          </Grid2>
        </Grid2>
      </form>
    </DashboardLayout>
  );
};

export default PayCommission;
