import {
  GKTypography,
  Grid2,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "components";
import { useFetchQuery } from "hooks/useQueries";
import { useFormContext } from "react-hook-form";
import { numberToCurrency } from "utils";
import { CLIENT_SUMMARY_BY_ID } from "utils/apiRoutes";
import { paletteLightTheme } from "utils/ColorTheme";
import { ThemeContainer } from "utils/Theme";

const CapitalGainCardSection = () => {
  const { theme } = ThemeContainer();

  const form = useFormContext();

  const { data, isFetching } = useFetchQuery({
    key: ["GET_CLIENT_SUMMARY_DATA", form.getValues("client")],
    route: CLIENT_SUMMARY_BY_ID(form.getValues("client")),
    enabled: !!form.getValues("client"),
  });

  return (
    <Grid2 container>
      <TableContainer>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell
                style={{
                  borderRight: `solid 1px ${paletteLightTheme.palette.grey[300]}`,
                  borderBottom: "none",
                  padding: 16,
                  width: "15%",
                }}
              >
                <GKTypography
                  fontSize={12}
                  color={theme.palette.secondaryText.main}
                >
                  Net Contribution
                </GKTypography>
                <GKTypography
                  fontWeight={600}
                  fontSize={18}
                  loading={isFetching}
                >
                  {numberToCurrency(data?.data?.netContribution || 0)}
                </GKTypography>
              </TableCell>
              <TableCell
                style={{
                  borderRight: `solid 1px ${paletteLightTheme.palette.grey[300]}`,
                  borderBottom: "none",
                  padding: 16,
                  width: "15%",
                }}
              >
                <Typography
                  fontSize={12}
                  color={theme.palette.secondaryText.main}
                >
                  Portfolio Value
                </Typography>
                <GKTypography
                  fontWeight={600}
                  fontSize={18}
                  loading={isFetching}
                >
                  {numberToCurrency(data?.data?.portfolioValue || 0)}
                </GKTypography>
              </TableCell>
              <TableCell
                style={{
                  borderRight: `solid 1px ${paletteLightTheme.palette.grey[300]}`,
                  borderBottom: "none",
                  padding: 16,
                  width: "15%",
                }}
              >
                <Typography
                  fontSize={12}
                  color={theme.palette.secondaryText.main}
                >
                  Net Unrealised Gain
                </Typography>
                <GKTypography
                  fontWeight={600}
                  fontSize={18}
                  loading={isFetching}
                >
                  {numberToCurrency(data?.data?.unrealisedGain || 0)}
                </GKTypography>
              </TableCell>
              <TableCell
                style={{
                  borderRight: `solid 1px ${paletteLightTheme.palette.grey[300]}`,
                  borderBottom: "none",
                  padding: 16,
                  width: "15%",
                }}
              >
                <Typography
                  fontSize={12}
                  color={theme.palette.secondaryText.main}
                >
                  Realised Gain
                </Typography>
                <GKTypography
                  fontWeight={600}
                  fontSize={18}
                  loading={isFetching}
                >
                  {numberToCurrency(data?.data?.realisedGain || 0)}
                </GKTypography>
              </TableCell>
              <TableCell
                style={{
                  borderRight: `solid 1px ${paletteLightTheme.palette.grey[300]}`,
                  borderBottom: "none",
                  padding: 16,
                  width: "15%",
                }}
              >
                <Typography
                  fontSize={12}
                  color={theme.palette.secondaryText.main}
                >
                  Dividends
                </Typography>
                <GKTypography
                  fontWeight={600}
                  fontSize={18}
                  loading={isFetching}
                >
                  {numberToCurrency(data?.data?.otherGains || 0)}
                </GKTypography>
              </TableCell>
              <TableCell
                style={{ borderBottom: "none", padding: 16, width: "10%" }}
              >
                <Typography
                  fontSize={12}
                  color={theme.palette.secondaryText.main}
                >
                  XIRR
                </Typography>
                <GKTypography
                  fontWeight={600}
                  fontSize={18}
                  loading={isFetching}
                >
                  {data?.data?.xirr || 0}%
                </GKTypography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Grid2>
  );
};

export default CapitalGainCardSection;
