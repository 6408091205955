import { Box, Divider, Grid2, Switch, Tab, Tabs, Typography } from "components";
import { useMutation } from "hooks/useQueries";
import { useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import axiosInstance from "utils/axiosInstance";
import { ThemeContainer } from "utils/Theme";
import useDocumentTitle from "utils/useDocumentTitle";
import CapitalGainCardSection from "./CapitalGainCardSection";
import NetUnrealisedGainSection from "./NetUnrealisedGainSection";
import { RealisedGainSection } from "./RealisedGainSection";
import SelectionSection from "./SelectionSection";

interface FormValues {
  client: number | null;
  instType: string[];
  isFinancialYearWise: boolean;
  responseData?: null | any;
  unrealisedGainReportData?: any;
}

const CapitalGain = () => {
  useDocumentTitle("Capital Gain");

  const { theme } = ThemeContainer();

  const [tab, setTab] = useState(0);

  const form = useForm<FormValues>({
    defaultValues: {
      client: null,
      instType: ["EQ"],
      isFinancialYearWise: false,
      responseData: {},
      unrealisedGainReportData: [],
    },
  });

  const { mutate, isLoading } = useMutation({
    mutationKey: ["CAPITAL_GAIN_MUTATION"],
    mutationFn: (values: FormValues) =>
      axiosInstance.post("/report/realised_gain_report/", values),
    onSuccess: (response) => {
      form.setValue("responseData", response.data?.data);
    },
  });

  const {
    mutate: unrealisedGainReport,
    isLoading: unrealisedGainReportLoading,
  } = useMutation({
    mutationKey: ["FETCH_NET_UNREALISED_GAIN"],
    mutationFn: (values: FormValues) =>
      axiosInstance.post("/report/unrealised_gain_report/", values),
    onSuccess: (response) => {
      form.setValue("unrealisedGainReportData", response.data?.data);
    },
  });

  return (
    <FormProvider {...form}>
      <form
        id="capital-gain-form"
        onSubmit={form.handleSubmit((values) => {
          delete values?.responseData;
          delete values?.unrealisedGainReportData;

          mutate(values);
          unrealisedGainReport(values);
        })}
      >
        <Grid2 container spacing={2}>
          <Grid2 size={12}>
            <SelectionSection isLoading={isLoading} />
          </Grid2>
          <Grid2 size={12}>
            <CapitalGainCardSection />
            <Divider sx={{ mt: 1 }} />
          </Grid2>
          <Grid2 container size={12} display={"inline"} spacing={2}>
            <Grid2
              size={12}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Tabs
                sx={{
                  "& .MuiTabs-flexContainer": {
                    backgroundColor: theme.palette.light.main,
                  },
                }}
                onChange={(e, val) => setTab(val)}
                value={tab}
              >
                <Tab label={"Realised Gain"} disableRipple />
                <Tab label={"Net Unrealised Gain"} disableRipple />
              </Tabs>
              {tab === 0 && (
                <Controller
                  control={form.control}
                  name="isFinancialYearWise"
                  render={({ field }) => (
                    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                      <Typography fontSize={12}>Financial Year wise</Typography>
                      <Switch
                        checked={field.value}
                        onChange={(e) => {
                          field.onChange(e.target.checked);
                          mutate({
                            client: form.getValues("client"),
                            instType: form.getValues("instType"),
                            isFinancialYearWise: e.target.checked,
                          });
                        }}
                      />
                      <Typography fontSize={12}>Portfolio wise</Typography>
                    </Box>
                  )}
                />
              )}
            </Grid2>
            <Grid2 size={12}>
              <Divider />
            </Grid2>
          </Grid2>
          <Grid2 size={12}>
            {tab === 0 ? (
              <RealisedGainSection isLoading={isLoading} />
            ) : (
              <NetUnrealisedGainSection
                isLoading={unrealisedGainReportLoading}
              />
            )}
          </Grid2>
        </Grid2>
      </form>
    </FormProvider>
  );
};

export default CapitalGain;
