import { GKDataGrid, Grid2, IconButton, LuEye, Typography } from "components";
import useGenerateQueryParams from "hooks/useGenerateQueryParams";
import { useFetchQuery, useMutation } from "hooks/useQueries";
import DashboardLayout from "layouts/DashboardLayout";
import { useState } from "react";
import { useParams } from "react-router-dom";
import axiosInstance from "utils/axiosInstance";
import { extraColumnsField } from "utils/helpers";
import { moneyCommaSeparator } from "utils/MathFunction";
import useDocumentTitle from "utils/useDocumentTitle";
import OrderPortfolioTradesModal from "./OrderPortfolioTradesModal";

interface TradesOfPortfolioInterface {
  isConverted: boolean;
  id: string;
  nse: string;
  bse: string;
  company: string;
  quantity: number;
  price: number;
  transactionType: string;
  smallcaseStatus: string;
  smallcaseError: any;
  filledQuantity: number;
  orderDate: string;
}

const OrderManagerPortfolios = () => {
  useDocumentTitle("Order Manager Portfolios");

  const [allTradesOfPortfolio, setAllTradesOfPortfolio] = useState<
    TradesOfPortfolioInterface[]
  >([]);

  const [selectedData, setSelectedData] = useState(null);

  const { orderId } = useParams();

  const [showTradesModal, setShowTradesModal] = useState(false);

  const requestBody = useGenerateQueryParams(
    "equityBasketOrderManagerPortfolio",
  );

  const { data, isFetching } = useFetchQuery({
    key: ["GET_ALL_PORTFOLIO_BY_ORDER_ID", requestBody],
    route: `/model_portfolio/${orderId}/order-manager-portfolios/`,
    requestBody,
  });

  const { mutate: getPortfolioTrade } = useMutation({
    mutationFn: (portfolioId: any) =>
      axiosInstance.get(
        `/model_portfolio/${orderId}/order-manager-trade/${portfolioId}/`,
      ),
    onSuccess: (response) => {
      setShowTradesModal(true);
      setAllTradesOfPortfolio(response?.data?.data);
    },
  });

  return (
    <DashboardLayout title="Order Manager Portfolios">
      <Grid2 container>
        <Grid2 size={12}>
          <GKDataGrid
            rows={data?.data?.data || []}
            loading={isFetching}
            name="equityBasketOrderManagerPortfolio"
            totalCount={data?.data?.totalCount || 0}
            columns={[
              {
                ...extraColumnsField("Portfolio", "portfolio", 0, 1),
                renderCell: (params) => {
                  return (
                    <Grid2
                      container
                      display={"flex"}
                      flexDirection={"column"}
                      alignItems={"flex-start"}
                    >
                      <Typography fontWeight={700}>
                        {params?.row?.clientName}
                      </Typography>
                      <Typography fontSize={12}>
                        {params?.row?.name} - {params?.row?.portfolioId}
                      </Typography>
                    </Grid2>
                  );
                },
              },
              {
                ...extraColumnsField(
                  "Net Trade Amount",
                  "netTradeAmount",
                  0,
                  1,
                ),
                valueFormatter: (params) => moneyCommaSeparator(params?.value),
              },
              {
                ...extraColumnsField("Buy Amount", "totalBuyAmount", 0, 1),
                valueFormatter: (params) => moneyCommaSeparator(params?.value),
              },
              {
                ...extraColumnsField("Sell Amount", "totalSellAmount", 0, 1),
                valueFormatter: (params) => moneyCommaSeparator(params?.value),
              },
              {
                ...extraColumnsField("Sending Mode", "sendingMode", 0, 1),
              },
              {
                ...extraColumnsField("Broker", "broker", 0, 1),
              },
              {
                align: "right",
                headerAlign: "right",
                ...extraColumnsField("Actions", "action", 0, 1),
                renderCell: (params) => {
                  return (
                    <IconButton
                      onClick={() => {
                        setSelectedData(params.row);
                        getPortfolioTrade(params?.row?.id);
                      }}
                    >
                      <LuEye />
                    </IconButton>
                  );
                },
              },
            ]}
          />
        </Grid2>
      </Grid2>
      {showTradesModal && (
        <OrderPortfolioTradesModal
          open={showTradesModal}
          portfolioData={selectedData}
          setOpen={setShowTradesModal}
          allTradesOfPortfolio={allTradesOfPortfolio}
        />
      )}{" "}
    </DashboardLayout>
  );
};

export default OrderManagerPortfolios;
