import { GKDataGrid } from "components";
import useGenerateQueryParams from "hooks/useGenerateQueryParams";
import { useFetchQuery } from "hooks/useQueries";
import { useParams } from "react-router-dom";
import { CLIENT_HOLDING_BY_ID } from "utils/apiRoutes";
import { assetsHoldingsColumns } from "utils/columns";
import { QueryKeys } from "utils/queryKey";
import useDocumentTitle from "utils/useDocumentTitle";

const HoldingsMainPage = () => {
  useDocumentTitle("Client Holdings");

  const { id } = useParams();

  const requestBody = useGenerateQueryParams("clientHolding");

  const { data: holdingTotalData, isFetching } = useFetchQuery({
    key: [QueryKeys.CLIENT_HOLDINGS, requestBody],
    route: CLIENT_HOLDING_BY_ID(id),
    requestBody,
  });

  return (
    <GKDataGrid
      loading={isFetching}
      columns={assetsHoldingsColumns}
      rows={holdingTotalData?.data?.data || []}
      zeroHolding
      maxHeight={"calc(100vh - 350px)"}
      totalCount={holdingTotalData?.data?.totalCount || 0}
      name="clientHolding"
      tableName="client_holding_list"
      exportFile={{
        path: CLIENT_HOLDING_BY_ID(id),
        exportName: "ClientHoldingList",
      }}
    />
  );
};

export default HoldingsMainPage;
