import { useAppContext } from "AppContext";
import {
  AnimationComponent,
  Box,
  Grid2,
  Show,
  Tab,
  Tabs,
  Typography,
} from "components";
import DashboardLayout from "layouts/DashboardLayout";
import { UploadManager } from "Pages";
import CustomBrokerImport from "Pages/CustomBrokerImport";
import ReconManager from "Pages/ReconManager";
import { BiLockAlt } from "react-icons/bi";
import { UserRoleTypes } from "utils/common";
import { ThemeContainer } from "utils/Theme";

const ImportsMenu = () => {
  const {
    state: { tab: tabValue, userData },
    dispatch,
  } = useAppContext();

  const { theme } = ThemeContainer();

  const tabs = [
    {
      label: "Import Manager",
      component: <UploadManager />,
      canAccess: true,
    },
    {
      label: "Recon Manager",
      component: <ReconManager />,
      canAccess: userData?.grantAccess,
    },
    userData?.userRole === UserRoleTypes.advisor &&
      userData?.brokerSupport?.adityaBirlaSupport && {
        label: "Import from Broker",
        component: <CustomBrokerImport />,
        canAccess: userData?.grantAccess,
      },
  ].filter(Boolean);

  return (
    <DashboardLayout title="Dashboard">
      <Grid2 container spacing={2}>
        <Grid2 size={12}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              sx={{
                "& .MuiTabs-flexContainer": {
                  backgroundColor: theme.palette.light.main,
                },
              }}
              onChange={(e, val) => {
                dispatch({
                  type: "TAB",
                  data: { ...tabValue, tabValueOfImport: val },
                });
              }}
              value={tabValue?.tabValueOfImport}
            >
              {tabs.map((tabItem, index) => (
                <Tab
                  key={index}
                  disabled={!tabItem.canAccess}
                  label={
                    <Box display={"flex"} gap={1}>
                      <Typography>{tabItem.label}</Typography>
                      {!tabItem.canAccess && <BiLockAlt size={16} />}
                    </Box>
                  }
                  disableRipple
                />
              ))}
            </Tabs>
          </Box>
        </Grid2>
        <Grid2 size={12}>
          {tabs.map((tabItem, index) => (
            <Show.When
              key={index}
              isTrue={tabValue?.tabValueOfImport === index}
            >
              <AnimationComponent>{tabItem.component}</AnimationComponent>
            </Show.When>
          ))}
        </Grid2>
      </Grid2>
    </DashboardLayout>
  );
};

export default ImportsMenu;
