/* eslint-disable react/no-danger */
import {
  Button,
  Card,
  CgMathPlus,
  Chip,
  Divider,
  GKFilePreviewCard,
  GKTypography,
  Grid2,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "components";
import { ViewEquityBasketInterface } from "interfaces/EquityBasket";
import { isEmpty } from "lodash";
import { paletteLightTheme } from "utils/ColorTheme";
import { handleCapitalize } from "utils/common";
import { getHexaColor } from "utils/constants";
import { matchDate } from "utils/helpers";
import { handleRemoveUnderscore } from "utils/StringFunctions";
import { ThemeContainer } from "utils/Theme";
import useDocumentTitle from "utils/useDocumentTitle";

interface Props {
  data: ViewEquityBasketInterface;
}

const EquityBasketView = (props: Props) => {
  useDocumentTitle("View Equity basket");

  const { theme } = ThemeContainer();

  const { data: basketData } = props;

  /** ========================================================================================================= */

  return (
    <Grid2 container spacing={2} size={12}>
      <Grid2 size={12}>
        <GKTypography fontWeight={600} fontSize={18}>
          {basketData?.name || "-"}
        </GKTypography>
        <GKTypography fontWeight={400} fontSize={12}>
          Managed by :{" "}
          <span style={{ fontWeight: 600 }}>
            {basketData?.managedBy || "-"}
          </span>
        </GKTypography>
      </Grid2>
      <Grid2 size={12}>
        <GKTypography
          fontWeight={400}
          fontSize={12}
          color={theme.palette.secondaryText.main}
        >
          Overview
        </GKTypography>
        <GKTypography fontSize={14} fontWeight={500}>
          {basketData?.overview || "-"}
        </GKTypography>
      </Grid2>

      <Grid2 size={7.9} spacing={2}>
        <Grid2 container spacing={2} size={12}>
          <Grid2 size={12}>
            <TableContainer>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell
                      style={{
                        width: "25%",
                        borderRight: `solid 1px ${paletteLightTheme.palette.border.main}`,
                      }}
                    >
                      <Typography
                        fontWeight={500}
                        fontSize={12}
                        color={theme.palette.secondaryText.main}
                      >
                        Creation Date
                      </Typography>
                      <Typography textTransform={"capitalize"}>
                        {matchDate(basketData.basketCreationDate)}
                      </Typography>
                    </TableCell>
                    <TableCell
                      style={{
                        width: "25%",
                        borderRight: `solid 1px ${paletteLightTheme.palette.border.main}`,
                      }}
                    >
                      <Typography
                        fontWeight={500}
                        fontSize={12}
                        color={theme.palette.secondaryText.main}
                      >
                        Risk Profile
                      </Typography>
                      {basketData?.riskProfile?.map((dataItem) => {
                        return (
                          <Chip
                            style={{
                              borderRadius: 8,
                              padding: "4px 0px",
                              height: "unset",
                              background: `${getHexaColor(dataItem)}22`,
                              color: getHexaColor(dataItem),
                              marginRight: 4,
                            }}
                            label={dataItem || "-"}
                          />
                        );
                      })}
                    </TableCell>
                    <TableCell
                      style={{
                        width: "25%",
                        borderRight: `solid 1px ${paletteLightTheme.palette.border.main}`,
                      }}
                    >
                      <Typography
                        fontWeight={500}
                        fontSize={12}
                        color={theme.palette.secondaryText.main}
                      >
                        Volatility
                      </Typography>
                      <Typography textTransform={"capitalize"}>
                        {isEmpty(basketData?.volatility)
                          ? "-"
                          : handleCapitalize(
                              `${basketData?.volatility} Volatile` || "-",
                            )}
                      </Typography>
                    </TableCell>
                    <TableCell style={{ width: "25%" }}>
                      <Typography
                        fontWeight={500}
                        fontSize={12}
                        color={theme.palette.secondaryText.main}
                      >
                        Time Horizon
                      </Typography>
                      <Typography>
                        {handleCapitalize(
                          handleRemoveUnderscore(basketData?.timePeriod || "-"),
                        )}
                      </Typography>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell
                      style={{
                        width: "25%",
                        borderRight: `solid 1px ${paletteLightTheme.palette.border.main}`,
                      }}
                    >
                      <Typography
                        fontWeight={500}
                        fontSize={12}
                        color={theme.palette.secondaryText.main}
                      >
                        Benchmark
                      </Typography>
                      <Typography>
                        {basketData?.benchmarkName || "-"}
                      </Typography>
                    </TableCell>
                    <TableCell
                      style={{
                        width: "25%",
                        borderRight: `solid 1px ${paletteLightTheme.palette.border.main}`,
                      }}
                    >
                      <Typography
                        fontWeight={500}
                        fontSize={12}
                        color={theme.palette.secondaryText.main}
                      >
                        Comparable Index
                      </Typography>
                      <Typography textTransform={"capitalize"}>
                        {basketData?.comparableIndex || "-"}
                      </Typography>
                    </TableCell>
                    <TableCell
                      style={{
                        width: "25%",
                        borderRight: `solid 1px ${paletteLightTheme.palette.border.main}`,
                      }}
                    >
                      <Typography
                        fontWeight={500}
                        fontSize={12}
                        color={theme.palette.secondaryText.main}
                      >
                        Constituents Type
                      </Typography>
                      <Typography>
                        {basketData?.constituentsType || "Stocks & ETFs"}
                      </Typography>
                    </TableCell>
                    <TableCell style={{ width: "25%" }}>
                      <Typography
                        fontWeight={500}
                        fontSize={12}
                        color={theme.palette.secondaryText.main}
                      >
                        Asset Class Type
                      </Typography>
                      <Typography>
                        {basketData?.assetClassType || "-"}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid2>

          <Grid2 size={12}>
            <GKTypography
              fontWeight={400}
              fontSize={12}
              color={theme.palette.secondaryText.main}
            >
              Rationale
            </GKTypography>
            <div
              className="ck"
              dangerouslySetInnerHTML={{
                __html: basketData?.rationale || "",
              }}
            />
          </Grid2>
          <Grid2 size={12}>
            <GKTypography
              fontWeight={400}
              fontSize={12}
              color={theme.palette.secondaryText.main}
            >
              Investment Philosophy
            </GKTypography>
            <div
              className="ck"
              dangerouslySetInnerHTML={{
                __html: basketData?.investmentPhilosophy || "",
              }}
            />
          </Grid2>
          <Grid2 size={12}>
            <Typography
              fontWeight={400}
              fontSize={12}
              color={theme.palette.secondaryText.main}
              mb={1}
            >
              Investment Strategies
            </Typography>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                gap: 4,
              }}
            >
              {basketData?.investmentStyle?.map((dataItem) => {
                return (
                  <Chip
                    style={{
                      borderRadius: 8,
                      padding: "4px 0px",
                      height: "unset",
                    }}
                    label={dataItem || "-"}
                  />
                );
              })}
            </div>
          </Grid2>
        </Grid2>
      </Grid2>
      <Grid2 size={0.1}>
        <Divider orientation="vertical" />
      </Grid2>
      <Grid2 size={3.9}>
        <Grid2 container spacing={2} size={12}>
          <Grid2 size={12} mt={2}>
            <Typography
              fontWeight={400}
              fontSize={12}
              color={theme.palette.secondaryText.main}
              mb={1}
            >
              Research File / Fact Sheet / Marketing Material
            </Typography>
            {basketData?.researchFiles?.map((dataItems) => {
              return <GKFilePreviewCard index={1} data={dataItems} size="-" />;
            })}
          </Grid2>
          <Grid2
            size={12}
            borderTop={`1px solid ${paletteLightTheme.palette.border.main}`}
            mt={1}
          >
            <GKTypography
              fontWeight={400}
              fontSize={12}
              color={theme.palette.secondaryText.main}
            >
              Fee Plans
            </GKTypography>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 8,
              }}
            >
              {basketData?.feePlansList
                ?.sort((a, b) => b.isDefault - a.isDefault)
                ?.map((item, index) => {
                  return (
                    <Card
                      sx={{
                        p: 1,
                        minWidth: "-webkit-fill-available",
                        maxWidth: "250px",
                      }}
                      key={index}
                    >
                      <Grid2
                        container
                        justifyContent={"space-between"}
                        flexDirection={"row"}
                        spacing={1}
                      >
                        <Grid2 size={6}>
                          <GKTypography fontWeight={600} fontSize={16}>
                            {item.name || "-"}
                          </GKTypography>
                        </Grid2>
                        <Grid2 size={6} textAlign={"right"}>
                          {item?.isDefault && (
                            <Button
                              sx={{
                                "&:hover": {
                                  cursor: "default",
                                  background:
                                    paletteLightTheme.palette.primary.main,
                                },
                              }}
                              variant={
                                item?.isDefault ? "contained" : "outlined"
                              }
                              color={item?.isDefault ? "primary" : "dark"}
                              size="extraSmall"
                            >
                              {item?.isDefault ? "Default" : "Set As Default"}
                            </Button>
                          )}
                        </Grid2>
                        {item.managementFee?.fee && (
                          <Grid2
                            size={5}
                            display={"flex"}
                            justifyContent={"center"}
                            flexDirection={"column"}
                          >
                            <Typography
                              textTransform={"capitalize"}
                              color={theme.palette.secondaryText.main}
                              fontSize={12}
                            >
                              {item.managementFee?.name || "-"}
                            </Typography>
                            <GKTypography fontWeight={600}>
                              {item.managementFee?.fee || "-"}%
                            </GKTypography>
                          </Grid2>
                        )}
                        {item.performanceFee?.fee &&
                          item.managementFee?.fee && (
                            <Grid2
                              size={2}
                              display={"flex"}
                              alignItems={"center"}
                              justifyContent={"center"}
                            >
                              <div
                                style={{
                                  border: `1px solid ${paletteLightTheme.palette.border.main}`,
                                  borderRadius: 4,
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  padding: 4,
                                }}
                              >
                                <CgMathPlus
                                  color={theme.palette.secondaryText.main}
                                />
                              </div>
                            </Grid2>
                          )}
                        {item.performanceFee?.fee && (
                          <Grid2
                            size={5}
                            display={"flex"}
                            justifyContent={"center"}
                            flexDirection={"column"}
                          >
                            <Typography
                              textTransform={"capitalize"}
                              color={theme.palette.secondaryText.main}
                              fontSize={12}
                            >
                              {item.performanceFee?.name || "-"}
                            </Typography>
                            <GKTypography fontWeight={600}>
                              {item.performanceFee?.fee || "-"}%
                            </GKTypography>
                          </Grid2>
                        )}
                      </Grid2>
                    </Card>
                  );
                })}
            </div>
          </Grid2>
        </Grid2>
      </Grid2>
    </Grid2>
  );
};

export default EquityBasketView;
