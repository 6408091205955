import { LoadingButton } from "@mui/lab";
import { GridColDef, GridRowParams } from "@mui/x-data-grid-pro";
import {
  GKButton,
  GKClientDataGrid,
  GKDataGrid,
  Grid2,
  PiEyeDuotone,
  Show,
  StatusField,
  Typography,
} from "components";
import useGenerateQueryParams from "hooks/useGenerateQueryParams";
import { useFetchQuery, useMutation } from "hooks/useQueries";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { removeUnderscoreAndFirstLetterCapital } from "utils";
import axiosInstance from "utils/axiosInstance";
import { extraColumnsField } from "utils/helpers";

interface APIResponseInterface {
  rowId: number;
  data: { [key: string]: boolean };
}

const UploadedProof = () => {
  const { id } = useParams();

  const [disableRow, setDisableRow] = useState(null);

  const requestBody = useGenerateQueryParams("clientUploadProof");

  const { data, isFetching, refetch } = useFetchQuery({
    key: ["GET_ALL_UPLOADED_PROOF", requestBody],
    route: `/client/${id}/proofs/`,
    requestBody,
  });

  const { mutate } = useMutation({
    mutationKey: ["ACCEPT_REJECT_PROOF"],
    mutationFn: (data: APIResponseInterface) =>
      axiosInstance.patch(`/client/${data?.rowId}/proofs/`, data.data),
    onSuccess: () => {
      setDisableRow(null);
      refetch();
    },
    onError: () => {
      setDisableRow(null);
    },
  });

  const DocumentDetailsTable = (params: GridRowParams) => {
    const { row } = params;

    const columns = (proofAccepted: boolean) => {
      return [
        {
          ...extraColumnsField("Portfolio", "portfolio", 250, 1),
        },
        {
          ...extraColumnsField("Document Type", "documentType", 150, 1),
          valueGetter: (params) =>
            removeUnderscoreAndFirstLetterCapital(params.row.documentType),
        },
        {
          ...extraColumnsField("Status", "accepted", 150, 0),
          renderCell: () => {
            return (
              <StatusField
                statusString={proofAccepted ? "approved" : "pending"}
              />
            );
          },
        },
        {
          ...extraColumnsField("Actions", "action", 100, 1),
          align: "right",
          headerAlign: "right",
          renderCell: (row) => {
            return (
              <Grid2 container spacing={1}>
                <GKButton
                  variant="outlined"
                  color="dark"
                  sx={{ p: "10px", minWidth: "1px" }}
                  disabled={row?.row?.id === disableRow}
                  onClick={() => {
                    const link = document.createElement("a");
                    link.href = row?.row?.document;
                    link.target = "_blank";
                    link.click();
                  }}
                >
                  <PiEyeDuotone />
                </GKButton>
              </Grid2>
            );
          },
        },
      ] as GridColDef[];
    };

    return (
      <Grid2 container p={2} spacing={2}>
        <Show.When isTrue={row?.capitalProofs?.length !== 0}>
          <Grid2 container size={12} spacing={2}>
            <Grid2
              size={12}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Typography fontWeight={600} fontSize={16}>
                Capital Proof
              </Typography>
              <Show.When isTrue={!row?.capitalProofAccepted}>
                <div
                  style={{ display: "flex", alignItems: "center", gap: "8px" }}
                >
                  <LoadingButton
                    variant="outlined"
                    color="error"
                    size="small"
                    disabled={
                      row?.capitalProofAccepted || row?.id === disableRow
                    }
                    onClick={() => {
                      setDisableRow(row?.id);
                      mutate({
                        rowId: row.id,
                        data: { capitalProofAccepted: false },
                      });
                    }}
                  >
                    Reject
                  </LoadingButton>
                  <LoadingButton
                    variant="contained"
                    size="small"
                    disabled={
                      row?.capitalProofAccepted || row?.id === disableRow
                    }
                    onClick={() => {
                      setDisableRow(row?.id);
                      mutate({
                        rowId: row.id,
                        data: { capitalProofAccepted: true },
                      });
                    }}
                  >
                    Approve
                  </LoadingButton>
                </div>
              </Show.When>
            </Grid2>
            <Grid2 size={12}>
              <GKClientDataGrid
                name="document"
                showHeaderGrid={false}
                columns={columns(row?.capitalProofAccepted)}
                rows={row?.capitalProofs || []}
              />
            </Grid2>
          </Grid2>
        </Show.When>
        <Show.When isTrue={row?.holdingProofs?.length !== 0}>
          <Grid2 container size={12} spacing={2}>
            <Grid2
              size={12}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Typography fontWeight={600} fontSize={16}>
                Holding Proof
              </Typography>
              <Show.When isTrue={!row?.holdingProofAccepted}>
                <div
                  style={{ display: "flex", alignItems: "center", gap: "8px" }}
                >
                  <LoadingButton
                    variant="outlined"
                    color="error"
                    size="small"
                    disabled={
                      row?.holdingProofAccepted || row?.id === disableRow
                    }
                    onClick={() => {
                      setDisableRow(row?.id);
                      mutate({
                        rowId: row.id,
                        data: { holdingProofAccepted: false },
                      });
                    }}
                  >
                    Reject
                  </LoadingButton>
                  <LoadingButton
                    variant="contained"
                    size="small"
                    disabled={
                      row?.holdingProofAccepted || row?.id === disableRow
                    }
                    onClick={() => {
                      setDisableRow(row?.id);
                      mutate({
                        rowId: row.id,
                        data: { holdingProofAccepted: true },
                      });
                    }}
                  >
                    Approve
                  </LoadingButton>
                </div>
              </Show.When>
            </Grid2>
            <Grid2 size={12}>
              <GKClientDataGrid
                name="document"
                showHeaderGrid={false}
                columns={columns(row?.holdingProofAccepted)}
                rows={row?.holdingProofs || []}
              />
            </Grid2>
          </Grid2>
        </Show.When>
      </Grid2>
    );
  };

  const columns: GridColDef[] = [
    { ...extraColumnsField("Basket Name", "basket", 150, 1) },
    {
      ...extraColumnsField("Actions", "action", 100, 1),
      align: "right",
      headerAlign: "right",
      renderCell: (params) => (
        <GKButton
          variant="link"
          disabled={params?.row?.id === disableRow}
          onClick={() => {
            setDisableRow(params?.row?.id);
            mutate({ rowId: params?.row.id, data: { both: true } });
          }}
        >
          Request Proof
        </GKButton>
      ),
    },
  ];

  return (
    <Grid2 container>
      <Grid2 size={12}>
        <GKDataGrid
          loading={isFetching}
          columns={columns}
          rows={data?.data?.data || []}
          totalCount={data?.data?.totalCount || 0}
          name="clientUploadProof"
          tableName="client_upload_proof"
          columnsButton={false}
          getDetailPanelContent={DocumentDetailsTable}
        />
      </Grid2>
    </Grid2>
  );
};

export default UploadedProof;
