/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import { GKButton, GKModal, GKSelect, Grid2, MenuItem } from "components";
import { useMutation } from "hooks/useQueries";
import { useState } from "react";
import axiosInstance from "utils/axiosInstance";
import { GKModalProps } from "utils/commonInterface";
import { refetchQuery } from "utils/helpers";
import { QueryKeys } from "utils/queryKey";
import SheetConfirmation from "./SheetConfirmation";

interface Props {
  data: any;
  setUploadModal: any;
  otherData?: any;
  formik?: any;
}

const SheetMappingModal = ({
  open,
  setOpen,
  data: dummyData,
  otherData,
  setUploadModal,
  formik,
}: Props & GKModalProps) => {
  const [nullCompanies, setNullCompanies] = useState(dummyData);

  const [showConfirmation, setShowConfirmation] = useState(false);

  const { mutate: handleSheetSubmit } = useMutation({
    mutationFn: (data) =>
      axiosInstance.put(
        `/transactions-upload-format-fields/${dummyData?.formatData}/`,
        {
          format: data,
          format_name: otherData || "",
          file: dummyData?.transactionUpload?.id,
        },
      ),
    onSuccess: () => {
      setShowConfirmation(true);
      refetchQuery(QueryKeys.UPLOAD_MANAGER_FAVORITE_SAMPLES);
    },
  });

  const handleUpdateAllField = async (data: any) => {
    delete data?.notMatch;
    await handleSheetSubmit(data);
  };

  return (
    <>
      <GKModal
        modalTitle="Sheet Mapping"
        open={open}
        setOpen={setOpen}
        width={"40%"}
        footer={
          <Grid2 container justifyContent={"flex-end"} gap={1}>
            <GKButton
              onClick={() => {
                handleUpdateAllField(nullCompanies);
              }}
              variant="contained"
              size="medium"
            >
              Submit
            </GKButton>
          </Grid2>
        }
      >
        <Grid2 container spacing={1}>
          {Object.entries(dummyData)
            ?.filter(([, value]) => value === null)
            ?.map(([key, val]) => (
              <Grid2 size={12} key={key}>
                <GKSelect
                  inputLabel={key}
                  name={key}
                  onChange={(event) => {
                    setNullCompanies({
                      ...nullCompanies,
                      [key]:
                        key === event.target.name ? event.target.value : val,
                    });
                  }}
                >
                  {Object.entries(dummyData?.notMatch)?.map(([, val]: any) => (
                    <MenuItem value={val} key={val}>
                      {val}
                    </MenuItem>
                  ))}
                </GKSelect>
              </Grid2>
            ))}
        </Grid2>
      </GKModal>
      {showConfirmation && (
        <SheetConfirmation
          open={showConfirmation}
          setOpen={setShowConfirmation}
          submit={formik}
          setMappingModal={setOpen}
          setUploadModal={setUploadModal}
        />
      )}
    </>
  );
};

export default SheetMappingModal;
