import {
  Alert,
  GKButton,
  GKClientDataGrid,
  Grid2,
  IoCloseCircleOutline,
  Typography,
} from "components";
import { useFormContext } from "react-hook-form";
import toast from "react-hot-toast";
import axiosInstance from "utils/axiosInstance";
import { returnReviewHeaders } from "utils/helpers";
import { ThemeContainer } from "utils/Theme";

const ReviewMapping = () => {
  const form = useFormContext();

  const reviewData = form.getValues("step3");

  const { theme } = ThemeContainer();

  const downloadSingleRowErrors = async () => {
    await axiosInstance
      ?.get(
        `/transaction_upload/${
          form.getValues("fileObject")?.id
        }/download_errors/?full=false&portfolio_id=${form.getValues(
          "step1.portfolioId",
        )}`,
      )
      .then((response: any) => {
        const a = document.createElement("a");
        a.href = window?.URL?.createObjectURL(new Blob([response.data]));
        a.download = `${form.getValues("step1.type")?.toLowerCase()}.csv`;
        a.click();
        a.remove();
      })
      .catch((response: any) => {
        const error = response?.json();
        toast.error(error?.message || "Something went wrong");
      });
  };

  const transactions = reviewData?.reviewResponse?.data?.transactions || [];

  return (
    <Grid2 size={12} container spacing={1}>
      <Grid2
        size={12}
        sx={{
          display:
            transactions?.filter((data: any) => data.error !== "")?.length === 0
              ? "none"
              : "flex",
          justifyContent: "space-between",
        }}
      >
        <Alert
          sx={{ width: "100%" }}
          severity="error"
          icon={<IoCloseCircleOutline size={14} />}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography>There are errors found in the import.</Typography>

            <GKButton
              style={{
                marginLeft: 10,
                padding: 0,
              }}
              color="error"
              variant="link"
              onClick={() => {
                downloadSingleRowErrors();
              }}
            >
              Download Errors
            </GKButton>
          </div>
        </Alert>
      </Grid2>
      <Grid2 size={12}>
        <GKClientDataGrid
          name="brokerReviewMapping"
          headerTitle={
            <div style={{ marginRight: 8 }}>
              <Typography fontSize={14} fontWeight={600} whiteSpace={"nowrap"}>
                Review Imported data
              </Typography>
              <Typography
                whiteSpace={"nowrap"}
                fontSize={12}
                color={theme?.palette?.grey[700]}
              >
                Total Entries: {transactions?.length}
              </Typography>
            </div>
          }
          density="compact"
          pagination={false}
          maxHeight={"calc(100vh - 500px)"}
          rows={transactions}
          columns={returnReviewHeaders("stock")}
          initialState={{
            columns: {
              columnVisibilityModel: {
                stt: false,
                gst: false,
                stampCharges: false,
                otherCharges: false,
                transactionCharges: false,
                sebiTurnoverFees: false,
              },
            },
            pagination: {
              paginationModel: {
                pageSize: 25,
              },
            },
          }}
          // headerComponent={
          //   <Grid2 container justifyContent={"flex-end"} alignItems={"center"}>
          //     <Grid2>
          //       {reviewData?.reviewResponse?.transactions?.filter(
          //         (data: any) => data.isDuplicate
          //       )?.length === 0 ? (
          //         ""
          //       ) : (
          //         <GKButton
          //           onClick={() => {
          //             form.setValue(
          //               "step3.showDuplicates",
          //               !form.getValues("step3.showDuplicates")
          //             );
          //           }}
          //           variant="outlined"
          //           color="dark"
          //           size="medium"
          //           style={{ whiteSpace: "nowrap" }}
          //           startIcon={
          //             form.getValues("step3.showDuplicates") ? (
          //               <TbArrowBackUp />
          //             ) : (
          //               ""
          //             )
          //           }
          //         >
          //           {form.getValues("step3.showDuplicates")
          //             ? "Back"
          //             : "View Duplicates"}
          //         </GKButton>
          //       )}
          //     </Grid2>
          //   </Grid2>
          // }
        />
      </Grid2>
    </Grid2>
  );
};

export default ReviewMapping;
