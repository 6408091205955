import { GKButton, GKModal, GKTextField, Grid2 } from "components";
import { useMutation } from "hooks/useQueries";
import { Controller, useForm } from "react-hook-form";
import axiosInstance from "utils/axiosInstance";
import { GKModalProps } from "utils/commonInterface";
import { refetchQuery } from "utils/helpers";
import { QueryKeys } from "utils/queryKey";

interface LedgerBalanceInterface {
  portfolioId: number;
  brokerLedgerBalance: number;
}

interface Props {
  ledgerBalanceData: LedgerBalanceInterface;
}

const AvailableMarginModal = (props: Props & GKModalProps) => {
  const { open, setOpen, ledgerBalanceData } = props;

  const form = useForm({
    defaultValues: {
      brokerLedgerBalance: +ledgerBalanceData?.brokerLedgerBalance || 0,
    },
  });

  const { mutate: handleUpdateMargin } = useMutation({
    mutationKey: ["UPDATE_AVAILABLE_MARGIN"],
    mutationFn: (data: { brokerLedgerBalance: number }) =>
      axiosInstance.patch(
        `/portfolios/${ledgerBalanceData?.portfolioId}/margin_bal/`,
        data,
      ),
    onSuccess: () => {
      refetchQuery(QueryKeys.PORTFOLIO_CASH_LIST);
      setOpen(false);
    },
  });

  return (
    <GKModal
      open={open}
      setOpen={setOpen}
      modalTitle="Edit Portfolio"
      width={"40%"}
      footer={
        <GKButton
          variant="contained"
          type="submit"
          form="available margin-form"
        >
          Save
        </GKButton>
      }
    >
      <form
        id="available margin-form"
        onSubmit={form.handleSubmit((values: { brokerLedgerBalance: number }) =>
          handleUpdateMargin(values),
        )}
      >
        <Grid2 container>
          <Grid2 size={12}>
            <Controller
              name="brokerLedgerBalance"
              control={form.control}
              render={({ field }) => (
                <GKTextField
                  {...field}
                  type="number"
                  inputLabel={"Available Margin"}
                  onChange={(
                    event: React.ChangeEvent<
                      HTMLInputElement | HTMLTextAreaElement
                    >,
                  ) => {
                    if (event.target instanceof HTMLInputElement) {
                      field.onChange(event.target.valueAsNumber);
                    }
                  }}
                />
              )}
            />
          </Grid2>
        </Grid2>
      </form>
    </GKModal>
  );
};

export default AvailableMarginModal;
