/* eslint-disable @typescript-eslint/no-unused-expressions */
import { GridColDef } from "@mui/x-data-grid-pro";
import { useAppContext } from "AppContext";
import {
  Button,
  ConfirmDeleteModal,
  EditIcon,
  FiTrash2,
  GKClientDataGrid,
  Grid2,
  IconButton,
  StatusField,
} from "components";
import { useFetchQuery, useMutation } from "hooks/useQueries";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axiosInstance from "utils/axiosInstance";
import { UserRoleTypes } from "utils/common";
import { extraColumnsField } from "utils/helpers";
import { moneyCommaSeparator } from "utils/MathFunction";
import useDocumentTitle from "utils/useDocumentTitle";
import AddNewStockModal from "./AddNewStockModal";

const EquityBasketAssets = () => {
  useDocumentTitle("Basket Assets");

  const {
    state: { userData },
  } = useAppContext();

  const { id } = useParams();

  const navigate = useNavigate();

  const [showStocksModal, setShowStocksModal] = useState(false);

  const [allStocks, setAllStocks] = useState([]);

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const [showConfirmationId, setShowConfirmationId] = useState(null);

  /** ========================================================================================================= */
  const { isFetching, refetch } = useFetchQuery({
    key: ["STOKE_UNIVERSE"],
    route: `model_portfolio/${id}/stock_universe/`,
    success: (response: any) => {
      setAllStocks(response?.data?.allocItem);
    },
  });

  /** ========================================================================================================= */
  const { mutate: handleDeleteStock } = useMutation({
    mutationFn: (data: { modelId: any; stockItemId: any }) =>
      axiosInstance?.delete(
        `/model_portfolio/${data?.modelId}/stock_item/${data?.stockItemId}/`,
      ),
    onSuccess: () => {
      refetch();
    },
  });

  const columns: GridColDef[] = [
    {
      ...extraColumnsField("Stocks", "companyName", 500, 1),
    },
    {
      ...extraColumnsField("Status", "status", 1, 1),
      renderCell: (params) => {
        return <StatusField statusString={params?.row?.status || "N/A"} />;
      },
    },
    {
      ...extraColumnsField("CMP", "cmp", 1, 1),
      valueFormatter: (params) => moneyCommaSeparator(params?.value || 0),
      type: "number",
    },
    {
      ...extraColumnsField("Target Price", "targetPrice", 1, 1),
      valueFormatter: (params) => moneyCommaSeparator(params?.value || 0),
      type: "number",
    },
    {
      ...extraColumnsField("Stop Loss", "stopLoss", 1, 1),
      valueFormatter: (params) => moneyCommaSeparator(params?.value || 0),
      type: "number",
    },
    {
      ...extraColumnsField("Portfolio Count", "portfolioCount", 1, 1),
      type: "number",
    },
    {
      ...extraColumnsField("Holding Value", "holdingValue", 1, 1),
      valueFormatter: (params) => moneyCommaSeparator(params?.value || 0),
      type: "number",
    },
    {
      ...extraColumnsField("Action", "action", 1, 1),
      align: "right",
      headerAlign: "right",
      renderCell: (params) => {
        return (
          <Grid2 container justifyContent={"flex-end"}>
            <IconButton
              className="outline"
              onClick={() => {
                navigate(
                  `/app/equity-basket/${id}/assets/${params?.row?.id}/details`,
                );
              }}
            >
              <EditIcon size={18} />
            </IconButton>
            <IconButton
              color="error"
              className="outline"
              onClick={(event) => {
                event?.stopPropagation();
                setShowConfirmationModal(true);
                setShowConfirmationId(params.row.id);
              }}
            >
              <FiTrash2 size={18} />
            </IconButton>
          </Grid2>
        );
      },
    },
  ];

  return (
    <>
      <Grid2 container>
        <Grid2 size={12}>
          <GKClientDataGrid
            name="stockUniverse"
            density="compact"
            headerComponent={
              userData?.userRole === UserRoleTypes.advisor && (
                <Button
                  variant="contained"
                  onClick={() => {
                    setShowStocksModal(true);
                  }}
                >
                  Add Stock
                </Button>
              )
            }
            pinnedColumns={{
              left: ["companyName"],
            }}
            maxHeight={`calc(100vh - 232px)`}
            loading={isFetching}
            rows={allStocks || []}
            columns={columns.filter((dataItem) =>
              userData?.userRole === UserRoleTypes.advisor
                ? dataItem
                : dataItem.field !== "action",
            )}
          />
        </Grid2>
      </Grid2>

      {showStocksModal && (
        <AddNewStockModal open={showStocksModal} setOpen={setShowStocksModal} />
      )}

      {showConfirmationModal && (
        <ConfirmDeleteModal
          title="Asset"
          isDelete
          open={showConfirmationModal}
          setOpen={setShowConfirmationModal}
          onDelete={() => {
            handleDeleteStock({
              modelId: id,
              stockItemId: showConfirmationId,
            });
          }}
        />
      )}
    </>
  );
};

export default EquityBasketAssets;
