import { LoadingButton } from "@mui/lab";
import { GKDatePicker, GKModal, Grid2 } from "components";
import dayjs from "dayjs";
import { useMutation } from "hooks/useQueries";
import { Controller, useForm } from "react-hook-form";
import axiosInstance from "utils/axiosInstance";
import { GKModalProps } from "utils/commonInterface";
import { refetchQuery } from "utils/helpers";

interface Props {
  invoiceId?: string | number;
}

const PresentationInvoiceModal = (props: Props & GKModalProps) => {
  const { open, setOpen, invoiceId } = props;

  const form = useForm({
    defaultValues: {
      settlementDate: dayjs().add(1, "day").format(),
      invoice: invoiceId,
    },
  });

  const { mutate: generatePresentation, isLoading } = useMutation({
    mutationKey: ["GENERATE_PRESENTATION"],
    mutationFn: (data) =>
      axiosInstance.post("/digio/mandate-presentation/", data),
    onSuccess: () => {
      refetchQuery("INVOICE_LIST");
      setOpen(false);
    },
  });

  return (
    <GKModal
      open={open}
      setOpen={setOpen}
      modalTitle={"Send Payment Link"}
      footer={
        <Grid2
          display={"flex"}
          justifyContent={"flex-end"}
          alignItems={"center"}
          gap={1}
        >
          <LoadingButton
            variant="contained"
            type="submit"
            loading={isLoading}
            form={"generate-presentation-form"}
          >
            Send
          </LoadingButton>
        </Grid2>
      }
    >
      <form
        id={"generate-presentation-form"}
        onSubmit={form.handleSubmit((values: any) =>
          generatePresentation(values),
        )}
      >
        <Grid2 container>
          <Grid2 size={12}>
            <Controller
              name="settlementDate"
              control={form.control}
              rules={{
                required: {
                  value: true,
                  message: "Please select settlement date",
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <GKDatePicker
                  {...field}
                  disablePast
                  inputLabel="Date"
                  value={dayjs(field.value)}
                  onChange={(value) => {
                    field.onChange(dayjs(value).format("YYYY-MM-DD"));
                  }}
                  disableToday
                  disableFuture={false}
                  slotProps={{
                    textField: {
                      error: !!error,
                      helperText: error?.message as string,
                    },
                  }}
                />
              )}
            />
          </Grid2>
        </Grid2>
      </form>
    </GKModal>
  );
};

export default PresentationInvoiceModal;
