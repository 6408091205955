import { blueGrey } from "@mui/material/colors";
import { useAppContext } from "AppContext";
import {
  Button,
  GKClientDataGrid,
  GKSearchSelect,
  GKTextField,
  Grid2,
  IconButton,
  TextField,
  Typography,
} from "components";
import { AdvisorOnboardFormInterface } from "interfaces/OnboardingInterface";
import { isEmpty } from "lodash";
import { useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { LuTrash2, LuUpload } from "react-icons/lu";
import { handleReturnNaturalRows } from "utils";
import UploadHoldingsModal from "./UploadHoldingsModal";

const PlansHoldings = () => {
  const { control, getValues, setValue } =
    useFormContext<AdvisorOnboardFormInterface>();
  const {
    state: { companies },
  } = useAppContext();

  const [uploadHoldingsModal, setUploadHoldingsModal] = useState(false);

  return (
    <>
      <Grid2 container spacing={2}>
        <Grid2 size={12} display={"flex"} alignItems={"center"} gap={1}>
          <Typography fontWeight={600}>Add holdings manually </Typography>
          <Typography fontWeight={600} color={blueGrey[500]}>
            OR
          </Typography>
          <Button
            startIcon={<LuUpload size={16} />}
            variant="outlined"
            color="dark"
            onClick={() => {
              setUploadHoldingsModal(true);
            }}
          >
            Upload via excel
          </Button>
        </Grid2>
        <Grid2 size={12} />
        <Grid2 size={12}>
          <GKClientDataGrid
            pagination
            columnHeaderHeight={36}
            disableRowSelectionOnClick
            maxHeight={`calc(100vh - 450px)`}
            name="plansHoldings"
            getRowClassName={(params) => {
              return isEmpty(params.row.gridCode) || +params.row.quantity === 0
                ? "error-row"
                : "";
            }}
            rows={
              getValues("planDataObject.plansHoldings")?.map(
                (d: any, index: any) => {
                  return {
                    ...d,
                    index: index,
                  };
                },
              ) || []
            }
            headerComponent={
              <Button
                variant="outlined"
                size="small"
                onClick={() => {
                  setValue("planDataObject.plansHoldings", [
                    {
                      gridCode: "",
                      name: "",
                      quantity: 0,
                      cmp: "",
                      id: getValues("planDataObject.plansHoldings")?.length,
                    },
                    ...getValues("planDataObject.plansHoldings"),
                  ]);
                }}
              >
                Add Stock
              </Button>
            }
            columns={[
              {
                field: "gridCode",
                headerName: "Company Name",
                minWidth: 300,
                disableColumnMenu: true,
                renderCell: (params: any) => {
                  return (
                    <Controller
                      name={`planDataObject.plansHoldings.${params?.row?.index}.gridCode`}
                      control={control}
                      render={({ field }) => {
                        return (
                          <GKSearchSelect
                            {...field}
                            size="small"
                            getOptionLabel={(opt) => opt?.name || opt}
                            options={
                              handleReturnNaturalRows({
                                options: companies,
                                optionsValueKey: "gridCode",
                                selectedValueKey: "gridCode",
                                values: getValues(
                                  "planDataObject.plansHoldings",
                                ),
                              }) || []
                            }
                            value={params?.row?.name}
                            onChange={(_, val) => {
                              field.onChange(val.value);
                              setValue(
                                `planDataObject.plansHoldings.${params?.row?.index}.cmp`,
                                val?.cmp,
                              );
                              setValue(
                                `planDataObject.plansHoldings.${params?.row?.index}.name`,
                                val?.name,
                              );
                            }}
                            renderInput={(pa) => <TextField {...pa} />}
                            sx={{
                              width: "100%",
                            }}
                          />
                        );
                      }}
                    />
                  );
                },
              },
              {
                field: "quantity",
                headerName: "Quantity",
                minWidth: 120,
                disableColumnMenu: true,
                renderCell: (params: any) => {
                  return (
                    <Controller
                      name={`planDataObject.plansHoldings.${params?.row?.id}.quantity`}
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: "",
                        },
                      }}
                      render={({ field }) => {
                        return (
                          <GKTextField
                            {...field}
                            size="small"
                            placeholder="Enter quantity"
                          />
                        );
                      }}
                    />
                  );
                },
              },
              {
                headerName: "CMP",
                field: "cmp",
                flex: 1,
                disableColumnMenu: true,
              },
              {
                headerName: "Action",
                headerAlign: "right",
                align: "right",
                field: "action",
                flex: 1,
                disableColumnMenu: true,
                renderCell: ({ row }: { row: any }) => {
                  return (
                    <IconButton
                      color="error"
                      onClick={() => {
                        const filterData = getValues(
                          "planDataObject.plansHoldings",
                        )?.filter((_: any, i: any) => i !== row.index);
                        setValue("planDataObject.plansHoldings", filterData);
                      }}
                    >
                      <LuTrash2 size={18} />
                    </IconButton>
                  );
                },
              },
            ]}
          />
        </Grid2>
      </Grid2>
      {uploadHoldingsModal && (
        <UploadHoldingsModal
          setOpen={setUploadHoldingsModal}
          open={uploadHoldingsModal}
        />
      )}
    </>
  );
};

export default PlansHoldings;
