import { useAppContext } from "AppContext";
import { AnimationComponent, Box, Grid2, Show, Tab, Tabs } from "components";
import { useFetchQuery } from "hooks/useQueries";
import DashboardLayout from "layouts/DashboardLayout";
import Partner from "Pages/CompanyOrganisation/Partner";
import BranchAdmins from "Pages/Organisations/BranchAdmins";
import { QueryKeys } from "utils/queryKey";
import { ThemeContainer } from "utils/Theme";

const CompanyOrganisationMenu = () => {
  const {
    state: { tab: tabValue },
    dispatch,
  } = useAppContext();

  const { theme } = ThemeContainer();

  const { data: branchList, isFetching } = useFetchQuery({
    key: [QueryKeys.BRANCH_LIST],
    enabled: tabValue?.organisationTab === 0,
    route: "/branch/",
  });

  const tabs = [
    {
      label: "Admin",
      component: (
        <BranchAdmins
          path={`/branch/${branchList?.data?.data[0]?.id}/employees/`}
        />
      ),
    },
    { label: "Partner", component: <Partner /> },
  ];

  return (
    <DashboardLayout title="Organisation" loading={isFetching}>
      <Grid2 container spacing={2}>
        <Grid2 size={12}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              sx={{
                "& .MuiTabs-flexContainer": {
                  backgroundColor: theme.palette.light.main,
                },
              }}
              onChange={(e, val) => {
                dispatch({
                  type: "TAB",
                  data: { ...tabValue, organisationTab: val },
                });
              }}
              value={tabValue?.organisationTab}
            >
              {tabs.map((tab, index) => (
                <Tab key={index} label={tab.label} disableRipple />
              ))}
            </Tabs>
          </Box>
        </Grid2>
        <Grid2 size={12}>
          {tabs.map((tab, index) => (
            <Show.When key={index} isTrue={tabValue?.organisationTab === index}>
              <AnimationComponent>{tab.component}</AnimationComponent>
            </Show.When>
          ))}
        </Grid2>
      </Grid2>
    </DashboardLayout>
  );
};

export default CompanyOrganisationMenu;
