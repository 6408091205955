import { LoadingButton } from "@mui/lab";
import { useAppContext } from "AppContext";
import { GKTextField, Grid2 } from "components";
import { useFetchQuery, useMutation } from "hooks/useQueries";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import axiosInstance from "utils/axiosInstance";

const DigioDetails = () => {
  const {
    state: { userData },
  } = useAppContext();

  const form = useForm({
    defaultValues: {
      clientId: "",
      clientSecret: "",
    },
  });

  useFetchQuery({
    key: ["DIGIO_DETIALS"],
    route: "/digio-details/",
    success: (res: any) => {
      form.reset({ ...res?.data?.data });
    },
  });

  const { mutate, isLoading } = useMutation({
    mutationFn: (requestedData: { clientId: string; clientSecret: string }) =>
      axiosInstance.patch(
        `digio-details/${userData.companyId}/`,
        requestedData,
      ),
    onSuccess: (response) => {
      toast.success(response.data?.message || "Action completed successfully.");
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again.",
      );
    },
  });

  return (
    <form
      onSubmit={form.handleSubmit((values) => {
        mutate(values);
      })}
    >
      <Grid2 container spacing={2}>
        <Grid2 size={5}>
          <Controller
            control={form.control}
            name="clientId"
            rules={{
              required: {
                value: true,
                message: "Please enter Client ID",
              },
            }}
            render={({ field, fieldState: { error } }) => {
              return (
                <GKTextField
                  {...field}
                  requiredField
                  inputLabel={"Client ID"}
                  error={!!error}
                  helperText={!!error && error.message}
                />
              );
            }}
          />
        </Grid2>
        <Grid2 size={5}>
          <Controller
            control={form.control}
            name="clientSecret"
            rules={{
              required: {
                value: true,
                message: "Please enter Client Secret Key",
              },
            }}
            render={({ field, fieldState: { error } }) => {
              return (
                <GKTextField
                  {...field}
                  requiredField
                  inputLabel={"Client Secret Key"}
                  error={!!error}
                  helperText={!!error && error.message}
                />
              );
            }}
          />
        </Grid2>
        <Grid2 size={2} mt={2.5}>
          <LoadingButton
            loading={isLoading}
            variant="contained"
            fullWidth
            type="submit"
          >
            Save
          </LoadingButton>
        </Grid2>
      </Grid2>
    </form>
  );
};

export default DigioDetails;
