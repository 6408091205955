import {
  CgMathPlus,
  CircularProgress,
  FiTrash2,
  GKButton,
  GKClientDataGrid,
  Grid2,
  IconButton,
} from "components";
import { useFetchQuery, useMutation } from "hooks/useQueries";
import { useState } from "react";
import toast from "react-hot-toast";
import { GET_ALL_AGREEMENT } from "utils/apiRoutes";
import axiosInstance from "utils/axiosInstance";
import { extraColumnsField, matchDate, refetchQuery } from "utils/helpers";
import useDocumentTitle from "utils/useDocumentTitle";
import CustomAgreementModal from "./CustomAgreementModal";

const CustomAgreement = () => {
  useDocumentTitle("Custom Agreement");

  const [showCreateModal, setShowCreateModal] = useState(false);

  const [selectedRow, setSelectedRow] = useState(null);

  const { data, isFetching } = useFetchQuery({
    key: ["GET_ALL_CUSTOM_AGREEMENTS"],
    route: GET_ALL_AGREEMENT,
  });

  const { mutate: handleDelete, isLoading: deleteLoading } = useMutation({
    mutationKey: ["DELETE_CUSTOM_AGREEMENT"],
    mutationFn: ({ id }: { id: any }) =>
      axiosInstance.delete(`/custom-agreement/${id}/`),
    onSuccess: () => {
      refetchQuery("GET_ALL_CUSTOM_AGREEMENTS");
      toast.success("Custom Agreement deleted successfully");
    },
    onError: () => {
      toast.error("Error deleting Custom Agreement");
    },
  });

  return (
    <Grid2 container>
      <Grid2 size={12}>
        <GKClientDataGrid
          loading={isFetching}
          name="customAgreement"
          rows={data?.data?.data || []}
          columns={[
            {
              ...extraColumnsField("Title", "title", 0, 1),
              valueFormatter: (value) => value.value || "-",
            },
            {
              ...extraColumnsField("Created On", "createdAt", 0, 1),
              valueFormatter: (value) => matchDate(value.value),
            },
            {
              ...extraColumnsField("Actions", "action", 0, 1),
              align: "right",
              headerAlign: "right",
              renderCell: (params) => (
                <IconButton
                  color="error"
                  className="outline"
                  onClick={(e) => {
                    e.stopPropagation();
                    setSelectedRow(params.row);
                    handleDelete({ id: params.row.id });
                  }}
                >
                  {deleteLoading && selectedRow?.id === params.row.id ? (
                    <CircularProgress size={16} color="error" />
                  ) : (
                    <FiTrash2 size={16} />
                  )}
                </IconButton>
              ),
            },
          ]}
          onRowClick={(params) => {
            setShowCreateModal(true);
            setSelectedRow(params.row);
          }}
          headerComponent={
            <GKButton
              variant="contained"
              onClick={() => {
                setShowCreateModal(true);
                setSelectedRow(null);
              }}
              startIcon={<CgMathPlus size={18} />}
            >
              Create Agreement
            </GKButton>
          }
        />
      </Grid2>
      {showCreateModal && (
        <CustomAgreementModal
          open={showCreateModal}
          setOpen={setShowCreateModal}
          selectedRow={selectedRow}
        />
      )}
    </Grid2>
  );
};

export default CustomAgreement;
