import {
  AiOutlineEdit,
  CgMathPlus,
  FiTrash2,
  GKButton,
  GKModal,
  GKSearchSelect,
  GKSelect,
  GKTextField,
  Grid2,
  IconButton,
  MenuItem,
  Paper,
  PhoneInput,
  TextField,
  Typography,
} from "components";
import { useFetchQuery, useMutation } from "hooks/useQueries";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  GET_ALL_EMPLOYEE,
  GET_ALL_LEAD_TAG,
  GET_LEAD_SOURCE,
  GET_LEAD_STATUS,
} from "utils/apiRoutes";
import axiosInstance from "utils/axiosInstance";
import { paletteLightTheme } from "utils/ColorTheme";
import { GKModalProps } from "utils/commonInterface";
import { refetchQuery } from "utils/helpers";
import { QueryKeys } from "utils/queryKey";
import { handleReturnAutocompletePlaceholder } from "utils/StringFunctions";
import { emailRegex, validateNotWhitespaceOnly } from "utils/validationSchema";
import AddStatusAndSouceModal from "./AddStatusAndSouceModal";
import TagModal from "./LeadTags/TagModal";

const CreateLeadModal = (props: GKModalProps) => {
  const { open, setOpen } = props;

  const [addStatusModal, setAddStatusModal] = useState(false);

  const [hasError, setHasError] = useState(false);

  const [addTagsModal, setAddTagsModal] = useState(false);

  const [type, setType] = useState("STATUS");

  const [updateValue, setUpdateValue] = useState({});

  const [sourceList, setSourceList] = useState([]);

  const { data: statusList } = useFetchQuery({
    route: GET_LEAD_STATUS,
    key: ["GET_ALL_LEAD_STATUS"],
  });

  const { data: employeeList } = useFetchQuery({
    route: GET_ALL_EMPLOYEE,
    key: ["GET_ALL_LEAD_ASSIGNEE"],
  });

  const { data: tagsList } = useFetchQuery({
    route: GET_ALL_LEAD_TAG,
    key: ["GET_ALL_LEAD_TAGS"],
  });

  const { mutate: createNewLead, isLoading } = useMutation({
    mutationFn: (data) => axiosInstance.post(`/lead/`, data),
    onSuccess: () => {
      refetchQuery(QueryKeys.GET_LEAD);
      refetchQuery("GET_ALL_LEADS");
      setOpen(false);
    },
  });

  const { refetch: sourceRefetch, isFetching } = useFetchQuery({
    key: [QueryKeys.GET_SOURCE],
    route: GET_LEAD_SOURCE,
    success: (data: any) => {
      setSourceList(
        data?.data?.data?.map((s: any) => ({
          name: s.leadsource,
          value: s.id,
        })),
      );
    },
  });

  const { mutate: handleDeleteSource } = useMutation({
    mutationFn: (data: any) => axiosInstance.delete(`/lead-source/${data}/`),
    onSuccess: () => {
      refetchQuery(QueryKeys.GET_LEAD);

      sourceRefetch();
    },
  });

  const form = useForm({
    defaultValues: {
      name: "",
      email: "",
      companyName: "",
      countryCode: "+91",
      phoneNumber: "",
      leadStatus: null,
      leadAssignedTo: null,
      sourceOfLead: null,
      tags: [],
    },
  });

  form.watch();

  return (
    <>
      <GKModal
        open={open}
        setOpen={setOpen}
        modalTitle={"Create New Lead"}
        footer={
          <Grid2 container justifyContent={"flex-end"} gap={1}>
            <GKButton
              variant="outlined"
              size="medium"
              onClick={() => setOpen(false)}
            >
              Cancel
            </GKButton>
            <GKButton
              variant="contained"
              type="submit"
              form="create-lead-form"
              size="medium"
              disabled={isLoading || hasError}
            >
              Submit
            </GKButton>
          </Grid2>
        }
      >
        <form
          id="create-lead-form"
          onSubmit={form.handleSubmit((values: any) => createNewLead(values))}
        >
          <Grid2 container spacing={1}>
            <Grid2 size={6}>
              <Controller
                name="name"
                control={form.control}
                rules={{
                  required: {
                    value: true,
                    message: "Please enter lead name",
                  },
                }}
                render={({ field, fieldState: { error } }) => (
                  <GKTextField
                    {...field}
                    inputLabel="Lead Name"
                    requiredField
                    error={!!error}
                    helperText={!!error && error?.message}
                  />
                )}
              />
            </Grid2>
            <Grid2 size={6}>
              <Controller
                name="companyName"
                control={form.control}
                rules={{
                  required: {
                    value: true,
                    message: "Please enter company name",
                  },
                }}
                render={({ field, fieldState: { error } }) => (
                  <GKTextField
                    {...field}
                    inputLabel="Company Name"
                    requiredField
                    error={!!error}
                    helperText={!!error && error?.message}
                  />
                )}
              />
            </Grid2>
            <Grid2 size={6}>
              <Controller
                name="email"
                control={form.control}
                rules={{
                  required: {
                    value: true,
                    message: "Please enter email",
                  },
                  pattern: {
                    value: emailRegex,
                    message: "Please enter a valid email",
                  },
                }}
                render={({ field, fieldState: { error } }) => (
                  <GKTextField
                    {...field}
                    inputLabel="Email"
                    requiredField
                    error={!!error}
                    helperText={!!error && error?.message}
                  />
                )}
              />
            </Grid2>
            <Grid2 size={6}>
              <Controller
                name="phoneNumber"
                control={form.control}
                rules={{
                  required: {
                    value: true,
                    message: "Please enter phone number",
                  },
                }}
                render={({ field, fieldState: { error } }) => (
                  <PhoneInput
                    {...field}
                    onSelectChange={(event: any, val: any) => {
                      form.setValue("countryCode", val?.callingCodes);
                    }}
                    onTextChange={(event: any) => {
                      field.onChange(event.target.value);
                    }}
                    onTextValue={field.value}
                    requiredField
                    onSelectValue={form.getValues("countryCode")}
                    error={!!error || hasError}
                    helperText={!!error && error?.message}
                    hasError={hasError}
                    setHasError={setHasError}
                  />
                )}
              />
            </Grid2>
            <Grid2 size={6}>
              <Controller
                name="leadStatus"
                rules={{
                  required: {
                    value: true,
                    message: "Please select status",
                  },
                  validate: validateNotWhitespaceOnly,
                }}
                control={form.control}
                render={({ field, fieldState: { error } }) => (
                  <GKSearchSelect
                    {...field}
                    disableClearable
                    requiredField
                    inputLabel="Status"
                    options={
                      statusList?.data?.data?.map(
                        (da: {
                          id: number;
                          leadstatus: string;
                          branch: string;
                        }) => {
                          return {
                            ...da,
                            name: da?.leadstatus,
                            value: da?.id,
                          };
                        },
                      ) || []
                    }
                    onChange={(e, val) => {
                      field.onChange(val?.value);
                    }}
                    value={
                      statusList?.data?.data?.find(
                        (da: any) => da?.id === form.getValues("leadStatus"),
                      )?.leadStatus
                    }
                    getOptionLabel={(option) => option.name || option}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder={handleReturnAutocompletePlaceholder({
                          placeholder: "Select Status",
                          value: field.value,
                        })}
                        error={!!error}
                        helperText={!!error && error?.message}
                      />
                    )}
                  />
                )}
              />
            </Grid2>
            <Grid2 size={6}>
              <Controller
                rules={{
                  required: {
                    value: true,
                    message: "Please select assignee",
                  },
                }}
                name="leadAssignedTo"
                control={form.control}
                render={({ field, fieldState: { error } }) => (
                  <GKSearchSelect
                    {...field}
                    disableClearable
                    inputLabel="Assigned"
                    requiredField
                    options={
                      employeeList?.data?.map(
                        (da: { id: number; name: string; phone: string }) => {
                          return {
                            ...da,
                            name: da?.name,
                            value: da?.id,
                          };
                        },
                      ) || []
                    }
                    onChange={(e, val) => {
                      field.onChange(val?.value);
                    }}
                    value={
                      employeeList?.data?.find(
                        (da: any) =>
                          da?.id === form.getValues("leadAssignedTo"),
                      )?.name
                    }
                    getOptionLabel={(option) => option.name || option}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder={handleReturnAutocompletePlaceholder({
                          placeholder: "Select Assignee",
                          value: field.value,
                        })}
                        error={!!error}
                        helperText={!!error && error?.message}
                      />
                    )}
                  />
                )}
              />
            </Grid2>
            <Grid2 size={6}>
              <Controller
                name="sourceOfLead"
                control={form.control}
                render={({ field }) => (
                  <GKSelect
                    {...field}
                    loading={isFetching}
                    inputLabel="Source"
                    placeholder="Source"
                    renderValue={(selected) => {
                      const value =
                        selected &&
                        sourceList?.find((data) => data?.value === selected)
                          ?.name;

                      return value || "Select an option";
                    }}
                  >
                    {sourceList.map((data: any) => {
                      return (
                        <MenuItem
                          value={data?.value}
                          key={data?.value}
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            gap: "10px",
                          }}
                        >
                          <Typography>{data?.name}</Typography>
                          <span
                            style={{
                              display: "flex",
                              gap: "2px",
                            }}
                          >
                            <IconButton
                              style={{ padding: 0 }}
                              onClick={(e) => {
                                e.stopPropagation();
                                setType("SOURCE");
                                setUpdateValue(data);
                                setAddStatusModal(true);
                              }}
                            >
                              <AiOutlineEdit />
                            </IconButton>
                            <IconButton
                              className="outline"
                              style={{ padding: 0 }}
                              color="error"
                              onClick={(e) => {
                                e.stopPropagation();
                                handleDeleteSource(data?.value);
                              }}
                            >
                              <FiTrash2 size={16} />
                            </IconButton>
                          </span>
                        </MenuItem>
                      );
                    })}
                    <MenuItem
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        gap: "10px",
                      }}
                    >
                      <GKButton
                        variant="link"
                        startIcon={<CgMathPlus />}
                        onClick={() => {
                          setType("SOURCE");
                          setUpdateValue({});
                          setAddStatusModal(true);
                        }}
                      >
                        Add New
                      </GKButton>
                    </MenuItem>
                  </GKSelect>
                )}
              />
            </Grid2>
            <Grid2 size={6}>
              <Controller
                name="tags"
                control={form.control}
                render={({ field }) => (
                  <GKSearchSelect
                    {...field}
                    multiple
                    disableClearable
                    inputLabel="Tags"
                    options={
                      tagsList?.data?.data?.map(
                        (da: { id: number; tag: string }) => {
                          return {
                            ...da,
                            name: da?.tag,
                            value: da?.id,
                          };
                        },
                      ) || []
                    }
                    onChange={(e, val) => {
                      const selectedIds = val.map(
                        (option: any) => option.value,
                      );
                      field.onChange(selectedIds);
                    }}
                    value={
                      tagsList?.data?.data
                        ?.map((da: { id: number; tag: string }) => {
                          return {
                            ...da,
                            name: da?.tag,
                            value: da?.id,
                          };
                        })
                        .filter((option: any) =>
                          field.value.includes(option.value),
                        ) || []
                    }
                    getOptionLabel={(option) => option.name || option}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder={handleReturnAutocompletePlaceholder({
                          placeholder: "Select Tags",
                          value: field.value,
                        })}
                      />
                    )}
                    slots={{
                      paper: ({ children }) => {
                        return (
                          <Paper
                            sx={{
                              fontFamily: "Inter !important",
                            }}
                          >
                            {children}
                            <GKButton
                              sx={{ p: 1 }}
                              fullWidth
                              variant="link"
                              onMouseDown={() => {
                                setAddTagsModal(true);
                              }}
                            >
                              + Add New
                            </GKButton>
                          </Paper>
                        );
                      },
                    }}
                    renderOption={(props, val) => {
                      return (
                        <li
                          {...props}
                          style={{
                            background: field.value?.includes(val?.id)
                              ? `${paletteLightTheme.palette.primary.main}22`
                              : "",
                          }}
                        >
                          {val?.tag}
                        </li>
                      );
                    }}
                  />
                )}
              />
            </Grid2>
          </Grid2>
        </form>
      </GKModal>
      {addStatusModal && (
        <AddStatusAndSouceModal
          open={addStatusModal}
          setOpen={setAddStatusModal}
          type={type}
          sourceRefetch={sourceRefetch}
          updateValue={updateValue}
        />
      )}

      {addTagsModal && (
        <TagModal open={addTagsModal} setOpen={setAddTagsModal} />
      )}
    </>
  );
};

export default CreateLeadModal;
