import { GKDataGrid } from "components";
import useGenerateQueryParams from "hooks/useGenerateQueryParams";
import { useFetchQuery } from "hooks/useQueries";
import { useParams } from "react-router-dom";
import { FAMILY_ID_BY_HOLDING } from "utils/apiRoutes";
import { assetsHoldingsColumns } from "utils/columns";
import { QueryKeys } from "utils/queryKey";

const HoldingTab = () => {
  const { id } = useParams();

  const requestBody = useGenerateQueryParams("familyHolding");

  const { data: familyState, isLoading } = useFetchQuery({
    key: [QueryKeys.FAMILY_HOLDINGS, requestBody],
    route: FAMILY_ID_BY_HOLDING(id),
    requestBody,
  });

  return (
    <GKDataGrid
      loading={isLoading}
      columns={assetsHoldingsColumns}
      rows={familyState?.data?.data || []}
      zeroHolding
      density="compact"
      // onRowClick={(row: any) => {
      //   navigate(
      //     `/app/holdings/${id}/transactions?type=${row?.row?.instType}`,
      //     {
      //       state: {
      //         holdingId: id,
      //         name: row?.row?.name,
      //         gcode: row?.row?.gcode,
      //         currentAmount: row?.row?.currentAmount,
      //         investedAmount: row?.row?.investedAmount,
      //         quantity: row?.row?.quantity,
      //         type: "family",
      //       },
      //     },
      //   );
      // }}
      totalCount={familyState?.data?.totalCount || 0}
      name="familyHolding"
      tableName="family_holding_list"
      exportFile={{
        path: FAMILY_ID_BY_HOLDING(id),
        exportName: "FamilyHoldingsList",
      }}
      maxHeight={"calc(100vh - 414px)"}
    />
  );
};

export default HoldingTab;
