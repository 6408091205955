import { LoadingButton } from "@mui/lab";
import { Box, Divider, DragDrop, Grid2, Show, Typography } from "components";
import { useMutation } from "hooks/useQueries";
import CommonThirdPartyLayout from "layouts/CommonThirdpartyLayout";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { MdOutlineInfo } from "react-icons/md";
import { useLocation } from "react-router-dom";
import axiosInstance from "utils/axiosInstance";
import { ThemeContainer } from "utils/Theme";

const VerifyDocument = () => {
  const { theme } = ThemeContainer();

  const location = useLocation();

  const [uploadError, setUploadError] = useState<string | null>(null);

  const portfolioId = new URLSearchParams(location.search).get("portfolio_id");
  const proofType = new URLSearchParams(location.search).get("proof_type");
  const logo = location.search.split("logo=")[1];

  const form = useForm({
    defaultValues: {
      holdingsProof: [],
      capitalProof: [],
      amount: 0,
    },
  });

  const { mutate, isLoading } = useMutation({
    mutationFn: (values: any) =>
      axiosInstance.patch(`/plans/${portfolioId}/`, values),
    onSuccess: (response) => {
      toast.success(
        response?.data?.message || "Something went wrong, please try again.",
      );
      setInterval(() => {
        window.location.href = "/";
      }, 1000);
    },
  });

  return (
    <CommonThirdPartyLayout logo={logo}>
      <Box
        bgcolor={theme.palette.light.main}
        sx={{ p: { xs: 0, sm: "32px 40px" } }}
        borderRadius="16px"
        border={"1px solid #E3E8EF"}
        component="form"
        onSubmit={form.handleSubmit((values) => {
          const formData = new FormData();

          formData.append("proofType", proofType);

          values?.holdingsProof?.forEach((file: string | Blob, index: any) => {
            formData.append(`holdingsProof[${index}]`, file);
          });
          values?.capitalProof?.forEach((file: string | Blob, index: any) => {
            formData.append(`capitalProof[${index}]`, file);
          });

          formData.append("amount", values?.amount?.toString());

          mutate(formData);
        })}
      >
        <Typography fontSize={28} fontWeight={600}>
          Upload Your Document
        </Typography>

        <Grid2 container size={12} mt={5} spacing={2}>
          <Grid2 container size={{ md: 5.5, xs: 12 }} spacing={2}>
            <Grid2 size={12}>
              <Typography fontSize={18} fontWeight={500} color="#4B5565">
                Upload your demat holding statement
              </Typography>
            </Grid2>
            <Grid2 size={12}>
              <Show.When isTrue={proofType !== "capital_proof"}>
                <Controller
                  control={form.control}
                  name={`holdingsProof`}
                  render={({ field }) => (
                    <Box
                      sx={{
                        maxHeight: "200px",
                        overflowY: "auto",
                        borderRadius: "8px",
                        padding: "8px",
                      }}
                    >
                      <DragDrop
                        {...field}
                        multiple
                        onChange={(e) => field.onChange(e)}
                        acceptType={".pdf, .xlsx"}
                        supportName="PDF or XLSX"
                        uploadError={uploadError}
                        disabled={proofType === "capital_proof"}
                        setUploadError={setUploadError}
                      />
                    </Box>
                  )}
                />
              </Show.When>
              <Show.When isTrue={proofType === "capital_proof"}>
                <Box textAlign={"center"}>
                  <img src="/Images/risk-success.svg" alt="" />
                  <Typography fontWeight={600}>Document Approved</Typography>
                </Box>
              </Show.When>
            </Grid2>
          </Grid2>
          <Grid2
            size={{ md: 1, xs: 0 }}
            display={"flex"}
            justifyContent={"center"}
          >
            <Divider orientation="vertical" />
          </Grid2>
          <Grid2 container size={{ md: 5.5, xs: 12 }} spacing={2}>
            <Grid2 size={12}>
              <Typography fontSize={18} fontWeight={500} color="#4B5565">
                Upload your bank statement
              </Typography>
            </Grid2>
            <Grid2 size={12}>
              <Show.When isTrue={proofType !== "holding_proof"}>
                <Controller
                  control={form.control}
                  name={`capitalProof`}
                  render={({ field }) => (
                    <Box
                      sx={{
                        maxHeight: "200px",
                        overflowY: "auto",
                        borderRadius: "8px",
                        padding: "8px",
                      }}
                    >
                      <DragDrop
                        {...field}
                        multiple
                        onChange={(e) => field.onChange(e)}
                        acceptType={".pdf, .xlsx"}
                        supportName="PDF or XLSX"
                        disabled={proofType === "holding_proof"}
                        uploadError={uploadError}
                        setUploadError={setUploadError}
                      />
                    </Box>
                  )}
                />
              </Show.When>

              <Show.When isTrue={proofType === "holding_proof"}>
                <Box textAlign={"center"}>
                  <img src="/Images/risk-success.svg" alt="" />
                  <Typography fontWeight={600}>Document Approved</Typography>
                </Box>
              </Show.When>
            </Grid2>
          </Grid2>
        </Grid2>

        <Grid2
          container
          size={12}
          mt={3}
          display={"flex"}
          alignItems={"center"}
        >
          <Grid2 size={10}>
            <Typography
              color="#9AA4B2"
              display={"flex"}
              alignItems={"start"}
              gap={1}
            >
              <MdOutlineInfo size={20} />
              The documents you uploaded were rejected by your advisor, please
              re-upload valid documents.
            </Typography>
          </Grid2>
          <Grid2 size={2} textAlign={"right"}>
            <LoadingButton
              variant="contained"
              type="submit"
              loading={isLoading}
            >
              Upload Files
            </LoadingButton>
          </Grid2>
        </Grid2>
      </Box>
    </CommonThirdPartyLayout>
  );
};

export default VerifyDocument;
