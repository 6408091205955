/* eslint-disable import/no-named-as-default-member */
import {
  CgMathPlus,
  GKButton,
  GKClientDataGrid,
  GKOverlayLoader,
  Grid2,
  IconButton,
  MdOutlineFileDownload,
  PiEyeDuotone,
  Typography,
} from "components";
import GKGaugeChart from "components/GKGaugeChart";
import { useFetchQuery, useMutation } from "hooks/useQueries";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { RISK_PROFILE_BY_ID } from "utils/apiRoutes";
import axiosInstance from "utils/axiosInstance";
import { paletteLightTheme } from "utils/ColorTheme";
import { handleDowloadFile } from "utils/fileHelper";
import { extraColumnsField, matchDate } from "utils/helpers";
import { ThemeContainer } from "utils/Theme";
import RiskRequestModal from "./RiskRequestModal";

const RiskProfileTab = () => {
  const [showRiskModal, setShowRiskModal] = useState(false);

  const { theme } = ThemeContainer();

  const { id } = useParams();

  const navigate = useNavigate();

  const { data: answers, isFetching: RiskResponseDetailsFetching } =
    useFetchQuery({
      key: ["RISK_PROFILE_LIST_ANSWER"],
      route: RISK_PROFILE_BY_ID(id),
    });

  const { mutate: downloadRiskPorfile, isLoading: downloadLoading } =
    useMutation({
      mutationFn: (dataId: any) => {
        return axiosInstance.get(`/download-risk-doc/${dataId}/`);
      },
      retry: false,
      onSuccess: (data: any) => {
        handleDowloadFile({
          fileData: data?.data,
          applicaionType: "application/pdf",
          fileName: "RiskProfile",
          extensionType: "pdf",
        });
      },
    });

  return (
    <>
      {downloadLoading && <GKOverlayLoader />}
      <Grid2 container spacing={1}>
        <Grid2
          size={4}
          display={
            answers?.data?.data?.response?.length === 0 ? "none" : "flex"
          }
        >
          <Grid2
            container
            justifyContent={"center"}
            alignItems={"center"}
            border={`solid 1px ${paletteLightTheme.palette.border.main}`}
            borderRadius={2}
            p={2}
          >
            {answers &&
              answers?.data?.data?.answers?.data?.data?.length !== 0 && (
                <Grid2
                  size={12}
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <GKGaugeChart
                    value={answers?.data?.data?.response[0]?.points}
                    maxValue={answers?.data?.data?.response[0]?.totalPoints}
                  />
                </Grid2>
              )}
            <Grid2 container size={12} px={10}>
              <Grid2 size={6} mt={"60px"}>
                <Typography
                  fontSize={12}
                  fontWeight={400}
                  color={theme.palette.secondaryText.main}
                >
                  Risk Score{" "}
                </Typography>
              </Grid2>
              <Grid2 size={6} textAlign={"right"} mt={"60px"}>
                <Typography fontWeight={600} fontSize={16}>
                  {answers?.data?.data?.response[0]?.points}
                </Typography>
              </Grid2>
              <Grid2 size={12}>
                <Typography
                  fontSize={10}
                  fontWeight={400}
                  color={theme.palette.secondaryText.main}
                >
                  {`(Min Value: 0 - Max Value : ${
                    answers?.data?.data?.response[0]?.totalPoints || 0
                  })`}
                </Typography>
              </Grid2>
              <Grid2
                size={12}
                mt={"20px"}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Typography
                  fontSize={12}
                  fontWeight={400}
                  color={theme.palette.secondaryText.main}
                >
                  Risk Status
                </Typography>
                <Typography fontSize={16} fontWeight={600}>
                  {answers?.data?.data?.response[0]?.riskStatus}
                </Typography>
              </Grid2>
            </Grid2>
          </Grid2>
        </Grid2>
        <Grid2 size={answers?.data?.data?.response?.length === 0 ? 12 : 8}>
          <GKClientDataGrid
            name="ClientRiskProfiling"
            headerComponent={
              <Grid2
                container
                display={
                  answers?.data?.data?.response?.length === 0 ? "none" : "flex"
                }
                justifyContent={"flex-end"}
                gap={1}
              >
                <GKButton
                  variant="contained"
                  size="medium"
                  onClick={() => {
                    setShowRiskModal(true);
                  }}
                  startIcon={<CgMathPlus size={14} />}
                >
                  Risk Assessment
                </GKButton>
              </Grid2>
            }
            emptyButtons={
              <GKButton
                variant="contained"
                size="medium"
                onClick={() => {
                  setShowRiskModal(true);
                }}
                startIcon={<CgMathPlus size={14} />}
              >
                Risk Assessment
              </GKButton>
            }
            loading={RiskResponseDetailsFetching}
            rows={answers?.data?.data?.response || []}
            columns={[
              {
                ...extraColumnsField("Risk Profile", "riskStatus", 150, 1),
                valueGetter: (row) => row.row.riskStatus || "-",
              },
              {
                ...extraColumnsField("Points", "points", 150, 1),
                valueGetter: (row) => row.row.points || "-",
              },
              {
                ...extraColumnsField("Date", "createdAt", 150, 1),
                valueFormatter: (value) => matchDate(value.value),
                valueGetter: (row) => row.row.createdAt || "-",
              },
              {
                ...extraColumnsField("Actions", "action", 100, 1),
                align: "right",
                headerAlign: "right",
                renderCell: (row) => {
                  return (
                    <Grid2 container justifyContent={"flex-end"} gap={1}>
                      <IconButton
                        onClick={() => {
                          navigate(
                            `/app/risk-profile/${id}/questionnaire/${row.row.id}`,
                          );
                        }}
                      >
                        <PiEyeDuotone />
                      </IconButton>
                      <IconButton
                        onClick={() => {
                          downloadRiskPorfile(row.row.id);
                        }}
                      >
                        <MdOutlineFileDownload />
                      </IconButton>
                    </Grid2>
                  );
                },
              },
            ]}
          />
        </Grid2>
      </Grid2>

      {showRiskModal && (
        <RiskRequestModal open={showRiskModal} setOpen={setShowRiskModal} />
      )}
    </>
  );
};

export default RiskProfileTab;
