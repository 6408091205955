import { useQueries } from "@tanstack/react-query";
import {
  CityResponseInterface,
  CompaniesListInterface,
  CompaniesResponseInterface,
  CountryResponseInterface,
  StateResponseInterface,
} from "interfaces/PrivateRouteInterface";
import { ReactNode } from "react";
import axiosInstance from "utils/axiosInstance";
import { useInvestorContext } from "./InvestorContext";

const InvestorPrivateRoute = ({ children }: { children: ReactNode | null }) => {
  const {
    state: { isExistToken },
  } = useInvestorContext();

  const {
    state: { selectedCountry, selectedState },
    dispatch,
  } = useInvestorContext();

  useQueries({
    queries: [
      {
        queryKey: ["GET_ALL_COUNTRIES"],
        queryFn: () =>
          axiosInstance.get("/country/", {
            headers: {
              Authorization: null,
            },
          }),
        onSuccess: (response: CountryResponseInterface) => {
          dispatch({
            type: "COUNTRY_LIST",
            data: response?.data?.data,
          });
        },
      },
      {
        queryKey: ["GET_STATE", selectedCountry],
        queryFn: () =>
          axiosInstance.get(`/state/?country_id=${selectedCountry}`, {
            headers: {
              Authorization: null,
            },
          }),
        enabled: Boolean(selectedCountry),
        onSuccess: (response: StateResponseInterface) => {
          dispatch({
            type: "STATE_LIST",
            data: response?.data?.data,
          });
        },
      },
      {
        queryKey: ["GET_CITY", selectedState],
        queryFn: () =>
          axiosInstance.get(`/city/?state_id=${selectedState}`, {
            headers: {
              Authorization: null,
            },
          }),
        enabled: Boolean(selectedState),
        onSuccess: (response: CityResponseInterface) => {
          dispatch({
            type: "CITY_LIST",
            data: response?.data?.data,
          });
        },
      },
      {
        queryKey: ["FETCH_COMPANIES"],
        queryFn: () => axiosInstance.get("/intruments/list/"),
        enabled: !!isExistToken,
        onSuccess: (data: CompaniesResponseInterface) => {
          dispatch({
            type: "COMPANIES",
            data: data?.data?.data?.map((item: CompaniesListInterface) => ({
              ...item,
              name: item.name,
              value: item.gridCode,
            })),
          });
        },
      },
    ],
  });

  return children;
};

export default InvestorPrivateRoute;
