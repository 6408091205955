import { useMutation } from "hooks/useQueries";
import { PartnerFormValues } from "interfaces/Partner";
import { useParams } from "react-router-dom";
import axiosInstance from "utils/axiosInstance";
import { refetchQuery } from "utils/helpers";

export const usePartner = () => {
  const { id } = useParams();

  const { mutateAsync: createPartner, isLoading: partnerCreateLoading } =
    useMutation({
      mutationKey: ["CREATE_PARTNER"],
      mutationFn: (data: PartnerFormValues) =>
        axiosInstance.post("/partner/", data),
      onSuccess: () => {
        refetchQuery("FETCH_ALL_PARTNER");
      },
    });

  const { mutateAsync: updatePartner, isLoading: partnerUpdateLoading } =
    useMutation({
      mutationKey: ["UPDATE_PARTNER"],
      mutationFn: (values: PartnerFormValues) =>
        axiosInstance.patch(`/partner/${id}/`, values),
    });

  return {
    updatePartner,
    partnerUpdateLoading,
    createPartner,
    partnerCreateLoading,
  };
};
