import { LoadingButton } from "@mui/lab";
import {
  GKDatePicker,
  GKModal,
  GKSearchSelect,
  GKTextField,
  Grid2,
  PhoneInput,
  Show,
  TextField,
} from "components";
import dayjs from "dayjs";
import { usePartner } from "hooks/usePartner";
import { useFetchQuery } from "hooks/useQueries";
import {
  PartnerByIdResponseInterface,
  PartnerFormValues,
} from "interfaces/Partner";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { refetchQuery } from "utils/helpers";
import { handleReturnAutocompletePlaceholder } from "utils/StringFunctions";
import { panRegex } from "utils/validationSchema";

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  isEdit?: boolean;
  data?: PartnerByIdResponseInterface;
}

const PartnerModal = (props: Props) => {
  const { open, setOpen, isEdit = false, data } = props;

  const [hasError, setHasError] = useState(false);

  const {
    updatePartner,
    partnerUpdateLoading,
    createPartner,
    partnerCreateLoading,
  } = usePartner();

  const { data: getAllBasket, isFetching } = useFetchQuery({
    key: ["GET_ALL_BASket_LIST"],
    route: "/model_portfolio/all/",
    enabled: isEdit,
  });

  const form = useForm<PartnerFormValues>({
    defaultValues: isEdit
      ? data || {
          personalDetails: {
            name: "",
            email: "",
            aadhar: "",
            pan: "",
            dateOfBirth: "",
            emergencyContact: "",
            emergencyContactNum: "",
          },
          user: {
            phone: "",
            countryCode: "+91",
          },
          joiningDate: "",
          baskets: [],
        }
      : {
          personalDetails: {
            name: "",
            email: "",
            dateOfBirth: "",
          },
          user: {
            phone: "",
            countryCode: "+91",
          },
        },
  });

  const onSubmit = async (values: PartnerFormValues) => {
    try {
      if (isEdit) {
        await updatePartner(values, {
          onSuccess: () => {
            refetchQuery("GET_PARTNER_BY_ID");
            setOpen(false);
          },
        });
      } else {
        await createPartner(values, {
          onSuccess: () => {
            refetchQuery("PARTNER_LIST");
            setOpen(false);
          },
        });
      }
    } catch (error) {
      toast.error("Error submitting form:", error);
    }
  };

  return (
    <GKModal
      open={open}
      setOpen={setOpen}
      modalTitle={isEdit ? "Edit Partner Details" : "Add Partner"}
      footer={
        <LoadingButton
          variant="contained"
          size="medium"
          type="submit"
          disabled={hasError}
          form="partner-form"
          loading={partnerCreateLoading || partnerUpdateLoading}
        >
          Save
        </LoadingButton>
      }
    >
      <form id="partner-form" onSubmit={form.handleSubmit(onSubmit)}>
        <Grid2 container spacing={2}>
          <Grid2 size={6}>
            <Controller
              name="personalDetails.name"
              control={form.control}
              rules={{
                required: {
                  value: true,
                  message: "Please enter partner name",
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <GKTextField
                  {...field}
                  inputLabel="Partner Name"
                  requiredField
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
          </Grid2>
          <Grid2 size={6}>
            <Controller
              name="personalDetails.email"
              control={form.control}
              render={({ field }) => (
                <GKTextField {...field} inputLabel="Email" />
              )}
            />
          </Grid2>
          <Grid2 size={6}>
            <Controller
              name="user.phone"
              control={form.control}
              rules={{
                required: {
                  value: true,
                  message: "Please enter phone number",
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <PhoneInput
                  {...field}
                  onSelectChange={(event: any, val: any) => {
                    form.setValue("user.countryCode", val?.callingCodes);
                  }}
                  onTextChange={(event: any) => {
                    field.onChange(event.target.value);
                  }}
                  onTextValue={field.value}
                  requiredField
                  onSelectValue={form.getValues("user.countryCode")}
                  error={!!error}
                  helperText={!!error && error?.message}
                  hasError={hasError}
                  setHasError={setHasError}
                />
              )}
            />
          </Grid2>
          <Grid2 size={6}>
            <Controller
              name="personalDetails.dateOfBirth"
              control={form.control}
              render={({ field }) => (
                <GKDatePicker
                  {...field}
                  disableFuture
                  inputLabel="Date Of Birth"
                  value={dayjs(field.value)}
                  onChange={(value) => {
                    field.onChange(dayjs(value).format("YYYY-MM-DD"));
                  }}
                  minDate={dayjs("01-01-1901")}
                  slotProps={{
                    textField: {
                      error: false,
                      helperText: false,
                    },
                  }}
                />
              )}
            />
          </Grid2>
          <Show.When isTrue={isEdit}>
            <Grid2 size={6}>
              <Controller
                name="personalDetails.aadhar"
                control={form.control}
                render={({ field }) => (
                  <GKTextField
                    {...field}
                    type="number"
                    inputLabel="Aadhar No."
                  />
                )}
              />
            </Grid2>
            <Grid2 size={6}>
              <Controller
                name="personalDetails.pan"
                control={form.control}
                rules={{
                  pattern: {
                    value: panRegex,
                    message: "PAN should have this format ABCDE1234A",
                  },
                  maxLength: {
                    value: 10,
                    message: "PAN should have 10 character",
                  },
                  minLength: {
                    value: 10,
                    message: "PAN should have 10 character",
                  },
                }}
                render={({ field, fieldState: { error } }) => (
                  <GKTextField
                    {...field}
                    inputLabel="PAN"
                    onChange={(e) =>
                      field.onChange(e.target.value.toUpperCase())
                    }
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              />
            </Grid2>
            <Grid2 size={6}>
              <Controller
                name="personalDetails.emergencyContact"
                control={form.control}
                render={({ field }) => (
                  <GKTextField {...field} inputLabel="Emergency Contact Name" />
                )}
              />
            </Grid2>
            <Grid2 size={6}>
              <Controller
                name="personalDetails.emergencyContactNum"
                control={form.control}
                rules={{
                  minLength: {
                    value: 10,
                    message: "Minimum length is 10 characters",
                  },
                  maxLength: {
                    value: 10,
                    message: "Maximum length is 10 characters",
                  },
                }}
                render={({ field, fieldState: { error } }) => (
                  <GKTextField
                    {...field}
                    type="number"
                    inputLabel="Emergency Contact Number"
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              />
            </Grid2>
            <Grid2 size={6}>
              <Controller
                name="joiningDate"
                control={form.control}
                render={({ field }) => (
                  <GKDatePicker
                    {...field}
                    disableFuture
                    inputLabel="Joining Date"
                    value={dayjs(field.value)}
                    onChange={(value) => {
                      field.onChange(dayjs(value).format("YYYY-MM-DD"));
                    }}
                    minDate={dayjs("01-01-1901")}
                    slotProps={{
                      textField: {
                        error: false,
                        helperText: false,
                      },
                    }}
                  />
                )}
              />
            </Grid2>
            <Grid2 size={6}>
              <Controller
                name="baskets"
                control={form.control}
                render={({ field }) => (
                  <GKSearchSelect
                    {...field}
                    disableClearable
                    inputLabel="Basket"
                    multiple
                    loading={isFetching}
                    options={getAllBasket?.data?.data || []}
                    value={
                      getAllBasket?.data?.data?.filter(
                        (option: { id: number }) =>
                          field.value.includes(option.id),
                      ) || []
                    }
                    onChange={(event, val) => {
                      field.onChange(
                        val.map((option: { id: number }) => option.id),
                      );
                    }}
                    getOptionLabel={(option) => option.name || option}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder={handleReturnAutocompletePlaceholder({
                          placeholder: "Select basket",
                          value: field.value,
                        })}
                      />
                    )}
                  />
                )}
              />
            </Grid2>
          </Show.When>
        </Grid2>
      </form>
    </GKModal>
  );
};

export default PartnerModal;
