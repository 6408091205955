import { useQueries, useQuery } from "@tanstack/react-query";
import { useAppContext } from "AppContext";
import {
  CityResponseInterface,
  ClientListInterface,
  ClientResponseInterface,
  CompaniesListInterface,
  CompaniesResponseInterface,
  CountryResponseInterface,
  EmployeeListInterface,
  EmployeeResponseInterface,
  FamilyListInterface,
  FamilyResponseInterface,
  FeePlanListInterface,
  PartnerListInterface,
  PartnerResponseInterface,
  PortfolioGroupListInterface,
  PortfolioGroupResponseInterface,
  PortfolioListInterface,
  PortfolioResponseInterface,
  StateResponseInterface,
} from "interfaces/PrivateRouteInterface";
import { useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { IS_FIRST_LOGIN, USER_LOADING } from "store/slices/appSlice";
import { useAppDispatch, useAppSelector } from "store/store";
import {
  GET_ALL_EMPLOYEE,
  GET_ALL_FAMILY,
  GET_ALL_FEE_PLAN,
} from "utils/apiRoutes";
import axiosInstance from "utils/axiosInstance";
import { currentVersion } from "utils/constants";
import { QueryKeys } from "utils/queryKey";

const PrivateRoute = ({ children }: any) => {
  const location = useLocation();

  const dispatch = useAppDispatch();

  const columnsPersist = useAppSelector((state) => state?.app?.columnsPersist);

  const {
    state: { commonFilter, selectedCountry, selectedState },
    dispatch: setDispatch,
  } = useAppContext();

  useEffect(() => {
    const handleBeforeUnload = () =>
      localStorage.setItem("columns", JSON.stringify(columnsPersist));

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => window.removeEventListener("beforeunload", handleBeforeUnload);
  }, [columnsPersist]);

  const token = Boolean(localStorage.getItem(`token`));

  const isCalledApi =
    token &&
    !location.pathname?.includes("/broker-integration") &&
    location?.pathname !== "client-risk-profile" &&
    !location.pathname?.includes("/broker-integration/order") &&
    !location.pathname?.includes("/connect-client-payment-gateway") &&
    !location.pathname?.includes("/broker-integration/broker");

  useQuery({
    queryKey: [QueryKeys.GET_USER_DETAIL],
    queryFn: () => axiosInstance.get(`/user-details/`),
    enabled: isCalledApi,
    onSuccess: (data) => {
      setDispatch({
        type: "CURRENT_USER",
        data: data?.data?.data,
      });
      localStorage?.setItem("token", JSON.stringify(data?.data?.data?.token));
      localStorage?.setItem("role", data?.data?.data?.userRole);
      localStorage?.setItem("product-version", currentVersion);
      dispatch(IS_FIRST_LOGIN(data?.data?.data?.isFirst));

      const d = Object.entries(commonFilter)?.map(([key, val]: [any, any]) => {
        return {
          [key]: {
            ...val,
            showNonZero: data?.data?.data?.showZeroHoldingTables,
          },
        };
      });

      const combinedObject: any = d.reduce((acc, obj) => {
        const item = Object.keys(obj)[0] as any;
        acc[item] = obj[item];
        return acc;
      }, {});

      setDispatch({ type: "FILTER", data: combinedObject });
      dispatch(USER_LOADING(false));
    },
    onError: (error: {
      response: { status: number; data: { message: string } };
    }) => {
      if (error?.response?.status === 401) {
        <Navigate to={"/"} replace />;
        localStorage.removeItem(`token`);
        localStorage.removeItem(`isInvestor`);
        localStorage.removeItem(`isAdvisor`);
        localStorage.removeItem(`userInfo`);
        localStorage.removeItem(`version`);
        localStorage.removeItem("breadcumbs");
        window.location.reload();
        dispatch(USER_LOADING(false));
      }
    },
  });

  useQueries({
    queries: [
      {
        queryKey: [QueryKeys.PORTFOLIOS_SHORT_DATA],
        queryFn: () => axiosInstance.get(`/portfolios/all/`),
        enabled: isCalledApi,
        onSuccess: (data: PortfolioResponseInterface) => {
          setDispatch({
            type: "PORTFOLIOS",
            data: data?.data?.map((item: PortfolioListInterface) => ({
              ...item,
              name: `${item.name} - ${item.portfolioId}`,
              value: item.id,
              portfolioName: item.name,
            })),
          });
        },
      },
      {
        queryKey: ["FETCH_COMPANIES"],
        queryFn: () => axiosInstance.get("/intruments/list/"),
        enabled: isCalledApi,
        onSuccess: (data: CompaniesResponseInterface) => {
          setDispatch({
            type: "COMPANIES",
            data: data?.data?.data?.map((item: CompaniesListInterface) => ({
              ...item,
              name: item.name,
              value: item.gridCode,
            })),
          });
        },
      },
      {
        queryKey: ["FETCH_ALL_PORTFOLIO_GROUP"],
        queryFn: () => axiosInstance.get("/portfolio-groups/all/?role=advisor"),
        enabled: isCalledApi,
        onSuccess: (data: PortfolioGroupResponseInterface) => {
          setDispatch({
            type: "PORTFOLIO_GROUP_LIST",
            data: data?.data?.map((item: PortfolioGroupListInterface) => ({
              name: item.name,
              value: item.id,
            })),
          });
        },
      },
      {
        queryKey: [QueryKeys.CLIENTS_SHORT_DATA],
        queryFn: () => axiosInstance.get(`/client/all/`),
        enabled: isCalledApi,
        onSuccess: (data: ClientResponseInterface) => {
          setDispatch({
            type: "CLIENT_LIST",
            data: data?.data?.map((item: ClientListInterface) => ({
              ...item,
              name: `${item.name} - ${item?.clientCode}`,
              value: item.id,
            })),
          });
        },
      },
      {
        queryKey: [QueryKeys.EMPLOYEE_LIST],
        queryFn: () => axiosInstance.get(GET_ALL_EMPLOYEE),
        enabled: isCalledApi,
        onSuccess: (data: EmployeeResponseInterface) => {
          setDispatch({
            type: "EMPLOYEE_LIST",
            data: data?.data?.map((item: EmployeeListInterface) => ({
              name: `${item.name || "-"}`,
              value: item.id,
            })),
          });
        },
      },
      {
        queryKey: [QueryKeys.FAMILY_LIST],
        queryFn: () => axiosInstance.get(GET_ALL_FAMILY),
        enabled: isCalledApi,
        onSuccess: (data: FamilyResponseInterface) => {
          setDispatch({
            type: "FAMILY_LIST",
            data: data?.data?.map((family: FamilyListInterface) => ({
              ...family,
              name: family.name,
              value: family.id,
            })),
          });
        },
      },
      {
        queryKey: ["GET_ALL_COUNTRIES"],
        queryFn: () => axiosInstance.get("/country/"),
        enabled: isCalledApi,
        onSuccess: (response: CountryResponseInterface) => {
          setDispatch({
            type: "COUNTRY_LIST",
            data: response?.data?.data,
          });
        },
      },
      {
        queryKey: ["GET_STATE", selectedCountry],
        queryFn: () =>
          axiosInstance.get(`/state/?country_id=${selectedCountry}`),
        enabled: isCalledApi && Boolean(selectedCountry),
        onSuccess: (response: StateResponseInterface) => {
          setDispatch({
            type: "STATE_LIST",
            data: response?.data?.data,
          });
        },
      },
      {
        queryKey: ["GET_CITY", selectedState],
        queryFn: () => axiosInstance.get(`/city/?state_id=${selectedState}`),
        enabled: Boolean(selectedState) && isCalledApi,
        onSuccess: (response: CityResponseInterface) => {
          setDispatch({
            type: "CITY_LIST",
            data: response?.data?.data,
          });
        },
      },
      {
        queryKey: ["FETCH_ALL_PARTNER"],
        queryFn: () => axiosInstance.get("/partner/all/"),
        enabled: isCalledApi,
        onSuccess: (data: PartnerResponseInterface) => {
          setDispatch({
            type: "PARTNER_LIST",
            data: data?.data?.map((item: PartnerListInterface) => ({
              name: item.name,
              value: item.id,
            })),
          });
        },
      },
      {
        queryKey: ["ON_BOARDING_FLOW"],
        queryFn: () =>
          axiosInstance.get(
            "/onboardflow/?flow_type=advisor_initiated_advisory_flow",
          ),
        enabled: isCalledApi,
        onSuccess: (data: any) => {
          setDispatch({
            type: "ON_BOARDING_FLOW",
            data: data?.data?.data,
          });
        },
      },
      {
        queryKey: ["GET_FEE_PLANS_DROPDOWN"],
        queryFn: () => axiosInstance.get(GET_ALL_FEE_PLAN),
        enabled: isCalledApi,
        onSuccess: (data: any) => {
          dispatch({
            type: "FEE_PLAN_LIST",
            data: data?.data?.data.map((dataItem: FeePlanListInterface) => {
              return {
                name: dataItem.name,
                value: dataItem.value,
              };
            }),
          });
        },
      },
      {
        queryKey: ["INVESTOR_ON_BOARDING_FLOW"],
        queryFn: () =>
          axiosInstance.get(
            "/onboardflow/?flow_type=client_initiated_advisory_flow",
          ),
        enabled: isCalledApi,
        onSuccess: (data: any) => {
          dispatch({
            type: "INVESTOR_ON_BOARDING_FLOW",
            data: data?.data?.data,
          });
        },
      },
    ],
  });

  return localStorage.getItem("token") ? children : <Navigate to="/login" />;
};

export default PrivateRoute;
