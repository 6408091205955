import { LoadingButton } from "@mui/lab";
import { Divider, GKSideModal, Grid2, Tab, Tabs } from "components";
import { useFetchQuery, useMutation } from "hooks/useQueries";
import { PortfolioDetailsInterface } from "interfaces/Portfolio";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { CLIENT_NOMINEE_BY_ID } from "utils/apiRoutes";
import axiosInstance from "utils/axiosInstance";
import { refetchQuery } from "utils/helpers";
import { QueryKeys } from "utils/queryKey";
import BankInfoTab from "./BankInfoTab";
import BasicInfoTab from "./BasicInfoTab";
import DematInfoTab from "./DematInfoTab";
import NomineeTab from "./NomineeTab";

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  portfolioData: PortfolioDetailsInterface;
  portfolioId: string | number;
  setPortfolioId: (portfolioId: string | number) => void;
}

const PortfolioProfileEdit = (props: Props) => {
  const { open, setOpen, portfolioData, portfolioId, setPortfolioId } = props;

  const [portfolioTabs, setPortfolioTabs] = useState(0);

  const { mutate: handleUpdatePortfolio, isLoading: portfolioLoading } =
    useMutation({
      mutationFn: (values: any) =>
        axiosInstance.patch(`/portfolios/${portfolioId}/`, values),
      onSuccess: () => {
        refetchQuery("PORTFOLIO_UPDATE_DATA_BY_ID");
        refetchQuery("GET_PORTFOLIO_DETAILS");
      },
    });

  const { data } = useFetchQuery({
    key: [QueryKeys.CLIENT_NOMINEE_ACCOUNT],
    route: CLIENT_NOMINEE_BY_ID(portfolioData?.client),
  });

  const { mutate: handleUpdatePortfolioNominee, isLoading: nomineeLoading } =
    useMutation({
      mutationKey: ["UPDATE_CLIENT_NOMINEE"],
      mutationFn: (data: any) =>
        axiosInstance.patch(`/client/${portfolioData?.client}/nominees/`, data),
      onSuccess: () => {
        refetchQuery("GET_ALL_PORTFOLIOS");
      },
    });

  const form = useForm({
    defaultValues: {
      portfolio: {
        partners: portfolioData ? portfolioData?.partners : [],
        broker: portfolioData ? portfolioData?.broker : "OTHER",
        name: portfolioData ? portfolioData?.name : "",
        committedValue: portfolioData ? portfolioData?.committedValue : null,
        portfolioId: portfolioData ? portfolioData?.portfolioId : "",
        client: portfolioData ? portfolioData?.client : "",
      },
      nominees: [{ name: "", percentage: "", relationship: "" }],
    },
    values: {
      portfolio: {
        partners: portfolioData ? portfolioData?.partners : [],
        broker: portfolioData ? portfolioData?.broker : "OTHER",
        name: portfolioData ? portfolioData?.name : "",
        committedValue: portfolioData ? portfolioData?.committedValue : null,
        portfolioId: portfolioData ? portfolioData?.portfolioId : "",
        client: portfolioData ? portfolioData?.client : "",
      },
      nominees: data?.data?.nominees.length
        ? data?.data?.nominees.map((dataItem: any) => {
            return {
              ...dataItem,
              rowId: dataItem.id,
            };
          })
        : [{ name: "", percentage: "", relationship: "" }],
    },
  });

  return (
    <GKSideModal
      width="80%"
      open={open}
      setOpen={setOpen}
      onClose={() => setPortfolioId(null)}
      modalTitle="Portfolio Profile Details"
      footerButton={
        <Grid2 container justifyContent={"flex-end"}>
          <LoadingButton
            form="portfolio-details-update-form"
            type="submit"
            variant="contained"
            loading={nomineeLoading || portfolioLoading}
          >
            Update
          </LoadingButton>
        </Grid2>
      }
    >
      <FormProvider {...form}>
        <form
          id="portfolio-details-update-form"
          onSubmit={form.handleSubmit((values) => {
            if (portfolioTabs === 2) {
              const promise1 = handleUpdatePortfolio(values.portfolio);
              const promise2 = handleUpdatePortfolioNominee(values.nominees);

              Promise.all([promise1, promise2]);
            } else {
              handleUpdatePortfolio(values.portfolio);
            }
          })}
        >
          <Grid2 container spacing={1}>
            <Grid2 size={12}>
              <BasicInfoTab isDisable={portfolioData?.isPms} />
            </Grid2>
            <Grid2 size={12}>
              <Tabs
                onChange={(e, val) => {
                  setPortfolioTabs(val);
                }}
                value={portfolioTabs}
              >
                <Tab label="Bank" />
                <Tab label="Demat" />
                <Tab label="Nominee" />
              </Tabs>
              <Divider />
            </Grid2>
            <Grid2 size={12}>
              {portfolioTabs === 0 && (
                <BankInfoTab portfolioData={portfolioData} />
              )}
              {portfolioTabs === 1 && (
                <DematInfoTab portfolioData={portfolioData} />
              )}
              {portfolioTabs === 2 && (
                <NomineeTab portfolioData={portfolioData} />
              )}
            </Grid2>
          </Grid2>
        </form>
      </FormProvider>
    </GKSideModal>
  );
};

export default PortfolioProfileEdit;
