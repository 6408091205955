import { LoadingButton } from "@mui/lab";
import { useMutation } from "@tanstack/react-query";
import {
  Box,
  CgMathPlus,
  FormControlLabel,
  GKButton,
  GKEditor,
  GKRadio,
  GKSearchSelect,
  GKTextField,
  Grid2,
  InputAdornment,
  PopConfirm,
  RadioGroup,
  Show,
  Switch,
  TextField,
  Typography,
} from "components";
import { useFetchQuery } from "hooks/useQueries";
import { FeePlanListInterface } from "interfaces/PrivateRouteInterface";
import DashboardLayout from "layouts/DashboardLayout";
import { useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { GET_ALL_FEE_PLAN } from "utils/apiRoutes";
import axiosInstance from "utils/axiosInstance";
import { validationForPercentage } from "utils/helpers";
import { handleReturnAutocompletePlaceholder } from "utils/StringFunctions";
import { ThemeContainer } from "utils/Theme";
import useDocumentTitle from "utils/useDocumentTitle";
import UserSpecificCouponModal from "../FeePlans/UserSpecificCouponModal";
import { generateRandomString } from "../utils";

interface FormValues {
  name: string;
  code: string;
  description: string;
  couponType: string;
  discountValue: string;
  maximumDiscountValue: string;
  applyOn: string;
  validFrom: null;
  validTo: null;
  couponUsage: any[];
  universalLimit: string;
  perUserLimit: string;
  isActive: boolean;
  feePlan: number | string;
  couponRestrictions: string;
  orderValue: string;
  allowedFrequency: string[];
}

const COUPON_TYPE = [
  { name: "Fix Amount", value: "fixed" },
  { name: "Percentage", value: "percentage" },
];

// const COUPON_RESTRICTIONS = [
//   { name: "Specific Frequency", value: "specific_frequency" },
//   { name: "Order Value", value: "order_value" },
// ];

const CouponCreation = () => {
  useDocumentTitle("Coupon");

  const navigate = useNavigate();
  const { id } = useParams();

  const { theme } = ThemeContainer();

  const [feePlanList, setFeePlanList] = useState([]);
  const [openUserSpecificModal, setOpenUserSpecificModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const { data, isFetching } = useFetchQuery({
    key: ["FETCH_COUPON_BY_ID"],
    route: `/fee-plan-coupon/${id}/`,
    enabled: !!id,
  });

  const { isFetching: feePlanFetching } = useFetchQuery({
    key: ["GET_FEE_PLANS_DROPDOWN"],
    route: `${GET_ALL_FEE_PLAN}`,
    requestBody: { all_fee_plan: true },
    success: (data: any) => {
      setFeePlanList(
        data?.data?.data.map((dataItem: FeePlanListInterface) => {
          return {
            name: dataItem.name,
            value: dataItem.value,
          };
        }),
      );
    },
  });

  const { mutate: createCoupon, isLoading } = useMutation({
    mutationKey: ["CREATE_COUPON"],
    mutationFn: (data: FormValues) =>
      axiosInstance.post("/fee-plan-coupon/", data),
    onSuccess: () => {
      navigate(-1);
    },
  });

  const { mutate: updateCoupon, isLoading: updateCouponLoading } = useMutation({
    mutationKey: ["CREATE_COUPON"],
    mutationFn: (data: FormValues) =>
      axiosInstance.patch(`/fee-plan-coupon/${id}/`, data),
    onSuccess: () => {
      navigate(-1);
    },
  });

  const { mutate: deleteCoupon, isLoading: deleteCouponLoading } = useMutation({
    mutationKey: ["CREATE_COUPON"],
    mutationFn: (couponId: string) =>
      axiosInstance.delete(`/fee-plan-coupon/${couponId}/`),
    onSuccess: () => {
      navigate(-1);
    },
  });

  const form = useForm<FormValues>({
    defaultValues: {
      name: "",
      code: "",
      description: "",
      couponType: "fixed",
      discountValue: "",
      maximumDiscountValue: "0",
      applyOn: "user_specific",
      validFrom: null,
      validTo: null,
      couponUsage: [{ phone: "" }],
      universalLimit: "0",
      perUserLimit: "0",
      isActive: false,
      feePlan: null,
      couponRestrictions: "",
      orderValue: "0",
      allowedFrequency: [],
    },
    values: data?.data?.data,
  });

  const applyOnWatch = form.watch("applyOn");
  const couponTypeWatch = form.watch("couponType");
  // const couponRestrictionsWatch = form.watch("couponRestrictions");

  return (
    <DashboardLayout title="Create Coupon" loading={id && isFetching}>
      <FormProvider {...form}>
        <form
          onSubmit={form.handleSubmit((values) => {
            let data = values;

            if (values?.applyOn === "universal") {
              data = {
                ...data,
                couponUsage: [],
              };
            }

            id ? updateCoupon(data) : createCoupon(data);
          })}
        >
          <Grid2 container spacing={2}>
            <Grid2
              size={12}
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Typography
                fontSize={16}
                fontWeight={500}
                color={theme.palette.secondaryText.main}
              >
                Basic Details
              </Typography>

              <Box>
                <Controller
                  name="isActive"
                  control={form.control}
                  render={({ field }) => (
                    <FormControlLabel
                      control={<Switch {...field} checked={field.value} />}
                      label={"Active"}
                    />
                  )}
                />
                {!!id && (
                  <LoadingButton
                    variant="outlined"
                    loading={deleteCouponLoading}
                    color="error"
                    onClick={(event) => setAnchorEl(event.currentTarget)}
                  >
                    Delete
                  </LoadingButton>
                )}
              </Box>
            </Grid2>

            <Grid2 size={4}>
              <Controller
                name="name"
                control={form.control}
                rules={{
                  required: {
                    value: true,
                    message: "Please enter a name",
                  },
                }}
                render={({ field, fieldState: { error } }) => (
                  <GKTextField
                    {...field}
                    requiredField
                    inputLabel={"Name"}
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              />
            </Grid2>
            <Grid2 size={4}>
              <Controller
                name="couponType"
                control={form.control}
                rules={{
                  required: {
                    value: true,
                    message: "Please select a Coupon Type",
                  },
                }}
                render={({ field, fieldState: { error } }) => (
                  <GKSearchSelect
                    {...field}
                    requiredField
                    inputLabel="Coupon Type"
                    disableClearable
                    options={COUPON_TYPE || []}
                    onChange={(e, val) => field.onChange(val?.value)}
                    value={
                      COUPON_TYPE.find(
                        (dataItem) => dataItem.value === field.value,
                      ) || ""
                    }
                    getOptionLabel={(option) => option.name || option}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder={handleReturnAutocompletePlaceholder({
                          placeholder: "Select Coupon Type",
                          value: field.value,
                        })}
                        error={!!error}
                        helperText={error?.message}
                      />
                    )}
                  />
                )}
              />
            </Grid2>
            <Grid2 size={4}>
              <Controller
                name="feePlan"
                control={form.control}
                rules={{
                  required: {
                    value: true,
                    message: "Please select fee plan",
                  },
                }}
                render={({ field, fieldState: { error } }) => (
                  <GKSearchSelect
                    {...field}
                    requiredField
                    inputLabel="Fee Plan"
                    loading={feePlanFetching}
                    disableClearable
                    options={feePlanList || []}
                    onChange={(e, val) => field.onChange(val?.value)}
                    value={
                      feePlanList.find(
                        (dataItem) => dataItem.value === field.value,
                      ) || ""
                    }
                    getOptionLabel={(option) => option.name || option}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder={handleReturnAutocompletePlaceholder({
                          placeholder: "Select fee plan",
                          value: field.value,
                        })}
                        error={!!error}
                        helperText={error?.message}
                      />
                    )}
                  />
                )}
              />
            </Grid2>

            <Grid2 size={12}>
              <Controller
                name="description"
                control={form.control}
                render={({ field }) => (
                  <GKEditor
                    value={field.value}
                    onChange={field.onChange}
                    inputLabel="Description"
                  />
                )}
              />
            </Grid2>

            <Grid2 size={5} display={"flex"} alignItems={"center"} gap={2}>
              <Controller
                name="code"
                control={form.control}
                rules={{
                  required: {
                    value: true,
                    message: "Please enter a code",
                  },
                  maxLength: {
                    value: 10,
                    message: "Code should have 10 character",
                  },
                  minLength: {
                    value: 10,
                    message: "Code should have 10 character",
                  },
                  pattern: {
                    value: /^[A-Za-z0-9]+$/,
                    message: "Special characters are not allowed",
                  },
                }}
                render={({ field, fieldState: { error } }) => (
                  <GKTextField
                    {...field}
                    requiredField
                    inputLabel={"Coupon Code"}
                    onChange={(e) => {
                      const { value } = e.target;

                      if (/^[A-Za-z0-9]*$/.test(value)) {
                        field.onChange(value);
                      }
                    }}
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              />
              <GKButton
                variant="outlined"
                color="dark"
                sx={{ mt: "20px" }}
                onClick={() => {
                  const generateCouponCode = generateRandomString(10);

                  form.setValue("code", generateCouponCode);
                  form.clearErrors("code");
                }}
              >
                Generate
              </GKButton>
            </Grid2>

            <Grid2 size={1} />

            <Grid2 size={3}>
              <Controller
                name="discountValue"
                control={form.control}
                render={({ field }) => (
                  <GKTextField
                    {...field}
                    type="number"
                    value={
                      couponTypeWatch !== "fixed"
                        ? validationForPercentage(+field.value)
                        : field.value
                    }
                    inputLabel={"Discount Value"}
                    slotProps={{
                      input: {
                        startAdornment: (
                          <InputAdornment position="start">
                            {couponTypeWatch === "fixed" ? "₹" : "%"}
                          </InputAdornment>
                        ),
                      },
                    }}
                  />
                )}
              />
            </Grid2>
            <Show.When isTrue={couponTypeWatch !== "fixed"}>
              <Grid2 size={3}>
                <Controller
                  name="maximumDiscountValue"
                  control={form.control}
                  render={({ field }) => (
                    <GKTextField
                      {...field}
                      inputLabel={"Maximum Value"}
                      type="number"
                    />
                  )}
                />
              </Grid2>
            </Show.When>

            <Grid2 size={12} mt={3}>
              <Typography
                fontSize={16}
                fontWeight={500}
                color={theme.palette.secondaryText.main}
              >
                Restriction
              </Typography>
            </Grid2>

            <Grid2 size={12} display={"flex"}>
              <Controller
                control={form.control}
                name="applyOn"
                render={({ field }) => (
                  <RadioGroup
                    {...field}
                    row
                    onChange={(e) => field.onChange(e.target.value)}
                  >
                    <FormControlLabel
                      label="Universal"
                      value="universal"
                      control={<GKRadio disableLeftPadding />}
                    />
                    <FormControlLabel
                      label="User Specific"
                      value="user_specific"
                      control={<GKRadio disableLeftPadding />}
                    />
                  </RadioGroup>
                )}
              />
              <Show.When isTrue={applyOnWatch === "user_specific"}>
                <GKButton
                  variant="outlined"
                  color="dark"
                  startIcon={<CgMathPlus />}
                  sx={{ ml: 2 }}
                  onClick={() => setOpenUserSpecificModal(true)}
                >
                  Add Number
                </GKButton>
              </Show.When>
            </Grid2>

            <Show.When isTrue={applyOnWatch === "universal"}>
              <Grid2 size={3} display={"flex"} alignItems={"center"} gap={1}>
                <Controller
                  name="universalLimit"
                  control={form.control}
                  render={({ field }) => (
                    <GKTextField
                      {...field}
                      inputLabel={"Universal Limit"}
                      type="number"
                    />
                  )}
                />
              </Grid2>
            </Show.When>
            <Grid2 size={3} display={"flex"} alignItems={"center"} gap={1}>
              <Controller
                name="perUserLimit"
                control={form.control}
                render={({ field }) => (
                  <GKTextField
                    {...field}
                    inputLabel={"Per User Limit"}
                    type="number"
                  />
                )}
              />
            </Grid2>

            <Grid2 size={12} container>
              {/* <Grid2 size={3}>
                <Controller
                  name="couponRestrictions"
                  control={form.control}
                  render={({ field }) => (
                    <GKSearchSelect
                      {...field}
                      disableCloseOnSelect
                      inputLabel="Coupon Restrictions"
                      disableClearable={false}
                      options={COUPON_RESTRICTIONS || []}
                      onChange={(e, val) => field.onChange(val?.value)}
                      value={
                        COUPON_RESTRICTIONS.find(
                          (dataItem) => dataItem.value === field.value,
                        ) || ""
                      }
                      getOptionLabel={(option) => option.name || option}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder={handleReturnAutocompletePlaceholder({
                            placeholder: "Select coupon restrictions",
                            value: field.value,
                          })}
                        />
                      )}
                    />
                  )}
                />
              </Grid2> */}
              {/* {couponRestrictionsWatch === "order_value" && (
                <Grid2 size={3}>
                  <Controller
                    name="orderValue"
                    control={form.control}
                    rules={{
                      required: {
                        value: true,
                        message: "Please enter order value",
                      },
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <GKTextField
                        {...field}
                        inputLabel={"Order Value"}
                        type="number"
                        requiredField
                        error={!!error}
                        helperText={error?.message}
                      />
                    )}
                  />
                </Grid2>
              )}

              {couponRestrictionsWatch === "specific_frequency" && (
                <Grid2 size={3}>
                  <Controller
                    name="allowedFrequency"
                    control={form.control}
                    rules={{
                      required: {
                        value: true,
                        message: "Please select allowed frequency",
                      },
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <GKSearchSelect
                        {...field}
                        disableClearable
                        requiredField
                        inputLabel="Allowed Frequency"
                        multiple
                        options={SUBSCRIPTION_FREQUENCY || []}
                        onChange={(event, val) => {
                          field.onChange(
                            val.map(
                              (option: { value: number }) => option.value,
                            ),
                          );
                        }}
                        value={
                          SUBSCRIPTION_FREQUENCY?.filter(
                            (option: { value: string }) =>
                              field?.value?.includes(option?.value),
                          ) || []
                        }
                        getOptionLabel={(option) => option.name || option}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder={handleReturnAutocompletePlaceholder({
                              placeholder: "Select allowed frequency",
                              value: field.value,
                            })}
                            error={!!error}
                            helperText={error?.message}
                          />
                        )}
                      />
                    )}
                  />
                </Grid2>
              )} */}
            </Grid2>

            <Grid2
              size={12}
              display={"flex"}
              justifyContent={"flex-end"}
              mt={2}
            >
              <LoadingButton
                variant="contained"
                type="submit"
                loading={isLoading || updateCouponLoading}
              >
                Save
              </LoadingButton>
            </Grid2>

            <Show.When isTrue={openUserSpecificModal}>
              <UserSpecificCouponModal
                open={openUserSpecificModal}
                setOpen={setOpenUserSpecificModal}
              />
            </Show.When>
          </Grid2>
        </form>
      </FormProvider>

      <Show.When isTrue={Boolean(anchorEl)}>
        <PopConfirm
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          setOpen={setAnchorEl}
          onSuccess={() => deleteCoupon(id)}
        />
      </Show.When>
    </DashboardLayout>
  );
};

export default CouponCreation;
