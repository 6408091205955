import { useAppContext } from "AppContext";
import { CgMathPlus, GKButton, GKDataGrid } from "components";
import { useLocation, useNavigate } from "react-router-dom";
import { portfolioHoldingTransactionsColumns } from "utils/columns";
import { UserRoleTypes } from "utils/common";
import useDocumentTitle from "utils/useDocumentTitle";

const PortfolioHoldingTradeBook = ({
  data,
  isFetching,
  portfolioId,
  createTransactionData,
}: {
  data: any;
  isFetching: boolean;
  portfolioId: string | number;
  createTransactionData: {
    gridCode: string;
    fdAccountNo: string;
    interestType: string;
  };
}) => {
  useDocumentTitle("Holding Trade Book");

  const {
    state: { userData },
  } = useAppContext();

  const location = useLocation();
  const navigate = useNavigate();

  const type = new URLSearchParams(location.search).get("type");

  return (
    <GKDataGrid
      loading={isFetching}
      maxHeight={"calc(100vh - 312px)"}
      // columns={portfolioHoldingTransactionsColumns}
      columns={portfolioHoldingTransactionsColumns.filter((column) => {
        return column.visibleType?.includes(type);
      })}
      getRowClassName={(params) => {
        return params?.row?.valid ? "" : "back-red";
      }}
      rows={
        data?.data?.data?.map((data: any, index: number) => {
          return {
            ...data,
            id: index,
          };
        }) || []
      }
      density="compact"
      totalCount={data?.data?.totalCount || 0}
      name="portfolioHoldingTransaction"
      exportFile={{
        path: `/${location?.state?.type}/${location?.state?.holdingId}/company_transactions/`,
        exportName: "PortfoioHolingsList",
        paramsValue: { key: "gcode", value: location?.state?.gcode },
      }}
      headerComponent={
        userData?.userRole !== UserRoleTypes.client &&
        type === "FD" && (
          <GKButton
            onClick={() => {
              navigate("/app/create-fixed-deposit-transaction", {
                state: {
                  transactionType: "OTHER-ASSETS",
                  assetType: "FD",
                  id: portfolioId,
                  gridCode: createTransactionData?.gridCode,
                  fdAccountNo: createTransactionData?.fdAccountNo,
                  interestType: createTransactionData?.interestType,
                  disableField: true,
                },
              });
            }}
            variant="contained"
            startIcon={<CgMathPlus size={14} />}
          >
            Add Trade
          </GKButton>
        )
      }
    />
  );
};

export default PortfolioHoldingTradeBook;
