import { useFetchQuery, useMutation } from "hooks/useQueries";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { CLIENT_BY_ID } from "utils/apiRoutes";
import axiosInstance from "utils/axiosInstance";
import { refetchQuery } from "utils/helpers";
import { QueryKeys } from "utils/queryKey";

export const useEditClient = () => {
  const { id: clientID } = useParams();

  const [clientData, setClientData] = useState(null);

  const { isFetching: clientLoading, isFetched } = useFetchQuery({
    key: ["GET_CLIENT_BY_ID"],
    route: CLIENT_BY_ID(clientID),
    success: (data: any) => {
      setClientData(data?.data);
    },
  });

  const { mutate: handleClientUpdate, isLoading } = useMutation({
    mutationFn: (data) => axiosInstance.patch(`/client/${clientID}/`, data),
    onSuccess: () => {
      refetchQuery(QueryKeys.CLIENTS_LIST);
    },
  });

  const form = useForm({
    defaultValues: {
      branch: null,
      family: [],
      phone: "",
      countryCode: "",
      personalDetails: {
        id: null,
        email: "",
        name: "",
        gender: "",
        maritalStatus: "",
        fatherName: "",
        motherName: "",
        dateOfBirth: "",
        gstn: "",
        address: "",
        city: null,
        state: null,
        country: null,
        pincode: null,
        income: 0,
        occupation: "",
        pan: "",
        aadhar: "",
        emergencyContact: "",
        emergencyContactNum: "",
      },
      portfolioCount: 0,
      clientCode: "",
      lead: null,
      bankAccounts: [],
      dematAccounts: [],
      nominees: [],
      familyName: "",
      // basket: [],
    },
    values: clientData,
  });

  const onSubmit = (values: any) => handleClientUpdate(values);

  return {
    clientData,
    clientLoading,
    isFetched,
    isLoading,
    form,
    onSubmit,
  };
};
