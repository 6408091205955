import {
  GKButton,
  GKClientDataGrid,
  GKModal,
  GKSearchSelect,
  GKTextField,
  Grid2,
  IconButton,
  LuTrash2,
  TextField,
  Typography,
} from "components";
import { isEmpty, sum } from "lodash";
import { useState } from "react";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import toast from "react-hot-toast";
import { LuUpload } from "react-icons/lu";
import { numberToCurrency } from "utils";
import { GKModalProps } from "utils/commonInterface";
import { ThemeContainer } from "utils/Theme";
import { useInvestorContext } from "./InvestorContext";
import UploadHoldingsModal from "./UploadHoldingsModal";

const PlansHoldingsModal = (props: GKModalProps) => {
  const form = useFormContext();
  const { theme } = ThemeContainer();

  const [uploadHoldingsModal, setUploadHoldingsModal] = useState(false);

  const {
    state: { companies },
  } = useInvestorContext();

  const { append } = useFieldArray({
    name: "planDataObject.plansHoldings",
    control: form.control,
  });

  form.watch("planDataObject.plansHoldings");

  const companiesOption = companies?.filter(
    (data: any) =>
      !form
        .getValues("planDataObject.plansHoldings")
        ?.map((dataItem: any) => dataItem.gridCode)
        .includes(data.value),
  );

  const { open, setOpen } = props;

  return (
    <>
      <GKModal
        open={open}
        setOpen={setOpen}
        modalTitle="Add Holdings"
        footer={
          <Grid2
            size={12}
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <GKButton
              variant="outlined"
              color="dark"
              onClick={() => {
                form.setValue("planDataObject.showHoldingsModal", false);
                form?.setValue("planDataObject.plansHoldings", []);
              }}
            >
              Cancel
            </GKButton>
            <Typography color="#64748B">
              Total Holding Value is{" "}
              <span
                style={{
                  fontWeight: 600,
                  color: "#334155",
                }}
              >
                {numberToCurrency(
                  sum(
                    form
                      .getValues("planDataObject.plansHoldings")
                      .map((d: any) => d.cmp * d.quantity),
                  ) || 0,
                )}
              </span>
            </Typography>
            <GKButton
              variant="contained"
              onClick={() => {
                const planHoldings =
                  form.getValues("planDataObject.plansHoldings") || [];

                const emptyGridCode = planHoldings.some(
                  (obj: { gridCode: string }) => isEmpty(obj.gridCode),
                );

                const emptyQuantity = planHoldings.some(
                  (obj: { quantity: number }) => +obj.quantity === 0,
                );
                if (emptyQuantity) {
                  toast.error(
                    "Please make sure that you have quantity for all the holdings are more 0.",
                  );
                } else if (emptyGridCode) {
                  toast.error(
                    "Please make sure that you have selected all companies properly.",
                  );
                } else {
                  form.setValue("planDataObject.showHoldingsModal", false);
                }
              }}
            >
              Save
            </GKButton>
          </Grid2>
        }
      >
        <Grid2 container>
          <Grid2 size={12}>
            <GKClientDataGrid
              pagination
              columnHeaderHeight={36}
              disableRowSelectionOnClick
              maxHeight={`calc(100vh - 450px)`}
              name="plansHoldings"
              columnsButton={false}
              getRowClassName={(params) => {
                return isEmpty(params.row.gridCode) ||
                  +params.row.quantity === 0
                  ? "error-row"
                  : "";
              }}
              rows={
                form
                  .getValues("planDataObject.plansHoldings")
                  ?.map((d: any, index: any) => {
                    return {
                      ...d,
                      index: index,
                    };
                  }) || []
              }
              headerComponent={
                <Grid2
                  size={12}
                  display={"flex"}
                  gap={1}
                  justifyContent={"flex-end"}
                >
                  <GKButton
                    startIcon={<LuUpload size={16} />}
                    variant="outlined"
                    color="dark"
                    onClick={() => {
                      setUploadHoldingsModal(true);
                    }}
                  >
                    Upload via excel
                  </GKButton>
                  <GKButton
                    variant="outlined"
                    size="small"
                    onClick={() => {
                      append({
                        gridCode: "",
                        name: "",
                        quantity: 0,
                        cmp: "",
                        id: form.getValues("planDataObject.plansHoldings")
                          ?.length,
                      });
                    }}
                  >
                    Add Stock
                  </GKButton>
                </Grid2>
              }
              columns={[
                {
                  field: "gridCode",
                  headerName: "Company Name",
                  minWidth: 300,
                  disableColumnMenu: true,
                  renderCell: (params: any) => {
                    return (
                      <Controller
                        name={`planDataObject.plansHoldings.${params?.row?.index}.gridCode`}
                        control={form.control}
                        render={({ field }) => {
                          return (
                            <GKSearchSelect
                              {...field}
                              disableClearable
                              size="small"
                              sx={{ width: "100%" }}
                              renderOption={(props, option) => (
                                <li
                                  {...props}
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 4,
                                  }}
                                >
                                  <Typography
                                    color={theme.palette.light.main}
                                    fontSize={8}
                                    style={{
                                      background:
                                        option?.instType === "EQ"
                                          ? theme.palette.secondary.main
                                          : theme.palette.warning.main,
                                      padding: "2px 4px",
                                      borderRadius: 4,
                                    }}
                                  >
                                    {option?.instType}
                                  </Typography>
                                  <Typography whiteSpace={"nowrap"}>
                                    {option?.name}
                                  </Typography>
                                </li>
                              )}
                              options={companiesOption || []}
                              getOptionLabel={(option) =>
                                option?.companyName || option
                              }
                              inputLabel={""}
                              renderInput={(params) => (
                                <TextField {...params} />
                              )}
                              value={
                                companies?.find(
                                  (data: { value: string }) =>
                                    data?.value === field.value,
                                )?.companyName || ""
                              }
                              onChange={(e, val) => {
                                field.onChange(val.value);
                                form.setValue(
                                  `planDataObject.plansHoldings.${params?.row?.index}.cmp`,
                                  val?.cmp,
                                );
                                form.setValue(
                                  `planDataObject.plansHoldings.${params?.row?.index}.name`,
                                  val?.name,
                                );
                              }}
                            />
                          );
                        }}
                      />
                    );
                  },
                },
                {
                  field: "quantity",
                  headerName: "Quantity",
                  minWidth: 120,
                  disableColumnMenu: true,
                  renderCell: (params: any) => {
                    return (
                      <Controller
                        name={`planDataObject.plansHoldings.${params?.row?.index}.quantity`}
                        control={form.control}
                        rules={{ required: true }}
                        render={({ field }) => {
                          return (
                            <GKTextField
                              {...field}
                              size="small"
                              placeholder="Enter quantity"
                            />
                          );
                        }}
                      />
                    );
                  },
                },
                {
                  headerName: "CMP",
                  field: "cmp",
                  flex: 1,
                  disableColumnMenu: true,
                },
                {
                  headerName: "Action",
                  headerAlign: "right",
                  align: "right",
                  field: "action",
                  flex: 1,
                  disableColumnMenu: true,
                  renderCell: ({ row }: { row: any }) => {
                    return (
                      <IconButton
                        color="error"
                        onClick={() => {
                          const filterData = form
                            .getValues("planDataObject.plansHoldings")
                            ?.filter((_: any, i: any) => i !== row.index);
                          form.setValue(
                            "planDataObject.plansHoldings",
                            filterData,
                          );
                        }}
                      >
                        <LuTrash2 size={18} />
                      </IconButton>
                    );
                  },
                },
              ]}
            />
          </Grid2>
        </Grid2>
      </GKModal>

      {uploadHoldingsModal && (
        <UploadHoldingsModal
          setOpen={setUploadHoldingsModal}
          open={uploadHoldingsModal}
        />
      )}
    </>
  );
};

export default PlansHoldingsModal;
