import {
  Divider,
  GKTypography,
  Grid2,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "components";
import { useFetchQuery } from "hooks/useQueries";
import { moneyCommaSeparatorPoint, numberToCurrency } from "utils";
import { paletteLightTheme } from "utils/ColorTheme";

interface Props {
  id: string;
}

const DetailsCardSection = (props: Props) => {
  const { id } = props;

  const { data, isFetching } = useFetchQuery({
    key: ["GET_PARTNER_SUMMARY_CARD"],
    route: `/partner/${id}/summary/`,
  });

  return (
    <Grid2 container spacing={2}>
      <Grid2 size={12}>
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell
                  style={{
                    borderRight: `solid 1px ${paletteLightTheme.palette.grey[300]}`,
                    borderBottom: "none",
                    padding: 16,
                    width: "16%",
                  }}
                >
                  <Typography fontSize={12} color={"#697586"}>
                    Total AUM
                  </Typography>
                  <GKTypography
                    fontWeight={600}
                    fontSize={18}
                    loading={isFetching}
                  >
                    {numberToCurrency(data?.data?.totalAum)}
                  </GKTypography>
                </TableCell>
                <TableCell
                  style={{
                    borderRight: `solid 1px ${paletteLightTheme.palette.grey[300]}`,
                    borderBottom: "none",
                    padding: 16,
                    width: "16%",
                  }}
                >
                  <Typography fontSize={12} color={"#697586"}>
                    Total Clients
                  </Typography>
                  <GKTypography
                    fontWeight={600}
                    fontSize={18}
                    loading={isFetching}
                  >
                    {moneyCommaSeparatorPoint(data?.data?.totalClients)}
                  </GKTypography>
                </TableCell>
                <TableCell
                  style={{
                    borderRight: `solid 1px ${paletteLightTheme.palette.grey[300]}`,
                    borderBottom: "none",
                    padding: 16,
                    width: "16%",
                  }}
                >
                  <Typography fontSize={12} color={"#697586"}>
                    Total Billed Revenue
                  </Typography>
                  <GKTypography
                    fontWeight={600}
                    fontSize={18}
                    loading={isFetching}
                  >
                    {numberToCurrency(data?.data?.totalBilledRevenue)}
                  </GKTypography>
                </TableCell>
                <TableCell
                  style={{
                    borderRight: `solid 1px ${paletteLightTheme.palette.grey[300]}`,
                    borderBottom: "none",
                    padding: 16,
                    width: "16%",
                  }}
                >
                  <Typography fontSize={12} color={"#697586"}>
                    Total Commission Paid
                  </Typography>
                  <GKTypography
                    fontWeight={600}
                    fontSize={18}
                    loading={isFetching}
                  >
                    {numberToCurrency(data?.data?.totalCommisionPaid)}
                  </GKTypography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid2>

      <Grid2 size={12}>
        <Divider />
      </Grid2>
    </Grid2>
  );
};

export default DetailsCardSection;
