import { Typography } from "@mui/material";
import { useAppContext } from "AppContext";
import { CommonFilterState } from "interfaces/CommonFilter";
import React, { Fragment } from "react";
import { FiTrash2 } from "react-icons/fi";
import { paletteLightTheme } from "./ColorTheme";
import { matchDate } from "./helpers";

interface Props {
  name: string;
}
export const TableFiltering: React.FC<Props> = (props) => {
  const { name } = props;

  const {
    state: { commonFilter },
    dispatch,
  } = useAppContext();

  const handleDeleteFilter = (type: string, value: any) => {
    const updatedCustomFilter = {
      ...commonFilter[name as keyof CommonFilterState]?.customFilter,
    };

    switch (type) {
      case "start_date":
      case "end_date":
      case "financialYear":
        delete updatedCustomFilter.start_date;
        delete updatedCustomFilter.end_date;
        delete updatedCustomFilter.financialYear;
        break;

      case "date":
        delete updatedCustomFilter.date;
        break;

      case "portfolioFilterName":
        updatedCustomFilter.portfolioFilterName =
          updatedCustomFilter.portfolioFilterName.filter(
            (item: any) => item.value !== value,
          );
        updatedCustomFilter.portfolio = updatedCustomFilter.portfolio.filter(
          (item: any) => item !== value,
        );
        break;

      case "type":
        updatedCustomFilter.type = updatedCustomFilter.type.filter(
          (item: any) => item !== value,
        );
        break;

      case "format":
        updatedCustomFilter.format = updatedCustomFilter.format.filter(
          (item: any) => item !== value,
        );
        break;

      case "source":
        updatedCustomFilter.source = updatedCustomFilter.source.filter(
          (item: any) => item !== value,
        );
        break;

      case "company":
        updatedCustomFilter.company = updatedCustomFilter.company.filter(
          (item: any) => item.value !== value,
        );
        updatedCustomFilter.gcode = updatedCustomFilter.gcode.filter(
          (item: any) => item !== value,
        );
        break;

      case "employeeName":
        updatedCustomFilter.employeeName =
          updatedCustomFilter.employeeName.filter(
            (item: any) => item.value !== value,
          );
        updatedCustomFilter.employee = updatedCustomFilter.employee.filter(
          (item: any) => item !== value,
        );
        break;

      case "clientName":
        updatedCustomFilter.clientName = updatedCustomFilter.clientName.filter(
          (item: any) => item.value !== value,
        );
        updatedCustomFilter.client = updatedCustomFilter.client.filter(
          (item: any) => item !== value,
        );
        break;

      case "groupName":
        updatedCustomFilter.groupName = updatedCustomFilter.groupName.filter(
          (item: any) => item.value !== value,
        );
        updatedCustomFilter.group = updatedCustomFilter.group.filter(
          (item: any) => item !== value,
        );
        break;

      case "feePlanName":
        updatedCustomFilter.feePlanName =
          updatedCustomFilter.feePlanName.filter(
            (item: any) => item.value !== value,
          );
        updatedCustomFilter.feePlan = updatedCustomFilter.feePlan.filter(
          (item: any) => item !== value,
        );
        break;

      case "partnerName":
        updatedCustomFilter.partnerName =
          updatedCustomFilter.partnerName.filter(
            (item: any) => item.value !== value,
          );
        updatedCustomFilter.partner = updatedCustomFilter.partner.filter(
          (item: any) => item !== value,
        );
        break;

      default:
        break;
    }

    dispatch({
      type: "FILTER",
      data: {
        ...commonFilter,
        [name]: {
          ...commonFilter[name as keyof CommonFilterState],
          customFilter: updatedCustomFilter,
        },
      },
    });
  };

  const customFilter =
    commonFilter[name as keyof CommonFilterState]?.customFilter || {};

  return (
    <div style={{ display: "flex", flexWrap: "wrap" }}>
      {customFilter?.date && (
        <>
          <div
            className="filter-item"
            style={{
              background: `${paletteLightTheme.palette.primary.main}22`,
            }}
          >
            <Typography fontSize={12} fontWeight={600}>
              {matchDate(customFilter?.date)}
            </Typography>

            <FiTrash2
              size={14}
              color={paletteLightTheme.palette.error.main}
              cursor={"pointer"}
              onClick={() => handleDeleteFilter("date", customFilter.date)}
            />
          </div>
          &nbsp;
        </>
      )}

      {customFilter?.start_date && customFilter?.end_date && (
        <>
          <div
            className="filter-item"
            style={{
              background: `${paletteLightTheme.palette.primary.main}22`,
            }}
          >
            <Typography fontSize={12} fontWeight={600}>
              {matchDate(customFilter?.start_date)} -{" "}
              {matchDate(customFilter?.end_date)}
            </Typography>

            <FiTrash2
              size={14}
              color={paletteLightTheme.palette.error.main}
              cursor={"pointer"}
              onClick={() => {
                handleDeleteFilter("start_date", "");
                handleDeleteFilter("end_date", "");
                handleDeleteFilter("financialYear", null);
              }}
            />
          </div>
          &nbsp;
        </>
      )}

      {customFilter?.company &&
        customFilter?.company?.map((dataItem: any) => {
          return (
            <>
              <div key={dataItem} className="filter-item">
                <Typography fontSize={12} fontWeight={600}>
                  {dataItem.name}
                </Typography>

                <FiTrash2
                  size={14}
                  color={paletteLightTheme.palette.error.main}
                  cursor={"pointer"}
                  onClick={() => {
                    handleDeleteFilter("company", dataItem.value);
                  }}
                />
              </div>{" "}
              &nbsp;
            </>
          );
        })}

      {customFilter?.employeeName?.map((dataItem: any, index: number) => {
        return (
          <Fragment key={index}>
            <div
              key={dataItem}
              className="filter-item"
              style={{
                background: `${paletteLightTheme.palette.primary.main}22`,
              }}
            >
              <Typography fontSize={12} fontWeight={600}>
                {dataItem.name}
              </Typography>

              <FiTrash2
                size={14}
                color={paletteLightTheme.palette.error.main}
                cursor={"pointer"}
                onClick={() =>
                  handleDeleteFilter("employeeName", dataItem.value)
                }
              />
            </div>
            &nbsp;
          </Fragment>
        );
      })}

      {customFilter?.clientName &&
        customFilter?.clientName?.map((dataItem: any) => {
          return (
            <>
              <div key={dataItem} className="filter-item">
                <Typography fontSize={12} fontWeight={600}>
                  {dataItem.name}
                </Typography>

                <FiTrash2
                  size={14}
                  color={paletteLightTheme.palette.error.main}
                  cursor={"pointer"}
                  onClick={() => {
                    handleDeleteFilter("clientName", dataItem.value);
                  }}
                />
              </div>{" "}
              &nbsp;
            </>
          );
        })}

      {customFilter?.groupName &&
        customFilter?.groupName.map((dataItem: any, index: number) => {
          return (
            <Fragment key={index}>
              <div key={dataItem.value} className="filter-item">
                <Typography fontSize={12} fontWeight={600}>
                  {dataItem.name}
                </Typography>

                <FiTrash2
                  size={14}
                  color={paletteLightTheme.palette.error.main}
                  cursor={"pointer"}
                  onClick={() =>
                    handleDeleteFilter("groupName", dataItem.value)
                  }
                />
              </div>{" "}
              &nbsp;
            </Fragment>
          );
        })}

      {customFilter?.portfolioFilterName &&
        customFilter?.portfolioFilterName?.map(
          (dataItem: any, index: number) => {
            return (
              <Fragment key={index}>
                <div key={dataItem?.name} className="filter-item">
                  <Typography fontSize={12} fontWeight={600}>
                    {dataItem?.name}
                  </Typography>

                  <FiTrash2
                    size={14}
                    color={paletteLightTheme.palette.error.main}
                    cursor={"pointer"}
                    onClick={() =>
                      handleDeleteFilter("portfolioFilterName", dataItem?.value)
                    }
                  />
                </div>{" "}
                &nbsp;
              </Fragment>
            );
          },
        )}

      {customFilter?.partnerName &&
        customFilter?.partnerName?.map((dataItem: any) => {
          return (
            <>
              <div key={dataItem} className="filter-item">
                <Typography fontSize={12} fontWeight={600}>
                  {dataItem.name}
                </Typography>

                <FiTrash2
                  size={14}
                  color={paletteLightTheme.palette.error.main}
                  cursor={"pointer"}
                  onClick={() => {
                    handleDeleteFilter("partnerName", dataItem.value);
                  }}
                />
              </div>{" "}
              &nbsp;
            </>
          );
        })}

      {customFilter?.format?.map((dataItem: string, index: number) => {
        return (
          <Fragment key={index}>
            <div
              key={dataItem}
              className="filter-item"
              style={{
                background: `${paletteLightTheme.palette.primary.main}22`,
              }}
            >
              <Typography fontSize={12} fontWeight={600}>
                {dataItem?.replaceAll("_", " ")}
              </Typography>

              <FiTrash2
                size={14}
                color={paletteLightTheme.palette.error.main}
                cursor={"pointer"}
                onClick={() => handleDeleteFilter("format", dataItem)}
              />
            </div>
            &nbsp;
          </Fragment>
        );
      })}

      {customFilter?.source?.map((dataItem: any, index: number) => {
        return (
          <Fragment key={index}>
            <div
              key={dataItem}
              className="filter-item"
              style={{
                background: `${paletteLightTheme.palette.primary.main}22`,
              }}
            >
              <Typography fontSize={12} fontWeight={600}>
                {dataItem}
              </Typography>

              <FiTrash2
                size={14}
                color={paletteLightTheme.palette.error.main}
                cursor={"pointer"}
                onClick={() => handleDeleteFilter("source", dataItem)}
              />
            </div>
            &nbsp;
          </Fragment>
        );
      })}

      {customFilter?.type &&
        customFilter?.type?.map((dataItem: any, index: number) => {
          return (
            <Fragment key={index}>
              <div key={dataItem} className="filter-item">
                <Typography
                  fontSize={12}
                  fontWeight={600}
                  textTransform={"uppercase"}
                >
                  {dataItem?.replaceAll("_", " ")}
                </Typography>

                <FiTrash2
                  size={14}
                  color={paletteLightTheme.palette.error.main}
                  cursor={"pointer"}
                  onClick={() => handleDeleteFilter("type", dataItem)}
                />
              </div>{" "}
              &nbsp;
            </Fragment>
          );
        })}

      {customFilter?.feePlanName &&
        customFilter?.feePlanName?.map((dataItem: any) => {
          return (
            <>
              <div key={dataItem} className="filter-item">
                <Typography fontSize={12} fontWeight={600}>
                  {dataItem.name}
                </Typography>

                <FiTrash2
                  size={14}
                  color={paletteLightTheme.palette.error.main}
                  cursor={"pointer"}
                  onClick={() => {
                    handleDeleteFilter("feePlanName", dataItem.value);
                  }}
                />
              </div>{" "}
              &nbsp;
            </>
          );
        })}
    </div>
  );
};

export const importManagerFormats = [
  { name: "Gridkey CSV", value: "GRIDKEY_CSV" },
  // { name: "Daily Auto CSV", value: "DAILY_AUTO_CSV" },
  { name: "Cash Entries", value: "GRIDKEY_CASH_CSV" },
  // { name: "Gridkey Contract CSV", value: "GRIDKEY_CONTRACT_CSV" },
  // {
  //   name: "Gridkey Model Portfolio CSV",
  //   value: "GRIDKEY_MODEL_PORTFOLIO_CSV",
  // },
];

export const importManagerSource = [
  { name: "Auto Contract Note", value: "Auto Contract Note" },
  { name: "Manual Import", value: "Manual Upload" },
  // { name: "Manual Entry", value: "Manual Entry" },
  // { name: "Cash Manual Entry", value: "Cash Manual Entry" },
];

export const transactionTypeIncomeExpense = [
  { name: "Expense", value: "expense" },
  { name: "Income", value: "income" },
];

export const transactionTypeCashEntry = [
  { name: "Deposit", value: "deposit" },
  { name: "Withdraw", value: "withdraw" },
];

export const expensesType = [
  { name: "INTEREST", value: "interest" },
  { name: "DP CHARGES", value: "dp_charges" },
  { name: "AMC CHARGES", value: "amc_charges" },
  { name: "BROKERAGE", value: "brokerage" },
  { name: "OTHER CHARGES", value: "total_charges" },
  { name: "MISC EXP", value: "misc_exp" },
  { name: "LATE PAYMENT CHARGES", value: "late_payment_charges" },
];

export const incomeType = [
  { name: "DIVIDEND", value: "dividend" },
  { name: "INTEREST", value: "interest" },
  { name: "MISC INCOME ", value: "misc_income" },
];

export const tradeBookTransactionType = [
  { name: "Buy", value: "buy" },
  { name: "Sell", value: "sell" },
  { name: "IPO", value: "ipo" },
  { name: "BUY BACK", value: "buy_back" },
  { name: "RIGHT ISSUE", value: "right_issue" },
  { name: "PART PAYMENT", value: "partly_paid" },
];

export const mutualFundsType = [
  { name: "Purchase", value: "buy" },
  { name: "Redemption", value: "sell" },
];

export const LEDGER_TRANSACTION_TYPE = [
  ...tradeBookTransactionType,
  { name: "Income", value: "INCOME" },
  { name: "Expense", value: "EXPENSE" },
];

export const portfolioOption = (portfolios: any, form: any) => {
  return portfolios?.filter(
    (data: any) =>
      !form
        .getValues("portfolio")
        ?.map((dataItem: any) => dataItem)
        .includes(data.value),
  );
};

export const portfolioGroupOption = (portfolioGroupList: any, form: any) => {
  return portfolioGroupList?.filter(
    (data: any) =>
      !form
        .getValues("group")
        ?.map((dataItem: any) => dataItem)
        .includes(data?.value),
  );
};

export const companiesOption = (companies: any, form: any) => {
  return companies?.filter(
    (data: any) =>
      !form
        .getValues("gcode")
        ?.map((dataItem: any) => dataItem)
        .includes(data.value),
  );
};

export const clientOption = (clientList: any, form: any) => {
  return clientList?.filter(
    (data: any) =>
      !form
        .getValues("client")
        ?.map((dataItem: any) => dataItem)
        .includes(data.value),
  );
};

export const employeeOption = (employeeList: any, form: any) => {
  return employeeList?.filter(
    (data: any) =>
      !form
        .getValues("employee")
        ?.map((dataItem: any) => dataItem)
        .includes(data.value),
  );
};

export const feePlanOption = (feePlanList: any, form: any) => {
  return feePlanList?.filter(
    (data: any) =>
      !form
        .getValues("feePlan")
        ?.map((dataItem: any) => dataItem)
        .includes(data.value),
  );
};

export const partnerOption = (partnerList: any, form: any) => {
  return partnerList?.filter(
    (data: any) =>
      !form
        .getValues("partner")
        ?.map((dataItem: any) => dataItem)
        .includes(data.value),
  );
};

// Include Filter
export const includePortfolio = [
  "portfolio_list",
  "holding_details",
  "bank_statement_list",
  "trade_book_list",
  "private_equity_list",
  "available_cash_list",
  "fee_calculation",
  "proforma_invoice",
  "invoice_list",
  "mutual_funds_list",
  "income_expense_list",
  "bank_ledger_list",
  "FD_list",
  "FD_payout_list",
  "aif_list",
  "client_upload_proof",
  "subscribed_history",
];

export const includePortfolioGroup = ["portfolio_group_list"];

export const includeCompany = [
  "trade_book_list",
  "private_equity_list",
  "portfolio_trade_book_list",
  "mutual_funds_list",
  "portfolio_mutual_funds",
  "portfolio_interest",
  "holding_summary_list",
  "insights_list",
  "portfolio_holding_list",
  "portfolio_group_holding_list",
  "client_holding_list",
  "family_holding_list",
  "branch_holding_list",
  "employee_holding_list",
  "portfolio_holdings_table",
  "anchor_transaction",
  "portfolio_private_equity_list",
  "portfolio_FD_list",
  "FD_list",
  "FD_payout_list",
  "portfolio_FD_Payout_list",
  "portfolio_aif_list",
  "aif_list",
];

export const includeDateRange = [
  "fee_calculation",
  "portfolio_calculation",
  "proforma_invoice",
  "portfolio_proforma",
  "invoice_list",
  "portfolio_invoice",
  "trade_book_list",
  "private_equity_list",
  "wallet_full_history",
  "portfolio_trade_book_list",
  "bank_ledger_list",
  "mutual_funds_list",
  "portfolio_mutual_funds",
  "bank_statement_list",
  "income_expense_list",
  "portfolio_income_expense",
  "portfolio_bank_ledger_list",
  "portfolio_cash_entry",
  "portfolio_private_equity_list",
  "portfolio_FD_list",
  "FD_list",
  "portfolio_FD_Payout_list",
  "FD_payout_list",
  "portfolio_aif_list",
  "aif_list",
];

export const includeDate = [
  "import_manager_list",
  "custom_broker",
  "recon_manager_list",
  "portfolio_import_manager_list",
  "portfolio_interest",
];

export const includeType = [
  "bank_statement_list",
  "income_expense_list",
  "portfolio_income_expense",
  "portfolio_cash_entry",
];

export const includeTransactionType = [
  "trade_book_list",
  "private_equity_list",
  "portfolio_trade_book_list",
  "mutual_funds_list",
  "portfolio_mutual_funds",
  "bank_ledger_list",
  "portfolio_bank_ledger_list",
  "portfolio_private_equity_list",
  "portfolio_aif_list",
  "aif_list",
];

export const includeClient = [
  "client_list",
  "proforma_invoice",
  "portfolio_proforma",
  "invoice_list",
  "portfolio_invoice",
  "partner_client_list",
];

export const includeEmployee = [
  "employee_list",
  "import_manager_list",
  "recon_manager_list",
  "portfolio_import_manager_list",
];

export const includeFormat = ["import_manager_list"];

export const includeFeePlan = [
  "fee_calculation",
  "portfolio_calculation",
  "coupon",
];

export const includePartner = ["partner_list"];
