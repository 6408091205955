import { useAppContext } from "AppContext";
import {
  AiOutlineLineChart,
  FiShoppingBag,
  LuClipboardList,
  LuImport,
  LuSettings2,
  LuUsers,
  MdOutlineCandlestickChart,
  RiMoneyRupeeCircleLine,
  TbSmartHome,
} from "components";
import { useLocation } from "react-router-dom";
import { UserRoleTypes } from "utils/common";

export const useDashboardLayout = () => {
  const location = useLocation();

  const {
    state: { userData },
  } = useAppContext();

  const AdvisorItems = [
    {
      name: "Dashboard",
      icon: TbSmartHome,
      to: "/app/dashboard",
      role: [
        UserRoleTypes.advisor,
        UserRoleTypes.partner,
        UserRoleTypes.trialUser,
      ],
      canAccess: true,
    },
    {
      name: "Holdings",
      icon: MdOutlineCandlestickChart,
      to: "/app/holdings",
      role: [
        UserRoleTypes.advisor,
        UserRoleTypes.trialUser,
        UserRoleTypes.partner,
        UserRoleTypes.client,
      ],
      canAccess: true,
    },
    {
      name: "Imports",
      icon: LuImport,
      to: "/app/imports",
      role: [
        UserRoleTypes.advisor,
        UserRoleTypes.trialUser,
        UserRoleTypes.partner,
      ],
      canAccess: true,
    },
    {
      name: "CRM",
      icon: LuUsers,
      to: "/app/crm",
      role: [
        UserRoleTypes.advisor,
        UserRoleTypes.trialUser,
        UserRoleTypes.partner,
      ],
      canAccess: true,
    },
    {
      name: "Basket",
      icon: FiShoppingBag,
      to: "/app/equity-basket",
      role: [
        UserRoleTypes.advisor,
        UserRoleTypes.trialUser,
        UserRoleTypes.partner,
      ],
      canAccess: userData?.grantAccess,
    },
    {
      name: "Reports",
      icon: LuClipboardList,
      to: "/app/reports",
      role: [
        UserRoleTypes.advisor,
        UserRoleTypes.trialUser,
        UserRoleTypes.partner,
        UserRoleTypes.client,
      ],
      canAccess: true,
    },
    {
      name: "Insights - (BETA)",
      icon: AiOutlineLineChart,
      to: "/app/insights-holdings",
      role: [
        UserRoleTypes.advisor,
        UserRoleTypes.trialUser,
        UserRoleTypes.partner,
      ],
      canAccess: userData?.grantAccess,
    },
    {
      name: "Revenue",
      icon: RiMoneyRupeeCircleLine,
      to: "/app/revenue",
      role: [
        UserRoleTypes.advisor,
        UserRoleTypes.trialUser,
        UserRoleTypes.partner,
        UserRoleTypes.client,
      ],
      canAccess: userData?.grantAccess,
    },
    {
      name: "Organisation",
      icon: LuSettings2,
      to: "/app/organisation",
      role: [UserRoleTypes.trialUser, UserRoleTypes.advisor],
      canAccess: userData?.grantAccess,
    },
  ];

  const CompanyMasterItem = [
    {
      name: "Company Master",
      icon: TbSmartHome,
      to: "/company-master/dashboard",
      role: [UserRoleTypes.trialUser, UserRoleTypes.advisor],
      canAccess: userData?.grantAccess,
    },
    // {
    //   name: "Settings",
    //   icon: IoSettingsOutline,
    //   to: "/company-master/setting",
    //   role: [UserRoleTypes.advisor],
    // },
  ];

  const items = location.pathname.includes("company-master")
    ? CompanyMasterItem
    : AdvisorItems;

  return { items };
};
