import { LoadingButton } from "@mui/lab";
import { useAppContext } from "AppContext";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  GKDatePicker,
  GKSearchSelect,
  GKTextField,
  Grid2,
  MdExpandMore,
  PhoneInput,
  TextField,
} from "components";
import dayjs from "dayjs";
import { useEditClient } from "hooks/useEditClient";
import { useState } from "react";
import { Controller, FormProvider } from "react-hook-form";
import { MARITAL_STATUS, PERSONAL_GENDER } from "utils/constants";
import { handleReturnAutocompletePlaceholder } from "utils/StringFunctions";
import { panRegex } from "utils/validationSchema";
import Bank from "./Bank";
import DematPage from "./Demat";
import NomineePage from "./Nominees";

const EditClientDetails = () => {
  const { form, isLoading, onSubmit } = useEditClient();

  const {
    state: { familyList },
  } = useAppContext();

  const [hasError, setHasError] = useState(false);

  // const basketOptionList = () => {
  //   return basketList?.filter(
  //     (data: any) =>
  //       !form
  //         .getValues("basket")
  //         ?.map((dataItem: any) => dataItem)
  //         .includes(data.value),
  //   );
  // };

  return (
    <FormProvider {...form}>
      <Accordion>
        <AccordionSummary expandIcon={<MdExpandMore />}>
          Details
        </AccordionSummary>
        <AccordionDetails>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <Grid2 container spacing={1}>
              <Grid2 size={3}>
                <Controller
                  name={`personalDetails.name`}
                  control={form.control}
                  render={({ field }) => (
                    <GKTextField {...field} inputLabel="Name" />
                  )}
                />
              </Grid2>
              <Grid2 size={3}>
                <Controller
                  name="phone"
                  control={form.control}
                  render={({ field }) => (
                    <PhoneInput
                      {...field}
                      onSelectChange={(event: any, val: any) => {
                        form.setValue("countryCode", val?.callingCodes);
                      }}
                      onTextChange={(event: any) => {
                        field.onChange(event.target.value);
                      }}
                      onTextValue={field.value}
                      onSelectValue={form.getValues("countryCode")}
                      hasError={hasError}
                      error={hasError}
                      setHasError={setHasError}
                    />
                  )}
                />
              </Grid2>
              <Grid2 size={3}>
                <Controller
                  name="personalDetails.email"
                  control={form.control}
                  render={({ field }) => (
                    <GKTextField {...field} inputLabel="Email" />
                  )}
                />
              </Grid2>
              <Grid2 size={3}>
                <Controller
                  name={`personalDetails.pan`}
                  control={form.control}
                  rules={{
                    required: {
                      value: true,
                      message: "Please enter PAN",
                    },
                    pattern: {
                      value: panRegex,
                      message: "PAN should have this format ABCDE1234A",
                    },
                    maxLength: {
                      value: 10,
                      message: "PAN should have 10 character",
                    },
                    minLength: {
                      value: 10,
                      message: "PAN should have 10 character",
                    },
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <GKTextField
                      {...field}
                      inputLabel="PAN"
                      requiredField
                      error={!!error}
                      helperText={!!error && error.message}
                      onChange={(e) =>
                        field.onChange(e.target.value.toUpperCase())
                      }
                    />
                  )}
                />
              </Grid2>
              <Grid2 size={3}>
                <Controller
                  name="family"
                  control={form.control}
                  render={({ field }) => (
                    <GKSearchSelect
                      {...field}
                      disableClearable
                      inputLabel="Family"
                      multiple
                      options={familyList || []}
                      onChange={(event, val) => {
                        field.onChange(
                          val.map((option: { value: number }) => option.value),
                        );
                      }}
                      value={
                        familyList?.filter((option: any) =>
                          field.value.includes(option.value),
                        ) || []
                      }
                      getOptionLabel={(option) => option.name || option}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder={handleReturnAutocompletePlaceholder({
                            placeholder: "Select Family",
                            value: field.value,
                          })}
                        />
                      )}
                    />
                  )}
                />
              </Grid2>
              <Grid2 size={3}>
                <Controller
                  name="personalDetails.gender"
                  control={form.control}
                  render={({ field }) => {
                    return (
                      <GKSearchSelect
                        {...field}
                        disableClearable={false}
                        inputLabel="Gender"
                        options={PERSONAL_GENDER || []}
                        onChange={(event, val) => {
                          field.onChange(val?.value);
                        }}
                        getOptionLabel={(option) => option.name || option}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder={handleReturnAutocompletePlaceholder({
                              placeholder: "Select Gender",
                              value: field.value,
                            })}
                          />
                        )}
                      />
                    );
                  }}
                />
              </Grid2>
              <Grid2 size={3}>
                <Controller
                  name="personalDetails.maritalStatus"
                  control={form.control}
                  render={({ field }) => (
                    <GKSearchSelect
                      {...field}
                      disableClearable={false}
                      inputLabel="Marital Status"
                      options={MARITAL_STATUS || []}
                      onChange={(event, val) => {
                        field.onChange(val?.value);
                      }}
                      getOptionLabel={(option) => option.name || option}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder={handleReturnAutocompletePlaceholder({
                            placeholder: "Select Marital Status",
                            value: field.value,
                          })}
                        />
                      )}
                    />
                  )}
                />
              </Grid2>
              <Grid2 size={3}>
                <Controller
                  name="personalDetails.gstn"
                  control={form.control}
                  rules={{
                    min: {
                      value: 15,
                      message: "GSTIN should have 15 characters",
                    },
                    max: {
                      value: 15,
                      message: "GSTIN should have 15 characters",
                    },
                  }}
                  render={({ field }) => (
                    <GKTextField {...field} inputLabel="GSTIN" />
                  )}
                />
              </Grid2>
              <Grid2 size={3}>
                <Controller
                  name="personalDetails.fatherName"
                  control={form.control}
                  render={({ field }) => (
                    <GKTextField {...field} inputLabel="Father Name" />
                  )}
                />
              </Grid2>
              <Grid2 size={3}>
                <Controller
                  name="personalDetails.motherName"
                  control={form.control}
                  render={({ field }) => (
                    <GKTextField {...field} inputLabel="Mother Name" />
                  )}
                />
              </Grid2>
              <Grid2 size={3}>
                <Controller
                  name="personalDetails.dateOfBirth"
                  control={form.control}
                  render={({ field }) => (
                    <GKDatePicker
                      {...field}
                      inputLabel="Date Of Birth"
                      value={dayjs(field.value)}
                      onChange={(value) => {
                        field.onChange(dayjs(value).format("YYYY-MM-DD"));
                      }}
                      minDate={dayjs("01-01-1901")}
                      slotProps={{
                        textField: {
                          error: false,
                          helperText: false,
                        },
                      }}
                    />
                  )}
                />
              </Grid2>
              <Grid2 size={3}>
                <Controller
                  name="personalDetails.income"
                  control={form.control}
                  render={({ field }) => (
                    <GKTextField {...field} type="number" inputLabel="Income" />
                  )}
                />
              </Grid2>
              <Grid2 size={3}>
                <Controller
                  name="personalDetails.occupation"
                  control={form.control}
                  render={({ field }) => (
                    <GKTextField {...field} inputLabel="Occupation" />
                  )}
                />
              </Grid2>
              <Grid2 size={3}>
                <Controller
                  name="personalDetails.state"
                  control={form.control}
                  render={({ field }) => (
                    <GKTextField {...field} inputLabel="State" />
                  )}
                />
              </Grid2>
              <Grid2 size={3}>
                <Controller
                  name="personalDetails.pincode"
                  control={form.control}
                  render={({ field }) => (
                    <GKTextField {...field} inputLabel="Pincode" />
                  )}
                />
              </Grid2>
              <Grid2 size={3}>
                <Controller
                  name="personalDetails.address"
                  control={form.control}
                  render={({ field }) => (
                    <GKTextField {...field} minRows={1} inputLabel="Address" />
                  )}
                />
              </Grid2>
              <Grid2 size={6}>
                <Controller
                  name="personalDetails.emergencyContact"
                  control={form.control}
                  render={({ field }) => (
                    <GKTextField
                      {...field}
                      inputLabel="ُEmergency Contact Name"
                    />
                  )}
                />
              </Grid2>
              <Grid2 size={6}>
                <Controller
                  name="personalDetails.emergencyContactNum"
                  control={form.control}
                  rules={{
                    minLength: {
                      value: 10,
                      message: "Minimum length is 10 characters",
                    },
                    maxLength: {
                      value: 10,
                      message: "Maximum length is 10 characters",
                    },
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <GKTextField
                      {...field}
                      type="number"
                      inputLabel="ُEmergency Contact Number"
                      error={!!error}
                      helperText={error?.message}
                    />
                  )}
                />
              </Grid2>
              {/* {form.getValues("type") === "PMS" && (
                <Grid2 size={6}>
                  <Controller
                    name="basket"
                    control={form.control}
                    render={({ field, fieldState: { error } }) => (
                      <GKSearchSelect
                        {...field}
                        multiple
                        disableClearable={false}
                        disableCloseOnSelect
                        limitTags={5}
                        inputLabel="Basket"
                        requiredField
                        loading={basketListFetching}
                        options={basketOptionList()}
                        value={
                          basketList?.filter((option: any) =>
                            field.value?.includes(option.value),
                          ) || []
                        }
                        onChange={(e, val) => {
                          const selectedIds = val.map(
                            (option: any) => option.value,
                          );
                          field.onChange(selectedIds);
                        }}
                        getOptionLabel={(option) => option.name || option}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            name="basket"
                            placeholder={handleReturnAutocompletePlaceholder({
                              placeholder: "Select Basket",
                              value: field.value,
                            })}
                            error={!!error}
                            helperText={error?.message}
                          />
                        )}
                      />
                    )}
                  />
                </Grid2>
              )} */}
              <Grid2
                size={12}
                display={"flex"}
                justifyContent={"flex-end"}
                alignItems={"center"}
                gap={1}
              >
                <LoadingButton
                  type="submit"
                  variant="contained"
                  size="medium"
                  disabled={hasError}
                  loading={isLoading}
                >
                  Update Client
                </LoadingButton>
              </Grid2>
            </Grid2>
          </form>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<MdExpandMore />}>
          Bank Details
        </AccordionSummary>
        <AccordionDetails>
          <Bank />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<MdExpandMore />}>
          Demat Details
        </AccordionSummary>
        <AccordionDetails>
          <DematPage />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<MdExpandMore />}>
          Nominee Details
        </AccordionSummary>
        <AccordionDetails>
          <NomineePage />
        </AccordionDetails>
      </Accordion>
    </FormProvider>
  );
};

export default EditClientDetails;
