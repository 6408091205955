import { blueGrey } from "@mui/material/colors";
import {
  Box,
  Card,
  Divider,
  FormControl,
  FormControlLabel,
  GKButton,
  GKRadio,
  GKTextField,
  Grid2,
  RadioGroup,
  Show,
  Typography,
} from "components";
import { sum } from "lodash";
import { Controller, useFormContext } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { numberToCurrency } from "utils";
import { daysCalculate } from "./utils";

const InvestmentTab = () => {
  const form = useFormContext();

  const location = useLocation();

  const handleCalculateFee = ({ total, feePercent }: any) => {
    const time =
      daysCalculate(form.getValues("clientObject.advanceFeePlan.frequency")) ||
      0;
    const calc = (time * total * feePercent) / 365 / 100;

    return calc || 0;
  };

  const aumBasedPlanWatch = form.watch("planDataObject.aumBasedPlan");
  const aumCashWatch = form.watch("planDataObject.aumCash");
  const skipPayment =
    new URLSearchParams(location.search).get("skip_advance_fee") === "True";

  return (
    <Grid2 container spacing={2}>
      <Grid2 size={12}>
        <Typography
          fontWeight={600}
          pt={2}
          sx={{ fontSize: "clamp(1.125rem, 0.6563rem + 1.5vw, 1.5rem)" }}
        >
          How are you going to invest in this basket?
        </Typography>
      </Grid2>

      {!skipPayment && (
        <Grid2 size={12}>
          <Typography fontWeight={500} fontSize={12} color="#94A3B8">
            {`We charge an AUA based fee of ${form.getValues("clientObject.advanceFeePlan.feePlan.managementFee.fee") || 0}% Annually for this Basket, only
          users with a minimum investment of ${numberToCurrency(form.getValues("clientObject.advanceFeePlan.feePlan.managementFee.minimumInvestment") || 0)} can subscribe.`}
          </Typography>
        </Grid2>
      )}
      <Grid2 size={12}>
        <FormControl component="fieldset">
          <Controller
            name={"planDataObject.aumBasedPlan"}
            control={form.control}
            rules={{
              required: {
                value: true,
                message: "*Please select one of above plan",
              },
            }}
            render={({ field, fieldState: { error } }) => {
              return (
                <>
                  <RadioGroup
                    {...field}
                    row
                    onChange={(e) => {
                      field.onChange(e.target.value);
                      form.setValue("planDataObject.aumCash", "");
                      form.setValue("planDataObject.plansHoldings", [
                        {
                          gridCode: "",
                          quantity: 0,
                          cmp: "",
                          id: 0,
                          name: "",
                        },
                      ]);
                    }}
                  >
                    <FormControlLabel
                      value="withHoldings"
                      control={<GKRadio disableLeftPadding />}
                      label={
                        <Typography
                          fontSize={16}
                          fontWeight={500}
                          display="inline"
                        >
                          With existing holdings
                        </Typography>
                      }
                    />
                    <FormControlLabel
                      value="withCash"
                      control={<GKRadio disableLeftPadding />}
                      label={
                        <Typography
                          fontSize={16}
                          fontWeight={500}
                          display="inline"
                        >
                          With fresh cash/capital
                        </Typography>
                      }
                    />
                    <FormControlLabel
                      value="both"
                      control={<GKRadio disableLeftPadding />}
                      label={
                        <Typography
                          fontSize={16}
                          fontWeight={500}
                          display="inline"
                        >
                          Both
                        </Typography>
                      }
                    />
                  </RadioGroup>

                  {!!error && (
                    <Typography color="error" fontSize={12}>
                      {error?.message}
                    </Typography>
                  )}
                </>
              );
            }}
          />
        </FormControl>
      </Grid2>

      <Show.When
        isTrue={
          aumBasedPlanWatch === "both" || aumBasedPlanWatch === "withHoldings"
        }
      >
        <Grid2 size={12}>
          <Card sx={{ p: 2 }}>
            <Grid2 container>
              <Grid2 size={{ xs: 12, md: 6 }}>
                <Typography fontWeight={600}>With existing holdings</Typography>
                <Typography color={blueGrey[600]}>
                  Add your holdings by clicking on the button.
                </Typography>
              </Grid2>
              <Grid2
                size={{ xs: 12, md: 6 }}
                sx={{
                  display: "flex",
                  gap: 1,
                  alignItems: "center",
                  justifyContent: {
                    xs: "center",
                    md: "flex-end",
                  },
                }}
              >
                <GKButton
                  size="small"
                  variant="contained"
                  onClick={() => {
                    form.setValue("planDataObject.showHoldingsModal", true);
                  }}
                >
                  {form.getValues("planDataObject.plansHoldings")[0]
                    ?.gridCode !== ""
                    ? "View Holdings"
                    : "Add Holdings"}
                </GKButton>
              </Grid2>
            </Grid2>
          </Card>
        </Grid2>
      </Show.When>
      <Show.When
        isTrue={
          aumBasedPlanWatch === "both" || aumBasedPlanWatch === "withCash"
        }
      >
        <Grid2 size={12}>
          <Card sx={{ p: 2 }}>
            <Grid2 container>
              <Grid2 size={{ xs: 12, md: 6 }}>
                <Typography fontWeight={600}>
                  With fresh cash/capital
                </Typography>
                <Typography color={blueGrey[600]}>
                  Add the fresh capital amount you want to invest.
                </Typography>
              </Grid2>
              <Grid2
                size={{ xs: 12, md: 6 }}
                sx={{
                  display: "flex",
                  gap: 1,
                  alignItems: "center",
                  justifyContent: {
                    xs: "center",
                    md: "flex-end",
                  },
                }}
              >
                <Controller
                  control={form.control}
                  name="planDataObject.aumCash"
                  rules={{
                    required: {
                      message: "Please enter a valid amount",
                      value:
                        form.getValues("planDataObject.aumBasedPlan") ===
                          "both" ||
                        form.getValues("planDataObject.aumBasedPlan") ===
                          "withCash",
                    },
                  }}
                  render={({ field, fieldState: { error } }) => {
                    return (
                      <GKTextField
                        {...field}
                        type="number"
                        fullWidth={false}
                        placeholder="Enter Cash Amount"
                        error={!!error}
                        helperText={error?.message}
                      />
                    );
                  }}
                />
              </Grid2>
            </Grid2>
          </Card>
        </Grid2>{" "}
      </Show.When>
      <Grid2 size={12}>
        <Divider />
      </Grid2>
      <Grid2 size={6}>
        <Box display={"flex"} alignItems={"center"}>
          <Typography fontSize={12} color={blueGrey[500]}>
            Fees (excluding GST)
          </Typography>
        </Box>
        <Typography fontSize={14} fontWeight={600}>
          {numberToCurrency(
            skipPayment
              ? 0
              : handleCalculateFee({
                  feePercent: form.getValues(
                    "clientObject.advanceFeePlan.feePlan.managementFee.fee",
                  ),
                  total:
                    +aumCashWatch +
                    sum(
                      form
                        .getValues("planDataObject.plansHoldings")
                        .map(
                          (d: { cmp: string; quantity: string }) =>
                            +d.cmp * +d.quantity,
                        ),
                    ),
                }),
          )}
        </Typography>
      </Grid2>
      <Grid2
        size={6}
        display={"flex"}
        alignItems={"flex-end"}
        flexDirection={"column"}
      >
        <Typography fontSize={12} color={blueGrey[500]}>
          Total investment value
        </Typography>
        <Typography fontWeight={600}>
          {numberToCurrency(
            +aumCashWatch +
              sum(
                form
                  .getValues("planDataObject.plansHoldings")
                  .map(
                    (d: { cmp: string; quantity: string }) =>
                      +d.cmp * +d.quantity,
                  ),
              ),
          )}
        </Typography>
      </Grid2>
    </Grid2>
  );
};

export default InvestmentTab;
