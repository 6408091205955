import {
  CgMathPlus,
  Divider,
  GKButton,
  GKSelect,
  GKTextField,
  Grid2,
  IconButton,
  MdClose,
  MenuItem,
} from "components";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { BANK_ACCOUNT_TYPES } from "utils/constants";

const BankDetails = () => {
  const form = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: "clientCreation.bankAccounts",
  });

  return (
    <Grid2 size={12} container spacing={2}>
      <Grid2 size={12}>
        {fields?.map((row, index) => {
          return (
            <Grid2
              container
              size={12}
              spacing={2}
              my={2}
              key={row.id}
              sx={{
                p: {
                  xs: 2,
                  md: 0,
                },
                border: {
                  xs: "1px solid #E3E8EF",
                  md: "none",
                },
                borderRadius: {
                  xs: 2,
                  md: 0,
                },
              }}
            >
              <Grid2 size={{ md: 2.8, xs: 12 }}>
                <Controller
                  name={`clientCreation.bankAccounts.${index}.name`}
                  control={form.control}
                  render={({ field }) => (
                    <GKTextField {...field} inputLabel="Bank Account Name" />
                  )}
                />
              </Grid2>
              <Grid2 size={{ md: 2.8, xs: 12 }}>
                <Controller
                  name={`clientCreation.bankAccounts.${index}.accountNum`}
                  control={form.control}
                  render={({ field }) => (
                    <GKTextField {...field} inputLabel="Bank Account Number" />
                  )}
                />
              </Grid2>
              <Grid2 size={{ md: 2.8, xs: 12 }}>
                <Controller
                  name={`clientCreation.bankAccounts.${index}.type`}
                  control={form.control}
                  render={({ field }) => (
                    <GKSelect {...field} inputLabel="Bank Account Type">
                      <MenuItem style={{ display: "none" }} value="">
                        Select Account Type
                      </MenuItem>
                      {BANK_ACCOUNT_TYPES?.map((dataItem) => (
                        <MenuItem value={dataItem?.value} key={dataItem?.value}>
                          {dataItem?.name}
                        </MenuItem>
                      ))}
                    </GKSelect>
                  )}
                />
              </Grid2>
              <Grid2 size={{ md: 2.8, xs: 12 }}>
                <Controller
                  name={`clientCreation.bankAccounts.${index}.ifsc`}
                  control={form.control}
                  render={({ field }) => (
                    <GKTextField {...field} inputLabel="IFSC Code" />
                  )}
                />
              </Grid2>
              <Grid2
                size={{ md: 0.5, xs: 12 }}
                textAlign="center"
                mt={{ md: 3, xs: 0 }}
              >
                <IconButton
                  color="error"
                  disabled={fields.length === 1}
                  onClick={() => remove(index)}
                >
                  <MdClose />
                </IconButton>
              </Grid2>
            </Grid2>
          );
        })}
      </Grid2>
      <Grid2 size={12}>
        <Divider>
          <GKButton
            variant="outlined"
            color="dark"
            size="small"
            startIcon={<CgMathPlus size={10} />}
            onClick={() =>
              append({ name: "", accountNum: "", type: "", ifsc: "" })
            }
          >
            Add Account
          </GKButton>
        </Divider>
      </Grid2>
    </Grid2>
  );
};

export default BankDetails;
