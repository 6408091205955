import {
  AiOutlineEdit,
  CgMathPlus,
  Divider,
  FiTrash2,
  GKButton,
  GKSearchSelect,
  GKSelect,
  GKTextArea,
  GKTextField,
  Grid2,
  IconButton,
  MenuItem,
  PhoneInput,
  TextField,
  Typography,
} from "components";
import { useFetchQuery } from "hooks/useQueries";
import { useUpdateLead } from "hooks/useUpdateLead";
import { useState } from "react";
import { Controller } from "react-hook-form";
import { useParams } from "react-router-dom";
import { GET_ALL_LEAD_LOGS_BY_LEAD_ID } from "utils/apiRoutes";
import { handleReturnChangedObject } from "utils/formHelpers";
import { handleReturnAutocompletePlaceholder } from "utils/StringFunctions";
import { panRegex } from "utils/validationSchema";
import AddStatusAndSouceModal from "../AddStatusAndSouceModal";
import LogsModal from "../LogsModal";
import LeadLogs from "./LeadLogs";

const UpdateNewLead = () => {
  const {
    sourceList,
    sourceRefetch,
    updateLead,
    handleDeleteSource,
    addStatusModal,
    setAddStatusModal,
    type,
    setType,
    updateValue,
    setUpdateValue,
    isOpenLogsModal,
    setIsOpenLogsModal,
    form,
    isFetching,
    countryList,
    dispatch,
    cityList,
    stateList,
  } = useUpdateLead();

  const [hasError, setHasError] = useState(false);

  const { id } = useParams();

  const { data: leadLogData } = useFetchQuery({
    key: ["GET_ALL_LEAD_LOGS"],
    route: GET_ALL_LEAD_LOGS_BY_LEAD_ID,
    requestBody: {
      lead_id: id,
    },
  });

  return (
    <>
      <Grid2 container spacing={1}>
        <Grid2 size={8}>
          <form
            onSubmit={form.handleSubmit((values) => {
              updateLead(
                handleReturnChangedObject({
                  values: values,
                  dirtyFields: form.formState.dirtyFields,
                }),
              );
            })}
          >
            <Grid2 container spacing={2}>
              <Grid2
                size={12}
                container
                spacing={1}
                display={"flex"}
                alignItems={"center"}
              >
                <Grid2 size={6}>
                  <Controller
                    name="name"
                    control={form.control}
                    rules={{
                      required: {
                        value: true,
                        message: "Please enter lead name",
                      },
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <GKTextField
                        {...field}
                        loading={isFetching}
                        inputLabel="Lead Name"
                        requiredField
                        error={!!error}
                        helperText={error?.message}
                      />
                    )}
                  />
                </Grid2>
                <Grid2 size={6}>
                  <Controller
                    name="companyName"
                    control={form.control}
                    rules={{
                      required: {
                        value: true,
                        message: "Please enter company name",
                      },
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <GKTextField
                        {...field}
                        loading={isFetching}
                        requiredField
                        inputLabel="Company Name"
                        error={!!error}
                        helperText={error?.message}
                      />
                    )}
                  />
                </Grid2>
                <Grid2 size={6}>
                  <Controller
                    name="email"
                    control={form.control}
                    rules={{
                      required: {
                        value: true,
                        message: "Please enter email",
                      },
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <GKTextField
                        {...field}
                        loading={isFetching}
                        requiredField
                        inputLabel="Email Address"
                        error={!!error}
                        helperText={error?.message as string}
                      />
                    )}
                  />
                </Grid2>
                <Grid2 size={6}>
                  <Controller
                    name="phoneNumber"
                    control={form.control}
                    rules={{
                      required: {
                        value: true,
                        message: "Please enter phone number",
                      },
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <PhoneInput
                        {...field}
                        requiredField
                        onSelectChange={(event: any, val: any) => {
                          form.setValue("countryCode", val?.callingCodes);
                        }}
                        onTextChange={(event: any) => {
                          field.onChange(event.target.value);
                        }}
                        onTextValue={field.value}
                        onSelectValue={form.getValues("countryCode")}
                        error={!!error || hasError}
                        helperText={error?.message}
                        hasError={hasError}
                        setHasError={setHasError}
                      />
                    )}
                  />
                </Grid2>
                <Grid2 size={6}>
                  <Controller
                    name="position"
                    control={form.control}
                    render={({ field }) => (
                      <GKTextField
                        {...field}
                        loading={isFetching}
                        inputLabel="Position"
                      />
                    )}
                  />
                </Grid2>
                <Grid2 size={6}>
                  <Controller
                    name="panNumber"
                    control={form.control}
                    rules={{
                      pattern: {
                        value: panRegex,
                        message: "PAN should have this format ABCDE1234A",
                      },
                      maxLength: {
                        value: 10,
                        message: "PAN should have 10 character",
                      },
                      minLength: {
                        value: 10,
                        message: "PAN should have 10 character",
                      },
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <GKTextField
                        {...field}
                        loading={isFetching}
                        inputLabel="PAN"
                        onChange={(e) =>
                          field.onChange(e.target.value.toUpperCase())
                        }
                        error={!!error}
                        helperText={error?.message}
                      />
                    )}
                  />
                </Grid2>
                <Grid2 size={6}>
                  <Controller
                    name="sourceOfLead"
                    control={form.control}
                    render={({ field }) => (
                      <GKSelect
                        {...field}
                        loading={isFetching}
                        inputLabel="Source"
                        placeholder="Source"
                        value={field.value}
                        renderValue={(selected) => {
                          const value =
                            selected &&
                            sourceList?.find((data) => data?.value === selected)
                              ?.name;

                          return value || "Select an option";
                        }}
                      >
                        {sourceList.map((data: any) => {
                          return (
                            <MenuItem
                              value={data?.value}
                              key={data?.value}
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                gap: "10px",
                              }}
                            >
                              <Typography>{data?.name}</Typography>
                              <span
                                style={{
                                  display: "flex",
                                  gap: "2px",
                                }}
                              >
                                <IconButton
                                  style={{ padding: 0 }}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setType("SOURCE");
                                    setUpdateValue(data);
                                    setAddStatusModal(true);
                                  }}
                                >
                                  <AiOutlineEdit />
                                </IconButton>
                                <IconButton
                                  className="outline"
                                  style={{ padding: 0 }}
                                  color="error"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleDeleteSource(data?.value);
                                  }}
                                >
                                  <FiTrash2 size={16} />
                                </IconButton>
                              </span>
                            </MenuItem>
                          );
                        })}
                        <MenuItem
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            gap: "10px",
                          }}
                        >
                          <GKButton
                            variant="link"
                            startIcon={<CgMathPlus />}
                            onClick={() => {
                              setType("SOURCE");
                              setUpdateValue({});
                              setAddStatusModal(true);
                            }}
                          >
                            Add New
                          </GKButton>
                        </MenuItem>
                      </GKSelect>
                    )}
                  />
                </Grid2>
                <Grid2 size={6}>
                  <Controller
                    name="leadValue"
                    control={form.control}
                    render={({ field }) => (
                      <GKTextField
                        {...field}
                        loading={isFetching}
                        inputLabel="Lead Value"
                      />
                    )}
                  />
                </Grid2>
                <Grid2 size={12}>
                  <Controller
                    name="description"
                    control={form.control}
                    render={({ field }) => (
                      <GKTextArea
                        {...field}
                        minRows={5}
                        loading={isFetching}
                        inputLabel="Description"
                      />
                    )}
                  />
                </Grid2>
                <Grid2 size={12}>
                  <Divider />
                </Grid2>
                <Grid2 size={12}>
                  <Controller
                    name="address"
                    control={form.control}
                    render={({ field }) => (
                      <GKTextField
                        {...field}
                        loading={isFetching}
                        inputLabel="Address"
                      />
                    )}
                  />
                </Grid2>
                <Grid2 size={3}>
                  <Controller
                    name="country"
                    control={form.control}
                    render={({ field }) => (
                      <GKSearchSelect
                        {...field}
                        inputLabel="Country"
                        renderInput={(er) => (
                          <TextField
                            {...er}
                            placeholder={handleReturnAutocompletePlaceholder({
                              placeholder: "Select Country",
                              value: field.value,
                            })}
                          />
                        )}
                        options={countryList || []}
                        getOptionLabel={(e) => e.name || e}
                        onChange={(e, val) => {
                          field.onChange(val.name);
                          dispatch({
                            type: "SELECTED_COUNTRY",
                            data: val.id,
                          });
                        }}
                      />
                    )}
                  />
                </Grid2>
                <Grid2 size={3}>
                  <Controller
                    name="state"
                    control={form.control}
                    render={({ field }) => (
                      <GKSearchSelect
                        {...field}
                        inputLabel="State"
                        renderInput={(er) => (
                          <TextField
                            {...er}
                            placeholder={handleReturnAutocompletePlaceholder({
                              placeholder: "Select State",
                              value: field.value,
                            })}
                          />
                        )}
                        options={stateList || []}
                        getOptionLabel={(e) => e.name || e}
                        onChange={(e, val) => {
                          field.onChange(val.name);
                          dispatch({
                            type: "SELECTED_STATE",
                            data: val.id,
                          });
                        }}
                      />
                    )}
                  />
                </Grid2>
                <Grid2 size={3}>
                  <Controller
                    name="city"
                    control={form.control}
                    render={({ field }) => (
                      <GKSearchSelect
                        {...field}
                        inputLabel="City"
                        renderInput={(er) => (
                          <TextField
                            {...er}
                            placeholder={handleReturnAutocompletePlaceholder({
                              placeholder: "Select City",
                              value: field.value,
                            })}
                          />
                        )}
                        options={cityList || []}
                        getOptionLabel={(e) => e.name || e}
                        onChange={(e, val) => {
                          field.onChange(val.name);
                          dispatch({
                            type: "SELECTED_CITY",
                            data: val.id,
                          });
                        }}
                      />
                    )}
                  />
                </Grid2>
                <Grid2 size={3}>
                  <Controller
                    name="pincode"
                    control={form.control}
                    render={({ field }) => (
                      <GKTextField
                        {...field}
                        loading={isFetching}
                        inputLabel="Pin Code"
                      />
                    )}
                  />
                </Grid2>
              </Grid2>
              <Grid2
                size={12}
                display={"flex"}
                justifyContent={"end"}
                textAlign={"right"}
              >
                <Grid2 size={12}>
                  <GKButton
                    variant="contained"
                    size="medium"
                    type="submit"
                    disabled={hasError}
                  >
                    Save
                  </GKButton>
                </Grid2>
              </Grid2>
            </Grid2>
          </form>
        </Grid2>
        <Grid2 size={0.1}>
          <Divider orientation="vertical" sx={{ height: "100%" }} />
        </Grid2>
        <Grid2 size={3.9}>
          <LeadLogs data={leadLogData?.data?.data} />
        </Grid2>
      </Grid2>

      {addStatusModal && (
        <AddStatusAndSouceModal
          open={addStatusModal}
          setOpen={setAddStatusModal}
          type={type}
          sourceRefetch={sourceRefetch}
          updateValue={updateValue}
        />
      )}

      {isOpenLogsModal && (
        <LogsModal open={isOpenLogsModal} setOpen={setIsOpenLogsModal} />
      )}
    </>
  );
};

export default UpdateNewLead;
