import { LoadingButton } from "@mui/lab";
import {
  Divider,
  FormControlLabel,
  Grid2,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import GKButton from "components/GKButton";
import GKDatePicker from "components/GKDatePicker";
import GKModal from "components/GKModal";
import GKSearchSelect from "components/GKSearchSelect";
import GKTextArea from "components/GKTextBar";
import GKTextField from "components/GKTextField";
import dayjs from "dayjs";
import { useFetchQuery, useMutation } from "hooks/useQueries";
import { useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { CgTrash } from "react-icons/cg";
import { useParams } from "react-router-dom";
import { ADVISOR_COMPANY_MASTER_LIST } from "utils/apiRoutes";
import axiosInstance from "utils/axiosInstance";
import { GKModalProps } from "utils/commonInterface";
import { refetchQuery } from "utils/helpers";
import { handleReturnAutocompletePlaceholder } from "utils/StringFunctions";
import { CORPORATE_ACTION_TYPE } from "./helper";

interface Props {
  instType: string;
}

type DemergedCompaniesType = {
  instrument: string;
  newAssetQuantity: string;
  costAcquisitionRatio: string;
};

type FormValues = {
  actType: string;
  actDate: string;
  perShare: string;
  forEvery: string;
  newShareNumber: string;
  bonusAmount: string;
  existingAssetQuantity: string;
  newAssetQuantity: string;
  parentInstrument: string;
  costAcquisitionRatio: string;
  reGcode: string;
  premium: string;
  purpose: string;
  closingDate: string;
  demergedCompanies: DemergedCompaniesType[];
  isCeil: boolean;
};

const CorporateActionModal = (props: GKModalProps & Props) => {
  const { open, setOpen, instType } = props;

  const { id } = useParams();

  const [companyList, setCompanyList] = useState([]);

  const { isFetching } = useFetchQuery({
    key: ["CORPORATE_ACTION_COMPANY_LIST"],
    route: ADVISOR_COMPANY_MASTER_LIST,
    success: (data: any) => {
      setCompanyList(
        data.data.data?.map((item: { name: string; gcode: string }) => ({
          name: item.name,
          value: item.gcode,
        })),
      );
    },
  });

  const { mutate, isLoading } = useMutation({
    mutationKey: ["CREATE_CORPORATE_ACTION"],
    mutationFn: (data: FormValues) =>
      axiosInstance.post(`/advisor-cm/${id}/ca/`, data),
    onSuccess: () => {
      refetchQuery("GET_COMPANY_CORPORATE_ACTION");
      setOpen(false);
    },
  });

  const form = useForm<FormValues>({
    defaultValues: {
      actType: "",
      actDate: "",
      perShare: "",
      forEvery: "",
      newShareNumber: "",
      bonusAmount: "",
      existingAssetQuantity: "",
      newAssetQuantity: "",
      parentInstrument: "",
      costAcquisitionRatio: "",
      reGcode: "",
      premium: "",
      purpose: "",
      closingDate: "",
      demergedCompanies: [
        {
          instrument: "",
          newAssetQuantity: "",
          costAcquisitionRatio: "",
        },
      ],
      isCeil: false,
    },
  });

  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: "demergedCompanies",
  });

  const watchActionType = form.watch("actType");

  return (
    <GKModal
      open={open}
      setOpen={setOpen}
      modalTitle={"Add Corporate Action"}
      footer={
        <Grid2
          container
          size={12}
          display={"flex"}
          justifyContent={"space-between"}
        >
          <Controller
            name="isCeil"
            control={form.control}
            render={({ field }) => (
              <FormControlLabel
                control={<Switch {...field} />}
                label="Ceil Fraction Holdings"
              />
            )}
          />
          <LoadingButton
            type="submit"
            form="corporate-action-form"
            variant="contained"
            loading={isLoading}
          >
            Save
          </LoadingButton>
        </Grid2>
      }
    >
      <form
        onSubmit={form.handleSubmit((values) => mutate(values))}
        id="corporate-action-form"
      >
        <Grid2 container spacing={2}>
          <Grid2 size={6}>
            <Controller
              name="actType"
              control={form.control}
              render={({ field }) => {
                return (
                  <GKSearchSelect
                    {...field}
                    disableClearable
                    inputLabel="Action Type"
                    options={CORPORATE_ACTION_TYPE(instType)}
                    onChange={(event, val) => field.onChange(val?.value)}
                    value={
                      CORPORATE_ACTION_TYPE()?.find(
                        (option: { value: string }) =>
                          option.value === field.value,
                      )?.name || ""
                    }
                    getOptionLabel={(option) => option.name || option}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder={handleReturnAutocompletePlaceholder({
                          placeholder: "Select Action Type",
                          value: field.value,
                        })}
                      />
                    )}
                  />
                );
              }}
            />
          </Grid2>
          <Grid2 size={6}>
            <Controller
              name="actDate"
              control={form.control}
              render={({ field }) => (
                <GKDatePicker
                  {...field}
                  inputLabel="Action Date"
                  value={dayjs(field.value)}
                  onChange={(value) =>
                    field.onChange(dayjs(value).format("YYYY-MM-DD"))
                  }
                  disableFuture
                  maxDate={dayjs()}
                  requiredField
                  slotProps={{
                    textField: {
                      error: false,
                      helperText: false,
                    },
                  }}
                />
              )}
            />
          </Grid2>

          {watchActionType === "DIV" && (
            <Grid2 size={6}>
              <Controller
                name="perShare"
                control={form.control}
                render={({ field }) => (
                  <GKTextField {...field} inputLabel={"Per Share"} />
                )}
              />
            </Grid2>
          )}

          {(watchActionType === "SPL" ||
            watchActionType === "RIGHTS" ||
            watchActionType === "BNS" ||
            watchActionType === "RVS") && (
            <Grid2 size={6}>
              <Controller
                name="forEvery"
                control={form.control}
                render={({ field }) => (
                  <GKTextField {...field} inputLabel="For Every" />
                )}
              />
            </Grid2>
          )}

          {(watchActionType === "SPL" ||
            watchActionType === "RIGHTS" ||
            watchActionType === "RVS") && (
            <Grid2 size={6}>
              <Controller
                name="newShareNumber"
                control={form.control}
                render={({ field }) => (
                  <GKTextField {...field} inputLabel="New Share" />
                )}
              />
            </Grid2>
          )}

          {watchActionType === "BNS" && (
            <Grid2 size={6}>
              <Controller
                name="bonusAmount"
                control={form.control}
                render={({ field }) => (
                  <GKTextField {...field} inputLabel="Bonus Share" />
                )}
              />
            </Grid2>
          )}
          {(watchActionType === "DMG" || watchActionType === "MRG") && (
            <Grid2 size={6}>
              <Controller
                name="existingAssetQuantity"
                control={form.control}
                render={({ field }) => (
                  <GKTextField
                    {...field}
                    inputLabel="Existing Asset Quantity"
                  />
                )}
              />
            </Grid2>
          )}

          {(watchActionType === "MRG" || watchActionType === "DMG") && (
            <Grid2 size={6}>
              <Controller
                name="newAssetQuantity"
                control={form.control}
                render={({ field }) => (
                  <GKTextField {...field} inputLabel="New Asset Quantity" />
                )}
              />
            </Grid2>
          )}

          {watchActionType === "MRG" && (
            <Grid2 size={6}>
              <Controller
                name="parentInstrument"
                control={form.control}
                render={({ field }) => (
                  <GKSearchSelect
                    {...field}
                    loading={isFetching}
                    disableClearable
                    inputLabel="Parent Instrument"
                    options={companyList}
                    onChange={(event, val) => field.onChange(val?.value)}
                    value={
                      companyList?.find(
                        (option) => option.value === field.value,
                      )?.name || ""
                    }
                    getOptionLabel={(option) => option.name || option}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder={handleReturnAutocompletePlaceholder({
                          placeholder: "Select Company Name",
                          value: field.value,
                        })}
                      />
                    )}
                  />
                )}
              />
            </Grid2>
          )}

          {watchActionType === "DMG" && (
            <>
              <Grid2 container size={12} spacing={2}>
                <Grid2 size={6}>
                  <Controller
                    name="costAcquisitionRatio"
                    control={form.control}
                    render={({ field }) => (
                      <GKTextField
                        {...field}
                        inputLabel="Cost Acquisition Ratio"
                      />
                    )}
                  />
                </Grid2>
              </Grid2>

              <Grid2 size={12}>
                <Divider />
              </Grid2>

              <Grid2 size={12}>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Child Instrument</TableCell>
                        <TableCell>New Asset Quantity Child</TableCell>
                        <TableCell>Cost Acquisition Ratio Child</TableCell>
                        <TableCell> </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {fields.map((row, index) => {
                        return (
                          <TableRow key={row.id}>
                            <TableCell
                              style={{
                                width: 300,
                              }}
                            >
                              <Controller
                                name={`demergedCompanies.${index}.instrument`}
                                control={form.control}
                                render={({ field }) => (
                                  <GKSearchSelect
                                    inputLabel=""
                                    disabled={isFetching}
                                    options={companyList || []}
                                    getOptionLabel={(option) =>
                                      option.name || option
                                    }
                                    fullWidth
                                    onChange={(event, val) =>
                                      field.onChange(val.value)
                                    }
                                    value={
                                      companyList?.find(
                                        (data) => data?.value === field.value,
                                      )?.name
                                    }
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        variant="outlined"
                                        placeholder={handleReturnAutocompletePlaceholder(
                                          {
                                            placeholder: "Select Company Name",
                                            value: field.value,
                                          },
                                        )}
                                      />
                                    )}
                                  />
                                )}
                              />
                            </TableCell>
                            <TableCell>
                              <Controller
                                name={`demergedCompanies.${index}.newAssetQuantity`}
                                control={form.control}
                                render={({ field }) => (
                                  <GKTextField
                                    {...field}
                                    inputLabel=""
                                    placeholder="Asset Quantity Child"
                                  />
                                )}
                              />
                            </TableCell>
                            <TableCell>
                              <Controller
                                name={`demergedCompanies.${index}.costAcquisitionRatio`}
                                control={form.control}
                                render={({ field }) => (
                                  <GKTextField
                                    {...field}
                                    inputLabel=""
                                    placeholder="Cost Acquisition Ratio Child"
                                  />
                                )}
                              />
                            </TableCell>
                            <TableCell>
                              <IconButton
                                disabled={fields?.length === 1}
                                color="error"
                                onClick={() => remove(index)}
                              >
                                <CgTrash />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                      <TableRow>
                        <TableCell colSpan={3} sx={{ border: "none" }}>
                          <GKButton
                            onClick={() =>
                              append({
                                instrument: "",
                                newAssetQuantity: "",
                                costAcquisitionRatio: "",
                              })
                            }
                            variant="outlined"
                          >
                            Add Stock
                          </GKButton>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid2>
            </>
          )}

          {(watchActionType === "RIGHTS" || watchActionType === "REC") && (
            <>
              <Grid2 size={6}>
                <Controller
                  name="reGcode"
                  control={form.control}
                  render={({ field }) => (
                    <GKSearchSelect
                      {...field}
                      inputLabel={
                        watchActionType === "RIGHTS"
                          ? "Right Issue Instrument"
                          : "Partly Paid Instrument"
                      }
                      disabled={isFetching}
                      options={companyList || []}
                      getOptionLabel={(option) => option.name || option}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          placeholder={handleReturnAutocompletePlaceholder({
                            placeholder: "Select Company Name",
                            value: field.value,
                          })}
                        />
                      )}
                      fullWidth
                      onChange={(e, val) => {
                        field.onChange(val.value);
                      }}
                      value={
                        companyList.find(
                          (data: any) => data?.gcode === field.value,
                        )?.name
                      }
                    />
                  )}
                />
              </Grid2>
              <Grid2 size={6}>
                <Controller
                  name="premium"
                  control={form.control}
                  render={({ field }) => (
                    <GKTextField {...field} inputLabel="Premium" />
                  )}
                />
              </Grid2>
            </>
          )}

          {watchActionType === "REC" && (
            <Grid2 size={6}>
              <Controller
                name="closingDate"
                control={form.control}
                render={({ field }) => (
                  <GKDatePicker
                    {...field}
                    inputLabel="Closing Date"
                    value={dayjs(field.value)}
                    onChange={(value) =>
                      field.onChange(dayjs(value).format("YYYY-MM-DD"))
                    }
                    disableFuture
                    maxDate={dayjs()}
                    requiredField
                    slotProps={{
                      textField: {
                        error: false,
                        helperText: false,
                      },
                    }}
                  />
                )}
              />
            </Grid2>
          )}

          <Grid2 size={12}>
            <Controller
              name="purpose"
              control={form.control}
              render={({ field }) => (
                <GKTextArea {...field} minRows={5} inputLabel="Purpose" />
              )}
            />
          </Grid2>
        </Grid2>
      </form>
    </GKModal>
  );
};

export default CorporateActionModal;
