/* eslint-disable import/no-named-as-default */
import {
  EditIcon,
  FaChartBar,
  GKDataGrid,
  Grid2,
  IconButton,
  Typography,
} from "components";
import useGenerateQueryParams from "hooks/useGenerateQueryParams";
import { useFetchQuery } from "hooks/useQueries";
import DashboardLayout from "layouts/DashboardLayout";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { ThemeContainer } from "utils/Theme";
import {
  PORTFOLIO_GROUP_BY_ID,
  PORTFOLIO_GROUP_HOLDING,
} from "utils/apiRoutes";
import { assetsHoldingsColumns } from "utils/columns";
import { QueryKeys } from "utils/queryKey";
import useDocumentTitle from "utils/useDocumentTitle";
import EditPortfolioGroupDetails from "./EditPortfolioGroupDetails";

const PortfolioGroupDetails = () => {
  useDocumentTitle("Portfolio Group Details");

  const { theme } = ThemeContainer();

  const { id } = useParams<{ id: string }>();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [singleGroupDetails, setSingleGroupDetails] = useState<any>();

  const requestBody = useGenerateQueryParams("portfolioGroupHolding");

  const { data: portfolioGroup, isLoading } = useFetchQuery({
    key: [QueryKeys.PORTFOLIO_GROUP_LIST, id, requestBody],
    route: PORTFOLIO_GROUP_HOLDING(id),
    requestBody,
  });

  const { data: portfolioGroupData } = useFetchQuery({
    key: ["GET_PORTFOLIOGROUP_BY_ID"],
    route: PORTFOLIO_GROUP_BY_ID(id),
  });

  return (
    <DashboardLayout title={"Portfolio Group Details"}>
      <Grid2 container spacing={2}>
        <Grid2 size={12} container>
          <Grid2 size={10}>
            <Typography fontWeight={600} fontSize={20}>
              {portfolioGroupData?.data?.name}
            </Typography>
            <Typography
              fontWeight={400}
              color={theme.palette.secondaryText.main}
              fontSize={12}
              display={"flex"}
              alignItems={"center"}
            >
              <FaChartBar
                size={16}
                style={{
                  marginRight: 4,
                }}
              />{" "}
              Portfolios
              <span
                style={{
                  fontSize: "14px",
                  color: theme.palette.dark.main,
                  marginLeft: 4,
                }}
              >
                {portfolioGroupData?.data?.portfolios?.length}
              </span>
            </Typography>
          </Grid2>
          <Grid2
            size={2}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"flex-end"}
          >
            <IconButton
              className="outline"
              onClick={() => {
                setSingleGroupDetails(portfolioGroupData?.data);
                setTimeout(() => {
                  setIsModalOpen(true);
                }, 100);
              }}
            >
              <EditIcon size={20} />
            </IconButton>
          </Grid2>
        </Grid2>
        <Grid2 size={12}>
          <GKDataGrid
            density="compact"
            loading={isLoading}
            rows={portfolioGroup?.data?.data || []}
            columns={assetsHoldingsColumns}
            pinnedColumns={{
              left: ["name"],
            }}
            zeroHolding
            totalCount={portfolioGroup?.data?.totalCount || 0}
            name="portfolioGroupHolding"
            tableName="portfolio_group_holding_list"
            exportFile={{
              path: PORTFOLIO_GROUP_HOLDING(id),
              exportName: "PortfolioGroupHolding",
            }}
          />
        </Grid2>
      </Grid2>

      {isModalOpen && (
        <EditPortfolioGroupDetails
          open={isModalOpen}
          setOpen={setIsModalOpen}
          portfolioGroup={singleGroupDetails}
        />
      )}
    </DashboardLayout>
  );
};

export default PortfolioGroupDetails;
