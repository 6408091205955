import { GridColDef } from "@mui/x-data-grid-pro";
import {
  FiSend,
  GKDataGrid,
  Grid2,
  IconButton,
  MdContentCopy,
  Show,
  Tooltip,
  Typography,
} from "components";
import dayjs from "dayjs";
import useGenerateQueryParams from "hooks/useGenerateQueryParams";
import { useFetchQuery, useMutation } from "hooks/useQueries";
import DashboardLayout from "layouts/DashboardLayout";
import { useState } from "react";
import { PAYMENT_LINK_LIST } from "utils/apiRoutes";
import axiosInstance from "utils/axiosInstance";
import { copyTextToClipboard, matchDate } from "utils/helpers";
import { moneyCommaSeparator } from "utils/MathFunction";
import useDocumentTitle from "utils/useDocumentTitle";
import PaymentStatusModal from "./PaymentStatusModal";

const PaymentLinkList = () => {
  useDocumentTitle("Payment Link");

  const [dateModal, setDateModal] = useState(false);
  const [paymentData, setPaymentData] = useState({});

  const requestBody = useGenerateQueryParams("paymentLink");

  const { data, isFetching } = useFetchQuery({
    key: ["PAYMENT_LINK_LIST", requestBody],
    route: PAYMENT_LINK_LIST,
    requestBody,
  });

  const { mutate: resendPaymentLink } = useMutation({
    mutationKey: ["RESEND_PAYMENT_GATEWAY_LINK"],
    mutationFn: (id: string) =>
      axiosInstance.post(`/razorpay-payment-link/${id}/resend/`),
  });

  const columns: GridColDef[] = [
    {
      headerName: "Invoice No.",
      flex: 1,
      minWidth: 150,
      field: "invoice",
      disableColumnMenu: true,
    },
    {
      headerName: "Date",
      flex: 1,
      minWidth: 150,
      field: "invoiceDate",
      disableColumnMenu: true,
      valueFormatter: (value) => matchDate(value?.value),
      valueGetter: (params) =>
        dayjs(params?.row?.invoiceDate).format("D MMM YYYY"),
    },
    {
      headerName: "Portfolios",
      flex: 1,
      minWidth: 250,
      field: "portfolioName",
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <Grid2
            container
            display={"flex"}
            flexDirection={"column"}
            alignItems={"flex-start"}
          >
            <Typography fontWeight={700}>{params?.row?.clientName}</Typography>
            <Typography fontSize={12}>
              {params?.row?.portfolioName} - {params?.row?.portfolioCode}
            </Typography>
          </Grid2>
        );
      },
    },
    {
      headerName: "Amount",
      flex: 1,
      minWidth: 150,
      field: "gettingAmount",
      disableColumnMenu: true,
      valueGetter: (row) => row?.row?.gettingAmount,
      valueFormatter: (params) => moneyCommaSeparator(params.value || 0),
      type: "number",
    },
    {
      headerName: "Payment Status",
      flex: 1,
      minWidth: 150,
      field: "paymentLinkStatus",
      disableColumnMenu: true,
      renderCell: (row) => row?.row?.paymentLinkStatus || "-",
    },
    {
      headerName: "Actions",
      flex: 1,
      minWidth: 100,
      field: "action",
      disableColumnMenu: true,
      align: "right",
      headerAlign: "right",
      sortable: false,
      renderCell: (params) => (
        <Grid2 container gap={1} display={"flex"} justifyContent={"flex-end"}>
          <Tooltip title="Copy Payment Link">
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                copyTextToClipboard(params.row.paymentLinkUrl);
              }}
            >
              <MdContentCopy size={20} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Resend Payment Link">
            <IconButton
              disabled={params?.row?.paymentLinkStatus === "paid"}
              onClick={(event) => {
                event.stopPropagation();
                resendPaymentLink(params?.row?.id);
              }}
            >
              <FiSend size={20} />
            </IconButton>
          </Tooltip>
        </Grid2>
      ),
    },
  ];

  return (
    <DashboardLayout title="Payment Link">
      <GKDataGrid
        loading={isFetching}
        columns={columns}
        rows={data?.data?.data || []}
        onRowClick={(params) => {
          setPaymentData(params.row);
          setDateModal(true);
        }}
        maxHeight={"calc(100vh - 306px)"}
        totalCount={data?.data?.totalCount || 0}
        name={"paymentLink"}
        tableName="payment_link"
      />

      <Show.When isTrue={dateModal}>
        <PaymentStatusModal
          open={dateModal}
          setOpen={setDateModal}
          paymentData={paymentData}
        />
      </Show.When>
    </DashboardLayout>
  );
};

export default PaymentLinkList;
