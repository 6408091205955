import { LoadingButton } from "@mui/lab";
import {
  GKButton,
  GKModal,
  Grid2,
  RiSendPlaneLine,
  Typography,
  VscDebugStart,
} from "components";
import { useMutation } from "hooks/useQueries";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import axiosInstance from "utils/axiosInstance";
import { GKModalProps } from "utils/commonInterface";
import { ThemeContainer } from "utils/Theme";

const RiskRequestModal = (props: GKModalProps) => {
  const { open, setOpen } = props;

  const { theme } = ThemeContainer();

  const { id } = useParams();

  const navigate = useNavigate();

  const { mutate, isLoading } = useMutation({
    mutationFn: (data: any) =>
      axiosInstance.post(`/riskprofile-request/`, { client: data }),
    mutationKey: ["RISK_PROFILE_CLIENT_REQUEST"],

    onSuccess: () => {
      toast.success("Questionnaire requested successfully.");
    },
  });

  return (
    <GKModal
      width={"40%"}
      setOpen={setOpen}
      open={open}
      modalTitle="Risk Assessment"
    >
      <Grid2 container spacing={1}>
        <Grid2 size={12}>
          <GKButton
            variant="outlined"
            color="dark"
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
            onClick={() => {
              navigate(`/app/risk-profile/${id}/questionnaire`);
            }}
          >
            <Grid2 container>
              <Grid2
                size={12}
                textAlign={"left"}
                display={"flex"}
                alignItems={"center"}
                gap={0.5}
              >
                <VscDebugStart size={16} />{" "}
                <Typography
                  fontWeight={600}
                  fontSize={16}
                  color={theme.palette.dark.main}
                >
                  Take Quiz
                </Typography>
              </Grid2>
              <Grid2 size={12} textAlign={"left"}>
                <Typography fontSize={12} textTransform={"none"}>
                  {`Evaluate your client's risk tolerance by answering a series of questions tailored to assess the client's financial risk profile.`}
                </Typography>
              </Grid2>
            </Grid2>
          </GKButton>
        </Grid2>
        <Grid2 size={12}>
          <LoadingButton
            loading={isLoading}
            variant="outlined"
            color="dark"
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
            onClick={() => {
              mutate(id);
            }}
          >
            <Grid2 container>
              <Grid2
                size={12}
                textAlign={"left"}
                display={"flex"}
                alignItems={"center"}
                gap={0.7}
              >
                <RiSendPlaneLine size={16} />{" "}
                <Typography
                  fontWeight={600}
                  fontSize={16}
                  color={theme.palette.dark.main}
                >
                  Send to Client
                </Typography>
              </Grid2>
              <Grid2 size={12} textAlign={"left"}>
                <Typography fontSize={12} textTransform={"none"}>
                  {`Evaluate your client's risk tolerance by sending them a series of questions to answer`}
                </Typography>
              </Grid2>
            </Grid2>
          </LoadingButton>
        </Grid2>
      </Grid2>
    </GKModal>
  );
};

export default RiskRequestModal;
