import { LoadingButton } from "@mui/lab";
import { Divider, Grid2, Show, Tab, Tabs } from "components";
import dayjs from "dayjs";
import { useFetchQuery, useMutation } from "hooks/useQueries";
import { EquitybasketFormInterface } from "interfaces/EquityBasket";
import DashboardLayout from "layouts/DashboardLayout";
import { split } from "lodash";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import { GET_COMPANY_LIST, GET_RISK_PROFILE } from "utils/apiRoutes";
import axiosInstance from "utils/axiosInstance";
import { handleCreateFormData } from "utils/formHelpers";
import { refetchQuery } from "utils/helpers";
import { QueryKeys } from "utils/queryKey";
import { ThemeContainer } from "utils/Theme";
import useDocumentTitle from "utils/useDocumentTitle";
import FeePlansTab from "./FeePlansTab";
import MetaTab from "./MetaTab";
import MethodologyTab from "./MethodologyTab";
import RationaleTabs from "./RationaleTabs";
import RebalanceTab from "./RebalanceTab";
import SettingsTab from "./SettingsTab";

const EquitybasketDetails = () => {
  useDocumentTitle("Basket Details");

  const { theme } = ThemeContainer();

  const [openFeePlanModal, setOpenFeePlanModal] = useState(false);

  const { id } = useParams();

  /** ========================================================================================================= */
  const form = useForm<EquitybasketFormInterface & { activeStep: number }>({
    defaultValues: {
      activeStep: 0,
      name: "",
      code: "",
      rebalanceTime: "",
      overview: "",
      allowLowRiskProfile: "false",
      investmentPhilosophy: "",
      benchmark: "",
      managedBy: "",
      rationale: "",
      basketCategory: "",
      minimumInvestmentValueCheck: "true",
      ownerType: "",
      partners: [],
      nextRebalanceDate: dayjs(),
      managementStyle: [],
      timePeriod: "",
      methodologyUniverse: "",
      methodologyResearch: "",
      methodologyConstituentScreening: "",
      methodologyWeightage: "",
      methodologyRebalance: "",
      methodologyAllocation: "",
      volatility: "",
      basketType: "",
      investmentStyle: [],
      feePlansList: [],
      riskProfile: [],
      feePlans: [],
      researchFiles: [],
      factSheet: [],
      allocationReferenceWeightage: false,
      rebalanceReferenceWeightage: false,
      rebalanceReferenceValue: "",
      publishPlatform: [],
      basketLogo: [],
      allowAllocation: false,
      selectedVersion: null,
      allowRebalance: false,
      published: false,
      basketCreationDate: "",
    },
  });

  /** ========================================================================================================= */
  const { data: companyMasterData } = useFetchQuery({
    key: [QueryKeys.COMPANY_NAMES],
    route: GET_COMPANY_LIST(["INDEX"]),
  });
  /** ========================================================================================================= */
  const { data: feePlanHistoryData } = useFetchQuery({
    key: ["EQUITY_BASKET_FEE_PLAN_HISTORY"],
    route: `/model_portfolio/${id}/fee_plan_history/`,
  });
  /** ========================================================================================================= */
  const { data: riskProfileData, isFetching: riskProfileDataLoading } =
    useFetchQuery({
      key: ["RISK_PROFILE_DATA_FOR_BASKET"],
      route: GET_RISK_PROFILE,
    });
  /** ========================================================================================================= */
  const { mutate: publishFile } = useMutation({
    mutationKey: ["PUBLISH_RESEARCH_FILES"],
    mutationFn: (fileId) =>
      axiosInstance.patch(
        `/model_portfolio/${id}/update_meta_research_file/?file_id=${fileId}`,
      ),
    onSuccess: () => {
      refetchQuery("GET_MODEL_PORTFOLIO_DETAILS_DATA");
      refetchQuery("EQUITY_BASKET_FEE_PLAN_HISTORY");
    },
  });
  /** ========================================================================================================= */
  const { mutate: deleteFile } = useMutation({
    mutationKey: ["DELETE_FILE"],
    mutationFn: (fileId) =>
      axiosInstance.delete(
        `/model_portfolio/${id}/delete_meta_research_file/?file_id=${fileId}`,
      ),
    onSuccess: () => {
      refetchQuery("GET_MODEL_PORTFOLIO_DETAILS_DATA");
      refetchQuery("EQUITY_BASKET_FEE_PLAN_HISTORY");
      form.setValue("researchFiles", []);
      toast.dismiss();
    },
  });
  /** ========================================================================================================= */
  const { mutate: handleSetFeeDefault } = useMutation({
    mutationKey: ["MAKE_FEE_PLAN_DEFAULT"],
    mutationFn: (feePlan) =>
      axiosInstance.patch(`/model_portfolio/${id}/fee_plan/`, {
        feePlan,
      }),
    onSuccess: () => {
      refetchQuery("GET_MODEL_PORTFOLIO_DETAILS_DATA");
      refetchQuery("EQUITY_BASKET_FEE_PLAN_HISTORY");
      form.setValue("researchFiles", []);
      toast.dismiss();
    },
  });

  /** ========================================================================================================= */
  const { mutate: handleUpdateEquityBasket, isLoading: updateLoading } =
    useMutation({
      mutationFn: (data: any) =>
        axiosInstance.patch(`/model_portfolio/${id}/`, data),
      onSuccess: () => {
        refetchQuery("GET_MODEL_PORTFOLIO_DETAILS_DATA");
        refetchQuery("EQUITY_BASKET_FEE_PLAN_HISTORY");
        form.setValue("researchFiles", []);

        if (openFeePlanModal) {
          setOpenFeePlanModal(false);
        }
      },
    });

  /** ========================================================================================================= */
  const { data } = useFetchQuery({
    key: ["GET_MODEL_PORTFOLIO_DETAILS_DATA"],
    route: `/model_portfolio/${id}/details/`,
    enabled: Boolean(id),
    success: (res: any) => {
      form.reset({
        ...res?.data,
        activeStep: form.getValues("activeStep") || 0,
        basketCategory: res?.data?.basketCategory,
        researchFiles: [],
        investmentPhilosophy: res?.data?.investmentPhilosophy || "-",
        rationale: res?.data?.rationale || "-",
        factSheet: res.data?.factSheet
          ? [
              {
                name:
                  split(split(res.data?.factSheet || "", "?")[0], "/")[
                    split(split(res.data?.factSheet || "", "?")[0], "/")
                      .length - 1
                  ] || "",
                file: res.data?.factSheet,
                size: 0,
              },
            ]
          : [],
      });
    },
  });

  const activeStepWatch = form.watch("activeStep");
  form.watch();

  return (
    <FormProvider {...form}>
      <form
        id="equity-basket-meta-form"
        onSubmit={form.handleSubmit((values) => {
          handleUpdateEquityBasket(
            handleCreateFormData({
              values: values,
              dirtyFields: form.formState.dirtyFields,
            }),
          );
        })}
      >
        <DashboardLayout title="Equity Basket Details">
          <Grid2 container spacing={2}>
            <Grid2 size={12}>
              <Tabs
                value={activeStepWatch}
                onChange={(_, val) => {
                  form.setValue("activeStep", val);
                }}
              >
                <Tab label="Meta" />
                <Tab label="Rationale" />
                <Tab label="Methodology" />
                <Tab label="Rebalance" />
                <Tab label="Fee Plans" />
                <Tab label="Settings" />
              </Tabs>
              <Divider />
            </Grid2>
            <Grid2
              size={12}
              maxHeight={"calc(100vh - 218px)"}
              overflow={"auto"}
            >
              <Show.When isTrue={activeStepWatch === 0}>
                <MetaTab
                  riskProfileDataLoading={riskProfileDataLoading}
                  riskProfileData={riskProfileData}
                  companyMasterData={companyMasterData}
                />
              </Show.When>
              <Show.When isTrue={activeStepWatch === 1}>
                <RationaleTabs
                  publishFile={publishFile}
                  deleteFile={deleteFile}
                  researchFiles={data?.data?.researchFiles}
                />
              </Show.When>
              <Show.When isTrue={activeStepWatch === 2}>
                <MethodologyTab />
              </Show.When>
              <Show.When isTrue={activeStepWatch === 3}>
                <RebalanceTab data={data?.data} />
              </Show.When>
              <Show.When isTrue={activeStepWatch === 4}>
                <FeePlansTab
                  handleSetFeeDefault={handleSetFeeDefault}
                  handleUpdateEquityBasket={handleUpdateEquityBasket}
                  updateLoading={updateLoading}
                  openFeePlanModal={openFeePlanModal}
                  setOpenFeePlanModal={setOpenFeePlanModal}
                  feePlanHistoryData={feePlanHistoryData?.data?.data}
                />
              </Show.When>
              <Show.When isTrue={activeStepWatch === 5}>
                <SettingsTab
                  handleUpdateEquityBasket={handleUpdateEquityBasket}
                />
              </Show.When>
            </Grid2>

            <Grid2
              size={12}
              position={"absolute"}
              right={1}
              bottom={1}
              display={"flex"}
              justifyContent={"flex-end"}
              p={1}
              borderTop={"solid 1px"}
              borderColor={theme.palette.border.main}
            >
              <LoadingButton
                loading={updateLoading}
                variant="contained"
                type="submit"
              >
                Save
              </LoadingButton>
            </Grid2>
          </Grid2>
        </DashboardLayout>
      </form>
    </FormProvider>
  );
};

export default EquitybasketDetails;
