import { load } from "@cashfreepayments/cashfree-js";
import { Box, CircularProgress, Divider, Typography } from "components";
import useEnvironmentCheck from "hooks/useEnvironmentCheck";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const AdvisorOnboardingPaymentPage = () => {
  const location = useLocation();

  const { getLocalEnvironment } = useEnvironmentCheck();

  const sessionId = JSON.parse(
    new URLSearchParams(location.search).get("subscription_id"),
  );

  const initializeSDK = async () => {
    const data = await load({
      mode: getLocalEnvironment ? "sandbox" : "production",
    });
    return data;
  };

  useEffect(() => {
    initializeSDK().then((d) => {
      d.subscriptionsCheckout({
        subsSessionId: sessionId,
      });
    });
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        width: "100vw",
        flexDirection: "column",
        gap: 2,
      }}
    >
      <CircularProgress size={50} />
      <Divider
        sx={{
          width: "40%",
        }}
      />
      <Typography fontWeight={600} fontSize={20}>
        Preparing you payment link
      </Typography>
    </Box>
  );
};

export default AdvisorOnboardingPaymentPage;
