import { LoadingButton } from "@mui/lab";
import { grey } from "@mui/material/colors";
import { useIsMutating } from "@tanstack/react-query";
import {
  CgCreditCard,
  CgFileDocument,
  Divider,
  GKButton,
  Grid2,
  IoMdArrowBack,
  IoMdArrowForward,
  PiCardsThree,
  PiDownload,
  PiHandCoins,
  PiUserCheck,
  PiUserRectangle,
  RiListRadio,
  Show,
  Tab,
  Tabs,
} from "components";
import dayjs from "dayjs";
import useEnvironmentCheck from "hooks/useEnvironmentCheck";
import { useFetchQuery, useMutation } from "hooks/useQueries";
import { isEmpty, sum } from "lodash";
import { FormProvider, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { RiCheckDoubleLine } from "react-icons/ri";
import { useLocation } from "react-router-dom";
import axiosInstance from "utils/axiosInstance";
import { refetchQuery } from "utils/helpers";
import { ThemeContainer } from "utils/Theme";
import Agreementtab from "./Agreementtab";
import AssessmentTab from "./AssessmentTab";
import BasketTab from "./BasketTab";
import DetailsTab from "./DetailsTab";
import InvestmentTab from "./InvestmentTab";
import { useInvestorContext } from "./InvestorContext";
import KYCTab from "./KYCTab";
import PaymentTab from "./PaymentTab";
import PlansHoldingsModal from "./PlansHoldingsModal";
import UploadPlanProofs from "./UploadPlanProofs";
import { daysCalculate } from "./utils";

const OnBoarding = () => {
  const { theme } = ThemeContainer();

  const location = useLocation();

  const { dispatch } = useInvestorContext();
  const { getLocalEnvironment } = useEnvironmentCheck();

  const logo = location.search.split("logo=")[1];
  const clientName = new URLSearchParams(location.search).get("client_name");
  const clientEmail = new URLSearchParams(location.search).get("client_email");
  const phone = new URLSearchParams(location.search).get("phone");
  const basketId = new URLSearchParams(location.search).get("basket_id");
  const companyId = new URLSearchParams(location.search).get("company_id");
  const feePlanId = new URLSearchParams(location.search).get("fee_plan_id");
  const partnerId = new URLSearchParams(location.search).get("partner_id");

  const form = useForm({
    defaultValues: {
      activeStep: 0,
      fetchedFromKra: false,
      clientObject: null,
      retakeAssessment: true,
      selectedFeePlan: {},
      planDataObject: {
        aumCash: "",
        showHoldingsModal: false,
        aumBasedPlan: "",
        subscriptionPlan: null,
        subscriptionPlanAccountType: null,
        holdingsProofs: [],
        cashProofs: [],
        plansTimeperiod: "",
        plansHoldings: [
          {
            gridCode: "",
            quantity: 0,
            cmp: "",
            id: 0,
          },
        ],
      },
      clientCreation: {
        personalDetails: {
          pan: "",
          dateOfBirth: dayjs().format("YYYY-MM-DD"),
          name: clientName || "",
          email: clientEmail || "",
          gender: "",
          maritalStatus: "",
          fatherName: "",
          motherName: "",
          income: 0,
          address: "",
          country: "",
          state: "",
          city: "",
          pincode: "",
        },
        bankAccounts: [],
        dematAccounts: [],
        nominees: [],
        phone: phone || "",
        countryCode: "+91",
        basketId: +basketId || "",
        company: +companyId,
        feePlan: +feePlanId,
        partner: +partnerId,
      },
    },
  });

  const activeStepWatch = form.watch("activeStep");
  const holdingModalWatch = form.watch("planDataObject.showHoldingsModal");

  const handleNext = () => form.setValue("activeStep", activeStepWatch + 1);
  const handlePrevious = () => form.setValue("activeStep", activeStepWatch - 1);

  const tabs = [
    {
      name: "Details",
      icon: PiUserRectangle,
      isCompleted: activeStepWatch !== 0,
      disabled: activeStepWatch !== 0,
    },
    {
      name: "Basket",
      icon: PiCardsThree,
      isCompleted: form.getValues("clientObject.portfolio.id"),
      disabled: form.getValues("clientObject.portfolio.id"),
    },
    {
      name: "Investment",
      icon: PiHandCoins,
      isCompleted:
        !isEmpty(form.getValues("planDataObject.aumBasedPlan")) &&
        activeStepWatch > 2,
      disabled:
        !isEmpty(form.getValues("planDataObject.aumBasedPlan")) &&
        activeStepWatch > 2,
    },
    {
      name: "Upload Proof",
      icon: PiDownload,
      isCompleted: activeStepWatch > 3,
      disabled: activeStepWatch > 3,
    },
    {
      name: "KYC",
      icon: PiUserCheck,
      isCompleted: form.getValues("clientObject.kycDetails.kycId"),
      disabled: form.getValues("clientObject.kycDetails.kycId"),
    },
    {
      name: "Risk Profile",
      icon: RiListRadio,
      isCompleted: form.getValues("clientObject.riskprofileDetails.points"),
      disabled: form.getValues("clientObject.riskprofileDetails.points"),
    },
    {
      name: "Agreement",
      icon: CgFileDocument,
      isCompleted: form.getValues(
        "clientObject.agreementDetails.agreementStatus.id",
      ),
      disabled: form.getValues(
        "clientObject.agreementDetails.agreementStatus.id",
      ),
    },
    { name: "Payment", icon: CgCreditCard },
  ];

  const handleReturnStepColor = ({
    index,
    dataItem,
  }: {
    index: number;
    dataItem: any;
  }) => {
    if (index === activeStepWatch) {
      return theme.palette?.primary.main;
    }
    if (dataItem?.isCompleted) {
      return theme.palette?.success.main;
    }
    return grey[600];
  };

  const { isFetching } = useFetchQuery({
    key: ["GET_CLIENT_FOR_CREATION"],
    route: `/client-onboard/?pan=${form.getValues("clientCreation.personalDetails.pan")}&phone=${phone}&basket_id=${basketId}&company=${companyId}`,
    enabled: activeStepWatch >= 1,
    success: (response: any) => {
      form.setValue("clientObject", response?.data?.data);
      form.setValue(
        "selectedFeePlan",
        response?.data?.data?.basket?.feePlansList?.find(
          (dataItem: { id: number }) => dataItem?.id === +feePlanId,
        ),
      );
      form.setValue(
        "retakeAssessment",
        !response?.data?.data?.riskprofileDetails?.points,
      );

      axiosInstance.interceptors.request.use((config) => {
        config.headers.Authorization = `Token ${response?.data?.data?.token}`;
        config.headers[`x-gridkey-user-role`] = `client`;
        return config;
      });

      dispatch({ type: "TOKEN", data: response?.data?.data?.token });
      dispatch({ type: "IS_EXIST_TOKEN", data: true });
    },
  });

  const { mutate: handleClientCreation } = useMutation({
    mutationKey: ["CLIENT_CREATION"],
    mutationFn: (data) => axiosInstance.post("/client-onboard/", data),
    onSuccess: () => {
      refetchQuery("GET_CLIENT_FOR_CREATION");
      handleNext();
    },
  });

  const { mutate: uploadProof } = useMutation({
    mutationKey: ["UPLOAD_PROOF_FOR_ONBOARDING"],
    mutationFn: (values: any) =>
      axiosInstance.patch(
        `/plans/${form.getValues("clientObject.portfolio.id")}/`,
        values,
      ),
    onSuccess: (response) => {
      handleNext();
      refetchQuery("GET_CLIENT_FOR_CREATION");
      toast.success(
        response?.data?.message || "Something went wrong, please try again.",
      );
    },
  });

  const handleCalculateFee = ({ total, feePercent }: any) => {
    const time =
      daysCalculate(form.getValues("clientObject.advanceFeePlan.frequency")) ||
      0;
    const calc = (time * total * feePercent) / 365 / 100;
    return calc || 0;
  };

  const onSubmit = (values: any) => {
    if (activeStepWatch === 0) {
      handleClientCreation(values.clientCreation);
    } else if (
      activeStepWatch === 1 ||
      activeStepWatch === 2 ||
      activeStepWatch === 6 ||
      activeStepWatch === 4
    ) {
      handleNext();
    } else if (activeStepWatch === 3) {
      const formData = new FormData();

      formData.append("freshCapital", values.planDataObject.aumCash);
      formData.append(
        "transactions",
        isEmpty(values?.planDataObject?.plansHoldings[0].gridCode)
          ? JSON.stringify([])
          : JSON.stringify(
              values?.planDataObject?.plansHoldings?.map(
                (d: {
                  gridCode: string;
                  quantity: string | number;
                  cmp: string | number;
                }) => {
                  return {
                    gridCode: d.gridCode,
                    quantity: d.quantity,
                    cmp: d.cmp,
                  };
                },
              ),
            ),
      );
      formData.append("portfolio", form.getValues("clientObject.portfolio.id"));
      formData.append(
        "frequency",
        form.getValues("clientObject.advanceFeePlan.frequency"),
      );
      formData.append(
        "amount",
        JSON.stringify(
          handleCalculateFee({
            feePercent: form.getValues(
              "clientObject.advanceFeePlan.feePlan.managementFee.fee",
            ),
            total:
              +values.planDataObject.aumCash +
              sum(
                values.planDataObject.plansHoldings.map(
                  (d: { cmp: string; quantity: string }) =>
                    +d.cmp * +d.quantity,
                ),
              ),
          }),
        ),
      );
      formData.append(
        "holdingsValue",
        JSON.stringify(
          sum(
            values?.planDataObject?.plansHoldings?.map(
              (d: { cmp: string; quantity: string }) => +d.cmp * +d.quantity,
            ),
          ),
        ),
      );
      formData.append("startDate", dayjs(new Date()).format("YYYY-MM-DD"));
      values.planDataObject?.holdingsProofs?.forEach(
        (file: string | Blob, index: any) => {
          formData.append(`holdingsProof[${index}]`, file);
        },
      );
      values.planDataObject?.cashProofs?.forEach(
        (file: string | Blob, index: any) => {
          formData.append(`capitalProof[${index}]`, file);
        },
      );

      uploadProof(formData);
    } else if (activeStepWatch === 5) {
      handleNext();
    }
  };

  const clientCreationLoading = !!useIsMutating({
    mutationKey: ["CLIENT_CREATION"],
  });

  const uploadProofLoading = !!useIsMutating({
    mutationKey: ["UPLOAD_PROOF_FOR_ONBOARDING"],
  });

  return (
    <FormProvider {...form}>
      <form
        style={{ display: "flex", height: "100%", width: "100%" }}
        onSubmit={form.handleSubmit(onSubmit)}
      >
        <Grid2 container bgcolor={theme.palette.light.main}>
          <Grid2
            size={12}
            borderBottom={`1px solid ${theme.palette.divider}`}
            p={2}
          >
            <img
              src={logo || "/Logos/iconFullLogo.png"}
              alt=""
              height={`-webkit-fill-available`}
              width={"auto"}
              style={{ maxHeight: "32px" }}
              onError={({ currentTarget }) => {
                currentTarget.src = "https://gridkey.in/Logos/iconFullLogo.png";
              }}
            />
          </Grid2>
          <Grid2 size={12}>
            <Tabs
              variant="scrollable"
              value={activeStepWatch}
              scrollButtons={false}
              TabIndicatorProps={{
                style: { backgroundColor: theme.palette.primary.main },
              }}
              onChange={(e, val) =>
                getLocalEnvironment && form.setValue("activeStep", val)
              }
            >
              {tabs?.map((dataItem, index) => (
                <Tab
                  sx={{
                    p: "24px !important",
                    gap: 1,
                    color: handleReturnStepColor({
                      dataItem: dataItem,
                      index: index,
                    }),
                    "&.Mui-disabled": {
                      color: handleReturnStepColor({
                        dataItem: dataItem,
                        index: index,
                      }),
                    },
                  }}
                  disabled={dataItem?.disabled}
                  key={dataItem.name}
                  label={dataItem.name}
                  icon={
                    dataItem?.isCompleted ? (
                      <RiCheckDoubleLine color={theme.palette.success.main} />
                    ) : (
                      <dataItem.icon />
                    )
                  }
                  iconPosition="start"
                />
              ))}
            </Tabs>
            <Divider />
          </Grid2>
          <Grid2
            size={12}
            maxHeight={`calc(100vh - 198px)`}
            minHeight={`calc(100vh - 198px)`}
            overflow={"hidden auto"}
            px={2}
          >
            <Grid2 container size={12} spacing={1}>
              <Grid2 size={12}>
                <Show.When isTrue={activeStepWatch === 0}>
                  <DetailsTab companyId={companyId} />
                </Show.When>
                <Show.When isTrue={activeStepWatch === 1}>
                  <BasketTab loading={isFetching} />
                </Show.When>
                <Show.When isTrue={activeStepWatch === 2}>
                  <InvestmentTab />
                </Show.When>
                <Show.When isTrue={activeStepWatch === 3}>
                  <UploadPlanProofs />
                </Show.When>
                <Show.When isTrue={activeStepWatch === 4}>
                  <KYCTab handleNext={handleNext} logo={logo} />
                </Show.When>
                <Show.When isTrue={activeStepWatch === 5}>
                  <AssessmentTab basketId={basketId} handleNext={handleNext} />
                </Show.When>
                <Show.When isTrue={activeStepWatch === 6}>
                  <Agreementtab handleNext={handleNext} basketId={basketId} />
                </Show.When>
                <Show.When isTrue={activeStepWatch === 7}>
                  <PaymentTab />
                </Show.When>
              </Grid2>
            </Grid2>
          </Grid2>
          <Grid2
            size={12}
            display={"flex"}
            justifyContent={
              activeStepWatch === 0 ? "flex-end" : "space-between"
            }
            gap={1}
            alignItems={"center"}
            p={2}
          >
            {activeStepWatch !== 0 && (
              <GKButton
                variant="outlined"
                color="dark"
                onClick={() => {
                  handlePrevious();
                }}
                startIcon={<IoMdArrowBack />}
              >
                Previous
              </GKButton>
            )}

            {activeStepWatch === 6 && (
              <LoadingButton
                variant="outlined"
                color="dark"
                sx={{ ml: "auto" }}
                target="_blank"
                href={form.getValues("clientObject.agreementTemplate")}
              >
                Read Agreement &nbsp;
              </LoadingButton>
            )}

            {activeStepWatch !== 7 && (
              <LoadingButton
                variant="contained"
                type="submit"
                loading={
                  clientCreationLoading || isFetching || uploadProofLoading
                }
                endIcon={<IoMdArrowForward />}
                disabled={
                  (activeStepWatch === 4 &&
                    !form.getValues("clientObject.kycDetails.kycId")) ||
                  (activeStepWatch === 6 &&
                    !form.getValues(
                      "clientObject.agreementDetails.agreementStatus.id",
                    ))
                }
              >
                {activeStepWatch === 3 &&
                form.watch("planDataObject.holdingsProofs").length === 0 &&
                form.watch("planDataObject.cashProofs").length === 0
                  ? "Skip & Next"
                  : "Next"}
                &nbsp;
              </LoadingButton>
            )}
          </Grid2>
        </Grid2>

        <Show.When isTrue={holdingModalWatch}>
          <PlansHoldingsModal
            open={!!holdingModalWatch}
            setOpen={() =>
              form.setValue("planDataObject.showHoldingsModal", false)
            }
          />
        </Show.When>
      </form>
    </FormProvider>
  );
};

export default OnBoarding;
