import { useAppContext } from "AppContext";
import { Box, Grid2, Show, Tab, Tabs, Typography } from "components";
import { useState } from "react";
import { BiLockAlt } from "react-icons/bi";
import { UserRoleTypes } from "utils/common";
import ReportsManager from ".";
import ReportScheduler from "./ReportScheduler";

const ReportTab = () => {
  const {
    state: { userData },
  } = useAppContext();

  const [reportTab, setReportTab] = useState(0);

  const tabs = [
    {
      label: "Report",
      component: ReportsManager,
      canAccess: true,
    },
    {
      label: "Scheduler",
      component: ReportScheduler,
      canAccess: userData?.grantAccess,
    },
  ];

  return (
    <Grid2 container spacing={1}>
      <Grid2 size={12} display={"flex"}>
        <Tabs
          className="light"
          variant="standard"
          TabIndicatorProps={{ style: { display: "none" } }}
          value={reportTab}
          onChange={(e, val) => setReportTab(val)}
        >
          {tabs
            .filter((dataItem) =>
              UserRoleTypes.client !== userData?.userRole
                ? dataItem
                : dataItem.label !== "Scheduler",
            )
            ?.map((dataItem, index) => (
              <Tab
                key={index}
                disabled={!dataItem.canAccess}
                label={
                  <Box display={"flex"} gap={1}>
                    <Typography>{dataItem.label}</Typography>
                    {!dataItem.canAccess && <BiLockAlt size={16} />}
                  </Box>
                }
                disableRipple
              />
            ))}
        </Tabs>
      </Grid2>
      <Grid2 size={12}>
        {tabs.map((tab, index) => (
          <Show.When key={index} isTrue={reportTab === index}>
            <tab.component />
          </Show.When>
        ))}
      </Grid2>
    </Grid2>
  );
};

export default ReportTab;
