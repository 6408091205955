import { GridColDef } from "@mui/x-data-grid-pro";
import {
  FormControlLabel,
  GKDataGrid,
  Grid2,
  Switch,
  Typography,
} from "components";
import useGenerateQueryParams from "hooks/useGenerateQueryParams";
import { useFetchQuery, useMutation } from "hooks/useQueries";
import { PortfolioFamilyAccessInterface } from "interfaces/Portfolio";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { PORTFOLIO_CLIENT_ACCESS_BY_ID } from "utils/apiRoutes";
import axiosInstance from "utils/axiosInstance";
import { extraColumnsField } from "utils/helpers";
import { ThemeContainer } from "utils/Theme";

interface AccessList {
  data: PortfolioFamilyAccessInterface[];
  totalCount?: number;
  clientsAccess?: number[];
  checkAll?: boolean;
}

const PortfolioFamilyAccess = () => {
  const { id } = useParams();

  const { theme } = ThemeContainer();

  const [clientList, setClientList] = useState<AccessList>();

  const requestBody = useGenerateQueryParams("portfolioFamilyAccess");

  const { isFetching, refetch } = useFetchQuery({
    key: ["PORTFOLIO_FAMILY_ACCESS", requestBody],
    route: PORTFOLIO_CLIENT_ACCESS_BY_ID(id),
    requestBody,
    success: (response: { data: AccessList }) => {
      setClientList(response?.data);
    },
  });

  const { mutate: handleToggleAccess, isLoading: toggleLoading } = useMutation({
    mutationKey: ["PORTFOLIO_FAMILY_TOGGLE_ACCESS"],
    mutationFn: (obj: { clientsAccess?: number[]; checkAll?: boolean }) =>
      axiosInstance.patch(`/portfolios/${id}/clients_access/`, obj),
    onSuccess: () => {
      refetch();
    },
  });

  const toggleSingleAccess = (id: number) => {
    const currentAccessIds = clientList?.clientsAccess || [];
    const updatedAccessIds = currentAccessIds.includes(id)
      ? currentAccessIds.filter((accessId) => accessId !== id)
      : [...currentAccessIds, id];

    handleToggleAccess({ clientsAccess: updatedAccessIds });
  };

  const columns: GridColDef[] = [
    {
      ...extraColumnsField("Client Name", "name", 200, 1),
      renderCell: (params) => (
        <Grid2 container>
          <Grid2 size={12}>
            <Typography
              fontWeight={700}
              maxWidth={"100%"}
              textOverflow={"ellipsis"}
              overflow={"hidden"}
              display={"flex"}
              gap={1}
              alignItems={"center"}
            >
              {params?.row?.name}
              {params?.row?.isClientsPortfolio && (
                <Typography
                  bgcolor={"rgb(0 101 128 / 11%)"}
                  fontSize={10}
                  fontWeight={500}
                  color={theme.palette.primary.main}
                  p={"2px 4px"}
                  borderRadius={"4px"}
                  lineHeight={"14px"}
                >
                  Self
                </Typography>
              )}
            </Typography>
            <Typography fontSize={12}>{params?.row?.clientCode}</Typography>
          </Grid2>
        </Grid2>
      ),
    },
    {
      ...extraColumnsField("Family Name", "familyName", 150, 1),
    },
    {
      ...extraColumnsField("Actions", "action", 80, 1),
      headerAlign: "right",
      align: "right",
      renderCell: (params) => {
        return (
          <Switch
            checked={clientList?.clientsAccess?.includes(params.row.id)}
            onChange={() => toggleSingleAccess(params.row.id)}
          />
        );
      },
    },
  ];

  return (
    <GKDataGrid
      columns={columns}
      rows={clientList?.data || []}
      loading={isFetching}
      name="portfolioFamilyAccess"
      totalCount={clientList?.totalCount || 0}
      headerComponent={
        <Grid2>
          <FormControlLabel
            control={
              <Switch
                disabled={isFetching || toggleLoading}
                checked={clientList?.checkAll}
                onChange={() =>
                  handleToggleAccess(
                    clientList?.checkAll
                      ? { checkAll: false }
                      : { checkAll: true },
                  )
                }
              />
            }
            label={clientList?.checkAll ? "Disable All" : "Enable All"}
            labelPlacement="end"
          />
        </Grid2>
      }
    />
  );
};

export default PortfolioFamilyAccess;
